import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { APP_ID } from "./network/API";
import "./i18n";
import "./ui/reset.scss";
import "./ui/typography.scss";
import "./index.scss";

Sentry.init({
  dsn: "https://1ba7f5b08e794b57bd1976a9eaa0164e@o4504866465185792.ingest.sentry.io/4504876968181760",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.5,
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.REACT_APP_ENV,
});

Sentry.setContext("session", {
  version: process.env.REACT_APP_VERSION,
  appId: APP_ID,
});

declare global {
  interface Window {
    maps: any;
    d3: any;
    topojson: any;
  }
}

window.maps = window.maps || {
  loaded: {},
};

window.maps.importScript = (url: string) => {
  return new Promise((resolve, reject) => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.addEventListener("load", () => resolve(script), false);
    script.addEventListener("error", () => reject(script), false);
    document.body.appendChild(script);
  });
};

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root"),
  () => {
    // if ("serviceWorker" in navigator) {
    //   window.addEventListener("load", () => {
    //     navigator.serviceWorker.register("/sw.js");
    //   });
    //   // navigator.serviceWorker
    //   //   .getRegistrations()
    //   //   .then(function (registrations) {
    //   //     for (let registration of registrations) {
    //   //       registration.unregister();
    //   //     }
    //   //   })
    //   //   .catch(function (err) {
    //   //     console.log("Service Worker registration failed: ", err);
    //   //   });
    // }
  }
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
