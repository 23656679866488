import { Country } from "../model/common/commonType";
import { data } from "./data";

export interface TokenResponse {
  token: string;
}

export const dataMaps = {
  initTopojson: (): Promise<any> => {
    if (window.topojson) {
      return Promise.resolve();
    }
    return window.maps.importScript("/maps/topojson-client.min.js");
  },

  initD3: (): Promise<any> => {
    if (window.d3) {
      return Promise.resolve();
    }
    return window.maps.importScript("/maps/d3.v7.min.js");
  },

  getTopo: (url: string) => data.get(url),

  getDefaultToken: () => data.post<TokenResponse>("/api/statistics/softpos/sales", {}),

  getTokenByCountry: (country: Country) =>
    data.post<TokenResponse>("/api/statistics/softpos/sales", {
      country: country,
    }),
};
