import React, { ReactNode } from "react";
import cx from "classnames";
import "./Visible.scss";

interface Props {
  show: boolean;
  children: ReactNode;
  displayNone?: boolean;
}

export const Visible: React.FunctionComponent<Props> = ({
  children,
  show,
  displayNone = false,
}) => {
  return (
    <div
      className={cx({
        "visible-component": displayNone === false,
        "visible-component-no-display": displayNone === true,
        show,
      })}
    >
      {children}
    </div>
  );
};
