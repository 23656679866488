import React, { useState, useCallback, useMemo } from "react";
import { contractStatusState } from "../../state/contractStatusState";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { ContractSaveError, handleError } from "../../pages/Contract/ContractPage";
import { contractPackageState } from "../../state/contractPackageState";
import { dataContracts } from "../../data/dataContracts";
import { ContractPackage } from "../../model/contract/contractType";
import { contractSaveState, contractErrorState } from "../../state/contractSaveState";
import { ContractEditError } from "../../pages/Contract/ContractEditError";
import { Minus } from "../icons/Minus";
import { Plus } from "../icons/Plus";
import { Button } from "../interactions/Buttons/Button";
import { Status } from "../../data/types";
import { useTranslation } from "react-i18next";
import "./PromotionMonths.scss";
import { CASHLESS_PROMOTION_MONTHS } from "../../model/pricing/pricingUtils";

export const PromotionMonths: React.FunctionComponent = () => {
  const contractStatus = useRecoilValue(contractStatusState);
  const [offerPackage, setOfferPackage] = useRecoilState(contractPackageState);
  const [error, setError] = useState<ContractSaveError | null>(null);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { country } = contractStatus;

  const onClose = useCallback(() => setError(null), []);

  const onSave = useCallback(
    (pack) => {
      if (!pack.packageId) {
        return;
      }

      dataContracts
        .updatePackage(contractStatus.contractId, {
          ...pack,
          promotionMonths: (pack as ContractPackage).cashlessPromotion
            ? CASHLESS_PROMOTION_MONTHS[country]
            : pack.promotionMonths,
        })
        .then((response) => {
          const copy = { ...response };
          delete copy.promotionMonths;
          setOfferPackage({ ...pack, ...copy });
          setDataSaved((dataSaved) =>
            dataSaved.concat({
              date: new Date(),
            })
          );
        })
        .catch((err) => {
          handleError(err, setError);
          setDataError((dataErrors) =>
            dataErrors.concat({
              date: new Date(),
            })
          );
        });
    },
    [contractStatus.contractId, country, setOfferPackage, setDataSaved, setDataError]
  );

  const retry = useCallback(() => {
    setError(null);
    setTimeout(onSave, 50);
  }, [onSave]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setTimeout(() => {
      dataContracts.claimContract(contractStatus.contractId).then(onSave).catch(onSave); // We're lazy, execute save again, which will fail,
      // and propagate error to Overlay
    }, 50);
  }, [contractStatus.contractId, onSave]);

  const offeringStart = new Date();
  offeringStart.setDate(1);
  offeringStart.setMonth(offeringStart.getMonth() + 1);
  if (offerPackage.cashlessPromotion) {
    offeringStart.setMonth(offeringStart.getMonth() + CASHLESS_PROMOTION_MONTHS[country]);
  } else if (offerPackage.promotionMonths && Number(offerPackage.promotionMonths)) {
    offeringStart.setMonth(offeringStart.getMonth() + offerPackage.promotionMonths);
  }

  const decrementButtonStatus = useMemo(() => {
    if (offerPackage.cashlessPromotion) {
      return Status.DISABLED;
    }

    if (!contractStatus.edit) {
      return Status.DISABLED;
    }

    if (!offerPackage.promotionMonths || offerPackage.promotionMonths === 0) {
      return Status.DISABLED;
    }

    return Status.DEFAULT;
  }, [offerPackage.cashlessPromotion, contractStatus.edit, offerPackage.promotionMonths]);

  const incrementButtonStatus = useMemo(() => {
    if (offerPackage.cashlessPromotion) {
      return Status.DISABLED;
    }

    if (!contractStatus.edit) {
      return Status.DISABLED;
    }

    return Status.DEFAULT;
  }, [offerPackage.cashlessPromotion, contractStatus.edit]);

  const onAddMonth = useCallback(() => {
    const copy = {
      ...offerPackage,
      promotionMonths: (offerPackage.promotionMonths || 0) + 1,
    };

    setOfferPackage(copy);
    onSave(copy);
  }, [setOfferPackage, offerPackage, onSave]);

  const onRemoveMonth = useCallback(() => {
    const copy = {
      ...offerPackage,
      promotionMonths: (offerPackage.promotionMonths || 1) - 1,
    };

    setOfferPackage(copy);
    onSave(copy);
  }, [setOfferPackage, offerPackage, onSave]);

  return (
    <div className="promotion-months">
      <ContractEditError
        error={error}
        setError={setError}
        retry={retry}
        onClose={onClose}
        reclaimAndSave={reclaimAndSave}
      />

      <div className="offer-start-wrapper">
        <div className="m-top-10">
          <strong>{t("First invoice")}</strong>
        </div>

        <div className="m-top-10 offer-start-buttons">
          <Button
            type="button"
            action
            onClick={onRemoveMonth}
            className="small"
            status={decrementButtonStatus}
          >
            <Minus />
          </Button>
          <span className="offer-start">
            {offeringStart.toLocaleDateString(lang, {
              year: "numeric",
              month: "long",
            })}
          </span>
          <Button type="button" action onClick={onAddMonth} className="small" status={incrementButtonStatus}>
            <Plus />
          </Button>
        </div>
      </div>
    </div>
  );
};
