import React from "react";
import i18n from "../../../../i18n";
import { IdImageInterface } from "../../../../components/fileUpload/FileUpload";
import { Status } from "../../../../data/types";
import { IdImage } from "../../../Contract/uploads/IdImage";
import { useTranslation } from "react-i18next";
import { ErrorBox } from "../../../../components/boxes/ErrorBox";
import { SuccessBox } from "../../../../components/boxes/SuccessBox";
import { getIntlDate } from "../../../../components/utils";
import { ContractDocument } from "../../../../model/contract/contractType";

interface Props {
  originalImage: IdImageInterface;
  confirmStatus: Status;
  rejectStatus: Status;
  document: ContractDocument;
}

export const ConfirmDocImageAndStatuses: React.FunctionComponent<Props> = ({
  originalImage,
  rejectStatus,
  confirmStatus,
  document,
}) => {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <IdImage status={Status.DEFAULT} image={originalImage} isGeneric={true} />

      <div className="confirm-doc-statuses">
        {confirmStatus === Status.ERROR && (
          <ErrorBox relative>{t("Something went wrong. We couldn't confirm this document.")}</ErrorBox>
        )}

        {confirmStatus === Status.SUCCESS && (
          <SuccessBox relative>
            <i>{getIntlDate(i18n.language, document.confirmed)}</i> -{" "}
            {t("Thanks! We have confirmed this document.")}
          </SuccessBox>
        )}

        {rejectStatus === Status.ERROR && (
          <ErrorBox relative>{t("Something went wrong. We couldn't reject this document.")}</ErrorBox>
        )}

        {rejectStatus === Status.SUCCESS && (
          <SuccessBox relative>{t("Ok! We have rejected this document.")}</SuccessBox>
        )}
      </div>
    </div>
  );
};
