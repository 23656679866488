import { FC } from "react";
import { PricingUpdateType } from "../PricingInputs";
import ContentBox from "../../../../../../../components/ContentBox/ContentBox";
import { useTranslation } from "react-i18next";
import { NumberInput } from "../../../../../../../components/form/NumberInput";
import { RequiredValidator } from "../../../../../../../components/form/validators/RequiredValidator";
import { EditablePricingTemplate } from "../../../../../../../model/pricing/pricingTypes";
import { MinValidator } from "../../../../../../../components/form/validators/MinValidator";
import FadeAnimation from "../../../../../../../components/animate/FadeAnimation";

type TemplateProps = Pick<EditablePricingTemplate, "storeLimitation" | "terminalLimitation">;

interface Props extends TemplateProps {
  updatePricing?: (updates: PricingUpdateType) => void;
  storeLimitationEnabled: boolean;
  terminalLimitationEnabled: boolean;
  isSalesComponent?: boolean;
  pomcaIsEnabled: boolean;
}

const StoreTerminalLimitation: FC<Props> = ({
  updatePricing,
  storeLimitationEnabled,
  terminalLimitationEnabled,
  storeLimitation,
  terminalLimitation,
  isSalesComponent,
  pomcaIsEnabled,
}) => {
  const { t } = useTranslation();

  return (
    <ContentBox
      colorStyle={isSalesComponent ? "grey" : "default"}
      className={isSalesComponent ? "m-bottom-30" : ""}
    >
      <h5 className="m-bottom-30">{t("Price plan limitations")}</h5>

      <div className="tablet-columns">
        <FadeAnimation animatePresence open={storeLimitationEnabled}>
          <NumberInput
            label={t("Store limitation")}
            onChange={updatePricing ? (value) => updatePricing({ storeLimitation: value }) : () => null}
            value={storeLimitation}
            message={t("This price plan will be limited to a maximum of {{storeLimitation}} stores", {
              storeLimitation,
            })}
            validators={[
              new RequiredValidator("Store limitation is required"),
              new MinValidator(1, "Store limitation needs to be a positive number"),
            ]}
            disabled={isSalesComponent}
          />
        </FadeAnimation>

        <FadeAnimation animatePresence open={pomcaIsEnabled ? true : terminalLimitationEnabled}>
          <NumberInput
            label={t("Terminal limitation")}
            value={terminalLimitation}
            onChange={updatePricing ? (value) => updatePricing({ terminalLimitation: value }) : () => null}
            message={t("This price plan will be limited to a maximum of {{terminalLimitation}} terminals", {
              terminalLimitation,
            })}
            validators={[
              new RequiredValidator("Terminal limitation is required"),
              new MinValidator(1, "Terminal limitation needs to be a positive number"),
            ]}
            disabled={pomcaIsEnabled || isSalesComponent}
          />
        </FadeAnimation>
      </div>
    </ContentBox>
  );
};

export default StoreTerminalLimitation;
