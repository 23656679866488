import { useMemo } from "react";
import { generatePath } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Flag } from "../../../../components/flags/Flag";
import { Link } from "../../../../components/links/Link";
import { Country } from "../../../../model/common/commonType";
import { Language } from "../../../../i18n";
import { userState } from "../../../../state/userState";
import { AdminPage } from "../../AdminPage";
import { ADMIN_PRICING_COUNTRY_PAGE } from "../AdminPricingCountryPage/AdminPricingCountryPage";
import "./AdminPricingPage.scss";

export const ADMIN_PRICING_PAGE = "/admin/pricing";

export function AdminPricingPage() {
  const user = useRecoilValue(userState);

  const regionNames = useMemo(() => {
    return new (Intl as any).DisplayNames([Language.UK], {
      type: "region",
    });
  }, []);

  const isSuperAdmin = user?.adminUser?.superAdmin ?? false;
  const isPricingAdmin = user?.adminUser?.pricingAdmin ?? false;

  // if superAdmin - show all countries - otherwise show only user's country
  const allowedCountries = isSuperAdmin
    ? [...Object.values(Country)]
    : [...(isPricingAdmin && user?.country ? [user.country] : [])];

  return (
    <AdminPage title="Pricing" className="admin-pricing-page">
      <section>
        <article>
          <div className="admin-pricing-page">
            <h1>Create/update pricing</h1>
            <ul className="m-top-40">
              {allowedCountries.map((country) => (
                <li key={country}>
                  <Link
                    link={generatePath(ADMIN_PRICING_COUNTRY_PAGE, {
                      country,
                    })}
                  >
                    <Flag lang={country} />
                    {regionNames.of(country)}
                  </Link>
                </li>
              ))}
              <li />
            </ul>
          </div>
        </article>
      </section>
    </AdminPage>
  );
}
