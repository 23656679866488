import { FC } from "react";
import styles from "./DashboardActiveFilter.module.scss";
import { CloseIcon } from "../../../components/icons/CloseIcon";

interface Props {
  onClose: () => void;
  label: string;
}

const DashboardActiveFilter: FC<Props> = ({ onClose, label }) => {
  return (
    <button className={styles.root} onClick={onClose}>
      <span>{label}</span>
      <CloseIcon />
    </button>
  );
};

export default DashboardActiveFilter;
