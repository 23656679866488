import { FC, ReactNode, useState } from "react";
import styles from "./DashboardFilterDropdown.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { Prettify } from "../../../components/utils";
import cx from "classnames";
import { MdOutlineCheck } from "react-icons/md";

export interface FilterAlternative {
  id: string;
  label: string;
  onClick: () => void;
  onRemove: () => void;
}

interface Props {
  labelText: string;
  icon?: ReactNode;
  alternatives: FilterAlternative[];
  values: Prettify<Pick<FilterAlternative, "id">>[];
}

const DashboardFilterDropdown: FC<Props> = ({ labelText, icon, alternatives, values }) => {
  const [showAlts, setShowAlts] = useState(false);

  const hasActiveFilter = values.length > 0;

  const onClickAlt = (selectedAlt: FilterAlternative, isSelected: boolean) => {
    return isSelected ? selectedAlt.onRemove() : selectedAlt.onClick();
  };

  const onHoverStart = () => {
    if (showAlts) return;
    setShowAlts(true);
  };

  return (
    <motion.div className={styles.root} onHoverStart={onHoverStart} onHoverEnd={() => setShowAlts(false)}>
      <label
        className={cx(styles.label, {
          [styles.label_open]: showAlts,
          [styles.active_label]: hasActiveFilter,
        })}
      >
        <span className={styles.icon}>{icon}</span>
        <span className={styles.label_text}>{labelText}</span>
      </label>

      <AnimatePresence>
        {showAlts && (
          <motion.ul
            className={styles.dropdown_items}
            initial={{ opacity: 0, y: -15 }}
            animate={{ opacity: 1, y: 5 }}
            exit={{ opacity: 0, y: -15 }}
          >
            {alternatives.map((alt) => {
              const isSelected = values.find((current) => current.id === alt.id) !== undefined;
              return (
                <li
                  key={alt.id}
                  onClick={() => onClickAlt(alt, isSelected)}
                  className={cx([styles.li], { [styles.li_selected]: isSelected })}
                >
                  {alt.label} {isSelected && <MdOutlineCheck size={20} />}
                </li>
              );
            })}
          </motion.ul>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default DashboardFilterDropdown;
