import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { BeneficialOwnerType } from "../../model/contract/contractType";

interface Props {
  beneficialOwnerType: BeneficialOwnerType;
}
export const FoundationOrOther: React.FunctionComponent<Props> = ({ beneficialOwnerType }) => {
  const { t } = useTranslation();

  if (beneficialOwnerType !== BeneficialOwnerType.FOUNDATION_OR_OTHER) {
    return null;
  }

  const type = t(beneficialOwnerType);

  return (
    <Trans>
      <i>This type of owner ({{ type }}) are not yet supported</i>
    </Trans>
  );
};
