import { useState, useCallback, useMemo, FC, useEffect } from "react";
import {
  AssociateTracking,
  CommunicationEvent,
  dataConfirm,
  InviteType,
} from "../../../../../data/dataConfirm";
import { Associate, AssociateRole } from "../../../../../model/associate/associateTypes";
import { Status } from "../../../../../data/types";
import { useSetRecoilState } from "recoil";
import { Trans, useTranslation } from "react-i18next";
import { getIntlDate, getRelativeDate } from "../../../../../components/utils";
import { Beacon } from "../../../../../components/beacon/Beacon";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { ContractStatus, Validity } from "../../../../../model/contract/contractType";
import { merchantStatusState } from "../../../../../state/merchantStatusState";
import { contractAssociateState } from "../../../../../state/contractAssociateState";
import { TextInput } from "../../../../../components/form/TextInput";
import { EmailValidator } from "../../../../../components/form/validators/EmailValidator";
import { MaxLengthValidator } from "../../../../../components/form/validators/MaxLengthValidator";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { PhoneCountryCodeValidator } from "../../../../../components/form/validators/PhoneCountryCodeValidator";
import { MinLengthValidator } from "../../../../../components/form/validators/MinLengthValidator";
import { Form } from "../../../../../components/form/Form";
import { hasRealErrors } from "../../../../../components/form/FormHelpers";
import { EmailBackendValidator } from "../../../../../components/form/validators/EmailBackendValidator";
import styles from "./Reminder.module.scss";
import { DateTimeFormatOptions } from "luxon";
import { ContractId, LinkId } from "../../../../../model/common/commonType";
import { ErrorBox } from "../../../../../components/boxes/ErrorBox";

interface Props {
  contractId: ContractId;
  associate: Associate;
  loadTracing: () => Promise<void>;
  setAssociate: (associate: Associate) => void;
}

export const Reminder: FC<Props> = ({ associate, setAssociate, contractId, loadTracing }) => {
  const setMerchantStatus = useSetRecoilState(merchantStatusState);
  const setAssociates = useSetRecoilState(contractAssociateState);
  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  const [status, setStatus] = useState(Status.DEFAULT);
  const [email, setEmail] = useState<string>(associate.email || "");
  const [phoneNumber, setPhoneNumber] = useState<string>(associate.phoneNumber || "");
  const [associateTracking, setAssociateTracking] = useState<AssociateTracking[]>([]);

  const tracking = useMemo(() => {
    return associateTracking.filter((item) => item.associateId === associate.associateId)[0];
  }, [associate, associateTracking]);

  const smsLink = tracking?.smsLink;
  const emailLink = tracking?.emailLink;

  const visited = getRelativeDate(lang, tracking?.emailLink?.visited || tracking?.smsLink?.visited);

  const revokedEmails = tracking?.trackingLog.filter(
    (log) => log.event === CommunicationEvent.REVOKED && log.communicationRecipient.includes("@")
  );
  const revokedSmsLinks = tracking?.trackingLog.filter(
    (log) => log.event === CommunicationEvent.REVOKED && !log.communicationRecipient.includes("@")
  );

  const onChange = useCallback((value, name) => {
    return name === "email" ? setEmail(value) : setPhoneNumber(value);
  }, []);

  const loadTracking = useCallback(async () => {
    dataConfirm.getConfirmStatus(contractId, true).then((resp) => setAssociateTracking(resp.tracking));
  }, [contractId, setAssociateTracking]);

  useEffect(() => {
    loadTracking();
  }, [loadTracking]);

  const deleteLink = useCallback(
    async (linkId?: LinkId) => {
      if (!tracking || !linkId) return;

      try {
        setStatus(Status.PENDING);
        await dataConfirm.deleteLink(contractId, linkId);
        await Promise.all([loadTracking(), loadTracing()]);
        setStatus(Status.DEFAULT);
      } catch (err) {
        setStatus(Status.ERROR);
        setTimeout(() => setStatus(Status.DEFAULT), 3000);
      }
    },
    [contractId, tracking, loadTracking, loadTracing]
  );

  const sendNewEmailLink = useCallback(async () => {
    try {
      setStatus(Status.PENDING);
      await dataConfirm.createNewEmailLink(contractId, associate.associateId);
      await Promise.all([loadTracking(), loadTracing()]);
      setStatus(Status.DEFAULT);
    } catch (err) {
      setStatus(Status.ERROR);
      setTimeout(() => setStatus(Status.DEFAULT), 3000);
    }
  }, [contractId, associate.associateId, loadTracing, loadTracking]);

  const sendNewSmsLink = useCallback(async () => {
    setStatus(Status.PENDING);

    try {
      await dataConfirm.createNewSmsLink(contractId, associate.associateId);
      await Promise.all([loadTracking(), loadTracing()]);
      setStatus(Status.DEFAULT);
    } catch (err) {
      setStatus(Status.ERROR);
      setTimeout(() => setStatus(Status.DEFAULT), 3000);
    }
  }, [contractId, associate.associateId, loadTracking, loadTracing]);

  const sendReminder = useCallback(async () => {
    if (!tracking) return;
    setStatus(Status.PENDING);

    try {
      const confirmStatus = await dataConfirm.resendLink(
        contractId,
        associate.associateId,
        email,
        phoneNumber
      );

      setMerchantStatus((prevData) => ({
        ...prevData,
        ...confirmStatus,
        isConfirmed: confirmStatus.status === ContractStatus.COMPLETE,
      }));

      setAssociates((prevAssociates) =>
        prevAssociates.map((prevAssociate) =>
          associate.associateId === prevAssociate.associateId
            ? { ...prevAssociate, email, phoneNumber }
            : prevAssociate
        )
      );

      setAssociate({ ...associate, email, phoneNumber });

      await Promise.all([loadTracking(), loadTracing()]);
      setStatus(Status.SUCCESS);
      setTimeout(() => setStatus(Status.DEFAULT), 3000);
    } catch (err) {
      setStatus(Status.ERROR);
      setTimeout(() => setStatus(Status.DEFAULT), 3000);
    }
  }, [
    contractId,
    tracking,
    email,
    phoneNumber,
    setMerchantStatus,
    setAssociates,
    associate,
    loadTracing,
    loadTracking,
    setAssociate,
  ]);

  if (!tracking) {
    return null;
  }

  return (
    <Form
      onSubmit={(event, form) => {
        const realErrors = hasRealErrors(form);
        if (!realErrors) {
          sendReminder();
        }
      }}
    >
      <div className="fw-600 m-bottom-20">{t("Latest communication")}</div>

      <div className={styles.communication}>
        <div className={styles.status}>
          <Beacon validity={!!visited ? Validity.VALID : Validity.MISSING} mini />
          <div>{t(getLinkText(tracking?.emailLink?.linkType, associate))}</div>
        </div>
      </div>

      <div className="tablet-columns">
        <div>
          <div className="fw-600 m-bottom-20">{t("Email link")}</div>

          <dl className={styles.linkData}>
            {/* <dt>{t("Link")}: </dt>
            <dd>
              {tracking?.emailLink?.id && (
                <Link link={`${API.getMerchantUrl(tracking?.emailLink?.id)}`} external>
                  {tracking?.emailLink?.id}
                </Link>
              )}
            </dd> */}

            <dt>{t("Sent to")}: </dt>
            <dd>{tracking?.emailLink?.email}</dd>

            <dt>{t("Status")}: </dt>
            <dd className={!emailLink ? "color-red-heavy" : "color-green-heavy"}>
              {!emailLink
                ? `${t("Revoked")} - ${getIntlDate(lang, revokedEmails[0].created, options)}`
                : "Active"}
            </dd>

            <dt>{t("Viewed")}: </dt>
            <dd>{getRelativeDate(lang, tracking?.emailLink?.visited)}</dd>
          </dl>

          {!emailLink ? (
            <>
              <Button onClick={sendNewEmailLink} className="small fs-small" status={status}>
                {t("Send new email link")}
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => deleteLink(tracking?.emailLink?.id)}
                className="danger-button small fs-small"
                status={status}
              >
                {t("Delete email link")}
              </Button>
            </>
          )}
        </div>

        <div>
          <div className="fw-600 m-bottom-20">{t("SMS link")}</div>
          {associate.phoneNumber ? (
            <>
              <dl className={styles.linkData}>
                {/* <dt>{t("Link")}: </dt>
                <dd>
                  {tracking?.smsLink?.id && (
                    <Link link={`${API.getMerchantUrl(tracking?.smsLink?.id)}`} external>
                      {tracking?.smsLink?.id}
                    </Link>
                  )}
                </dd> */}

                <dt>{t("Sent to")}: </dt>
                <dd>{tracking?.smsLink?.number}</dd>

                <dt>{t("Status")}: </dt>
                <dd className={!smsLink ? "color-red-heavy" : "color-green-heavy"}>
                  {!smsLink
                    ? `${t("Revoked")} - ${getIntlDate(lang, revokedSmsLinks[0].created, options)}`
                    : "Active"}
                </dd>

                <dt>{t("Viewed")}: </dt>
                <dd>{getRelativeDate(lang, tracking?.smsLink?.visited)}</dd>
              </dl>

              {!smsLink ? (
                <>
                  <Button onClick={sendNewSmsLink} className="small fs-small" status={status}>
                    {t("Send new SMS link")}
                  </Button>
                </>
              ) : (
                <Button
                  onClick={() => deleteLink(tracking?.smsLink?.id)}
                  className="danger-button small fs-small"
                  status={status}
                >
                  {t("Delete SMS link")}
                </Button>
              )}
            </>
          ) : (
            <div className="text-passive fs-small fw-500">
              {"This person has no phone number, and therefore no SMS link"}
            </div>
          )}
        </div>
      </div>

      {!emailLink && !smsLink && (
        <ErrorBox className="m-top-40">
          {t(
            "This person has no available onboarding links, and thus cannot complete their onboarding if no new links are provided."
          )}
        </ErrorBox>
      )}

      <div className="m-top-40">
        <div className="fw-600 m-bottom-20">{t("Remind person")}</div>
        <div className="tablet-columns">
          <div>
            <TextInput
              onChange={onChange}
              name="email"
              label={t("Email")}
              value={email}
              validators={[
                new RequiredValidator(t("Email is required")),
                new EmailValidator(t("Email is not valid")),
                new EmailBackendValidator(t("Email is not valid")),
                new MaxLengthValidator(
                  50,
                  t("Email must be less than {{max}} characters", {
                    max: 50,
                  })
                ),
              ]}
              disabled={status !== Status.DEFAULT}
            />
          </div>
          <div>
            <TextInput
              onChange={onChange}
              name="phoneNumber"
              label={t("Mobile phone")}
              value={phoneNumber}
              hint={
                <Trans>
                  Country prefix <b>must</b> be included.
                </Trans>
              }
              validators={[
                new PhoneCountryCodeValidator(t("Phone number must start with a country code e.g +46...")),
                new MinLengthValidator(
                  8,
                  t("Mobile phone must be at least {{min}} characters", {
                    min: 8,
                  })
                ),
                new MaxLengthValidator(
                  14,
                  t("Mobile phone must be less than {{max}} characters", {
                    max: 14,
                  })
                ),
              ]}
              disabled={status !== Status.DEFAULT}
            />
          </div>
        </div>

        <div>
          <Button className="small fs-small" type="submit" status={status}>
            {t("Remind person")}
          </Button>

          <div className="fw-500 fs-small color-warning-heavy m-top-10">
            {t("Sending a reminder will delete the old links and send new ones")}
          </div>
        </div>
      </div>
    </Form>
  );
};

function getLinkText(linkType: InviteType, associate: Associate) {
  const isSignee = associate.roles.indexOf(AssociateRole.SIGNATORY) > -1;

  if (linkType === InviteType.INVITE_TO_CONFIRM) {
    return "Merchant was invited to confirm contract details";
  }

  if (linkType === InviteType.INVITE_TO_SUBMIT) {
    return isSignee
      ? "Legal representative was invited to sign contract"
      : "Owner was invited to upload identification";
  }

  if (linkType === InviteType.REMINDER_TO_SUBMIT) {
    return isSignee
      ? "Reminder sent to legal representative to upload identification and/or sign contract"
      : "Reminder sent to owner to upload identification";
  }

  return isSignee
    ? "Identification or signature of legal representative was rejected. Invite to resubmit identifaction/signature was sent."
    : "Identification of owner was rejected. Invite to resubmit identifaction was sent.";
}

const options: DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};
