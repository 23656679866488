import { FC, useState, useEffect } from "react";
import { PricingModel, SteppedBasedPricing } from "../../../../../../../model/pricing/pricingTypes";
import { Country } from "../../../../../../../model/common/commonType";
import { useTranslation } from "react-i18next";
import styles from "./SteppedBasedPricing.module.scss";
import { Checkboxes } from "../../../../../../../components/interactions/Checkboxes/Checkboxes";
import { FeeBrandLogo, sortBrands } from "../../../../../../Contract/pricingV2/views/FeeBrandLogo";
import { AnimatePresence, motion } from "framer-motion";
import StepPricingTableEdit from "./StepPricingTableEdit/StepPricingTableEdit";
import StepPricingTableDisplay from "./StepPricingTableDisplay/StepPricingTableDisplay";
import { SetEditablePricing } from "../PricingInputs";
import { useLocation } from "react-router";
import { InfoBox } from "../../../../../../../components/boxes/InfoBox";
import { getCardBrandsByCountry } from "../../../../../../../model/pricing/pricingUtils";

interface Props {
  pricingModel: PricingModel;
  steppedBasedPricing: SteppedBasedPricing;
  setEditablePricing: SetEditablePricing;
  isSalesComp?: boolean;
  country: Country;
  disabled?: boolean;
}

const SteppedBasedPricingAdmin: FC<Props> = ({
  steppedBasedPricing,
  setEditablePricing,
  country,
  isSalesComp,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [editMode, setEditmode] = useState<string[]>(isSalesComp ? [] : [EDIT_MODE]);
  const isEditMode = editMode.includes(EDIT_MODE);
  const { pathname } = useLocation();

  useEffect(() => {
    if (isSalesComp) return;
    const isCreating = pathname.includes("new");
    setEditmode(isCreating ? [EDIT_MODE] : []);
  }, [pathname, isSalesComp]);

  useEffect(() => {
    if (disabled) {
      setEditmode([]);
    }
  }, [disabled]);

  const sortedBrands = getCardBrandsByCountry(country).sort(sortBrands);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={isSalesComp ? "m-bottom-30" : ""}
    >
      {!isSalesComp && (
        <Checkboxes
          className="m-bottom-10"
          onChange={(values) => setEditmode(values)}
          values={editMode}
          disabled={disabled}
          alternatives={[
            {
              text: t("Edit mode"),
              value: EDIT_MODE,
            },
          ]}
        />
      )}

      <div className={styles.table} key="stepped-display-table">
        <div className={styles.table_header}>
          <div>
            <h5>{t("Stepped based pricing")}</h5>
            <InfoBox className="m-y-20">
              {t(
                "A dynamic pricing plan that adjusts based on the previous month's turnover. All new merchants start in the lowest threshold band"
              )}
            </InfoBox>
          </div>

          <ul className={styles.cards}>
            {sortedBrands.map((brand) => (
              <li key={brand}>
                <FeeBrandLogo brand={brand} />
              </li>
            ))}
          </ul>
        </div>

        <AnimatePresence>
          {isEditMode ? (
            <StepPricingTableEdit
              steppedBasedPricing={steppedBasedPricing}
              setEditablePricing={setEditablePricing}
            />
          ) : (
            <StepPricingTableDisplay steppedBasedPricing={steppedBasedPricing} />
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default SteppedBasedPricingAdmin;

const EDIT_MODE = "edit-mode";
