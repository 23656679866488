import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ScrollPositionContext,
  ScrollPositionContextInterface,
  ScrollPositionEntry,
} from "./ScrollPositionContext";
import throttle from "lodash.throttle";

const SCROLL_THROTTLE = 1000 / 30;
const SCROLL_OFFSET_FROM_TOP = 300;

export const ScrollPosition: React.FunctionComponent = ({ children }) => {
  const elements = useRef<ScrollPositionEntry[]>([]);
  const latestElementRef = useRef<string>();
  const [closestScrollEntry, setClosestScrollEntry] =
    useState<ScrollPositionEntry>();

  useEffect(() => {
    const handleScroll = throttle(() => {
      const closest = elements.current.reduce(
        (acc: { distance: number; item?: ScrollPositionEntry }, item) => {
          if (!item.ref.current) {
            return acc;
          }

          const rect = item.ref.current.getBoundingClientRect();
          const distance = Math.abs(rect.top);

          if (
            (rect.top < 0 || distance < SCROLL_OFFSET_FROM_TOP) &&
            distance < acc.distance
          ) {
            return {
              distance,
              item: item,
            };
          }

          return acc;
        },
        {
          distance: Infinity,
          item: undefined,
        }
      );

      if (closest.item) {
        if (latestElementRef.current !== closest.item.id) {
          setClosestScrollEntry(closest.item);
        }
        latestElementRef.current = closest.item.id;
      }
    }, SCROLL_THROTTLE);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const addScrollRef = useCallback(
    (id: string, ref: RefObject<HTMLElement>) => {
      if (ref.current) {
        elements.current.push({ id, ref });
      }
    },
    []
  );

  const removeScrollRef = useCallback((id: string) => {
    elements.current = elements.current.filter((item) => item.id !== id);
  }, []);

  const value: ScrollPositionContextInterface = useMemo(
    () => ({
      addScrollRef,
      removeScrollRef,
      closestScrollEntry,
    }),
    [addScrollRef, removeScrollRef, closestScrollEntry]
  );

  return (
    <ScrollPositionContext.Provider value={value}>
      {children}
    </ScrollPositionContext.Provider>
  );
};
