import styles from "./TerminalPriceError.module.scss";
import { ErrorBox } from "../../../../../../../components/boxes/ErrorBox";
import { T } from "../../../../../../../components/translation/T";
import { FC } from "react";

interface Props {
  onFetchTerminalPrices?: () => void;
}

export const TerminalPriceError: FC<Props> = ({ onFetchTerminalPrices }) => {
  return (
    <div className={styles.terminalPricesError}>
      <div className={styles.retrySection}>
        <ErrorBox>
          <T>There was a problem getting terminal prices.</T>
        </ErrorBox>

        {/* <Button
          className="m-top-30"
          block
          onClick={onFetchTerminalPrices ? onFetchTerminalPrices : () => null}
        >
          Retry
        </Button> */}
      </div>
      {/* <div className={styles.selectors}>
        {Object.keys(TerminalType).map((terminalType) => (
          <TerminalPriceSelectorDisabled
            key={terminalType}
            terminalType={terminalType as TerminalType}
          />
        ))}
      </div> */}
    </div>
  );
};
