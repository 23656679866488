import React, { ReactNode, useCallback, useState } from "react";
import cx from "classnames";
import { PricingTemplate } from "../../../../../../model/pricing/pricingTypes";
import { getPricingModelDisplayName } from "../../../../../../model/pricing/pricingUtils";
import { Link } from "../../../../../../components/links/Link";
import { generatePath, useHistory } from "react-router-dom";
import { ADMIN_PRICING_PRESET_PAGE } from "../../AdminPresetPage/EditAdminPreSetPage";
import { Country, UTCDate } from "../../../../../../model/common/commonType";
import { SetterOrUpdater } from "recoil";
import { Button } from "../../../../../../components/interactions/Buttons/Button";
import { dataPricing } from "../../../../../../data/dataPricing";
import { ContractEditError } from "../../../../../Contract/ContractEditError";
import { ContractSaveError, handleError } from "../../../../../Contract/ContractPage";
import { Status } from "../../../../../../data/types";
import { PricingPulse } from "../../../PricingPulse/PricingPulse";
import { getIntlDate } from "../../../../../../components/utils";
import { useTranslation } from "react-i18next";
import "./PreSetRow.scss";
import { EditIcon } from "../../../../../../components/icons/EditIcon";
import { TrashIcon } from "../../../../../../components/icons/TrashIcon";
import { getContractTypeDisplayString } from "../../../../../../model/contract/contractUtils";
import { InstoreIcon } from "../../../../../../components/icons/InstoreIcon";
import { ContractType } from "../../../../../../model/contract/contractType";
import { EcomIcon } from "../../../../../../components/icons/EcomIcon";

interface Props {
  preSet: PricingTemplate;
  country: Country;
  setPreSets: SetterOrUpdater<PricingTemplate[]>;
  width: number;
  d3: Status;
}

export const PreSetRow: React.FunctionComponent<Props> = ({ country, preSet, setPreSets, width, d3 }) => {
  const [error, setError] = useState<ContractSaveError | null>(null);
  const onClose = useCallback(() => setError(null), []);
  const { i18n } = useTranslation();
  const [disabled, setDisabled] = useState<UTCDate | undefined>(preSet.disabled);
  const history = useHistory();

  const onRemove = useCallback(() => {
    setDisabled(new Date().toISOString() as UTCDate);
    dataPricing
      .disablePricingTemplate(preSet.templateId)
      .then(() => {
        setPreSets((prev) =>
          prev.map((prevPreSet) => {
            if (prevPreSet.templateId === preSet.templateId) {
              return {
                ...prevPreSet,
                disabled: new Date().toISOString() as UTCDate,
              };
            }

            return prevPreSet;
          })
        );
      })
      .catch((err) => {
        setDisabled(undefined);
        handleError(err, setError);
      });
  }, [preSet.templateId, setPreSets]);

  const onEnable = useCallback(() => {
    setDisabled(undefined);
    dataPricing
      .enablePricingTemplate(preSet.templateId)
      // didn't seem to do anything?
      // .savePricingTemplate(country, preSet)
      .then((data) => {
        setPreSets((prev) =>
          prev.map((prevPreSet) => {
            if (prevPreSet.templateId === preSet.templateId) {
              return { ...prevPreSet, disabled: undefined };
            }
            return prevPreSet;
          })
        );
      })
      .catch((err) => {
        setDisabled(preSet.disabled);
        handleError(err, setError);
      });
  }, [preSet, setPreSets]);

  return (
    <div
      className={cx("pre-set-row-wrapper", preSet.pricingModel, {
        archived: disabled,
      })}
    >
      <ContractEditError
        error={error}
        setError={setError}
        retry={onRemove}
        onClose={onClose}
        reclaimAndSave={onRemove}
      />

      <div className="pre-set-row">
        <div className="pre-set-row-name truncated">
          <Link
            link={generatePath(ADMIN_PRICING_PRESET_PAGE, {
              country,
              preSetId: preSet.templateId,
            })}
          >
            {preSet.name}
          </Link>
        </div>
        <div className="pre-set-row-buttons">
          <Button
            className={cx("small ghost action button", {
              "is-disabled": !!disabled,
            })}
            onClick={() =>
              history.push(
                generatePath(ADMIN_PRICING_PRESET_PAGE, {
                  country,
                  preSetId: preSet.templateId,
                })
              )
            }
          >
            <EditIcon />
          </Button>
          <Button
            className="small danger-button"
            status={!!disabled ? Status.DISABLED : Status.DEFAULT}
            action
            onClick={onRemove}
          >
            <TrashIcon />
          </Button>
        </div>
        <div className="pre-set-row-description small truncated m-y-10">{preSet.description}</div>
        <PricingPulse width={width} d3={d3} preSet={preSet} />
        <div className="pre-set-row-info fs-small ">
          <div className="pre-set-row-labels">
            <div
              className={`pre-set-row-label pre-set-row-pricing-model-${preSet.pricingModel.toLocaleLowerCase()}`}
            >
              {/* <span style={{ marginRight: 10 }} className="model-indicator" />{" "} */}
              {getPricingModelDisplayName(preSet.pricingModel)}
            </div>
            <div
              className={`pre-set-row-label pre-set-contract-type-${preSet.contractType.toLocaleLowerCase()}`}
            >
              {/* {getContractTypeIcon(preSet.contractType)} */}
              {getContractTypeDisplayString(preSet.contractType)}
            </div>
          </div>

          <div>
            <i>Updated {getIntlDate(i18n.language, preSet.updated)}</i>
          </div>
        </div>

        <div className="pre-set-row-enable">
          <i>Archived price plan</i>
          <Button className="small" onClick={onEnable} block>
            Enable
          </Button>
        </div>
      </div>
    </div>
  );
};

const CONTRACT_TYPE_ICON_MAP: Record<ContractType, ReactNode> = {
  [ContractType.INSTORE]: <InstoreIcon />,
  [ContractType.ECOMMERCE]: <EcomIcon />,
};

export const getContractTypeIcon = (type: ContractType) => {
  const icon = CONTRACT_TYPE_ICON_MAP[type];
  return icon;
};
