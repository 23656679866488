import { FC } from "react";
import { Link } from "../../../components/links/Link";
import { useRecoilValue } from "recoil";
import { STORE_KEY, Store } from "../../../Store";
import { SalesDashboardMenuItems } from "./SidebarMenuItems/SalesDashboardMenuItems";
import { FilterUtils } from "../../../model/Filter";
import { userState } from "../../../state/userState";
import { ADMIN_DASHBOARD_ROUTE } from "../../Admin/AdminDashboardPage";
import { DASHBOARD_ROUTE } from "../../Dashboard/DashboardPage";
import styles from "./Sidebar.module.scss";
import { Logo } from "../../../components/images/Logo";
import { Role } from "../../../data/dataAuth";
import { Filter as FilterInterface } from "../../../Store";
import { AdminMenuItems } from "./SidebarMenuItems/AdminMenuItems";
import { PageType } from "../Page";
import { ContractMenuItems } from "./SidebarMenuItems/ContractMenuItems";

const getHomeRoute = (isAdmin: boolean) => {
  return isAdmin
    ? ADMIN_DASHBOARD_ROUTE
    : `${DASHBOARD_ROUTE}${FilterUtils.getQueryFromFilter(
        Store.getValue<FilterInterface>(STORE_KEY.STORE_DASHBOARD_FILTER)
      )}`;
};

interface Props {
  pageType: PageType;
}

export const Sidebar: FC<Props> = ({ pageType }) => {
  const user = useRecoilValue(userState);

  const isAdmin = user?.role === Role.ADMIN;
  const homeRoute = getHomeRoute(isAdmin);

  return (
    <div className={styles.root}>
      <Link link={homeRoute}>
        <Logo className={styles.logo} />
      </Link>

      {pageType === PageType.ADMIN && <AdminMenuItems />}

      {pageType === PageType.SALES_DASHBOARD && <SalesDashboardMenuItems />}

      {pageType === PageType.SALES_CONTRACT && <ContractMenuItems />}
    </div>
  );
};
