import { useMemo, FC, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { RadioGroup } from "../../../../components/form/RadioGroup";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { PricingModel, PricingTemplate } from "../../../../model/pricing/pricingTypes";
import {
  TEMPLATE_ID_MOCK,
  contractPricingState,
  pricingBlendedSelector,
  pricingIcppSelector,
  pricingPackSelector,
  availablePricingTemplatesState,
  selectedPricingTemplates,
} from "../../../../state/contractCostState";
import { contractStatusState } from "../../../../state/contractStatusState";
import {
  convertTemplateToPricingStructure,
  getPricingModelDisplayName,
  isPack,
} from "../../../../model/pricing/pricingUtils";
import { Alternative } from "../../../../components/interactions/InputTypes";
import { NoTemplatesFound } from "./components/NoTemplatesFound";
import { SelectedTemplateNotFound } from "./components/SelectedTemplateNotFound";
import styles from "./LoadTemplate.module.scss";
import { Status } from "../../../../data/types";
import { ErrorBox } from "../../../../components/boxes/ErrorBox";
import { contractStoresState, contractTerminalsSelector } from "../../../../state/contractStoresState";
import { AnimateHeightMotion } from "../../../../components/animate/AnimateHeightMotion";
import { ContractType, TerminalType } from "../../../../model/contract/contractType";
import { storeQueue } from "../../../../data/queues/StoreQueue";
import { ONGOING_RESPONSE } from "../../../../data/queues/QueueTypes";
import { Store2 } from "../../../../model/contract/contractType";
import { ServerError } from "../../../../network/API";
import { contractSaveState, contractErrorState } from "../../../../state/contractSaveState";
import { generateNewTerminal } from "../../../../model/terminal/terminalUtils";

type TemplateMap = Record<PricingModel, PricingTemplate[]>;

interface Props {
  contractTypeTemplatesToLoad: ContractType;
}

export const LoadTemplate: FC<Props> = ({ contractTypeTemplatesToLoad }) => {
  const [status, setStatus] = useState(Status.DEFAULT);
  const [errorMessage, setErrorMessage] = useState<string>(BASE_ERROR_MESSAGE);

  const { t } = useTranslation();
  const { edit, contractId } = useRecoilValue(contractStatusState);

  const [contractPricing, setContractPricing] = useRecoilState(contractPricingState);
  const { templateId: contractTemplateId, pricingModel, name } = contractPricing[contractTypeTemplatesToLoad];

  const [storesState, setStoresState] = useRecoilState(contractStoresState);
  const allTerminals = useRecoilValue(contractTerminalsSelector);

  const storesCount = storesState.length;
  const terminalsCount = allTerminals.length;

  const selectedTemplate = useRecoilValue(selectedPricingTemplates)[contractTypeTemplatesToLoad];

  const pricingStructureId = contractPricing[contractTypeTemplatesToLoad].pricingStructureId;

  const templates = useRecoilValue(availablePricingTemplatesState).filter(
    ({ contractType }) => contractTypeTemplatesToLoad === contractType
  );

  const blendedTemplates = useRecoilValue(pricingBlendedSelector).filter(
    ({ contractType }) => contractTypeTemplatesToLoad === contractType
  );

  const icppTemplates = useRecoilValue(pricingIcppSelector).filter(
    ({ contractType }) => contractTypeTemplatesToLoad === contractType
  );

  const packTemplates = useRecoilValue(pricingPackSelector).filter(
    ({ contractType }) => contractTypeTemplatesToLoad === contractType
  );

  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);

  const contractPricingExists = contractTemplateId !== TEMPLATE_ID_MOCK;
  const isMockId = contractTemplateId === TEMPLATE_ID_MOCK;

  const priceModelAlternatives: Alternative<PricingModel>[] = useMemo(
    () => generatePriceModelAlternatives({ blendedTemplates, icppTemplates, packTemplates }),
    [blendedTemplates, icppTemplates, packTemplates]
  );

  const templateMap: TemplateMap = useMemo(() => {
    return {
      [PricingModel.BLENDED]: blendedTemplates,
      [PricingModel.ICPP]: icppTemplates,
      [PricingModel.PACK]: packTemplates,
    };
  }, [blendedTemplates, icppTemplates, packTemplates]);

  const templatesByPricingModel = useMemo(
    () => templates.filter((template) => template.pricingModel === pricingModel),
    [pricingModel, templates]
  );

  const pricePlanAlternatives: Alternative<number>[] = useMemo(() => {
    const isPackModel = pricingModel === PricingModel.PACK;
    const sortFunction = isPackModel ? sortByTerminalTypeAndThreshold : sortByName;

    return [...templatesByPricingModel]
      .sort(sortFunction)
      .map(({ templateId, name }) => ({ value: templateId, text: name }));
  }, [templatesByPricingModel, pricingModel]);

  const showError = useCallback((errorMsg: string, time?: number) => {
    setStatus(Status.ERROR);
    setErrorMessage(errorMsg);
    setTimeout(() => {
      setStatus(Status.DEFAULT);
      setErrorMessage(BASE_ERROR_MESSAGE);
    }, time ?? 5000);
  }, []);

  const saveStoreCallback = useCallback(
    (err: ServerError<Store2> | null | typeof ONGOING_RESPONSE, response?: Store2[]) => {
      if (err === ONGOING_RESPONSE) return;
      setStatus(Status.DEFAULT);

      if (err) {
        // handleError(err, setError);
        setDataError((dataErrors) => dataErrors.concat({ date: new Date() }));
        return;
      }

      if (response) {
        setStoresState(response);
        setDataSaved((dataSaved) => dataSaved.concat({ date: new Date() }));
      }
    },
    [setDataSaved, setDataError, setStoresState]
  );

  const setStoreTerminalForPack = useCallback(
    async (terminalType: TerminalType) => {
      // should only be 1 store for PACK
      const store = storesState[0];
      const terminal = generateNewTerminal(store.storeId, terminalType);
      const updatedStore = { ...store, terminals: [terminal] };
      storeQueue.saveStore(contractId, updatedStore, saveStoreCallback);
    },
    [contractId, saveStoreCallback, storesState]
  );

  const removeDisabledTerminals = useCallback(
    async (disabledTerminals: TerminalType[] | undefined) => {
      if (!disabledTerminals) return;

      storesState.forEach((store) => {
        const newTerminals = store.terminals.filter(
          ({ terminalType }) => !disabledTerminals.includes(terminalType)
        );

        const shouldUpdate = store.terminals.length !== newTerminals.length;

        if (shouldUpdate) {
          const updatedStore = { ...store, terminals: newTerminals };
          storeQueue.saveStore(contractId, updatedStore, saveStoreCallback);
        }
      });
    },
    [contractId, saveStoreCallback, storesState]
  );

  const templateValidation = useCallback(
    (template: PricingTemplate): { isValid: boolean; message?: string } => {
      const isValid = false;

      const { terminalType, pricingModel, storeLimitation, terminalLimitation, name } = template;

      if (isPack(pricingModel) && storesCount > MAX_STORE_PACK) {
        return { isValid, message: t(PACK_ERROR_MESSAGE) };
      }

      if (storeLimitation && storesCount > storeLimitation) {
        const message = t(
          '"{{ name }}" have a store limitation of {{ storeLimitation }} but found {{ storesCount }}. Please remove additional stores.',
          { name, storeLimitation, storesCount }
        );
        return { isValid, message };
      }

      if (terminalLimitation && terminalsCount > terminalLimitation) {
        const message = t(
          '"{{ name }}" have a terminal limitation of {{ terminalLimitation }} but  found {{terminalsCount}}. Please remove additional terminals.',
          { name, terminalLimitation, terminalsCount }
        );
        return { isValid, message };
      }

      if (isPack(pricingModel)) {
        if (!terminalType) return { isValid, message: t(NO_TERMINAL_TYPE_ERROR_MESSAGE) };
        setStoreTerminalForPack(terminalType);
      }

      return { isValid: true };
    },
    [setStoreTerminalForPack, storesCount, terminalsCount, t]
  );

  const onChangePricePlan = useCallback(
    (selectedTemplateId) => {
      if (status === Status.ERROR) return;

      const activeTemplate = templates.find((t) => t.templateId === Number(selectedTemplateId));
      if (!activeTemplate) return;

      const { isValid, message } = templateValidation(activeTemplate);
      if (!isValid) return showError(message ?? BASE_ERROR_MESSAGE);

      removeDisabledTerminals(activeTemplate.disabledTerminals);

      const pricingStructure = convertTemplateToPricingStructure(pricingStructureId, activeTemplate);
      setContractPricing((prev) => ({
        ...prev,
        [contractTypeTemplatesToLoad]: {
          ...pricingStructure,
          cas: prev[contractTypeTemplatesToLoad].cas,
        },
      }));
    },
    [
      templateValidation,
      templates,
      setContractPricing,
      contractTypeTemplatesToLoad,
      pricingStructureId,
      showError,
      status,
      removeDisabledTerminals,
    ]
  );

  const onChangePriceModel = useCallback(
    (newModel: PricingModel) => {
      if (status === Status.ERROR) return;

      const templateByModel = templateMap[newModel];
      const newTemplate = templateByModel.find((template) => templateValidation(template).isValid);

      if (!newTemplate) {
        return showError(
          t(
            "Could not find a valid {{newModel}} template by the given stores and terminals. Please remove additional stores and terminals",
            { newModel }
          )
        );
      }

      removeDisabledTerminals(newTemplate.disabledTerminals);

      const pricingStructure = convertTemplateToPricingStructure(pricingStructureId, newTemplate);
      setContractPricing((prev) => ({
        ...prev,
        [contractTypeTemplatesToLoad]: {
          ...pricingStructure,
          cas: prev[contractTypeTemplatesToLoad].cas,
        },
      }));
    },
    [
      setContractPricing,
      templateMap,
      contractTypeTemplatesToLoad,
      pricingStructureId,
      templateValidation,
      showError,
      t,
      status,
      removeDisabledTerminals,
    ]
  );

  if (!templates.length) {
    return (
      <NoTemplatesFound
        contractPricingExists={contractPricingExists}
        contractTemplateId={contractTemplateId}
      />
    );
  }

  if (!selectedTemplate && !isMockId) {
    return (
      <SelectedTemplateNotFound
        pricingStructureId={pricingStructureId}
        contractTemplateId={contractTemplateId}
        templateName={name}
        setContractPricing={setContractPricing}
        templateMap={templateMap}
        edit={edit}
        contractTypeTemplatesToLoad={contractTypeTemplatesToLoad}
      />
    );
  }

  return (
    <div className="m-bottom-30">
      <RadioGroup
        alternatives={priceModelAlternatives}
        onChange={onChangePriceModel}
        label={t("Pricing model")}
        value={isMockId ? undefined : pricingModel}
        disabled={!edit}
        validators={[new RequiredValidator(t("Pricing model is required"))]}
        name="pricingModel"
        className="tablet-horizontal m-bottom-30"
      />
      <AnimateHeightMotion presence>
        {status === Status.ERROR && <ErrorBox className="m-bottom-30">{errorMessage}</ErrorBox>}
      </AnimateHeightMotion>
      {!isMockId && (
        <>
          <RadioGroup
            alternatives={pricePlanAlternatives}
            onChange={onChangePricePlan}
            label={t("Price plans")}
            value={contractPricing[contractTypeTemplatesToLoad].templateId}
            disabled={!edit}
            validators={[new RequiredValidator(t("Pricing model is required"))]}
            name="pricingModel"
          />
          {selectedTemplate?.description && (
            <div className={styles.description}>
              <i>{selectedTemplate?.description}</i>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const PACK_ERROR_MESSAGE =
  "Pack pricing models can only have 1 store location. Please remove additional stores.";
const BASE_ERROR_MESSAGE = "Unkown error occured, could not switch price plan";
const NO_TERMINAL_TYPE_ERROR_MESSAGE =
  "No predefined terminal was found for the price plan, which it should have. This should not be able to happen";

const MAX_STORE_PACK = 1;

const generatePriceModelAlternatives = ({
  blendedTemplates,
  icppTemplates,
  packTemplates,
}: {
  blendedTemplates: PricingTemplate[];
  icppTemplates: PricingTemplate[];
  packTemplates: PricingTemplate[];
}) => {
  const alternatives = [];

  if (blendedTemplates.length > 0) {
    alternatives.push({
      text: `${getPricingModelDisplayName(PricingModel.BLENDED)}`,
      value: PricingModel.BLENDED,
    });
  }

  if (icppTemplates.length > 0) {
    alternatives.push({
      text: `${getPricingModelDisplayName(PricingModel.ICPP)}`,
      value: PricingModel.ICPP,
    });
  }

  if (packTemplates.length > 0) {
    alternatives.push({
      text: `${getPricingModelDisplayName(PricingModel.PACK)}`,
      value: PricingModel.PACK,
    });
  }

  return alternatives;
};

const sortByTerminalTypeAndThreshold = (a: PricingTemplate, b: PricingTemplate) => {
  if (!a.terminalType || !b.terminalType) return 0;
  if (a.terminalType < b.terminalType) return -1;
  if (a.terminalType > b.terminalType) return 1;

  if (!a.monthlyTurnoverThreshold || !b.monthlyTurnoverThreshold) return 0;
  if (a.monthlyTurnoverThreshold < b.monthlyTurnoverThreshold) return -1;
  if (a.monthlyTurnoverThreshold > b.monthlyTurnoverThreshold) return 1;

  return 0;
};

// TODO: should find better parameter to sort by
const sortByName = (a: PricingTemplate, b: PricingTemplate) => {
  const nameA = a.name;
  const nameB = b.name;

  // Extract numeric values from the names
  const matchA = nameA.match(/\d+/);
  const matchB = nameB.match(/\d+/);

  // Comparing numbers is needed because otherwise EUR 100k would come before EUR 10k.
  // And we don't have any other parameters to sort by
  const numA = matchA ? parseFloat(matchA[0]) : NaN;
  const numB = matchB ? parseFloat(matchB[0]) : NaN;

  // If both names contain numbers, compare them as numbers
  if (!isNaN(numA) && !isNaN(numB)) {
    return numA - numB;
  }

  // If one or both names don't have numbers, use lexicographic comparison
  return nameA.localeCompare(nameB);
};
