import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TFunction, Trans, useTranslation } from "react-i18next";
import { generatePath, useHistory } from "react-router-dom";
import {
  ContractCreationStatus,
  LookupPollResult,
  LookupStatus,
  dataContracts,
} from "../../data/dataContracts";
import { Country } from "../../model/common/commonType";
import { ContractFeature, ContractType, Validity } from "../../model/contract/contractType";
import { getContractTypeDisplayString } from "../../model/contract/contractUtils";
import { Status } from "../../data/types";
import { CONTRACT_ROUTE } from "../../pages/Contract/ContractPage";
import { AnimateHeight } from "../animate/AnimateHeight";
import { Beacon } from "../beacon/Beacon";
import { ErrorBox } from "../boxes/ErrorBox";
import { SuccessBox } from "../boxes/SuccessBox";
import { WarningBox } from "../boxes/WarningBox";
import { Form } from "../form/Form";
import { TextInput } from "../form/TextInput";
import { BaseValidator } from "../form/validators/BaseValidator";
import { IcoValidator } from "../form/validators/IcoValidator";
import { NipValidator } from "../form/validators/NipValidator";
import { OibOrMboValidator } from "../form/validators/OibOrMboValidator";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { Pending } from "../icons/Pending";
import { Button } from "../interactions/Buttons/Button";
import { Link } from "../links/Link";
import { NewOverlay } from "../overlay/NewOverlay";
import { T } from "../translation/T";
import { PlusIcon } from "../icons/PlusIcon";

import useGeneralData from "../../hooks/useGeneralData";
import { Checkboxes } from "../interactions/Checkboxes/Checkboxes";
import { Alternative } from "../interactions/InputTypes";
import { ArrowRightIcon } from "../icons/ArrowRightIcon";

import styles from "./NewContractOverlay.module.scss";

export const NewContractOverlay: React.FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState<string>("");
  const [status, setStatus] = useState<RegistrationStatus>(Status.DEFAULT);
  const [lookupData, setLookupData] = useState<Partial<LookupPollResult>>({});
  const [redirectTime, setRedirectTime] = useState<number>(5);
  const [contractType, setContractType] = useState<ContractType[]>([ContractType.INSTORE]);

  const { country, ecomFeatureIsEnabled } = useGeneralData();
  const { t } = useTranslation();

  const ENABLED_FEATURES = useMemo(
    () =>
      country !== Country.CZECHIA ? [ContractFeature.ADVANCED_PRICING] : [ContractFeature.ADVANCED_PRICING],
    [country]
  );

  const history = useHistory();
  const timer = useRef<number>();
  const start = useRef<Date>();
  const resumedContractId = useRef<string>("");
  const orgIdentifierValidator = useMemo(() => REGISTRATION_VALIDATOR_MAP[country](t), [country, t]);
  const { contractLookupPoll, newContract, contractLookup } = dataContracts;

  const reset = useCallback(() => {
    setStatus(Status.DEFAULT);
    setId("");
    setLookupData({});
    resumedContractId.current = "";
    window.clearTimeout(timer.current);
  }, []);

  const redirect = useCallback(() => {
    if (!lookupData.contractId) return;
    history.push(generatePath(CONTRACT_ROUTE, { id: lookupData.contractId }));
  }, [history, lookupData.contractId]);

  useEffect(() => {
    if (isOpen) return;
    reset();
  }, [isOpen, reset]);

  useEffect(() => {
    const timerRef = timer.current;
    return () => window.clearTimeout(timerRef);
  }, []);

  useEffect(() => {
    if (lookupData.status !== LookupStatus.COMPLETE) return;
    if (showWarning(lookupData)) return;
    if (redirectTime === 0) return redirect();
    timer.current = window.setTimeout(() => setRedirectTime(redirectTime - 1), 1000);
  }, [redirectTime, redirect, lookupData]);

  const poll = useCallback(async () => {
    try {
      const lookupData = await contractLookupPoll(id);
      if (lookupData.status === LookupStatus.PENDING) {
        timer.current = window.setTimeout(poll, 3000);
      }

      setLookupData(lookupData);
      setStatus(lookupData.status);
    } catch (err) {
      setStatus(registraionLookupStatus.FAILED);
    }
  }, [id, contractLookupPoll]);

  const manual = useCallback(async () => {
    try {
      setStatus(registraionLookupStatus.PENDING);
      const { contractId } = await newContract(id, contractType, ENABLED_FEATURES);
      window.clearTimeout(timer.current);
      history.push(generatePath(CONTRACT_ROUTE, { id: contractId }));
    } catch (e) {
      timer.current = window.setTimeout(() => setStatus(registraionLookupStatus.FAILED), 2000);
    }
  }, [id, history, newContract, contractType, ENABLED_FEATURES]);

  const register = useCallback(async () => {
    try {
      setStatus(registraionLookupStatus.PENDING);
      const lookupResp = await contractLookup(id, ENABLED_FEATURES, contractType);
      start.current = new Date();

      if (lookupResp.status === ContractCreationStatus.NEW) {
        return (timer.current = window.setTimeout(poll, 3000));
      }

      resumedContractId.current = lookupResp.contractId || "";
      timer.current = window.setTimeout(() => setStatus(lookupResp.status), 1000);
    } catch (e) {
      setStatus(registraionLookupStatus.FAILED);
    }
  }, [poll, id, contractLookup, contractType, ENABLED_FEATURES]);

  const retry = useCallback(() => {
    setStatus(registraionLookupStatus.PENDING);
    setLookupData({});
    register();
  }, [register]);

  const onSelectContractType = (values: string[]) => {
    setContractType(values as ContractType[]);
  };

  const isPendingOrCompleted =
    status === registraionLookupStatus.PENDING || status === registraionLookupStatus.COMPLETE;

  return (
    <>
      <Button className="block" onClick={() => setIsOpen(true)} variant="CTA">
        {t("New contract")} <PlusIcon style={{ marginLeft: 8 }} />
      </Button>
      <NewOverlay
        widthSize="small"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        disableClose={isPendingOrCompleted}
        noPadding
      >
        <div className={styles.header}>
          <h4 style={{ opacity: isPendingOrCompleted ? 0.5 : 1 }}>{t("New contract")}</h4>
          <p style={{ opacity: isPendingOrCompleted ? 0.5 : 1, margin: 0 }} className="fw-500">
            {t(
              "First, in order for us to prefill as much information as possible, enter the unique id of the company"
            )}
          </p>
        </div>
        <Form
          className={styles.form}
          onSubmit={(_, formRef) => {
            if (formRef.isValid) {
              register();
            }
          }}
        >
          <AnimateHeight name={status}>
            <div>
              {status === registraionLookupStatus.FAILED && (
                <>
                  {ecomFeatureIsEnabled && (
                    <Checkboxes
                      className="horizontal m-bottom-30"
                      onChange={onSelectContractType}
                      name="contract-type"
                      label={t("Contract type")}
                      alternatives={contractTypeAlts}
                      values={contractType}
                      validators={[new RequiredValidator(t("Contract type is required"))]}
                    />
                  )}
                  <TextInput
                    name="new-org-identifier"
                    onChange={setId}
                    label={t(COUNTRY_ID_LABEL[country])}
                    validators={[new RequiredValidator(t("Please enter a valid id")), orgIdentifierValidator]}
                    value={id}
                  />

                  <div className="m-top-20">
                    <ErrorBox relative>
                      <b>
                        <T>Oh no!</T>
                      </b>{" "}
                      <T>Something went wrong. Try again?</T>
                    </ErrorBox>
                  </div>
                  <Button onClick={retry} block className="danger-button m-top-20">
                    <T>Retry</T>
                  </Button>
                </>
              )}

              {status === registraionLookupStatus.DEFAULT && (
                <>
                  {ecomFeatureIsEnabled && (
                    <Checkboxes
                      className="horizontal m-bottom-30"
                      onChange={onSelectContractType}
                      name="contract-type"
                      label={t("Contract type")}
                      alternatives={contractTypeAlts}
                      values={contractType}
                      validators={[new RequiredValidator(t("Contract type is required"))]}
                    />
                  )}
                  <TextInput
                    name="new-org-identifier"
                    onChange={setId}
                    label={t(COUNTRY_ID_LABEL[country])}
                    validators={[new RequiredValidator(t("Please enter a valid id")), orgIdentifierValidator]}
                    value={id}
                  />

                  <Button type="submit" className="m-top-30" block data-testid="new-org-submit-button">
                    {t("Go!")}
                  </Button>
                </>
              )}

              {isPendingOrCompleted && (
                <PendingCompletedLookup
                  country={country}
                  id={id}
                  redirect={redirect}
                  redirectTime={redirectTime}
                  lookupData={lookupData}
                  isNotPending={status === registraionLookupStatus.COMPLETE}
                />
              )}

              {status === registraionLookupStatus.NOT_ACTIVE && <NotActiveLookup reset={reset} />}

              {status === registraionLookupStatus.RESUMED && (
                <>
                  <p style={{ display: "inline-block" }}>
                    <div className="m-bottom-10">
                      <T>This company is being worked on.</T>
                    </div>

                    <Link
                      link={generatePath(CONTRACT_ROUTE, {
                        id: resumedContractId.current,
                      })}
                    >
                      <T>Go to contract</T>
                      <ArrowRightIcon style={{ marginLeft: 5 }} />
                    </Link>
                  </p>

                  <Button onClick={reset} block className="m-top-30">
                    <T>Reset</T>
                  </Button>
                </>
              )}

              {status === registraionLookupStatus.ALREADY_BOARDED && <AlreadyBoardedLookup reset={reset} />}

              {status === registraionLookupStatus.OTHER_TEAM && <OtherTeamLookup reset={reset} />}

              {status === registraionLookupStatus.BLOCKED && <BlockedLookup reset={reset} />}

              {status === registraionLookupStatus.NOT_FOUND && (
                <NotFoundLookup reset={reset} manual={manual} />
              )}
            </div>
          </AnimateHeight>
        </Form>
      </NewOverlay>
    </>
  );
};

interface ResetProps {
  reset: () => void;
}

const NotActiveLookup: FC<ResetProps> = ({ reset }) => {
  return (
    <>
      <div className="m-top-20">
        <p>
          <T>
            This company is not active anymore and, therefore, cannot be registered. Perhaps it has closed
            down recently?
          </T>
        </p>
      </div>
      <Button onClick={reset} block className="m-top-20">
        <T>Reset</T>
      </Button>
    </>
  );
};

const AlreadyBoardedLookup: FC<ResetProps> = ({ reset }) => {
  return (
    <>
      <div className="m-top-20">
        <SuccessBox relative>
          <T>This company has already been borded! Try another one?</T>
        </SuccessBox>
      </div>
      <Button onClick={reset} block className="m-top-20">
        <T>Reset</T>
      </Button>
    </>
  );
};

const OtherTeamLookup: FC<ResetProps> = ({ reset }) => {
  return (
    <>
      <div className="m-top-20">
        <p>
          <T>This company is being worked on by another team. Try another one?</T>
        </p>
      </div>
      <Button onClick={reset} block className="m-top-20">
        <T>Reset</T>
      </Button>
    </>
  );
};

const BlockedLookup: FC<ResetProps> = ({ reset }) => {
  return (
    <>
      <div className="m-top-20">
        <p>
          <T>This company is blocked for some reason and cannot be registered. Try another?</T>
        </p>
      </div>
      <Button onClick={reset} block className="m-top-20">
        <T>Reset</T>
      </Button>
    </>
  );
};

interface NotFoundProps extends ResetProps {
  manual: () => void;
}

const NotFoundLookup: FC<NotFoundProps> = ({ reset, manual }) => {
  return (
    <>
      <div className="m-top-20">
        <WarningBox relative>
          <T>
            We couldn't find this company in the registry. You may go head and create a contract for this
            company but we won't be able to prefill anything.
          </T>
        </WarningBox>
      </div>
      <Button onClick={manual} block className="m-top-20">
        <T>Create contract</T>
      </Button>
      <Button onClick={reset} block className="ghost m-top-10">
        <T>No, I'll try another one</T>
      </Button>
    </>
  );
};

interface PendingCompletedLookupProps {
  country: Country;
  lookupData: Partial<LookupPollResult>;
  id: string;
  redirect: () => void;
  redirectTime: number;
  isNotPending: boolean;
}

const PendingCompletedLookup: FC<PendingCompletedLookupProps> = ({
  country,
  id,
  lookupData,
  redirect,
  redirectTime,
  isNotPending,
}) => {
  const renderIndicator = useCallback((indicator?: boolean) => {
    if (indicator === false) return <Beacon mini validity={Validity.PARTIAL} />;
    if (indicator) return <Beacon mini validity={Validity.VALID} />;
    return <Pending />;
  }, []);

  return (
    <>
      <dl className="fw-500 ">
        <dt>
          <T>{COUNTRY_ID_TITLE[country]}</T>
        </dt>
        <dd>{id}</dd>
        <dt>
          <T>Company name</T>
        </dt>
        <dd>{lookupData.companyName || <Pending />}</dd>
        <dt>
          <T>Registry lookup</T>
        </dt>
        <dd>{renderIndicator(lookupData.companyLookupDone)}</dd>
        <dt>
          <T>VAT lookup</T>
        </dt>
        <dd>{renderIndicator(lookupData.vatLookupDone)}</dd>
        <>
          <dt>
            <T>Bank account lookup</T>
          </dt>
          <dd>{renderIndicator(lookupData.bankAccountsAvailable)}</dd>
        </>
      </dl>

      {isNotPending && showWarning(lookupData) && (
        <>
          <div className="m-bottom-40">
            <WarningBox relative>
              <T>
                We have created the contract. However, in some registries we didn't find any information. It
                is fine, you can still continue but we couldn't prefill as much as we liked.
              </T>
            </WarningBox>
          </div>

          <Button onClick={redirect} block>
            <T>Go to contract</T>
          </Button>
        </>
      )}

      {isNotPending && !showWarning(lookupData) && (
        <>
          <div className="m-bottom-20">
            <Trans>
              Will redirect to new contract in <b>{{ redirectTime }} seconds</b>
            </Trans>
          </div>

          <Button onClick={redirect} block>
            <T>Redirect now</T>
          </Button>
        </>
      )}
    </>
  );
};

const contractTypeAlts: Alternative<string>[] = [
  {
    text: getContractTypeDisplayString(ContractType.INSTORE),
    value: ContractType.INSTORE,
  },
  {
    text: getContractTypeDisplayString(ContractType.ECOMMERCE),
    value: ContractType.ECOMMERCE,
  },
];

type RegistrationStatus = LookupStatus | Status | ContractCreationStatus;

const registraionLookupStatus = {
  ...Status,
  ...LookupStatus,
  ...ContractCreationStatus,
};

export type RegistrationState = "LOOKUP" | "REGISTER";

const COUNTRY_ID_LABEL: Record<Country, string> = {
  [Country.POLAND]: "Enter NIP to continue",
  [Country.CROATIA]: "Enter OIB / MBO to coninue",
  [Country.CZECHIA]: "Enter IČO to continue",
};

export const COUNTRY_ID_TITLE: Record<Country, string> = {
  [Country.POLAND]: "NIP",
  [Country.CROATIA]: "OIB / MBO",
  [Country.CZECHIA]: "IČO",
};

function showWarning(result: Partial<LookupPollResult>) {
  if (result.bankAccountsAvailable === false) {
    return true;
  }

  if (result.companyLookupDone === false) {
    return true;
  }

  if (result.status === LookupStatus.COMPLETE && result.vatLookupDone === undefined) {
    return true;
  }

  return false;
}

const REGISTRATION_VALIDATOR_MAP: Record<Country, (t: TFunction<"translation">) => BaseValidator> = {
  [Country.POLAND]: (t) => new NipValidator(t("Invalid NIP")),
  [Country.CROATIA]: (t) => new OibOrMboValidator(t("Invalid OIB / MBO"), t("Valid OIB"), t("Valid MBO")),
  [Country.CZECHIA]: (t) => new IcoValidator(t("Invalid IČO")),
};
