import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Fee } from "../../../../../../components/fee/Fee";
import { NewImage } from "../../../../../../components/images/NewImage";
import { TerminalType } from "../../../../../../model/contract/contractType";
import { defaultCurrencyFromCountry } from "../../../../../../i18n";
import { contractStatusState } from "../../../../../../state/contractStatusState";
import "./SummaryDevicePack.scss";
import {
  getTerminalEntityType,
  getTerminalImageData,
  getTerminalTypeString,
} from "../../../../../../model/terminal/terminalUtils";

interface Props {
  type: TerminalType;
  nbrOfTerminals: number;
  pricePerItem?: number;
}

export const SummaryDevicePack: React.FunctionComponent<Props> = ({ type, nbrOfTerminals, pricePerItem }) => {
  const { i18n } = useTranslation();
  const { country } = useRecoilValue(contractStatusState);
  const currency = defaultCurrencyFromCountry(country);

  return (
    <div className="summary-device">
      <NewImage {...getTerminalImageData(type)} />
      <div className="summary-device-info">
        <div className="summary-device-name">{getTerminalTypeString(type)}</div>
        <div className="summary-device-count">&#215; {nbrOfTerminals}</div>
        {pricePerItem && (
          <div className="summary-device-fee">
            <Fee
              lang={i18n.language}
              cost={pricePerItem}
              currency={currency}
              extension={getTerminalEntityType(type)}
              decimals={2}
            />
          </div>
        )}
      </div>
    </div>
  );
};
