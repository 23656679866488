import { DateTime } from "luxon";
import { SessionResponse } from "./data/dataAuth";
import { AnalyticsFilter, FilteredName } from "./data/dataStatistics";
import { ContractStatus } from "./model/contract/contractType";
import { SortDirection } from "./model/Page";
import { PricingView } from "./model/pricing/pricingTypes";

export type SortField = "created" | "lastUpdated";

export interface Filter {
  page: number;
  size?: number;
  status?: ContractStatus;
  adminId?: string;
  sortField?: SortField;
  sortDirection?: SortDirection;
  search?: string;
  datesFormat?: DashboardDatesFormat;
}

const defaultPassSettings = {
  merchandiser: "",
  direction: "",
};

export enum Feature {
  ADVANCED_PRICING = "ADVANCED_PRICING",
  TRANSLATION_MODE = "TRANSLATION_MODE",
  E_COMMERCE = "E_COMMERCE",
}

export interface FeatureInterface {
  [Feature.ADVANCED_PRICING]?: boolean;
  [Feature.TRANSLATION_MODE]?: boolean;
  [Feature.E_COMMERCE]?: boolean;
}

const defaultFeatures: FeatureInterface = {
  [Feature.ADVANCED_PRICING]: false,
  [Feature.TRANSLATION_MODE]: false,
  [Feature.E_COMMERCE]: false,
};

export interface PricingSettings {
  view: PricingView;
  hidePricingIngress: boolean;
}

export interface PassSettings {
  merchandiser: string;
  direction: string;
}

export enum DashboardDatesFormat {
  RELATIVE_DATES = "RELATIVE_DATES",
  ABSOLUTE_DATES = "ABSOLUTE_DATES",
}

export interface DashboardSettings {
  dateFormat: DashboardDatesFormat;
}

export const defaultFilter: Filter = {
  page: 0,
  size: 15,
};

export const defaultAnalyticsFilter: AnalyticsFilter = {
  from: "2022-01-01",
  to: DateTime.now().toISODate(),
  filteredName: FilteredName.DATE,
};

export enum Theme {
  LIGHT = "light-theme",
  DARK = "dark-theme",
}

export enum STORE_KEY {
  STORE_THEME = "store.ui.theme",
  STORE_LOGIN_EMAIL = "store.auth.login.email",
  STORE_USER = "store.auth.user",
  STORE_DASHBOARD_FILTER = "store.dashboard.filter",
  STORE_ANALYTICS_FILTER = "store.analytics.filter",
  STORE_PASS_SETTINGS = "store.pass",
  STORE_FEATURES = "store.features",
  STORE_FEE_TEMPLATES = "store.fee.templates",
  STORE_PRICING_SETTINGS = "store.pricing",
  STORE_DASHBOARD_SETTINGS = "store.dashboard",
}

interface StoredData {
  [STORE_KEY.STORE_THEME]: Theme;
  [STORE_KEY.STORE_LOGIN_EMAIL]: string;
  [STORE_KEY.STORE_DASHBOARD_FILTER]: Filter;
  [STORE_KEY.STORE_ANALYTICS_FILTER]: AnalyticsFilter;
  [STORE_KEY.STORE_USER]: SessionResponse | null;
  [STORE_KEY.STORE_PASS_SETTINGS]: PassSettings;
  [STORE_KEY.STORE_FEATURES]: FeatureInterface;
  [STORE_KEY.STORE_PRICING_SETTINGS]: PricingSettings;
  [STORE_KEY.STORE_DASHBOARD_SETTINGS]: DashboardSettings;
}

const NAME = "wlx";

const defaultValues = {
  [STORE_KEY.STORE_THEME]: Theme.LIGHT,
  [STORE_KEY.STORE_LOGIN_EMAIL]: "",
  [STORE_KEY.STORE_DASHBOARD_FILTER]: { ...defaultFilter },
  [STORE_KEY.STORE_ANALYTICS_FILTER]: { ...defaultAnalyticsFilter },
  [STORE_KEY.STORE_USER]: null,
  [STORE_KEY.STORE_PASS_SETTINGS]: { ...defaultPassSettings },
  [STORE_KEY.STORE_FEATURES]: { ...defaultFeatures },
  [STORE_KEY.STORE_FEE_TEMPLATES]: {
    selected: "",
    templates: [],
  },
  [STORE_KEY.STORE_PRICING_SETTINGS]: {
    view: PricingView.GROUP,
    hidePricingIngress: false,
  },
  [STORE_KEY.STORE_DASHBOARD_SETTINGS]: {
    dateFormat: DashboardDatesFormat.RELATIVE_DATES,
  },
};

let storedData: StoredData = {
  ...defaultValues,
};
const storedStringData = window.localStorage.getItem(NAME);
if (storedStringData) {
  try {
    storedData = { ...defaultValues, ...JSON.parse(storedStringData) };
  } catch (err) {}
}

export const Store = {
  getValue<T>(key: keyof StoredData) {
    return storedData[key] as unknown as T;
  },

  setValue<Key extends keyof StoredData>(key: Key, value: StoredData[Key]) {
    storedData[key] = value;
    try {
      window.localStorage.setItem(NAME, JSON.stringify(storedData));
    } catch (err) {
      //Do not log
    }
  },

  getStorage() {
    return storedData;
  },
};
