import { ServerError } from "../../network/API";
import { data } from "../data";
import { ONGOING_RESPONSE, QueueCallback } from "./QueueTypes";
import { Cas, ContractId } from "../../model/common/commonType";
import { DCC, PricingStructure } from "../../model/pricing/pricingTypes";

class CasStorage {
  cas?: Cas;

  getCas(pricing: UpdateContractPricingRequest | PricingStructure) {
    return this.cas || pricing.cas || 0;
  }

  setCas(pricing: UpdateContractPricingRequest | PricingStructure) {
    this.cas = pricing.cas as Cas;
  }
}

interface PricingQueueProps {
  contractId: ContractId;
  pricing: UpdateContractPricingRequest;
  callback: QueueCallback<PricingStructure, PricingStructure>;
}

export interface UpdateContractPricingRequest {
  pricingStructureId: number; // 0 if undefined its new
  cas: number;
  templateId: number;
  customized: boolean;
  promotionMonths: number;
  cashlessPromotion: boolean;
  cashBack: number | undefined;
  installationFee: number | undefined;
  acceptance: boolean;
  dcc: DCC;
  installedByTechnician: boolean;
}

class PricingQueue {
  queue: PricingQueueProps[] = [];
  casStorage = new CasStorage();
  isRequesting: boolean = false;

  savePricing(
    contractId: ContractId,
    pricing: UpdateContractPricingRequest,
    callback: QueueCallback<PricingStructure, PricingStructure>
  ) {
    // We have an ongoing request.
    if (this.isRequesting) {
      // 2. Add to queue
      this.queue.push({
        pricing,
        contractId,
        callback,
      });
      return;
    }

    // Nothing in queue. Request immediately
    if (!this.queue.length) {
      this.postPricing(contractId, pricing, callback);
      return;
    }

    // We probably will never end up here? We are either
    // requesting or adding to an empty queue. But well,
    // we'll deal with it anyway
    const next = this.queue.pop();
    if (next) {
      this.postPricing(next.contractId, next.pricing, next.callback);
    }
  }

  postPricing(
    contractId: ContractId,
    pricing: UpdateContractPricingRequest,
    callback: (
      error: ServerError<PricingStructure> | null | typeof ONGOING_RESPONSE,
      response?: PricingStructure
    ) => void
  ) {
    this.isRequesting = true;

    data
      .post<PricingStructure>(`/api/sales/contract/${contractId}/pricing`, {
        ...pricing,
        cas: this.casStorage.getCas(pricing),
      })
      .then((response) => {
        this.casStorage.setCas(response);
        const hasRecentUpdate = !!this.queue.length;
        this.isRequesting = false;

        // Are there unsaved store updates in queue?
        const next = this.queue.pop();
        if (next) {
          this.postPricing(next.contractId, next.pricing, next.callback);
        }

        // If this store is buffered, resolve with placeholder
        // for now and wait for more recent promises to resolve
        hasRecentUpdate ? callback(ONGOING_RESPONSE) : callback(null, response);
      })
      .catch((err) => {
        const hasRecentUpdate = !!this.queue.length;
        this.isRequesting = false;

        // Are there unsaved store updates in queue?
        const next = this.queue.pop();
        if (next) {
          this.postPricing(next.contractId, next.pricing, next.callback);
        }

        // If this store is buffered, disregard error and
        // resolve with placeholder for now and wait for
        // more recent promises to resolve.
        hasRecentUpdate ? callback(ONGOING_RESPONSE) : callback(err);
      });
  }
}

export const pricingQueue = new PricingQueue();
