import React, { FunctionComponent } from "react";
import { Language } from "../../../../../i18n";
import "./IMessage.scss";

interface Props {
  language: Language;
  message: string;
  originalText?: string;
}

export const IMessage: FunctionComponent<Props> = ({
  language,
  message,
  originalText,
}) => {
  const languageNames = new (Intl as any).DisplayNames(language, {
    type: "language",
  });

  return (
    <div className="imessage">
      {originalText && (
        <React.Fragment>
          <span className="language-label">{languageNames.of("en")}</span>
          <pre className="from-them">{originalText}</pre>
        </React.Fragment>
      )}
      <span className="language-label">{languageNames.of(language)}</span>
      <pre className="from-them">{message}</pre>
    </div>
  );
};
