import React from "react";
import { ContractStatusState } from "../../../state/contractStatusState";
import { Status } from "../../../data/types";
import { DateTime, Interval } from "luxon";
import { TeamMember } from "../../../data/dataSales";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/interactions/Buttons/Button";
import { matchPath, useLocation } from "react-router-dom";
import { CONTRACT_ROUTE } from "../ContractPage";
import styles from "./Claim.module.scss";

interface Props {
  contractStatus: ContractStatusState;
  currentMember?: TeamMember;
  onClick: () => void;
  status: Status;
}

export const NotAllowed: React.FunctionComponent<Props> = ({
  contractStatus,
  currentMember,
  onClick,
  status,
}) => {
  const { t } = useTranslation();
  let ago = "";
  const { pathname } = useLocation();

  const isContractPage = !!matchPath(pathname, {
    path: CONTRACT_ROUTE,
  });

  if (contractStatus.lastUpdated) {
    const now = DateTime.now();
    const updated = DateTime.fromISO(contractStatus.lastUpdated);
    const interval = Interval.fromDateTimes(updated, now);
    const days = interval.length("days");
    const hours = interval.length("hours");
    if (days > 1) {
      ago = t(`It was edited {{count}} day ago.`, {
        count: Math.floor(days),
      });
    } else if (hours > 1) {
      ago = t(`It was edited {{count}} hour ago.`, {
        count: Math.floor(hours),
      });
    } else {
      ago = t(`It was edited {{count}} minute ago.`, {
        count: Math.floor(interval.length("minutes")),
      });
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.eyebrow} />
      <h4 className={styles.title}>{t("Heads up")} ✋</h4>
      <p className={styles.text}>
        {t("This contract has already been worked on by")}{" "}
        {currentMember ? (
          <i>{currentMember.name}</i>
        ) : (
          <>
            {t("user")}: <i>{contractStatus.currentSalesUser}</i>
          </>
        )}
        . {ago}
      </p>
      <Button block onClick={onClick} status={status}>
        {isContractPage ? t("Claim and edit") : t("Claim and confirm")}
      </Button>
    </div>
  );
};
