import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FeeInputValidator } from "../../../../components/form/validators/FeeInputValidator";
import { MaxValidator } from "../../../../components/form/validators/MaxValidator";
import { MinValidator } from "../../../../components/form/validators/MinValidator";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { getCurrencySymbol, getIntlCost } from "../../../../components/utils";
import { NumberInput } from "../../../../components/form/NumberInput";
import {
  OptionalTransactionFee,
  FeeType,
  PricingModel,
  CardBrand,
} from "../../../../model/pricing/pricingTypes";
import { defaultCurrencyFromCountry, Language } from "../../../../i18n";

import { Country } from "../../../../model/common/commonType";
import { getFeeBoundValues } from "../../../../model/pricing/feeBoundUtils";

interface Props {
  item: OptionalTransactionFee;
  feeType: FeeType;
  required: boolean;
  active?: boolean;
  additionalBrands?: CardBrand[];
  pricingModel: PricingModel;
  updateFeeInput: (args: UpdateFeeInputArgs) => void;
  country: Country;
}

export const FeeInput: FC<Props> = ({
  item,
  feeType,
  required,
  active = true,
  additionalBrands,
  pricingModel,
  updateFeeInput,
  country,
}) => {
  const { i18n, t } = useTranslation();

  const lang = i18n.language as Language;
  const currency = defaultCurrencyFromCountry(country);

  const feeBounds = getFeeBoundValues({ pricingModel, cardBrand: item.brand, feeType });
  if (typeof feeBounds === "undefined") return null;

  const name = `${feeType}-cost-${item.brand}`;
  const value = item[feeType];
  const label = t(getLabel(feeType));

  let message;
  let errorMin;
  let errorMax;
  let hint;
  let validators = [];

  if (feeType === FeeType.TRANSACTION) {
    hint = t("From {{from}}% to {{to}}%", {
      from: getIntlNumberFormat(lang, feeBounds.min, 2),
      to: getIntlNumberFormat(lang, feeBounds.max, 2),
    });
    message = hint;
    errorMin = t("Minimum value is {{min}}%", {
      min: getIntlNumberFormat(lang, feeBounds.min, 2),
    });
    errorMax = t("Maximum value is {{max}}%", {
      max: getIntlNumberFormat(lang, feeBounds.max, 2),
    });
  } else {
    hint = t("From {{from}} to {{to}}", {
      from: getIntlCost(lang, feeBounds.min, currency, 2),
      to: getIntlCost(lang, feeBounds.max, currency, 2),
    });
    message = hint;
    errorMin = t("Minimum value is {{min}}", {
      min: getIntlCost(lang, feeBounds.min, currency, 2),
    });
    errorMax = t("Maximum value is {{max}}", {
      max: getIntlCost(lang, feeBounds.max, currency, 2),
    });
  }

  if (required) {
    validators.push(new RequiredValidator(t("Fee is required")));
  }
  validators.push(new FeeInputValidator(t("Fee is incorrectly formatted")));
  validators.push(new MinValidator(feeBounds.min, errorMin));
  validators.push(new MaxValidator(feeBounds.max, errorMax));

  return (
    <NumberInput
      disabled={!active}
      label={label}
      name={name}
      value={value ? Number(value) : undefined}
      onChange={(value) => {
        return updateFeeInput({ value: value?.toString() ?? "", item, feeType, additionalBrands });
      }}
      placeholder={"-"}
      message={message}
      validators={validators}
      suffix={feeType === FeeType.TRANSACTION ? "%" : undefined}
      prefix={feeType !== FeeType.TRANSACTION ? `${getCurrencySymbol(lang, currency)} ` : undefined}
    />
  );
};

export type UpdateFeeInputArgs = {
  value: string;
  feeType: FeeType;
  item: OptionalTransactionFee;
  additionalBrands?: CardBrand[]; // If additionalBrands is not provided, the fee will be updated the current brand only
};

export interface GetUpdatedFeesArgs extends UpdateFeeInputArgs {
  transactionFees: OptionalTransactionFee[];
}

export function getUpdatedFees(updateFeeArgs: GetUpdatedFeesArgs) {
  const { value, feeType, item, transactionFees, additionalBrands } = updateFeeArgs;

  const brandsToUpdate = additionalBrands
    ? [item.brand, ...additionalBrands.filter((brand) => brand !== item.brand)]
    : [item.brand];

  return transactionFees.map((transaction) => {
    if (brandsToUpdate.includes(transaction.brand)) {
      return {
        brand: transaction.brand,
        [FeeType.TRANSACTION]: feeType === FeeType.TRANSACTION ? value : transaction[FeeType.TRANSACTION],
        [FeeType.MIN]: feeType === FeeType.MIN ? value : transaction[FeeType.MIN],
        [FeeType.FIXED]: feeType === FeeType.FIXED ? value : transaction[FeeType.FIXED],
        [FeeType.MAX]: feeType === FeeType.MAX ? value : transaction[FeeType.MAX],
        tariffClass: transaction.tariffClass,
      };
    }

    return transaction;
  });
}

export const updateTariffClassGroupedFees = ({
  value,
  additionalBrands,
  transactionFees,
}: {
  value: number;
  additionalBrands: CardBrand[];
  transactionFees: OptionalTransactionFee[];
}) => {
  return transactionFees.map((fee) => {
    const shouldBeUpdated = additionalBrands.includes(fee.brand);
    return shouldBeUpdated ? { ...fee, tariffClass: value } : fee;
  });
};

export const updateTariffClassInvidiualFee = ({
  value,
  transactionFees,
  cardBrand,
}: {
  value: number;
  cardBrand: CardBrand;
  transactionFees: OptionalTransactionFee[];
}) => {
  return transactionFees.map((fee) => {
    const shouldBeUpdated = fee.brand === cardBrand;
    return shouldBeUpdated ? { ...fee, tariffClass: value } : fee;
  });
};

function getLabel(feeType: FeeType) {
  if (feeType === FeeType.FIXED) {
    return "Fixed fee per trx.";
  }

  if (feeType === FeeType.TRANSACTION) {
    return "Fee per trx. (%)";
  }

  if (feeType === FeeType.MAX) {
    return "Max fee per trx.";
  }

  return "Min fee per trx.";
}

export function getIntlNumberFormat(lang: Language, value: number, digits?: number) {
  return new Intl.NumberFormat(lang, {
    minimumFractionDigits: digits || 2,
    maximumFractionDigits: digits || 2,
  }).format(value);
}
