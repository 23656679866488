import { BaseValidator } from "./BaseValidator";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { validateICO } from "../../CompanyIdUtils";

export class IcoValidator extends BaseValidator {
  name = "ICO validator";

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (validateICO(value)) {
        resolve({
          status: VALIDATION_STATE.SUCCESS,
        });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
          hideError: value.replaceAll("-", "").length < 8,
        });
      }
    });
  }
}
