import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/interactions/Buttons/Button";
import { Input } from "../../components/interactions/Input/Input";
import { dataAuth, SessionResponse } from "../../data/dataAuth";
import { Status } from "../../data/types";
import { useIsMountedRef } from "../../hooks/useIsMounted";

interface Props {
  onLogin: (sessionResponse: SessionResponse) => void;
  requestId?: string;
  onReset: () => void;
}

function getPotentialError(t: any, otp?: string) {
  if (!otp) {
    return t("A code has to be supplied");
  }
  return "";
}

export const LoginOtp: React.FunctionComponent<Props> = ({ onLogin, requestId, onReset }) => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState<string>();
  const [otpStatus, setOtpStatus] = useState<Status>(Status.DEFAULT);
  const [otpError, setOtpError] = useState<ReactNode>();
  const statusRef = useRef<Status>(Status.DEFAULT);
  const mounted = useIsMountedRef();
  const formRef = useRef<HTMLFormElement>(null);

  const updateStatus = useCallback((statusValue: Status) => {
    statusRef.current = statusValue;
    setOtpStatus(statusValue);
  }, []);

  const onOtpSubmit = useCallback(
    (ev) => {
      ev.preventDefault();
      updateStatus(Status.PENDING);

      const error = getPotentialError(t, otp);
      if (error) {
        updateStatus(Status.ERROR);
        setOtpError(error);
        return;
      }

      dataAuth
        .postOtp(requestId || "", otp?.trim() || "")
        .then(onLogin)
        .catch(() => {
          updateStatus(Status.ERROR);
          setOtpError(t("Ooh no! Something bad happened. Try again?"));

          setTimeout(() => {
            if (!mounted.current) {
              return;
            }

            if (statusRef.current !== Status.ERROR) {
              return;
            }

            updateStatus(Status.DEFAULT);
          }, 4000);
        });
    },
    [requestId, otp, onLogin, t, updateStatus, mounted]
  );

  const onBlur = useCallback(() => {
    const error = getPotentialError(t, otp);
    if (error) {
      updateStatus(Status.ERROR);
      setOtpError(error);
    }
  }, [otp, t, updateStatus]);

  const onOtpChange = useCallback(
    (val) => {
      updateStatus(Status.DEFAULT);
      setOtp(val.trim());
    },
    [updateStatus]
  );

  useEffect(() => {
    if (!formRef.current) {
      return;
    }

    const elem = formRef.current.querySelector("[name='input-login-otp']");
    if (elem) {
      setTimeout(() => {
        (elem as HTMLInputElement).focus();
      }, 900);
    }
  }, []);

  if (!requestId) {
    onReset();
  }

  return (
    <form onSubmit={onOtpSubmit} ref={formRef}>
      <p>
        <b>{t("Perfect!")}</b> {t("A login code was sent to your email")}
      </p>
      <Input
        name="input-login-otp"
        label={t("Enter code")}
        hint={t("Please check your mail for the login code")}
        value={otp}
        onChange={onOtpChange}
        status={otpStatus}
        message={otpError}
        onBlur={onBlur}
        autocomplete="off"
      />
      <div className="m-top-30">
        <Button block type="submit" status={otpStatus}>
          {t("Submit code")}
        </Button>
        <div className="m-top-10"></div>
        <Button block ghost onClick={onReset}>
          {t("Reset")}
        </Button>
      </div>
    </form>
  );
};
