import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useState, FC } from "react";
import { TextInput } from "../../../components/form/TextInput";
import { COUNTRY_ID_TITLE } from "../../../components/NewOrganisationOverlay/NewContractOverlay";
import DashboardActiveFilter from "../DashboardActiveFilter/DashboardActiveFilter";
import DashboardFilterDropdown, {
  FilterAlternative,
} from "../DashboardFilterDropdown/DashboardFilterDropdown";
import { StatusFilter } from "../StatusFilter/StatusFilter";
import { DashboardDatesFormat, Filter } from "../../../Store";
import { SessionResponse } from "../../../data/dataAuth";
import { DASHBOARD_ROUTE } from "../DashboardPage";
import { useHistory, useLocation } from "react-router";
import { useRecoilValue } from "recoil";
import { userState } from "../../../state/userState";
import { useDebounceFn } from "../../../hooks/useDebounce";
import { FilterUtils } from "../../../model/Filter";
import { useTranslation } from "react-i18next";
import DashboardSettingsDropdown from "../DashboardSettingsDropdown/DashboardSettingsDropdown";
import { getContractStatusDisplayString } from "../../../components/contractStatus/ContractStatus";
import { MdOutlineFilterList } from "react-icons/md";

enum FilterKeys {
  MY_CONTRACTS_FILTER = "MY_CONTRACTS_FILTER",
  CONTRACT_STATUS_FILTER = "CONTRACT_STATUS_FILTER",
}

interface Props {
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  dateFormat: DashboardDatesFormat;
  onChangeDateFormat: (newFormat: DashboardDatesFormat) => void;
}

const ContractTableFilters: FC<Props> = ({ pageSize, onChangePageSize, dateFormat, onChangeDateFormat }) => {
  const { search, pathname } = useLocation();
  const filter: Filter = useMemo(() => FilterUtils.searchToFilter(search), [search]);
  const { t } = useTranslation();
  const history = useHistory();

  const user = useRecoilValue(userState);
  const [searchValue, setSearchValue] = useState<string>(filter.search ?? "");
  const [dropdownFilterValues, setDropDownFilterValues] = useState<FilterAlternative[]>([]);

  const MY_CONTRACT_FILTER_ALT: FilterAlternative = useMemo(
    () => ({
      id: FilterKeys.MY_CONTRACTS_FILTER,
      label: getFilterKeysDisplayString(FilterKeys.MY_CONTRACTS_FILTER),
      onClick: () => history.push(getOwnContractsRoute(user, search)),
      onRemove: () => history.push(onRemoveOwnContractParams(search)),
    }),
    [history, user, search]
  );

  useEffect(() => {
    const activeFilters: FilterAlternative[] = [];

    const isMyContracts = search.includes("adminId");
    if (isMyContracts) activeFilters.push(MY_CONTRACT_FILTER_ALT);

    setDropDownFilterValues(activeFilters);
  }, [pathname, search, MY_CONTRACT_FILTER_ALT]);

  const handleSearch = (value: string) => {
    if (filter.search === value) return;

    const params = FilterUtils.getParams({
      ...filter,
      search: value,
      page: !filter.search ? 0 : filter.page,
    });

    history.replace({ pathname, search: params.toString() });
  };

  const debounceSearchValue = useDebounceFn(handleSearch, 500);

  const userAlts: FilterAlternative[] = [MY_CONTRACT_FILTER_ALT];

  return (
    <>
      <StatusFilter filter={filter} />
      <div className="filter-view-filter-row m-bottom-10">
        <DashboardFilterDropdown
          icon={<MdOutlineFilterList />}
          labelText="Filters"
          alternatives={userAlts}
          values={dropdownFilterValues}
        />
        <AnimatePresence>
          {filter.status && (
            <motion.div
              layout
              key={"contract-filter-status"}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
            >
              <DashboardActiveFilter
                label={getContractStatusDisplayString(filter.status)}
                onClose={() => history.push(onRemoveContractStatusParam(search))}
              />
            </motion.div>
          )}

          {dropdownFilterValues.map((alt) => (
            <motion.div
              layout
              key={alt.id}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
            >
              <DashboardActiveFilter label={alt.label} onClose={alt.onRemove} />
            </motion.div>
          ))}
        </AnimatePresence>
        <div className="filter-search-wrapper">
          <TextInput
            onChange={(value) => {
              debounceSearchValue(value);
              setSearchValue(value);
            }}
            value={searchValue}
            placeholder={t("Search by {{ field }}", {
              field: user?.country ? COUNTRY_ID_TITLE[user.country] : "organisation number",
            })}
            className="compact no-label"
          />
        </div>
        <DashboardSettingsDropdown
          onChangePageSize={onChangePageSize}
          pageSize={pageSize}
          dateFormat={dateFormat}
          onChangeDateFormat={onChangeDateFormat}
        />
      </div>
    </>
  );
};

export default ContractTableFilters;

const getFilterKeysDisplayString = (filterKeys: FilterKeys) => {
  switch (filterKeys) {
    case FilterKeys.MY_CONTRACTS_FILTER:
      return "My contracts";

    default:
      return filterKeys;
  }
};

function getOwnContractsRoute(user: SessionResponse | null, search: string) {
  if (!user) return DASHBOARD_ROUTE;
  const params = new URLSearchParams(search);
  params.append("adminId", user.userId);
  return `${DASHBOARD_ROUTE}?${params.toString()}`;
}

function onRemoveOwnContractParams(search: string) {
  const params = new URLSearchParams(search);
  params.delete("adminId");
  return `${DASHBOARD_ROUTE}?${params.toString()}`;
}

function onRemoveContractStatusParam(search: string) {
  const params = new URLSearchParams(search);
  params.delete("status");
  return `${DASHBOARD_ROUTE}?${params.toString()}`;
}
