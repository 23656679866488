import { Dispatch, FC, SetStateAction, useMemo, useRef } from "react";
import { AnimateHeight, Speed } from "../../../../../../../components/animate/AnimateHeight";
import { Country } from "../../../../../../../model/common/commonType";
import {
  PricingView,
  PricingModel,
  OptionalTransactionFee,
  EditablePricingTemplate,
  TariffClassProduct,
} from "../../../../../../../model/pricing/pricingTypes";
import { HighlighBoxStyleProps } from "../../../../../../Contract/pricingV2/AdditionalSettings/AdditionalSettings";
import { Group } from "../../../../../../Contract/pricingV2/views/Group";
import { Individual } from "../../../../../../Contract/pricingV2/views/Individual";
import { TransactionFeesHeader } from "./TransactionFeesHeader";

import styles from "./TransactionFeeInputs.module.scss";
import { HiddenInput } from "../../../../../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../../../../../components/form/validators/RequiredValidator";
import { useTranslation } from "react-i18next";

import {
  getUniqueContractRows,
  groupFees,
  hasValidUniqueFeeRows,
  MAXIMUM_UNIQUE_FEE_ROWS,
} from "../../../../../../../model/pricing/transactionFeesUtils";
import { getTariffClassFromBrand } from "../../../../../../../model/pricing/pricingUtils";

interface Props extends HighlighBoxStyleProps {
  pricingView: PricingView;
  pricingModel: PricingModel;
  optionalTransactionFees: OptionalTransactionFee[];
  disabled: boolean;
  setPricingView: (newView: PricingView) => void;
  setEditablePricing?: Dispatch<SetStateAction<EditablePricingTemplate>>;
  country: Country;
  className?: string;
  isSalesComponent?: boolean;
}

const TransactionFeeInputs: FC<Props> = ({
  pricingModel,
  optionalTransactionFees,
  pricingView,
  setEditablePricing,
  setPricingView,
  disabled,
  country,
  className,
  isSalesComponent = false,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const isPack = pricingModel === PricingModel.PACK;

  // unique rows that are based on the pdf template generated in the BE
  const uniqueContractRows = useMemo(
    () => getUniqueContractRows(optionalTransactionFees),
    [optionalTransactionFees]
  );

  // the grouped fees in the UI
  const groupedFees = useMemo(
    () => groupFees(optionalTransactionFees, pricingModel),
    [optionalTransactionFees, pricingModel]
  );

  const hiddenInputValue = hasValidUniqueFeeRows(groupedFees, uniqueContractRows, pricingModel);

  const missingTariffClasses = hasAllTariffClasses(optionalTransactionFees);

  return (
    <div className={className}>
      <div className={styles.content}>
        <AnimateHeight name={pricingView || "unselected"} speed={Speed.SLOW}>
          <>
            <TransactionFeesHeader
              view={pricingView}
              setView={setPricingView}
              pricingModel={pricingModel ?? PricingModel.BLENDED}
            />
            <div className={styles.inputs}>
              {pricingView === PricingView.GROUP ? (
                <Group
                  disabled={disabled}
                  pricingModel={pricingModel ?? PricingModel.BLENDED}
                  transactionFees={optionalTransactionFees}
                  setEditablePricing={setEditablePricing}
                  country={country}
                  isSalesComponent={isSalesComponent}
                />
              ) : (
                <Individual
                  disabled={disabled}
                  pricingModel={pricingModel ?? PricingModel.BLENDED}
                  transactionFees={optionalTransactionFees}
                  setEditablePricing={setEditablePricing}
                  country={country}
                  isSalesComponent={isSalesComponent}
                />
              )}
            </div>
          </>
        </AnimateHeight>
      </div>

      {isSalesComponent && (
        <HiddenInput
          label={t("Tariff classes")}
          value={missingTariffClasses.size > 0 ? undefined : true}
          validators={[
            new RequiredValidator(
              t(
                "The following tariff classes are missing {{ brands }}. Contact your country manager to update the price plan.",
                { brands: Array.from(missingTariffClasses).join(", ") }
              )
            ),
          ]}
          scrollToRef={ref}
          boxVariant
        />
      )}

      <HiddenInput
        label={t("Maximum fee rows")}
        value={hiddenInputValue}
        validators={[new RequiredValidator(isPack ? PACK_ERROR_MSG : BUNDLE_ERROR_MSG)]}
        scrollToRef={ref}
        boxVariant
      />
    </div>
  );
};

const hasAllTariffClasses = (optionalTransactionFees: OptionalTransactionFee[]) => {
  const missingTariffClasses = new Set<TariffClassProduct>();

  optionalTransactionFees.forEach((fee) => {
    const { brand, tariffClass } = fee;
    if (!tariffClass) {
      missingTariffClasses.add(getTariffClassFromBrand(brand));
    }
  });

  return missingTariffClasses;
};

const BUNDLE_ERROR_MSG = `The maximum amount of unique fee rows is ${MAXIMUM_UNIQUE_FEE_ROWS}. Please delete some before you continue.`;
const PACK_ERROR_MSG = "For pack price plans, you can only have 1 transaction fee for all brands";

export default TransactionFeeInputs;
