import { AnimatePresence } from "framer-motion";
import { FC, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import AnimatedPage from "../../../../../components/animate/FadeAnimation";
import { ButtonToggle } from "../../../../../components/form/ButtonToggle";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import styles from "../../AssociateReview.module.scss";

interface QuestionProps {
  confirm: () => void;
  reject: () => void;
  isCompleted: boolean;
}

const ReviewQuestions: FC<QuestionProps> = ({ confirm, reject, isCompleted }) => {
  const [isClear, setIsClear] = useState<boolean>();
  const [isTampered, setIsTampered] = useState<boolean>();

  const { t } = useTranslation();

  return (
    <div className={styles.rightColumn}>
      <h4>{t("Review questions")}</h4>
      <div className={styles.questions}>
        <ButtonToggle
          label={t("Is the picture in focus and show the face clearly?")}
          helpText={t(
            "The face should be looking into the camera and have nothing obstructing any part of the face"
          )}
          positiveText={t("Yes")}
          negativeText={t("No")}
          value={isCompleted ? true : isClear}
          onChange={setIsClear}
        />

        <ButtonToggle
          label={t("Is this a live selfie?")}
          helpText={t("The picture should not be of another picture or computer screen")}
          positiveText={t("Yes")}
          negativeText={t("No")}
          value={isCompleted ? true : isTampered}
          onChange={setIsTampered}
        />
        <AnimatePresence>
          {!isCompleted && (
            <div className={styles.actions}>
              {isClear === true && isTampered === true && (
                <AnimatedPage>
                  <Button onClick={confirm} block>
                    <Trans>Continue</Trans>
                  </Button>
                </AnimatedPage>
              )}
              {(isClear === false || isTampered === false) && (
                <AnimatedPage>
                  <Button onClick={reject} block className="danger-button">
                    <Trans>Reject selfie</Trans>
                  </Button>
                </AnimatedPage>
              )}
            </div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default ReviewQuestions;
