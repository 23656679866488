import isEqual from "lodash.isequal";
import { useCallback, useRef, useState } from "react";
import { UpdateContractPricingRequest, pricingQueue } from "../../../data/queues/PricingQueue";
import { QueueCallback, ONGOING_RESPONSE } from "../../../data/queues/QueueTypes";
import { PricingStructure, PricingView } from "../../../model/pricing/pricingTypes";
import { ContractSaveError, handleError } from "../ContractPage";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { contractSaveState, contractErrorState } from "../../../state/contractSaveState";
import { contractPricingState } from "../../../state/contractCostState";
import { contractStatusState } from "../../../state/contractStatusState";
import { deepCopy } from "../../../components/utils";

function clearSaveObj(pricingStructure: PricingStructure): Omit<PricingStructure, "cas"> {
  const { cas, ...rest } = pricingStructure;
  return deepCopy(rest);
}

const getRequestFromPricingStructure = (pricingStructure: PricingStructure): UpdateContractPricingRequest => {
  return {
    pricingStructureId: pricingStructure.pricingStructureId,
    cas: pricingStructure.cas,
    templateId: pricingStructure.templateId,
    customized: pricingStructure.customized,
    promotionMonths: pricingStructure.promotionMonths,
    cashlessPromotion: pricingStructure.cashlessPromotion,
    cashBack: pricingStructure.cashBack,
    installationFee: pricingStructure.installationFee,
    acceptance: pricingStructure.acceptance,
    dcc: pricingStructure.dcc,
    installedByTechnician: pricingStructure.installedByTechnician,
  };
};

const useSavePricingStructure = () => {
  const { contractId } = useRecoilValue(contractStatusState);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);
  const [error, setError] = useState<ContractSaveError | null>(null);
  const [contractPricing, setContractPricing] = useRecoilState(contractPricingState);
  const prevSave = useRef<any>(clearSaveObj(contractPricing.INSTORE));

  const setPricingView = useCallback(
    (view: PricingView) => {
      setContractPricing((prev) => ({ ...prev, view }));
    },
    [setContractPricing]
  );

  const savePricingCallback = useCallback<QueueCallback<PricingStructure, PricingStructure>>(
    (err, response) => {
      if (err === ONGOING_RESPONSE || response === undefined) return;

      if (err) {
        handleError(err, setError);
        setDataError((dataErrors) => dataErrors.concat({ date: new Date() }));
        return;
      }

      const contractType = response.contractType;
      setContractPricing((prev) => ({ ...prev, [contractType]: { ...prev[contractType], ...response } }));
      setDataSaved((dataSaved) => dataSaved.concat({ date: new Date() }));
    },
    [setContractPricing, setDataError, setDataSaved]
  );

  const savePricingStructure = useCallback(
    (pricingStructure: PricingStructure) => {
      const newPricingCopy = clearSaveObj(pricingStructure);

      if (isEqual(newPricingCopy, prevSave.current)) {
        return setDataSaved((dataSaved) => dataSaved.concat({ date: new Date() }));
      }
      prevSave.current = clearSaveObj(pricingStructure);
      const updateContractPricingRequest = getRequestFromPricingStructure(pricingStructure);
      pricingQueue.savePricing(contractId, updateContractPricingRequest, savePricingCallback);
    },
    [contractId, savePricingCallback, setDataSaved]
  );

  return {
    savePricingStructure,
    error,
    setError,
    contractPricing,
    setPricingView,
  };
};

export default useSavePricingStructure;
