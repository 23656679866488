import React, { useState, useEffect, useMemo, useRef } from "react";
import cx from "classnames";
import { dataPricing } from "../../../data/dataPricing";
import { PricingModel, PricingTemplate } from "../../../model/pricing/pricingTypes";
import { DynamicOfType } from "../../../data/types";
import { SalesTeam } from "../../../data/UserAdministration";
import { useTranslation } from "react-i18next";
import "./PricingHeatMap.scss";

interface Props {
  teams: SalesTeam[];
}

function lerpColor(a: string, b: string, amount: number) {
  const ah = +a.replace("#", "0x");
  const ar = ah >> 16;
  const ag = (ah >> 8) & 0xff; // eslint-disable-line no-mixed-operators
  const ab = ah & 0xff;
  const bh = +b.replace("#", "0x");
  const br = bh >> 16;
  const bg = (bh >> 8) & 0xff; // eslint-disable-line no-mixed-operators
  const bb = bh & 0xff;
  const rr = ar + amount * (br - ar);
  const rg = ag + amount * (bg - ag);
  const rb = ab + amount * (bb - ab);

  return "#" + (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1);
}

const NAME_WIDTH = 160;
const CELL_WIDTH = 40;

export const PricingHeatMap: React.FunctionComponent<Props> = ({ teams }) => {
  const [pricePlans, setPricePlans] = useState<PricingTemplate[] | null>(null);
  const max = useRef<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    dataPricing
      .loadAdminPresets()
      .then((data) => {
        const sortedArray = [...data];
        sortedArray.sort((a, b) => {
          if (a.pricingModel === PricingModel.BLENDED && a.pricingModel !== b.pricingModel) {
            return -1;
          }

          if (a.pricingModel === PricingModel.ICPP && a.pricingModel !== b.pricingModel) {
            return 1;
          }

          return a.name.localeCompare(b.name);
        });

        setPricePlans(sortedArray);
      })
      .catch(() => {});
  }, []);

  const items = useMemo(() => {
    if (!pricePlans) {
      return {};
    }

    const item: DynamicOfType<any> = {};
    return pricePlans.reduce((acc, curr) => {
      acc[`id_${curr.templateId}`] = 0;
      return acc;
    }, item);
  }, [pricePlans]);

  const rows = useMemo(() => {
    if (!pricePlans) {
      return null;
    }

    return teams.map((team) => {
      const { pricingPresets } = team;

      return (
        <tr key={team.id}>
          <td className="heatmap-row-name truncated">{team.name}</td>
          {pricePlans.map((plan) => {
            const checked = !!pricingPresets.find((preset) => preset.id === plan.templateId);

            if (checked) {
              let checkedItems = (items[`id_${plan.templateId}`] || 0) + 1;
              max.current = Math.max(checkedItems, max.current);
              items[`id_${plan.templateId}`] = checkedItems;
            }

            return (
              <td
                key={plan.templateId}
                className={cx({
                  checked,
                })}
              />
            );
          })}
        </tr>
      );
    });
  }, [teams, pricePlans, items]);

  if (!pricePlans || !pricePlans.length || !teams.length) {
    return null;
  }

  const width = pricePlans.length * CELL_WIDTH + NAME_WIDTH;

  return (
    <div className="pricing-heat-map">
      <h2>{t("Price plans / team")}</h2>
      <table
        style={{
          width,
        }}
      >
        <thead>
          <tr>
            <th className="heatmap-row-name" />
            {pricePlans.map((plan) => {
              return (
                <th key={plan.templateId}>
                  <span className="truncated heatmap-row-price-plan">{plan.name}</span>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
        <tfoot>
          <tr>
            <td className="heatmap-row-name">
              <b>{t("Total usage")}:</b>
            </td>
            {pricePlans.map((plan) => {
              return (
                <td
                  key={plan.templateId}
                  style={{
                    backgroundColor: lerpColor(
                      "#ffffff",
                      "#007b7c",
                      items[`id_${plan.templateId}`] / max.current
                    ),
                  }}
                />
              );
            })}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
