import React, { RefObject, useEffect, useRef, useState, VoidFunctionComponent } from "react";
import { AssociateRow } from "../../../components/associate/AssociateRowAndOverlay/AssociateRow";
import {
  AssociateType,
  UpdateAssociate,
} from "../../../components/associate/AssociateRowAndOverlay/UpdateAssociate";
import { FormContainer } from "../../../components/form/Form";
import { id } from "../../../components/utils";
import { Associate, AssociateRole } from "../../../model/associate/associateTypes";
import { Validity } from "../../../model/contract/contractType";
import { Status } from "../../../data/types";
import { FormName } from "../menus/ContractEditMenu";
import { NewOverlay } from "../../../components/overlay/NewOverlay";

interface Props {
  associate: Associate;
  onToggle: (selected: boolean, toggledAssociate: Associate) => void;
  status: Status;
  initialOpen?: boolean;
  scrollToRef?: RefObject<HTMLElement>;
}

export const SigneesOverlay: VoidFunctionComponent<Props> = ({
  associate,
  onToggle,
  status,
  initialOpen = false,
  scrollToRef,
}) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const formContainer = useRef<FormContainer>();
  const isSignee = associate.roles.indexOf(AssociateRole.SIGNATORY) > -1;
  const formId = useRef(id());
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const onUpdate = () => {
      if (formContainer.current && isFormValid !== formContainer.current.isValid) {
        setIsFormValid(formContainer.current.isValid);
      }
    };

    const container = formContainer.current;
    container?.addListener(onUpdate);
    return () => {
      container?.removeListener(onUpdate);
    };
  }, [isFormValid]);

  return (
    <React.Fragment>
      <AssociateRow
        associate={associate}
        setActiveAssociate={() => setIsOpen(true)}
        status={status}
        toggled={isSignee}
        onToggle={onToggle}
        validity={isSignee ? (formContainer.current?.isValid ? Validity.VALID : Validity.MISSING) : undefined}
      />
      {/* <div className="error-message">
        {formContainer.current?.isValid
          ? null
          : t("One or more fields are invalid")}
      </div> */}

      <NewOverlay
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="overlay-edit"
        disableClose={status !== Status.DISABLED}
        noPadding
      >
        <UpdateAssociate
          status={status}
          associate={associate}
          type={AssociateType.SIGNEE}
          onClose={() => setIsOpen(false)}
          formName={`${FormName.SIGNEE}-${formId.current}`}
          formContainer={formContainer}
          scrollToRef={scrollToRef}
        />
      </NewOverlay>
    </React.Fragment>
  );
};
