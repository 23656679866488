import React, { useEffect, useState } from "react";
import { generatePath, useHistory, useLocation, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Flag } from "../../../../components/flags/Flag";
import { Link } from "../../../../components/links/Link";
import { dataMaps } from "../../../../data/dataMaps";
import { Country } from "../../../../model/common/commonType";
import { PricingModel, PricingTemplate } from "../../../../model/pricing/pricingTypes";
import { useWidth } from "../../../../hooks/useWidth";
import { availablePricingTemplatesState } from "../../../../state/contractCostState";
import { AdminPage } from "../../AdminPage";
import { ADMIN_NEW_PRICING_PAGE } from "./AdminPresetPage/NewAdminPreSetPage";
import { PreSetRow } from "./components/PreSetList/PreSetRow";
import { PresetsLoader } from "./components/PresSetsLoader/PreSetsLoader";
import { Status } from "../../../../data/types";
import "./AdminPricingCountryPage.scss";
import { PresetList } from "./components/PreSetList/PresetList";
import { Tabs } from "../../../../components/tabs/Tabs";
import { useQuery } from "../../../../hooks/useQuery";
import { ContractType } from "../../../../model/contract/contractType";
import { getContractTypeDisplayString } from "../../../../model/contract/contractUtils";

export const ADMIN_PRICING_COUNTRY_PAGE = "/admin/pricing/:country";

export const AdminPricingCountryPage: React.FunctionComponent = () => {
  const [templates, setTemplates] = useRecoilState(availablePricingTemplatesState);
  const [d3, setD3] = useState<Status>(Status.PENDING);
  // const [order] = useState<Order>(Order.NAME);
  // const prevOrder = useRef<Order | null>(null);

  const width = useWidth();
  const { country } = useParams<{ country: Country }>();
  const { contractType } = useQuery<{ contractType: ContractType }>();
  const { pathname } = useLocation();
  const history = useHistory();

  const groupedTemplates = sortTemplates(templates, contractType);

  useEffect(() => {
    if (typeof contractType === "undefined") {
      const newSearchParams = new URLSearchParams({ contractType: ContractType.INSTORE });
      history.replace({ pathname, search: newSearchParams.toString() });
    }
  }, [contractType, history, pathname]);

  useEffect(() => {
    dataMaps
      .initD3()
      .then(() => setD3(Status.SUCCESS))
      .catch(() => setD3(Status.ERROR));
  }, []);

  const changeContractType = (contractType: ContractType) => {
    const newSearchParams = new URLSearchParams({ contractType });
    history.push({ pathname, search: newSearchParams.toString() });
  };

  return (
    <AdminPage title="Country pricing" className="country-pricing-page">
      <h1>
        <Flag lang={country} />
        Price plans
      </h1>

      <div className="m-bottom-40">
        <p className="m-bottom-20">
          Create a new or update an existing price plan. Price plans might be archived at any time, they will
          not affect ongoing contracts part from not being selectable.
        </p>
        <Link
          link={generatePath(ADMIN_NEW_PRICING_PAGE, {
            country,
          })}
          className="as-button block m-bottom-40"
        >
          Create new price plan
        </Link>
        <Tabs
          tabs={[
            {
              value: ContractType.INSTORE,
              text: getContractTypeDisplayString(ContractType.INSTORE),
            },
            {
              value: ContractType.ECOMMERCE,
              text: getContractTypeDisplayString(ContractType.ECOMMERCE),
            },
          ]}
          value={contractType}
          onChange={changeContractType}
        />
      </div>

      <PresetsLoader country={country}>
        <PresetList
          pricingModel={PricingModel.BLENDED}
          preSets={groupedTemplates.blended}
          setPreSets={setTemplates}
          d3={d3}
          width={width}
          country={country}
        />
        <PresetList
          pricingModel={PricingModel.ICPP}
          preSets={groupedTemplates.icpp}
          setPreSets={setTemplates}
          d3={d3}
          width={width}
          country={country}
        />
        <PresetList
          pricingModel={PricingModel.PACK}
          preSets={groupedTemplates.pack}
          setPreSets={setTemplates}
          d3={d3}
          width={width}
          country={country}
        />

        {!!groupedTemplates.archived.length && (
          <>
            <h4 className="m-bottom-20">Archived</h4>
            <ul>
              {groupedTemplates.archived.map((preSet) => {
                return (
                  <li key={`${preSet.pricingModel}${preSet.templateId}`}>
                    <PreSetRow
                      setPreSets={setTemplates}
                      preSet={preSet}
                      country={country}
                      width={width}
                      d3={d3}
                    />
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </PresetsLoader>
    </AdminPage>
  );
};

interface GroupedTemplates {
  blended: PricingTemplate[];
  icpp: PricingTemplate[];
  pack: PricingTemplate[];
  archived: PricingTemplate[];
}

function sortTemplates(preSets: PricingTemplate[], contractType?: ContractType): GroupedTemplates {
  const archived: PricingTemplate[] = [];
  const blended: PricingTemplate[] = [];
  const icpp: PricingTemplate[] = [];
  const pack: PricingTemplate[] = [];

  const filtered = contractType
    ? preSets.filter((plan) => plan.contractType.includes(contractType))
    : [...preSets];

  filtered.forEach((item) => {
    const { disabled, pricingModel } = item;
    if (disabled) archived.push(item);
    else if (pricingModel === PricingModel.BLENDED) blended.push(item);
    else if (pricingModel === PricingModel.ICPP) icpp.push(item);
    else pack.push(item);
  });

  return {
    archived: archived.sort(sortFunc),
    blended: blended.sort(sortFunc),
    icpp: icpp.sort(sortFunc),
    pack: pack.sort(sortFunc),
  };
}

enum Order {
  NAME,
  UPDATED,
}

function sortFunc(a: PricingTemplate, b: PricingTemplate, order?: Order) {
  if (order === Order.UPDATED) {
    if (!b.updated) return -1;

    if (!a.updated) return 1;

    return new Date(a.updated).getTime() - new Date(b.updated).getTime();
  }

  return a.name.localeCompare(b.name);
}
