import { FC, useState } from "react";
import styles from "./DCCSelector.module.scss";
import { Status } from "../../../../data/types";
import { Dropdown } from "../../../../components/interactions/Dropdown/Dropdown";
import { T } from "../../../../components/translation/T";
import { useTranslation } from "react-i18next";
import { availablePricingTemplatesState, contractPricingState } from "../../../../state/contractCostState";
import { useRecoilState, useRecoilValue } from "recoil";
import { ContractType } from "../../../../model/contract/contractType";
import { DCC } from "../../../../model/pricing/pricingTypes";
import { Country } from "../../../../model/common/commonType";

interface Props {
  isEditable: boolean;
  label?: string;
  contractTypeCurr: ContractType;
  country: Country;
}

export const DCCSelector: FC<Props> = ({ isEditable, contractTypeCurr, country }) => {
  const basicDCC: DCC = {
    dcc: 1,
    tariffClass: 1,
  };
  const isAllowedDccSelect = country === Country.POLAND;
  const [dropdownStatus] = useState(Status.DEFAULT);
  const [contractPricing, setContractPricing] = useRecoilState(contractPricingState);
  const { t } = useTranslation();
  const templates = useRecoilValue(availablePricingTemplatesState).filter(
    ({ contractType }) => contractType === contractTypeCurr
  );
  const activeTemplate = templates.find((t) => t.templateId === contractPricing[contractTypeCurr].templateId);
  return (
    <div className={styles.root}>
      <Dropdown
        status={isAllowedDccSelect && isEditable ? dropdownStatus : Status.DISABLED}
        label={t("Dynamic Currency Conversion (DCC)")}
        value={contractPricing[contractTypeCurr].dcc.dcc}
        message={dropdownStatus === Status.ERROR && <T>Could not update monthly cost</T>}
        onChange={(value) => {
          setContractPricing((prev) => ({
            ...prev,
            [contractTypeCurr]: {
              ...prev[contractTypeCurr],
              dcc: activeTemplate?.dcc.find((t) => t.dcc === value),
            },
          }));
        }}
        alternatives={generatePriceAlternatives(activeTemplate ? activeTemplate.dcc : [basicDCC])}
      />
    </div>
  );
};

const generatePriceAlternatives = (dccPriceDetails: DCC[]) =>
  [...dccPriceDetails]
    .sort((a, b) => a.dcc - b.dcc)
    .map((monthlyCost) => ({
      key: monthlyCost.dcc,
      value: monthlyCost.dcc,
      text: `${monthlyCost.dcc.toFixed(1)}%`,
    }));
