import cx from "classnames";
import { useCallback, useEffect, useState } from "react";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { StatusBoxes } from "../../../components/boxes/StatusBoxes";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import { WarningBox } from "../../../components/boxes/WarningBox";
import { Form } from "../../../components/form/Form";
import { TextInput } from "../../../components/form/TextInput";
import { MaxLengthValidator } from "../../../components/form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../../../components/form/validators/MinLengthValidator";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Pending } from "../../../components/icons/Pending";
import { Button } from "../../../components/interactions/Buttons/Button";
import { FormattedDate } from "../../../components/time/FormattedDate";
import { ArchiveDetails, dataAdminCommunication } from "../../../data/dataAdminCommunication";
import { ContractId } from "../../../model/common/commonType";
import { Validity } from "../../../model/contract/contractType";
import { Status } from "../../../data/types";
import { AdminPage } from "../AdminPage";
import "./ArchivePage.scss";

export const ARCHIVE_PAGE = "/admin/archive";

export function ArchivePage() {
  const [id, setId] = useState<ContractId>("" as ContractId);
  const [details, setDetails] = useState<ArchiveDetails>();
  const [online, setOnline] = useState<boolean>();
  const [lookupStatus, setLookupStatus] = useState<Validity>(Validity.DEFAULT);

  const [uploadStatus, setUploadStatus] = useState<Status>(Status.DISABLED);

  useEffect(() => {
    dataAdminCommunication
      .checkArchiving()
      .then((data) => setOnline(data.up))
      .catch((err) => {
        console.log("err", err);
        setOnline(false);
      });
  }, []);

  const search = useCallback(() => {
    setLookupStatus(Validity.DEFAULT);
    setUploadStatus(Status.DISABLED);
    dataAdminCommunication
      .loadContractArchiveDetails(id as ContractId)
      .then((data) => {
        setDetails(data);
        setLookupStatus(data.canBeUploaded ? Validity.VALID : Validity.PARTIAL);
        setUploadStatus(data.canBeUploaded ? Status.DEFAULT : Status.DISABLED);
      })
      .catch((err) => {
        setLookupStatus(Validity.MISSING);
        setUploadStatus(Status.DISABLED);
      });
  }, [id]);

  const upload = () => {
    setUploadStatus(Status.PENDING);
    dataAdminCommunication
      .postArchiving(id)
      .then((success) => {
        setUploadStatus(success ? Status.SUCCESS : Status.ERROR);
      })
      .catch((err) => {
        setUploadStatus(Status.ERROR);
      });
  };

  return (
    <AdminPage title="Archive" className="admin-archive-page">
      <section className="archive-page">
        <article>
          <h1>
            Archiving{" "}
            <span
              className={cx("indicator", {
                online,
                offline: online === false,
              })}
            />
          </h1>

          <div className="m-top-40">
            <AnimateHeight name={online !== undefined ? online.toString() : "empty"}>
              <div>
                {online === undefined && <Pending />}

                {online === false && (
                  <div className="sleeping">
                    <span>😴</span> Service is sleeping...
                  </div>
                )}

                {online === true && (
                  <Form
                    onSubmit={(_, formRef) => {
                      if (formRef.isValid) {
                        search();
                      }
                    }}
                  >
                    <TextInput
                      value={id}
                      onChange={(value) => {
                        setId(value as ContractId);
                        setLookupStatus(Validity.DEFAULT);
                        setUploadStatus(Status.DISABLED);
                      }}
                      label="Upload contract to archive"
                      placeholder="E.g. 'PL-1fb4ca14b52c4a64ac57-4f76375fe04a'"
                      validators={[
                        new RequiredValidator("Contract id is required"),
                        new MinLengthValidator(36, "Contract id seems a litte short"),
                        new MaxLengthValidator(36, "Contract id seems a litte long"),
                      ]}
                    />

                    <div className="tablet-columns">
                      <div>
                        <StatusBoxes status={lookupStatus}>
                          <SuccessBox>
                            <b>Company</b>: <i>{details?.companyName}</i>
                            <br />
                            Contract can be sent to archiving
                            <br />
                            <br />
                            <b>Heads up ✋</b>
                            <br />
                            {details?.uploaded ? (
                              <>
                                This contract has already been archived on{" "}
                                <FormattedDate date={details?.uploaded} />
                              </>
                            ) : null}
                          </SuccessBox>

                          <WarningBox relative>
                            <b>Company</b>: <i>{details?.companyName}</i>
                            <br />
                            <br />
                            <b>Heads up ✋</b>
                            <br />
                            This contract can not be archived, not completed by onboarding team
                            <br />
                            <br />
                            <br />
                          </WarningBox>

                          <ErrorBox>
                            <b>Noo!</b> Could not look up contract <br />
                            Try again?
                          </ErrorBox>
                        </StatusBoxes>
                      </div>
                      <div>
                        <Button type="submit" block>
                          Lookup
                        </Button>
                        <Button status={uploadStatus} block onClick={upload}>
                          Upload
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </div>
            </AnimateHeight>
          </div>
        </article>
      </section>
    </AdminPage>
  );
}
