import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Fee } from "../../../../../../components/fee/Fee";
import { TextInput } from "../../../../../../components/form/TextInput";
import { NewImage } from "../../../../../../components/images/NewImage";
import {
  getTerminalImageData,
  getTerminalEntityType,
  getTerminalTypeString,
} from "../../../../../../model/terminal/terminalUtils";
import { TerminalConfigurationOptions } from "../../../../../../data/dataTerminalPricing";
import { TerminalType } from "../../../../../../model/contract/contractType";
import { defaultCurrencyFromCountry } from "../../../../../../i18n";
import { contractStatusState } from "../../../../../../state/contractStatusState";
import { TerminalPriceSelector } from "./TerminalPriceSelector/TerminalPriceSelector";
import styles from "./SummaryDevice.module.scss";
import { getCountryDisplayString } from "../../../../../../components/utils";

interface Props {
  terminalType: TerminalType;
  numberOfTerminals: number;
  monthlyCost: number | undefined;
  terminalPriceDetails: TerminalConfigurationOptions | undefined;
  isPomca: boolean;
}

export const SummaryDevice: FC<Props> = ({
  terminalType,
  numberOfTerminals,
  monthlyCost,
  terminalPriceDetails,
  isPomca,
}) => {
  const { t, i18n } = useTranslation();
  const { country, contractId, edit } = useRecoilValue(contractStatusState);
  const currency = defaultCurrencyFromCountry(country);

  return (
    <div className={styles.root}>
      <div className={styles.image}>
        <NewImage {...getTerminalImageData(terminalType)} />
      </div>
      <div className={styles.content}>
        <div className={styles.firstRow}>
          <div className={styles.terminalTitle}>{getTerminalTypeString(terminalType)}</div>
          <h3 className={styles.amount}>&#215; {numberOfTerminals}</h3>
        </div>
        <div className={styles.secondRow}>
          {monthlyCost === undefined ? (
            <div className={styles.selected_price_error}>
              {t(
                "No selected terminal price found for {{terminalType}}. Try selecting a new one from the dropdown menu.",
                {
                  terminalType: getTerminalTypeString(terminalType),
                }
              )}
            </div>
          ) : (
            <div className={styles.fee}>
              <Fee
                lang={i18n.language}
                cost={monthlyCost}
                currency={currency}
                extension={t(getTerminalEntityType(terminalType))}
                decimals={2}
              />
            </div>
          )}
        </div>
        <div className={styles.input}>
          {terminalPriceDetails ? (
            <TerminalPriceSelector
              isPomca={isPomca}
              label={t("Select monthly terminal price")}
              contractId={contractId}
              isEditable={edit}
              terminalPriceDetails={terminalPriceDetails}
            />
          ) : (
            <div className={styles.text_input_error}>
              <TextInput disabled={true} onChange={() => console.log("")} value={monthlyCost?.toFixed(2)} />
              <div className={styles.available_prices_error}>
                {t(
                  "Could not get available prices for {{terminalType}}. This is most likely because {{terminalType}} is no longer available in {{country}}.",
                  {
                    terminalType: getTerminalTypeString(terminalType),
                    country: getCountryDisplayString(country),
                  }
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
