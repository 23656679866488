import cx from "classnames";
import React, { useCallback, useMemo, useRef } from "react";
import i18n, { Language } from "../../i18n";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { AssociateIdentity } from "../../model/associate/associateTypes";
import { IdType } from "../../model/contract/contractType";
import { Status } from "../../data/types";
import { Select } from "../form/Select";
import { TextInput } from "../form/TextInput";
import { MaxDateValidator } from "../form/validators/MaxDateValidator";
import { MinDateValidator } from "../form/validators/MinDateValidator";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { getCountryOpts } from "../utils";
import "./IdCard.scss";
import { Checkboxes } from "../interactions/Checkboxes/Checkboxes";

interface Props {
  cardForm: AssociateIdentity;
  setCardForm: (card: AssociateIdentity) => void;
  status?: Status;
  prefilledFields?: string[];
}

export const NO_EXPIRY_DATE = "2099-12-31";

const MAX_AGE = 90;
const MIN_AGE = 18;
const DATE_PATTERN = "yyyy-MM-dd";

function calcAge(date: string) {
  const isoDate = DateTime.fromISO(date);
  const years = DateTime.now().diff(isoDate, "years").years;
  return Math.abs(Math.floor(years));
}

export const IdCardInputs: React.FunctionComponent<Props> = ({
  cardForm,
  setCardForm,
  status = Status.DEFAULT,
  prefilledFields = [],
}) => {
  const { t } = useTranslation();
  const expiryRef = useRef<string>();
  const min = DateTime.now().plus({ day: 14 });
  const minDate = min.toJSDate();
  const minDateString = min.toISODate();
  const max = DateTime.now();
  const maxDate = max.toJSDate();
  const maxDateString = max.toISODate();
  const active = status !== Status.DISABLED;
  const minAge = DateTime.now().minus({ years: MAX_AGE });
  const minAgeDate = minAge.toJSDate();
  const minAgeDateString = minAge.toFormat(DATE_PATTERN);
  const maxAge = DateTime.now().minus({ years: MIN_AGE });
  const maxAgeDate = maxAge.toJSDate();
  const maxAgeDateString = maxAge.toFormat(DATE_PATTERN);

  const onChange = useCallback(
    (value, name) => {
      const copy = { ...cardForm, [name]: value };
      setCardForm(copy);
    },
    [cardForm, setCardForm]
  );

  const countries = useMemo(() => getCountryOpts(i18n.language as Language, t), [t]);

  const opts = useMemo(
    () => [
      {
        value: "",
        text: t("Select type"),
        disabled: true,
      },
      {
        value: IdType.NATIONAL_ID_CARD,
        text: t("National id card"),
        disabled: false,
      },
      {
        value: IdType.PASSPORT,
        text: t("Passport"),
        disabled: false,
      },
    ],
    [t]
  );

  return (
    <>
      <div className="id-card-input">
        <Select
          className={cx({
            "positive-attention": prefilledFields.includes("idType"),
          })}
          onChange={onChange}
          disabled={!active}
          name="idType"
          label={t("Type of identification")}
          alternatives={opts}
          value={cardForm.idType || ""}
          validators={[new RequiredValidator(t("Type of identification is required"))]}
        />
      </div>
      <div className="id-card-input">
        <TextInput
          className={cx({
            "positive-attention": prefilledFields.includes("idDocumentNo"),
          })}
          onChange={onChange}
          name="idDocumentNo"
          label={t("Document number")}
          disabled={!active}
          value={cardForm.idDocumentNo}
          validators={[new RequiredValidator(t("Document number is required"))]}
        />
      </div>
      <div className="id-card-input">
        <TextInput
          className={cx({
            "positive-attention": prefilledFields.includes("dateOfBirth"),
          })}
          type="date"
          onChange={onChange}
          name="dateOfBirth"
          label={t("Date of birth")}
          disabled={!active}
          placeholder="yyyy-mm-dd"
          value={cardForm.dateOfBirth}
          message={
            cardForm.dateOfBirth ? t("{{year}} years old", { year: calcAge(cardForm.dateOfBirth) }) : null
          }
          attributes={{
            min: minAgeDateString,
            max: maxAgeDateString,
          }}
          validators={[
            new RequiredValidator(t("Date of birth is required")),
            new MaxDateValidator(
              maxAgeDate,
              t("Person cannot be younger than {{min}} years", {
                min: MIN_AGE,
              })
            ),
            new MinDateValidator(
              minAgeDate,
              t("Person cannot be older than {{max}} years", {
                max: MAX_AGE,
              })
            ),
          ]}
        />
      </div>
      <div className="id-card-input">
        <TextInput
          className={cx({
            "positive-attention": prefilledFields.includes("placeOfBirth"),
          })}
          onChange={onChange}
          name="placeOfBirth"
          label={t("Place of birth")}
          disabled={!active}
          value={cardForm.placeOfBirth}
          validators={[new RequiredValidator(t("Place of birth is required"))]}
        />
      </div>
      <div className="id-card-input">
        <Select
          className={cx({
            "positive-attention": prefilledFields.includes("nationality"),
          })}
          onChange={(value, name, ev) => {
            onChange(value || "", name);
          }}
          disabled={!active}
          name="nationality"
          label={t("Nationality")}
          alternatives={countries}
          value={cardForm.nationality || ""}
          validators={[new RequiredValidator(t("Nationality is required"))]}
        />
      </div>
      <div className="id-card-input">
        <TextInput
          className={cx({
            "positive-attention": prefilledFields.includes("issuedIn"),
          })}
          onChange={onChange}
          name="issuedIn"
          label={t("Issued in city")}
          disabled={!active}
          value={cardForm.issuedIn}
        />
      </div>
      <div className="id-card-input">
        <TextInput
          className={cx({
            "positive-attention": prefilledFields.includes("issuedByAuthority"),
          })}
          onChange={onChange}
          name="issuedByAuthority"
          label={t("Issued by authority")}
          disabled={!active}
          value={cardForm.issuedByAuthority}
          validators={[new RequiredValidator(t("Issued by authority is required"))]}
        />
      </div>
      <div className="id-card-input">
        <Select
          className={cx({
            "positive-attention": prefilledFields.includes("issuedByCountry"),
          })}
          onChange={(value, name, ev) => {
            onChange(value || "", name);
          }}
          name="issuedByCountry"
          label={t("Issued by country")}
          disabled={!active}
          alternatives={countries}
          value={cardForm.issuedByCountry || ""}
          validators={[new RequiredValidator(t("Issued by country is required"))]}
        />
      </div>
      <div className="id-card-input">
        <TextInput
          className={cx({
            "positive-attention": prefilledFields.includes("issued"),
          })}
          type="date"
          onChange={onChange}
          label={t("Date of issue")}
          disabled={!active}
          name="issued"
          placeholder="yyyy-mm-dd"
          value={cardForm.issued}
          message={
            cardForm.issued
              ? t("Issued {{years}} years ago", {
                  years: calcAge(cardForm.issued),
                })
              : null
          }
          attributes={{
            max: maxDateString,
          }}
          validators={
            cardForm.dateOfBirth
              ? [
                  new RequiredValidator(t("Date of issue is required")),
                  new MaxDateValidator(maxDate, t("Date must be set before today")),
                  new MinDateValidator(
                    new Date(new Date(Date.parse(cardForm.dateOfBirth) + 1)),
                    t("Date must be set after date of birth")
                  ),
                ]
              : [
                  new RequiredValidator(t("Date of issue is required")),
                  new MaxDateValidator(maxDate, t("Date must be set before today")),

                  // new MinDateValidator(
                  //   cardForm.dateOfBirth,
                  //   t("You cannot issue an date before your date of birth")
                  // ),
                ]
          }
        />
      </div>
      <div className="id-card-input">
        <TextInput
          className={cx({
            "positive-attention": prefilledFields.includes("expiry"),
          })}
          type="date"
          onChange={onChange}
          name="expiry"
          label={t("Expiry date of id")}
          placeholder="yyyy-mm-dd"
          disabled={!active || cardForm.noExpiry}
          value={cardForm.expiry}
          message={
            cardForm.expiry
              ? t("Expires in {{years}} years from now", {
                  years: calcAge(cardForm.expiry),
                })
              : null
          }
          hint={t("Must not be within two weeks from today")}
          attributes={{
            min: minDateString,
          }}
          validators={
            cardForm.noExpiry
              ? []
              : [
                  new RequiredValidator(t("Expiry date is required")),
                  new MinDateValidator(minDate, t("Must not be within two weeks from today")),
                ]
          }
        />
      </div>
      <div />
      <div>
        <Checkboxes
          className="compact expiry-checkbox"
          onChange={(values, name) => {
            let copy;
            if (!!values.length) {
              expiryRef.current = cardForm.expiry;
              copy = { ...cardForm, expiry: NO_EXPIRY_DATE, noExpiry: true };
            } else {
              if (expiryRef.current) {
                copy = {
                  ...cardForm,
                  expiry: expiryRef.current,
                  noExpiry: false,
                };
              } else {
                copy = {
                  ...cardForm,
                  noExpiry: false,
                };
              }
            }

            setCardForm(copy);
          }}
          values={cardForm.noExpiry ? ["true"] : []}
          disabled={status === Status.DISABLED}
          alternatives={[
            {
              text: t("No expiry date"),
              value: "true",
            },
          ]}
        />
      </div>
    </>
  );
};
