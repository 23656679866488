import { useRef, useState } from "react";
import { Button } from "../../../components/interactions/Buttons/Button";
import { NewOverlay } from "../../../components/overlay/NewOverlay";
import useEnvironment from "../../../hooks/useEnvironment";
import { RadioGroup } from "../../../components/form/RadioGroup";
import { Alternative } from "../../../components/interactions/InputTypes";
import {
  fillAfterMerchant,
  fillContract,
  fillMerchant,
  fillUntilCompleted,
  deleteAllDevCompany,
} from "../../../data/dataDev";
import { Form } from "../../../components/form/Form";
import { HiddenInput } from "../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Status } from "../../../data/types";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import FadeAnimation from "../../../components/animate/FadeAnimation";
import { generatePath, useHistory } from "react-router-dom";
import { ContractId } from "../../../model/common/commonType";
import { LuTestTube2 } from "react-icons/lu";

export enum DevContractStatus {
  PENDING_SALES_INPUT = "PENDING_SALES_INPUT",
  MERCHANT_VERIFICATION = "MERCHANT_VERIFICATION",
  MERCHANT_SIGNATURES = "MERCHANT_SIGNATURES",
  SALES_VERIFICATION = "SALES_VERIFICATION",
  COMPLETE = "COMPLETE",
  DELETE = "DELETE",
  ANONYMISED = "ANONYMISED",
}

export function getDevContractStatusDisplayString(status: DevContractStatus) {
  switch (status) {
    case DevContractStatus.COMPLETE:
      return "Completed";
    case DevContractStatus.MERCHANT_SIGNATURES:
      return "Merchant signing";
    case DevContractStatus.MERCHANT_VERIFICATION:
      return "Merchant confirmation";
    case DevContractStatus.PENDING_SALES_INPUT:
      return "Creating contract";
    case DevContractStatus.SALES_VERIFICATION:
      return "Document sign off";
    case DevContractStatus.DELETE:
      return "Delete all DevCompany";
    case DevContractStatus.ANONYMISED:
      return "Anonymised";
  }
  return status;
}

const statuses = [
  DevContractStatus.PENDING_SALES_INPUT,
  DevContractStatus.MERCHANT_VERIFICATION,
  DevContractStatus.MERCHANT_SIGNATURES,
  DevContractStatus.SALES_VERIFICATION,
  DevContractStatus.COMPLETE,
  DevContractStatus.DELETE,
];

const stageAlternatives: Alternative<DevContractStatus>[] = statuses.map((status) => ({
  text: getDevContractStatusDisplayString(status),
  value: status as DevContractStatus,
}));

const MockNewContract = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<DevContractStatus | undefined>(undefined);
  const [status, setStatus] = useState(Status.DEFAULT);
  const ref = useRef(null);
  const history = useHistory();

  const { isProduction } = useEnvironment();
  if (isProduction) return null;

  const onMockContract = async () => {
    console.log("Runniiiing");

    if (!selectedOption) return;
    setStatus(Status.PENDING);

    console.log("Runniiiing");

    try {
      const devApiRequest = getApiCall(selectedOption);
      const contractId = await devApiRequest();
      if (typeof contractId === "string") {
        const ROUTE = DEV_ROUTE_MAPPING[selectedOption];
        history.push(generatePath(ROUTE, { id: contractId }));
      }
      setStatus(Status.SUCCESS);
    } catch (error) {
      console.error(error);
      setStatus(Status.ERROR);
    } finally {
      setTimeout(() => setStatus(Status.DEFAULT), 5000);
    }
  };

  return (
    <div>
      <Button block className="m-top-10" color="mock" onClick={() => setIsOpen(!isOpen)} variant="CTA">
        Mock contract <LuTestTube2 style={{ marginLeft: 8, height: "1.2em", width: "1.2em" }} />
      </Button>
      <NewOverlay open={isOpen} onClose={() => setIsOpen(false)} widthSize="small">
        <Form
          onSubmit={(_, form) => {
            if (form.isInvalid) return;
            onMockContract();
          }}
        >
          <h4 ref={ref} style={{ paddingLeft: 10 }} className="m-bottom-30">
            Mock new contract
          </h4>
          <RadioGroup
            label={"Select a stage in which the contract will be created in"}
            name="contract-stage"
            onChange={(value) => {
              setStatus(Status.DEFAULT);
              setSelectedOption(value);
            }}
            value={selectedOption}
            alternatives={stageAlternatives}
          />
          <HiddenInput
            boxVariant
            value={selectedOption}
            validators={[new RequiredValidator("You need to select a contract stage")]}
            label="Contract stage"
            scrollToRef={ref}
          />
          <Button type="submit" color="mock" block className="m-y-20" status={status}>
            Go!
          </Button>
        </Form>

        <FadeAnimation animatePresence={true} open={status === Status.SUCCESS}>
          <SuccessBox className="m-bottom-20">
            You have successfully mocked a contract in stage{" "}
            <strong>{selectedOption ? getDevContractStatusDisplayString(selectedOption) : "unknown"}</strong>
            <br />
            <i>(You have to reload the dashboard filters to see the mocked contract)</i>
          </SuccessBox>
        </FadeAnimation>
      </NewOverlay>
    </div>
  );
};

export default MockNewContract;

const DEV_API_CALL_MAPPING: Record<DevContractStatus, () => Promise<ContractId | void>> = {
  [DevContractStatus.PENDING_SALES_INPUT]: fillContract,
  [DevContractStatus.MERCHANT_VERIFICATION]: fillMerchant,
  [DevContractStatus.MERCHANT_SIGNATURES]: fillMerchant,
  [DevContractStatus.SALES_VERIFICATION]: fillAfterMerchant,
  [DevContractStatus.COMPLETE]: fillUntilCompleted,
  [DevContractStatus.DELETE]: deleteAllDevCompany,
  [DevContractStatus.ANONYMISED]: () => Promise.reject("Action not defined for anonymized status"),
};

export const DEV_ROUTE_MAPPING: Record<DevContractStatus, string> = {
  [DevContractStatus.PENDING_SALES_INPUT]: "/contract/:id",
  [DevContractStatus.MERCHANT_VERIFICATION]: "/merchant/:id",
  [DevContractStatus.MERCHANT_SIGNATURES]: "/merchant/:id",
  [DevContractStatus.SALES_VERIFICATION]: "/merchant/:id",
  [DevContractStatus.COMPLETE]: "/contract/completed/:id",
  [DevContractStatus.DELETE]: "",
  [DevContractStatus.ANONYMISED]: "",
};

const getApiCall = (status: DevContractStatus): (() => Promise<ContractId | void>) => {
  const apiCall = DEV_API_CALL_MAPPING[status];
  const error = () => Promise.reject("Function not defined for this status");
  return apiCall ?? error;
};
