import React, { useState, useCallback } from "react";
import { Status } from "../../data/types";
import { Button } from "../interactions/Buttons/Button";
import { useTranslation } from "react-i18next";
import { dataContracts } from "../../data/dataContracts";
import { ContractId } from "../../model/common/commonType";
import { ContractStatus, VatStatus } from "../../model/contract/contractType";
import { TextInput } from "../form/TextInput";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { NumberValidator } from "../form/validators/NumberValidator";
import { trimAllButAlphaNumeric } from "../utils";
import { ExternalMultiValueValidator } from "../form/validators/ExternalMultiValueValidator";
import { useRecoilValue } from "recoil";
import { contractStatusState } from "../../state/contractStatusState";
import "./VAT.scss";

interface Props {
  status?: Status;
  onChange: (value: string, name: string) => void;
  value?: string;
  contractId: ContractId;
  vatResult?: VatStatus;
}

const VALID = "Registered for VAT";
const NOT_VALID = "Not registered for VAT";
const VIES_ERROR = "Could not get VAT status, try again later";

const vatStatusMap: Record<VatStatus, { status: Status; message: string }> = {
  [VatStatus.NOT_CHECKED]: {
    status: Status.DEFAULT,
    message: VIES_ERROR,
  },
  [VatStatus.VALID]: {
    status: Status.SUCCESS,
    message: VALID,
  },
  [VatStatus.NOT_VALID]: {
    status: Status.DISABLED,
    message: NOT_VALID,
  },
  [VatStatus.VIES_ERROR]: {
    status: Status.ERROR,
    message: VIES_ERROR,
  },
};

export const VAT: React.FunctionComponent<Props> = ({
  status = Status.DEFAULT,
  onChange,
  value,
  contractId,
  vatResult,
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>(vatResult ? vatStatusMap[vatResult].message : "");
  const { status: contractStatus } = useRecoilValue(contractStatusState);

  // Specific block for editing VAT that is in merchant verification status.
  // We had issues where sales would remove the VAT number and then the contract would be stuck.
  // This is a temporary fix until we have a better solution to validate the entire form during MERCHANT_VERIFICATION status.
  const isBlocked = contractStatus === ContractStatus.MERCHANT_VERIFICATION;

  const [validationStatus, setValidationStatus] = useState<Status>(
    vatResult === VatStatus.VALID ? Status.DISABLED : Status.DEFAULT
  );

  const [internalVatStatus, setInternalVatStatus] = useState(vatResult);
  const [previousVatValue, setPreviousVatValue] = useState(value);

  const validate = useCallback(() => {
    setValidationStatus(Status.PENDING);
    setMessage("");
    setTimeout(() => {
      dataContracts
        .validateVat(contractId, value || "")
        .then((response) => {
          const { status, message } = vatStatusMap[response.result];
          setValidationStatus(status);
          setMessage(message);
          setInternalVatStatus(response.result);
          if (status === Status.ERROR) {
            setTimeout(() => {
              setValidationStatus(Status.DEFAULT);
            }, 4000);
          }
        })
        .catch((err) => {
          console.error(err);
          // fugly.
          setValidationStatus(Status.ERROR);
          setTimeout(() => {
            setValidationStatus(Status.DEFAULT);
          }, 4000);
        })
        .finally(() => setPreviousVatValue(value));
    }, 2000);
  }, [value, contractId]);

  return (
    <div className="vat-input">
      <TextInput
        onChange={(value, name) => {
          onChange(trimAllButAlphaNumeric(value), name);
          setValidationStatus(Status.DEFAULT);
        }}
        label={t("VAT ID")}
        name="vatNumber"
        value={value}
        validators={[
          new RequiredValidator(t("VAT ID is required")),
          new NumberValidator(t("VAT ID should only be a number")),
          new ExternalMultiValueValidator(t("VAT ID hasn't been validated"), value, [previousVatValue]),
          new ExternalMultiValueValidator(t("VAT ID hasn't been validated"), internalVatStatus, [
            VatStatus.VALID,
            VatStatus.NOT_VALID,
            VatStatus.VIES_ERROR,
          ]),
          new ExternalMultiValueValidator(t(VIES_ERROR), internalVatStatus, [
            VatStatus.VALID,
            VatStatus.NOT_VALID,
            VatStatus.NOT_CHECKED,
          ]),
        ]}
        disabled={status === Status.DISABLED || isBlocked}
        message={message && t(message)}
      />
      <div className="vat-input-validation">
        <Button
          type="button"
          onClick={validate}
          status={status === Status.DISABLED || isBlocked ? Status.DISABLED : validationStatus}
          data-testid="validate-vat-button"
        >
          {t("Check")}
        </Button>
      </div>
    </div>
  );
};
