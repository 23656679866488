import React from "react";
import { useRecoilValue } from "recoil";
import { contractPackageState } from "../../../state/contractPackageState";
import { ContractPackage } from "../../../model/contract/contractType";
import { Link } from "../../../components/links/Link";
import { AcquiringPL } from "../../../components/acquiring/AcquiringPL";
import { userState } from "../../../state/userState";
import { SessionResponse } from "../../../data/dataAuth";
import { Country, Currency } from "../../../model/common/commonType";
import { Trans, useTranslation } from "react-i18next";

const WORLDLINE_FEE = 1.45;
const INTERCHANGE_URL = "http://www.worldline.com/merchant-services/interchange";
const SCHEMA_URL = "http://www.worldline.com/merchant-services/schemefees";

const BRANDS = ["Visa", "Mastercard", "V PAY", "Maestro"];

export const PricingCZ: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const pack = useRecoilValue(contractPackageState);
  const user = useRecoilValue(userState);

  return (
    <div className="pricing-cz">
      <div className="tablet-columns">
        <div className="m-bottom-40">
          <strong>{t("Payment terminal")}:</strong>
          <br />
          {t("PayPhone application is available on Android.")}
          <br />
        </div>
        <div className="m-bottom-40">
          <strong>{t("Services")}:</strong>
          <br />- {t("Hotline")}
          <br />- {t("App-download")}
          <br />- {t("Software update")}
          <br />- {t("System operation")}
        </div>
      </div>
      <div className="m-bottom-40">
        <strong>{t("Acceptance")}:</strong>
        <dl>
          <dt>{t("Brands")}:</dt>
          <dd>{BRANDS.join(", ")}</dd>

          <dt>{t("Reimbursement currency")}:</dt>
          <dd>{Currency.CZECHIA}</dd>

          <dt>{t("Reimbursement frequency")}:</dt>
          <dd>{t("Daily")}</dd>

          <dt>{t("Reimbursement notice")}:</dt>
          <dd>{t("Transaction level (detailed)")}</dd>

          <dt>{t("Features included")}:</dt>
          <dd>{t("Online reports")}</dd>
        </dl>
      </div>
      <Total pack={pack} user={user} />
    </div>
  );
};

const Total: React.FunctionComponent<{
  pack: ContractPackage;
  user: SessionResponse | null;
}> = ({ pack, user }) => {
  const country = user?.country as Country;
  const { t } = useTranslation();

  return (
    <>
      <div className="m-bottom-40">
        <strong>{t("Total fee includes")}:</strong>
        <br />
        <ol>
          <li>
            1.{" "}
            <Link external link={INTERCHANGE_URL}>
              {t("Interchange fees")}
            </Link>{" "}
            ({t("As applied by the respective Card Scheme on behalf of the Card Issuers.")})
          </li>
          <li>
            2.{" "}
            <Link external link={SCHEMA_URL}>
              {t("Card Scheme fees")}
            </Link>{" "}
            ({t("As applied by the respective Card Scheme.")})
          </li>
          <li>
            3. <i>{t("Worldline acquiring fee")}</i> {WORLDLINE_FEE}%
          </li>
        </ol>
      </div>
      <div className="m-bottom-30">
        <AcquiringPL pack={pack} country={country} />
      </div>
      <div>
        <div className="disclaimer">
          <Trans>
            Worldline offers and invoices the Merchant for the agreed card brands and card types with
            different levels of interchange fees, individually itemized charges (interchange fees, card scheme
            fees and Worldline acquiring fees). Unless otherwise agreed, these shall also be charged in the
            case of a refund.
          </Trans>
        </div>
        <div className="disclaimer">
          <Trans>
            The Merchant declares to have acknowledged the individually itemized information according to card
            brand and card type in relation to the different levels of interchange fees and card scheme fees
            available under{" "}
            <Link external link={INTERCHANGE_URL}>
              Interchange fees
            </Link>{" "}
            and{" "}
            <Link external link={SCHEMA_URL}>
              Card Scheme fees
            </Link>
          </Trans>
        </div>
      </div>
    </>
  );
};
