import { useEffect, useState } from "react";
import { Link } from "../../../components/links/Link";
import { Table } from "../../../components/table/Table";
import { getDynamicLink } from "../../../components/utils";
import { AdminContractListResponse, dataContracts } from "../../../data/dataContracts";
import { Page, SortDirection } from "../../../model/Page";
import { CONTRACT_ROUTE } from "../../Contract/ContractPage";
import { ContractStatus as StatusInterface } from "../../../model/contract/contractType";
import { MERCHANT_ROUTE } from "../../MerchantStatus/MerchantStatusPage";
import { AdminPage } from "../AdminPage";
import { ContractStatus } from "../../../components/contractStatus/ContractStatus";
import { FormattedDate } from "../../../components/time/FormattedDate";
import { Pagination } from "../../../components/pagination/Pagination";
import { useHistory, useLocation } from "react-router";
import { TextInput } from "../../../components/form/TextInput";
import { useDebounceFn } from "../../../hooks/useDebounce";

function resolveLinkByStatus(
  contract: AdminContractListResponse
): "/contract/:id" | "/merchant/:id" | "/contract/completed/:id" {
  if (contract.status === StatusInterface.PENDING_SALES_INPUT) {
    return CONTRACT_ROUTE;
  }
  if (contract.status === StatusInterface.COMPLETE) {
    return "/contract/completed/:id";
  }
  return MERCHANT_ROUTE;
}

export const ADMIN_CONTRACTS_LIST_PAGE = "/admin/contracts";

export const AdminContractListPage = () => {
  const [contracts, setContracts] = useState<Page<AdminContractListResponse>>();

  const history = useHistory();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState<string>();

  const onSearchChange = (value: string) => {
    const search = new URLSearchParams(location.search);
    search.set("search", value);
    search.set("page", `${0}`);
    history.replace({
      pathname: location.pathname,
      search: search.toString(),
    });
  };

  const debounceSearchValue = useDebounceFn(onSearchChange, 500);

  const onPageChange = (page: number) => {
    const search = new URLSearchParams(location.search);
    search.set("page", page.toString());
    history.replace({
      pathname: location.pathname,
      search: search.toString(),
    });
  };

  const onSortChange = (ev: React.MouseEvent<HTMLButtonElement>) => {
    const property = (ev.target as HTMLButtonElement).dataset.id;
    if (!property) {
      return;
    }
    const search = new URLSearchParams(location.search);
    search.set("sort", property);

    const direction = contracts?.sort.sorted
      ? contracts?.sort.direction === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
      : SortDirection.ASC;
    search.set("sortDirection", direction);

    history.push({
      pathname: location.pathname,
      search: search.toString(),
    });
  };

  const isSorted = (property: string) => {
    if (!contracts) {
      return undefined;
    }
    if (!contracts.sort.sorted) {
      return undefined;
    }

    if (contracts.sort.property === property) {
      return contracts.sort.direction === "ASC" ? "ascending" : "descending";
    }

    return undefined;
  };

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const page = search.has("page") ? Number(search.get("page")) : 0;
    let sort = search.get("sort");
    if (sort === null) {
      sort = "created";
    }
    let sortDirection = search.get("sortDirection") as SortDirection;
    if (sortDirection === null) {
      sortDirection = SortDirection.DESC;
    }
    const searchValue = search.get("search") || undefined;

    dataContracts.loadAdminContractsListing(page, 20, sort, sortDirection, searchValue).then(setContracts);
  }, [location, setContracts]);

  return (
    <AdminPage title="Contracts" className="admin-contracts-list">
      <section>
        <div className="m-bottom-10">
          <TextInput
            onChange={(value) => {
              debounceSearchValue(value);
              setSearchValue(value);
            }}
            value={searchValue}
            placeholder={"Search by organisation number"}
            className="compact no-label"
          />
        </div>
        <Table>
          <thead>
            <tr>
              <th>
                <button className={isSorted("companyName")} onClick={onSortChange} data-id="companyName">
                  Company name
                </button>
              </th>
              <th>
                <button className={isSorted("status")} onClick={onSortChange} data-id="status">
                  Status
                </button>
              </th>
              <th>
                <button
                  className={isSorted("currentSalesUser")}
                  onClick={onSortChange}
                  data-id="currentSalesUser"
                >
                  Sales User
                </button>
              </th>
              <th>
                <button className={isSorted("teamId")} onClick={onSortChange} data-id="teamId">
                  Team
                </button>
              </th>
              <th>
                <button className={isSorted("created")} onClick={onSortChange} data-id="created">
                  Started
                </button>
              </th>
              <th>
                <button className={isSorted("lastUpdated")} onClick={onSortChange} data-id="lastUpdated">
                  Updated
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {contracts?.content.map((contract) => (
              <tr key={contract.contractId}>
                <td>
                  <Link
                    link={getDynamicLink(resolveLinkByStatus(contract), {
                      id: contract.contractId,
                    })}
                  >
                    {contract.companyName ? contract.companyName : contract.companyRegistrationId}
                  </Link>
                </td>
                <td>
                  {contract.onboardingCompleted ? "Onboarded" : <ContractStatus status={contract.status} />}
                </td>
                <td>{contract.currentSalesName}</td>
                <td>{contract.salesTeam}</td>
                <td>
                  <FormattedDate date={contract.created} pattern="yyyy-MM-dd" />
                </td>
                <td>
                  <FormattedDate date={contract.lastUpdated} pattern="yyyy-MM-dd" />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="m-top-10">
          <Pagination
            activePage={contracts ? contracts.number : 0}
            totalPages={contracts ? contracts.totalPages : 0}
            onPageChange={onPageChange}
          />
        </div>
      </section>
    </AdminPage>
  );
};
