import { motion } from "framer-motion";
import { FC } from "react";
import { Button } from "../../../../../../../../components/interactions/Buttons/Button";
import { SteppedBasedPricing } from "../../../../../../../../model/pricing/pricingTypes";
import { PricingStep } from "../PricingStep/PricingStep";
import { PricingStep as IPricingStep } from "../../../../../../../../model/pricing/pricingTypes";
import styles from "./StepPricingTableEdit.module.scss";
import { SetEditablePricing } from "../../PricingInputs";

interface Props {
  steppedBasedPricing: SteppedBasedPricing;
  setEditablePricing: SetEditablePricing;
}

const StepPricingTableEdit: FC<Props> = ({ steppedBasedPricing, setEditablePricing }) => {
  const { cashlessPromotionPricing, regularPricing } = steppedBasedPricing;

  const onChangeFee = (
    value: number | undefined,
    index: number,
    pricingType: "cashlessPromotionPricing" | "regularPricing"
  ) => {
    if (value === undefined) return;

    const newPricing: SteppedBasedPricing = {
      ...steppedBasedPricing,
      [pricingType]: steppedBasedPricing[pricingType]?.map((pricingStep, stepIndex) =>
        stepIndex === index ? { ...pricingStep, transactionFee: value } : pricingStep
      ),
    };

    setEditablePricing((prev) => ({ ...prev, steppedBasedPricing: newPricing }));
  };

  const onChangeToThreshold = (
    value: number | undefined,
    index: number,
    pricingType: "cashlessPromotionPricing" | "regularPricing"
  ) => {
    if (!value) return;
    const nextIndex = index + 1;

    const newStepped = steppedBasedPricing[pricingType]?.map((pricingStep, stepIndex) => {
      if (stepIndex === index) return { ...pricingStep, toThreshold: value };
      if (stepIndex === nextIndex) return { ...pricingStep, fromThreshold: value };
      return pricingStep;
    });

    const newPricing: SteppedBasedPricing = {
      ...steppedBasedPricing,
      [pricingType]: newStepped,
    };

    setEditablePricing((prev) => ({ ...prev, steppedBasedPricing: newPricing }));
  };

  const onDeletePricingStep = (pricingType: "cashlessPromotionPricing" | "regularPricing") => {
    const oldPricing = steppedBasedPricing[pricingType];
    if (!oldPricing) return;

    const newPricing: SteppedBasedPricing = {
      ...steppedBasedPricing,
      [pricingType]: oldPricing.slice(0, -1),
    };

    setEditablePricing((prev) => ({
      ...prev,
      steppedBasedPricing: newPricing,
    }));
  };

  const onAddPricingStep = (pricingType: "cashlessPromotionPricing" | "regularPricing") => {
    const oldPricing = steppedBasedPricing[pricingType];
    if (!oldPricing) return;

    const oldLastStep = oldPricing[oldPricing.length - 1];

    const newStep: IPricingStep = {
      fromThreshold: oldLastStep.toThreshold,
      toThreshold: oldLastStep.toThreshold * 2,
      transactionFee: Number((oldLastStep.transactionFee - 0.1).toFixed(2)),
    };

    const newPricing: SteppedBasedPricing = {
      ...steppedBasedPricing,
      [pricingType]: [...oldPricing, newStep],
    };

    setEditablePricing((prev) => ({
      ...prev,
      steppedBasedPricing: newPricing,
    }));
  };

  return (
    <div>
      <motion.div className={styles.root}>
        {cashlessPromotionPricing && (
          <div className={styles.cashless_header}>First 12 months after contract signature</div>
        )}

        {cashlessPromotionPricing && (
          <div>
            {cashlessPromotionPricing.map((pricingStep, index) => (
              <PricingStep
                key={"cashless-pricing-step " + index}
                pricingStep={pricingStep}
                onChangeToThreshold={(value) => onChangeToThreshold(value, index, "cashlessPromotionPricing")}
                onChangeTransactionFee={(value) => onChangeFee(value, index, "cashlessPromotionPricing")}
                onDeletePricingStep={() => onDeletePricingStep("cashlessPromotionPricing")}
                isFirstStep={index === 0}
                isLastStep={index + 1 === cashlessPromotionPricing?.length}
                canDelete={cashlessPromotionPricing.length > 2}
              />
            ))}
            <div className="center">
              <Button onClick={() => onAddPricingStep("cashlessPromotionPricing")} ghost>
                Add threshold
              </Button>
            </div>
          </div>
        )}

        {cashlessPromotionPricing && (
          <div className={styles.cashless_header}>More than 12 months after contract signing</div>
        )}

        <div>
          {regularPricing.map((pricingStep, index) => (
            <PricingStep
              key={"regular-pricing-step " + index}
              isFirstStep={index === 0}
              pricingStep={pricingStep}
              onChangeToThreshold={(value) => onChangeToThreshold(value, index, "regularPricing")}
              onChangeTransactionFee={(value) => onChangeFee(value, index, "regularPricing")}
              onDeletePricingStep={() => onDeletePricingStep("regularPricing")}
              isLastStep={index + 1 === regularPricing.length}
              canDelete={regularPricing.length > 2}
            />
          ))}

          <div className="center">
            <Button onClick={() => onAddPricingStep("regularPricing")} ghost>
              Add threshold
            </Button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default StepPricingTableEdit;

// const addThreshold = (sectionIndex: number, prev: SteppedBasedPricingOptional[] | undefined) => {
//   if (!prev) return undefined;
//   return prev.map((section, index) =>
//     index === sectionIndex ? { ...section, steps: [...section.steps, INITIAL_PRICING_STEP] } : section
//   );
// };

// const deleteThreshold = (
//   sectionIndex: number,
//   stepIndex: number,
//   prev: SteppedBasedPricingOptional[] | undefined
// ) => {
//   if (!prev) return undefined;

//   return prev.map((section, _sectionIndex) => {
//     if (sectionIndex === _sectionIndex) {
//       const updatedSteps = section.steps.filter((step, _stepIndex) =>
//         stepIndex === _stepIndex ? false : true
//       );
//       return { ...section, steps: updatedSteps };
//     }
//     return section;
//   });
// };

// // Helper function for finding and updating a step
// const updatePricingStep = (
//   sectionIndex: number,
//   stepIndex: number,
//   steppedBasedPricing: SteppedBasedPricingOptional[] | undefined,
//   updateCallback: (step: PricingStepOptional) => PricingStepOptional
// ) => {
//   if (!steppedBasedPricing) return undefined;

//   return steppedBasedPricing.map((section, _sectionIndex) => {
//     if (sectionIndex === _sectionIndex) {
//       const updatedSteps = section.steps.map((step, _stepIndex) =>
//         stepIndex === _stepIndex ? updateCallback(step) : step
//       );
//       return { ...section, steps: updatedSteps };
//     }
//     return section;
//   });
// };

// const updateTransactionFees = (
//   sectionIndex: number,
//   stepIndex: number,
//   steppedBasedPricing: SteppedBasedPricingOptional[] | undefined,
//   inputArgs: UpdateFeeInputArgs
// ) => {
//   const updateCallback = (step: PricingStepOptional) => ({
//     ...step,
//     transactionFees: getUpdatedFees({
//       ...inputArgs,
//       transactionFees: step.transactionFees,
//     }),
//   });

//   return updatePricingStep(sectionIndex, stepIndex, steppedBasedPricing, updateCallback);
// };

// const updateStepLabel = (
//   sectionIndex: number,
//   stepIndex: number,
//   steppedBasedPricing: SteppedBasedPricingOptional[] | undefined,
//   newThreshold: string
// ) => {
//   const updateCallback = (step: PricingStepOptional) => ({
//     ...step,
//     threshold: newThreshold,
//   });

//   return updatePricingStep(sectionIndex, stepIndex, steppedBasedPricing, updateCallback);
// };

// const updateSectionLabel = (
//   sectionIndex: number,
//   steppedBasedPricing: SteppedBasedPricingOptional[] | undefined,
//   inputeValue: string
// ) => {
//   if (!steppedBasedPricing) return undefined;
//   return steppedBasedPricing.map((section, index) =>
//     sectionIndex === index ? { ...section, label: inputeValue } : { ...section }
//   );
// };
