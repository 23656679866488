import styles from "./SingleFileUpload.module.scss";
import cx from "classnames";
import { useState, FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Status } from "../../../data/types";
import { TextArea } from "../../form/TextArea";
import { RequiredValidator } from "../../form/validators/RequiredValidator";
import FileUploadImage from "./FileUploadImage";
import { ErrorBox } from "../../boxes/ErrorBox";
import { SuccessBox } from "../../boxes/SuccessBox";

interface Props {
  title?: string;
  subtitle?: string;
  status: Status;
  descriptionIsRequired?: boolean;
  onUpload: (file: File, fileDescription: string) => void;
  errorMessage?: string;
  accept?: string;
}

const SingleFileUpload: FC<Props> = ({
  title = "Upload files",
  subtitle = "Supported formates: JPG, PNG or PDF. File size no more than 10MB",
  onUpload,
  status,
  descriptionIsRequired = true,
  errorMessage,
  accept = "image/*,application/pdf",
}) => {
  const [fileDescription, setFileDescription] = useState<string | undefined>(undefined);
  const { t } = useTranslation();

  const isDisabled = status !== Status.DEFAULT;

  const onAttachFile = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>): void => {
      if (descriptionIsRequired && !fileDescription) return;

      if (ev.target.files) {
        const file = Array.from(ev.target.files)[0];
        onUpload(file, fileDescription ?? "");
        return setFileDescription(undefined);
      }
    },
    [fileDescription, descriptionIsRequired, onUpload]
  );

  return (
    <div className={styles.upload_container}>
      <div className={styles.header}>
        <FileUploadImage status={status} />
        <div>
          <div className="fw-600">{t(title)}</div>
          <div className="text-passive fs-small fw-500" style={{ marginTop: 5 }}>
            {subtitle}
          </div>
        </div>
      </div>
      <div className={styles.body}>
        {descriptionIsRequired && (
          <TextArea
            disabled={isDisabled}
            value={fileDescription}
            onChange={setFileDescription}
            placeholder="e.g. PDF file containing terms and conditions"
            label="File description"
            validators={[new RequiredValidator("File description is required")]}
          />
        )}

        <label htmlFor="file-upload">
          <span
            className={cx("button block button-color-primary", {
              "is-disabled": isDisabled,
            })}
          >
            {t("Select file to upload")}
          </span>
        </label>
        <input
          id="file-upload"
          type="file"
          onChange={onAttachFile}
          style={{ display: "none" }}
          multiple={true}
          disabled={isDisabled}
          // accept={accept}
        />
        {status === Status.ERROR && <ErrorBox className="m-top-10">{errorMessage}</ErrorBox>}
        {status === Status.SUCCESS && (
          <SuccessBox className="m-top-10">{t("File got successfully uploaded!")}</SuccessBox>
        )}
      </div>
    </div>
  );
};

export default SingleFileUpload;
