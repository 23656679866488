import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Checkboxes } from "../../../components/interactions/Checkboxes/Checkboxes";
import { Country } from "../../../model/common/commonType";
import { Status } from "../../../data/types";
import { userState } from "../../../state/userState";

interface Props {
  onChange: (val: any, name: any) => void;
  cashlessPromotion?: boolean;
  country: Country;
  inputStatus: Status;
}

const CASHLESS_PROMOTION_COUNTRIES = [Country.POLAND, Country.CZECHIA];

const DISABLED_COUNTRIES_FOR_EXTERNAL = [Country.CZECHIA];

export const CashlessPromotion: React.FunctionComponent<Props> = ({
  onChange,
  cashlessPromotion,
  country,
  inputStatus,
}) => {
  const { t } = useTranslation();
  const user = useRecoilValue(userState);
  const change = (value: string[]) => {
    onChange(value[0] === "true", "cashlessPromotion");
  };

  if (!CASHLESS_PROMOTION_COUNTRIES.includes(country)) {
    return null;
  }

  if (DISABLED_COUNTRIES_FOR_EXTERNAL.includes(country) && user?.salesUser?.external) {
    return null;
  }

  const values = cashlessPromotion ? ["true"] : [];

  return (
    <div className="m-top-30">
      <Checkboxes
        className="compact"
        onChange={change}
        values={values}
        disabled={inputStatus === Status.DISABLED}
        name="cashlessPromotion"
        alternatives={[
          {
            text: t("Cashless promotion"),
            value: "true",
          },
        ]}
      />
    </div>
  );
};
