import { data } from "../data";
import { ONGOING_RESPONSE, QueueCallback } from "./QueueTypes";
import { Store2 } from "../../model/contract/contractType";
import { Cas, ContractId, StoreId } from "../../model/common/commonType";

class CasStorage {
  storage = new Map<string, Cas>();

  getCas(store: Store2) {
    const key = `${store.storeId}`;
    const cas = this.storage.get(key);
    return cas || store.cas;
  }

  setCas(store: Store2) {
    const key = `${store.storeId}`;
    this.storage.set(key, store.cas);
  }

  setCasByResponse(stores: Store2[], currentStore: Store2) {
    const updatedStore = stores.find((item) => item.storeId === currentStore.storeId);

    if (updatedStore) {
      this.setCas(updatedStore);
    }
  }
}

interface StoreQueueProps {
  contractId: ContractId;
  store: Store2;
  callback: QueueCallback<Store2>;
}

class Store {
  queue: StoreQueueProps[] = [];
  isRequesting: StoreId | null = null;
  casStorage = new CasStorage();

  isInQueue(store: Store2) {
    return !!this.queue.find((storeInQueue) => storeInQueue.store.storeId === store.storeId);
  }

  saveStore(contractId: ContractId, store: Store2, callback: QueueCallback<Store2>) {
    // We have an ongoing request.
    if (this.isRequesting) {
      // 1. Since we have a newer, purge old queue items
      this.queue = this.queue.filter((storeInQueue) => storeInQueue.store.storeId !== store.storeId);

      // 2. Add to queue
      this.queue.push({ store, contractId, callback });
      return;
    }

    // Nothing in queue. Request immediately
    if (!this.queue.length) {
      this.postStore(contractId, store, callback);
      return;
    }

    // We probably will never end up here? We are either
    // requesting or adding to an empty queue. But well,
    // we'll deal with it anyway
    const next = this.queue.pop();
    if (next) {
      this.postStore(next.contractId, next.store, next.callback);
    }
  }

  postStore(contractId: ContractId, store: Store2, callback: QueueCallback<Store2>) {
    this.isRequesting = store.storeId;

    data
      .post<Store2[]>(`/api/sales/contracts/${contractId}/store`, {
        ...store,
        cas: this.casStorage.getCas(store),
      })
      .then((response) => {
        this.casStorage.setCasByResponse(response, store);
        const hasRecentUpdate = this.isInQueue(store);
        this.isRequesting = null;

        // Are there unsaved store updates in queue?
        const next = this.queue.pop();
        if (next) {
          this.postStore(next.contractId, next.store, next.callback);
        }

        // If this store is buffered, resolve with placeholder
        // for now and wait for more recent promises to resolve
        hasRecentUpdate ? callback(ONGOING_RESPONSE) : callback(null, response);
      })
      .catch((err) => {
        const hasRecentUpdate = this.isInQueue(store);
        this.isRequesting = null;

        // Are there unsaved store updates in queue?
        const next = this.queue.pop();
        if (next) {
          this.postStore(next.contractId, next.store, next.callback);
        }

        // If this store is buffered, disregard error and
        // resolve with placeholder for now and wait for
        // more recent promises to resolve.
        hasRecentUpdate ? callback(ONGOING_RESPONSE) : callback(err);
      });
  }
}

export const storeQueue = new Store();
