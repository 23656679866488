import React, { FunctionComponent } from "react";
import { Duration } from "luxon";

interface Props {
  duration: Duration;
}
//https://moment.github.io/luxon/docs/manual/formatting#table-of-tokens
export const FormattedDuration: FunctionComponent<Props> = ({ duration }) => {
  if (duration.valueOf() < 0) {
    duration = duration.negate();
  }
  var milliseconds = duration.as("milliseconds");
  if (milliseconds / 1000 / 86400 >= 1) {
    return <span>{duration.toFormat("d'd' h'h' mm'm'")}</span>;
  } else if (milliseconds / 1000 / 3600 >= 1) {
    return <span>{duration.toFormat("h'h' mm'm'")}</span>;
  } else if (milliseconds / 1000 / 60 >= 1) {
    return <span>{duration.toFormat("m'm' ss's'")}</span>;
  } else {
    return <span>{duration.toFormat("ss's'")}</span>;
  }
};
