import { Language } from "../i18n";
import { cache } from "../network/Cache";
import { data } from "./data";
import { UTCDate } from "../model/common/commonType";

export type TranslationId = number & { isTranslationId: true };

export enum SiteTranslation {
  merchant = "merchant",
  sales = "sales",
}

export interface TranslationText {
  id: TranslationId;
  created: UTCDate;
  updated?: UTCDate;
  updatedBy?: string;
  language: Language;
  namespace: string;
  key: string;
  value?: string;
  site: SiteTranslation;
}

export interface Count {
  count: number;
}

export const dataTranslation = {
  loadTranslations: (language?: Language) => {
    const lang = language || "";
    return data.get<TranslationText[]>(`/api/admin/translations?language=${lang}`);
  },

  suggestText: (id: TranslationId, text: string) => {
    return data
      .post<TranslationText[]>(`/api/admin/translations/${id}`, {
        text: text,
      })
      .then(() => {
        cache.delete("/api/admin/translations");
      });
  },

  deleteText: (id: TranslationId) => {
    return data.delete<void>(`/api/admin/translations/${id}`).then(() => {
      cache.delete("/api/admin/translations");
    });
  },

  countMissingTranslations: (language?: Language) => {
    const lang = language || "";
    return data.get<Count>(`/api/admin/translations/missing/count?language=${lang}`);
  },

  addTranslationLabel: ({
    language,
    namespace,
    key,
    page,
  }: {
    language: Language;
    namespace: string;
    key: string;
    page?: string;
  }) =>
    data.post(`/api/translation/${language}/${namespace}.json`, {
      key,
      page,
    }),
};
