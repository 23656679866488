import { useCallback, useEffect, useState, VoidFunctionComponent } from "react";
import { useRecoilValue } from "recoil";
import { Form } from "../../../components/form/Form";
import { TextInput } from "../../../components/form/TextInput";
import { EmailBackendValidator } from "../../../components/form/validators/EmailBackendValidator";
import { EmailValidator } from "../../../components/form/validators/EmailValidator";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Toggle } from "../../../components/interactions/Toggle/Toggle";
import { Status } from "../../../data/types";
import { AdminUser, userAdministration } from "../../../data/UserAdministration";
import { userState } from "../../../state/userState";
import { NewOverlay } from "../../../components/overlay/NewOverlay";
import styles from "./AdminUser.module.scss";
import { EditIcon } from "../../../components/icons/EditIcon";
import { getCountryDisplayString } from "../../../components/utils";
import { Flag } from "../../../components/flags/Flag";

interface Props {
  adminUser: AdminUser;
  refresh: () => void;
}

export const AdminUserEdit: VoidFunctionComponent<Props> = ({ adminUser, refresh }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [newAdminUserRequest, setNewAdminUserRequest] = useState<AdminUser>(adminUser);

  console.log(newAdminUserRequest.country);

  const [saveButtonStatus, setSaveButtonStatus] = useState(Status.DEFAULT);
  const user = useRecoilValue(userState);

  const isSelf = user?.userId === adminUser.id;

  useEffect(() => {
    setNewAdminUserRequest(adminUser);
  }, [adminUser]);

  const updateWithReset = useCallback(
    (status: Status) => {
      setSaveButtonStatus(status);
      setTimeout(() => {
        setSaveButtonStatus(Status.DEFAULT);
      }, 4000);
    },
    [setSaveButtonStatus]
  );

  const handleDelete = useCallback(() => {
    userAdministration
      .deleteAdministrator(adminUser.id)
      .then(() => {
        refresh();
        setOpen(false);
      })
      .catch();
  }, [adminUser, refresh]);

  return (
    <div className="add-admin-user-overlay light-theme">
      <NewOverlay open={open} onClose={() => setOpen(false)} noPadding widthSize="small">
        <div className={styles.header}>
          <h5 style={{ margin: 0 }}>Update admin user</h5>
        </div>

        <Form
          onSubmit={(event, form) => {
            if (form.isInvalid) {
              return;
            }
            setSaveButtonStatus(Status.PENDING);

            const country = newAdminUserRequest.salesAdmin ? newAdminUserRequest.country : undefined;

            userAdministration
              .editAdministrator(newAdminUserRequest.id, {
                email: newAdminUserRequest.email,
                name: newAdminUserRequest.name,
                loginMethod: newAdminUserRequest.loginMethod,
                superAdmin: newAdminUserRequest.superAdmin,
                salesAdmin: newAdminUserRequest.salesAdmin,
                pricingAdmin: newAdminUserRequest.pricingAdmin,
                country: country,
              })
              .then(() => {
                refresh();
                setOpen(false);
                setSaveButtonStatus(Status.DEFAULT);
              })
              .catch(() => {
                updateWithReset(Status.ERROR);
              });
          }}
        >
          <div className={styles.form_body}>
            <div className={styles.tablet_columns + " m-top-20"}>
              <div>
                <TextInput
                  onChange={(value) => {
                    setNewAdminUserRequest({
                      ...newAdminUserRequest,
                      email: value,
                    });
                  }}
                  value={newAdminUserRequest.email}
                  name="email"
                  label="Email"
                  placeholder="Used as login"
                  validators={[
                    new RequiredValidator("Email is required"),
                    new EmailValidator("Invalid email format"),
                    new EmailBackendValidator("Email is not valid"),
                  ]}
                />
              </div>
              <div>
                <TextInput
                  onChange={(value) => {
                    setNewAdminUserRequest({
                      ...newAdminUserRequest,
                      name: value,
                    });
                  }}
                  value={newAdminUserRequest.name}
                  name="name"
                  label="Name"
                  placeholder="Display name"
                  validators={[new RequiredValidator("Name is required")]}
                />
              </div>
            </div>
            <div className={styles.tablet_columns + " m-bottom-20"}>
              {newAdminUserRequest.salesAdmin ? (
                <>
                  <div>
                    <TextInput
                      disabled={true}
                      label="Country"
                      value={
                        newAdminUserRequest.country
                          ? getCountryDisplayString(newAdminUserRequest.country)
                          : newAdminUserRequest.country
                      }
                      onChange={() => {}}
                      validators={[new RequiredValidator("Country is required")]}
                    />
                  </div>
                  {newAdminUserRequest.country && (
                    <div className="m-top-30">
                      <Flag lang={newAdminUserRequest.country} size={32} />
                    </div>
                  )}
                </>
              ) : (
                <div></div>
              )}
            </div>

            <div className="role-toggle m-y-20">
              <Toggle
                className="mini"
                status={Status.DISABLED}
                onChange={() => {}}
                selected={newAdminUserRequest.superAdmin}
                label="Super Admin"
              />
            </div>

            <div className="role-toggle m-bottom-20">
              <Toggle
                className="mini"
                status={Status.DISABLED}
                onChange={() => {}}
                selected={newAdminUserRequest.salesAdmin}
                label="Sales Admin"
              />
            </div>

            <div className="role-toggle">
              <Toggle
                className="mini"
                status={Status.DEFAULT}
                onChange={(value) => {
                  setNewAdminUserRequest({
                    ...newAdminUserRequest,
                    pricingAdmin: value,
                  });
                }}
                selected={newAdminUserRequest.pricingAdmin}
                label="Pricing Admin"
              />
            </div>

            {/* Disable until we decide to use this */}
            {/* <div>
              <Select
                alternatives={[
                  { value: "", text: "Select" },
                  { value: "AZURE", text: "Azure OAUTH" },
                  { value: "GOOGLE", text: "Google OAUTH" },
                  { value: "OTP", text: "Login Code" },
                ]}
                label="Login Method"
                value={newAdminUserRequest.loginMethod as string}
                onChange={(value) => {
                  setNewAdminUserRequest({
                    ...newAdminUserRequest,
                    loginMethod: value as LoginMethod,
                  });
                }}
                validators={[new RequiredValidator("Login method is required")]}
              />
            </div> */}
          </div>
          <div className={styles.actions}>
            <div>
              <Button
                type="button"
                className="danger-button"
                onClick={handleDelete}
                block
                status={isSelf ? Status.DISABLED : Status.DEFAULT}
              >
                Delete user
              </Button>
            </div>
            <div>
              <Button type="submit" block status={saveButtonStatus}>
                Save
              </Button>
            </div>
          </div>
        </Form>
      </NewOverlay>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        // className="open-edit-button"
        action
        className="mini"
      >
        <EditIcon />
      </Button>
    </div>
  );
};
