import { FC, useState } from "react";
import styles from "./DashboardSettingsDropdown.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import cx from "classnames";
import { FiSettings } from "react-icons/fi";
import { Alternative } from "../../../components/interactions/InputTypes";
import { RadioButtons } from "../../../components/interactions/Radio/RadioButtons";
import { useTranslation } from "react-i18next";
import { DashboardDatesFormat } from "../../../Store";

export interface FilterAlternative {
  id: string;
  label: string;
  onClick: () => void;
  onRemove: () => void;
}

interface Props {
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
  dateFormat: DashboardDatesFormat;
  onChangeDateFormat: (newFormat: DashboardDatesFormat) => void;
}

const DashboardSettingsDropdown: FC<Props> = ({
  pageSize,
  onChangePageSize,
  dateFormat,
  onChangeDateFormat,
}) => {
  const [showAlts, setShowAlts] = useState(false);
  const { t } = useTranslation();

  const datesAlts: Alternative<DashboardDatesFormat>[] = [
    {
      value: DashboardDatesFormat.RELATIVE_DATES,
      text: t("Relative dates"),
    },
    {
      value: DashboardDatesFormat.ABSOLUTE_DATES,
      text: t("Absolute dates"),
    },
  ];

  const onHoverStart = () => {
    if (showAlts) return;
    setShowAlts(true);
  };

  return (
    <motion.div className={styles.root} onHoverStart={onHoverStart} onHoverEnd={() => setShowAlts(false)}>
      <label
        className={cx(styles.label, {
          [styles.label_open]: showAlts,
        })}
      >
        <span className={styles.icon}>{<FiSettings />}</span>
      </label>

      <AnimatePresence>
        {showAlts && (
          <motion.div
            className={styles.dropdown_items}
            initial={{ opacity: 0, y: -15 }}
            animate={{ opacity: 1, y: 5 }}
            exit={{ opacity: 0, y: -15 }}
          >
            <h4 className="m-bottom-30" style={{ paddingLeft: 8 }}>
              {t("Dashboard settings")}
            </h4>
            <ul className="m-bottom-30">
              <RadioButtons
                label={"Date format"}
                className="horizontal"
                alternatives={datesAlts}
                value={dateFormat}
                onChange={onChangeDateFormat}
              />
            </ul>
            <ul className="m-bottom-20">
              <RadioButtons
                label={"Contracts per page"}
                className="horizontal"
                alternatives={numPerPageAlts}
                value={pageSize}
                onChange={onChangePageSize}
              />
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const numPerPageAlts: Alternative<number>[] = [
  {
    value: 10,
    text: "10",
  },
  {
    value: 15,
    text: "15",
  },
  {
    value: 20,
    text: "20",
  },
  {
    value: 25,
    text: "25",
  },
];

export default DashboardSettingsDropdown;
