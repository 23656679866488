import React, { ReactElement, useMemo } from "react";
import { Children } from "react";
import { TCondition, TConditionalProps, TDefault, TDefaultProps } from "./T";

interface Props {
  children: ReactElement<TConditionalProps | TDefaultProps>[];
}

export const TSwitch = ({ children }: Props) => {
  const child = useMemo(
    () =>
      (Children.toArray(children) as ReactElement<TConditionalProps | TDefaultProps>[]).find((child) => {
        if (!React.isValidElement(child)) {
          return false;
        }

        if (child.type !== TCondition && child.type !== TDefault) {
          throw Error("TSwitch: Child type not supported");
        }

        if (isTCondition(child)) {
          return child.props.condition;
        }

        if (isTDefault(child)) {
          return true;
        }

        return false;
      }),
    [children]
  );

  const alternatives = useMemo(
    () =>
      (Children.toArray(children) as ReactElement<TConditionalProps | TDefaultProps>[]).reduce(
        (ids: string[], child) => {
          if (React.isValidElement(child) && isT(child)) {
            return [...ids, "id" in child.props ? child.props.id : child.props.children];
          }

          return ids;
        },
        []
      ),
    [children]
  );

  const childWithProps = React.isValidElement(child)
    ? React.cloneElement(child, { alternativeIds: alternatives })
    : null;

  return childWithProps;
};

const isT = (
  child: ReactElement<TConditionalProps | TDefaultProps>
): child is ReactElement<TConditionalProps | TDefaultProps> => {
  return child.type === TCondition || child.type === TDefault;
};

const isTCondition = (
  child: ReactElement<TConditionalProps | TDefaultProps>
): child is ReactElement<TConditionalProps> => {
  return child.type === TCondition;
};

const isTDefault = (
  child: ReactElement<TConditionalProps | TDefaultProps>
): child is ReactElement<TDefaultProps> => {
  return child.type === TDefault;
};
