import cx from "classnames";
import "./Badge.scss";

interface Props {
  content?: string | React.ReactNode;
  absolute?: boolean;
  inline?: boolean;
}

export const Badge: React.FunctionComponent<Props> = ({
  children,
  content,
  absolute,
  inline = false,
}) => {
  return (
    <div className={cx("badge", { absolute, inline })}>
      {content || children}
    </div>
  );
};
