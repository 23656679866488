import { generatePath } from "react-router";
import { Link } from "../../../../../../components/links/Link";
import { Country } from "../../../../../../model/common/commonType";
import { PricingModel, PricingTemplate } from "../../../../../../model/pricing/pricingTypes";
import { getPricingModelDisplayName } from "../../../../../../model/pricing/pricingUtils";
import { PreSetRow } from "./PreSetRow";
import { FC } from "react";
import { ADMIN_NEW_PRICING_PAGE } from "../../AdminPresetPage/NewAdminPreSetPage";
import { Status } from "../../../../../../data/types";
import { SetterOrUpdater } from "recoil";

interface Props {
  preSets: PricingTemplate[];
  setPreSets: SetterOrUpdater<PricingTemplate[]>;
  pricingModel: PricingModel;
  country: Country;
  d3: Status;
  width: number;
}

export const PresetList: FC<Props> = ({ preSets, pricingModel, country, d3, width, setPreSets }) => {
  return (
    <div className="pre-set-list" style={{ marginBottom: 60 }}>
      <h4 className="m-bottom-20">{getPricingModelDisplayName(pricingModel)}</h4>
      <ul>
        {preSets.map((preSet) => {
          return (
            <li key={`${preSet.pricingModel}${preSet.templateId}`}>
              <PreSetRow setPreSets={setPreSets} preSet={preSet} country={country} width={width} d3={d3} />
            </li>
          );
        })}
        {preSets.length === 0 && (
          <li>
            <p>{`There are no ${getPricingModelDisplayName(pricingModel)} price plans for this country `}</p>

            <Link
              className="m-top-20"
              link={generatePath(ADMIN_NEW_PRICING_PAGE, {
                country,
              })}
            >
              Create new price
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};
