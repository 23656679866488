import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import { getDynamicLink } from "../../components/utils";
import { dataConfirm } from "../../data/dataConfirm";
import { ContractStatus } from "../../model/contract/contractType";
import { Status } from "../../data/types";
import {
  legalAssociatesSelector,
  primaryContactAssociatesSelector,
} from "../../state/contractAssociateState";
import { contractDocumentsState } from "../../state/contractDocumentsState";
import { contractMainContractDataState } from "../../state/contractMainContractDataState";
import { contractStatusState } from "../../state/contractStatusState";
import { MerchantStatusState, merchantStatusState } from "../../state/merchantStatusState";
import { PassSettings, Store, STORE_KEY } from "../../Store";
import { COMPLETED_CONTRACT_PAGE } from "../CompletedContract/CompletedContractPage";
import { Claim } from "../Contract/claim/Claim";
import { Timeline } from "./Timeline/Timeline";
import { SendReminderBox } from "./SendReminderBox/SendReminderBox";
import { ConfirmBox } from "./ConfirmBox/ConfirmBox";
import { RevokeBox } from "./RevokeBox/RevokeBox";
import styles from "./MerchantStatus.module.scss";
import ContentBox from "../../components/ContentBox/ContentBox";

export function MerchantStatus() {
  const { t } = useTranslation();
  const history = useHistory();

  const [contractStatus, setContractStatus] = useRecoilState(contractStatusState);
  const { companyName } = useRecoilValue(contractMainContractDataState);
  const primaryContact = useRecoilValue(primaryContactAssociatesSelector);
  const merchantStatus = useRecoilValue(merchantStatusState);
  const associates = useRecoilValue(legalAssociatesSelector);
  const documents = useRecoilValue(contractDocumentsState);

  const { contractId, completed } = contractStatus;
  const allConfirmed = associates.every((associate) => !!associate.signedOff);

  const [merchandiser, setMerchandiser] = useState<string>();
  const [direction, setDirection] = useState<string>();
  const [status, setStatus] = useState<Status>(completed ? Status.SUCCESS : Status.DEFAULT);

  useEffect(() => {
    const { direction, merchandiser } = Store.getValue<PassSettings>(STORE_KEY.STORE_PASS_SETTINGS);
    if (direction) setDirection(direction);
    if (merchandiser) setMerchandiser(merchandiser);
  }, [setDirection, setMerchandiser]);

  const onConfirm = async () => {
    if (!merchandiser || !direction) return;

    try {
      await dataConfirm.confirmContractComplete(contractId, merchandiser, direction);
      setStatus(Status.SUCCESS);
      setContractStatus((currStatus) => ({ ...currStatus, confirmed: true }));
      history.push(getDynamicLink(COMPLETED_CONTRACT_PAGE, { id: contractId }));
    } catch (err) {
      setStatus(Status.ERROR);
      setTimeout(() => setStatus(Status.DEFAULT), 4000);
    }
  };

  if (merchantStatus.status === ContractStatus.PENDING_SALES_INPUT) {
    const text = t("This contract has not yet been sent to merchant for confirmation");
    return <EmtpyMerchantStatus companyName={companyName} text={text} />;
  }

  if (!associates.length) {
    const text = t("There are no owners or signees to this contract. Should never happen.");
    return <EmtpyMerchantStatus companyName={companyName} text={text} />;
  }

  return (
    <>
      <div className={styles.root}>
        <h1 className="m-bottom-30">{companyName}</h1>
        <Claim />

        <div className={styles.content}>
          <ContentBox className={styles.timeline} padding="no">
            <Timeline />
          </ContentBox>

          <div className={styles.actions}>
            <SendReminderBox
              primaryContact={primaryContact}
              allConfirmed={allConfirmed}
              merchantStatus={merchantStatus}
              contractStatus={contractStatus}
              status={status}
            />
            <ConfirmBox
              status={status}
              contractStatus={contractStatus}
              passUserValues={{ merchandiser, direction }}
              onPassUserChange={({ merchandiser, direction }) => {
                setDirection(direction);
                setMerchandiser(merchandiser);
              }}
              associates={associates}
              documents={documents}
              onConfirm={onConfirm}
            />
            <RevokeBox contractStatus={contractStatus} merchantStatus={merchantStatus} status={status} />
          </div>
        </div>
      </div>
    </>
  );
}

interface EmtpyMerchantStautsProps {
  companyName: string | undefined;
  text: string;
}

export const EmtpyMerchantStatus: FC<EmtpyMerchantStautsProps> = ({ companyName, text }) => {
  return (
    <>
      <h1>{companyName}</h1>
      <p>{text}</p>
    </>
  );
};

export function getMerchantStatusEdit(merchantStatus: MerchantStatusState, edit: boolean, status: Status) {
  if (!edit) {
    return Status.DISABLED;
  }

  if (merchantStatus.contractCompleted) {
    return Status.DISABLED;
  }

  return status;
}
