import React, { useState } from "react";
import { Status } from "../../data/types";
import { Input } from "../../components/interactions/Input/Input";
import { Button } from "../../components/interactions/Buttons/Button";
import { dataContracts } from "../../data/dataContracts";
import { ContractId } from "../../model/common/commonType";
import "./HiddenClaimPage.scss";

export const HIDDEN_CLAIM_ROUTE = "/hidden-claim";

export const HiddenClaimPage: React.FunctionComponent = () => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [text, setText] = useState<string>("");

  const onClick = () => {
    setStatus(Status.PENDING);
    dataContracts
      .claimContract(text as ContractId)
      .then(() => setStatus(Status.SUCCESS))
      .catch(() => {
        setStatus(Status.ERROR);

        setTimeout(() => setStatus(Status.DEFAULT), 3000);
      });
  };

  return (
    <div className="hidden-claim">
      <section>
        <article>
          <h1>Claim contract</h1>
          <div className="m-bottom-20">
            <Input name="hidden-claim" onChange={setText} value={text} status={status} hint="Contract ID" />
          </div>

          <Button onClick={onClick} block status={status}>
            Claim
          </Button>
        </article>
      </section>
    </div>
  );
};
