import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../interactions/Buttons/Button";
import styles from "./Error.module.scss";

export function CasError() {
  const { t } = useTranslation();
  const reload = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div className={styles.root}>
      <h5>{t("Ooh noo!")} 😔</h5>
      <p className={styles.message}>
        {t(
          "The contract has been updated by someone else! You must reload to see the latest information"
        )}
      </p>
      <Button onClick={reload} block>
        {t("Reload page?")}
      </Button>
    </div>
  );
}
