import cx from "classnames";
import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { Beacon } from "../../../components/beacon/Beacon";
import { Link } from "../../../components/links/Link";
import { FormattedDate } from "../../../components/time/FormattedDate";
import { getDynamicLink } from "../../../components/utils";
import { AssociateTracking, CommunicationEvent, RemoteIdStatus } from "../../../data/dataConfirm";
import { Associate } from "../../../model/associate/associateTypes";
import { ContractId } from "../../../model/common/commonType";
import { BankAccount, Validity } from "../../../model/contract/contractType";
import {
  isAccountHolder as isAccountHolderFn,
  isOwner,
  isSignee as isSigneeFn,
} from "../../../state/contractAssociateState";
import { ASSOCIATE_REVEW_PAGE } from "../AssociateReview/AssociateReviewPage";
import "./AssociateListItem.scss";
import { EyeIcon } from "../../../components/icons/EyeIcon";
import { EditIcon } from "../../../components/icons/EditIcon";

interface Props {
  contractId: ContractId;
  associate: Associate;
  enableConfirmDetails: boolean;
  bankAccount: BankAccount;
  remoteIdStatus: RemoteIdStatus;
  associateTracking?: AssociateTracking;
}

function buildHeader(associate: Associate, t: TFunction<"translation">) {
  var header = "";
  if (isSigneeFn(associate)) {
    header += t("Legal representative");
  }
  if (isOwner(associate)) {
    if (header.length > 0) {
      header += ", ";
    }
    header += t("Beneficial Owner");
  }
  if (isAccountHolderFn(associate)) {
    if (header.length > 0) {
      header += ", ";
    }
    header += t("Bank Account Representative");
  }
  return header;
}

function getAssociateButton(
  associate: Associate,
  remoteIdStatus: RemoteIdStatus,
  t: TFunction<"translation">
) {
  const signedOff =
    remoteIdStatus.signedOffIdDocument !== undefined &&
    remoteIdStatus.signedOffSelfie !== undefined &&
    associate.signedOff !== undefined;

  if (signedOff) {
    return (
      <>
        <EyeIcon /> {t("View")}
      </>
    );
  }

  const idDocumentUploaded = remoteIdStatus.uploadedIdDocument !== undefined;
  const selfieUploaded = remoteIdStatus.uploadedSelfie !== undefined;

  if (isSigneeFn(associate)) {
    if (idDocumentUploaded && selfieUploaded && associate.signatory?.signature !== undefined) {
      return (
        <>
          <EditIcon /> {t("Confirm")}
        </>
      );
    }
  } else {
    if (idDocumentUploaded && selfieUploaded) {
      return (
        <>
          <EditIcon /> {t("Confirm")}
        </>
      );
    }
  }

  return (
    <>
      <EyeIcon /> {t("Waiting")}
    </>
  );
}

export const AssociateListItem: React.FunctionComponent<Props> = ({
  contractId,
  associate,
  enableConfirmDetails,
  bankAccount,
  remoteIdStatus,
  associateTracking,
}) => {
  const { t } = useTranslation();

  const isSignee = isSigneeFn(associate);
  const isAccountHolder = isAccountHolderFn(associate);
  const isSingleAccountHolder = isAccountHolder && associate.roles.length === 1;

  const hasViewdContract = associateTracking?.trackingLog
    //Reverse mutates..
    .slice()
    //We want the last occurance
    .reverse()
    .find((v) => {
      if (v.event === CommunicationEvent.CONTRACT_VIEWED) {
        return true;
      }
      return false;
    });

  let button = (
    <Link
      className={cx("event-button", "as-button", "event-button--absolute", {
        "is-disabled": !enableConfirmDetails && !associate.signedOff,
      })}
      link={getDynamicLink(ASSOCIATE_REVEW_PAGE, {
        contractId: contractId,
        associateId: associate.associateId,
      })}
    >
      {getAssociateButton(associate, remoteIdStatus, t)}
    </Link>
  );

  if (isSingleAccountHolder) {
    return (
      <li
        className={cx("associate-list-item", {
          enableConfirmDetails: "active",
        })}
      >
        <div className="relative">
          <div className="associate-item-name">
            {`${associate.firstName} ${associate.lastName}`},{" "}
            <span className="timeline-item-type">{t("Account holder")}</span>
          </div>

          <div className="associate-item-signature">
            <Beacon
              className="aligned"
              mini
              validity={bankAccount.iban ? Validity.VALID : Validity.MISSING}
            />{" "}
            <span>{t("Verified bank account")}</span>
          </div>

          <div className="associate-item-signature">
            <Beacon className="aligned invisible" mini validity={Validity.VALID} />{" "}
            <div className="truncated">IBAN: {bankAccount.iban || "-"}</div>
          </div>

          <div className="associate-item-signature">
            <Beacon className="aligned invisible" mini validity={Validity.VALID} />{" "}
            <div className="truncated">Account holder: {bankAccount.accountHolder || "-"}</div>
          </div>
        </div>
      </li>
    );
  }

  return (
    <li className={cx("associate-list-item", "active")}>
      {button}
      <div className="relative">
        <div className="associate-item-name">
          {`${associate.firstName} ${associate.lastName}`},{" "}
          <span className="timeline-item-type">{buildHeader(associate, t)}</span>
        </div>

        <div className="associate-item-signature">
          <Beacon
            className="aligned"
            mini
            validity={
              associateTracking === undefined
                ? Validity.MISSING
                : hasViewdContract
                ? Validity.VALID
                : //Message sent, not viewed
                  Validity.DEFAULT
            }
          />{" "}
          <span>
            {t("Viewed contract")} -{" "}
            {hasViewdContract ? <FormattedDate date={hasViewdContract.created} /> : null}
          </span>
        </div>
        {isAccountHolder && (
          <div className="associate-item-signature">
            <Beacon
              className="aligned"
              mini
              validity={bankAccount.iban ? Validity.VALID : Validity.MISSING}
            />{" "}
            <span>{t("Added payout account")}</span>
          </div>
        )}

        <div className="associate-item-docs">
          <Beacon
            className="aligned"
            mini
            validity={remoteIdStatus.uploadedIdDocument ? Validity.VALID : Validity.MISSING}
          />{" "}
          <span>{t("Identification uploaded")}</span>
        </div>
        <div className="associate-item-docs">
          <Beacon
            className="aligned"
            mini
            validity={remoteIdStatus.uploadedSelfie ? Validity.VALID : Validity.MISSING}
          />{" "}
          <span>{t("Selfie uploaded")}</span>
        </div>

        {isSignee && (
          <div className="associate-item-signature">
            <Beacon
              className="aligned"
              mini
              validity={associate.signatory?.signature ? Validity.VALID : Validity.MISSING}
            />{" "}
            <span>{t("Signature uploaded")}</span>
          </div>
        )}
        <div className="associate-item-confirmed">
          <Beacon
            className="aligned"
            mini
            validity={associate.signedOff ? Validity.VALID : Validity.MISSING}
          />{" "}
          <span>
            {associate.signedOff
              ? t("Signed off by {{name}}", { name: associate.signedOffBy })
              : t("Signed off")}
          </span>
        </div>
      </div>
    </li>
  );
};
