import React from "react";
import cx from "classnames";
import { ContractDocument, ContractStatus } from "../../../model/contract/contractType";
import { MerchantStatusState } from "../../../state/merchantStatusState";
import { Associate } from "../../../model/associate/associateTypes";
import { getConfirmedOffset } from "./ToAssociates";

interface Props {
  associates: Associate[];
  merchantStatus: MerchantStatusState;
  documents: ContractDocument[];
}

export const Complete: React.FunctionComponent<Props> = ({ associates, merchantStatus, documents }) => {
  const offset = getConfirmedOffset(associates, documents);

  return (
    <circle
      r="6"
      cx="10"
      cy={offset}
      className={cx({
        active: merchantStatus.status === ContractStatus.COMPLETE,
      })}
    />
  );
};
