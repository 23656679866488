import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { FileType, IdImageInterface } from "../../../components/fileUpload/FileUpload";
import { External } from "../../../components/icons/External";
import { Link } from "../../../components/links/Link";
import { Status } from "../../../data/types";
import "./IdImage.scss";

interface Props {
  image: IdImageInterface;
  status: Status;
  isGeneric?: boolean;
}

export const IdImage: React.FunctionComponent<Props> = ({ isGeneric, image, status }) => {
  const { t } = useTranslation();

  if (status === Status.ERROR) {
    return (
      <div className="id-image default-error-box">
        {t("Something went wrong. We couldn't upload the image")}
      </div>
    );
  }

  if (!image.url) {
    if (isGeneric) {
      return (
        <div className="id-image">
          <i className="fw-500 text-passive fs-small">{t("No file has yet been uploaded")}</i>
        </div>
      );
    }

    return (
      <div className="id-image">
        <i>{t("No file has yet been uploaded for this user")}</i>
      </div>
    );
  }

  if (image.type === FileType.PDF) {
    return (
      <div className="id-image pdf-image">
        <object data={image.url} type="application/pdf" width="100%" height="650px">
          <a className={cx("link", "external-link")} href={image.url} target="_blank" rel="noreferrer">
            {t("PDF-viewer not supported. Download here")}:
            <External />
          </a>
        </object>
      </div>
    );
  }

  return (
    <div className="id-image">
      <img src={image.url} alt="identification" />
      <div>
        <Link external link={image.url}>
          {t("View in new tab")}
        </Link>
      </div>
    </div>
  );
};
