import { data } from "./data";

const BASE_URL = "/api/admin/report";

export enum REPORT_MONTH {
  JANUARY = "JANUARY",
  FEBRUARY = "FEBRUARY",
  MARCH = "MARCH",
  APRIL = "APRIL",
  MAY = "MAY",
  JUNE = "JUNE",
  JULY = "JULY",
  AUGUST = "AUGUST",
  SEPTEMBER = "SEPTEMBER",
  OCTOBER = "OCTOBER",
  NOVEMBER = "NOVEMBER",
  DECEMBER = "DECEMBER",
}

export const dataPeriodicReport = {
  sendTestReport: (recipients: string[], month?: REPORT_MONTH) =>
    data.post<void>(`${BASE_URL}${month ? `?month=${month}` : ""}`, recipients),

  getRecipients: () => data.get<string[]>(`${BASE_URL}/recipients`),
};
