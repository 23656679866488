import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Table } from "../../../components/table/Table";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Input } from "../../../components/interactions/Input/Input";
import { AdminMerchantCategory, dataMccAdministration } from "../../../data/dataMccAdministration";
import { MccRiskLevel } from "../../../model/contract/contractType";
import { Status } from "../../../data/types";
import { AdminPage } from "../AdminPage";
import { AddMccModal } from "./AddMccModal";
import { Edit } from "../../../components/icons/Edit";
import { Pending } from "../../../components/icons/Pending";
import styles from "./MccAdminPage.module.scss";
import { CheckCircleOutline } from "../../../components/icons/CheckCircleOutline";
import { WarningOutline } from "../../../components/icons/WarningOutline";
import { ErrorOutline } from "../../../components/icons/ErrorOutline";
import { SiMicrosoftexcel } from "react-icons/si";

export const MCC_ADMIN_PAGE = "/admin/mcc";

export const MccAdministrationPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [codes, setCodes] = useState<AdminMerchantCategory[]>([]);
  const [mccFilter, setMccFilter] = useState<string>("");
  const [sortField, setSortField] = useState("code");
  const [sortDirection, setSortDirection] = useState<"ascending" | "descending">("ascending");
  const [selectedMcc, setSelectedMcc] = useState<AdminMerchantCategory | null>(null);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const [loading, setLoading] = useState(false);
  const updateData = useCallback(() => {
    setLoading(true);
    dataMccAdministration
      .loadAll()
      .then(setCodes)
      .finally(() => setLoading(false));
  }, [setCodes, setLoading]);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const sortField = search.get("sortField");
    const sortDirection = search.get("sortDirection");

    if (sortField !== null) {
      setSortField(sortField);
    }
    if (sortDirection !== null && (sortDirection === "ascending" || sortDirection === "descending")) {
      setSortDirection(sortDirection);
    }
    updateData();
  }, [updateData, setSortDirection, setSortField, location]);

  const onSortChange = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement>) => {
      const property = (ev.target as HTMLButtonElement).dataset.id || "name";
      setSortField(property);

      const search = new URLSearchParams(location.search);
      search.set("sortField", property);

      const direction = sortDirection === "ascending" ? "descending" : "ascending";
      search.set("sortDirection", direction);
      setSortDirection(direction);
      history.push({
        pathname: location.pathname,
        search: search.toString(),
      });
    },
    [history, location, sortDirection]
  );

  const isSorted = (property: string) => {
    if (sortField === property) {
      return sortDirection;
    }
    return undefined;
  };

  const onClickEditMcc = (mcc: AdminMerchantCategory) => {
    setSelectedMcc(mcc);
    setModalIsOpen(true);
  };

  const onClickAddMcc = () => {
    setSelectedMcc(null);
    setModalIsOpen(true);
  };

  const getRowClass = (faceToFace: MccRiskLevel, cardNotPresent: MccRiskLevel) => {
    const isNotAllowed =
      faceToFace === MccRiskLevel.NOT_ALLOWED && cardNotPresent === MccRiskLevel.NOT_ALLOWED;

    const isRestricted =
      (faceToFace === MccRiskLevel.RISK_APPROVAL && cardNotPresent !== MccRiskLevel.NOT_ALLOWED) ||
      (cardNotPresent === MccRiskLevel.RISK_APPROVAL && faceToFace !== MccRiskLevel.NOT_ALLOWED);

    const isAccepted = faceToFace === MccRiskLevel.NONE && cardNotPresent === MccRiskLevel.NONE;

    if (isNotAllowed) return styles.forbidden;
    if (isAccepted) return styles.accepted;
    if (isRestricted) return styles.restricted;
    return styles.incapable;
  };

  return (
    <AdminPage title="MCC Administration" className="mcc-admin">
      <AddMccModal
        open={modalIsOpen}
        onSave={updateData}
        onClose={() => setModalIsOpen(false)}
        mccData={selectedMcc}
      />
      <div className={styles.body}>
        <section>
          <article>
            <h4 className="m-bottom-30">MCC administration</h4>
            <div className={styles.actions_row}>
              <div className={styles.search_container}>
                <Input
                  name="mccFilter"
                  value={mccFilter}
                  placeholder=""
                  status={loading ? Status.DISABLED : Status.DEFAULT}
                  label="Search for MCC"
                  onChange={(value) => {
                    setMccFilter(value);
                  }}
                />
              </div>

              <div className={styles.add_container}>
                <Button block onClick={onClickAddMcc} status={loading ? Status.DISABLED : Status.DEFAULT}>
                  Add MCC
                </Button>
              </div>

              <div className={styles.upload_container}>
                <label htmlFor="document">
                  <Button
                    block
                    ghost
                    onClick={handleUploadClick}
                    status={loading ? Status.DISABLED : Status.DEFAULT}
                  >
                    <SiMicrosoftexcel style={{ marginRight: 8, fontSize: "1.2em" }} />
                    Upload Excel
                  </Button>
                </label>
                <input
                  type="file"
                  onChange={(event) => {
                    if (!event.target.files) {
                      return;
                    }
                    const selectedFile = event.target.files[0];
                    setLoading(true);

                    const formData = new FormData();

                    formData.append("document", selectedFile, selectedFile.name);

                    dataMccAdministration
                      .uploadExcel(formData)
                      .then(() => {
                        updateData();
                      })
                      .finally(() => setLoading(false));
                  }}
                  ref={hiddenFileInput}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  style={{ display: "none" }}
                  id="document"
                />
              </div>
            </div>
          </article>

          <article className="m-top-20">
            <Table className={styles.mcc_table}>
              <thead>
                <tr>
                  <th />
                  <th>
                    <button className={isSorted("code")} onClick={onSortChange} data-id="code">
                      MCC
                    </button>
                  </th>
                  <th>
                    <button className={isSorted("name")} onClick={onSortChange} data-id="name">
                      Description
                    </button>
                  </th>
                  <th>
                    <button className={isSorted("faceToFace")} onClick={onSortChange} data-id="faceToFace">
                      Face to Face
                    </button>
                  </th>
                  <th>
                    <button
                      className={isSorted("cardNotPresent")}
                      onClick={onSortChange}
                      data-id="cardNotPresent"
                    >
                      Card not present
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {codes
                  .filter((row) => {
                    if (mccFilter.length > 0) {
                      return (
                        row.code.indexOf(mccFilter) > -1 ||
                        row.name.indexOf(mccFilter) > -1 ||
                        row.category.indexOf(mccFilter) > -1
                      );
                    }
                    return true;
                  })
                  .sort((r1, r2) => {
                    if (sortField.length === 0) {
                      return 0;
                    }

                    const val1 = r1[sortField as keyof AdminMerchantCategory] as string;
                    const val2 = r2[sortField as keyof AdminMerchantCategory] as string;

                    if (val1 > val2) {
                      return 1 * (sortDirection === "descending" ? -1 : 1);
                    }
                    if (val1 < val2) {
                      return -1 * (sortDirection === "descending" ? -1 : 1);
                    }
                    return 0;
                  })
                  .map((row) => {
                    const className = getRowClass(row.cardNotPresent, row.faceToFace);

                    return (
                      <tr key={row.id} className={className}>
                        <td>
                          <Button
                            action
                            className="mini"
                            data={{ id: row.id }}
                            onClick={() => onClickEditMcc(row)}
                          >
                            <Edit />
                          </Button>
                        </td>

                        <td>{row.code}</td>
                        <td>{row.name}</td>
                        <td>{prettyPrint(row.faceToFace)}</td>
                        <td>{prettyPrint(row.cardNotPresent)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {loading ? (
              <div className="center">
                <Pending />
              </div>
            ) : codes.length === 0 ? (
              "No codes available"
            ) : null}
            <hr />
          </article>
        </section>
      </div>
    </AdminPage>
  );
};

const prettyPrint = (riskLevel: MccRiskLevel) => {
  switch (riskLevel) {
    case MccRiskLevel.NONE:
      return (
        <span className={`${styles.risk_level} ${styles.green}`}>
          <CheckCircleOutline />
          None
        </span>
      );
    case MccRiskLevel.NOT_ALLOWED:
      return (
        <span className={`${styles.risk_level} ${styles.red}`}>
          <ErrorOutline />
          Not Allowed
        </span>
      );
    case MccRiskLevel.RISK_APPROVAL:
      return (
        <span className={`${styles.risk_level} ${styles.warning}`}>
          <WarningOutline />
          Risk Approval
        </span>
      );
  }
};
