import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import cx from "classnames";
import { IBAN } from "ibankit";
import { TFunction } from "i18next";
import { useTranslation, Trans } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AnimateHeight } from "../../../../../components/animate/AnimateHeight";
import { ErrorBox } from "../../../../../components/boxes/ErrorBox";
import { IdImageInterface } from "../../../../../components/fileUpload/FileUpload";
import { Form } from "../../../../../components/form/Form";
import { TextInput } from "../../../../../components/form/TextInput";
import { IbanValidator } from "../../../../../components/form/validators/IbanValidator";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { ConfirmButton, ConfirmType } from "../../../../../components/interactions/Buttons/ConfirmButton";
import { Visible } from "../../../../../components/visible/Visible";
import { dataContracts } from "../../../../../data/dataContracts";
import { ContractDocument } from "../../../../../model/contract/contractType";
import { documentTypeDisplay, documentTypeDescription } from "../../../../../model/contract/contractUtils";

import { Status } from "../../../../../data/types";
import { contractAssociateState } from "../../../../../state/contractAssociateState";
import { contractBankState } from "../../../../../state/contractBankState";
import { contractDocumentsState } from "../../../../../state/contractDocumentsState";
import { contractMainContractDataState } from "../../../../../state/contractMainContractDataState";
import { ContractStatusState, contractStatusState } from "../../../../../state/contractStatusState";
import { ConfirmDocImageAndStatuses } from "../ConfirmDocImageAndStatuses";
import { Select } from "../../../../../components/form/Select";
import { dataConfirm } from "../../../../../data/dataConfirm";
import "./ConfirmBankStatement.scss";
import { getFileAttributes } from "../../../../../components/utils";

interface Props {
  document: ContractDocument;
  onClose: () => void;
}

const AdditionalDocInfo: React.FunctionComponent<{
  document: ContractDocument;
}> = ({ document }) => {
  const { t } = useTranslation();
  const bank = useRecoilValue(contractBankState);
  const associates = useRecoilValue(contractAssociateState);
  const mainData = useRecoilValue(contractMainContractDataState);

  if (!document.fileAvailable) {
    return null;
  }

  const holder = associates.find((associate) => associate.associateId === bank.accountHolder);

  let name;
  if (holder) {
    name = `${holder.firstName} ${holder.lastName}`;
  } else {
    name = mainData.companyName;
  }

  if (!bank.iban) {
    return (
      <Visible show={!document.confirmed}>
        <div className="m-top-10">
          <ErrorBox relative>
            <Trans>
              This bank account was <b>not verified</b> by Klarna. It must be manually verified.
            </Trans>
          </ErrorBox>
        </div>
      </Visible>
    );
  }

  return (
    <dl className="fs-small">
      <dt>
        <strong>{t("From the contract")}:</strong>
      </dt>
      <dd />
      <dt>{t("IBAN")}:</dt>
      <dd>{bank.iban}</dd>
      <dt>{t("Bank")}:</dt>
      <dd>{bank.bank}</dd>
      <dt>{t("Account holder")}:</dt>
      <dd>{name}</dd>
    </dl>
  );
};

export const VerifyAccountManually: React.FunctionComponent<{
  onSave: () => void;
  contractStatus: ContractStatusState;
  onReject: () => void;
  rejectStatus: Status;
  confirmStatus: Status;
  document: ContractDocument;
}> = ({ onSave, contractStatus, onReject, rejectStatus, confirmStatus, document }) => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [showBicAndBank, setShowBicAndBank] = useState<boolean>(false);
  const associates = useRecoilValue(contractAssociateState);
  const [bank, setBank] = useRecoilState(contractBankState);
  const mainData = useRecoilValue(contractMainContractDataState);
  const { t } = useTranslation();

  useEffect(() => {
    if (!bank.iban) {
      return;
    }

    if (!IBAN.isValid(bank.iban)) {
      return;
    }

    setStatus(Status.PENDING);

    dataContracts
      .lookupBicAndBank(bank.iban, contractStatus.contractId)
      .then((lookupResult) => {
        setStatus(Status.DEFAULT);

        if (lookupResult.success) {
          setShowBicAndBank(false);
          setBank((prevBank) => ({
            ...prevBank,
            bic: lookupResult.bic,
            bank: lookupResult.bank,
          }));
        } else {
          setShowBicAndBank(true);
          setBank((prevBank) => ({
            ...prevBank,
            bic: undefined,
            bank: undefined,
          }));
        }
      })
      .catch((err) => {
        setStatus(Status.DEFAULT);
        console.error(err);
      });
  }, [bank.iban, contractStatus.contractId, setBank]);

  const opts = useMemo(() => {
    if (!mainData.companyName) {
      return [];
    }

    const options = [
      {
        text: t("Select account holder"),
        value: "",
        disabled: true,
      },
      {
        text: `${t("Company")}: ${mainData.companyName}`,
        value: "COMPANY",
      },
    ];

    associates.forEach((item) => {
      if (!item.firstName && !item.lastName) {
        return;
      }

      let name;
      if (item.firstName && item.lastName) {
        name = `${item.firstName} ${item.lastName}`;
      } else if (item.firstName) {
        name = item.firstName;
      } else {
        name = item.lastName;
      }

      options.push({
        text: name || "No name",
        value: item.associateId,
      });
    });

    return options;
  }, [mainData.companyName, associates, t]);

  if (!document.fileAvailable) {
    return null;
  }

  return (
    <Form
      onSubmit={(event, form) => {
        if (form.isValid) {
          onSave();
        }
      }}
    >
      <div className="tablet-columns m-top-20">
        <div>
          <TextInput
            onChange={(val, name) => {
              setBank((prevBank) => ({
                ...prevBank,
                [name]: val,
              }));
            }}
            label="IBAN"
            value={bank.iban}
            name="iban"
            validators={[
              new RequiredValidator(t("IBAN is required")),
              new IbanValidator(t("IBAN is invalid")),
            ]}
          />
        </div>
        <div>
          <Select
            onChange={(val) =>
              setBank((prevBank) => ({
                ...prevBank,
                accountHolder: val,
              }))
            }
            label={t("Account Holder")}
            alternatives={opts}
            value={bank.accountHolder}
            hint={t("Owner of the account")}
            validators={[new RequiredValidator(t("Account owner is required"))]}
          />
        </div>

        <div>
          <TextInput
            onChange={(val, name) => {
              setBank((prevBank) => ({
                ...prevBank,
                [name]: val,
              }));
            }}
            label={t("Bank")}
            value={bank.bank}
            name="bank"
            validators={[new RequiredValidator(t("Bank is required"))]}
            attributes={{
              tabIndex: showBicAndBank ? 0 : -1,
            }}
            disabled={!showBicAndBank}
          />
        </div>
        <div>
          <TextInput
            onChange={(val, name) => {
              setBank((prevBank) => ({
                ...prevBank,
                [name]: val,
              }));
            }}
            label="BIC"
            validators={[new RequiredValidator(t("Bic is required"))]}
            value={bank.bic}
            name="bic"
            attributes={{
              tabIndex: showBicAndBank ? 0 : -1,
            }}
            disabled={!showBicAndBank}
          />
        </div>
      </div>

      <AnimateHeight name={showBicAndBank ? "show" : "hide"}>
        {showBicAndBank ? (
          <Trans>
            The account has to be a transaction account. <b>BIC</b> and <b>Bank</b> are mandatory fields. If
            e.g. <b>BIC</b> is missing the contract has to be rejected and the merchant will have to upload
            document for an alternative account.
          </Trans>
        ) : (
          <div />
        )}
      </AnimateHeight>
      <div className="tablet-columns m-top-20">
        <div>
          <ConfirmButton ghost onClick={onReject} removeButtonText={t("Yes, reject")} status={rejectStatus}>
            {t("Reject document")}
          </ConfirmButton>
        </div>
        <div>
          <Button
            className="small confirm-button"
            block
            type="submit"
            status={status === Status.PENDING ? status : confirmStatus}
          >
            {t("Confirm document")}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export const ConfirmButtons: React.FunctionComponent<{
  document: ContractDocument;
  t: TFunction;
  rejectStatus: Status;
  onReject: () => void;
  confirmStatus: Status;
  onConfirm: () => void;
}> = ({ document, onReject, t, rejectStatus, onConfirm, confirmStatus }) => {
  return (
    <>
      <Visible show={!!(document.fileAvailable && !document.confirmed)}>
        <div className="tablet-columns">
          <div>
            <ConfirmButton ghost onClick={onReject} removeButtonText={t("Yes, reject")} status={rejectStatus}>
              {t("Reject document")}
            </ConfirmButton>
          </div>
          <div>
            <ConfirmButton
              onClick={onConfirm}
              removeButtonText={t("Yes, confirm")}
              confirmType={ConfirmType.SUCCESS}
              status={confirmStatus}
            >
              {t("Confirm document")}
            </ConfirmButton>
          </div>
        </div>
      </Visible>
    </>
  );
};

export const ConfirmBankStatement: React.FunctionComponent<Props> = ({ document, onClose }) => {
  const { t } = useTranslation();
  const bank = useRecoilValue(contractBankState);
  const bankVerified = useRef(!!bank.iban);
  const setDocuments = useSetRecoilState(contractDocumentsState);
  const contractStatus = useRecoilValue(contractStatusState);
  const [rejectStatus, setRejectStatus] = useState<Status>(Status.DEFAULT);
  const [confirmStatus, setConfirmStatus] = useState<Status>(
    document.confirmed ? Status.SUCCESS : Status.DEFAULT
  );

  const originalImage = useRef<IdImageInterface>(getFileAttributes(contractStatus, document));

  const onReject = useCallback(() => {
    setRejectStatus(Status.PENDING);

    dataConfirm
      .rejectUploadedDocument(contractStatus.contractId, document.id)
      .then((response) => {
        setRejectStatus(Status.SUCCESS);
        setDocuments(response);
      })
      .catch((err) => {
        setRejectStatus(Status.ERROR);
        setTimeout(() => setRejectStatus(Status.DEFAULT), 4000);
      });
  }, [contractStatus.contractId, document.id, setDocuments]);

  const onConfirm = useCallback(() => {
    setConfirmStatus(Status.PENDING);

    dataConfirm
      .confirmUploadedDocument(contractStatus.contractId, document.id)
      .then((response) => {
        setConfirmStatus(Status.SUCCESS);
        setDocuments(response);
      })
      .catch((err) => {
        setConfirmStatus(Status.ERROR);
        setTimeout(() => setConfirmStatus(Status.DEFAULT), 4000);
      });
  }, [contractStatus.contractId, document.id, setDocuments]);

  const onSave = useCallback(() => {
    // Todo
    onConfirm();
  }, [onConfirm]);

  return (
    <div
      className={cx("confirm-doc", {
        "is-confirmed": !!document.confirmed,
        "show-status": confirmStatus !== Status.DEFAULT || rejectStatus !== Status.DEFAULT,
      })}
    >
      <h4>{t(documentTypeDisplay(document.documentType))}</h4>
      <p className="document-description">
        {document.documentDescription
          ? document.documentDescription
          : t(documentTypeDescription(document.documentType))}
      </p>
      <AdditionalDocInfo document={document} />

      <div className="m-top-30">
        <ConfirmDocImageAndStatuses
          originalImage={originalImage.current}
          rejectStatus={rejectStatus}
          confirmStatus={confirmStatus}
          document={document}
        />
      </div>

      {bankVerified.current ? (
        <AnimateHeight className="m-top-20" name={!!document.confirmed ? "confirmed" : "not-confirmed"}>
          {!!document.confirmed || rejectStatus === Status.SUCCESS ? (
            <Button ghost onClick={onClose} block className="small close-button">
              {t("Close")}
            </Button>
          ) : (
            <ConfirmButtons
              onConfirm={onConfirm}
              onReject={onReject}
              confirmStatus={confirmStatus}
              rejectStatus={rejectStatus}
              t={t}
              document={document}
            />
          )}
        </AnimateHeight>
      ) : (
        <AnimateHeight name={!!document.confirmed ? "confirmed" : "not-confirmed"}>
          {!!document.confirmed ? (
            <Button ghost onClick={onClose} block className="small close-button">
              {t("Close")}
            </Button>
          ) : (
            <VerifyAccountManually
              contractStatus={contractStatus}
              onSave={onSave}
              onReject={onReject}
              rejectStatus={rejectStatus}
              document={document}
              confirmStatus={confirmStatus}
            />
          )}
        </AnimateHeight>
      )}
    </div>
  );
};
