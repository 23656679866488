import { FC } from "react";
import { PricingTemplate, SteppedBasedPricing } from "../../../../../../../model/pricing/pricingTypes";
import { SetEditablePricing } from "../PricingInputs";
import { Trans, useTranslation } from "react-i18next";

import { Country } from "../../../../../../../model/common/commonType";
import styles from "./CashlessPromotionAdmin.module.scss";
import { WarningBox } from "../../../../../../../components/boxes/WarningBox";
import { RadioGroup } from "../../../../../../../components/form/RadioGroup";
import { RequiredValidator } from "../../../../../../../components/form/validators/RequiredValidator";
import { AnimatePresence } from "framer-motion";
import FadeAnimation from "../../../../../../../components/animate/FadeAnimation";
import ContentBox from "../../../../../../../components/ContentBox/ContentBox";
import {
  CASHLESS_PROMOTION_MONTHS,
  INITIAL_PRICING_STEPS,
} from "../../../../../../../model/pricing/pricingUtils";

type TemplateProps = Pick<PricingTemplate, "cashlessPromotion">;

interface Props extends TemplateProps {
  setEditablePricing: SetEditablePricing;
  country: Country;
  disabled?: boolean;
}

const CashlessPromotionAdmin: FC<Props> = ({
  cashlessPromotion,
  setEditablePricing,
  country,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const onChangeCashless = (value: string) => {
    const isYes = value === YES;

    setEditablePricing((prev) => ({
      ...prev,
      cashlessPromotion: isYes ? true : false,
      promotionMonths: isYes ? CASHLESS_PROMOTION_MONTHS[country] : 0,
      steppedBasedPricing: isYes
        ? addCashlessPromotionToSteppedBasedPricing(prev.steppedBasedPricing)
        : removeCashlessPromotionToSteppedBasedPricing(prev.steppedBasedPricing),
    }));
  };

  return (
    <ContentBox>
      <h5 style={{ margin: 0 }}>Pre-select cashless promotion</h5>
      <WarningBox className="m-y-20">
        <Trans>
          By pre-selecting a value on cashless promotion here, this option will be <strong>uneditable</strong>{" "}
          for the sales rep selecting this price plan.
        </Trans>
      </WarningBox>
      <div className="tablet-columns">
        <RadioGroup
          disabled={disabled}
          label={t("Is this a cashless promotion price plan?")}
          onChange={onChangeCashless}
          alternatives={radioAlts}
          value={cashlessPromotion ? YES : NO}
          validators={[new RequiredValidator(t("Cashless is required"))]}
        />
        <AnimatePresence exitBeforeEnter>
          {cashlessPromotion ? (
            <FadeAnimation className={styles.center} key={"yes-cashless"}>
              <i className="text-passive fw-500">
                {t("The cashless promotion (government program) for {{ country }} is 12 promotion months", {
                  country,
                })}
              </i>
            </FadeAnimation>
          ) : (
            <FadeAnimation className={styles.center} key={"no-cashless"}>
              <i className="text-passive fw-500">{t("This price plan will have no cashless promotion")}</i>
            </FadeAnimation>
          )}
        </AnimatePresence>
      </div>
    </ContentBox>
  );
};

export default CashlessPromotionAdmin;

const YES = "YES";
const NO = "NO";

const radioAlts = [
  {
    text: "Yes",
    value: YES,
  },
  {
    text: "No",
    value: NO,
  },
];

const addCashlessPromotionToSteppedBasedPricing = (
  prev: SteppedBasedPricing | undefined
): SteppedBasedPricing | undefined => {
  if (!prev) return undefined;
  return { ...prev, cashlessPromotionPricing: [...INITIAL_PRICING_STEPS] };
};

const removeCashlessPromotionToSteppedBasedPricing = (
  prev: SteppedBasedPricing | undefined
): SteppedBasedPricing | undefined => {
  if (!prev) return undefined;
  return { ...prev, cashlessPromotionPricing: undefined };
};
