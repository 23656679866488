import { Dispatch } from "react";
import { createContext, useContext } from "react";
import { Validity } from "../../model/contract/contractType";
import { useDebounceValue } from "../../hooks/useDebounce";
import { MultiFormContainer } from "./MultiForm";
import { FormName } from "../../pages/Contract/menus/ContractEditMenu";

type SetFormValidityAction = {
  action: "SET";
  form: string;
  isValid: boolean;
};

type DeleteFormValidationAction = {
  action: "DELETE";
  form: string;
};

export type FormValidationAction = SetFormValidityAction | DeleteFormValidationAction;
export interface MultiFormContextInterface {
  multiForm: MultiFormContainer;
  validity: Record<string, boolean>;
  setValidity: Dispatch<FormValidationAction>;
}

export const MultiFormContext = createContext<MultiFormContextInterface | null>(null);

export function useMultiForm() {
  const context = useContext(MultiFormContext);
  return context?.multiForm;
}

export function useFormValidity(formName: FormName, debounceTime = 200) {
  const context = useContext(MultiFormContext);
  const isFormValid = getIsFormValid(formName, context?.validity);

  let status = Validity.DEFAULT;

  if (isFormValid) {
    status = Validity.VALID;
  } else if (context?.multiForm.forceErrors) {
    status = Validity.MISSING;
  }

  const debouncedValue = useDebounceValue(status, debounceTime);
  return debouncedValue;
}

function getIsFormValid(formName: FormName, forms: Record<string, boolean> | undefined) {
  if (typeof forms === "undefined") {
    return false;
  }

  return Object.entries(forms)
    .filter(([name]) => name.startsWith(formName))
    .every(([name, isValid]) => isValid);
}
