import React, { FunctionComponent, useState } from "react";
import "./HelpModal.scss";
import { NewOverlay } from "../overlay/NewOverlay";
import { HelpIcon } from "../icons/HelpIcon";

interface Props {}

export const HelpModal: FunctionComponent<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <React.Fragment>
      <button type="button" className="help-modal-button" onClick={() => setIsOpen(true)}>
        {/* <MdOutlineLiveHelp color={"var(--link)"} size={24} /> */}
        <HelpIcon />
      </button>
      <NewOverlay open={isOpen} onClose={() => setIsOpen(false)}>
        {children}
      </NewOverlay>
    </React.Fragment>
  );
};
