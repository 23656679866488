import { data } from "./data";
import { ContractId, UTCDate } from "../model/common/commonType";

export enum EmailTemplate {
  INVITE_TO_CONFIRM = "INVITE_TO_CONFIRM",
  INVITE = "INVITE",
  RESUBMIT_DOCUMENTS = "RESUBMIT_DOCUMENTS",
  CONTRACT_COMPLETED = "CONTRACT_COMPLETED",
}

export enum SmsTemplate {
  INVITE_TO_CONFIRM = "INVITE_TO_CONFIRM",
  INVITE = "INVITE",
  RESUBMIT_DOCUMENTS = "RESUBMIT_DOCUMENTS",
}

export interface TestEmailRequest {
  emailTemplate: EmailTemplate;
  language: string;
}

export interface TestSmsRequest {
  number: string;
  template: SmsTemplate;
  language: string;
}

export interface ArchiveDetails {
  companyName: string;
  canBeUploaded: boolean;
  uploaded?: UTCDate;
}

export const dataAdminCommunication = {
  sendTestEmail: (request: TestEmailRequest) => {
    return data.post("/api/admin/communication/email", request);
  },

  sendTestSms: (request: TestSmsRequest) => {
    return data.post("/api/admin/communication/sms", request);
  },

  checkArchiving: () => data.get<{ up: boolean; folder: string[] }>("/api/admin/archive/test"),

  postArchiving: (contractId: ContractId) => data.post<boolean>(`/api/admin/archive/upload/${contractId}`),

  loadContractArchiveDetails: (contractId: ContractId) =>
    data.get<ArchiveDetails>(`/api/admin/archive/${contractId}`),
};
