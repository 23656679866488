import React, { useCallback, useRef, useEffect } from "react";
import cx from "classnames";
import { Checkmark } from "../../icons/Checkmark";
import { Error as ErrorIcon } from "../../icons/Error";
import { Pending as PendingIcon } from "../../icons/Pending";
import { Disabled as DisabledIcon } from "../../icons/Disabled";
import { Status } from "../../../data/types";
import "./Button.scss";
import { Transition, motion } from "framer-motion";

function getIcon(status: Status) {
  if (status === Status.ERROR) {
    return <ErrorIcon />;
  }

  if (status === Status.SUCCESS) {
    return <Checkmark />;
  }

  if (status === Status.PENDING) {
    return <PendingIcon />;
  }

  if (status === Status.DISABLED) {
    return <DisabledIcon />;
  }

  return null;
}

type ButtonVariants = "primary" | "outlined" | "text" | "CTA";
type ButtonColors = "primary" | "danger" | "warning" | "secondary" | "mock";

interface BaseProps {
  children: React.ReactNode;
  className?: string;
  data?: any;
  block?: boolean;
  ghost?: boolean;
  status?: Status;
  action?: boolean;
  link?: boolean;
  tabIndex?: number;
  variant?: ButtonVariants;
  color?: ButtonColors;
}

interface ButtonProps extends BaseProps {
  onClick: (data: any, event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: "button" | "reset";
}

interface SubmitProps extends BaseProps {
  type: "submit";
}

function isSubmit(tested: ButtonProps | SubmitProps): tested is SubmitProps {
  return (tested as unknown as SubmitProps).type === "submit";
}

export const Button: React.FunctionComponent<ButtonProps | SubmitProps> = (props) => {
  const {
    children,
    className,
    data,
    block = false,
    ghost = false,
    status = Status.DEFAULT,
    action = false,
    link = false,
    tabIndex = 0,
    variant = "primary",
    color = "primary",
    ...additionalProps
  } = props;

  let onClick: (data: any, event: React.MouseEvent<HTMLButtonElement>) => void;
  let type: "button" | "reset" | "submit";

  if (isSubmit(props)) {
    onClick = () => {};
    ({ type } = props);
  } else {
    ({ onClick, type = "button" } = props);
  }

  const wasClicked = useRef<boolean>(false);
  const hasUnmounted = useRef<boolean>(false);

  useEffect(
    () => () => {
      hasUnmounted.current = true;
    },
    []
  );

  const onButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (wasClicked.current) {
        return;
      }

      onClick?.(data, event);
      wasClicked.current = true;

      setTimeout(() => {
        if (!hasUnmounted.current) {
          wasClicked.current = false;
        }
      }, 300);
    },
    [onClick, data]
  );

  let icon = null;
  if (!action) {
    icon = getIcon(status);
  }

  return (
    <motion.button
      {...additionalProps}
      className={cx(className, status, `button-variant-${variant}`, `button-color-${color}`, {
        ghost,
        block,
        action,
        button: !link,
        "as-link": link,
      })}
      disabled={status !== Status.DEFAULT}
      onClick={onButtonClick}
      tabIndex={tabIndex}
      type={type}
      whileTap={{ scale: block ? 0.975 : 0.95 }}
      transition={BUTTON_TRANSITION_PROPS}
    >
      {children} {icon}
    </motion.button>
  );
};

export const BUTTON_TRANSITION_PROPS: Transition = { type: "spring", stiffness: 500, damping: 20 };
