import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";
import { Button } from "../../components/interactions/Buttons/Button";
import { Dropdown } from "../../components/interactions/Dropdown/Dropdown";
import { Input } from "../../components/interactions/Input/Input";
import { Alternative } from "../../components/interactions/InputTypes";
import { Link } from "../../components/links/Link";
import { AnalyticsFilter } from "../../data/dataStatistics";
import { AnalyticsFilterUtils } from "../../model/AnalyticsFilter";
import { Country, TeamId } from "../../model/common/commonType";
import { userAdministration } from "../../data/UserAdministration";
import { analyticsFilterState } from "../../state/analyticsState";
import { userState } from "../../state/userState";
import { defaultAnalyticsFilter } from "../../Store";
import "./Filter.scss";

interface Props {}

const EXCEL_DOWNLOAD = "/api/admin/statistics/export";

export const Filter: React.FunctionComponent<Props> = () => {
  const user = useRecoilValue(userState);

  const analyticsFilter = useRecoilValue(analyticsFilterState);
  // const user = useRecoilValue(userState);
  const { t } = useTranslation();
  const [from, setFrom] = useState<string>(analyticsFilter.from);
  const [to, setTo] = useState<string>(analyticsFilter.to);
  const [country, setCountry] = useState<string>(user?.country || "");
  const [teamId, setTeamId] = useState<TeamId>();
  const [teams, setTeams] = useState<Alternative<string>[]>([]);
  const history = useHistory();

  // Dates might be updated elsewhere
  useEffect(() => {
    setFrom(analyticsFilter.from);
    setTo(analyticsFilter.to);
  }, [analyticsFilter]);

  const onUpdate = useCallback(() => {
    const filter: AnalyticsFilter = {
      ...analyticsFilter,
      from,
      to,
    };

    if (user?.adminUser?.superAdmin && country) {
      filter.country = country as Country;
    } else {
      delete filter.country;
    }

    if (teamId) {
      filter.teamId = teamId;
    } else {
      delete filter.teamId;
    }

    if (AnalyticsFilterUtils.isValidFilter(filter)) {
      history.push(`${history.location.pathname}${AnalyticsFilterUtils.filterToQuery(filter)}`);
    }
  }, [user, from, to, teamId, country, analyticsFilter, history]);

  const excelLink = `${EXCEL_DOWNLOAD}${AnalyticsFilterUtils.filterToQuery(analyticsFilter)}`;

  useEffect(() => {
    if (!user?.adminUser?.salesAdmin) {
      return;
    }

    userAdministration
      .loadSalesTeams()
      .then((salesTeams) => {
        const alternatives = salesTeams.map((t) => {
          return {
            text: t.name,
            value: t.id,
          };
        });
        setTeams([
          {
            text: "All teams",
            value: "",
          },
          ...alternatives,
        ]);
      })
      .catch((err) => {});

    return () => {
      setTeams([]);
    };
  }, [setTeams, user]);

  const showCountrySelection = () => {
    if (!user?.adminUser?.superAdmin) {
      return null;
    }

    return (
      <Dropdown
        className="compact"
        label="Country"
        alternatives={[
          {
            text: "All countries",
            value: "",
          },
          {
            text: "Poland",
            value: Country.POLAND,
          },
          {
            text: "Croatia",
            value: Country.CROATIA,
          },
        ]}
        onChange={(value) => {
          setCountry(value);
        }}
        value={country}
      />
    );
  };

  const showTeamSelection = () => {
    if (!user?.adminUser?.salesAdmin) {
      return null;
    }

    return (
      <Dropdown
        className="compact teams-dropdown"
        label="Teams"
        alternatives={teams}
        onChange={(value) => {
          setTeamId(value as TeamId);
        }}
        value={teamId}
      />
    );
  };

  return (
    <div className="analytics-filter">
      <div className="filter-dates">
        <Input
          type="date"
          name="min-date"
          className="compact"
          attributes={{
            min: defaultAnalyticsFilter.from,
            max: to,
          }}
          onChange={(updatedFrom) => {
            setFrom(updatedFrom);
          }}
          value={from}
          label={t("Start date")}
        />

        <Input
          type="date"
          name="max-date"
          className="compact"
          attributes={{
            min: from,
            max: defaultAnalyticsFilter.to,
          }}
          onChange={(updatedTo) => {
            setTo(updatedTo);
          }}
          value={to}
          label={t("End date")}
        />
        {showCountrySelection()}
        {showTeamSelection()}
        <Button onClick={onUpdate}>{t("Update")}</Button>
        <Link className="as-button excel-download" download={`${from}-${to}.xlsx`} external link={excelLink}>
          {t("Download")}
        </Link>
      </div>
    </div>
  );
};
