import { RefObject, useRef, VoidFunctionComponent } from "react";
import { useValidation, ValidationProps } from "./hooks/useValidation";
import { useForm } from "./hooks/useForm";
import cx from "classnames";
import "./HiddenInput.scss";
import { AnimatePresence, motion } from "framer-motion";

interface Props extends ValidationProps {
  value?: any;
  label: string;
  name?: string;
  scrollToRef: RefObject<HTMLElement>;
  boxVariant?: boolean;
  noMargins?: boolean;
}

export const HiddenInput: VoidFunctionComponent<Props> = ({
  value = "",
  name,
  validators = [],
  forceValidation = false,
  scrollToRef,
  boxVariant = true,
  noMargins = false,
}) => {
  const inputId = useRef("text_" + Math.random().toString(36).substring(2, 9));

  const [validity, errorMessages, resetValidation, status] = useValidation(
    value,
    validators,
    forceValidation
  );
  useForm(inputId.current, validity, value, resetValidation, scrollToRef);

  return (
    <AnimatePresence>
      {errorMessages.length > 0 && (
        <div
          className={cx("hidden-input", status, {
            "hidden-input-box-variant": boxVariant,
          })}
        >
          <input value={value} type="hidden" id={inputId.current} name={name || inputId.current} />
          <motion.div
            className={cx("input-messages", {
              "input-message-no-margins": noMargins,
            })}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="input-message">{errorMessages[0]}</div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};
