import cx from "classnames";
import { useCallback, useRef, useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { IdImageInterface } from "../../../../components/fileUpload/FileUpload";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { ConfirmButton, ConfirmType } from "../../../../components/interactions/Buttons/ConfirmButton";
import { ContractDocument, DocumentType } from "../../../../model/contract/contractType";

import { documentTypeDescription, documentTypeDisplay } from "../../../../model/contract/contractUtils";
import { Status } from "../../../../data/types";
import { contractDocumentsState } from "../../../../state/contractDocumentsState";
import { contractStatusState } from "../../../../state/contractStatusState";
import { ConfirmDocImageAndStatuses } from "./ConfirmDocImageAndStatuses";
import { ConfirmBankStatement } from "./ConfirmBank/ConfirmBankStatement";
import { dataConfirm } from "../../../../data/dataConfirm";
import "./ConfirmDoc.scss";
import { getFileAttributes } from "../../../../components/utils";

interface Props {
  document: ContractDocument | null;
  onClose: () => void;
}

export const ConfirmDoc: FC<Props> = ({ document, onClose }) => {
  if (!document) return null;

  if (document.documentType === DocumentType.BANK_STATEMENT) {
    return <ConfirmBankStatement document={document} onClose={onClose} />;
  }

  return <ConfirmDocInner document={document} onClose={onClose} />;
};

interface InnerProps {
  document: ContractDocument;
  onClose: () => void;
}

export const ConfirmDocInner: FC<InnerProps> = ({ document, onClose }) => {
  const { t } = useTranslation();
  const setDocuments = useSetRecoilState(contractDocumentsState);
  const contractStatus = useRecoilValue(contractStatusState);

  const [rejectStatus, setRejectStatus] = useState<Status>(Status.DEFAULT);
  const [confirmStatus, setConfirmStatus] = useState<Status>(
    document.confirmed ? Status.SUCCESS : Status.DEFAULT
  );

  const originalImage = useRef<IdImageInterface>(getFileAttributes(contractStatus, document));

  const onReject = useCallback(() => {
    setRejectStatus(Status.PENDING);

    dataConfirm
      .rejectUploadedDocument(contractStatus.contractId, document.id)
      .then((response) => {
        setRejectStatus(Status.SUCCESS);
        // We don't send bank statement documents in ContractResponse, but if we confirm a document we get it from this endpoint which causes a duplicate in the FE.
        const filteredDocuments = response.filter(
          ({ documentType }) => documentType !== DocumentType.BANK_STATEMENT
        );
        setDocuments(filteredDocuments);
      })
      .catch((err) => {
        setRejectStatus(Status.ERROR);
        setTimeout(() => setRejectStatus(Status.DEFAULT), 4000);
      });
  }, [contractStatus.contractId, document.id, setDocuments]);

  const onConfirm = useCallback(() => {
    setConfirmStatus(Status.PENDING);

    dataConfirm
      .confirmUploadedDocument(contractStatus.contractId, document.id)
      .then((response) => {
        setConfirmStatus(Status.SUCCESS);
        // We don't send bank statement documents in ContractResponse, but if we confirm a document we get it from this endpoint which causes a duplicate in the FE.
        const filteredDocuments = response.filter(
          ({ documentType }) => documentType !== DocumentType.BANK_STATEMENT
        );
        setDocuments(filteredDocuments);
      })
      .catch((err) => {
        setConfirmStatus(Status.ERROR);
        setTimeout(() => setConfirmStatus(Status.DEFAULT), 4000);
      });
  }, [contractStatus.contractId, document.id, setDocuments]);

  return (
    <div
      className={cx("confirm-doc", {
        "is-confirmed": !!document.confirmed,
        "show-status": confirmStatus !== Status.DEFAULT || rejectStatus !== Status.DEFAULT,
      })}
    >
      <h4>{t(documentTypeDisplay(document.documentType))}</h4>
      <p className="document-description">
        {document.documentDescription || t(documentTypeDescription(document.documentType))}
      </p>

      <div className="m-top-30">
        <ConfirmDocImageAndStatuses
          originalImage={originalImage.current}
          rejectStatus={rejectStatus}
          confirmStatus={confirmStatus}
          document={document}
        />
      </div>

      {!!document.fileAvailable && !document.confirmed && (
        <div className="confirm-doc-buttons m-top-30">
          <ConfirmButton
            small={false}
            className="danger-button"
            onClick={onReject}
            removeButtonText={t("Yes, reject")}
            status={rejectStatus}
          >
            {t("Reject document")}
          </ConfirmButton>

          <ConfirmButton
            small={false}
            onClick={onConfirm}
            removeButtonText={t("Yes, confirm")}
            confirmType={ConfirmType.SUCCESS}
            status={confirmStatus}
          >
            {t("Confirm document")}
          </ConfirmButton>
        </div>
      )}
      {!!document.confirmed ||
        (rejectStatus === Status.SUCCESS && (
          <Button ghost onClick={onClose} block className="close-button">
            {t("Close")}
          </Button>
        ))}
    </div>
  );
};
