import { AnalyticsFilter, FilteredName } from "../data/dataStatistics";
import { DateTime } from "luxon";
import { isValidDate } from "../components/utils";
import { Language } from "../i18n";
import { UserId } from "./common/commonType";

export const AnalyticsFilterUtils = {
  filterToQuery: (filter: AnalyticsFilter) => {
    const params = new URLSearchParams();
    Object.entries(filter)
      .filter((pair) => {
        return pair[1] !== undefined && pair[1] !== null;
      })
      .forEach((pair) => {
        if (isValidDate(pair[1])) {
          const date = DateTime.fromJSDate(pair[1]);
          params.set(pair[0], date.toISODate());
        } else if (pair[0] === "country") {
          params.set(pair[0], pair[1].toLocaleUpperCase());
        } else {
          params.set(pair[0], pair[1].toString());
        }
      });
    return `?${params.toString()}`;
  },

  queryToFilter: (search: string) => {
    const params: any = {};

    const searchObject = new URLSearchParams(search);

    if (searchObject.get("from")) {
      params.from = searchObject.get("from");
    }

    if (searchObject.get("to")) {
      params.to = searchObject.get("to");
    }

    if (searchObject.get("country")) {
      params.country = searchObject.get("country") as Language;
    }

    if (searchObject.get("status")) {
      params.status = searchObject.get("status");
    }

    if (searchObject.get("teamId")) {
      params.teamId = searchObject.get("teamId");
    }

    if (searchObject.get("userId")) {
      params.userId = searchObject.get("userId") as UserId;
    }

    if (searchObject.get("filteredName")) {
      params.filteredName = (searchObject.get("filteredName") || FilteredName.DATE) as FilteredName;
    }

    return params;
  },

  isValidQuery: (search: string) => {
    const params = AnalyticsFilterUtils.queryToFilter(search);
    return isValidDate(new Date(params["from"])) && isValidDate(new Date(params["to"]));
  },

  isValidFilter: (filter: AnalyticsFilter) => {
    return isValidDate(new Date(filter["from"])) && isValidDate(new Date(filter["to"]));
  },
};
