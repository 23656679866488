import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { Checkboxes } from "../../../../components/interactions/Checkboxes/Checkboxes";
import { contractPricingState } from "../../../../state/contractCostState";
import { contractStatusState } from "../../../../state/contractStatusState";
import styles from "./InstallationFee.module.scss";
import { WarningBox } from "../../../../components/boxes/WarningBox";
import { getCurrencySymbol } from "../../../../components/utils";
import { NumberInput } from "../../../../components/form/NumberInput";
import { MinValidator } from "../../../../components/form/validators/MinValidator";
import { MaxValidator } from "../../../../components/form/validators/MaxValidator";
import useGeneralData from "../../../../hooks/useGeneralData";
import { contractTerminalsSelector } from "../../../../state/contractStoresState";
import { ContractType, TerminalType } from "../../../../model/contract/contractType";
import { useCallback, useMemo, useState } from "react";
import { useDebounceFn } from "../../../../hooks/useDebounce";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { AnimatePresence, motion } from "framer-motion";

const TERMINAL_INSTALLATION_FEE = "TERMINAL_INSTALLATION_FEE";
const INSTALLED_BY_TECHNICIAN = "INSTALLED_BY_TECHNICIAN";

export const InstallationFee: React.FC = () => {
  const [cost, setCost] = useRecoilState(contractPricingState);
  const { edit } = useRecoilValue(contractStatusState);
  const { installationFee, installedByTechnician } = cost.INSTORE;
  const [enableInstallationFee, setEnableInstallationFee] = useState<string[]>(
    installationFee ? [TERMINAL_INSTALLATION_FEE] : []
  );

  const { currency, language } = useGeneralData();
  const { t } = useTranslation();
  const allTerminals = useRecoilValue(contractTerminalsSelector);

  const noHardTerminals = useMemo(
    () =>
      allTerminals.every(
        (terminal) =>
          terminal.terminalType === TerminalType.SOFT_POS || terminal.terminalType === TerminalType.ECOMMERCE
      ),
    [allTerminals]
  );

  const onEnableInstallationFee = useCallback(
    (value: string[]) => {
      if (!value.includes(TERMINAL_INSTALLATION_FEE)) {
        setCost((prev) => ({
          ...prev,
          [ContractType.INSTORE]: {
            ...prev[ContractType.INSTORE],
            installationFee: undefined,
          },
        }));
      }

      setEnableInstallationFee(value);
    },
    [setCost]
  );

  const onChangeInstallationFeeValue = useCallback(
    (value: number | undefined) => {
      setCost((prev) => ({
        ...prev,
        [ContractType.INSTORE]: {
          ...prev[ContractType.INSTORE],
          installationFee: value,
        },
      }));
    },
    [setCost]
  );

  const onEnableInstalledByTechnician = useCallback(
    (value: string[]) => {
      setCost((prev) => ({
        ...prev,
        [ContractType.INSTORE]: {
          ...prev[ContractType.INSTORE],
          installedByTechnician: value.includes(INSTALLED_BY_TECHNICIAN) ? true : false,
        },
      }));
    },
    [setCost]
  );

  const debounceInstallationFee = useDebounceFn(onChangeInstallationFeeValue, 500);

  return (
    <>
      <div className={styles.root}>
        <h5 className="m-bottom-30">{t("Installation fees")}</h5>

        {allTerminals.length < 1 ? (
          <WarningBox className="m-top-20">{t("No terminals added")}</WarningBox>
        ) : noHardTerminals ? (
          <WarningBox className="m-top-20">
            {t("The installation fee only applies to EP2 devices")}
          </WarningBox>
        ) : (
          <div className="tablet-columns ">
            <div className="m-top-30">
              <Checkboxes
                disabled={!edit || noHardTerminals ? true : false}
                onChange={onEnableInstallationFee}
                values={enableInstallationFee}
                alternatives={[
                  {
                    text: t("Terminal installation fee"),
                    value: TERMINAL_INSTALLATION_FEE,
                  },
                ]}
              />
            </div>
            <AnimatePresence>
              {enableInstallationFee.includes(TERMINAL_INSTALLATION_FEE) && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <NumberInput
                    label={t(`Terminal installation Fee ({{currency}})`, {
                      currency: getCurrencySymbol(language, currency),
                    })}
                    name="installation-fee"
                    disabled={!edit || noHardTerminals}
                    value={installationFee}
                    onChange={(value) => debounceInstallationFee(value)}
                    validators={[
                      new RequiredValidator("Installation fee is required"),
                      new MinValidator(
                        1,
                        t("Minimum value is {{min}}", {
                          min: 1,
                        })
                      ),
                      new MaxValidator(
                        999,
                        t("Maximum value is 1000 {{currency}}", {
                          currency: getCurrencySymbol(language, currency),
                        })
                      ),
                    ]}
                    prefix={`${getCurrencySymbol(language, currency)} `}
                    hint={t("The installation fee is a one time cost")}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
        <hr className={styles.hr} />
        <div className="tablet-columns">
          <div>
            <Checkboxes
              disabled={!edit || noHardTerminals ? true : false}
              onChange={onEnableInstalledByTechnician}
              values={installedByTechnician ? [INSTALLED_BY_TECHNICIAN] : []}
              alternatives={[
                {
                  text: t("Installed by Technician"),
                  value: INSTALLED_BY_TECHNICIAN,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
