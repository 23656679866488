import { FunctionComponent } from "react";
import { getCurrencySymbol, getIntlCost } from "../../../../../../../../components/utils";
import { PricingStep as IPricingStep } from "../../../../../../../../model/pricing/pricingTypes";
import { RequiredValidator } from "../../../../../../../../components/form/validators/RequiredValidator";
import { Button } from "../../../../../../../../components/interactions/Buttons/Button";
import { TrashIcon } from "../../../../../../../../components/icons/TrashIcon";
import styles from "./PricingStep.module.scss";
import { motion } from "framer-motion";
import { Status } from "../../../../../../../../data/types";
import { NumberInput } from "../../../../../../../../components/form/NumberInput";
import useGeneralData from "../../../../../../../../hooks/useGeneralData";
import { MaxValidator } from "../../../../../../../../components/form/validators/MaxValidator";
import { MinValidator } from "../../../../../../../../components/form/validators/MinValidator";
import { TextInput } from "../../../../../../../../components/form/TextInput";

interface Props {
  disabled?: boolean;
  pricingStep: IPricingStep;
  onChangeToThreshold: (value: number | undefined) => void;
  onChangeTransactionFee: (value: number | undefined) => void;
  onDeletePricingStep: () => void;
  isLastStep: boolean;
  isFirstStep: boolean;
  canDelete: boolean;
}

export const PricingStep: FunctionComponent<Props> = ({
  pricingStep,
  onChangeToThreshold,
  onChangeTransactionFee,
  onDeletePricingStep,
  isLastStep,
  isFirstStep,
  canDelete,
}) => {
  const { language, currency } = useGeneralData();

  return (
    <motion.div
      className={styles.root}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={styles.header}>
        {isFirstStep ? (
          <NumberInput
            label="From"
            prefix={`${getCurrencySymbol(language, currency)} `}
            value={0}
            onChange={() => null}
            disabled
          />
        ) : (
          <NumberInput
            label="From"
            placeholder={`100 000 ${getCurrencySymbol(language, currency)} `}
            prefix={`${getCurrencySymbol(language, currency)} `}
            value={pricingStep.fromThreshold}
            onChange={() => null}
            disabled
            validators={[new RequiredValidator("Monthly transaction turnover is required")]}
          />
        )}

        {isLastStep ? (
          <TextInput
            label="To"
            value={`${getIntlCost(language, pricingStep.fromThreshold, currency)} and above`}
            onChange={() => null}
            disabled
          />
        ) : (
          <NumberInput
            label="To"
            placeholder={`250 000 ${getCurrencySymbol(language, currency)} `}
            prefix={`${getCurrencySymbol(language, currency)} `}
            value={pricingStep.toThreshold}
            onChange={onChangeToThreshold}
            validators={[new RequiredValidator("Monthly transaction turnover is required")]}
          />
        )}

        <NumberInput
          label="Transaction fee"
          suffix=" %"
          value={pricingStep.transactionFee}
          onChange={onChangeTransactionFee}
          validators={[
            new RequiredValidator("Monthly transaction turnover is required"),
            new MaxValidator(5, "Max transaction fee is 5"),
            new MinValidator(0, "Min transaction fee is 0"),
          ]}
        />

        <div className={styles.delete}>
          {isLastStep && (
            <Button
              className="m-top-30"
              action
              variant="text"
              color="danger"
              onClick={onDeletePricingStep}
              status={canDelete ? Status.DEFAULT : Status.DISABLED}
            >
              <TrashIcon style={{ fontSize: "1.5em" }} />
            </Button>
          )}
        </div>
      </div>
    </motion.div>
  );
};
