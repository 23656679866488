import { useRecoilValue } from "recoil";
import { SummaryDevicePack } from "./SummaryDevicePack/SummaryDevicePack";
import { useTranslation } from "react-i18next";
import { contractStoresState } from "../../../../../state/contractStoresState";
import styles from "./StorePack.module.scss";
import { BiStore } from "react-icons/bi";

export const StorePack = () => {
  const { t } = useTranslation();
  // should only be 1 store for PACK
  const store = useRecoilValue(contractStoresState).find((store) => store !== undefined);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h5 style={{ margin: 0 }}>{t("Location & terminal")}</h5>
      </div>

      <div className={styles.store}>
        <div className={styles.store_info}>
          <BiStore size={24} className="text-passive" />
          <div className={styles.store_data}>
            <div className="fw-600">{store?.commercialName ? store.commercialName : "No name set"}</div>
            <div className="fw-500 fs-small text-passive">
              {store?.address.street}
              {store?.address.street && store.address.city && ","} {store?.address.city}
            </div>
          </div>
        </div>
        <div className={styles.content}>
          {store?.terminals.map((terminal) => (
            <SummaryDevicePack key={terminal.id} type={terminal.terminalType} nbrOfTerminals={1} />
          ))}
        </div>
      </div>

      {/* should only really be 1 terminal for PACK, but uses map to see if unwanted terminals gets added*/}

      {/* <div className={storeStyles.terminalSummary}>
        <h5 className={storeStyles.terminalTitle}>{t("Terminal summary")}</h5>
       
      </div> */}
    </div>
  );
};
