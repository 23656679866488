import React from "react";
import cx from "classnames";
import { ContractStatus, contractStatusToString } from "../../../components/contractStatus/ContractStatus";
import { useTranslation } from "react-i18next";
import { Link } from "../../../components/links/Link";
import { ContractListResponse } from "../../../data/dataContracts";
import { getDynamicLink, getIntlDate, getRelativeDate, isStaleCase } from "../../../components/utils";
import { CONTRACT_ROUTE } from "../../Contract/ContractPage";
import { MERCHANT_ROUTE } from "../../MerchantStatus/MerchantStatusPage";
import { ContractType, ContractStatus as StatusInterface } from "../../../model/contract/contractType";
import { SessionResponse } from "../../../data/dataAuth";
import "./ContractTableRow.scss";
import { motion } from "framer-motion";
import useGeneralData from "../../../hooks/useGeneralData";
import { getDisplayStringFromContractTypes } from "../../../model/contract/contractUtils";
import { GlobeIcon } from "../../../components/icons/GlobeIcon";
import { MdOutlineStorefront } from "react-icons/md";
import { DashboardDatesFormat } from "../../../Store";

interface Props {
  contract: ContractListResponse;
  user: SessionResponse | null;
  index: number;
  dateFormat: DashboardDatesFormat;
}

export const ContractTableRow: React.FunctionComponent<Props> = ({ user, contract, dateFormat, index }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { ecomFeatureIsEnabled } = useGeneralData();
  const { contractType } = contract;

  const isRelativeDate = dateFormat === DashboardDatesFormat.RELATIVE_DATES;

  const getSalesRepValue = () => {
    if (user?.userId === contract.currentSalesId) return t("You");
    return contract.currentSalesName || t("Not assigned");
  };

  return (
    <motion.tr
      initial={{ opacity: 0.5, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      layout
      // transition={{ type: "spring" }}
      className={cx("contract contract-cells", {
        highlighted: user?.userId === contract.currentSalesId,
      })}
    >
      <td className="contract-cell company-name" title={contract.companyName}>
        <div>
          <Link
            link={getDynamicLink(resolveLinkByStatus(contract), {
              id: contract.contractId,
            })}
          >
            {contract.companyName ? contract.companyName : contract.companyRegistrationId}
          </Link>
        </div>
      </td>
      <td className="contract-cell status" title={contractStatusToString(t, contract.status)}>
        {/* <span className="contract-status-cell">
          <span>
            <ContractStatus status={contract.status} />
          </span>
          <ActionNotification status={contract.status} texts={texts} />
        </span> */}
        <div>
          <span className={`contract-status-indicator contract-status-variant-${contract.status}`}>
            <ContractStatus status={contract.status} />
          </span>
        </div>
      </td>

      <td className="contract-cell sales-user" title={contract.currentSalesName || t("Not assigned")}>
        <div>{getSalesRepValue()}</div>
      </td>

      {ecomFeatureIsEnabled && (
        <td className="contract-cell contract-type" title={contract.currentSalesName || t("Not assigned")}>
          <div className="contract-type-cell">
            <span className="contract-type-container">
              {/* {getContractTypeIcon(contractType)} */}
              {getDisplayStringFromContractTypes(contractType)}
            </span>
          </div>
        </td>
      )}
      <td className="contract-cell created" title={contract.created || " - "}>
        <div className={isStaleCase(contract.lastUpdated) ? "text-disabled fs-small" : "fs-small fw-500"}>
          {contract.created
            ? isRelativeDate
              ? getRelativeDate(lang, contract.created)
              : getIntlDate(lang, contract.created)
            : " - "}
        </div>
      </td>
      <td className="contract-cell updated" title={contract.lastUpdated || " - "}>
        <div className={isStaleCase(contract.lastUpdated) ? "text-disabled fs-small" : "fs-small fw-500"}>
          {contract.lastUpdated
            ? isRelativeDate
              ? getRelativeDate(lang, contract.lastUpdated)
              : getIntlDate(lang, contract.lastUpdated)
            : " - "}
        </div>
      </td>
      {/* <td className={cx("contract-cell", "contract-menu")}>
        <Link
          link={getDynamicLink(resolveLinkByStatus(contract), {
            id: contract.contractId,
          })}
        >
          <Edit />
        </Link>
      </td> */}
    </motion.tr>
  );
};

function resolveLinkByStatus(
  contract: ContractListResponse
): "/contract/:id" | "/merchant/:id" | "/contract/completed/:id" {
  if (contract.status === StatusInterface.PENDING_SALES_INPUT) {
    return CONTRACT_ROUTE;
  }
  if (contract.status === StatusInterface.COMPLETE) {
    return "/contract/completed/:id";
  }
  return MERCHANT_ROUTE;
}

export const getContractTypeIcon = (types: ContractType[]) => {
  const isEcom = types.includes(ContractType.ECOMMERCE);
  const isInstore = types.includes(ContractType.INSTORE);

  if (isEcom && isInstore) return <GlobeIcon />;

  if (isEcom) return <GlobeIcon />;

  return <MdOutlineStorefront />;
};
