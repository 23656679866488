import { useEffect, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { ContractStatus } from "../../../components/contractStatus/ContractStatus";
import { FieldSet } from "../../../components/fieldSet/FieldSet";
import { Pending } from "../../../components/icons/Pending";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Dropdown } from "../../../components/interactions/Dropdown/Dropdown";
import { Input } from "../../../components/interactions/Input/Input";
import { Alternative } from "../../../components/interactions/InputTypes";
import { Pagination } from "../../../components/pagination/Pagination";
import { Table } from "../../../components/table/Table";
import { FormattedDate } from "../../../components/time/FormattedDate";
import { LoginMethod } from "../../../data/dataAuth";
import { ContractId, TeamId, UserId } from "../../../model/common/commonType";
import { ContractStatus as Type } from "../../../model/contract/contractType";
import { Page } from "../../../model/Page";
import { Status } from "../../../data/types";
import {
  AdminLoginHistory,
  CurrentContract,
  SalesUser,
  SalesUserRequest,
  userAdministration,
} from "../../../data/UserAdministration";
import { userState } from "../../../state/userState";
import { COMPLETED_CONTRACT_PAGE } from "../../CompletedContract/CompletedContractPage";
import { CONTRACT_ROUTE } from "../../Contract/ContractPage";
import { MERCHANT_ROUTE } from "../../MerchantStatus/MerchantStatusPage";
import { AdminPage } from "../AdminPage";
import { CONTRACT_AUDIT_PAGE } from "../Audit/AdminContractAuditLog";
import { DelegateContractOverlay } from "./DelegateContractOverlay";
import "./UserAdministration.scss";

export const SALES_USER_EDIT_PAGE = "/admin/sales/:id";

type PageParams = {
  id: UserId;
};

function resolveLinkByStatus(status: Type): "/contract/:id" | "/merchant/:id" | "/contract/completed/:id" {
  if (status === Type.PENDING_SALES_INPUT) {
    return CONTRACT_ROUTE;
  }
  if (status === Type.COMPLETE) {
    return COMPLETED_CONTRACT_PAGE;
  }
  return MERCHANT_ROUTE;
}

const hasChanged = (original: SalesUser | undefined, request: SalesUserRequest) => {
  if (!original) {
    return false;
  }
  return (
    original.email !== request.email ||
    original.name !== request.name ||
    original.teamId !== request.teamId ||
    original.phone !== request.phone ||
    original.loginMethod !== request.loginMethod ||
    original.salesId !== request.salesId
  );
};

export const SalesUserDetailsPage = () => {
  const history = useHistory();
  let { id } = useParams<PageParams>();
  const [originalSalesUser, setOriginalSalesUser] = useState<SalesUser>();
  // const [salesTeams, setSalesTeams] = useState<SalesTeam[]>();

  const [contracts, setContracts] = useState<CurrentContract[]>();
  const [logins, setLogins] = useState<Page<AdminLoginHistory>>();
  const [loginPage, setLoginPage] = useState(0);

  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState<UserId>(id);
  const [salesUserRequest, setSalesUserRequest] = useState<SalesUserRequest>({
    email: "",
    phone: "",
    name: "",
    teamId: "" as TeamId,
    loginMethod: LoginMethod.OTP,
    salesId: "",
  });

  const user = useRecoilValue(userState);
  const isSalesAdmin = user?.adminUser?.salesAdmin;

  const [salesTeamsAlternative, setSalesTeamsAlternative] = useState<Alternative<string>[]>([]);

  const [nameStatus, setNameStatus] = useState(isSalesAdmin ? Status.DEFAULT : Status.DISABLED);
  const [teamStatus, setTeamStatus] = useState(isSalesAdmin ? Status.DEFAULT : Status.DEFAULT);
  const [emailStatus, setEmailStatus] = useState(isSalesAdmin ? Status.DEFAULT : Status.DEFAULT);
  const [emailError, setEmailError] = useState("");
  const [phoneStatus, setPhoneStatus] = useState(isSalesAdmin ? Status.DEFAULT : Status.DEFAULT);

  const [delegateContract, setDelegateContract] = useState<ContractId>();

  useEffect(() => {
    setLoading(true);
    userAdministration
      .loadSalesUser(id)
      .then((user) => {
        const loadTeamsPromise = userAdministration.loadSalesTeams().then((salesTeams) => {
          const teams = salesTeams.map((s) => {
            return {
              value: s.id,
              text: s.name,
            };
          });
          teams.unshift({
            value: "" as TeamId,
            text: "Choose team",
          });
          setSalesTeamsAlternative(teams);
          // setSalesTeams(salesTeams);
        });

        const contractPromise = userAdministration.loadSalesUserContracts(id).then(setContracts);
        const loadLoginsPromise = userAdministration.loadSalesUserLoginHistory(id, loginPage).then(setLogins);

        Promise.all([loadTeamsPromise, contractPromise, loadLoginsPromise])
          .then(() => {
            setUserId(user.id);
            setOriginalSalesUser(user);

            setSalesUserRequest({
              email: user.email,
              phone: user.phone,
              name: user.name,
              teamId: user.teamId,
              loginMethod: user.loginMethod,
              salesId: user.salesId,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        // :(
      });
  }, [
    id,
    loginPage,
    setUserId,
    setSalesUserRequest,
    setSalesTeamsAlternative,
    setLogins,
    setLoading,
    setContracts,
    setOriginalSalesUser,
  ]);

  if (loading) {
    return (
      <AdminPage className={"light-theme edit-sales-user"}>
        <FieldSet header={<span>Sales User</span>}>
          Loading.. <Pending />
        </FieldSet>
      </AdminPage>
    );
  }

  return (
    <AdminPage className={"light-theme edit-sales-user"}>
      <DelegateContractOverlay
        contractId={delegateContract}
        currentUser={userId}
        onClose={() => {
          setDelegateContract(undefined);
        }}
        onDelegate={() => {
          setDelegateContract(undefined);
          userAdministration.loadSalesUserContracts(id).then(setContracts);
        }}
      />
      <section>
        <article>
          <FieldSet header="Sales User">
            <div className="tablet-columns">
              <div>
                <Input
                  onChange={(value) => {
                    setSalesUserRequest({ ...salesUserRequest, name: value });
                  }}
                  value={salesUserRequest.name}
                  name="name"
                  label="Name"
                  status={nameStatus}
                  message={nameStatus === Status.ERROR ? "Name is required" : null}
                  placeholder="Name of the sales user"
                  onBlur={() => {
                    if (salesUserRequest.name.length === 0) {
                      setNameStatus(Status.ERROR);
                    } else if (salesUserRequest.name.length > 0) {
                      setNameStatus(Status.SUCCESS);
                    } else {
                      setNameStatus(Status.DEFAULT);
                    }
                  }}
                />
              </div>
              <div>
                <Dropdown
                  alternatives={salesTeamsAlternative}
                  label="Team"
                  status={teamStatus}
                  value={salesUserRequest.teamId}
                  onChange={(value) => {
                    if (value === "") {
                      setTeamStatus(Status.ERROR);
                    } else {
                      setTeamStatus(Status.SUCCESS);
                    }
                    setSalesUserRequest({
                      ...salesUserRequest,
                      teamId: value as TeamId,
                    });
                  }}
                />
              </div>
              <div>
                <Input
                  onChange={(value) => {
                    setSalesUserRequest({ ...salesUserRequest, email: value });
                  }}
                  value={salesUserRequest.email}
                  name="email"
                  label="Email"
                  status={emailStatus}
                  message={emailStatus === Status.ERROR ? emailError : null}
                  placeholder="Email of the sales user, will be used as login"
                  onBlur={() => {
                    if (salesUserRequest.email.length === 0) {
                      setEmailError("Email is required");
                      setEmailStatus(Status.ERROR);
                      return;
                    }
                    if (userAdministration.isValidEmail(salesUserRequest.email)) {
                      setEmailStatus(Status.SUCCESS);
                    } else {
                      setEmailError("Wrong format on email");
                      setEmailStatus(Status.ERROR);
                    }
                  }}
                />
              </div>
              <div>
                <Input
                  onChange={(value) => {
                    setSalesUserRequest({ ...salesUserRequest, phone: value });
                  }}
                  value={salesUserRequest.phone}
                  name="phone"
                  label="Phone"
                  status={phoneStatus}
                  message={phoneStatus === Status.ERROR ? "Invalid phone format, use +<country code>" : null}
                  placeholder="+XXXXXXX"
                  onBlur={() => {
                    if (salesUserRequest.phone.length === 0) {
                      setPhoneStatus(Status.ERROR);
                      return;
                    }
                    if (salesUserRequest.phone.startsWith("+") && salesUserRequest.phone.length > 8) {
                      setPhoneStatus(Status.SUCCESS);
                    } else {
                      setPhoneStatus(Status.ERROR);
                    }
                  }}
                />
              </div>
              <div>
                <Input
                  onChange={(value) => {
                    setSalesUserRequest({
                      ...salesUserRequest,
                      salesId: value,
                    });
                  }}
                  value={salesUserRequest.salesId}
                  name="salesId"
                  label="Sales ID"
                />
              </div>
              <div>
                {/* Disable until we decide to use this */}
                {/* <Dropdown
                  alternatives={[
                    { value: "", text: "Select" },
                    { value: "AZURE", text: "Azure OAUTH" },
                    { value: "GOOGLE", text: "Google OAUTH" },
                    { value: "OTP", text: "Login Code" },
                  ]}
                  label="Login Method"
                  status={loginMethodStatus}
                  value={salesUserRequest.loginMethod as string}
                  onChange={(value) => {
                    if (value === "") {
                      setLoginMethodStatus(Status.ERROR);
                    } else {
                      setLoginMethodStatus(Status.SUCCESS);
                    }

                    setSalesUserRequest({
                      ...salesUserRequest,
                      loginMethod: value as LoginMethod,
                    });
                  }}
                /> */}
              </div>
              <div className="m-top-10">
                <Button
                  onClick={() => {
                    userAdministration.updateSalesUser(userId, salesUserRequest).then(() => {
                      //saved! Ladda om sidan?
                    });
                  }}
                  status={
                    hasChanged(originalSalesUser, salesUserRequest) &&
                    userAdministration.isFormValid(salesUserRequest)
                      ? Status.DEFAULT
                      : Status.DISABLED
                  }
                  block
                >
                  Save
                </Button>
              </div>
              <div className="m-top-10">
                <Button
                  ghost
                  block
                  onClick={() => {
                    history.go(-1);
                  }}
                >
                  Go back
                </Button>
              </div>
            </div>
          </FieldSet>
          <hr />
        </article>
        <article>
          <h5>Current Contracts</h5>

          <Table>
            <thead>
              <tr>
                <th>Company</th>
                <th>Status</th>
                <th>Started</th>
                <th>Last Updated</th>
                <th></th>
                <th>Log</th>
                <th>Delegate</th>
              </tr>
            </thead>
            <tbody>
              {contracts?.map((contract) => (
                <tr key={contract.companyRegistrationId}>
                  <td className="truncate">
                    {contract.companyName ? contract.companyName : contract.companyRegistrationId}
                  </td>
                  <td className="truncate">
                    <ContractStatus status={contract.status} />
                  </td>
                  <td className="truncate">
                    <FormattedDate date={contract.created} pattern="yyyy-MM-dd" />
                  </td>
                  <td className="truncate">
                    {contract.lastUpdated ? (
                      <FormattedDate date={contract.lastUpdated} pattern="yyyy-MM-dd" />
                    ) : (
                      " - "
                    )}
                  </td>
                  <td>
                    <Button
                      ghost
                      className="small"
                      onClick={() => {
                        history.push(
                          generatePath(resolveLinkByStatus(contract.status), {
                            id: contract.contractId,
                          })
                        );
                      }}
                      status={Status.DEFAULT}
                    >
                      Open
                    </Button>
                  </td>
                  <td>
                    <Button
                      ghost
                      className="small"
                      onClick={() => {
                        history.push(
                          generatePath(CONTRACT_AUDIT_PAGE, {
                            contractId: contract.contractId,
                          })
                        );
                      }}
                      status={isSalesAdmin ? Status.DEFAULT : Status.DEFAULT}
                    >
                      View Log
                    </Button>
                  </td>
                  <td>
                    <Button
                      ghost
                      className="small"
                      onClick={() => {
                        //Show users modal to delegate to
                        setDelegateContract(contract.contractId);
                      }}
                      status={
                        contract.status === Type.COMPLETE
                          ? Status.DISABLED
                          : isSalesAdmin
                          ? Status.DEFAULT
                          : Status.DEFAULT
                      }
                    >
                      Delegate
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <hr />
        </article>
        <article>
          <h5>Latest logins</h5>

          <Table>
            <thead>
              <tr>
                <th>Time</th>
                <th>IP</th>
                <th>Method</th>
              </tr>
            </thead>
            <tbody>
              {logins?.content.map((login) => (
                <tr key={login.created}>
                  <td className="truncate">
                    <FormattedDate date={login.created} pattern="yyyy-MM-dd HH:mm:ss" />
                  </td>
                  <td>{login.ip}</td>
                  <td>{login.method}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="m-top-10">
            <Pagination
              activePage={logins ? logins.number : 0}
              totalPages={logins ? logins.totalPages : 0}
              onPageChange={(page) => {
                setLoginPage(page);
              }}
            />
          </div>
          <hr />
        </article>
      </section>
    </AdminPage>
  );
};
