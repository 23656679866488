import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Button } from "../../components/interactions/Buttons/Button";
import { userState } from "../../state/userState";
import { Store, STORE_KEY } from "../../Store";
import { PageStripped } from "../Page/PageStripped";
import { LOGIN_ROUTE } from "./LoginPage";
import "./Logout.scss";

export const LOGOUT_ROUTE = "/logout";

export const Logout: React.FunctionComponent = () => {
  const [user, setUserState] = useRecoilState(userState);
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();
  const { t } = useTranslation();

  const expired = params.has("expired");

  console.log("user in logout: ", user);

  useEffect(() => {
    Store.setValue(STORE_KEY.STORE_USER, null);
    setUserState(null);
  }, [user, setUserState]);

  const onClose = useCallback(() => {
    const url = params.get("expired");

    if (url) {
      const search = new URLSearchParams();
      search.set("expired", url);
      history.push(`${LOGIN_ROUTE}?${search}`);
    } else {
      history.push(LOGIN_ROUTE);
    }

    // WsClient.shutdown();
  }, [history, params]);

  return (
    <PageStripped title="Logout">
      <section>
        <article>
          <div className="logout-wrapper">
            {expired ? (
              <>
                <h4>{t("Session expired")}</h4>
                <p>
                  {t(
                    "So, it seems like your session has expired. You will have to sign in again. No worries. Take care."
                  )}
                </p>
              </>
            ) : (
              <>
                <h4>{t("Logged out")}</h4>
                <p>{t("You are now logged out")}</p>
              </>
            )}
            <Button onClick={onClose} block>
              {t("Sign in")}
            </Button>
          </div>
        </article>
      </section>
    </PageStripped>
  );
};
