import React from "react";
import cx from "classnames";
import { Image } from "../../../../components/images/Image";
import "./ProviderLogo.scss";

interface Props {
  className?: string;
}

export const Maestro: React.FunctionComponent<Props> = ({ className }) => (
  <div className={cx("provider-logo", "logo-maestro", className)}>
    <Image src="/images/cards/maestro_logo_126x75.svg" ratio={126 / 75} alt="Maestro logo" />
  </div>
);
