import { useTranslation } from "react-i18next";
import { ContractStatus } from "../../model/contract/contractType";
import { useRecoilValue } from "recoil";
import { contractStatusState } from "../../state/contractStatusState";
import { Link } from "../../components/links/Link";
import { API } from "../../network/API";

export function PreviewContract() {
  const { t } = useTranslation();
  const contractStatus = useRecoilValue(contractStatusState);

  if (contractStatus.status !== ContractStatus.PENDING_SALES_INPUT) {
    return null;
  }

  return (
    <div className="preview-contract">
      <Link
        className="as-button block ghost"
        external
        link={API.getMerchantUrl(`preview/${contractStatus.contractId}`)}
      >
        {t("Preview merchant contract")}
      </Link>
    </div>
  );
}
