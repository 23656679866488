import React, { useState, useMemo, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useMultiForm } from "../../components/form/MultiFormContext";
import { Button } from "../../components/interactions/Buttons/Button";
import { getDynamicLink } from "../../components/utils";
import { dataContracts } from "../../data/dataContracts";
import { ContractStatus } from "../../model/contract/contractType";
import { Status } from "../../data/types";
import { contractStatusState } from "../../state/contractStatusState";
import { COMPLETED_CONTRACT_PAGE } from "../CompletedContract/CompletedContractPage";
import { MERCHANT_ROUTE } from "../MerchantStatus/MerchantStatusPage";
import { getEditStatus } from "./ContractPage";
import { Form } from "../../components/form/Form";
import DuplicateAssociateError from "./DuplicateAssociateError";

export function SendForConfirmation() {
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const contractStatus = useRecoilValue(contractStatusState);
  const multiForm = useMultiForm();

  const inputStatus = useMemo(() => {
    return getEditStatus(contractStatus.edit, status);
  }, [contractStatus.edit, status]);

  const history = useHistory();
  const ref = useRef<HTMLFormElement>(null);

  const send = useCallback(() => {
    if (!multiForm || multiForm.isInvalid) {
      multiForm?.forceValidation();
      return;
    }

    dataContracts
      .finalizeAndSendForConfirmation(contractStatus.contractId)
      .then(() => {
        history.push(getDynamicLink(MERCHANT_ROUTE, { id: contractStatus.contractId }));
        setStatus(Status.SUCCESS);
      })
      .catch((err) => {
        setStatus(Status.ERROR);

        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 4000);
      });
  }, [contractStatus.contractId, history, multiForm]);

  if (
    contractStatus.status === ContractStatus.MERCHANT_VERIFICATION ||
    contractStatus.status === ContractStatus.MERCHANT_SIGNATURES ||
    contractStatus.status === ContractStatus.SALES_VERIFICATION
  ) {
    return (
      <div className="send-for-confirmation">
        <Link
          className="as-button block"
          to={getDynamicLink(MERCHANT_ROUTE, { id: contractStatus.contractId })}
        >
          {t("To confirmation page")}
        </Link>
      </div>
    );
  }

  if (contractStatus.status === ContractStatus.COMPLETE) {
    return (
      <div className="send-for-confirmation">
        <Link
          className="as-button block"
          to={getDynamicLink(COMPLETED_CONTRACT_PAGE, {
            id: contractStatus.contractId,
          })}
        >
          {t("To onboarding page")}
        </Link>
      </div>
    );
  }

  return (
    <div className="send-for-confirmation">
      <Form>
        <DuplicateAssociateError scrollToRef={ref} />
      </Form>
      <Button
        type="button"
        data-testid="send-for-confirmation-button"
        block
        status={inputStatus}
        onClick={send}
      >
        {t("Send for confirmation")}
      </Button>
    </div>
  );
}
