import React, { createRef } from "react";
import cx from "classnames";
import { Status } from "../../data/types";
import { Point } from "./HistoricalStats";
import { getIntlDate } from "../../components/utils";
import "./HistoricalStatsGraph.scss";
import { Language } from "../../i18n";

const HORIZONTAL_PADDING = 20;
const VERTICAL_PADDING = 20;

interface HistoricalSeriesProps {
  historicalData: Point[];
  max: number;
  lang: Language;
  width: number;
}

interface Area {
  w: number;
  h: number;
}

function getXTicks(dates: Date[]) {
  const len = dates.length;

  if (len < 2) {
    return [];
  }

  if (len < 5) {
    return [dates[0], dates[len - 1]];
  }

  return [
    dates[0],
    dates[Math.floor((len - 1) / 4)],
    dates[Math.floor((len - 1) / 2)],
    dates[Math.floor(((len - 1) * 3) / 4)],
    dates[len - 1],
  ];
}

interface State {
  status: Status;
}

export class HistoricalStatsGraph extends React.Component<
  HistoricalSeriesProps,
  State
> {
  plot = createRef<HTMLDivElement>();
  d3Plot: any = null;
  area: Area = { w: 0, h: 0 };

  constructor(props: HistoricalSeriesProps) {
    super(props);
    this.state = {
      status: Status.PENDING,
    };
  }

  componentDidMount() {
    if (this.plot?.current) {
      this.d3Plot = window.d3.select(this.plot.current.firstChild);
    }
  }

  shouldComponentUpdate(nextProps: HistoricalSeriesProps) {
    if (!this.plot.current) {
      return false;
    }

    if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
      this.draw({ ...nextProps });
    }

    return false;
  }

  draw = (props: HistoricalSeriesProps) => {
    if (!this.plot.current) {
      return;
    }

    const { historicalData, max } = props;
    const box = this.plot.current.getBoundingClientRect();
    this.area = {
      w: box.width,
      h: box.height,
    };

    const y = window.d3
      .scaleLinear()
      .range([0, this.area.h])
      .domain([0, max + 2]);

    const x = window.d3
      .scaleBand()
      .range([0, this.area.w])
      .domain(historicalData.map((d) => d.date))
      .padding(0.2);

    const yTicks = y.ticks(5);

    const yTickElems = this.d3Plot.selectAll(".horizontal-tick").data(yTicks);
    yTickElems.exit().remove();
    const xTicks = getXTicks(x.domain());

    const barWidthOffset = x.bandwidth() / 2;

    const xTickElems = this.d3Plot
      .selectAll(".vertical-tick")
      .data(xTicks)
      .lower();
    xTickElems.exit().remove();

    const enteredYTickElems = yTickElems
      .enter()
      .append("line")
      .attr("class", "horizontal-tick")
      .lower();

    const enteredXTickElems = xTickElems
      .enter()
      .append("line")
      .attr("class", "vertical-tick");

    const mergedYTicks = enteredYTickElems.merge(yTickElems);
    const mergedXTicks = enteredXTickElems.merge(xTickElems);

    mergedYTicks
      .attr("x1", -HORIZONTAL_PADDING)
      .attr("x2", this.area.w + HORIZONTAL_PADDING)
      .attr("y1", (d: any) => this.area.h - y(d))
      .attr("y2", (d: any) => this.area.h - y(d));

    mergedXTicks
      .attr("x1", (d: any) => barWidthOffset + x(d))
      .attr("x2", (d: any) => barWidthOffset + x(d))
      .attr("y1", -VERTICAL_PADDING)
      .attr("y2", this.area.h + VERTICAL_PADDING);

    const bars = this.d3Plot.selectAll(".areas").data(historicalData);
    bars.exit().remove();

    const enteredBars = bars
      .enter()
      .append("rect")
      .attr("class", "areas")
      .attr("fill", (d: any) => {
        if (d.name === "started") {
          return "#fdc590";
        }
        if (d.name === "sentForSigning") {
          return "#f7a6c6";
        }
        if (d.name === "completedSigning") {
          return "#c3aedf";
        }
        if (d.name === "completedSignoff") {
          return "#a4b5df";
        }
        return "#b1d2fb";
      });

    const mergedBars = enteredBars.merge(bars);

    mergedBars
      .attr("width", (d: any) => x.bandwidth())
      .attr("x", (d: any) => x(d.date))
      .attr("y", (d: any) => this.area.h - y(d.offset) - y(d.count))
      .attr("height", (d: any) => y(d.count));

    const xTickTexts = this.d3Plot.selectAll(".x-tick").data(xTicks);
    const yTickTexts = this.d3Plot.selectAll(".y-tick").data(yTicks);
    xTickTexts.exit().remove();
    yTickTexts.exit().remove();

    const enteredXText = xTickTexts
      .enter()
      .append("text")
      .attr("class", "x-tick");

    const mergedXText = enteredXText.merge(xTickTexts);

    mergedXText
      .attr("x", (d: any) => barWidthOffset + x(d))
      .attr("y", this.area.h + VERTICAL_PADDING * 2.5)
      .text((d: string) => getIntlDate(props.lang, d));

    const enteredYText = yTickTexts.enter().append("g").attr("class", "y-tick");

    enteredYText
      .append("rect")
      .attr("rx", 16)
      .attr("width", 32)
      .attr("height", 32);

    enteredYText.append("text");

    const mergedYText = enteredYText.merge(yTickTexts);

    mergedYText
      .select("rect")
      .attr("x", this.area.w)
      .attr("y", (d: any) => -17 - y(d) + this.area.h);

    mergedYText
      .select("text")
      .attr("x", this.area.w + 16)
      .attr("y", (d: any) => this.area.h + 4 - y(d))
      .text((d: string) => d);
  };

  render() {
    return (
      <div
        className={cx("historical-stats-graph", this.state.status)}
        ref={this.plot}
      >
        <svg />
      </div>
    );
  }
}
