import { atom } from "recoil";
import { SessionResponse } from "../data/dataAuth";

export const userState = atom<SessionResponse | null>({
  key: "user",
  default: null,
});

export const sessionExpiredState = atom<boolean>({
  key: "sessionExpired",
  default: false,
});
