import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useRecoilValue } from "recoil";
import { AssignmentCompleted } from "../../components/icons/AssignmentCompleted";
import { CheckCircleOutline } from "../../components/icons/CheckCircleOutline";
import { Edit } from "../../components/icons/Edit";
import { Heart } from "../../components/icons/Heart";
import { PendingActions } from "../../components/icons/PendingActions";
import { T } from "../../components/translation/T";
import { getIntlDate } from "../../components/utils";
import { Current, dataStatistics } from "../../data/dataStatistics";
import { userState } from "../../state/userState";
import "./CurrentStats.scss";

function CurrectStatsByTeamOrCountry() {
  const { i18n } = useTranslation();
  const user = useRecoilValue(userState);
  const location = useLocation();

  if (user?.salesUser) {
    return (
      <div>
        <i>
          <T
            id="Statistics for team {{team}} as of"
            options={{
              team: user.salesUser.teamName,
            }}
          />
        </i>{" "}
        <b>{getIntlDate(i18n.language, new Date())}</b>
      </div>
    );
  }

  const searchObject = new URLSearchParams(location.search);
  const regionNames = new (Intl as any).DisplayNames([i18n.language], {
    type: "region",
  });

  if (user?.adminUser?.salesAdmin || searchObject.has("country")) {
    const country = user?.adminUser?.salesAdmin
      ? user.country
      : searchObject.get("country");

    return (
      <div>
        <i>
          <T
            id="Statistics for {{country}} as of"
            options={{
              country: regionNames.of(country),
            }}
          />
        </i>{" "}
        <b>{getIntlDate(i18n.language, new Date())}</b>
      </div>
    );
  }

  return (
    <div>
      <i>
        <T>Statistics as of</T>
      </i>{" "}
      <b>{getIntlDate(i18n.language, new Date())}</b>
    </div>
  );
}

export function CurrentStats() {
  const location = useLocation();
  const [stats, setStats] = useState<Current>();
  // const filter = useRecoilValue(analyticsFilterState);

  const user = useRecoilValue(userState);

  const load = useCallback(() => {
    if (user?.salesUser) {
      dataStatistics
        .getSalesCurrent()
        .then(setStats)
        .catch((err) => {
          setTimeout(load, 10000);
        });
      return;
    }

    const searchObject = new URLSearchParams(location.search);
    let params = "";

    if (user?.adminUser?.salesAdmin) {
      params = `?country=${user.country}`;
    } else if (searchObject.has("country")) {
      params = `?country=${searchObject.get("country")}`;
    }

    dataStatistics
      .getAdminCurrent(params)
      .then(setStats)
      .catch((err) => {
        setTimeout(load, 10000);
      });
    return;
  }, [user, location]);

  useEffect(load, [load]);

  return (
    <div className="todays-statistics">
      <h1>
        <T id="Analytics" />
      </h1>
      <CurrectStatsByTeamOrCountry />
      <ul>
        <li>
          <div className="stats-item creating">
            <b>{<T id="Creating" />}</b>
            <span>
              <Edit />
            </span>
            <div className="text-right">
              <strong>{stats?.creating ?? "-"}</strong>
            </div>
          </div>
        </li>

        <li>
          <div className="stats-item waiting-merchant">
            <b>{<T id="Awaiting merchant" />}</b>
            <span>
              <PendingActions />
            </span>
            <div className="text-right">
              <strong>{stats?.waitingSigning ?? "-"}</strong>
            </div>
          </div>
        </li>

        <li>
          <div className="stats-item waiting-signoff">
            <b>{<T id="Awaiting sign off" />}</b>
            <span>
              <AssignmentCompleted />
            </span>
            <div className="text-right">
              <strong>{stats?.waitingSignoff ?? "-"}</strong>
            </div>
          </div>
        </li>

        <li>
          <div className="stats-item completed-today">
            <b>{<T id="Sent to onboarding" />}</b>
            <span>
              <CheckCircleOutline />
            </span>
            <div className="text-right">
              <strong>{stats?.completedToday ?? "-"}</strong>
            </div>
          </div>
        </li>

        <li>
          <div className="stats-item onboarded-today">
            <b>{<T id="Onboarded today" />}</b>
            <span>
              <Heart />
            </span>

            <div className="text-right">
              <strong>{stats?.onboardedToday ?? "-"}</strong>
            </div>
          </div>
        </li>
        <li />
      </ul>
    </div>
  );
}
