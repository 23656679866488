import { motion } from "framer-motion";
import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { TranslationText } from "../../data/dataTranslation";
import {
  RegisteredTranslation,
  translationManagementState,
  translationStateActiveSelector,
} from "../../state/translationState";
import { Badge } from "../Badge/Badge";
import { Checkmark } from "../icons/Checkmark";
import { ErrorOutline } from "../icons/ErrorOutline";
import { Button } from "../interactions/Buttons/Button";
import "./TranslationList.scss";

interface Props {
  onClose: () => void;
  translationTexts?: TranslationText[];
}

export const TranslationList: React.FunctionComponent<Props> = ({
  onClose,
  translationTexts,
}) => {
  const [{ registeredTranslations }] = useRecoilState(
    translationManagementState
  );
  const setActive = useSetRecoilState(translationStateActiveSelector);

  // const setTranslation = useSetRecoilState(translationStateSelector);

  // const selectTranslation = (selected: string) => {
  //   setTranslation({ selected });
  // };

  const scrollToTranslation = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    { ref, highlightFunction }: RegisteredTranslation
  ) => {
    e.stopPropagation();
    if (!ref.current) {
      return;
    }
    ref.current.scrollIntoView({
      block: "center",
      inline: "center",
      behavior: "smooth",
    });
    highlightFunction();
  };

  return (
    <div className="translation-list">
      <h5>
        Translations on page{" "}
        <Badge content={registeredTranslations.length.toString()} />
      </h5>
      {registeredTranslations.length ? (
        <ul>
          {registeredTranslations.map((entry) => (
            <li onClick={(e) => scrollToTranslation(e, entry)} key={entry.id}>
              {!translationTexts?.find((t) => t.key === entry.id)?.value ? (
                <ErrorOutline className="missing-icon" />
              ) : (
                <Checkmark className="translated-icon" />
              )}
              <span>{entry.id}</span>
            </li>
          ))}
        </ul>
      ) : (
        "No translations found"
      )}
      <div className="buttons">
        <motion.div
          layout
          className="m-top-20"
          layoutId="translation-list-button"
        >
          <Button block onClick={onClose}>
            Hide list
          </Button>
        </motion.div>
        <motion.div layoutId="translation-exit-button">
          <Button
            block
            className="danger-button"
            onClick={() => setActive(false)}
          >
            Exit translation mode
          </Button>
        </motion.div>
      </div>
    </div>
  );
};
