import { useCallback, FC } from "react";
import { Associate } from "../../../model/associate/associateTypes";
import { Validity } from "../../../model/contract/contractType";
import { Status } from "../../../data/types";
import { Toggle } from "../../interactions/Toggle/Toggle";
import { Beacon } from "../../beacon/Beacon";
import { EditIcon } from "../../icons/EditIcon";
import { AnimatePresence } from "framer-motion";
import styles from "./AssociateRow.module.scss";
import AssociateRolesList from "../AssociateRolesList";

interface Props {
  associate: Associate;
  setActiveAssociate: (associate: Associate) => void;
  status: Status;
  toggled: boolean;
  onToggle: (selected: boolean, data: any) => void;
  validity?: Validity;
}

export const AssociateRow: FC<Props> = ({
  associate,
  setActiveAssociate,
  status,
  toggled,
  onToggle,
  validity,
}) => {
  const onClick = useCallback(() => setActiveAssociate(associate), [associate, setActiveAssociate]);

  return (
    <div className={styles.root}>
      <Toggle className="small" onChange={onToggle} selected={toggled} status={status} data={associate} />
      <button onClick={onClick} className={styles.wrapper_button}>
        <AssociateRowInner associate={associate} validity={validity} />
      </button>
    </div>
  );
};

interface AssociateRowProps {
  associate: Associate;

  validity?: Validity;
}

export const AssociateRowInner: FC<AssociateRowProps> = ({ associate, validity }) => {
  return (
    <div className={styles.associate_row}>
      <Beacon validity={validity} />

      <div className={styles.associate_info}>
        <div style={{ paddingLeft: 5 }}>
          <span style={{ marginRight: 8 }}>{getName(associate.firstName, associate.lastName)}</span>
          <span className="text-passive fs-small">
            {associate.email} {associate.phoneNumber}
          </span>
        </div>

        {associate.roles.length > 0 && (
          <AnimatePresence>
            <AssociateRolesList associate={associate} />
          </AnimatePresence>
        )}
      </div>

      <div className={styles.icon}>
        <EditIcon />
      </div>
    </div>
  );
};

function getName(firstName?: string, lastName?: string) {
  if (firstName && lastName) {
    return `${lastName}, ${firstName}`;
  }

  return firstName || lastName || "No name set";
}
