import { FC, useState } from "react";
import { ErrorBox } from "../../../../../components/boxes/ErrorBox";
import { Pending } from "../../../../../components/icons/Pending";
import { ZoomableImage } from "../../../../../components/images/ZoomableImage";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { T } from "../../../../../components/translation/T";
import { IdScan, MerchantAssociateResponse } from "../../../../../data/dataConfirm";
import { cache } from "../../../../../network/Cache";
import styles from "../../AssociateReview.module.scss";
import { ExtractedIdText } from "../../components/ExtractedIdText";
import { ImageToggler } from "../../components/ImageToggler/ImageToggler";

interface Props {
  idScan: IdScan | undefined;
  merchantAssociate: MerchantAssociateResponse;
  fetchIdScan: () => void;
  isFetchingData: boolean;
  idScanApiError: boolean;
}

export const IdCardDisplay: FC<Props> = ({
  idScan,
  merchantAssociate,
  fetchIdScan,
  isFetchingData,
  idScanApiError,
}) => {
  const [showIdText, setShowIdText] = useState(false);

  if (isFetchingData) return <Loading />;

  return (
    <>
      <IdImages merchantAssociate={merchantAssociate} idScan={idScan} showIdText={showIdText} />
      {idScanApiError ? (
        <>
          <ErrorBox className="m-top-10">
            <T>We could not load the id scan...</T>
          </ErrorBox>
          <Button
            onClick={() => {
              cache.clear();
              fetchIdScan();
            }}
            className="m-top-10"
            block
          >
            Retry?
          </Button>
        </>
      ) : (
        <Button onClick={() => setShowIdText(!showIdText)} className={"m-top-20 "} ghost={showIdText} block>
          {showIdText ? <T>Show ID</T> : <T>Show extracted text</T>}
        </Button>
      )}
    </>
  );
};

interface IdImagesProps {
  merchantAssociate: MerchantAssociateResponse;
  showIdText: boolean;
  idScan?: IdScan;
}

const IdImages: FC<IdImagesProps> = ({ merchantAssociate, showIdText, idScan }) => {
  const { identityDocument } = merchantAssociate;

  if (identityDocument.passport) {
    if (showIdText) {
      return <ExtractedIdText blocks={idScan?.passport} />;
    }

    return (
      <div className={styles.passport}>
        <ZoomableImage
          image={identityDocument.passport}
          textBlocks={idScan?.passport}
          alt="Passport"
          showTextBlocks={false}
        />
      </div>
    );
  }

  if (identityDocument.idFront && identityDocument.idBack) {
    return (
      <ImageToggler
        images={[identityDocument.idFront, identityDocument.idBack]}
        idScan={idScan}
        showIdText={showIdText}
        showTextBlocks={false}
      />
    );
  }

  return null;
};

export const Loading = () => {
  return (
    <div className={styles.loader}>
      <div>
        <Pending />
        <div>Scanning images</div>
      </div>
    </div>
  );
};
