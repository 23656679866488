import { FC, useCallback, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { ConfirmButton } from "../../../components/interactions/Buttons/ConfirmButton";
import { Status } from "../../../data/types";
import { CONTRACT_ROUTE } from "../../Contract/ContractPage";
import { getDynamicLink } from "../../../components/utils";
import { dataConfirm } from "../../../data/dataConfirm";
import { ContractStatusState } from "../../../state/contractStatusState";
import { useHistory } from "react-router";
import { MerchantStatusState } from "../../../state/merchantStatusState";
import { getMerchantStatusEdit } from "../MerchantStatus";
import styles from "./RevokeBox.module.scss";
import { TrashIcon } from "../../../components/icons/TrashIcon";

interface Props {
  contractStatus: ContractStatusState;
  merchantStatus: MerchantStatusState;
  status: Status;
}

export const RevokeBox: FC<Props> = ({ contractStatus, merchantStatus, status }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [revokeStatus, setRevokeStatus] = useState<Status>(Status.DEFAULT);

  const { contractId, edit } = contractStatus;

  const revokeContract = useCallback(() => {
    dataConfirm
      .retractContract(contractId)
      .then(() => history.push(getDynamicLink(CONTRACT_ROUTE, { id: contractId })))
      .catch((err) => {
        setRevokeStatus(Status.ERROR);
        setTimeout(() => setRevokeStatus(Status.DEFAULT), 4000);
      });
  }, [history, contractId]);

  return (
    <div className={styles.root}>
      <b className={styles.title}>
        {t("Revoke contract")} <TrashIcon />
      </b>
      <p className={`${styles.p} line-mini`}>
        {t(
          "All sent links will be rendered obsolete, all uploaded documents will be removed and the contract will be placed in editing mode."
        )}
      </p>

      {revokeStatus === Status.ERROR && (
        <p className="line-mini">
          <Trans>
            <b>Ouch!</b> The irony, we failed to remove the contract. Try again?
          </Trans>
        </p>
      )}

      <ConfirmButton
        removeButtonText={t("Revoke contract")}
        onClick={revokeContract}
        className="danger-button"
        status={getMerchantStatusEdit(
          merchantStatus,
          edit,
          status === Status.SUCCESS ? Status.DISABLED : revokeStatus
        )}
      >
        {t("Revoke contract")}
      </ConfirmButton>
    </div>
  );
};
