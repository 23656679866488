import { atom, selector } from "recoil";
import { ContractDocument, DocumentType } from "../model/contract/contractType";

export const contractDocumentsState = atom<ContractDocument[]>({
  key: "contract-documents-state",
  default: [],
});

export const generalDocumentSelector = selector({
  key: "general-document-selector",
  get: ({ get }) => {
    return get(contractDocumentsState).filter(
      (doc) => doc.documentType !== DocumentType.E_COMMERCE_SUPPLEMENT
    );
  },
});
