import React from "react";
import { useTranslation } from "react-i18next";
import { PricingStep } from "../../model/contract/contractType";
import { Country, Currency } from "../../model/common/commonType";
import { ContractPackage, Validity } from "../../model/contract/contractType";
import { Language } from "../../i18n";
import { Beacon } from "../beacon/Beacon";
import { getIntlCost } from "../utils";
import "./Acquiring.scss";

interface Props {
  pack: ContractPackage;
  country: Country;
}

export const AcquiringPL: React.FunctionComponent<Props> = ({ pack, country }) => {
  const { t } = useTranslation();

  if (country !== Country.POLAND) {
    return null;
  }

  return (
    <div className="acquiring">
      <strong>{t("Worldline acquiring fees")}</strong>
      <br />
      {t("The commission rate for every transaction depends on the monthly transaction volume.")}
      <dl>
        <dt>
          <span>
            <i>Light</i> - {t("from 0 to 3000 zł")}
          </span>
        </dt>
        <dd>
          {" "}
          {pack.pricingStep === PricingStep.LIGHT && (
            <span className="selected-acquiring">
              <b>{t("selected")}</b>
              <Beacon validity={Validity.VALID} mini />
            </span>
          )}
          0.30%
        </dd>
        <dt>
          <span>
            <i>Standard</i> - {t("from 3001 to 6500 zł")}
          </span>
        </dt>
        <dd>
          {" "}
          {pack.pricingStep === PricingStep.STANDARD && (
            <span className="selected-acquiring">
              <Beacon validity={Validity.VALID} mini />
              <b>{t("selected")}</b>
            </span>
          )}
          0.25%
        </dd>
        <dt>
          <span>
            <i>Advanced</i> - {t("above 6500 zł")}
          </span>
        </dt>
        <dd>
          {" "}
          {pack.pricingStep === PricingStep.ADVANCED && (
            <span className="selected-acquiring">
              <b>{t("selected")}</b>
              <Beacon validity={Validity.VALID} mini />
            </span>
          )}
          0.20%
        </dd>
        <dt />
        <dd />
        <dt>
          <b>{t("Deactivation fee")}</b>
        </dt>
        <dd>{getIntlCost(Language.POLAND, 450, Currency.POLAND)}</dd>
      </dl>
      <p>
        {t(
          "Note that the figure above represents the basic monthly cost. The final cost will also depend on the number of selected terminals."
        )}
      </p>
    </div>
  );
};
