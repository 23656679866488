import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import "./Image.scss";
import { AnimatePresence, motion } from "framer-motion";

const PLACEHOLDER =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

interface Props {
  active?: boolean;
  src: string;
  alt: string;
  ratio: number;
  className?: string;

  noBackground?: boolean;
  notSelected?: boolean;
  size?: "small" | "medium" | "large";
}

export const NewImage: React.FunctionComponent<Props> = ({
  alt,
  src,
  active = true,

  className,

  noBackground = false,
  notSelected = false,
  size = "medium",
}) => {
  const [url, setUrl] = useState<string>(PLACEHOLDER);
  const [loaded, setLoaded] = useState<boolean>(false);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!active) {
      return;
    }

    if (!imageRef.current) {
      return;
    }

    const ref = imageRef.current;

    ref.onload = () => {
      setLoaded(true);
    };

    setUrl(src);

    return () => {
      ref.onload = null;
    };
  }, [src, active, imageRef]);

  return (
    <div
      className={cx("new-image", className, {
        loaded,
        "new-image-small-size": size === "small",
        "new-image-small-large": size === "large",
      })}
    >
      <AnimatePresence>
        {!noBackground && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            className={cx("new-image-background", { "image-not-selected": notSelected })}
          />
        )}
      </AnimatePresence>
      <img src={url} alt={alt} ref={imageRef} />
    </div>
  );
};
