import { Prettify } from "../../components/utils";
import {
  CardBrand,
  FeeType,
  PricingModel,
  brandsArray,
  countrySpecificBrands,
  premiumBrandsArray,
  regularBrandsArray,
} from "./pricingTypes";
import { isPack } from "./pricingUtils";

const REGULAR_FEE_PRESET = {
  [FeeType.TRANSACTION]: {
    placeholder: 1,
    min: 0,
    max: 5,
    step: 0.01,
  },
  [FeeType.FIXED]: {
    placeholder: 0.0,
    min: 0,
    max: 5,
    step: 0.01,
  },
  [FeeType.MIN]: {
    placeholder: 0.0,
    min: 0,
    max: 5,
    step: 0.01,
  },
  [FeeType.MAX]: {
    placeholder: 3,
    min: 0,
    max: 10,
    step: 0.01,
  },
};

interface FeeBoundValues {
  placeholder: number;
  min: number;
  max: number;
  step: number;
}

type FeeBounds = Prettify<Record<FeeType, FeeBoundValues>>;
type BrandsFeeBoundsMap = Prettify<Record<CardBrand, FeeBounds>>;

type BrandTypes = "regular" | "premium";

// for now we have the same bounds for premium and regular
const getFeeBoundPreset = (brandType: BrandTypes) => {
  return brandType === "premium" ? { ...REGULAR_FEE_PRESET } : { ...REGULAR_FEE_PRESET };
};

const generateBlendedICPPBounds = (): BrandsFeeBoundsMap => {
  const boundsMap = {} as BrandsFeeBoundsMap;
  for (const brand of regularBrandsArray) boundsMap[brand] = getFeeBoundPreset("regular");
  for (const brand of premiumBrandsArray) boundsMap[brand] = getFeeBoundPreset("premium");

  for (const brand of countrySpecificBrands) boundsMap[brand] = getFeeBoundPreset("regular");

  return boundsMap;
};

// For Pack price plans all the fees should be the same for all brands
const generatePackBounds = () => {
  const boundsMap = {} as BrandsFeeBoundsMap;
  for (const brand of brandsArray) boundsMap[brand] = { ...REGULAR_FEE_PRESET };
  return boundsMap;
};

// This might need to be fetched from the backend in the future as these default values
// will most likely be configured by the pricing manager, as well as upper / lower bounds
const PACK_BOUNDS = generatePackBounds();
const BUNDLE_BOUNDS = generateBlendedICPPBounds();

type GetFeeBoundsInput = {
  pricingModel: PricingModel;
  cardBrand: CardBrand;
  feeType: FeeType;
};

export const getFeeBounds = (model: PricingModel) => {
  return isPack(model) ? PACK_BOUNDS : BUNDLE_BOUNDS;
};

export const getFeeBoundValues = ({ pricingModel, cardBrand, feeType }: GetFeeBoundsInput) => {
  return isPack(pricingModel) ? PACK_BOUNDS[cardBrand][feeType] : BUNDLE_BOUNDS[cardBrand][feeType];
};
