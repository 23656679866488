import { Address } from "../../model/contract/contractType";
import { BaseValidator } from "../form/validators/BaseValidator";
import { MaxLengthValidator } from "../form/validators/MaxLengthValidator";
import { RequiredValidator } from "../form/validators/RequiredValidator";

export const addressFieldValidators: Record<keyof Address, BaseValidator[]> = {
  street: [new MaxLengthValidator(40, "Street address can't be longer than 40 characters")],
  streetNumber: [new MaxLengthValidator(12, "Street number can't be longer than 12 characters")],
  city: [new MaxLengthValidator(40, "City name can't be longer than 40 characters")],
  postalCode: [
    new MaxLengthValidator(
      10,

      "Postal code can't be longer than 10 characters"
    ),
  ],
  countryCode: [new RequiredValidator("Country is required")],
};

export const addressFieldRequiredText: Record<keyof Address, string> = {
  street: "Street address is required",
  streetNumber: "Street number is required",
  city: "City is required",
  postalCode: "Postal code is required",
  countryCode: "Country code is required",
};
