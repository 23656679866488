import { atom } from "recoil";
import { ConfirmStatus } from "../data/dataConfirm";
import { ContractStatus } from "../model/contract/contractType";

export interface MerchantStatusState
  extends Pick<
    ConfirmStatus,
    | "status"
    | "confirmationSent"
    | "merchantConfirmed"
    | "merchantComplete"
    | "allSignedOff"
    | "contractCompleted"
    | "salesContact"
    | "tracking"
    | "remoteIdStatus"
  > {
  isConfirmed: boolean;
}

export const merchantStatusState = atom<MerchantStatusState>({
  key: "merchant-status-state",
  default: {
    status: ContractStatus.PENDING_SALES_INPUT,
    salesContact: { name: "", email: "", phoneNumber: "" },
    isConfirmed: true,
    tracking: [],
    remoteIdStatus: [],
  },
});
