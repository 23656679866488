import { upperFirst, capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { extractParameters } from "../utils/Extractor";
import { replaceParameter } from "../utils/Replacer";
import { fixResources } from "../utils/PreviewResources";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { VariableList } from "../components/VariableList/VariableList";
import { Select } from "../../../../components/form/Select";
import { TextArea } from "../../../../components/form/TextArea";
import { TextInput } from "../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Alternative } from "../../../../components/interactions/InputTypes";
import {
  TemplateId,
  EmailTemplateAndTranslationsResponse,
  dataCommunication,
} from "../../../../data/dataCommunication";
import { Language } from "../../../../i18n";
import { userState } from "../../../../state/userState";
import { AdminPage } from "../../AdminPage";
import "./EmailPreview.scss";

export const EMAIL_PREVIEW_URL =
  "/admin/communication/preview/email/:templateId";

export const EmailPreview = () => {
  const { templateId } = useParams<{ templateId: TemplateId }>();
  const [templateData, setTemplateData] =
    useState<EmailTemplateAndTranslationsResponse>();
  const [parameters, setParameters] = useState<string[]>();
  const [subject, setSubject] = useState("");
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language as Language);
  const [parameterValues, setParameterValues] = useState<
    Record<string, string>
  >({});
  const user = useRecoilValue(userState);

  useEffect(() => {
    setTemplateData(undefined);
    setParameters(undefined);
    setSubject("");
    setParameterValues({});

    if (!templateId) {
      return;
    }

    dataCommunication
      .getEmailTemplateAndTranslations(templateId, language as Language)
      .then((response) => {
        setTemplateData(response);
        const parameters = extractParameters(response.template);
        const translations = Object.entries(
          response.translation.properties
        ).reduce<Record<string, string>>((acc, [key, value]) => {
          if (parameters.includes(key)) {
            acc[key] = value;
          }
          return acc;
        }, {});
        setParameters(parameters);
        setParameterValues(translations);
        setSubject(response.translation.subject);
      });
  }, [templateId, language]);

  if (
    !templateId ||
    !templateData ||
    !parameters ||
    !user?.email ||
    !templateId
  ) {
    return null;
  }
  const withImages = fixResources(templateData.template);

  const withParameters = parameters.reduce((acc, curr) => {
    const parameter = parameterValues[curr];
    return replaceParameter(
      acc,
      curr,
      parameter && parameter.length > 0 ? parameter : `{ ${curr} }`
    );
  }, withImages);

  return (
    <AdminPage>
      <div className="email-preview">
        <h3>Preview for {templateData.templateName}</h3>
        <div className="main-wrapper">
          <div className="iframe-wrapper">
            <iframe
              title="Email preview"
              srcDoc={withParameters}
              height={1000}
              width={1000}
            />
          </div>
          <div className="translation-wrapper">
            <h4 className="space-between">
              Translations
              <Select
                alternatives={Object.entries(Language).map(
                  ([label, language]): Alternative<Language> => ({
                    text: capitalize(label),
                    value: language,
                  })
                )}
                onChange={(language) => setLanguage(language)}
                value={language}
              />
            </h4>
            <h5>Subject</h5>
            <TextInput
              onChange={(newVal) => setSubject(newVal)}
              label="Subject"
              value={subject}
              validators={[new RequiredValidator("Subject is required")]}
            />
            <h5 className="space-between">
              Parameters
              <VariableList
                variables={templateData.translation.internalProperties}
              />
            </h5>
            {parameters.map((parameter) =>
              parameter.endsWith("_block") ? (
                <TextArea
                  key={parameter}
                  onChange={(newVal) => {
                    setParameterValues({
                      ...parameterValues,
                      [parameter]: newVal,
                    });
                  }}
                  label={upperFirst(parameter).replace(/_/g, " ")}
                  value={parameterValues[parameter]}
                  attributes={{ rows: 3 }}
                />
              ) : (
                <TextInput
                  key={parameter}
                  onChange={(newVal) => {
                    setParameterValues({
                      ...parameterValues,
                      [parameter]: newVal,
                    });
                  }}
                  label={upperFirst(parameter).replace(/_/g, " ")}
                  value={parameterValues[parameter]}
                />
              )
            )}
            <Button
              onClick={() => {
                dataCommunication.postEmailPreview(
                  templateId,
                  user.email,
                  subject,
                  parameterValues
                );
              }}
              ghost
              block
            >
              Send preview
            </Button>
            <Button
              onClick={() => {
                dataCommunication.postEmailTranslations(
                  templateId,
                  language,
                  subject,
                  parameterValues
                );
              }}
              block
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </AdminPage>
  );
};
