import { useRecoilValue } from "recoil";
import { contractArticlesSelector } from "../../../../state/contractStoresState";
import { Article, ArticleName } from "../../../../data/dataTerminalPricing";
import styles from "./ArticlesTable.module.scss";
import { useTranslation } from "react-i18next";

const ArticlesTable = () => {
  const articles = useRecoilValue(contractArticlesSelector);
  const { t } = useTranslation();

  const groupedArticles = groupArticles(articles);

  const getTotalPrice = (totalPrice: number) => {
    return totalPrice === 0 ? t("Free") : totalPrice;
  };

  if (groupedArticles.length < 1) return null;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h5 style={{ margin: 0 }}>{t("Article summary")}</h5>
      </div>
      <div className={styles.subheader}>
        <div>{t("Article")}</div>
        <div>{t("Amount")}</div>
        <div>{t("Price type")}</div>
        <div>{t("Total cost")}</div>
      </div>
      <div className={styles.body}>
        {groupedArticles.map((groupedArticle) => (
          <div className={styles.row}>
            <div className={styles.article}>{groupedArticle.article.articleDescription}</div>
            <div>{groupedArticle.amount}</div>
            <div>{groupedArticle.article.priceType}</div>
            <div className={groupedArticle.totalPrice === 0 ? "color-green-heavy" : ""}>
              {getTotalPrice(groupedArticle.totalPrice)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

type GroupedArticle = { articleName: ArticleName; totalPrice: number; amount: number; article: Article };

const groupArticles = (articles: Article[]): GroupedArticle[] => {
  const articleMap = new Map<string, GroupedArticle>();

  articles.forEach((article) => {
    const { article: articleName, price = 0 } = article;

    if (!articleMap.has(articleName)) {
      articleMap.set(articleName, {
        articleName,
        article,
        totalPrice: price,
        amount: 1,
      });
    } else {
      const foundArticle = articleMap.get(articleName)!;
      foundArticle.totalPrice += price;
      foundArticle.amount += 1;
    }
  });

  return Array.from(articleMap.values());
};

export default ArticlesTable;
