import { cache } from "../network/Cache";
import { data } from "./data";
import { LoginMethod } from "./dataAuth";
import { ContractId, Country, TeamId, UserId, UTCDate } from "../model/common/commonType";
import { ContractStatus, PackageId } from "../model/contract/contractType";
import { Page, SortDirection } from "../model/Page";
import { PricingModel } from "../model/pricing/pricingTypes";

export interface AdminUser {
  id: UserId;
  email: string;
  name: string;
  loginMethod: LoginMethod;
  superAdmin: boolean;
  salesAdmin: boolean;
  pricingAdmin: boolean;
  country?: Country;
  created: string;
  updated: string;
}

export interface BackofficeUser {
  id: UserId;
  email: string;
  name: string;
  loginMethod: LoginMethod;
  country: Country;
  created: string;
  updated: string;
  disabled: string;
}

//Man skulle kunna köra in en vanlig admin user också, men då måste man typ
// köra null på id-fältet så det skulle kladda ner den klassen
export interface NewAdministratorRequest {
  email: string;
  name: string;
  loginMethod: LoginMethod;
  superAdmin: boolean;
  salesAdmin: boolean;
  pricingAdmin: boolean;
  country?: Country;
}

export interface NewBackofficeUsersRequest {
  email: string;
  name: string;
  loginMethod: LoginMethod;
  country: Country;
}

export interface SalesUser {
  id: UserId;
  email: string;
  phone: string;
  name: string;
  loginMethod: LoginMethod;
  country: Country;
  teamId: TeamId;
  teamName: string;
  external: boolean;
  enabledBundles: PackageId[];
  lastLogin?: AdminLoginHistory;
  created: string;
  updated: string;
  salesId: string;
}

export interface SalesUserRequest {
  email: string;
  phone: string;
  name: string;
  loginMethod: LoginMethod;
  teamId: TeamId;
  salesId: string;
}

export interface AdminLoginHistory {
  created: string;
  ip: string;
  method: LoginMethod;
}

export interface TeamPricePreset {
  id: number;
  name: string;
  pricingModel: PricingModel;
}

export interface SalesTeam {
  id: TeamId;
  name: string;
  external: boolean;
  created: UTCDate;
  country: Country;
  enabledBundles: PackageId[];
  merchandiser?: string;
  memberCount: number;
  pricingPresets: TeamPricePreset[];
}

export interface TeamRequest {
  name: string;
  external: boolean;
  enabledBundles: PackageId[];
  merchandiser?: string;
  pricingPresets: number[];
}

export interface CurrentContract {
  contractId: ContractId;
  created: UTCDate;
  lastUpdated?: UTCDate;
  completed?: UTCDate;
  status: ContractStatus;
  companyName?: string;
  website?: string;
  companyRegistrationId: string;
}

//Alla dom här endpointsen kräver att man är en Super eller Sales Admin
export const userAdministration = {
  loadAllAdministrators: () => data.get<AdminUser[]>("/api/admin/users", true),

  addNewAdministrator: (request: NewAdministratorRequest) => {
    return data.post<AdminUser[]>("/api/admin/users", request).then((response) => {
      cache.delete(`/api/admin/users`);
      return response;
    });
  },

  editAdministrator: (id: UserId, request: NewAdministratorRequest) => {
    return data.post<AdminUser[]>(`/api/admin/users/${id}`, request).then((response) => {
      cache.delete(`/api/admin/users`);
      return response;
    });
  },

  deleteAdministrator: async (id: UserId) => {
    const response = await data.delete<AdminUser[]>(`/api/admin/users/${id}`);
    cache.delete(`/api/admin/users`);
    return response;
  },

  loadAllBackofficeUsers: () => data.get<BackofficeUser[]>("/api/admin/users/backoffice", true),

  addNewBackofficeUsers: (request: NewBackofficeUsersRequest) => {
    return data.post<BackofficeUser[]>("/api/admin/users/backoffice", request).then((response) => {
      cache.delete(`/api/admin/users/backoffice`);
      return response;
    });
  },

  editBackofficeUsers: (id: UserId, request: NewBackofficeUsersRequest) => {
    return data.post<BackofficeUser[]>(`/api/admin/users/backoffice/${id}`, request).then((response) => {
      cache.delete(`/api/admin/users/backoffice`);
      return response;
    });
  },

  deleteBackofficeUsers: async (id: UserId) => {
    const response = await data.delete<BackofficeUser[]>(`/api/admin/users/backoffice/${id}`);
    cache.delete(`/api/admin/users/backoffice`);
    return response;
  },

  loadSalesUsers: (
    page: number,
    size: number,
    country: string | null,
    teamId: TeamId | null,
    sort: string | null,
    sortOrder: SortDirection | null
  ) => {
    const uri =
      `/api/admin/users/sales?page=${page}&size=${size}` +
      (country != null ? `&country=${country}` : "") +
      (teamId != null ? `&teamId=${teamId}` : "") +
      (sort != null ? `&sort=${sort}` : "") +
      (sortOrder != null ? `&sortOrder=${sortOrder}` : "");

    return data.get<Page<SalesUser>>(uri, true);
  },

  loadSalesUser: (userId: UserId) => data.get<SalesUser>(`/api/admin/users/sales/${userId}`),

  /**
   * Loads all sales users based o the admin country, only works for sales admin
   */
  loadAllSalesUsers: () => data.get<SalesUser[]>(`/api/admin/users/sales/all`),

  loadSalesUserLogins: (userId: UserId, page: number, size: number) =>
    data.get<Page<AdminLoginHistory>>(`/api/admin/users/sales/${userId}?page=${page}&size=${size}`),

  addNewSalesTeam: (team: TeamRequest) => {
    return data.post(`/api/admin/sales/teams`, team).then((response) => {
      cache.delete(`/api/admin/sales/teams`);
      return response;
    });
  },
  //Implicitly loads teams for the current sales admins country
  loadSalesTeams: () => {
    const uri = `/api/admin/sales/teams`;
    return data.get<SalesTeam[]>(uri);
  },
  loadTeam: (teamId: TeamId) => {
    return data.get<SalesTeam>(`/api/admin/sales/teams/${teamId}`);
  },
  updateTeam: (teamId: TeamId, request: TeamRequest) => {
    return data.post(`/api/admin/sales/teams/${teamId}`, request).then((response) => {
      cache.delete(`/api/admin/sales/teams`);
      return response;
    });
  },
  loadTeamMembers: (teamId: TeamId) => {
    return data.get<SalesUser[]>(`/api/admin/users/sales?teamId=${teamId}`);
  },
  loadTeamContractsPage: (teamId: TeamId, page: number, size: number) => {
    return data.get<Page<CurrentContract>>(
      `/api/admin/sales/teams/${teamId}/contracts?page=${page}&size=${size}`
    );
  },
  addNewSalesUser: (request: SalesUserRequest) =>
    data.post<SalesUser>("/api/admin/users/sales", request).then((response) => {
      cache.delete(`/api/admin/users/sales`);
      return response;
    }),

  updateSalesUser: (userId: UserId, request: SalesUserRequest) =>
    data.post<SalesUser>(`/api/admin/users/sales/${userId}`, request).then((response) => {
      cache.delete(`/api/admin/users/sales`);
      return response;
    }),

  loadSalesUserContracts: (userId: UserId) =>
    data.get<CurrentContract[]>(`/api/admin/users/sales/${userId}/contracts`, true),

  loadSalesUserLoginHistory: (userId: UserId, page: number, size = 10) =>
    data.get<Page<AdminLoginHistory>>(
      `/api/admin/users/sales/${userId}/logins?page=${page}&size=${size}`,
      true
    ),
  deleteSalesUser: (userId: UserId) =>
    data.delete(`/api/admin/users/sales/${userId}`).then((response) => {
      cache.delete(`/api/admin/users/sales`);
      return response;
    }),

  isFormValid: (form: SalesUserRequest) => {
    if (
      form.email.length === 0 ||
      form.phone.length === 0 ||
      form.name.length === 0 ||
      form.salesId.length === 0 ||
      form.teamId.length === 0
    ) {
      return false;
    }

    if (!form.phone.startsWith("+") || form.phone.length < 8) {
      return false;
    }

    if (!userAdministration.isValidEmail(form.email)) {
      return false;
    }
    return true;
  },

  isValidEmail: (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  delegateContract: (contractId: ContractId, userId: UserId) => {
    return data.post(`/api/admin/contracts/delegate`, {
      contractId,
      userId,
    });
  },

  //TODO user audit log
};
