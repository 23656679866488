import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";
import { ErrorBox } from "../../components/boxes/ErrorBox";
import { StatusBoxes } from "../../components/boxes/StatusBoxes";
import { ConfirmButton } from "../../components/interactions/Buttons/ConfirmButton";
import { dataContracts } from "../../data/dataContracts";
import { ContractStatus } from "../../model/contract/contractType";
import { Status } from "../../data/types";
import { contractStatusState } from "../../state/contractStatusState";
import { userState } from "../../state/userState";
import { DASHBOARD_ROUTE } from "../Dashboard/DashboardPage";
import styles from "./DeleteContract.module.scss";
import { TrashIcon } from "../../components/icons/TrashIcon";

export function DeleteContract() {
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const contractStatus = useRecoilValue(contractStatusState);
  const user = useRecoilValue(userState);
  const history = useHistory();

  function onRemove() {
    setStatus(Status.DEFAULT);
    dataContracts
      .deleteContract(contractStatus.contractId)
      .then(() => history.push(DASHBOARD_ROUTE))
      .catch((err) => {
        setStatus(Status.ERROR);
      });
  }

  if (
    contractStatus.status === ContractStatus.COMPLETE ||
    contractStatus.status === ContractStatus.ANONYMISED
  ) {
    return null;
  }

  let warning = null;

  if (contractStatus.status === ContractStatus.SALES_VERIFICATION) {
    warning =
      "This contract is almost completed. All that is left is to confirm the files/information that where attached by the merchant. If you delete this contract all information will be lost. Make sure you know what you are doing.";
  } else {
    warning =
      "This action can't be undone. This contract will disappear, all sent links will be rendered obsolete and all uploaded documents will be removed.";
  }

  return (
    <div className={styles.container}>
      {status === Status.ERROR && (
        <StatusBoxes status={status}>
          <ErrorBox relative>
            {t("Ooh no! We couldn't delete the contract. Try again?")}
            <br />
          </ErrorBox>
        </StatusBoxes>
      )}

      <div className={styles.root}>
        <strong className={styles.title}>
          {t("Be aware!")} <TrashIcon />
        </strong>
        <p className={styles.p}>{t(warning)}</p>
        <ConfirmButton
          className="danger-button"
          onClick={onRemove}
          removeButtonText={t("Yes, delete")}
          status={contractStatus.currentSalesUser === user?.userId ? Status.DEFAULT : Status.DISABLED}
          small={false}
        >
          {t("Delete contract")}
        </ConfirmButton>
      </div>
    </div>
  );
}
