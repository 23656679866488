import cx from "classnames";
import { Alternative } from "../interactions/InputTypes";
import "./Tabs.scss";
import { motion } from "framer-motion";

interface Props<T> {
  tabs: Alternative<T>[];
  onChange: (value: T) => void;
  value: T | undefined;
  disabled?: boolean;
}

export const Tabs = <T extends string>({ tabs, onChange, value, disabled }: Props<T>): JSX.Element | null => {
  if (!tabs.length) {
    return null;
  }

  return (
    <ul className="tabs">
      {tabs.map((tab) => {
        const active = tab.value === value;
        return (
          <li key={tab.value.toString()}>
            <button
              className={cx("tab-button", {
                active,
                disabled: tab.disabled || disabled,
              })}
              onClick={() => onChange(tab.value)}
              type="button"
            >
              {tab.text}
            </button>
            {active && (
              <motion.div
                className="active-indicator"
                layoutId="tab-active-indicator"
                transition={{
                  duration: 0.5,
                  type: "spring",
                }}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};
