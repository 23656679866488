import { data } from "../data";
import { ConstrainedAssociate } from "../dataConfirm";
import { Associate } from "../../model/associate/associateTypes";
import { ONGOING_RESPONSE, QueueCallback } from "./QueueTypes";
import { AssociateId, Cas, ContractId } from "../../model/common/commonType";

interface AssociateCas {
  associate: Cas;
  owner: Cas;
  id: Cas;
}

export enum SaveType {
  ASSOCIATE = "ASSOCIATE",
  OWNER = "OWNER",
  ID = "ID",
}

interface AssociateQueueProps {
  contractId: ContractId;
  associate: ConstrainedAssociate;
  saveType: SaveType;
  callback: QueueCallback<Associate>;
}

class CasStorage {
  storage = new Map<AssociateId, AssociateCas>();

  getCas(associate: ConstrainedAssociate): AssociateCas {
    const associatedCas = this.storage.get(associate.associateId);
    return {
      associate: (associate.cas as Cas) || (0 as Cas),
      owner: (associate.owner?.cas as Cas) ?? (0 as Cas),
      id: associate.identity?.cas ?? (0 as Cas),
      ...associatedCas,
    };
  }

  setCas(associate: ConstrainedAssociate) {
    this.storage.set(associate.associateId, {
      associate: associate.cas,
      owner: associate.owner?.cas ?? (0 as Cas),
      id: associate.identity?.cas ?? (0 as Cas),
    });
  }

  setCasByResponse(associates: Associate[]) {
    associates.forEach((associate) => this.setCas(associate));
  }
}

class Associates {
  queue: AssociateQueueProps[] = [];
  isRequesting: AssociateId | null = null;
  casStorage = new CasStorage();

  isInQueue(associate: ConstrainedAssociate) {
    return !!this.queue.find(
      (associateInQueue) => associateInQueue.associate.associateId === associate.associateId
    );
  }

  saveAssociate(
    contractId: ContractId,
    associate: ConstrainedAssociate,
    saveType: SaveType,
    callback: QueueCallback<Associate>
  ) {
    if (this.isRequesting) {
      this.queue = this.queue.filter(
        (associateInQueue) => associateInQueue.associate.associateId !== associate.associateId
      );

      this.queue.push({ contractId, associate, saveType, callback });
      return;
    }

    if (!this.queue.length) {
      this.postAssociate(contractId, associate, saveType, callback);
      return;
    }

    const next = this.queue.pop();
    if (next) {
      this.postAssociate(next.contractId, next.associate, next.saveType, next.callback);
    }
  }

  postAssociate(
    contractId: ContractId,
    associate: ConstrainedAssociate,
    saveType: SaveType,
    callback: QueueCallback<Associate>
  ) {
    this.isRequesting = associate.associateId;

    getPromise(contractId, associate, saveType, this.casStorage.getCas(associate))
      .then((response) => {
        this.casStorage.setCasByResponse(response);
        const hasRecentUpdate = this.isInQueue(associate);
        this.isRequesting = null;

        const next = this.queue.pop();
        if (next) {
          this.postAssociate(next.contractId, next.associate, next.saveType, next.callback);
        }

        hasRecentUpdate ? callback(ONGOING_RESPONSE) : callback(null, response);
      })
      .catch((err) => {
        const hasRecentUpdate = this.isInQueue(associate);
        this.isRequesting = null;

        const next = this.queue.pop();
        if (next) {
          this.postAssociate(next.contractId, next.associate, next.saveType, next.callback);
        }

        hasRecentUpdate ? callback(ONGOING_RESPONSE) : callback(err);
      });
  }

  deleteAssociate(contractId: ContractId, associateId: AssociateId) {
    return data.delete<Associate[]>(`/api/sales/contracts/${contractId}/associates/${associateId}`);
  }
}

function getPromise(
  contractId: ContractId,
  associate: ConstrainedAssociate,
  saveType: SaveType,
  associateCas: AssociateCas
) {
  if (saveType === SaveType.ASSOCIATE) {
    return data.post<Associate[]>(`/api/sales/contracts/${contractId}/associates`, {
      ...associate,
      cas: associateCas.associate,
    });
  }

  if (saveType === SaveType.OWNER && associate.owner) {
    return data.post<Associate[]>(
      `/api/sales/contracts/${contractId}/associates/${associate.associateId}/owner`,
      { ...associate.owner, cas: associateCas.owner }
    );
  }

  if (saveType === SaveType.ID && associate.identity) {
    return data.post<Associate[]>(
      `/api/sales/merchant/${contractId}/associates/${associate.associateId}/identity`,
      {
        ...associate.identity,
        cas: associateCas.id,
      }
    );
  }

  return Promise.resolve([]);
}

export const associateQueue = new Associates();
