import { data } from "./data";
import { ContractId, UserId, UTCDate } from "../model/common/commonType";
import { Page } from "../model/Page";

export enum ContractAuditType {
  VIEW_CONTRACT = "VIEW_CONTRACT",
  NEW_CONTRACT = "NEW_CONTRACT",
  DELETED_CONTRACT = "DELETED_CONTRACT",
  RETRACT_CONTRACT = "RETRACT_CONTRACT",
  CLAIM_CONTRACT = "CLAIM_CONTRACT",

  SIGNEE_SELECTION = "SIGNEE_SELECTION",
  SIGNEE_CONFIRMATION = "SIGNEE_CONFIRMATION",
  SIGNEE_REJECTION = "SIGNEE_REJECTION",

  OWNER_SELECTION = "OWNER_SELECTION",
  OWNER_CONFIRMATION = "OWNER_CONFIRMATION",

  ASSOCIATE_SIGNOFF = "ASSOCIATE_SIGNOFF",

  SEND_CONFIRMATION = "SEND_CONFIRMATION",
  MARK_COMPLETE = "MARK_COMPLETE",

  LOCAL_REGISTRY_UPDATE = "LOCAL_REGISTRY_UPDATE",

  LINK_REMINDER = "LINK_REMINDER",
  LINK_REVOKE = "LINK_REVOKE",

  DOCUMENT_UPLOAD = "DOCUMENT_UPLOAD",
  DOCUMENT_DELETE = "DOCUMENT_DELETE",
  DOCUMENT_REJECT = "DOCUMENT_REJECT",
  DOCUMENT_CONFIRM = "DOCUMENT_CONFIRM",
}

export interface Payload {
  key: string;
  value: string;
}

export interface ContractAuditLog {
  id: number;
  created: UTCDate;
  salesUserName: string;
  salesUserId: UserId;
  ip: string;
  type: ContractAuditType;
  payload: Payload[];
}

export interface ContractAudit {
  companyName: string;
  country: string;
  created: UTCDate;
  auditLog: Page<ContractAuditLog>;
}

export const dataAudit = {
  loadContractAuditLog: (contractId: ContractId, page: number, size = 20, hideViews = false) => {
    const uri = `/api/admin/audit/contract/${contractId}?page=${page}&size=${size}&hideViews=${hideViews}`;
    return data.get<ContractAudit>(uri);
  },
};
