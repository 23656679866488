import { atom } from "recoil";
import { Cas } from "../model/common/commonType";
import { BankAccount } from "../model/contract/contractType";

export const defaultBankAccount = {
  cas: 0 as Cas,
  iban: "",
  bank: "",
  bic: "",
  accountHolder: "",
};

export const contractBankState = atom<BankAccount>({
  key: "contract-bank-state",
  default: { ...defaultBankAccount },
});
