import { data } from "./data";
import { ContractId, DocumentId } from "../model/common/commonType";

export const dataFiles = {
  deleteFile: (contractId: ContractId, docId: DocumentId) =>
    data.delete(`/api/sales/contracts/${contractId}/document/${docId}`),

  deleteFileByUrl: (url: string) => data.delete(url),
};

//Ett dokument finns på contracts objektet och är antingen generellt tillhörande dokumentet
// eller kopplat till en signee eller beneficial owner, du behöver bara mappa dom rätt visuellt

//För att hämta en dokument-fil, det här kan göras direkt med en src=".." för en bild eller
// med en window.href=.. om det är en pdf som ska laddas ner
// GET /api/sales/contracts/${contractId}/document/${id}

//För att ladda upp en fil, max 10MB
// POST /api/sales/contracts/${contractId}/document/${id}
// multiPart requestParam=document

//Ta bort ett uppladdat dokument
// DELETE /api/sales/contracts/${contractId}/document/${id}
