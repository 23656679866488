import React from "react";
import { useRecoilValue } from "recoil";
import { AcquiringPL } from "../../../components/acquiring/AcquiringPL";
import { Fee } from "../../../components/fee/Fee";
import { Link } from "../../../components/links/Link";
import { SessionResponse } from "../../../data/dataAuth";
import { Country, Currency } from "../../../model/common/commonType";
import { ContractPackage, PackageId, TerminalType } from "../../../model/contract/contractType";
import { Language } from "../../../i18n";
import { OLD_TERMINAL_PRICES } from "../../../state/terminalPricingState";
import { contractPackageState } from "../../../state/contractPackageState";
import { contractTerminalsSelector } from "../../../state/contractStoresState";
import { userState } from "../../../state/userState";

const SOFT_POS_CONTRACT_DURATION = 12;
const STANDARD_PACKAGE_CONTRACT_DURATION = 24;

const Total: React.FunctionComponent<{
  pack: ContractPackage;
  user: SessionResponse | null;
}> = ({ pack, user }) => {
  const country = user?.country as Country;

  return (
    <>
      <div className="m-bottom-40">
        <strong>Total fee includes:</strong>
        <br />
        <ol>
          <li>
            1.{" "}
            <Link
              external
              link="https://www.six-payment-services.com/en/home/products/acceptance.html#scheme-interchange-fees"
            >
              Interchange fees
            </Link>{" "}
            (as applied by the respective Card Scheme on behalf of the Card Issuers)
          </li>
          <li>
            2.{" "}
            <Link
              external
              link="https://www.six-payment-services.com/en/home/service-support/schemefees.html"
            >
              Card Scheme fees
            </Link>{" "}
            (as applied by the respective Card Scheme)
          </li>
          <li>
            3. <i>Worldline acquiring fees</i>. The commission rate for every transaction depends on the
            monthly transaction volume
          </li>
        </ol>
      </div>
      <div className="m-bottom-30">
        <AcquiringPL pack={pack} country={country} />
      </div>
      <div>
        <div className="disclaimer">
          If during a month the merchant reach a higher transaction volume threshold, the commission rate will
          adapt the next month, and be the new commission rate.
        </div>
        <div className="disclaimer">
          Worldline offers and invoices the Merchant for the agreed card brands and card types with different
          level of interchange fees, individually itemized charges (interchange fees, card scheme fees and
          Worldline acquiring fees). Unless otherwise agreed, these should also be charged in the case of a
          refund.
        </div>
        <div className="disclaimer">
          The Merchant declares to have acknowledged the individually itemized information according to card
          brand and card type in relation to the different levels of interchange fees and card scheme fees
          available under{" "}
          <Link
            external
            link="https://www.six-payment-services.com/en/home/products/acceptance.html#scheme-interchange-fees"
          >
            Interchange fees
          </Link>{" "}
          and{" "}
          <Link external link="https://www.six-payment-services.com/en/home/service-support/schemefees.html">
            Card Scheme fees
          </Link>
        </div>
      </div>
    </>
  );
};

const SOFT_POS_MONTHLY = OLD_TERMINAL_PRICES.SOFT_POS.monthlyCost;
const DESK_MONTHLY = OLD_TERMINAL_PRICES.DESK_3500.monthlyCost;
const MOVE_MONTHLY = OLD_TERMINAL_PRICES.MOVE_3500.monthlyCost;

export function PricingPL() {
  const pack = useRecoilValue(contractPackageState);
  const terminals = useRecoilValue(contractTerminalsSelector);
  const user = useRecoilValue(userState);

  if (pack.packageId === PackageId.SOFTPOS_BUNDLE) {
    const noOfTerminals = terminals.filter(
      (terminal) => terminal.terminalType === TerminalType.SOFT_POS
    ).length;

    return (
      <div className="pricing-pl">
        Worldline Tap on Mobile application is available on Android
        <div className="tablet-columns m-top-20">
          <div className="m-bottom-40">
            <strong>Payment terminal:</strong>
            <br />- Minimum contract duration <b>{SOFT_POS_CONTRACT_DURATION} months</b>
            <br />
            <br />
            Monthly acquiring service by Account
            <br />-{" "}
            <Fee
              cost={SOFT_POS_MONTHLY}
              lang={Language.POLAND}
              currency={Currency.POLAND}
              extension="month"
              decimals={2}
            />{" "}
            - Quantity <b>{noOfTerminals}</b>
            <br />
            <br />
            Additional functions:
            <br />- Dynamic currency conversion (DCC)
          </div>
          <div className="m-bottom-40">
            <strong>Services:</strong>
            <br />- Hotline
            <br />- App-download
            <br />- Software update
            <br />- System operation
          </div>
        </div>
        <div className="m-bottom-40">
          <strong>Acceptance:</strong>
          <dl>
            <dt>Brands:</dt>
            <dd>Visa, Mastercard, V PAY, Maestro</dd>

            <dt>Reimbursement currency:</dt>
            <dd>{Currency.POLAND}</dd>

            <dt>Reimbursement frequency:</dt>
            <dd>Daily</dd>

            <dt>Reimbursement notice:</dt>
            <dd>Extract of transaction level details</dd>

            <dt>Dynamic currency conversion (DCC):</dt>
            <dd>-1 % rebate on DCC transaction amount</dd>

            <dt>Features included:</dt>
            <dd>Online reports</dd>
          </dl>
        </div>
        <Total pack={pack} user={user} />
      </div>
    );
  }

  const isDesk = pack.packageId === PackageId.DESK_BUNDLE;

  const numberOfDeskTerminals = terminals.filter(
    (terminal) => terminal.terminalType === TerminalType.DESK_3500
  ).length;

  const numberOfMoveTerminals = terminals.filter(
    (terminal) => terminal.terminalType === TerminalType.MOVE_3500
  ).length;

  return (
    <div className="pricing-pl">
      Minimum contract duration <b>{STANDARD_PACKAGE_CONTRACT_DURATION} months</b>
      <div className="tablet-columns m-top-20">
        <div className="m-bottom-40">
          <strong>Terminals:</strong>

          {isDesk ? (
            <>
              {" "}
              <br />- Desk 3500:{" "}
              <Fee
                cost={DESK_MONTHLY}
                lang={Language.POLAND}
                currency={Currency.POLAND}
                extension="month"
              />{" "}
              - Quantity <b>{numberOfDeskTerminals}</b>
            </>
          ) : (
            <>
              {" "}
              <br />- Move 3500:{" "}
              <Fee
                cost={MOVE_MONTHLY}
                lang={Language.POLAND}
                currency={Currency.POLAND}
                extension="month"
              />{" "}
              - Quantity <b>{numberOfMoveTerminals}</b>
            </>
          )}
        </div>
        <div className="m-bottom-40">
          <strong>Included:</strong>
          <br />- Courier installation (plug & play)
          <br />- A terminal charger with each terminal
        </div>
        <div className="m-bottom-40">
          <strong>Services:</strong>
          <br />- On-site repair within 2 working days
          <br />- Hotline
          <br />- Software update
          <br />- System operation
        </div>
        <div className="m-bottom-40">
          <strong>Additional functions:</strong>
          <br />- Dynamic currency conversion (DCC)
          <br />- Data communication (sim card/wifi)
          <br />- Cashback
        </div>
      </div>
      <div className="m-bottom-40">
        <strong>Acceptance:</strong>
        <dl>
          <dt>Brands:</dt>
          <dd>Visa, Mastercard, V PAY, Maestro, Diners Club/Discover, UnionPay, JCB</dd>

          <dt>Reimbursement currency:</dt>
          <dd>{Currency.POLAND}</dd>

          <dt>Reimbursement frequency:</dt>
          <dd>Daily</dd>

          <dt>Reimbursement notice:</dt>
          <dd>Extract of transaction level details</dd>

          <dt>Dynamic currency conversion (DCC):</dt>
          <dd>-1 % rebate on DCC transaction amount</dd>

          <dt>Features included:</dt>
          <dd>Online reports</dd>
        </dl>
      </div>
      <Total pack={pack} user={user} />
    </div>
  );
}
