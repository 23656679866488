import React from "react";
import "./PendingOverlay.scss";
import { Spinner } from "../spinner/Spinner";
import { motion } from "framer-motion";

interface Props {
  text?: string | React.ReactNode;
}

export const PendingOverlay: React.FunctionComponent<Props> = ({ text }) => {
  return (
    <motion.div
      className="pending-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div layout>
        <Spinner />
      </motion.div>
      {text && <motion.div className="text">{text}</motion.div>}
    </motion.div>
  );
};
