import React from "react";
import cx from "classnames";
import "./Icon.scss";

interface Props {
  className?: string;
}

export const ChevronDown: React.FunctionComponent<Props> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={cx("icon", "chevron-down", className)}
  >
    <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);
