import React from "react";
import cx from "classnames";
import { ITEM_HEIGHT, ITEM_OFFSET, ITEM_HEIGHT_EVENT } from "./Timeline";
import { ContractDocument } from "../../../model/contract/contractType";

interface Props {
  active: boolean;
  documents: ContractDocument[];
}

export const MANDATORY_EVENTS = 2; // "Contract created", "Contract sent for confirmation"

export function getOwnerOffset(documents: ContractDocument[]) {
  const start = ITEM_OFFSET + MANDATORY_EVENTS * ITEM_HEIGHT + ITEM_HEIGHT_EVENT;

  return start + ITEM_HEIGHT + documents.length * ITEM_HEIGHT_EVENT;
}

export const ToSignature: React.FunctionComponent<Props> = ({ active, documents }) => {
  const start = ITEM_OFFSET + MANDATORY_EVENTS * ITEM_HEIGHT;
  const end = getOwnerOffset(documents);
  const length = documents.length;

  if (!length) {
    return (
      <>
        <line x1="10" x2="10" y1={start} y2={end} className={cx({ active })} />
        <circle r="4" cx="10" cy={end} className={cx({ active })} />
      </>
    );
  }

  const items = [];
  for (let i = 0; i < length; i++) {
    const itemEnd = start + ITEM_HEIGHT + i * ITEM_HEIGHT_EVENT;

    items.push(
      <React.Fragment key={`to-signature-${documents[i].id}`}>
        <line x1="10" x2="50" y1={start} y2={itemEnd} className={cx({ active })} />
        <circle r="4" cx="50" cy={itemEnd} className={cx({ active })} />
      </React.Fragment>
    );
  }

  return (
    <>
      {items}
      <line x1="10" x2="10" y1={start} y2={end} className={cx({ active })} />
      <circle r="4" cx="10" cy={end} className={cx({ active })} />
    </>
  );
};
