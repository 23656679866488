import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class NumberValidator extends BaseValidator {
  name = "NumberValidator";

  validate(value: number | string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      const num = Number(value);

      if ((typeof value === "string" && value.length === 0) || !isNaN(num)) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
