import React, { useCallback, useRef, useState } from "react";
import cx from "classnames";
import { id } from "../../utils";
import { Status } from "../../../data/types";
import "./Toggle.scss";

interface Props {
  selected: boolean;
  data?: any;
  onChange: (selected: boolean, data: any) => void;
  status?: Status;
  className?: string;
  label?: string | React.ReactNode;
  name?: string;
}

export const Toggle: React.FunctionComponent<Props> = ({
  selected,
  data,
  onChange,
  status = Status.DEFAULT,
  className,
  label = null,
  name,
}) => {
  const identifier = useRef<string>(id());
  const [focus, setFocus] = useState<boolean>(false);
  const change = useCallback(() => {
    onChange(!selected, data);
  }, [selected, data, onChange]);

  const onFocus = useCallback(() => setFocus(true), []);
  const onBlur = useCallback(() => setFocus(false), []);

  return (
    <label
      htmlFor={identifier.current}
      className={cx("toggle", className, status, {
        "is-selected": selected,
        "has-focus": focus,
      })}
    >
      <div className="toggle-label-wrapper">
        <div className="toggle-background">
          <div className="toggle-handle" />

          <input
            id={identifier.current}
            name={name ?? identifier.current}
            type="checkbox"
            onChange={change}
            checked={selected}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={status === Status.DISABLED || status === Status.PENDING}
          />
        </div>

        {label && (
          <div className="toggle-label">
            <div className="toggle-label-tag">{label}</div>
          </div>
        )}
      </div>
    </label>
  );
};
