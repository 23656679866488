import { FC } from "react";

import styles from "./TransactionFeeInputs.module.scss";
import { WarningBox } from "../../../../../../../components/boxes/WarningBox";
import { Checkboxes } from "../../../../../../../components/interactions/Checkboxes/Checkboxes";
import { T } from "../../../../../../../components/translation/T";
import { PricingView, PricingModel } from "../../../../../../../model/pricing/pricingTypes";
interface Props {
  view: PricingView;
  setView: (view: PricingView) => void;
  pricingModel: PricingModel;
}

export const TransactionFeesHeader: FC<Props> = ({ view, setView, pricingModel }) => {
  return (
    <div className={styles.heading}>
      <div className={styles.header}>
        <h5 style={{ margin: 0 }}>
          <T>Transaction fees</T>
        </h5>

        {pricingModel !== PricingModel.PACK && (
          <Checkboxes
            className="compact"
            values={[view]}
            onChange={() => {
              const newView = view === PricingView.INDIVIDUAL ? PricingView.GROUP : PricingView.INDIVIDUAL;
              setView(newView);
            }}
            alternatives={[
              {
                value: PricingView.GROUP,
                text: <T>Group view</T>,
              },
            ]}
          />
        )}
      </div>
      {pricingModel === PricingModel.PACK && (
        <WarningBox className="m-top-20">
          <T>
            If the included monthly transaction volume is exceeded, the merchant will be charged a transaction
            fee for each additional transaction
          </T>
        </WarningBox>
      )}
    </div>
  );
};
