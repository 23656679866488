import { getDecimalSeparator } from "../../../model/pricing/pricingUtils";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class FeeInputValidator extends BaseValidator {
  name = "FeeInputValidator";
  // https://stackoverflow.com/questions/1074660/with-a-browser-how-do-i-know-which-decimal-separator-does-the-operating-system
  private separator: string;

  constructor(error: string, locale?: string) {
    super(error);
    this.separator = getDecimalSeparator(locale);
  }

  validate(value?: string | number): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (typeof value === "undefined" || value === null || value === "") {
        resolve({ status: VALIDATION_STATE.SUCCESS });
        return;
      }

      let number;
      if (typeof value === "string") {
        const modifiedValue = value.replace(this.separator, ".");
        number = Number(modifiedValue);
      } else {
        number = value;
      }

      if (isNaN(number)) {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      } else {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      }
    });
  }
}
