import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { AnimateHeight } from "../../components/animate/AnimateHeight";
import { SuccessBox } from "../../components/boxes/SuccessBox";
import { FieldSet } from "../../components/fieldSet/FieldSet";
import { FieldSetTonedBox } from "../../components/fieldSet/FieldSetTonedBox";
import { Form } from "../../components/form/Form";
import { TextInput } from "../../components/form/TextInput";
import { MaxLengthValidator } from "../../components/form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../../components/form/validators/MinLengthValidator";
import { NumberValidator } from "../../components/form/validators/NumberValidator";
import { RequiredValidator } from "../../components/form/validators/RequiredValidator";
import { Plus } from "../../components/icons/Plus";
import { Trash } from "../../components/icons/Trash";
import { Logo } from "../../components/images/Logo";
import { Button } from "../../components/interactions/Buttons/Button";
import { Link } from "../../components/links/Link";
import { FormattedDate } from "../../components/time/FormattedDate";
import { dataPassUsers, Location } from "../../data/dataPassUsers";
import { ContractId, UTCDate } from "../../model/common/commonType";
import { Status } from "../../data/types";
import "./BackofficeCompletedPage.scss";
import { AnimateHeightMotion } from "../../components/animate/AnimateHeightMotion";
import { API } from "../../network/API";

export const BACKOFFICE_COMPLETE_PAGE = "/backoffice/completed/:id";
const enabled = false;

function fuglyPrefixStrip(name: string) {
  return name.replace("Merchant ", "");
}

export const BackofficeCompletedPage = () => {
  const { id } = useParams<{ id: ContractId }>();
  const [merchantIds, setMerchantIds] = useState<string[]>([""]);
  const [merchantName, setMerchantName] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [buttonStatus, setButtonStatus] = useState(Status.DISABLED);
  const [cashless, setCashless] = useState(false);

  const [locations, setLocations] = useState<Location[]>([]);
  const [completed, setCompleted] = useState<UTCDate>();

  const onSave = () => {
    setButtonStatus(Status.PENDING);

    const stores = locations.map((location) => ({
      id: location.id,
      merchantId: location.value || "",
    }));

    setTimeout(() => {
      setDisabled(true);
      setButtonStatus(Status.SUCCESS);
    }, 3000);
    dataPassUsers
      .postMerchant(id, merchantIds[0], stores)
      .then(() => {
        setDisabled(true);
        setButtonStatus(Status.SUCCESS);
      })
      .catch((err) => {
        setButtonStatus(Status.ERROR);
        setTimeout(() => setButtonStatus(Status.DEFAULT), 3000);
      });
  };

  useEffect(() => {
    dataPassUsers
      .getMerchant(id)
      .then((merchantData) => {
        setMerchantName("Merchant " + merchantData.companyName);
        setCashless(false);
        if (merchantData.completed) {
          setCompleted(merchantData.completed);
          return;
        }

        if (merchantData.provisioningCompleted) {
          setMerchantIds([merchantData.merchantId]);
          setLocations(
            merchantData.locations.map((location) => ({
              ...location,
              value: location.merchantId,
            }))
          );
          setDisabled(true);
        } else {
          setLocations(
            merchantData.locations.map((location) => ({
              ...location,
              value: "",
            }))
          );
          setDisabled(false);
        }

        setButtonStatus(Status.DEFAULT);
      })
      .catch((err) => {
        if (err.status === 404) {
          setMerchantName("Contract not found");
          setButtonStatus(Status.DISABLED);
          setDisabled(true);
        } else {
          console.error("Error loading contract", err);
          setButtonStatus(Status.ERROR);
          setDisabled(true);
          setMerchantName("Error loading contract, reload page to try again");
        }
      });
  }, [setMerchantName, id]);

  if (completed) {
    return (
      <div className="backoffice-completed">
        <Logo />
        <div className="m-top-40">
          <FieldSet header={merchantName}>
            <span>
              Onboarding completed on <FormattedDate date={completed} />
            </span>
          </FieldSet>
        </div>
      </div>
    );
  }

  const allowMultipleMerchandisers = cashless && enabled;

  return (
    <div className="backoffice-completed">
      <Logo />
      <div className="m-top-40">
        <Form
          onSubmit={(event, form) => {
            if (!form.isValid) {
              return;
            }

            onSave();
          }}
        >
          <FieldSet header={merchantName}>
            <div className="">
              <div className="m-top-20">
                <FieldSetTonedBox>
                  <Link
                    external
                    download={fuglyPrefixStrip(merchantName)}
                    link={API.getUrl(`/api/backoffice/contract/${id}/zip`)}
                  >
                    <b>Download documents </b>
                  </Link>
                </FieldSetTonedBox>
              </div>

              <AnimateHeight name={merchantIds.length + "height"}>
                <div
                  className={cx("merchant-wrapper", {
                    "has-cashless": allowMultipleMerchandisers,
                  })}
                >
                  {merchantIds.map((merchantId, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <TextInput
                          key={idx}
                          name="merchantId"
                          label="Merchant Id / VP Number"
                          onChange={(val) => {
                            setMerchantIds((values) => {
                              const copy = [...values];
                              copy[idx] = val ?? "";
                              return copy;
                            });
                          }}
                          disabled={disabled}
                          value={merchantIds[idx] ?? ""}
                          hint="ID for company"
                          validators={[
                            new RequiredValidator("This field is required"),
                            new NumberValidator("This field must be a number"),
                            new MinLengthValidator(9, "Minimum length is 9"),
                            new MaxLengthValidator(10, "Max length is 10"),
                          ]}
                        />

                        {allowMultipleMerchandisers && idx !== 0 && (
                          <Button
                            className="danger-button"
                            status={buttonStatus !== Status.DEFAULT ? Status.DISABLED : Status.DEFAULT}
                            onClick={() =>
                              setMerchantIds((values) => {
                                return values.filter((val, arrayIndex) => arrayIndex !== idx);
                              })
                            }
                          >
                            <Trash />
                            &nbsp;Remove
                          </Button>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </AnimateHeight>
              {allowMultipleMerchandisers && (
                <Button
                  className="add-merch-id"
                  status={buttonStatus !== Status.DEFAULT ? Status.DISABLED : Status.DEFAULT}
                  onClick={() => setMerchantIds((values) => [...values, ""])}
                >
                  <Plus />
                  &nbsp;Add merchant ID
                </Button>
              )}
              <hr />
              <strong>Locations</strong>
              {locations.map((location) => {
                return (
                  <div className="m-top-30" key={location.id}>
                    <TextInput
                      name={`merchantId-${location.id}`}
                      label={`Merchant Id / VP Number - ${location.name}`}
                      onChange={(val) => {
                        setLocations((locations) =>
                          locations.map((store) => {
                            if (store.id === location.id) {
                              return {
                                ...store,
                                value: val,
                              };
                            }
                            return store;
                          })
                        );
                      }}
                      disabled={disabled}
                      value={location.value}
                      hint={`Location address: ${location.street}, ${location.city}`}
                      validators={[
                        new RequiredValidator("This field is required"),
                        new NumberValidator("This field must be a number"),
                        new MinLengthValidator(9, "Minimum length is 9"),
                        new MaxLengthValidator(10, "Max length is 10"),
                      ]}
                    />
                  </div>
                );
              })}
            </div>
          </FieldSet>

          <AnimateHeightMotion presence>
            {buttonStatus === Status.SUCCESS && (
              <SuccessBox relative>
                <strong>Onboarding completed!</strong>
                <div>Documents were automatically sent for archiving.</div>
              </SuccessBox>
            )}
          </AnimateHeightMotion>

          <div className="m-top-20">
            <Button block status={buttonStatus} type="submit">
              Save and Archive
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
