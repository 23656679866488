import React, { useMemo } from "react";
import { ITEM_HEIGHT, ITEM_HEIGHT_ASSOCIATE } from "./Timeline";
import cx from "classnames";
import { Associate } from "../../../model/associate/associateTypes";
import { ContractDocument } from "../../../model/contract/contractType";
import { getOwnerOffset } from "./ToSignature";

interface Props {
  associates: Associate[];
  documents: ContractDocument[];
  active: boolean;
}

export function getAssociatesEndOffset(associates: Associate[], start: number) {
  return start + ITEM_HEIGHT + associates.length * ITEM_HEIGHT_ASSOCIATE;
}

export function getConfirmedOffset(associates: Associate[], documents: ContractDocument[]) {
  return getAssociatesEndOffset(associates, getOwnerOffset(documents));
}

export const ToAssociates: React.FunctionComponent<Props> = ({ associates, documents, active }) => {
  const allConfirmed = useMemo(() => {
    return associates.every((associate) => associate.signedOff);
  }, [associates]);

  const length = associates.length;
  const start = getOwnerOffset(documents);
  const end = getAssociatesEndOffset(associates, start);

  if (!length) {
    return null;
  }

  const items = [];
  for (let i = 0; i < length; i++) {
    const itemEnd = start + ITEM_HEIGHT + i * ITEM_HEIGHT_ASSOCIATE;
    items.push(
      <React.Fragment key={`to-associates-${associates[i].associateId}`}>
        <line x1="10" x2="50" y1={start} y2={itemEnd} className={cx({ active })} />

        <line x1="50" x2="10" y1={itemEnd} y2={end} className={cx({ active: allConfirmed })} />

        <circle r="4" cx="50" cy={itemEnd} className={cx({ active })} />
      </React.Fragment>
    );
  }

  return <>{items}</>;
};
