import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdAddCircleOutline } from "react-icons/md";
import { Reminder } from "./Reminder";
import { getRelativeDate } from "../../../../../components/utils";
import {
  CommunicationEvent,
  dataConfirm,
  TracingInformation,
  TracingLog,
} from "../../../../../data/dataConfirm";
import { Associate } from "../../../../../model/associate/associateTypes";
import { ContractId } from "../../../../../model/common/commonType";
import "./AssociateTraceView.scss";
import ContentBox from "../../../../../components/ContentBox/ContentBox";
import { EyeIcon } from "../../../../../components/icons/EyeIcon";
import { MdOutlineMessage } from "react-icons/md";
import { MdMailOutline } from "react-icons/md";
import { MdRemoveCircleOutline } from "react-icons/md";
import { MdOutlineCheckCircleOutline } from "react-icons/md";
import cx from "classnames";
import { motion } from "framer-motion";
import { FiBell } from "react-icons/fi";

export interface Props {
  contractId: ContractId;
  associate: Associate;
  setAssociate: (associate: Associate) => void;
}

export const AssociateTraceView: FC<Props> = ({ contractId, associate, setAssociate }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [tracingInformation, setTracingInformation] = useState<TracingInformation>();

  const loadTracingInformation = useCallback(async () => {
    dataConfirm
      .getTrackingInformation(contractId, associate.associateId, true)
      .then((ti) => setTracingInformation(ti))
      .catch((err) => console.log(err));
  }, [contractId, associate.associateId, setTracingInformation]);

  useEffect(() => {
    loadTracingInformation();
  }, [loadTracingInformation]);

  return (
    <ContentBox>
      <div className="associate-review associate-trace-view">
        <Reminder
          associate={associate}
          setAssociate={setAssociate}
          contractId={contractId}
          loadTracing={loadTracingInformation}
        />

        <div className="m-top-40">
          <div className="fw-600 m-bottom-10">{t("Communication log")}</div>
          <ul className="communication-list">
            {tracingInformation?.communicationLog.map((log) => (
              <TraceLog log={log} language={lang} key={log.id} />
            ))}
          </ul>
        </div>
      </div>
    </ContentBox>
  );
};

interface TraceLogProps {
  log: TracingLog;
  language: string;
}

const TraceLog: FC<TraceLogProps> = ({ language, log }) => {
  return (
    <motion.li
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      layout
      key={log.id}
      className="communication-list-item fw-500"
    >
      <span
        className={cx("log-icon", {
          "color-red-heavy": log.event === CommunicationEvent.REVOKED,
          "color-green-heavy": log.event === CommunicationEvent.CONTRACT_VIEWED,
          "color-wl-blue": log.event === CommunicationEvent.SENT,
          "color-warning-heavy":
            log.event === CommunicationEvent.REMINDER || log.event === CommunicationEvent.CREATED,
        })}
      >
        {getLogIcon(log)}
      </span>
      <div>
        <span
          className={cx("log-text", {
            "color-red-heavy": log.event === CommunicationEvent.REVOKED,
            "color-green-heavy": log.event === CommunicationEvent.CONTRACT_VIEWED,
            "color-wl-blue": log.event === CommunicationEvent.SENT,
            "color-warning-heavy":
              log.event === CommunicationEvent.REMINDER || log.event === CommunicationEvent.CREATED,
          })}
        >
          {getLogText(log)}
        </span>
        <span className="text-passive">{getRelativeDate(language, log.created)}</span>
      </div>

      <span className="log-recipient truncated">
        <div>{log.recipient}</div>
      </span>
    </motion.li>
  );
};

function getLogIcon(log: TracingLog) {
  switch (log.event) {
    case CommunicationEvent.SENT:
      return log.recipient.indexOf("@") > -1 ? <MdMailOutline title="Sent" /> : <MdOutlineMessage />;
    case CommunicationEvent.CONTRACT_VIEWED:
      return <EyeIcon />;
    case CommunicationEvent.REMINDER:
      return <FiBell title="reminder" />;
    case CommunicationEvent.REVOKED:
      return <MdRemoveCircleOutline title="Revoked" />;
    case CommunicationEvent.RE_ACTIVATED:
      return <MdOutlineCheckCircleOutline title="re-activated" />;
    case CommunicationEvent.CREATED:
      return <MdAddCircleOutline title="created" />;
  }

  return log.event;
}

function getLogText(log: TracingLog) {
  switch (log.event) {
    case CommunicationEvent.SENT:
      return log.recipient.indexOf("@") > -1 ? "Email link sent" : "SMS link sent";
    case CommunicationEvent.CONTRACT_VIEWED:
      return log.recipient.indexOf("@") > -1 ? "Merchant viewed email link" : "Merchant viewed SMS link";
    case CommunicationEvent.REMINDER:
      return log.recipient.indexOf("@") > -1 ? "Email reminder" : "SMS reminder";
    case CommunicationEvent.REVOKED:
      return log.recipient.indexOf("@") > -1 ? "Email link revoked" : "SMS link revoked";
    case CommunicationEvent.RE_ACTIVATED:
      return log.recipient.indexOf("@") > -1 ? "Email link re-activated" : "SMS link re-activated";
    case CommunicationEvent.CREATED:
      return log.recipient.indexOf("@") > -1 ? "New email link created" : "New SMS link created";
  }

  return log.event;
}

// function extraLogInfo(log: TracingLog) {
//   if (log.merchantIpLookup) {
//     const ip = log.merchantIpLookup.ip;
//     const city = log.merchantIpLookup.city;
//     const country = log.merchantIpLookup.country;

//     return (
//       <>
//         <div className="trace-log-location">
//           {city}, {country}
//         </div>
//         <div className="trace-log-ip">{ip}</div>
//       </>
//     );
//   }

//   return <div className="trace-log-location">{log.salesUserName}</div>;
// }
