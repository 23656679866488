import React from "react";
import cx from "classnames";
import { Image } from "../../../../components/images/Image";
import "./ProviderLogo.scss";

interface Props {
  className?: string;
}

export const Mastercard: React.FunctionComponent<Props> = ({ className }) => (
  <div className={cx("provider-logo", "logo-mastercard", className)}>
    <Image
      src="/images/cards/mastercard_logo_1000x618.svg"
      ratio={1000 / 618}
      alt="Mastercard logo"
      className="mastercard-logo"
    />
  </div>
);
