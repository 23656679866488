import { FC, useEffect, useState } from "react";
import ContentBox from "../../../../../../../components/ContentBox/ContentBox";
import {
  TerminalConfigurationOptions,
  dataTerminalPricing,
} from "../../../../../../../data/dataTerminalPricing";
import { Country } from "../../../../../../../model/common/commonType";
import {
  getTerminalImageData,
  getTerminalTypeString,
  sortTerminalsByType,
} from "../../../../../../../model/terminal/terminalUtils";
import styles from "./DisableTerminals.module.scss";
import { PricingUpdateType } from "../PricingInputs";
import { TerminalType } from "../../../../../../../model/contract/contractType";
import cx from "classnames";
import { NewImage } from "../../../../../../../components/images/NewImage";
import { Checkbox } from "../../../../../../../components/icons/Checkbox";

interface Props {
  country: Country;
  updatePricing: (updates: PricingUpdateType) => void;
  disabledTerminalTypes: TerminalType[];
  disabled?: boolean;
}

const DisableTerminals: FC<Props> = ({ country, updatePricing, disabledTerminalTypes, disabled = false }) => {
  const [countryTerminalPrices, setCountryTerminalPrices] = useState<TerminalConfigurationOptions[]>([]);

  useEffect(() => {
    const { getTerminalConfigurationOptions: getCountryTerminalPrices } = dataTerminalPricing;
    getCountryTerminalPrices(country).then((countryPriceDetails) => {
      const sortedPrices = sortTerminalsByType(countryPriceDetails);
      setCountryTerminalPrices(sortedPrices);
    });
  });

  const onSelectTerminal = (isSelected: boolean, terminalType: TerminalType) => {
    if (isSelected) {
      const newTerminals = disabledTerminalTypes.filter((type) => type !== terminalType);
      updatePricing({ disabledTerminals: newTerminals });
    } else {
      const newTerminals = [...disabledTerminalTypes, terminalType];
      updatePricing({ disabledTerminals: newTerminals });
    }
  };

  return (
    <ContentBox>
      <div className="m-bottom-20">
        <h5>Disable terminals</h5>
        <i className="fw-500 text-passive">
          The selected terminals will be disabled and not available on this particular price plan
        </i>
      </div>
      <div className="fw-600 m-bottom-10">Select terminal types to disable</div>

      <div className={styles.terminals}>
        {countryTerminalPrices.map(({ terminalType }) => {
          const isSelected = disabledTerminalTypes.includes(terminalType);
          return (
            <TerminalCard
              disabled={disabled}
              key={terminalType}
              type={terminalType}
              isSelected={isSelected}
              onClick={(type) => onSelectTerminal(isSelected, type)}
            />
          );
        })}
      </div>
    </ContentBox>
  );
};

export default DisableTerminals;

interface TerminalCardProps {
  isSelected: boolean;
  type: TerminalType;
  onClick: (terminalType: TerminalType) => void;
  disabled: boolean;
}

export const TerminalCard: FC<TerminalCardProps> = ({ isSelected, type, onClick, disabled }) => {
  return (
    <div
      className={cx([styles.terminal], {
        [styles.isSelected]: isSelected,
      })}
    >
      <label htmlFor={type}>
        <div className={styles.body}>
          <div className={styles.radio}>
            <Checkbox checked={isSelected} />
            <input type="radio" id={type} name={type} onClick={disabled ? () => null : () => onClick(type)} />
          </div>
          {isSelected && <span className={styles.disabled}>Disabled</span>}

          <div className={styles.image}>
            <NewImage {...getTerminalImageData(type)} notSelected={!isSelected} size="small" />
            <div className={styles.title}>{getTerminalTypeString(type)}</div>
          </div>
        </div>
      </label>
    </div>
  );
};
