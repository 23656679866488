import { deepCopy } from "../../components/utils";
import { BlikLogo } from "../../pages/Contract/pricingV2/logos/Blik";
import { Diners } from "../../pages/Contract/pricingV2/logos/Diners";
import { JCB } from "../../pages/Contract/pricingV2/logos/JCB";
import { Maestro } from "../../pages/Contract/pricingV2/logos/Maestro";
import { Mastercard } from "../../pages/Contract/pricingV2/logos/Mastercard";
import { Union } from "../../pages/Contract/pricingV2/logos/Union";
import { Visa } from "../../pages/Contract/pricingV2/logos/Visa";
import { VPay } from "../../pages/Contract/pricingV2/logos/VPay";
import { ContractId, Country } from "../common/commonType";
import { ContractType } from "../contract/contractType";
import {
  PricingTemplate,
  PricingStructure,
  TransactionFee,
  PricingModel,
  OptionalTransactionFee,
  FeeType,
  PricingView,
  EditablePricingTemplate,
  regularBrandsArray,
  premiumBrandsArray,
  polandSpecificBrandsArray,
  CardBrand,
  TariffClassProduct,
  brandsArray,
  POLAND_SPECIFIC_TARIFF_CLASS_PRODUCTS,
  PricingStep,
  SteppedBasedPricing,
  TARIFF_CLASS_PRODUCTS_ALL,
  TARIFF_CLASS_PRODUCTS_BASE,
  TARIFF_CLASS_PRODUCTS_TAP_ON_MOBILE_BASE,
  TariffClassMapping,
  DCC,
} from "./pricingTypes";

// <--------- UTILS & HELPER FUNCTIONS --------->

export const CASHLESS_PROMOTION_COUNTRIES = [Country.POLAND, Country.CZECHIA];
export const INSTALLATION_FEE_COUNTRIES = [Country.POLAND];
export const CASHBACK_COUNTRIES = [Country.POLAND];
export const ACQUIRING_COUNTRIES = [Country.POLAND];

export const PRE_AUTHORIZATION_FEES = {
  preAuthorizationFeeIntegrity: 100,
  preAuthorizationFee: 100,
  preAuthorizationFeeFinal: 100,
} as const;

export const CASHLESS_PROMOTION_MONTHS: Record<Country, number> = {
  [Country.POLAND]: 12,
  [Country.CZECHIA]: 12,
  [Country.CROATIA]: 0,
};

export const NON_EEA_MIN_FEE: Record<Country, number> = {
  [Country.POLAND]: 1,
  [Country.CZECHIA]: 1,
  [Country.CROATIA]: 1,
};

export const COMMERCIAL_CARD_MIN_FEE: Record<Country, number> = {
  [Country.POLAND]: 1.2,
  [Country.CZECHIA]: 1.2,
  [Country.CROATIA]: 1.2,
};

export const CHARGEBACK_FEE_PLACEHOLDER: Record<Country, number> = {
  [Country.POLAND]: 120,
  [Country.CZECHIA]: 120,
  [Country.CROATIA]: 10,
};

export const INITIAL_DCC_CLASS: DCC = {
  dcc: 1,
  tariffClass: 1,
};

export const INITIAL_TARIFF_CLASS_MAPPING: TariffClassMapping[] = TARIFF_CLASS_PRODUCTS_ALL.map(
  (product) => ({ product, tariffClass: 0 })
);

export const DEFAULT_OPTIONAL_FEES: OptionalTransactionFee[] = brandsArray.map((brandEnum) => ({
  brand: brandEnum,
}));

const REGULAR_FEES: TransactionFee[] = regularBrandsArray.map((brandEnum) => ({
  brand: brandEnum,
  [FeeType.TRANSACTION]: 0.25,
  [FeeType.MIN]: 0,
  [FeeType.FIXED]: 0,
  [FeeType.MAX]: 0,
  tariffClass: 0,
}));

const PREMIUM_FEES: TransactionFee[] = premiumBrandsArray.map((brandEnum) => ({
  brand: brandEnum,
  [FeeType.TRANSACTION]: 0.5,
  [FeeType.MIN]: 0,
  [FeeType.FIXED]: 0,
  [FeeType.MAX]: 0,
  tariffClass: 0,
}));

const POLAND_SPECIFIC_FEES: TransactionFee[] = polandSpecificBrandsArray.map((brandEnum) => ({
  brand: brandEnum,
  [FeeType.TRANSACTION]: 0.25,
  [FeeType.MIN]: 0,
  [FeeType.FIXED]: 0,
  [FeeType.MAX]: 0,
  tariffClass: 0,
}));

export const POLAND_SPECIFIC_TRANSACTION_FEES = [...POLAND_SPECIFIC_FEES];

export const DEFAULT_TRANSACTION_FEES: TransactionFee[] = [...REGULAR_FEES, ...PREMIUM_FEES];

export const INITIAL_PRICING_STEPS: PricingStep[] = [
  {
    fromThreshold: 0,
    toThreshold: 100000,
    transactionFee: 0.69,
  },
  {
    fromThreshold: 100000,
    toThreshold: 250000,
    transactionFee: 0.59,
  },
];

export const INITIAL_STEPPED_BASED_PRICING: SteppedBasedPricing = {
  cashlessPromotionPricing: undefined,
  regularPricing: INITIAL_PRICING_STEPS,
};

export function getDecimalSeparator(locale?: string) {
  return (
    Intl.NumberFormat(locale)
      .formatToParts(1.1)
      .find((part) => part.type === "decimal")?.value ?? "."
  );
}

function convertToFee(optionalFee: OptionalTransactionFee): TransactionFee {
  const separator = getDecimalSeparator();
  const min = optionalFee[FeeType.MIN]?.replace(separator, ".") ?? "0";
  const transaction = optionalFee[FeeType.TRANSACTION]?.replace(separator, ".") ?? "0";
  const fixed = optionalFee[FeeType.FIXED]?.replace(separator, ".") ?? "0";

  const item: TransactionFee = {
    brand: optionalFee.brand,
    [FeeType.MIN]: isNaN(Number(min)) ? 0 : Number(min),
    [FeeType.TRANSACTION]: isNaN(Number(transaction)) ? 0 : Number(transaction),
    [FeeType.FIXED]: isNaN(Number(fixed)) ? 0 : Number(fixed),
    tariffClass: optionalFee.tariffClass ?? 0,
  };

  if (optionalFee[FeeType.MAX]) {
    const max = optionalFee[FeeType.MAX]?.replace(separator, ".") ?? "0";
    item[FeeType.MAX] = isNaN(Number(max)) ? 0 : Number(max);
  }

  return item;
}

export function convertToOptionalFee(transactionFee: TransactionFee): OptionalTransactionFee {
  const item: OptionalTransactionFee = {
    brand: transactionFee.brand,
    tariffClass: transactionFee.tariffClass,
    [FeeType.TRANSACTION]: isNaN(transactionFee[FeeType.TRANSACTION])
      ? undefined
      : transactionFee[FeeType.TRANSACTION].toString(10),
  };
  if (transactionFee[FeeType.MIN]) {
    item[FeeType.MIN] = isNaN(transactionFee[FeeType.MIN] || 0)
      ? undefined
      : transactionFee[FeeType.MIN].toString(10);
  }
  if (transactionFee[FeeType.MAX]) {
    item[FeeType.MAX] = isNaN(transactionFee[FeeType.MAX] || 0)
      ? undefined
      : transactionFee[FeeType.MAX]?.toString(10);
  }
  if (transactionFee[FeeType.FIXED]) {
    item[FeeType.FIXED] = isNaN(transactionFee[FeeType.FIXED] || 0)
      ? undefined
      : transactionFee[FeeType.FIXED].toString(10);
  }

  return item;
}

export function getPricingView() {
  // could do something more fancy here in the future perhaps
  // now always default to group view
  return PricingView.GROUP;
}

export const getPricingModelDisplayName = (model: PricingModel) => {
  switch (model) {
    case PricingModel.BLENDED:
      return "Blended";
    case PricingModel.ICPP:
      return "Interchange++";
    case PricingModel.PACK:
      return "Pack";
    default:
      return model;
  }
};

export const isBundle = (model: PricingModel) => {
  return model === PricingModel.BLENDED || model === PricingModel.ICPP;
};

export const isICPP = (model: PricingModel | undefined) => {
  return model === PricingModel.ICPP;
};

export const isBlended = (model: PricingModel | undefined) => {
  return model === PricingModel.BLENDED;
};

export const isPack = (model: PricingModel) => {
  return model === PricingModel.PACK;
};

// <--------- MAIN FUNCTIONS --------->

const getCountrySpecificTransactionFees = (country: Country): TransactionFee[] => {
  // currently only Poland has specific transaction fees (BLIK)
  if (country === Country.POLAND) return [...POLAND_SPECIFIC_TRANSACTION_FEES];
  return [];
};

const getPlaceholderTransactionFees = (country: Country): TransactionFee[] => {
  // fees and brands that are for ALL countries
  const baseTransactionFees = [...DEFAULT_TRANSACTION_FEES];
  const countrySpecificFees = getCountrySpecificTransactionFees(country);

  return [...baseTransactionFees, ...countrySpecificFees];
};

const getCountrySpecificBrands = (country: Country): CardBrand[] => {
  // currently poland is the only country with card specific brands
  if (country === Country.POLAND) return [...polandSpecificBrandsArray];
  return [];
};

export const getCardBrandsByCountry = (country: Country) => {
  const basebrands = [...regularBrandsArray, ...premiumBrandsArray];
  const countrySpecificBrands = getCountrySpecificBrands(country);
  return [...basebrands, ...countrySpecificBrands];
};

export const getPlaceholderPricingTemplate = (country: Country): PricingTemplate => {
  return {
    ...INITIAL_PRICING_TEMPLATE,
    transactionFees: getPlaceholderTransactionFees(country),
  };
};

// convert a template to a pricing structure (pricing for a contract)
export const convertTemplateToPricingStructure = (
  pricingStructureId: number,
  template: PricingTemplate
): PricingStructure => {
  const templateCopy = deepCopy(template);

  const pricingStructure: PricingStructure = {
    ...templateCopy,
    pricingStructureId: pricingStructureId,
    // TODO: perhaps fix cas?
    cas: 0,
    customized: false,
    view: getPricingView(),
    contractId: "" as ContractId,
    customizedBy: "",
    created: new Date(),
    acceptance: true,
    dcc: templateCopy.dcc[0],
    installedByTechnician: false,
  };

  return pricingStructure;
};

const addMissingTransactionFees = (country: Country, fees: TransactionFee[]) => {
  const placeholderFees = getPlaceholderTransactionFees(country);
  return placeholderFees.map((placeholderFee) => {
    const existingFee = fees.find((fee) => fee.brand === placeholderFee.brand);
    return existingFee ? existingFee : placeholderFee;
  });
};

// when you convert a pricing template (non-editable, that is present in DB) to an editable pricing (the type we use for creating or updating existing pricing template)
export const convertPricingTemplateToEditable = (
  template: PricingTemplate,
  country: Country
): EditablePricingTemplate => {
  const { transactionFees } = template;

  // Needed because we probably will add new card brands in the future (which also means new transaction fees), which would require us to add these new brands to existing pricing templates
  const updatedTransactionFees = addMissingTransactionFees(country, transactionFees);

  const editableTemplate: EditablePricingTemplate = {
    ...template,
    transactionFees: updatedTransactionFees.map((item) => convertToOptionalFee(item)) ?? [],
    view: getPricingView(),
  };

  return editableTemplate;
};

const INITIAL_PRICING_TEMPLATE: PricingTemplate = {
  templateId: 0,
  name: "",
  description: "",
  priority: 10,
  pricingModel: PricingModel.BLENDED,
  promotionMonths: 0,
  cashlessPromotion: false,
  dcc: [INITIAL_DCC_CLASS],
  chargebackFee: 120,
  surchargesCommercialCards: 1.2,
  surchargesNonEea: 1,
  preAuthorizationFees: true,
  transactionFees: [...DEFAULT_TRANSACTION_FEES],
  conditionCategoryCode: 0,
  contractCategory: 0,
  contractTemplateName: "",
  tariffClassMappings: INITIAL_TARIFF_CLASS_MAPPING,
  steppedBasedPricing: undefined,
  cashlessPromotionIsPreSelected: false,
  contractType: ContractType.INSTORE,
};

export const convertEditableTemplateToSaveObject = (
  editableTemplate: EditablePricingTemplate
): PricingTemplate => {
  const templateCopy = deepCopy(editableTemplate);
  const { transactionFees, steppedBasedPricing } = templateCopy;

  const saveObject: PricingTemplate = {
    // We are spreading default values for the optional fields. Should theoretically never be needed since all required fields should be validated in the form
    ...INITIAL_PRICING_TEMPLATE,
    ...templateCopy,
    transactionFees: transactionFees.map((item) => convertToFee(item)),
    steppedBasedPricing: steppedBasedPricing,
  };

  return saveObject;
};

const TARIFF_CLASS_BRAND_MAPPING: Record<CardBrand, TariffClassProduct> = {
  [CardBrand.VISA_DEBIT]: TariffClassProduct.VSDB,
  [CardBrand.VISA_CREDIT]: TariffClassProduct.VISA,
  [CardBrand.MASTERCARD_DEBIT]: TariffClassProduct.DMC,
  [CardBrand.MASTERCARD_CREDIT]: TariffClassProduct.ECAMC,

  [CardBrand.VPAY]: TariffClassProduct.VPAY,
  [CardBrand.MAESTRO]: TariffClassProduct.MAES,
  [CardBrand.DINERS]: TariffClassProduct.DINER,
  [CardBrand.UNION]: TariffClassProduct.CUP,
  [CardBrand.JCB]: TariffClassProduct.JCB,
  [CardBrand.BLIK]: TariffClassProduct.BLIK,
};

export const getTariffClassFromBrand = (brand: CardBrand) => {
  const tariff = TARIFF_CLASS_BRAND_MAPPING[brand];
  return tariff ?? "Could not find tariff class for " + brand;
};

type BrandData = {
  logo: React.FunctionComponent;
  label: string;
  type?: string;
  disabled?: boolean;
  brand: CardBrand;
  tariffClass: TariffClassProduct;
};

const visaCreditData: BrandData = {
  brand: CardBrand.VISA_CREDIT,
  logo: Visa,
  type: "Credit",
  label: "Visa",
  tariffClass: getTariffClassFromBrand(CardBrand.VISA_CREDIT),
};

const visaDebitData: BrandData = {
  brand: CardBrand.VISA_DEBIT,
  logo: Visa,
  type: "Debit",
  label: "Visa Debit",
  tariffClass: getTariffClassFromBrand(CardBrand.VISA_DEBIT),
};

const mastercardCreditData: BrandData = {
  brand: CardBrand.MASTERCARD_CREDIT,
  logo: Mastercard,
  type: "Credit",
  label: "Mastercard",
  tariffClass: getTariffClassFromBrand(CardBrand.MASTERCARD_CREDIT),
};

const mastercardDebitData: BrandData = {
  brand: CardBrand.MASTERCARD_DEBIT,
  logo: Mastercard,
  type: "Debit",
  label: "Mastercard Debit",
  tariffClass: getTariffClassFromBrand(CardBrand.MASTERCARD_DEBIT),
};

const unionData: BrandData = {
  brand: CardBrand.UNION,
  logo: Union,
  label: "UnionPay",
  tariffClass: getTariffClassFromBrand(CardBrand.UNION),
};

const dinersData: BrandData = {
  brand: CardBrand.DINERS,
  logo: Diners,
  label: "Diners",
  tariffClass: getTariffClassFromBrand(CardBrand.DINERS),
};

const jcbData: BrandData = {
  brand: CardBrand.JCB,
  logo: JCB,
  label: "eftpos",
  tariffClass: getTariffClassFromBrand(CardBrand.JCB),
};

const vpayData: BrandData = {
  brand: CardBrand.VPAY,
  logo: VPay,
  label: "VPay",
  tariffClass: getTariffClassFromBrand(CardBrand.VPAY),
};

const maestroData: BrandData = {
  brand: CardBrand.MAESTRO,
  logo: Maestro,
  label: "Maestro",
  tariffClass: getTariffClassFromBrand(CardBrand.MAESTRO),
};

const blinkData: BrandData = {
  brand: CardBrand.BLIK,
  logo: BlikLogo,
  label: "BLIK",
  tariffClass: getTariffClassFromBrand(CardBrand.BLIK),
};

const DEFAULT_BRAND_MAP: BrandData[] = [
  visaCreditData,
  visaDebitData,
  mastercardCreditData,
  mastercardDebitData,
  unionData,
  dinersData,
  jcbData,
  vpayData,
  maestroData,
];

const getCountrySpecifcBrandsData = (country: Country): BrandData[] => {
  if (country === Country.POLAND) return [blinkData];
  return [];
};

export const getBrandsData = (country: Country): BrandData[] => {
  const baseBrands = [...DEFAULT_BRAND_MAP];
  const extraBrands = getCountrySpecifcBrandsData(country);
  return [...baseBrands, ...extraBrands];
};

export const BRANDS_DATA_MAP: Record<CardBrand, BrandData> = {
  [CardBrand.VISA_CREDIT]: visaCreditData,
  [CardBrand.VISA_DEBIT]: visaDebitData,
  [CardBrand.MASTERCARD_CREDIT]: mastercardCreditData,
  [CardBrand.MASTERCARD_DEBIT]: mastercardDebitData,
  [CardBrand.UNION]: unionData,
  [CardBrand.DINERS]: dinersData,
  [CardBrand.JCB]: jcbData,
  [CardBrand.VPAY]: vpayData,
  [CardBrand.MAESTRO]: maestroData,
  [CardBrand.BLIK]: blinkData,
};

const getCountrySpecificTariffClasses = (country: Country): TariffClassProduct[] => {
  if (country === Country.POLAND) return [...POLAND_SPECIFIC_TARIFF_CLASS_PRODUCTS];
  return [];
};

export const getTariffClassProductsArray = (country: Country, isToM: boolean): TariffClassProduct[] => {
  const baseProducts = isToM
    ? [...TARIFF_CLASS_PRODUCTS_TAP_ON_MOBILE_BASE]
    : [...TARIFF_CLASS_PRODUCTS_BASE];
  const countryProducts = getCountrySpecificTariffClasses(country);
  return [...baseProducts, ...countryProducts];
};
