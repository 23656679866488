import { FC } from "react";
import { CardBrand } from "../../../../model/pricing/pricingTypes";
import "./FeeBrandLogo.scss";
import cx from "classnames";
import { BRANDS_DATA_MAP } from "../../../../model/pricing/pricingUtils";

interface Props {
  brand: CardBrand;
  greyVariant?: boolean;
}

export const FeeBrandLogo: FC<Props> = ({ brand, greyVariant }) => {
  const { logo: Logo, type, tariffClass } = BRANDS_DATA_MAP[brand];
  const isDebit = type === "Debit";
  const isCredit = type === "Credit";

  return (
    <div className="fee-brand-logo">
      <div className={cx("fee-logo", { "fee-logo-grey": greyVariant })}>{<Logo />}</div>
      <div className="fee-type-wrapper">{<div className="fee-tariff-class">{tariffClass}</div>}</div>
      <div className="fee-type-wrapper">
        {isDebit && <div className="fee-type">Debit</div>}
        {isCredit && <div className="fee-type">Credit</div>}
      </div>
    </div>
  );
};

const brandOrder = [
  CardBrand.VISA_DEBIT,
  CardBrand.VISA_CREDIT,
  CardBrand.MASTERCARD_DEBIT,
  CardBrand.MASTERCARD_CREDIT,
  CardBrand.MAESTRO,
  CardBrand.VPAY,
];

export const sortBrands = (a: CardBrand, b: CardBrand) => {
  const brandA = a;
  const brandB = b;

  const indexA = brandOrder.indexOf(brandA);
  const indexB = brandOrder.indexOf(brandB);

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  if (indexA !== -1) {
    return -1;
  }

  if (indexB !== -1) {
    return 1;
  }

  return brandA.localeCompare(brandB);
};
