import { FC } from "react";
import { Associate } from "../../model/associate/associateTypes";
import styles from "./AssociateRolesList.module.scss";
import cx from "classnames";
import { motion } from "framer-motion";
import { getAssociateRoleDisplayName, sortAssociateRoles } from "../../model/associate/associateUtils";

interface Props {
  associate: Associate;
}

const AssociateRolesList: FC<Props> = ({ associate }) => {
  return (
    <ul className={styles.roles_list}>
      {[...associate.roles].sort(sortAssociateRoles).map((role) => (
        <motion.li
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={cx([styles.role])}
          key={`${associate.associateId}-${role}`}
        >
          {getAssociateRoleDisplayName(role)}
        </motion.li>
      ))}
    </ul>
  );
};

export default AssociateRolesList;
