import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class MaxValidator extends BaseValidator {
  name = "MaxValidator";
  private max: number;

  constructor(max: number, error: string) {
    super(error);
    this.max = max;
  }
  validate(value: number | string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      const num = Number(value);

      if (isNaN(num) || Number(value) <= this.max) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
