import { Done } from "../../../components/icons/Done";
import { Radio } from "../../../components/icons/Radio";
import { PackageId, Terminal, Validity } from "../../../model/contract/contractType";
import { Image } from "../../../components/images/Image";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { HiddenInput } from "../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import "./Terminals.scss";
import { Country } from "../../../model/common/commonType";
import { StatusBoxes } from "../../../components/boxes/StatusBoxes";
import { WarningBox } from "../../../components/boxes/WarningBox";
import { useMemo, useRef } from "react";
import { userState } from "../../../state/userState";
import { useRecoilValue } from "recoil";

interface Props {
  disabled: boolean;
  onChange: (packageId: PackageId) => void;
  selectedPackageId?: PackageId;
  country: Country;
  terminals: Terminal[];
}

interface TerminalOption {
  name: string;
  packageId: PackageId;
  image: React.ReactNode;
}

const TERMINAL_OFFERINGS: Record<string, TerminalOption> = {
  SOFTPOS: {
    name: "WL ToM bundle",
    packageId: PackageId.SOFTPOS_BUNDLE,
    image: (
      <Image
        src="/images/terminals/softpos_239x499.png"
        ratio={239 / 499}
        alt="Worldline Tap on Mobile terminal"
      />
    ),
  },
  PAYPHONE: {
    name: "PayPhone bundle",
    packageId: PackageId.SOFTPOS_BUNDLE,
    image: <Image src="/images/terminals/payphone_237×483.png" ratio={237 / 483} alt="Payphone terminal" />,
  },
  DESK: {
    name: "Desk bundle",
    packageId: PackageId.DESK_BUNDLE,
    image: <Image src="/images/terminals/desk5000_510x510.png" ratio={510 / 510} alt="Desk terminal" />,
  },
  MOVE: {
    name: "Move bundle",
    packageId: PackageId.MOVE_BUNDLE,
    image: <Image src="/images/terminals/move5000_510x510.png" ratio={510 / 510} alt="Move terminal" />,
  },
};

const AVAILABLE_TERMINAL_OFFERINGS: Record<Country, TerminalOption[]> = {
  [Country.POLAND]: [TERMINAL_OFFERINGS.SOFTPOS, TERMINAL_OFFERINGS.DESK, TERMINAL_OFFERINGS.MOVE],
  [Country.CZECHIA]: [TERMINAL_OFFERINGS.PAYPHONE],
  [Country.CROATIA]: [TERMINAL_OFFERINGS.SOFTPOS, TERMINAL_OFFERINGS.DESK, TERMINAL_OFFERINGS.MOVE],
};

export const Terminals: React.VoidFunctionComponent<Props> = ({
  disabled,
  onChange,
  selectedPackageId,
  country,
  terminals,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const user = useRecoilValue(userState);

  const terminalOfferings = AVAILABLE_TERMINAL_OFFERINGS[country];

  const enabledOfferings = useMemo(
    () =>
      terminalOfferings.filter(
        ({ packageId }) =>
          user?.salesUser?.enabledBundles?.includes(packageId) || selectedPackageId === packageId
      ),
    [selectedPackageId, terminalOfferings, user?.salesUser?.enabledBundles]
  );

  return (
    <>
      {enabledOfferings.length > 1 && (
        <StatusBoxes status={terminals.length ? Validity.PARTIAL : Validity.DEFAULT}>
          <WarningBox relative>
            {t("You must remove all terminals before a new package can be selected")}
          </WarningBox>
        </StatusBoxes>
      )}

      <div
        className={cx("offering-terminals", {
          "is-centered": enabledOfferings.length < 3,
        })}
        ref={ref}
      >
        {enabledOfferings.map(({ name, packageId, image }) => {
          const isActive = selectedPackageId === packageId;
          return (
            <div className="package-terminal" key={packageId}>
              <label htmlFor={packageId} className="package">
                <div
                  className={cx("offer", "as-ghost", {
                    "is-active": isActive,
                    "is-disabled": disabled,
                  })}
                >
                  <div className="active-notifier">
                    <div className="active-notifier-bg" />
                    <Done />
                  </div>
                  <Radio checked={isActive} />
                  <input
                    type="radio"
                    id={packageId}
                    name={packageId}
                    checked={isActive}
                    onChange={() => onChange(packageId)}
                    disabled={disabled}
                  />
                  <strong>{t(name)}</strong>

                  <div className="bundle-image">{image}</div>
                </div>
              </label>
            </div>
          );
        })}
      </div>

      <HiddenInput
        label={t("Bundle selection")}
        value={selectedPackageId}
        validators={[new RequiredValidator(t("Please select a package"))]}
        scrollToRef={ref}
      />
    </>
  );
};
