import React from "react";
import { ProgressInterface } from "./FileUpload";
import "./Progress.scss";
import cx from "classnames";

interface Props extends ProgressInterface {
  bgWhite?: boolean;
}

export const Progress: React.FC<Props> = ({ name, progress, bgWhite }) => {
  return (
    <div className="progress fs-small">
      <div className="progress-pending-name">{name}</div>
      <div className="progress-pending-wrapper">
        <div
          className={cx("progress-pending-bar-bg", {
            "progress-pending-bar-bg-white": bgWhite,
          })}
        >
          <div
            className="progress-pending-bar-overlay"
            style={{
              transform: `translate3d(-${100 - progress}%, 0, 0)`,
            }}
          />
        </div>
        {/* <div className="progress-percentage">{100 - progress} %</div> */}
      </div>
    </div>
  );
};
