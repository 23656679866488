import { TFunction } from "i18next";
import { Status } from "../../../../../../../data/types";
import { Store2, ContractType } from "../../../../../../../model/contract/contractType";

export const HTTPS_PREFIX = "https://";

export interface EcomTypesContentProps {
  onChange: <K extends keyof Store2<ContractType.ECOMMERCE>>(
    key: K,
    value: Store2<ContractType.ECOMMERCE>[K]
  ) => void;
  store: Store2<ContractType.ECOMMERCE>;
  t: TFunction;
  edit: boolean;
  status: Status;
}

export const addHttpsPrefix = (url: string) => {
  return `${HTTPS_PREFIX}${url}`;
};

export const removeHttpsPrefix = (url?: string) => {
  if (!url) return url;
  if (url.startsWith(HTTPS_PREFIX)) return url.slice(HTTPS_PREFIX.length);
  else return url;
};
