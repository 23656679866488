import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Retry } from "../../../components/retry/Retry";
import { dataConfirm, MerchantAssociateResponse } from "../../../data/dataConfirm";
import { Associate } from "../../../model/associate/associateTypes";
import { AssociateId, ContractId } from "../../../model/common/commonType";
import { Status } from "../../../data/types";
import { isSignee } from "../../../state/contractAssociateState";
import { AssociateIdCardReview } from "./views/IdCardReview/AssociateIdCardReview";
import { AssociateIdDataInputView } from "./views/IdDataInputView/AssociateIdDataInputView";
import { AssociateSelfieReview } from "./views/SelfieReview/AssociateSelfieReview";
import { AssociateSignedOffView } from "./views/SignedOffView/AssociateSignedOffView";
import { AssociateTraceView } from "./views/TraceView/AssociateTraceView";
import styles from "./AssociateReview.module.scss";
import { Page, PageType } from "../../Page/Page";
import AssociateData from "./AssociateData";
import { motion } from "framer-motion";

export const ASSOCIATE_REVEW_PAGE = "/merchant/:contractId/associate/:associateId";

export enum ReviewState {
  PENDING = "PENDING",
  ID_DOCUMENT = "ID_DOCUMENT",
  SELFIE = "SELFIE",
  DATA_INPUT = "DATA_INPUT",
  COMPLETE = "COMPLETE",
}

interface Props {
  contractId: ContractId;
  associateId: AssociateId;
  setReviewState: (state: ReviewState) => void;
  associate?: Associate;
  setAssociate: (associate: Associate) => void;
  reviewState?: ReviewState;
  merchantAssociate?: MerchantAssociateResponse;
}

const AssociateReviewPageInner: FC<Props> = ({
  contractId,
  associateId,
  reviewState,
  merchantAssociate,
  associate,
  setReviewState,
  setAssociate,
}) => {
  const selfieRef = useRef<HTMLDivElement>(null);
  const idDataInputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (reviewState === ReviewState.DATA_INPUT) {
      idDataInputRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    if (reviewState === ReviewState.SELFIE) {
      selfieRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [selfieRef, idDataInputRef, reviewState]);

  if (!merchantAssociate || !reviewState || !associate) {
    return null;
  }

  if (reviewState === ReviewState.PENDING) {
    return (
      <div className={styles.root}>
        <AssociateData associate={associate} />
        <AssociateTraceView contractId={contractId} associate={associate} setAssociate={setAssociate} />;
      </div>
    );
  }

  if (reviewState === ReviewState.COMPLETE) {
    return (
      <div className={styles.root}>
        <AssociateData associate={associate} />
        <AssociateSignedOffView
          contractId={contractId}
          merchantAssociate={merchantAssociate}
          associate={associate}
        />
      </div>
    );
  }

  const shouldShowIdDataInput = reviewState === ReviewState.DATA_INPUT;
  const shouldShowSelfie = reviewState === ReviewState.SELFIE || shouldShowIdDataInput;

  const selfieReviewIsCompleted = reviewState === ReviewState.DATA_INPUT;
  const idCardReviewIsCompleted = reviewState === ReviewState.SELFIE || selfieReviewIsCompleted;

  const onCompleteIdCard = () => {
    setReviewState(ReviewState.SELFIE);
    selfieRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const onCompleteSelfieReview = () => {
    setReviewState(ReviewState.DATA_INPUT);
    idDataInputRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className={styles.root}>
      <AssociateData associate={associate} />
      <>
        <AssociateIdCardReview
          merchantAssociate={merchantAssociate}
          contractId={contractId}
          associateId={associateId}
          completed={onCompleteIdCard}
          isCompleted={idCardReviewIsCompleted}
        />

        {shouldShowSelfie && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            ref={selfieRef}
            key="review-selfie-ref"
          >
            <AssociateSelfieReview
              merchantAssociate={merchantAssociate}
              contractId={contractId}
              associateId={associateId}
              completed={onCompleteSelfieReview}
              isCompleted={selfieReviewIsCompleted}
            />
          </motion.div>
        )}

        {shouldShowIdDataInput && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} ref={idDataInputRef}>
            <AssociateIdDataInputView
              merchantAssociate={merchantAssociate}
              contractId={contractId}
              associateId={associateId}
            />
          </motion.div>
        )}
      </>
    </div>
  );
};

/* 

Om associate är färdig-reviewad så visar vi information
bara med filer att ladda ner

Review ID-kort. Visa ID-kortet och ställ kontrollfrågor
När man godkänner så sparar vi hur lång tid man tog på sig att kontrollera
TODO ska man fråga om signaturens matchning?

Review Selfie och ID-kortsmatching. Visa Selfie och fråga om bilden är OKEJ.
Visa sen ID-kortet och be säljaren kontrollera så att selfien matchar ID-kortet

Lägg till information om ID-kortet. Visa ID-kortet och signaturen
Visa input-fält. Om vi har stöd för det så visa information som vi kunna extrahera
automatiskt. Säljaren behöver då bara godkänna 

*/

export const AssociateReviewPage = () => {
  const { contractId, associateId } = useParams<{
    contractId: ContractId;
    associateId: AssociateId;
  }>();

  const [reviewState, setReviewState] = useState<ReviewState>();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [associate, setAssociate] = useState<Associate>();
  const [merchantAssociate, setMerchantAssociate] = useState<MerchantAssociateResponse>();

  const load = useCallback(
    (contractId, associateId) => {
      setStatus(Status.PENDING);
      Promise.all([
        dataConfirm.getAssociate(contractId, associateId),
        dataConfirm.loadAssociateRemoteId(contractId, associateId),
      ])
        .then((responses) => {
          const [associate, ma] = responses;
          setMerchantAssociate(ma);
          setAssociate(associate);
          setStatus(Status.SUCCESS);

          const identityDocument = ma.identityDocument;
          const selfie = ma.selfie;

          if (isSignee(associate) && !associate.signatory?.signature) {
            setReviewState(ReviewState.PENDING);
            return;
          }

          if (ma.signedOffBy) {
            setReviewState(ReviewState.COMPLETE);
          } else if (identityDocument.signedOff && selfie.signedOff) {
            setReviewState(ReviewState.DATA_INPUT);
          } else if (identityDocument.uploaded && !identityDocument.signedOff) {
            setReviewState(ReviewState.ID_DOCUMENT);
          } else if (selfie.uploaded && !selfie.signedOff) {
            setReviewState(ReviewState.SELFIE);
          } else {
            setReviewState(ReviewState.PENDING);
          }
        })
        .catch(() => {
          setStatus(Status.ERROR);
        });
    },
    [setMerchantAssociate, setAssociate, setStatus]
  );

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(() => {
      load(contractId, associateId);
    }, 300);
  }, [load, contractId, associateId]);

  useEffect(() => {
    load(contractId, associateId);
  }, [load, contractId, associateId]);

  return (
    <Page title="Review" pageType={PageType.SALES_CONTRACT}>
      <Retry retry={retry} status={status}>
        <AssociateReviewPageInner
          merchantAssociate={merchantAssociate}
          contractId={contractId}
          associateId={associateId}
          reviewState={reviewState}
          associate={associate}
          setAssociate={setAssociate}
          setReviewState={setReviewState}
        />
      </Retry>
    </Page>
  );
};
