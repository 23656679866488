import { VFC, useCallback, useState } from "react";
import { PhoneNumberCountry } from "../../../components/contact/PhoneNumberCountry";
import { FieldSet } from "../../../components/fieldSet/FieldSet";
import { Form, FormContainer } from "../../../components/form/Form";
import { Select } from "../../../components/form/Select";
import { TextInput } from "../../../components/form/TextInput";
import { EmailBackendValidator } from "../../../components/form/validators/EmailBackendValidator";
import { EmailValidator } from "../../../components/form/validators/EmailValidator";
import { MaxLengthValidator } from "../../../components/form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../../../components/form/validators/MinLengthValidator";
import { PhoneCountryCodeValidator } from "../../../components/form/validators/PhoneCountryCodeValidator";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { AddCircleOutline } from "../../../components/icons/AddCircleOutline";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Alternative } from "../../../components/interactions/InputTypes";
import { LoginMethod } from "../../../data/dataAuth";
import { dataPassUsers } from "../../../data/dataPassUsers";
import { TeamId } from "../../../model/common/commonType";
import { Status } from "../../../data/types";
import { SalesUserRequest, userAdministration } from "../../../data/UserAdministration";
import { ServerError } from "../../../network/API";
import "./UserAdministration.scss";
import { NewOverlay } from "../../../components/overlay/NewOverlay";

export interface ModalProps {
  onSave: () => void;
}

const DEFAULT_ADD_USER = () => {
  return {
    email: "",
    phone: "",
    name: "",
    teamId: "" as TeamId,
    loginMethod: LoginMethod.OTP,
    salesId: "",
  } as SalesUserRequest;
};

export const AddUserModal: VFC<ModalProps> = ({ onSave }) => {
  const [open, setOpen] = useState(false);
  const [salesTeams, setSalesTeams] = useState<Alternative<string>[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [unconfirmedSalesIdWarning, setUnconfirmedSalesIdWarning] = useState(false);

  const [newSalesUser, setNewSalesUserRequest] = useState<SalesUserRequest>(DEFAULT_ADD_USER());

  const onOpen = useCallback(() => {
    setNewSalesUserRequest(DEFAULT_ADD_USER());
    userAdministration.loadSalesTeams().then((salesTeams) => {
      const teams = salesTeams.map((s) => {
        return {
          value: s.id,
          text: s.name,
        };
      });
      teams.unshift({
        value: "" as TeamId,
        text: "Choose team",
      });
      setSalesTeams(teams);
      setOpen(true);
    });
  }, []);

  const handleSave = useCallback(
    (_, form: FormContainer) => {
      if (form.isInvalid) {
        return;
      }

      setIsSaving(true);
      userAdministration
        .addNewSalesUser(newSalesUser)
        .then(() => {
          onSave();
          setOpen(false);
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [newSalesUser, onSave]
  );

  const fetchSalesIdDetails = useCallback(() => {
    if (!newSalesUser.salesId) {
      return;
    }

    setUnconfirmedSalesIdWarning(false);
    dataPassUsers
      .getPassUser(newSalesUser.salesId)
      .then((passUser) => {
        setNewSalesUserRequest({
          ...newSalesUser,
          name: (newSalesUser.name || passUser.name) ?? "",
          phone: (newSalesUser.phone || passUser.number) ?? "",
          email: (newSalesUser.email || passUser.email) ?? "",
        });
      })
      .catch((err: ServerError<unknown>) => {
        if (err.status === 404) {
          setUnconfirmedSalesIdWarning(true);
        }
      });
  }, [newSalesUser]);

  return (
    <div className="add-sales-user-modal light-theme">
      <NewOverlay onClose={() => setOpen(false)} open={open}>
        <Form onSubmit={handleSave}>
          <FieldSet header={<span>Sales User</span>} className="m-top-20">
            <div className="tablet-columns">
              <div className="column">
                <TextInput
                  onChange={(value) => {
                    setNewSalesUserRequest({
                      ...newSalesUser,
                      salesId: value,
                    });
                  }}
                  value={newSalesUser.salesId}
                  name="salesId"
                  label="Sales Id"
                  hint={
                    unconfirmedSalesIdWarning
                      ? "Unconfirmed, please ensure this is the correct Sales id"
                      : "This is sent to PASS as user id"
                  }
                  validators={[
                    new RequiredValidator("Sales Id is required"),
                    new MinLengthValidator(6, "Sales Id must be at least 6 characters"),
                  ]}
                  onBlur={fetchSalesIdDetails}
                />
              </div>
            </div>
            <div className="tablet-columns">
              <div className="column">
                <TextInput
                  onChange={(value) => {
                    setNewSalesUserRequest({ ...newSalesUser, name: value });
                  }}
                  value={newSalesUser.name}
                  name="name"
                  label="Name"
                  hint="The name will be presented to the merchant"
                  placeholder="Display name of the sales user"
                  validators={[new RequiredValidator("Name is required")]}
                />
              </div>
              <div className="column">
                <Select
                  alternatives={salesTeams}
                  label="Team"
                  value={newSalesUser.teamId}
                  onChange={(value) => {
                    setNewSalesUserRequest({
                      ...newSalesUser,
                      teamId: value as TeamId,
                    });
                  }}
                  validators={[new RequiredValidator("Team is required")]}
                />
              </div>
            </div>
            <div className="tablet-columns">
              <div className="column">
                <TextInput
                  onChange={(value) => {
                    setNewSalesUserRequest({ ...newSalesUser, email: value });
                  }}
                  value={newSalesUser.email}
                  name="email"
                  label="Email"
                  placeholder="Email of the sales user, will be used as login"
                  hint="The email will be used as login and shown to the merchant when they need to reach out to their sales representative"
                  validators={[
                    new RequiredValidator("Email is required"),
                    new EmailValidator("Email is not valid"),
                    new EmailBackendValidator("Email is not valid"),
                    new MaxLengthValidator(50, "Email must be less than 50 characters"),
                  ]}
                />
              </div>
              <div className="column">
                <TextInput
                  onChange={(value) => {
                    setNewSalesUserRequest({ ...newSalesUser, phone: value });
                  }}
                  value={newSalesUser.phone}
                  name="phone"
                  label="Contact Phone"
                  placeholder="+XXXXXXX"
                  hint="The phone number will be presented to the merchant as a contact number incase they have questions"
                  validators={[
                    new RequiredValidator("Phone is required"),
                    new PhoneCountryCodeValidator("Phone number must start with a country code e.g +46..."),
                    new MinLengthValidator(8, "Mobile phone must be at least 8 characters"),
                    new MaxLengthValidator(14, "Mobile phone must be less than 14 characters"),
                  ]}
                  message={<PhoneNumberCountry phoneNumber={newSalesUser.phone} />}
                />
              </div>
            </div>
          </FieldSet>

          <div className="tablet-columns m-top-20">
            <div>
              <Button type="submit" status={isSaving ? Status.DISABLED : Status.DEFAULT} block>
                Save
              </Button>
            </div>

            <div>
              <Button type="button" ghost block onClick={() => setOpen(false)}>
                Close
              </Button>
            </div>
          </div>
        </Form>
      </NewOverlay>
      <Button onClick={onOpen}>
        New Sales User <AddCircleOutline />
      </Button>
    </div>
  );
};
