import React, { RefObject, useCallback, useImperativeHandle, useRef } from "react";
import { useValidation, ValidationProps } from "./hooks/useValidation";
import { useForm } from "./hooks/useForm";
import { Input, Props as InputProps } from "../interactions/Input/Input";
import { RequiredValidatorName } from "./validators/RequiredValidator";
import { Status } from "../../data/types";
import { useDebounceFn } from "../../hooks/useDebounce";

export interface TextInputProps extends ValidationProps, Omit<InputProps, "status" | "required"> {
  disabled?: boolean;
  scrollToRef?: RefObject<HTMLElement>;
  trimEnd?: boolean;
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      name,
      label = null,
      value = "",
      onChange,
      onBlur,
      hint = null,
      placeholder = "",
      autoFocus,
      type,
      attributes = {},
      list,
      autocomplete,
      message,
      validators = [],
      forceValidation = false,
      disabled = false,
      scrollToRef,
      preIcon = undefined,
      trimEnd = true,
      ...props
    },
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);
    const innerValidators = disabled ? [] : validators;
    const inputId = useRef("text_" + Math.random().toString(36).substring(2, 9));
    const [validity, errorMessages, resetValidation, status, hintMessages] = useValidation<string>(
      value,
      innerValidators,
      forceValidation
    );
    useForm(inputId.current, validity, value, resetValidation, scrollToRef ?? innerRef);

    const debounceTrim = useDebounceFn((value: string, name: string, ev?: InputEvent) => {
      const hasTrailingSpaces = value !== value.trimEnd();
      if (hasTrailingSpaces) {
        onChange(value.trimEnd(), name, ev);
      }
    }, 1500);

    const internalChange = useCallback(
      (value: string, name: string, ev?: InputEvent) => {
        onChange(value, name, ev);

        if (trimEnd) {
          debounceTrim(value, name, ev);
        }
      },
      [onChange, debounceTrim, trimEnd]
    );

    const isRequired = innerValidators.some((validator) => validator.name === RequiredValidatorName);

    const errors = errorMessages.length > 0 ? errorMessages[0] : null;
    const hints = hintMessages.length > 0 ? hintMessages[0] : null;

    return (
      <Input
        id={inputId.current}
        className={className}
        name={name}
        label={label}
        value={value}
        onChange={internalChange}
        onBlur={onBlur}
        hint={hints ?? hint}
        placeholder={placeholder}
        status={disabled ? Status.DISABLED : status}
        autoFocus={autoFocus}
        type={type}
        attributes={attributes}
        list={list}
        autocomplete={autocomplete}
        required={isRequired}
        message={status === Status.ERROR ? errors : message}
        ref={innerRef}
        preIcon={preIcon}
        {...props}
      />
    );
  }
);
