import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { Language, defaultCurrencyFromCountry } from "../i18n";

import { userState } from "../state/userState";

import { Country } from "../model/common/commonType";
import { featureState } from "../state/featureState";
import { Feature } from "../Store";
import { useMemo } from "react";

const useGeneralData = () => {
  const user = useRecoilValue(userState);
  const features = useRecoilValue(featureState);
  const ecomFeatureIsEnabled = useMemo(() => features[Feature.E_COMMERCE], [features]);

  const { i18n } = useTranslation();
  const language = i18n.language as Language;
  const country = user?.country ? user.country : Country.POLAND;
  const currency = defaultCurrencyFromCountry(country);

  return { language, country, currency, ecomFeatureIsEnabled };
};

export default useGeneralData;
