import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ZoomableImage } from "../../../../../components/images/ZoomableImage";
import { getDynamicLink } from "../../../../../components/utils";
import { dataConfirm, MerchantAssociateResponse } from "../../../../../data/dataConfirm";
import { AssociateId, ContractId } from "../../../../../model/common/commonType";
import { MERCHANT_ROUTE } from "../../../MerchantStatusPage";
import styles from "../../AssociateReview.module.scss";
import { AnimatePresence } from "framer-motion";
import ReviewQuestions from "./ReviewQuestions";
import CompareIDandSelfie from "./CompareIDandSelfie";
import ContentBox from "../../../../../components/ContentBox/ContentBox";
import ReviewStatus from "../../ReviewStatus";

export interface Props {
  contractId: ContractId;
  associateId: AssociateId;
  merchantAssociate: MerchantAssociateResponse;
  completed: () => void;
  isCompleted: boolean;
}

export const AssociateSelfieReview: React.FunctionComponent<Props> = ({
  contractId,
  associateId,
  merchantAssociate,
  completed,
  isCompleted,
}) => {
  const history = useHistory();
  const [startedReview] = useState<Date>(new Date());
  const [confirmedQuestions, setConfirmedQuestions] = useState(false);
  const { t } = useTranslation();

  const signoffIdCard = () => {
    const reviewInSeconds = Math.abs((startedReview.getTime() - new Date().getTime()) / 1000);
    dataConfirm.signOffSelfie(contractId, associateId, reviewInSeconds).then(() => {
      completed();
    });
  };

  const reject = () => {
    dataConfirm.rejectSelfie(contractId, associateId).then(() => {
      history.replace(getDynamicLink(MERCHANT_ROUTE, { id: contractId }));
    });
  };

  return (
    <div className={styles.view}>
      <ReviewStatus isComplete={isCompleted} />
      <ContentBox colorStyle={isCompleted ? "completed" : "default"}>
        <div className={styles.columns}>
          <div className={styles.leftColumn}>
            <h4>{t("Selfie")}</h4>
            <div className={styles.selfie}>
              <ZoomableImage image={merchantAssociate.selfie.image} alt="Selfie" />
            </div>
          </div>

          <AnimatePresence>
            {confirmedQuestions ? (
              <CompareIDandSelfie
                merchantAssociate={merchantAssociate}
                signoffIdCard={signoffIdCard}
                reject={reject}
              />
            ) : (
              <ReviewQuestions
                confirm={() => setConfirmedQuestions(true)}
                reject={reject}
                isCompleted={isCompleted}
              />
            )}
          </AnimatePresence>
        </div>
      </ContentBox>
    </div>
  );
};
