import { FC } from "react";
import styles from "./DCCvalueDisplayer.module.scss";
import { CloseIcon } from "../../../../components/icons/CloseIcon";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Status } from "../../../../data/types";

interface Props {
  onClose: () => void;
  disabled: boolean;
}

const DCCvalueDisplayer: FC<Props> = ({ onClose, disabled, children }) => {
  return (
    <Button status={disabled ? Status.DISABLED : Status.DEFAULT} className={styles.root} onClick={onClose}>
      <div className={styles.labelHolder}>{children}</div>
      <CloseIcon />
    </Button>
  );
};

export default DCCvalueDisplayer;
