import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiArrowNarrowDown } from "react-icons/hi";
import { SortDirection } from "../../../model/Page";
import { SortField } from "../../../Store";
import { FilterUtils } from "../../../model/Filter";
import { DASHBOARD_ROUTE } from "../DashboardPage";
import { useHistory, useLocation } from "react-router";
import cx from "classnames";
import useGeneralData from "../../../hooks/useGeneralData";

const ContractTableHeader: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const filter = useMemo(() => FilterUtils.searchToFilter(search), [search]);
  const { sortField, sortDirection } = filter;
  const { ecomFeatureIsEnabled } = useGeneralData();

  const onSortChange = (sortField: SortField) => {
    const newFilter = { ...filter };
    newFilter.sortField = sortField;

    if (!sortDirection) {
      newFilter.sortDirection = SortDirection.DESC;
    } else {
      const newDirection = sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
      newFilter.sortDirection = newDirection;
    }

    const newParams = FilterUtils.getQueryFromFilter(newFilter);
    history.push({ pathname: DASHBOARD_ROUTE, search: newParams });
  };

  return (
    <thead className="contract-header">
      <tr className="contract-cells">
        <th className="contract-cell company-name">
          <div>{t("Legal name")}</div>
        </th>
        <th className="contract-cell status">
          <div>{t("Status")}</div>
        </th>
        <th className="contract-cell sales-user">
          <div>{t("Sales representative")}</div>
        </th>
        {ecomFeatureIsEnabled && (
          <th className="contract-cell contract-type">
            <div>{t("Contract type")}</div>
          </th>
        )}

        <th className="contract-cell created">
          <div>
            <button onClick={() => onSortChange("created")} className="sortfield-button">
              <span className="sortable-cell">
                {t("Started")}

                {sortField === "created" && (
                  <span
                    className={cx("icon-wrapper", {
                      "icon-wrapper-rotated": sortDirection === SortDirection.DESC,
                    })}
                  >
                    <HiArrowNarrowDown />
                  </span>
                )}
              </span>
            </button>
          </div>
        </th>
        <th className="contract-cell updated">
          <div>
            <button onClick={() => onSortChange("lastUpdated")} className="sortfield-button">
              <span className="sortable-cell">
                {t("Updated")}

                {sortField === "lastUpdated" && (
                  <span
                    className={cx("icon-wrapper", {
                      "icon-wrapper-rotated": sortDirection === SortDirection.DESC,
                    })}
                  >
                    <HiArrowNarrowDown />
                  </span>
                )}
              </span>
            </button>
          </div>
        </th>
        {/* <th className="contract-cell contract-menu" /> */}
      </tr>
    </thead>
  );
};

export default ContractTableHeader;
