import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./PricingPackTerminalSelect.module.scss";
import cx from "classnames";
import { HiddenInput } from "../../../../../../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../../../../../../components/form/validators/RequiredValidator";
import { Radio } from "../../../../../../../../components/icons/Radio";
import { NewImage } from "../../../../../../../../components/images/NewImage";
import {
  getTerminalImageData,
  getTerminalTypeString,
} from "../../../../../../../../model/terminal/terminalUtils";
import { Country } from "../../../../../../../../model/common/commonType";
import { TerminalType } from "../../../../../../../../model/contract/contractType";

interface Props {
  terminalType?: TerminalType;
  setTerminalType?: (terminalType: TerminalType) => void;
  disabled?: boolean;
  country: Country;
  onlyDisplaySelected?: boolean;
}

export const PricingPackTerminalSelect: FC<Props> = ({
  terminalType,
  setTerminalType,
  disabled = false,
  onlyDisplaySelected = false,
  country,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  return (
    <div className={styles.packTerminals}>
      <div className={styles.header}>{t("Pre-selected terminal")}</div>

      <div className={onlyDisplaySelected ? styles.singleContainer : styles.container} ref={ref}>
        {onlyDisplaySelected && terminalType ? (
          <TerminalCard isSelected={true} type={terminalType} disabled={true} />
        ) : (
          getAvailablePackTerminals(country).map((type) => {
            const isSelected = terminalType === type;
            return (
              <TerminalCard
                key={type}
                isSelected={isSelected}
                setTerminalType={setTerminalType}
                type={type}
                disabled={disabled}
              />
            );
          })
        )}
      </div>

      <HiddenInput
        label="Terminal type"
        value={terminalType}
        validators={[new RequiredValidator("Please select a terminal type")]}
        scrollToRef={ref}
        boxVariant
      />
    </div>
  );
};

interface TerminalCardProps {
  isSelected: boolean;
  type: TerminalType;
  setTerminalType?: (terminalType: TerminalType) => void;
  disabled: boolean;
}

export const TerminalCard: FC<TerminalCardProps> = ({ isSelected, type, setTerminalType, disabled }) => {
  return (
    <div
      className={cx([styles.terminal], {
        [styles.isSelected]: isSelected,
        [styles.isDisabled]: disabled,
      })}
    >
      <label htmlFor={type}>
        <div
          className={cx([
            styles.body,
            {
              [styles.isDisabled]: disabled,
            },
          ])}
        >
          <div className={styles.radio}>
            {!disabled && <Radio checked={isSelected} />}

            <input
              type="radio"
              id={type}
              name={type}
              checked={isSelected}
              onChange={setTerminalType ? () => setTerminalType(type) : () => null}
              disabled={setTerminalType ? disabled : true}
            />
          </div>
          <div className={styles.image}>
            <NewImage {...getTerminalImageData(type)} notSelected={!isSelected} size="small" />
            <div className={styles.title}>{getTerminalTypeString(type)}</div>
          </div>
        </div>
      </label>
    </div>
  );
};

// TODO: later this should perhaps be fetched from BE and based on country
const PACK_TERMINALS_CROATIA = [TerminalType.DESK_5000, TerminalType.DX_8000, TerminalType.SOFT_POS];
const PACK_TERMINALS_CZECHIA = [TerminalType.SOFT_POS];

const getAvailablePackTerminals = (country: Country): TerminalType[] => {
  switch (country) {
    case Country.CROATIA:
      return PACK_TERMINALS_CROATIA;
    case Country.CZECHIA:
      return PACK_TERMINALS_CZECHIA;
    default:
      return PACK_TERMINALS_CZECHIA;
  }
};
