import { motion } from "framer-motion";
import React, { FC } from "react";
import { BUTTON_TRANSITION_PROPS } from "../interactions/Buttons/Button";
import styles from "./NewPagination.module.scss";

interface Props {
  onPageChange: (page: number) => void;
  isSelected: boolean;
  pageNumber: number;
}

const PaginationButton: FC<Props> = ({ onPageChange, isSelected, pageNumber }) => {
  return (
    <motion.li
      role="button"
      whileTap={{ scale: 0.9 }}
      transition={BUTTON_TRANSITION_PROPS}
      className={styles.page_button}
      onClick={() => onPageChange(pageNumber - 1)}
    >
      {isSelected && (
        <motion.div
          layoutId="selected-page"
          className={styles.selected_page}
          initial={{ scale: 1.3 }}
          animate={{ scale: 1 }}
          transition={{
            duration: 0.5,
          }}
        >
          {pageNumber}
        </motion.div>
      )}

      <span>{pageNumber}</span>
    </motion.li>
  );
};

export default PaginationButton;
