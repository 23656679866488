import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NumberInput } from "../../../../../../../components/form/NumberInput";
import { MinValidator } from "../../../../../../../components/form/validators/MinValidator";
import { RequiredValidator } from "../../../../../../../components/form/validators/RequiredValidator";
import { TerminalType } from "../../../../../../../model/contract/contractType";
import {
  EditablePricingTemplate,
  TariffClassMapping,
  TariffClassProduct,
} from "../../../../../../../model/pricing/pricingTypes";
import styles from "./TariffClassMapping.module.scss";
import { SetEditablePricing } from "../PricingInputs";

import { Country } from "../../../../../../../model/common/commonType";
import ContentBox from "../../../../../../../components/ContentBox/ContentBox";
import { getTariffClassProductsArray } from "../../../../../../../model/pricing/pricingUtils";

type TemplateProps = Pick<EditablePricingTemplate, "tariffClassMappings" | "terminalType">;

interface Props extends TemplateProps {
  setEditablePricing: SetEditablePricing;
  country: Country;
  disabled?: boolean;
}

const TariffClassMappingComp: FC<Props> = ({
  tariffClassMappings,
  terminalType,
  setEditablePricing,
  country,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const isSoftposPack = terminalType === TerminalType.SOFT_POS;
  const tariffProducts = getTariffClassProductsArray(country, isSoftposPack);
  const tariffProductValueMap = getTariffProductValueMap(tariffClassMappings);

  const onChangeTariffValue = (inputProduct: TariffClassProduct, value: number | undefined) => {
    if (!value) return;

    const updatedMapping: TariffClassMapping = { product: inputProduct, tariffClass: value };
    const toUpdateIndex = tariffClassMappings.findIndex(({ product }) => product === inputProduct);
    const newMappings = tariffClassMappings.filter(({ product }) => tariffProducts.includes(product));

    // can be empty if we have added new brands after this mapping was created
    if (toUpdateIndex === -1) newMappings.push(updatedMapping);
    else newMappings[toUpdateIndex] = updatedMapping;

    setEditablePricing((prev) => {
      return { ...prev, tariffClassMappings: newMappings };
    });
  };

  return (
    <ContentBox padding="no">
      <div className={styles.heading}>
        <h5>Tariff class mapping</h5>
        <p className="fs-small fw-500 text-passive" style={{ margin: 0 }}>
          These fields must correspond with the corresponding fields in iPass for the particular pricing,
          their purpose is to map the WLx price plan to iPass.
        </p>
      </div>

      <div className={styles.inputs}>
        {tariffProducts.map((product) => {
          return (
            <NumberInput
              key={product}
              value={tariffProductValueMap[product]}
              label={product}
              onChange={(value) => onChangeTariffValue(product, value)}
              validators={[
                new RequiredValidator(`${product} is required`),
                new MinValidator(1, t("Tariff class must be a positive number")),
              ]}
              disabled={disabled}
            />
          );
        })}
      </div>
    </ContentBox>
  );
};

export default TariffClassMappingComp;

const ALL_PRODUCTS_MAP: Record<TariffClassProduct, number> = {
  [TariffClassProduct.CUP]: 0,
  [TariffClassProduct.JCB]: 0,
  [TariffClassProduct.DINER]: 0,
  [TariffClassProduct.DMC]: 0,
  [TariffClassProduct.ECAMC]: 0,
  [TariffClassProduct.MAES]: 0,
  [TariffClassProduct.VISA]: 0,
  [TariffClassProduct.VPAY]: 0,
  [TariffClassProduct.VSDB]: 0,
  [TariffClassProduct.BLIK]: 0,
  [TariffClassProduct.UNION]: 0,
};

const getTariffProductValueMap = (productList: TariffClassMapping[]) => {
  const map: Record<TariffClassProduct, number> = { ...ALL_PRODUCTS_MAP };
  productList.forEach(({ product, tariffClass }) => {
    map[product] = tariffClass;
  });
  return map;
};
