import { FC, Fragment } from "react";

import { Trans } from "react-i18next";
import styles from "./ImageOverlaySignedOff.module.scss";

import { Button } from "../../../../../components/interactions/Buttons/Button";
import { NewOverlay } from "../../../../../components/overlay/NewOverlay";
import { Link } from "../../../../../components/links/Link";

interface Props {
  showImage: boolean;
  toggleImage: (open: boolean) => void;
  image: string | undefined;
  fileName: string;
  openText: string;
  downloadText: string;
  title: string;
}

export const ImageOverlaySignedOff: FC<Props> = ({
  showImage,
  toggleImage,
  image,
  fileName,
  openText,
  downloadText,
  title,
}) => {
  if (!image) return null;

  return (
    <Fragment>
      <NewOverlay open={showImage} onClose={() => toggleImage(false)}>
        <img src={image} alt="Full display of id card" />
      </NewOverlay>
      <div className={styles.root}>
        <div className="text-passive fw-500">
          <Trans>{title}</Trans>
        </div>
        <div className={styles.buttons}>
          <Button className="small" block onClick={() => toggleImage(true)}>
            <Trans>{openText}</Trans>
          </Button>
          <Link
            link={image}
            download={`${fileName}.jpg`}
            external
            className="as-button button-variant-text block small"
            iconType={"download"}
          >
            <Trans>{downloadText}</Trans>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};
