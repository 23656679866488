import React, { useState, useCallback, useMemo, useRef, ReactNode } from "react";
import cx from "classnames";
import { nanoid } from "nanoid";
import { Contract, ContractDocument } from "../../model/contract/contractType";
import { Status } from "../../data/types";
import { Attach } from "../icons/Attach";
import { Disabled } from "../icons/Disabled";
import { Pending } from "../icons/Pending";
import { Error } from "../icons/Error";
import { dataFiles } from "../../data/dataFiles";
import { Progress } from "./Progress";
import { ContractStatusState } from "../../state/contractStatusState";
import { API } from "../../network/API";
import "./FileUpload.scss";

export interface ProgressInterface {
  progress: number;
  name: string;
}

export const MIME_PDF = "application/pdf";

export interface IdImageInterface {
  url?: string;
  type?: FileType;
}

export enum FileType {
  PDF,
  IMAGE,
}

function getUrl(contract: Contract | ContractStatusState, document: ContractDocument) {
  return API.getUrl(`/api/sales/contracts/${contract.contractId}/document/${document.id}`);
}

interface Props {
  document: ContractDocument;
  onUpload: (document: ContractDocument) => void;
  onError?: (document: ContractDocument) => void;
  contract: Contract | ContractStatusState;
  edit?: Status;
  children?: ReactNode;
}

export const FileUpload: React.FunctionComponent<Props> = ({
  document,
  contract,
  edit = Status.DEFAULT,
  onUpload,
  onError,
  children = null,
}) => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const identifier = useRef<string>(nanoid());
  const fileElemRef = useRef<HTMLInputElement>(null);
  const [progress, setProgress] = useState<ProgressInterface>({
    progress: 0,
    name: "",
  });

  const upload = useCallback(
    (targetFile: File, urlParam: string) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          setProgress({
            progress: (event.loaded / event.total) * 100,
            name: targetFile.name,
          });
        }
      });

      req.onload = function () {
        if (req.status === 200) {
          setProgress({
            progress: 100,
            name: targetFile.name,
          });
          onUpload({
            ...document,
            fileName: targetFile.name,
            fileAvailable: true,
            uploaded: new Date().toISOString(),
          });
          setStatus(Status.DEFAULT);
        } else {
          onError &&
            onError({
              ...document,
              fileAvailable: false,
              uploaded: undefined,
            });
          setStatus(Status.ERROR);
          setTimeout(() => setStatus(Status.DEFAULT), 4000);
        }
      };

      const formData = new FormData();
      formData.append("document", targetFile);
      formData.append("filename", targetFile.name);
      req.open("POST", urlParam);
      req.withCredentials = true;
      req.send(formData);
    },
    [onUpload, onError, document]
  );

  const onAttachFile = useCallback(
    (ev) => {
      const targetFile = ev.target.files[0];
      setProgress({
        progress: 0,
        name: targetFile.name,
      });
      setStatus(Status.PENDING);

      if (!document.fileAvailable) {
        upload(targetFile, getUrl(contract, document));
        return;
      }

      dataFiles
        .deleteFile(contract.contractId, document.id)
        .then(() => {
          upload(targetFile, getUrl(contract, document));
        })
        .catch(() => {
          onError && onError(document);
          setStatus(Status.ERROR);
          setTimeout(() => setStatus(Status.DEFAULT), 4000);
        });
    },
    [contract, document, upload, onError]
  );

  const button = useMemo(() => {
    if (status === Status.DISABLED) {
      return <Disabled />;
    }
    if (status === Status.PENDING) {
      return <Pending />;
    }
    if (status === Status.ERROR) {
      return <Error />;
    }
    return <Attach />;
  }, [status]);

  return (
    <div className={cx("file-upload", status)}>
      <div className="file-upload-button">
        <input
          type="file"
          id={identifier.current}
          name={identifier.current}
          onChange={onAttachFile}
          ref={fileElemRef}
          multiple={false}
          accept="image/*"
          disabled={edit === Status.DISABLED || status !== Status.DEFAULT}
        />
        <label htmlFor={identifier.current}>
          <span className={cx("button", "action", edit === Status.DISABLED ? edit : status)}>{button}</span>
        </label>
        <Progress {...progress} />
      </div>
      <div className="file-upload-body">{children}</div>
    </div>
  );
};
