import { useCallback, ReactNode, useEffect, useRef, FC } from "react";
import cx from "classnames";
import { Button } from "../interactions/Buttons/Button";
import { Status } from "../../data/types";
import { lock, unlock } from "tua-body-scroll-lock";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import { CloseIcon } from "../icons/CloseIcon";
import styles from "./NewOverlay.module.scss";
import { Backdrop } from "../backdrop/Backdrop";
import { createPortal } from "react-dom";

interface WrapperProps {
  open: boolean;
  children: ReactNode;
  onClose: () => void;
  disableClose?: boolean;
  status?: Status;
  className?: string;
  layoutId?: string; // Set a layoutId to apply layout animations on the modal, see framer-motion docs for more info
  width?: number;
  widthSize?: "small" | "medium" | "large";
  noPadding?: boolean;
  scrollLock?: boolean;
  noOverflowScroll?: boolean;
}

export const NewOverlay: FC<WrapperProps> = ({
  open,
  children,
  onClose,
  disableClose = false,
  status,
  className,
  layoutId,
  widthSize = "medium",
  noPadding = false,
  scrollLock = true,
  noOverflowScroll = false,
}) => {
  const popoverPortalEl = document.getElementById("popover-portal");

  return (
    <>
      <>
        {popoverPortalEl &&
          createPortal(
            <>
              <Inner
                open={open}
                onClose={onClose}
                disableClose={disableClose}
                layoutId={layoutId}
                widthSize={widthSize}
                noPadding={noPadding}
                status={status}
                scrollLock={scrollLock}
                noOverflowScroll={noOverflowScroll}
              >
                {children}
              </Inner>
            </>,
            popoverPortalEl
          )}
      </>

      <Backdrop open={open} onClick={onClose} />
    </>
  );
};

type InnerProps = Omit<WrapperProps, "className">;

export const Inner: FC<InnerProps> = ({
  open,
  children,
  onClose,
  disableClose,
  layoutId,
  widthSize,
  noPadding,
  status,
  scrollLock,
  noOverflowScroll,
}) => {
  const overlayRef = useRef<HTMLDialogElement>(null);

  const tryClose = () => {
    if (disableClose) {
      return;
    }
    onClose();
  };

  const handler = useCallback(
    (ev) => {
      if (disableClose) {
        return;
      }

      if (ev.keyCode === ESC) {
        (document.activeElement as HTMLElement).blur();
        onClose();
      }
    },
    [onClose, disableClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", handler, false);
    return () => {
      window.removeEventListener("keydown", handler, false);
    };
  }, [handler]);

  useEffect(() => {
    if (!scrollLock || !open) return;

    const ref = overlayRef.current;
    if (!ref) {
      return;
    }
    lock(ref, { useGlobalLockState: true });
    return () => {
      unlock(ref, { useGlobalLockState: true });
    };
  }, [scrollLock, overlayRef, open]);

  const positionClass = cx([styles.position], {
    [styles.overlaySmall]: widthSize === "small",
    [styles.overlayLarge]: widthSize === "large",
  });

  const overlayClass = cx([styles.overlay], {
    [styles.hasError]: status === Status.ERROR,
  });

  const closeButtonClass = cx([styles.closeButton], {
    [styles.hasError]: status === Status.ERROR,
  });

  const contentClass = cx([styles.content], {
    [styles.noOverflowScroll]: noOverflowScroll,
    [styles.noPadding]: noPadding,
  });

  return (
    <AnimatePresence>
      {open && (
        <div className={positionClass}>
          <motion.dialog
            ref={overlayRef}
            className={overlayClass}
            open
            layoutId={layoutId}
            transition={{ type: "spring", stiffness: 250, damping: 24 }}
            {...(layoutId ? { layoutId } : DEFAULT_TRANSITION_PROPS)}
          >
            {!disableClose && (
              <Button type="button" className={closeButtonClass} onClick={tryClose}>
                <CloseIcon />
              </Button>
            )}
            <div className={contentClass}>{children}</div>
          </motion.dialog>
        </div>
      )}
    </AnimatePresence>
  );
};

const ESC = 27;

const DEFAULT_TRANSITION_PROPS: Partial<MotionProps> = {
  initial: { opacity: 0, scale: 0.95 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.95 },
  transition: { type: "tween", duration: 0.2 },
};
