import { useEffect, useState } from "react";

type Environment = "production" | "development" | "staging";

const useEnvironment = () => {
  const [environment, setEnvironment] = useState<Environment>("production");

  useEffect(() => {
    const isProduction = process.env.REACT_APP_ENV === "production";
    const isStaging = process.env.REACT_APP_ENV === "staging";

    if (isProduction) return setEnvironment("production");
    if (isStaging) return setEnvironment("staging");
    else return setEnvironment("development");
  }, []);

  return {
    environment,
    isProduction: environment === "production",
  };
};

export default useEnvironment;
