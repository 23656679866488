import React, { useState, useCallback, useRef, useMemo } from "react";
import { Form, FormContainer } from "../../../components/form/Form";
import { LinkAnchors } from "../ContractEdit";
import { ContractSaveError, getEditStatus, handleError } from "../ContractPage";
import { clearCas, getLatestValueByKey } from "../../../components/utils";
import { useTranslation } from "react-i18next";
import { contractStatusState } from "../../../state/contractStatusState";
import { bankAccountOwnerSelector, contractAssociateState } from "../../../state/contractAssociateState";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { dataContracts } from "../../../data/dataContracts";
import { Status } from "../../../data/types";
import { ContractEditError } from "../ContractEditError";
import { Associate, AssociateRole } from "../../../model/associate/associateTypes";
import { ONGOING_RESPONSE } from "../../../data/queues/QueueTypes";
import { AssociateId, Cas } from "../../../model/common/commonType";
import { associateQueue, SaveType } from "../../../data/queues/AssociateQueue";
import { Saluation } from "../../../model/contract/contractType";
import { contractErrorState, contractSaveState } from "../../../state/contractSaveState";
import { PrimaryContactOverlay } from "./PrimaryContactOverlay";
import { Button } from "../../../components/interactions/Buttons/Button";
import { FormName } from "../menus/ContractEditMenu";
import { HiddenInput } from "../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { ScrollPositionAnchor } from "../../../components/scrollPosition/ScrollPositionAnchor";
import { SectionFieldSet } from "../../../components/sectionFieldSet/SectionFieldSet";

const DUMMY_ASSOCIATE_ID = "aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee" as AssociateId;

interface BankOwnerValues {
  saluation: Saluation;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export const getInitialData = () => ({
  saluation: Saluation.HE,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
});

function getNewPrimaryContact(associates: Associate[]) {
  return getLatestValueByKey("created", associates);
}

export const PrimaryContact: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [associates, setAssociates] = useRecoilState(contractAssociateState);
  const accountOwner = useRecoilValue<Associate>(bankAccountOwnerSelector);
  const { contractId, edit: canEditContract } = useRecoilValue(contractStatusState);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);

  const [error, setError] = useState<ContractSaveError | null>(null);
  const [values, setValues] = useState<BankOwnerValues>(getInitialData());
  const [activeAssociate, setActiveAssociate] = useState<Associate | null>(null);

  const prevSaved = useRef<string>(JSON.stringify(clearCas(accountOwner)));
  const formRef = useRef<FormContainer>();
  const ref = useRef<HTMLFormElement>(null);

  const hasManager = !!associates.find((item) => item.roles.indexOf(AssociateRole.PRIMARY_CONTACT) > -1);

  const inputStatus = useMemo(() => getEditStatus(canEditContract, Status.DEFAULT), [canEditContract]);

  const onClose = useCallback(() => {
    setError(null);
    setActiveAssociate(null);
  }, []);

  const sortedAssociates = useMemo(() => sortAssociates(associates), [associates]);

  const saveAssociateCallback = useCallback(
    (err, response, wasAdded?) => {
      if (err === ONGOING_RESPONSE) {
        return;
      }

      if (err) {
        handleError(err, setError);
        setDataError((dataErrors) =>
          dataErrors.concat({
            date: new Date(),
          })
        );
        return;
      }

      setAssociates(response);

      if (wasAdded) {
        const newOwner = getNewPrimaryContact(response);
        setActiveAssociate(newOwner);
      }

      setValues(getInitialData());

      setDataSaved((dataSaved) =>
        dataSaved.concat({
          date: new Date(),
        })
      );
      formRef.current?.resetValidation();
    },
    [setDataSaved, setDataError, setAssociates]
  );

  const onSave = useCallback(
    (primaryContact, wasAdded?) => {
      const stringCopy = JSON.stringify(clearCas(primaryContact));
      if (!wasAdded && prevSaved.current === stringCopy) {
        return;
      } else {
        prevSaved.current = stringCopy;
      }

      associateQueue.saveAssociate(
        contractId,
        {
          associateId: DUMMY_ASSOCIATE_ID,
          cas: 0 as Cas,
          roles: [AssociateRole.PRIMARY_CONTACT],
          ...values,
          ...primaryContact,
        },
        SaveType.ASSOCIATE,
        (err, response) => saveAssociateCallback(err, response, wasAdded)
      );
    },
    [contractId, saveAssociateCallback, values]
  );

  const retry = useCallback(() => {
    setError(null);
    setTimeout(() => onSave(accountOwner), 500);
  }, [onSave, accountOwner]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setTimeout(() => {
      dataContracts
        .claimContract(contractId)
        .then(() => onSave(accountOwner))
        .catch(() => onSave(accountOwner)); // We're lazy, execute save again, which will fail and propagate error to Overlay
    }, 500);
  }, [contractId, onSave, accountOwner]);

  const onToggleToPrimaryContact = useCallback(
    (toggled, toggledAssociate, wasAdded?: boolean) => {
      const currentPrimaryContact = associates.find(
        (manager) => manager.roles.indexOf(AssociateRole.PRIMARY_CONTACT) > -1
      );

      if (currentPrimaryContact && currentPrimaryContact.associateId !== toggledAssociate.associateId) {
        // This associate is no longer the primary contact, remove the role
        const updatedRoles = currentPrimaryContact.roles.filter(notPrimaryContactFilter);
        const updatedCurrentPrimaryContact = { ...currentPrimaryContact, roles: updatedRoles };

        return associateQueue.saveAssociate(
          contractId,
          updatedCurrentPrimaryContact,
          SaveType.ASSOCIATE,
          (err, response) => {
            if (err === ONGOING_RESPONSE) return;
            if (err) return saveAssociateCallback(err, null);
            if (!response) return;

            setAssociates(response);

            const updatedRoles = toggledAssociate.roles.concat(AssociateRole.PRIMARY_CONTACT);
            const newPrimaryContact = { ...toggledAssociate, roles: updatedRoles };

            onSave(newPrimaryContact, wasAdded);
          }
        );
      }

      // is not a new primary contact, check if it was toggled or not
      const updatedRoles = toggled
        ? [...toggledAssociate.roles, AssociateRole.PRIMARY_CONTACT]
        : toggledAssociate.roles.filter(notPrimaryContactFilter);

      const updatedPrimaryContact = { ...toggledAssociate, roles: updatedRoles };
      onSave(updatedPrimaryContact, wasAdded);
    },
    [contractId, saveAssociateCallback, onSave, associates, setAssociates]
  );

  const onAddContact = useCallback(() => {
    const newAssociate = {
      associateId: DUMMY_ASSOCIATE_ID,
      cas: 0 as Cas,
      roles: [AssociateRole.PRIMARY_CONTACT],
    } as Associate;
    onToggleToPrimaryContact(true, newAssociate, true);
  }, [onToggleToPrimaryContact]);

  return (
    <div className="primary">
      <ScrollPositionAnchor id={LinkAnchors.PRIMAR_CONTACT.anchor} />
      <ContractEditError
        error={error}
        setError={setError}
        retry={retry}
        onClose={onClose}
        reclaimAndSave={reclaimAndSave}
      />
      <SectionFieldSet headerTitle={t(LinkAnchors.PRIMAR_CONTACT.name)} formName={FormName.PRIMARY_CONTACT}>
        {!!associates.length && <div className="m-bottom-20 fw-600">{t("Select the primary contact")}</div>}
        <div className="m-top-10" />
        {sortedAssociates.map((associate) => (
          <PrimaryContactOverlay
            associate={associate}
            onToggle={onToggleToPrimaryContact}
            status={inputStatus}
            key={associate.associateId}
            initialOpen={associate.associateId === activeAssociate?.associateId}
            scrollToRef={ref}
          />
        ))}

        <Form name={FormName.PRIMARY_CONTACT} ref={ref}>
          <HiddenInput
            label={t("Primary contact")}
            value={hasManager ? true : undefined}
            validators={[new RequiredValidator(t("Please select an primary contact"))]}
            scrollToRef={ref}
          />
        </Form>

        <Button onClick={onAddContact} block status={inputStatus} variant="text" className="m-top-30">
          {t("Add a primary contact")}
        </Button>
      </SectionFieldSet>
    </div>
  );
};

// sort so the most newly created associate is on top
const sortAssociates = (associates: Associate[]) =>
  [...associates].sort((a, b) =>
    a.created && b.created ? new Date(a.created).getTime() - new Date(b.created).getTime() : 0
  );

const notPrimaryContactFilter = (role: AssociateRole) => role !== AssociateRole.PRIMARY_CONTACT;
