import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RadioGroup } from "../../../components/form/RadioGroup";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { PricingStep } from "../../../model/contract/contractType";
import { Country } from "../../../model/common/commonType";

interface Props {
  onChange: (val: PricingStep, name: string) => void;
  pricingStep?: PricingStep;
  country: Country;
  disabled: boolean;
}

const MONTHLY_TRANSACTION_COUNTRIES = [Country.POLAND, Country.CROATIA];

export const MonthlyTransactionVolume: React.VoidFunctionComponent<Props> = ({
  onChange,
  pricingStep,
  country,
  disabled,
}) => {
  const { t } = useTranslation();

  const opts = useMemo(
    () => [
      {
        text: t("Light"),
        value: PricingStep.LIGHT,
      },
      {
        text: t("Standard"),
        value: PricingStep.STANDARD,
      },
      {
        text: t("Advanced"),
        value: PricingStep.ADVANCED,
      },
    ],
    [t]
  );

  if (!MONTHLY_TRANSACTION_COUNTRIES.includes(country)) {
    return null;
  }

  return (
    <div>
      <RadioGroup
        alternatives={opts}
        onChange={onChange}
        label={t("Monthly transaction volume included")}
        value={pricingStep}
        disabled={disabled}
        validators={[new RequiredValidator(t("Transaction volume is required"))]}
        name="pricingStep"
        className="transaction-volume tablet-horizontal"
      />
    </div>
  );
};
