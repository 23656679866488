import { useRef, useState } from "react";

import styles from "./AddressPopover.module.scss";

import { AddressForm } from "./AddressForm";
import { Status } from "../../data/types";
import { Address } from "../../model/contract/contractType";
import { Form } from "../form/Form";
import { Button } from "../interactions/Buttons/Button";
import { T } from "../translation/T";
import cx from "classnames";
import { NewOverlay } from "../overlay/NewOverlay";

interface Props {
  address: Partial<Address>;
  onChange: (address: Partial<Address>) => void;
  disabled?: boolean;
  addressRequiredFields: Record<keyof Address, boolean> | Record<keyof Omit<Address, "countryCode">, boolean>;
  showCountryCode?: boolean;
  noLabel?: boolean;
}

export const AddressPopover = ({ onChange, ...props }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isDirectionUpwards, setDirectionUpwards] = useState(false);

  const checkIfShouldOpenUpwards = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    return windowHeight - rect.bottom < 400;
  };

  const onOpen = () => {
    if (ref.current) {
      setDirectionUpwards(checkIfShouldOpenUpwards(ref.current));
    }
    setIsOpen(true);
  };

  return (
    <div className={styles.addressPopover} ref={ref}>
      <Button
        ghost
        block
        onClick={onOpen}
        status={props.disabled ? Status.DISABLED : Status.DEFAULT}
        className={cx(styles.manualButton, {
          [styles.noLabel]: props.noLabel,
        })}
      >
        <T>Enter manually</T>
      </Button>
      <NewOverlay open={isOpen} onClose={() => setIsOpen(false)} widthSize="small" noPadding>
        <AddressPopoverInner
          upwards={isDirectionUpwards}
          onClose={() => setIsOpen(false)}
          {...{ onChange, ...props }}
        />
      </NewOverlay>
    </div>
  );
};

type InnerProps = {
  onClose: () => void;
  /**
   * Whether the popover should be opened upwards, defaults to false
   */
  upwards?: boolean;
} & Props;

const AddressPopoverInner = ({ onChange, onClose, address, upwards = false, ...props }: InnerProps) => {
  const [innerAddress, setInnerAddress] = useState<Partial<Address>>(() => address);

  return (
    <div className={styles.content}>
      <h4 className={styles.header} style={{ margin: 0 }}>
        <T>Address</T>
      </h4>

      <Form>
        <div className={"double-auto-columns p-content"}>
          <AddressForm
            onChange={setInnerAddress}
            address={innerAddress}
            showCountryCode
            {...{
              ...props,
            }}
          />
        </div>
      </Form>
      <div className={styles.buttons}>
        <div>
          <Button variant="outlined" block color="secondary" onClick={onClose}>
            <T>Cancel</T>
          </Button>
        </div>
        <div>
          <Button
            block
            onClick={() => {
              onChange(innerAddress);
              onClose();
            }}
          >
            <T>Save</T>
          </Button>
        </div>
      </div>
    </div>
  );
};
