import { data } from "./data";
import { UserId } from "../model/common/commonType";

export interface TeamMember {
  userId: UserId;
  email: string;
  name: string;
  lastLogin?: string; //datetime
}

export const dataSales = {
  loadMyTeamMembers: () => data.get<TeamMember[]>("/api/sales/team/my-members"),
};
