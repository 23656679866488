import React, { useCallback, useRef } from "react";
import cx from "classnames";
import { Status } from "../../../data/types";
import "./RadioButtons.scss";
import { Alternative } from "../InputTypes";
import { Radio } from "../../icons/Radio";
import { id } from "../../utils";
import { Checkmark } from "../../icons/Checkmark";
import { Disabled } from "../../icons/Disabled";
import { Pending } from "../../icons/Pending";
import { Error } from "../../icons/Error";
import { useTranslation } from "react-i18next";

export interface Stringifiable {
  toString(): string;
}

export interface Props<T extends Stringifiable> {
  status?: Status;
  className?: string;
  value?: T;
  hint?: string | React.ReactNode;
  label?: string | React.ReactNode;
  name?: string;
  message?: string | React.ReactNode;
  onChange: (value: T, name: string, ev?: InputEvent) => void;
  onBlur?: (value: T | undefined, name: string) => void;
  alternatives: Alternative<T>[];
  required?: boolean;
}

function InnerRadioButtons<T extends Stringifiable>(
  {
    status = Status.DEFAULT,
    className,
    name,
    label = null,
    onChange,
    onBlur,
    message = null,
    hint,
    alternatives = [],
    value,
    required,
  }: Props<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { t } = useTranslation();
  const identifier = useRef<string>(id());

  const internalChange = useCallback(
    (ev) => {
      onChange(ev.target.value, name || identifier.current, ev);
    },
    [onChange, name]
  );

  const internalBlur = useCallback(() => {
    onBlur && onBlur(value, name || identifier.current);
  }, [value, name, onBlur]);

  const indexLength = alternatives.length - 1;

  return (
    <div className={cx("input", "radio-buttons", className, status)} ref={ref}>
      <div className="input-label-wrapper">
        <div className="input-label">
          <div className="input-label-tag">{label || <div className="invisible">empty</div>}</div>
        </div>
        {!value && required && <div className="required-marker fs-small">{t("required")}</div>}
      </div>

      {alternatives.map((option, idx) => {
        const current = option?.value;
        const checked = value === current;
        const labelId = `${identifier.current}-${idx}`;
        const { disabled } = option;

        return (
          <label
            htmlFor={labelId}
            key={current.toString()}
            className={cx("radio-button", `length-${alternatives.length}`, {
              "is-first": idx === 0,
              "is-last": indexLength === idx,
              checked,
              "is-disabled": disabled,
            })}
          >
            <Radio checked={checked} />
            <div className="radio-label">{option.text}</div>
            <input
              id={labelId}
              name={labelId}
              type="radio"
              onChange={internalChange}
              onBlur={internalBlur}
              value={current.toString()}
              checked={checked}
              disabled={disabled || status === Status.DISABLED || status === Status.PENDING}
            />

            {checked && (
              <div className="input-status">
                <Checkmark />
                <Disabled />
                <Pending />
                <Error />
              </div>
            )}
          </label>
        );
      })}

      {(hint || message) && (
        <div className="input-messages">
          <div className="input-hint">{hint}</div>
          <div className="input-message">{message}</div>
        </div>
      )}
    </div>
  );
}

declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export const RadioButtons = React.forwardRef(InnerRadioButtons);
