import { motion } from "framer-motion";
import { PropsWithChildren } from "react";
import { useAutoHeightAnimation } from "../../hooks/useAutoHeightAnimation";

interface Props {
  className?: string;
  /**
   * optional dependencies to watch for changes, specify if you want to force trigger a height change
   * @default []
   * */
  dependencies?: any[];
  opacity?: boolean;
  allowOverflow?: boolean;
}

export const AnimateHeightAuto: React.FunctionComponent<PropsWithChildren<Props>> = ({
  className,
  dependencies = [],
  children,
  opacity = true,
  allowOverflow = false,
}) => {
  const [controls, ref] = useAutoHeightAnimation({
    deps: dependencies,
    opacity,
  });

  return (
    <motion.div
      animate={controls}
      {...{ children, className, ref }}
      style={{
        overflow: allowOverflow ? "visible" : "hidden",
      }}
    />
  );
};
