import React, { ReactNode, useState } from "react";
import { Page } from "../Page/Page";
import { useTranslation } from "react-i18next";
import { Link } from "../../components/links/Link";
import { Button } from "../../components/interactions/Buttons/Button";
import { Input } from "../../components/interactions/Input/Input";
import { Status } from "../../data/types";
import { Burger } from "../../components/icons/Burger";
import { Dropdown } from "../../components/interactions/Dropdown/Dropdown";
import { RadioButtons } from "../../components/interactions/Radio/RadioButtons";
import "./Styleguide.scss";

export const Styleguide: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>();
  const [alternative, setAlternative] = useState<string>();
  const [opt, setOpt] = useState<string>();

  // const texts: UploadTexts = {
  //   extensions: (
  //     <Trans i18nKey="input.upload.extension.standard">
  //       Accepted extensions are <i>pdf</i>, <i>jpg</i>, <i>jpeg</i>, <i>png</i>,{" "}
  //       <i>xls</i>, <i>xlsx</i>, <i>doc</i> or <i>docx</i>
  //     </Trans>
  //   ),
  //   error: (
  //     <Trans i18nKey="input.upload.error">
  //       <strong>Oh noo!</strong> Something bad happened. Try again?
  //     </Trans>
  //   ),
  //   button: t("input.upload.button"),
  // };

  let status = Status.DEFAULT;
  let message: ReactNode = "";

  if (value === "error") {
    status = Status.ERROR;
    message = (
      <>
        This is an <b>error</b> message
      </>
    );
  }

  if (value === "success") {
    status = Status.SUCCESS;
    message = (
      <>
        This is a <b>success</b> message
      </>
    );
  }

  if (value === "disabled") {
    status = Status.DISABLED;
    message = (
      <>
        This is a <b>disabled</b> message
      </>
    );
  }

  if (value === "pending") {
    status = Status.PENDING;
    message = (
      <>
        This is a <b>pending</b> message
      </>
    );
  }

  return (
    <Page title="Styleguide" className="styleguide">
      <section>
        <article>
          <h1>{t("Welcome to React")}</h1>

          <hr />

          <RadioButtons
            onChange={(val) => {
              setOpt(val);
            }}
            label="Radio buttons label"
            name="styleguide-label"
            value={opt}
            alternatives={[
              {
                value: "evil",
                text: "evil",
              },
              {
                value: "medium",
                text: "medium",
              },
              {
                value: "good",
                text: "good",
              },
            ]}
          />

          <hr />

          <Dropdown
            onChange={(alt) => setAlternative(alt)}
            label="Pick crew"
            alternatives={[
              {
                value: "evil",
                text: "evil",
              },
              {
                value: "good",
                text: "good",
              },
            ]}
            hint="Evil has no boundaries"
            value={alternative}
            status={status}
            message="Ohh, no."
          />

          <hr />

          <Input
            name="styleguide-input-number"
            value={value}
            onChange={(val) => setValue(val)}
            message={message}
            status={status}
            label="Contact number"
            placeholder="E.g 0023 543 435 12"
            hint={<>Type success or error for status update</>}
          />
          <hr />
          <p>
            <Link link="/">Home</Link>{" "}
            <Link external link="https://interactive-history.app">
              interactive
            </Link>
          </p>

          <Button
            onClick={(data) => console.log("Default", data)}
            data={{
              test: "some data",
            }}
          >
            Default
          </Button>

          <Button onClick={() => console.log("Default")} ghost block>
            Default
          </Button>

          <Button block onClick={(data) => console.log("Error", data)} status={Status.ERROR}>
            Error
          </Button>

          <div className="wrapper">
            <Button onClick={(data) => console.log("Success", data)} status={Status.SUCCESS}>
              Success
            </Button>

            <Button onClick={(data) => console.log("Pending", data)} status={Status.PENDING}>
              Pending
            </Button>

            <Button onClick={(data) => console.log("Disabled", data)} status={Status.DISABLED}>
              Disabled
            </Button>

            <Button onClick={() => console.log("Default")} ghost status={Status.ERROR}>
              Error
            </Button>

            <Button onClick={() => console.log("Default")} ghost status={Status.SUCCESS}>
              Success
            </Button>

            <Button onClick={() => console.log("Default")} ghost status={Status.PENDING}>
              Pending
            </Button>

            <Button onClick={() => console.log("Default")} ghost status={Status.DISABLED}>
              Disabled
            </Button>

            <Button action onClick={() => console.log("Default")}>
              <Burger />
            </Button>

            <Button action ghost onClick={() => console.log("Default")}>
              <Burger />
            </Button>

            <Button className="mini" action onClick={() => console.log("Default")}>
              <Burger />
            </Button>

            <Button className="mini" action ghost onClick={() => console.log("Default")}>
              <Burger />
            </Button>

            <Button className="mini" onClick={() => console.log("Default")}>
              mini
            </Button>

            <Button className="mini" ghost onClick={() => console.log("Default")}>
              mini
            </Button>
          </div>

          <hr />
          {/* <Upload
            initialFiles={[]}
            onChange={(f) => {
              // console.log("files", f);
            }}
            texts={texts}
          /> */}
        </article>
      </section>
    </Page>
  );
};
