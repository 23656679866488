import { useEffect, useState } from "react";
import { dataTranslation } from "../../data/dataTranslation";
import { Country } from "../../model/common/commonType";
import { useIsMountedRef } from "../../hooks/useIsMounted";
import { countryToLang } from "../../i18n";
import "./MissingTranslationsBadge.scss";

interface Props {
  country: Country;
}

export const MissingTranslationsBadge: React.VoidFunctionComponent<Props> = ({ country }) => {
  const [missingTranslations, setMissingTranslations] = useState<number>();
  const mounted = useIsMountedRef();

  useEffect(() => {
    dataTranslation.countMissingTranslations(countryToLang(country)).then((response) => {
      if (mounted.current) {
        setMissingTranslations(response.count);
      }
    });
  });

  if (!missingTranslations) {
    return null;
  }

  return <span className="missing-translations-badge">{missingTranslations}</span>;
};
