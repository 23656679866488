import { FC, useEffect, useState } from "react";
import { Pomca } from "../../../../../../../model/pricing/pricingTypes";
import { Country, Currency } from "../../../../../../../model/common/commonType";
import styles from "./PomcaFee.module.scss";
import { InfoBox } from "../../../../../../../components/boxes/InfoBox";
import { Button } from "../../../../../../../components/interactions/Buttons/Button";
import { NumberInput } from "../../../../../../../components/form/NumberInput";
import { getCurrencySymbol } from "../../../../../../../components/utils";
import useGeneralData from "../../../../../../../hooks/useGeneralData";
import { PricingUpdateType } from "../PricingInputs";
import { TrashIcon } from "../../../../../../../components/icons/TrashIcon";
import { RequiredValidator } from "../../../../../../../components/form/validators/RequiredValidator";
import { MinValidator } from "../../../../../../../components/form/validators/MinValidator";
import { Status } from "../../../../../../../data/types";
import { AnimatePresence, motion } from "framer-motion";
import { FADE_ANIMATION_PROPS } from "../../../../../../../components/animate/FadeAnimation";
import { Checkboxes } from "../../../../../../../components/interactions/Checkboxes/Checkboxes";
import { Language } from "../../../../../../../i18n";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

export const INITIAL_POMCA: Pomca[] = [{ terminalAmount: 1, fee: 100 }];

export const POMCA_COUNTRIES = [Country.CZECHIA];

interface Props {
  pomcas: Pomca[];
  updatePricing: (updates: PricingUpdateType) => void;
  isSalesComp?: boolean;
  disabled?: boolean;
}

const EDIT_MODE = "edit-mode";

const PomcaFee: FC<Props> = ({ pomcas, updatePricing, isSalesComp = false, disabled = false }) => {
  const [editMode, setEditmode] = useState<string[]>(isSalesComp ? [] : [EDIT_MODE]);
  const { language, currency } = useGeneralData();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (isSalesComp) return;

    const isCreating = pathname.includes("new");
    setEditmode(isCreating ? [EDIT_MODE] : []);
  }, [pathname, isSalesComp]);

  useEffect(() => {
    if (!disabled) return;
    setEditmode([]);
  }, [disabled]);

  return (
    <>
      {!isSalesComp && (
        <Checkboxes
          className="m-bottom-10"
          onChange={(values) => setEditmode(values)}
          values={editMode}
          alternatives={[
            {
              text: "Edit mode",
              value: EDIT_MODE,
            },
          ]}
          disabled={disabled}
        />
      )}
      <div className={`${styles.root} ${isSalesComp ? "m-bottom-30" : null}`}>
        <div className={styles.header}>
          <h5>{t("POMCA")}</h5>
          <InfoBox>
            {t(
              "POMCA is a guaranteed mininum monthly fee according to number of terminals. The fee is applied on a location level."
            )}
          </InfoBox>
        </div>

        {editMode.includes(EDIT_MODE) ? (
          <PomcaEdit pomcas={pomcas} updatePricing={updatePricing} currency={currency} language={language} />
        ) : (
          <PomcaDisplay pomcas={pomcas} language={language} currency={currency} />
        )}
      </div>
    </>
  );
};

interface PomcaEditProps {
  pomcas: Pomca[];
  updatePricing: (updates: PricingUpdateType) => void;
  language: Language;
  currency: Currency;
}

const PomcaEdit: FC<PomcaEditProps> = ({ pomcas, updatePricing, language, currency }) => {
  const onAddThreshold = () => {
    const newFee = { terminalAmount: pomcas.length + 1, fee: 100 * (pomcas.length + 1) };
    const updatedPomca = [...pomcas, newFee];
    updatePricing({ pomca: updatedPomca, terminalLimitation: updatedPomca.length });
  };

  const onRemoveFee = () => {
    if (pomcas.length < 2) return;
    const updatedPomca = [...pomcas].slice(0, -1);
    updatePricing({ pomca: updatedPomca, terminalLimitation: updatedPomca.length });
  };

  const updateFee = (updateIndex: number, value: number | undefined) => {
    const updatedPomca = pomcas.map(({ terminalAmount, fee }, index) => {
      if (updateIndex === index) {
        return { terminalAmount, fee: value ? value : 0 };
      }

      return { terminalAmount, fee };
    });

    updatePricing({ pomca: updatedPomca, terminalLimitation: updatedPomca.length });
  };

  return (
    <div className={styles.body}>
      <AnimatePresence>
        {pomcas.map((pomca, index) => (
          <motion.div
            key={`pomca-fee-${pomca.terminalAmount}`}
            className={styles.edit_row}
            {...FADE_ANIMATION_PROPS}
          >
            <NumberInput
              label={"Terminal amount"}
              value={pomca.terminalAmount}
              onChange={() => null}
              disabled
            />

            <NumberInput
              label={`Minimum monthly fee `}
              value={pomca.fee}
              onChange={(value) => updateFee(index, value)}
              prefix={`${getCurrencySymbol(language, currency)} `}
              validators={[
                new RequiredValidator("Minimum monthly fee is required"),
                new MinValidator(1, "Minimum monthly fee must be larger than 0"),
              ]}
            />

            {index + 1 === pomcas.length && (
              <Button
                action
                onClick={onRemoveFee}
                variant="text"
                className="m-top-30"
                color="danger"
                status={pomcas.length < 2 ? Status.DISABLED : Status.DEFAULT}
              >
                <TrashIcon style={{ fontSize: "1.5em" }} />
              </Button>
            )}
          </motion.div>
        ))}
      </AnimatePresence>
      <div className={styles.add_threshold}>
        <Button onClick={onAddThreshold} className="m-top-30">
          Add terminal fee
        </Button>
      </div>
    </div>
  );
};

interface PomcaDisplayProps {
  pomcas: Pomca[];
  language: Language;
  currency: Currency;
}

const PomcaDisplay: FC<PomcaDisplayProps> = ({ pomcas, language, currency }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.display_body}>
      <div className={styles.display_row_header}>
        <div>{t("Terminal amount")}</div>
        <div>{t("Minimum monthly fee")}</div>
      </div>
      {pomcas.map((pomca) => (
        <div className={styles.display_row} key={`${pomca.terminalAmount}-pomca-display`}>
          <div>{pomca.terminalAmount}</div>
          <div>{`${getCurrencySymbol(language, currency)} ${pomca.fee}`}</div>
        </div>
      ))}
    </div>
  );
};

export default PomcaFee;
