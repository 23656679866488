import { useState, useCallback, useMemo, useRef } from "react";
import { LinkAnchors } from "../ContractEdit";
import { ContractEditError } from "../ContractEditError";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Status } from "../../../data/types";
import { contractStatusState } from "../../../state/contractStatusState";
import { ContractSaveError, getEditStatus, handleError } from "../ContractPage";
import { Button } from "../../../components/interactions/Buttons/Button";
import { dataContracts } from "../../../data/dataContracts";
import { Associate, AssociateRole } from "../../../model/associate/associateTypes";
import { ONGOING_RESPONSE } from "../../../data/queues/QueueTypes";
import { contractAssociateState, sortedAssociatesSelector } from "../../../state/contractAssociateState";
import { contractSaveState, contractErrorState } from "../../../state/contractSaveState";
import { AssociateId, Cas, Country } from "../../../model/common/commonType";
import { associateQueue, SaveType } from "../../../data/queues/AssociateQueue";
import { Trans, useTranslation } from "react-i18next";
import { PackageId } from "../../../model/contract/contractType";
import { contractPackageState } from "../../../state/contractPackageState";
import { Form, FormContainer } from "../../../components/form/Form";
import { TextInput } from "../../../components/form/TextInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { MinLengthValidator } from "../../../components/form/validators/MinLengthValidator";
import { MaxLengthValidator } from "../../../components/form/validators/MaxLengthValidator";
import { EmailValidator } from "../../../components/form/validators/EmailValidator";
import { HiddenInput } from "../../../components/form/HiddenInput";
import { FormName } from "../menus/ContractEditMenu";
import { LoginsOverlay } from "./LoginsOverlay";
import "./Logins.scss";
import { userState } from "../../../state/userState";
import { ScrollPositionAnchor } from "../../../components/scrollPosition/ScrollPositionAnchor";
import { EmailBackendValidator } from "../../../components/form/validators/EmailBackendValidator";
import { SectionFieldSet } from "../../../components/sectionFieldSet/SectionFieldSet";

interface LoginValues {
  firstName: string;
  lastName: string;
  email: string;
}

const DUMMY_ASSOCIATE_ID = "aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee" as AssociateId;

export function Logins() {
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const offerPackage = useRecoilValue(contractPackageState);
  const contractStatus = useRecoilValue(contractStatusState);
  const user = useRecoilValue(userState);
  const [error, setError] = useState<ContractSaveError | null>(null);
  const [values, setValues] = useState<LoginValues>({
    firstName: "",
    lastName: "",
    email: "",
  });
  const setAssociates = useSetRecoilState(contractAssociateState);
  const associates = useRecoilValue(sortedAssociatesSelector);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);
  const hasLogins =
    associates.filter((associate) => associate.roles.indexOf(AssociateRole.PORTAL_LOGIN) > -1).length > 0;
  const formRef = useRef<FormContainer>();
  const country = user?.country as Country;
  const ref = useRef<HTMLDivElement>(null);

  const onClose = useCallback(() => {
    setError(null);
  }, []);

  const onChange = useCallback(
    (val, name) => {
      setValues({
        ...values,
        [name]: val,
      });
    },
    [values]
  );

  const callback = useCallback(
    (err, response) => {
      if (err === ONGOING_RESPONSE) {
        return;
      }

      setStatus(Status.DEFAULT);

      if (err) {
        handleError(err, setError);
        setDataError((dataErrors) =>
          dataErrors.concat({
            date: new Date(),
          })
        );
        return;
      }

      setAssociates(response);
      setValues({
        firstName: "",
        lastName: "",
        email: "",
      });
      setDataSaved((dataSaved) =>
        dataSaved.concat({
          date: new Date(),
        })
      );
      formRef.current?.resetValidation();
    },
    [setDataSaved, setDataError, setAssociates]
  );

  const onSave = useCallback(() => {
    associateQueue.saveAssociate(
      contractStatus.contractId,
      {
        associateId: DUMMY_ASSOCIATE_ID,
        cas: 0 as Cas,
        roles: [AssociateRole.PORTAL_LOGIN],
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      },
      SaveType.ASSOCIATE,
      callback
    );
  }, [contractStatus.contractId, callback, values]);

  const buttonStatus = useMemo(() => {
    return getEditStatus(contractStatus.edit, status);
  }, [contractStatus.edit, status]);

  const retry = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(onSave, 500);
  }, [onSave]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataContracts.claimContract(contractStatus.contractId).then(onSave).catch(onSave); // We're lazy, execute save again, which will fail,
      // and propagate error to Overlay
    }, 500);
  }, [contractStatus.contractId, onSave]);

  const onToggle = useCallback(
    (toggled, toggledAssociate: Associate) => {
      const copy = {
        ...toggledAssociate,
        roles: toggled
          ? [...toggledAssociate.roles, AssociateRole.PORTAL_LOGIN]
          : toggledAssociate.roles.filter((role) => role !== AssociateRole.PORTAL_LOGIN),
      };

      associateQueue.saveAssociate(contractStatus.contractId, copy, SaveType.ASSOCIATE, callback);
    },
    [contractStatus.contractId, callback]
  );

  const inputStatus = useMemo(() => {
    return getEditStatus(contractStatus.edit, Status.DEFAULT);
  }, [contractStatus.edit]);

  // Close overlay if associate was deleted
  // useEffect(() => {
  //   if (
  //     !associates.find(
  //       (person) => person.associateId === activeAssociate?.associateId
  //     )
  //   ) {
  //     onClose();
  //   }
  // }, [associates, activeAssociate, onClose]);

  const isAddingLogin = values.email.length > 0 || values.firstName.length > 0 || values.lastName.length > 0;

  return (
    <div className="logins" ref={ref}>
      <ScrollPositionAnchor id={LinkAnchors.PORTAL_LOGIN.anchor} />

      <ContractEditError
        error={error}
        setError={setError}
        retry={retry}
        onClose={onClose}
        reclaimAndSave={reclaimAndSave}
      />

      <SectionFieldSet headerTitle={t(LinkAnchors.PORTAL_LOGIN.name)} formName={FormName.LOGIN}>
        {offerPackage.packageId === PackageId.SOFTPOS_BUNDLE && (
          <p>
            {country === Country.CZECHIA ? (
              <Trans>
                These logins will be used also for the <u>PayPhone portal</u>.
              </Trans>
            ) : (
              <Trans>
                These logins will be used also for the <u>Worldline Tap on Mobile portal</u>.
              </Trans>
            )}
          </p>
        )}
        {!!associates.length && <h5 className="m-bottom-20">{t("Select persons for logins")}</h5>}
        {associates.map((associate) => (
          <LoginsOverlay
            associate={associate}
            onToggle={onToggle}
            status={inputStatus}
            key={associate.associateId}
            scrollToRef={ref}
          />
        ))}
        <Form name={FormName.LOGIN}>
          <HiddenInput
            label={t("Logins")}
            value={hasLogins ? true : undefined}
            validators={[new RequiredValidator(t("Please select at least one person with login"))]}
            scrollToRef={ref}
          />
        </Form>

        <br />
        <br />

        <h5 className="m-bottom-30">{t("Add new login")}</h5>

        {/**
         * We want to split this into two different forms so that we can
         * handle the errors separately.
         */}
        <Form
          formContainer={formRef}
          onSubmit={(event, formRef) => {
            /**
             * TODO: We need to make sure that all fields are valid when there is already an admin
             * and the user is trying to add another login
             */
            if (formRef.isValid) {
              onSave();
            }
          }}
        >
          <div className="tablet-columns">
            <div>
              <TextInput
                onChange={onChange}
                name="firstName"
                label={t("First Name")}
                validators={
                  !isAddingLogin
                    ? []
                    : [
                        new RequiredValidator(t("First name is required")),
                        new MinLengthValidator(2, t("First name must be at least 2 characters")),
                        new MaxLengthValidator(25, t("First name must be at most 25 characters")),
                      ]
                }
                value={values.firstName}
                disabled={!contractStatus.edit}
              />
            </div>
            <div>
              <TextInput
                onChange={onChange}
                name="lastName"
                label={t("Last Name")}
                validators={
                  !isAddingLogin
                    ? []
                    : [
                        new RequiredValidator(t("Last name is required")),
                        new MinLengthValidator(2, t("Last name must be at least 2 characters")),
                        new MaxLengthValidator(25, t("Last name must be at most 25 characters")),
                      ]
                }
                value={values.lastName}
                disabled={!contractStatus.edit}
              />
            </div>

            <div>
              <TextInput
                onChange={onChange}
                label="Email"
                validators={
                  !isAddingLogin
                    ? []
                    : [
                        new RequiredValidator(t("Email is required")),
                        new EmailValidator(t("Email is invalid")),
                        new EmailBackendValidator(t("Email is not valid")),
                        new MaxLengthValidator(50, t("Email must be at most 50 characters")),
                      ]
                }
                value={values.email}
                name="email"
                disabled={!contractStatus.edit}
              />
            </div>
          </div>

          <Button block variant="text" status={buttonStatus} className="m-top-30" type="submit">
            {t("Add new login")}
          </Button>
        </Form>
      </SectionFieldSet>
    </div>
  );
}
