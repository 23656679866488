import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { FeatureSwitch } from "../../../components/featureSwitch/FeatureSwitch";
import { useFormValidity } from "../../../components/form/MultiFormContext";
import { ScrollPositionContext } from "../../../components/scrollPosition/ScrollPositionContext";
import { ContractFeature, Validity } from "../../../model/contract/contractType";
import { LinkAnchors } from "../ContractEdit";
import "./ContractEditMenu.scss";
import { ContractEditMenuItem } from "./ContractEditMenuItem";

export enum FormName {
  PRIMARY_CONTACT = "primary-contact",
  MAIN_DATA = "main-data",
  PRICING_CALCULATOR = "pricing-calculator",
  PACKAGE = "package",
  STORE = "store",
  SIGNEE = "signee",
  OWNER = "owner",
  BANK = "bank",
  LOGIN = "login",
  DOCUMENTS = "documents",
}

const scrollToElement = (element: HTMLElement) => {
  const y = element.getBoundingClientRect().top + window.pageYOffset + -50;
  window.scrollTo({
    top: y,
    left: 0,
    behavior: "smooth",
  });
};

export const ContractEditMenu: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const isPrimaryValid = useFormValidity(FormName.PRIMARY_CONTACT);
  const isMainDataValid = useFormValidity(FormName.MAIN_DATA);
  const isPackageValid = useFormValidity(FormName.PACKAGE);
  const isStoreValid = useFormValidity(FormName.STORE);
  const isSigneeValid = useFormValidity(FormName.SIGNEE);
  const isOwnerValid = useFormValidity(FormName.OWNER);
  const isBankValid = useFormValidity(FormName.BANK);
  const isPricingCalculatorValid = useFormValidity(FormName.PRICING_CALCULATOR);
  const isLoginValid = useFormValidity(FormName.LOGIN);
  const scrollPositionContext = useContext(ScrollPositionContext);

  const scrollPosition = scrollPositionContext?.closestScrollEntry;

  const onClick = useCallback((id: string) => {
    const element = document.getElementById(id);

    if (element) {
      scrollToElement(element);
    }
  }, []);

  return (
    <div className="contract-edit-vertical-menu">
      <div className="contract-edit-menu-links">
        <div className="menu-header m-bottom-10">{t("Contract sections")}</div>
        <ul className="menu-list">
          <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.PRIMAR_CONTACT}
            validity={isPrimaryValid}
            active={
              scrollPosition?.id === LinkAnchors.PRIMAR_CONTACT.anchor ||
              typeof scrollPosition?.id === "undefined"
            }
          />
          <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.COMPANY_DATA}
            validity={isMainDataValid}
            active={scrollPosition?.id === LinkAnchors.COMPANY_DATA.anchor}
          />

          <FeatureSwitch feature={ContractFeature.ADVANCED_PRICING}>
            <FeatureSwitch.Feature>
              <ContractEditMenuItem
                onClick={onClick}
                anchor={LinkAnchors.TERMINALS}
                validity={isStoreValid}
                active={scrollPosition?.id === LinkAnchors.TERMINALS.anchor}
              />
              <ContractEditMenuItem
                onClick={onClick}
                anchor={LinkAnchors.PRICING_CALCULATOR}
                validity={isPricingCalculatorValid}
                active={scrollPosition?.id === LinkAnchors.PRICING_CALCULATOR.anchor}
              />
            </FeatureSwitch.Feature>
            <FeatureSwitch.Default>
              <ContractEditMenuItem
                onClick={onClick}
                anchor={LinkAnchors.OFFERING}
                validity={isPackageValid}
                active={scrollPosition?.id === LinkAnchors.OFFERING.anchor}
              />
              <ContractEditMenuItem
                onClick={onClick}
                anchor={LinkAnchors.PRICING}
                validity={Validity.VALID}
                active={scrollPosition?.id === LinkAnchors.PRICING.anchor}
              />
              <ContractEditMenuItem
                onClick={onClick}
                anchor={LinkAnchors.TERMINALS}
                validity={isStoreValid}
                active={scrollPosition?.id === LinkAnchors.TERMINALS.anchor}
              />
            </FeatureSwitch.Default>
          </FeatureSwitch>
          <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.SIGNATORIES}
            validity={isSigneeValid}
            active={scrollPosition?.id === LinkAnchors.SIGNATORIES.anchor}
          />
          <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.OWNERS}
            validity={isOwnerValid}
            active={scrollPosition?.id === LinkAnchors.OWNERS.anchor}
          />
          <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.BANK_DETAILS}
            validity={isBankValid}
            active={scrollPosition?.id === LinkAnchors.BANK_DETAILS.anchor}
          />
          <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.PORTAL_LOGIN}
            validity={isLoginValid}
            active={scrollPosition?.id === LinkAnchors.PORTAL_LOGIN.anchor}
          />
          <ContractEditMenuItem
            onClick={onClick}
            anchor={LinkAnchors.UPLOADS}
            validity={Validity.VALID}
            active={scrollPosition?.id === LinkAnchors.UPLOADS.anchor}
          />
        </ul>
      </div>
    </div>
  );
};
