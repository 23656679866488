import React, { ReactNode, useCallback } from "react";
import cx from "classnames";
import "./Pagination.scss";

interface Props {
  activePage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  className?: string;
  alwaysShow?: boolean;
}

export const Pagination: React.FunctionComponent<Props> = ({
  activePage,
  totalPages,
  onPageChange,
  className,
  alwaysShow = false,
}) => {
  const onClick = useCallback((ev) => onPageChange(ev.target.dataset.page), [onPageChange]);

  if (!alwaysShow && totalPages < 2) {
    return null;
  }

  let buttons: ReactNode[] = [];
  if (totalPages < 8) {
    for (let i = 0; i < totalPages; i++) {
      buttons.push(
        <li key={`pagination-key-${i}`}>
          <button
            className={cx("pagination-button", {
              "is-active": i === activePage,
            })}
            data-page={i}
            onClick={onClick}
          >
            {i + 1}
          </button>
        </li>
      );
    }
  } else if (activePage === 0) {
    buttons = [
      <li key="pagination-key-0">
        <button className="pagination-button is-active" data-page={0} onClick={onClick}>
          1
        </button>
      </li>,
      <li key="pagination-key-1">
        <button className="pagination-button" data-page={1} onClick={onClick}>
          2
        </button>
      </li>,
      <li key="pagination-key-2">
        <button className="pagination-button" data-page={1} onClick={onClick}>
          &gt;
        </button>
      </li>,
      <li key="pagination-key-3">
        <button className="pagination-button" data-page={totalPages - 1} onClick={onClick}>
          &gt;&gt;
        </button>
      </li>,
    ];
  } else if (activePage === 1) {
    buttons = [
      <li key="pagination-key-0">
        <button className="pagination-button" data-page={0} onClick={onClick}>
          1
        </button>
      </li>,
      <li key="pagination-key-1">
        <button className="pagination-button is-active" data-page={1} onClick={onClick}>
          2
        </button>
      </li>,
      <li key="pagination-key-2">
        <button className="pagination-button" data-page={2} onClick={onClick}>
          3
        </button>
      </li>,
      <li key="pagination-key-3">
        <button className="pagination-button" data-page={2} onClick={onClick}>
          &gt;
        </button>
      </li>,
      <li key="pagination-key-4">
        <button className="pagination-button" data-page={totalPages - 1} onClick={onClick}>
          &gt;&gt;
        </button>
      </li>,
    ];
  } else if (activePage === totalPages - 1) {
    buttons = [
      <li key="pagination-key-0">
        <button className="pagination-button" data-page={0} onClick={onClick}>
          &lt;&lt;
        </button>
      </li>,
      <li key="pagination-key-1">
        <button className="pagination-button" data-page={totalPages - 2} onClick={onClick}>
          &lt;
        </button>
      </li>,
      <li key="pagination-key-2">
        <button className="pagination-button" data-page={totalPages - 2} onClick={onClick}>
          {totalPages - 1}
        </button>
      </li>,
      <li key="pagination-key-3">
        <button className="pagination-button is-active" data-page={totalPages - 1} onClick={onClick}>
          {totalPages}
        </button>
      </li>,
    ];
  } else if (activePage === totalPages - 2) {
    buttons = [
      <li key="pagination-key-0">
        <button className="pagination-button" data-page={0} onClick={onClick}>
          &lt;&lt;
        </button>
      </li>,
      <li key="pagination-key-1">
        <button className="pagination-button" data-page={totalPages - 3} onClick={onClick}>
          &lt;
        </button>
      </li>,
      <li key="pagination-key-2">
        <button className="pagination-button" data-page={totalPages - 3} onClick={onClick}>
          {totalPages - 2}
        </button>
      </li>,
      <li key="pagination-key-3">
        <button className="pagination-button is-active" data-page={totalPages - 2} onClick={onClick}>
          {totalPages - 1}
        </button>
      </li>,
      <li key="pagination-key-4">
        <button className="pagination-button" data-page={totalPages - 1} onClick={onClick}>
          {totalPages}
        </button>
      </li>,
    ];
  } else {
    buttons = [
      <li key="pagination-key-0">
        <button className="pagination-button" data-page={0} onClick={onClick}>
          &lt;&lt;
        </button>
      </li>,
      <li key="pagination-key-1">
        <button className="pagination-button" data-page={activePage - 1} onClick={onClick}>
          &lt;
        </button>
      </li>,

      <li key="pagination-key-2">
        <button className="pagination-button" data-page={activePage - 1} onClick={onClick}>
          {activePage}
        </button>
      </li>,

      <li key="pagination-key-3">
        <button className="pagination-button is-active" data-page={activePage} onClick={onClick}>
          {activePage + 1}
        </button>
      </li>,
      <li key="pagination-key-4">
        <button className="pagination-button" data-page={activePage + 1} onClick={onClick}>
          {activePage + 2}
        </button>
      </li>,

      <li key="pagination-key-5">
        <button className="pagination-button " data-page={activePage + 1} onClick={onClick}>
          &gt;
        </button>
      </li>,
      <li key="pagination-key-6">
        <button className="pagination-button" data-page={totalPages - 1} onClick={onClick}>
          &gt;&gt;
        </button>
      </li>,
    ];
  }

  return <ul className={cx("pagination", className)}>{buttons}</ul>;
};
