import React, { useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import styles from "./AddTerminalsToStores.module.scss";
import { useTranslation } from "react-i18next";
import { WarningBox } from "../../../../../components/boxes/WarningBox";
import { groupTerminalsByType, sortTerminalsByType } from "../../../../../model/terminal/terminalUtils";
import { TerminalConfigurationOptions, dataTerminalPricing } from "../../../../../data/dataTerminalPricing";
import { contractStatusState } from "../../../../../state/contractStatusState";
import {
  contractInstoresSelector,
  contractTerminalsSelector,
} from "../../../../../state/contractStoresState";
import {
  terminalPricingState,
  convertTerminalPricingResponseToState,
} from "../../../../../state/terminalPricingState";
import { TerminalPriceError } from "./SummaryDevice/TerminalPriceError/TerminalPriceError";
import { StoreAddTerminals } from "./StoreAddTerminals/StoreAddTerminals";
import { SummaryDevice } from "./SummaryDevice/SummaryDevice";
import { AnimatePresence, motion } from "framer-motion";
import FadeAnimation from "../../../../../components/animate/FadeAnimation";
import { ContractType, TerminalType } from "../../../../../model/contract/contractType";
import { getContractTypeDisplayString } from "../../../../../model/contract/contractUtils";
import { contractPricingState } from "../../../../../state/contractCostState";

// Based on our contract pdf templates, currently only accepts 3 rows which means 3 unique terminal types
const MAXIMUM_UNIQUE_TERMINAL_TYPE_AMOUNT = 3;

export const AddTerminalsToStores: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [terminalConfigOptions, setTerminalConfigOptions] = useState<TerminalConfigurationOptions[]>([]);
  const [apiError, setApiError] = useState(false);

  const { t } = useTranslation();
  const stores = useRecoilValue(contractInstoresSelector);
  const { terminalLimitation, disabledTerminals, pomca } = useRecoilValue(contractPricingState).INSTORE;
  const terminalPricing = useRecoilValue(terminalPricingState);
  const { country, contractId } = useRecoilValue(contractStatusState);
  const setTerminalPricingState = useSetRecoilState(terminalPricingState);
  const hardTerminals = useRecoilValue(contractTerminalsSelector).filter(
    (terminal) => terminal.terminalType !== TerminalType.ECOMMERCE
  );

  const groupedTerminals = groupTerminalsByType(hardTerminals);
  const maxTerminalsLimited = !!terminalLimitation && hardTerminals.length >= terminalLimitation;
  const maxUniqueTerminals = groupedTerminals.length >= MAXIMUM_UNIQUE_TERMINAL_TYPE_AMOUNT;

  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted

    const fetchTerminalData = async () => {
      setIsLoading(true);

      try {
        const [configurationOptions, contractTerminalPricing] = await Promise.all([
          dataTerminalPricing.getTerminalConfigurationOptions(country),
          dataTerminalPricing.getContractTerminalPrices(contractId),
        ]);

        const filteredConfigs = configurationOptions.filter((detail) =>
          filterDisabledTerminals(detail, disabledTerminals)
        );

        const sortedAndFilteredOptions = sortTerminalsByType(filteredConfigs);

        if (isMounted) {
          setTerminalConfigOptions(sortedAndFilteredOptions);

          const updatedTerminalPricingState = convertTerminalPricingResponseToState(
            contractTerminalPricing,
            country
          );
          setTerminalPricingState(updatedTerminalPricingState);
          setApiError(false);
        }
      } catch (error) {
        if (isMounted) setApiError(true);
      } finally {
        if (isMounted) setIsLoading(false);
      }
    };

    fetchTerminalData();

    // Cleanup function to cancel any ongoing tasks when component unmounts
    return () => {
      isMounted = false;
    };
  }, [country, setTerminalPricingState, contractId, disabledTerminals]);

  if (isLoading) return <div className="center fw-500 m-y-20">{t("loading terminal prices")}...</div>;

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <div className={styles.heading}>
          <h5 style={{ margin: 0 }}>
            {t(`${getContractTypeDisplayString(ContractType.INSTORE)} locations`)}
          </h5>
        </div>

        {stores.map((store) => (
          <StoreAddTerminals
            key={store.storeId}
            store={store}
            terminalConfigOptions={terminalConfigOptions}
            maxTerminalLimitation={maxTerminalsLimited}
            maxUniqueTerminals={maxUniqueTerminals}
            groupedTerminals={groupedTerminals}
          />
        ))}
      </div>
      <div className={styles.section}>
        <div className={styles.heading}>
          <h5 style={{ margin: 0 }}>{t("Terminal summary")}</h5>
        </div>
        <div className={styles.terminal_summaries}>
          <AnimatePresence exitBeforeEnter>
            {apiError ? (
              <TerminalPriceError key="terminal-price-error" />
            ) : hardTerminals.length < 1 ? (
              <FadeAnimation animationKey="no-terminals-added" className={styles.box}>
                <WarningBox>{t("No terminals added")}</WarningBox>
              </FadeAnimation>
            ) : (
              <>
                <ul key="summary-device-list" className={styles.device_summary_list}>
                  <AnimatePresence>
                    {maxUniqueTerminals && (
                      <FadeAnimation className={styles.box}>
                        <WarningBox>
                          {t(
                            `You can have a maximum of {{maxUniqueTerminals}} unique terminals per contract`,
                            { maxUniqueTerminals: MAXIMUM_UNIQUE_TERMINAL_TYPE_AMOUNT }
                          )}
                        </WarningBox>
                      </FadeAnimation>
                    )}
                  </AnimatePresence>

                  <AnimatePresence>
                    {groupedTerminals.map(({ terminalType, terminals }) => (
                      <motion.li
                        layoutId={terminalType}
                        key={terminalType}
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0 }}
                      >
                        <SummaryDevice
                          isPomca={!!pomca}
                          monthlyCost={terminalPricing[terminalType].monthlyCost}
                          terminalType={terminalType}
                          numberOfTerminals={terminals.length}
                          terminalPriceDetails={terminalConfigOptions.find(
                            (detail) => detail.terminalType === terminalType
                          )}
                        />
                      </motion.li>
                    ))}
                  </AnimatePresence>
                </ul>
              </>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

const filterDisabledTerminals = (
  terminal: TerminalConfigurationOptions,
  disabledTerminals: TerminalType[] | undefined
) => {
  return disabledTerminals && disabledTerminals.includes(terminal.terminalType) ? false : true;
};
