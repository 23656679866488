import { useCallback, useState } from "react";
import styles from "./MockContractData.module.scss";
import { Button } from "../../../components/interactions/Buttons/Button";
import { NewOverlay } from "../../../components/overlay/NewOverlay";
import { ConstrainedAssociate } from "../../../data/dataConfirm";
import { Associate, AssociateRole } from "../../../model/associate/associateTypes";
import { ONGOING_RESPONSE, QueueCallback } from "../../../data/queues/QueueTypes";
import { getAssociateRoleDisplayName } from "../../../model/associate/associateUtils";
import { Checkboxes } from "../../../components/interactions/Checkboxes/Checkboxes";
import { SaveType, associateQueue } from "../../../data/queues/AssociateQueue";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { contractStatusState } from "../../../state/contractStatusState";
import { contractErrorState, contractSaveState } from "../../../state/contractSaveState";
import { contractAssociateState } from "../../../state/contractAssociateState";
import { Alternative } from "../../../components/interactions/InputTypes";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { deepCopy } from "../../../components/utils";
import { getRandomAssociate, getRandomCompanyData, getRandomStoreData } from "./mockUtils";
import { contractMainContractDataState } from "../../../state/contractMainContractDataState";
import { dataContracts } from "../../../data/dataContracts";
import { Status } from "../../../data/types";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import { StatusBoxes } from "../../../components/boxes/StatusBoxes";
import { contractStoresState } from "../../../state/contractStoresState";
import { contractPricingState } from "../../../state/contractCostState";
import { PricingModel } from "../../../model/pricing/pricingTypes";
import useEnvironment from "../../../hooks/useEnvironment";
import { ContractType } from "../../../model/contract/contractType";
import { continueToCompletedFromContract } from "../../../data/dataDev";
import { DEV_ROUTE_MAPPING, DevContractStatus } from "./MockNewContract";
import { generatePath, useHistory } from "react-router-dom";
import { LuTestTube2 } from "react-icons/lu";

const relevantRoles = [
  AssociateRole.PRIMARY_CONTACT,
  AssociateRole.BENEFICIAL_OWNER,
  AssociateRole.SIGNATORY,
  AssociateRole.PORTAL_LOGIN,
];

const roleAlternatives: Alternative<AssociateRole>[] = relevantRoles.map((role) => ({
  text: getAssociateRoleDisplayName(role as AssociateRole),
  value: role as AssociateRole,
}));

const MockContractData = () => {
  const [status, setStatus] = useState(Status.DEFAULT);
  const [open, setOpen] = useState(false);
  const [checkedRoles, setCheckedRoles] = useState<string[]>([]);

  const [associates, setAssociates] = useRecoilState(contractAssociateState);
  const [mainData, setMainData] = useRecoilState(contractMainContractDataState);
  const [stores, setStores] = useRecoilState(contractStoresState);

  const setDataError = useSetRecoilState(contractErrorState);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const { contractId, country } = useRecoilValue(contractStatusState);
  const { pricingModel } = useRecoilValue(contractPricingState)[ContractType.INSTORE];
  const history = useHistory();

  const isNotAllowedToAddStores = pricingModel === PricingModel.PACK && stores.length >= 1;

  const showSuccesBox = () => {
    setStatus(Status.SUCCESS);
    setTimeout(() => {
      setStatus(Status.DEFAULT);
    }, 3500);
  };

  const onChangeRole = (values: string[]) => {
    setCheckedRoles(values);
  };

  const saveAssociateCallback = useCallback<QueueCallback<Associate>>(
    (err, response) => {
      if (err === ONGOING_RESPONSE) return;

      if (err) {
        setStatus(Status.ERROR);
        setDataError((dataErrors) => dataErrors.concat({ date: new Date() }));
        return;
      }

      if (!response) return;

      setAssociates(response);
      setDataSaved((dataSaved) => dataSaved.concat({ date: new Date() }));
      showSuccesBox();
    },
    [setDataSaved, setDataError, setAssociates]
  );

  const mockAssociate = useCallback(() => {
    setStatus(Status.ERROR);
    const associateRoles = [...checkedRoles] as AssociateRole[];
    const newAssociate: ConstrainedAssociate = { ...getRandomAssociate(country, associateRoles) };
    const currentPrimaryContact = associates.find(isPrimaryContact);

    // if we add a new associate with primary contact, update previous primary contact (if it exists)
    if (currentPrimaryContact && associateRoles.includes(AssociateRole.PRIMARY_CONTACT)) {
      const primaryCopy = deepCopy(currentPrimaryContact);
      const updatedRoles = currentPrimaryContact.roles.filter(notPrimaryContact);
      primaryCopy.roles = updatedRoles;

      return associateQueue.saveAssociate(contractId, primaryCopy, SaveType.ASSOCIATE, (err, response) => {
        if (err === ONGOING_RESPONSE) return;
        associateQueue.saveAssociate(contractId, newAssociate, SaveType.ASSOCIATE, (err, resp) =>
          saveAssociateCallback(err, resp)
        );
      });
    }

    return associateQueue.saveAssociate(contractId, newAssociate, SaveType.ASSOCIATE, (err, response) =>
      saveAssociateCallback(err, response)
    );
  }, [checkedRoles, contractId, saveAssociateCallback, associates, country]);

  const mockCompanyData = () => {
    setStatus(Status.ERROR);
    const mockedCompanyData = getRandomCompanyData(country);

    const newMainData = {
      ...mainData,
      ...mockedCompanyData,
    };

    dataContracts
      .saveMainContractData(contractId, newMainData)
      .then(() => {
        setMainData(newMainData);
        showSuccesBox();
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  };

  const mockLocation = () => {
    if (isNotAllowedToAddStores) return;

    const saveStorePayload = getRandomStoreData(country);
    dataContracts
      .saveStore(contractId, saveStorePayload)
      .then((resp) => {
        setStores(resp);
        showSuccesBox();
      })
      .catch((err) => {
        setStatus(Status.ERROR);
      });
  };

  const continueContractToCompleted = async () => {
    await continueToCompletedFromContract(contractId);
    const ROUTE = DEV_ROUTE_MAPPING[DevContractStatus.COMPLETE];
    history.push(generatePath(ROUTE, { id: contractId }));
  };

  const { isProduction } = useEnvironment();
  if (isProduction) return null;

  return (
    <div className={styles.root}>
      <NewOverlay open={open} onClose={() => setOpen(false)} widthSize="small" scrollLock={true}>
        <div className={styles.content}>
          <h4>Mock contract data</h4>
          <div className="m-top-20">
            <Button variant="primary" color="mock" block onClick={continueContractToCompleted}>
              Continue contract to completed
            </Button>
          </div>
          <div className="m-top-20">
            <h5>Associate</h5>
            <div className={styles.associates}>
              <Checkboxes onChange={onChangeRole} values={checkedRoles} alternatives={roleAlternatives} />
            </div>
            <Button className="m-top-10" color="mock" block onClick={mockAssociate}>
              Mock associate
            </Button>
          </div>
          <div className="m-top-20">
            <h5>Store location</h5>

            {isNotAllowedToAddStores ? (
              <ErrorBox>Pricing model Pack are only allowed 1 store</ErrorBox>
            ) : (
              <Button color="mock" block onClick={mockLocation}>
                Mock store
              </Button>
            )}
          </div>
          <div className="m-top-20">
            <h5>Company data</h5>
            <Button color="mock" block onClick={mockCompanyData}>
              Mock company data
            </Button>
          </div>
          <StatusBoxes status={status}>
            <SuccessBox>You mock was successful!</SuccessBox>
            <ErrorBox>An error occured... Please retry</ErrorBox>
          </StatusBoxes>
        </div>
      </NewOverlay>

      <Button color="mock" onClick={() => setOpen(!open)} block>
        Mock contract data
        <LuTestTube2 style={{ marginLeft: 10, height: "1.2em", width: "1.2em" }} />
      </Button>
    </div>
  );
};

export default MockContractData;

const isPrimaryContact = (associate: Associate) => associate.roles.includes(AssociateRole.PRIMARY_CONTACT);

const notPrimaryContact = (role: AssociateRole) => role !== AssociateRole.PRIMARY_CONTACT;
