import { BaseValidator } from "./BaseValidator";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";

export class ExternalMultiValueValidator<T> extends BaseValidator {
  name = "ExternalMultiValueValidator";

  constructor(
    error: string,
    private externalValue: T,
    private targetValues: T[]
  ) {
    super(error);
    this.name = `ExternalMultiValueValidator-${externalValue}-${targetValues}`;
  }

  validate(): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (this.targetValues.includes(this.externalValue)) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
