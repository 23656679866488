import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Edit } from "../../../components/icons/Edit";
import { Expand } from "../../../components/icons/Expand";
import { Button } from "../../../components/interactions/Buttons/Button";
import { NewOverlay } from "../../../components/overlay/NewOverlay";
import { PendingOverlay } from "../../../components/overlay/PendingOverlay";
import { Table } from "../../../components/table/Table";
import { getDynamicLink } from "../../../components/utils";
import { TeamId } from "../../../model/common/commonType";
import { SalesUser, userAdministration } from "../../../data/UserAdministration";
import { SALES_USER_EDIT_PAGE } from "../Users/SalesUserPage";
import "./TeamMembersButtonOverlay.scss";

interface Props {
  id: TeamId;
}

export const TeamMembersButtonOverlay: React.FunctionComponent<Props> = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button action className="mini" data={{ id }} onClick={() => setIsOpen(true)}>
        <Expand />
      </Button>
      <NewOverlay open={isOpen} onClose={() => setIsOpen(false)} className="team-members-button-overlay">
        <InnerTeamMembersButtonOverlay id={id} />
      </NewOverlay>
    </>
  );
};

interface InnerProps {
  id: TeamId;
}

const InnerTeamMembersButtonOverlay: React.FunctionComponent<InnerProps> = ({ id }) => {
  const history = useHistory();
  const [teamMembers, setTeamMembers] = useState<SalesUser[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    userAdministration
      .loadTeamMembers(id)
      .then((users) => {
        setTeamMembers(users);
      })
      .catch((err) => {
        //Show some error?
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onOpenSalesUser = useCallback(
    (data) => {
      history.push(getDynamicLink(SALES_USER_EDIT_PAGE, { id: data.id }));
    },
    [history]
  );

  return (
    <div>
      <h4>Team members</h4>
      {isLoading && <PendingOverlay />}
      <Table>
        <thead>
          <tr>
            <th>Member Name</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {teamMembers.map((member) => (
            <tr key={member.id}>
              <td>{member.name}</td>
              <td className="text-right">
                <Button
                  action
                  className="mini"
                  data={{
                    id: member.id,
                  }}
                  onClick={onOpenSalesUser}
                >
                  <Edit />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
