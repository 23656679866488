import { FunctionComponent, useEffect, useRef, useState } from "react";
import { STORE_KEY, Store, PassSettings } from "../../../Store";
import { TextInput } from "../../../components/form/TextInput";
import { PassIdValidator } from "../../../components/form/validators/PassIdValidator";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { PassCategory, PassLookup, dataPassUsers } from "../../../data/dataPassUsers";
import { id } from "../../../components/utils";

export interface PassUserValues {
  merchandiser?: string;
  direction?: string;
}

interface Props {
  values: PassUserValues;
  onChange: (values: PassUserValues) => void;
  disabled?: boolean;
}

export const PassUserInputs: FunctionComponent<Props> = ({ values, onChange, disabled = false }) => {
  const [merchandiserMessage, setMerchandiserMessage] = useState("");
  const [directionMessage, setDirectionMessage] = useState("");
  const [merchandisers, setMerchandisers] = useState<PassLookup[]>([]);
  const [directions, setDirections] = useState<PassLookup[]>([]);

  const merchandiserIdentifier = useRef<string>(id());
  const directionIdentifier = useRef<string>(id());

  useEffect(() => {
    Promise.all([
      dataPassUsers.getPassUsers(PassCategory.merchandiser),
      dataPassUsers.getPassUsers(PassCategory.direction),
    ]).then(([merchandisers, directions]) => {
      setMerchandisers(merchandisers);
      setDirections(directions);
    });
  }, []);

  const { merchandiser, direction } = values;

  return (
    <>
      <div className="m-bottom-20">
        <TextInput
          name="merchandiser"
          label="Merchandiser"
          list={merchandiserIdentifier.current}
          onChange={(value) => onChange({ ...values, merchandiser: value })}
          disabled={disabled}
          validators={[
            new RequiredValidator("Merchandiser is required"),
            new PassIdValidator(PassCategory.merchandiser, "Merchandiser could not be verified", (lookup) => {
              setMerchandiserMessage(lookup.name);
              const key = STORE_KEY.STORE_PASS_SETTINGS;
              const settings = Store.getValue<PassSettings>(key);
              Store.setValue(key, {
                ...settings,
                merchandiser: merchandiser ?? "",
              });
            }),
          ]}
          message={merchandiserMessage}
          value={merchandiser}
        />
        <datalist id={merchandiserIdentifier.current}>
          {merchandisers.map(({ name, userId }) => (
            <option value={userId} key={userId}>
              {name}
            </option>
          ))}
        </datalist>
      </div>
      <div>
        <TextInput
          name="direction"
          label="Direction"
          list={directionIdentifier.current}
          onChange={(value) => onChange({ ...values, direction: value })}
          disabled={disabled}
          validators={[
            new RequiredValidator("Direction is required"),
            new PassIdValidator(PassCategory.direction, "Direction could not be verified", (lookup) => {
              setDirectionMessage(lookup.name);
              const key = STORE_KEY.STORE_PASS_SETTINGS;
              const settings = Store.getValue<PassSettings>(key);
              Store.setValue(key, {
                ...settings,
                direction: direction ?? "",
              });
            }),
          ]}
          message={directionMessage}
          value={direction}
        />
        <datalist id={directionIdentifier.current}>
          {directions.map(({ name, userId }) => (
            <option value={userId} key={userId}>
              {name}
            </option>
          ))}
        </datalist>
      </div>
    </>
  );
};
