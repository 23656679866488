import React, { useEffect, useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ErrorBox } from "../../../../../components/boxes/ErrorBox";
import { StatusBoxes } from "../../../../../components/boxes/StatusBoxes";
import { SuccessBox } from "../../../../../components/boxes/SuccessBox";
import { Form, FormContainer } from "../../../../../components/form/Form";
import { IdCardInputs } from "../../../../../components/idCard/IdCardInputs";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { getDynamicLink } from "../../../../../components/utils";
import { dataConfirm, IdScan, MerchantAssociateResponse } from "../../../../../data/dataConfirm";
import { AssociateIdentity } from "../../../../../model/associate/associateTypes";
import { AssociateId, ContractId } from "../../../../../model/common/commonType";
import { Status } from "../../../../../data/types";
import { cache } from "../../../../../network/Cache";
import { MERCHANT_ROUTE } from "../../../MerchantStatusPage";
import styles from "../../AssociateReview.module.scss";
import { SignatureDisplay } from "../../components/SignatureDisplay/SignatureDisplay";
import { IdCardDisplay } from "./IdCardDisplay";
import ContentBox from "../../../../../components/ContentBox/ContentBox";
import ReviewStatus from "../../ReviewStatus";

export interface Props {
  contractId: ContractId;
  associateId: AssociateId;
  merchantAssociate: MerchantAssociateResponse;
}

export const AssociateIdDataInputView: React.FunctionComponent<Props> = ({
  contractId,
  associateId,
  merchantAssociate,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { identificationData } = merchantAssociate;

  const formContainer = useRef<FormContainer>();
  const [saveStatus, setSaveStatus] = useState<Status>(Status.DEFAULT);

  const [idScan, setIdScan] = useState<IdScan>();
  const [isLoadingIdScan, setIsLoadingIdScan] = useState(true);
  const [idScanApiError, setIdScanApiError] = useState(false);

  const [cardForm, setCardForm] = useState<AssociateIdentity>(identificationData);

  const getIdScanData = useCallback(async () => {
    setIsLoadingIdScan(true);
    dataConfirm
      .getIdScan(contractId, associateId)
      .then((scan) => {
        setIdScan(scan);
        setCardForm({
          ...identificationData,
          ...scan.resolvedData,
        });
        setIdScanApiError(false);
      })
      .catch((err) => {
        setIdScanApiError(true);
      })
      .finally(() => {
        setIsLoadingIdScan(false);
      });
  }, [setIdScan, identificationData, contractId, associateId]);

  useEffect(() => {
    getIdScanData();
  }, [getIdScanData]);

  const onClose = useCallback(() => {
    history.replace(getDynamicLink(MERCHANT_ROUTE, { id: contractId }));
  }, [contractId, history]);

  const onSaveAndSignOff = useCallback(async () => {
    setSaveStatus(Status.PENDING);
    try {
      await dataConfirm.saveAssociateIdentity(contractId, associateId, cardForm);
      await dataConfirm.signoffOnAssociate(contractId, associateId);
      cache.clear();
      setSaveStatus(Status.SUCCESS);
      setTimeout(() => onClose(), 2000);
    } catch (err) {
      setSaveStatus(Status.ERROR);
      setTimeout(() => setSaveStatus(Status.DEFAULT), 4000);
    }
  }, [associateId, cardForm, contractId, onClose]);

  const extractedFields = idScan?.resolvedData ? Object.keys(idScan?.resolvedData) : [];

  return (
    <Form
      onSubmit={(event, form) => {
        if (form.isValid) {
          onSaveAndSignOff();
        }
      }}
      formContainer={formContainer}
      name="confirm-associate-id"
    >
      <div className={styles.view}>
        <ReviewStatus isComplete={saveStatus === Status.SUCCESS} />

        <ContentBox colorStyle={saveStatus === Status.SUCCESS ? "completed" : "default"}>
          <div className={styles.columns}>
            <div className={styles.leftColumn}>
              <h4 className="m-bottom-30">{t("ID")}</h4>
              <IdCardDisplay
                idScan={idScan}
                merchantAssociate={merchantAssociate}
                fetchIdScan={getIdScanData}
                idScanApiError={idScanApiError}
                isFetchingData={isLoadingIdScan}
              />
            </div>
            <div className={styles.rightColumn}>
              <h4 className="m-bottom-30">{t("Identity information")}</h4>
              <div className={styles.idCardInputsColumn}>
                <IdCardInputs
                  cardForm={cardForm}
                  setCardForm={setCardForm}
                  status={isLoadingIdScan ? Status.DISABLED : Status.DEFAULT}
                  prefilledFields={extractedFields}
                />
              </div>
            </div>
            <SignatureDisplay merchantAssociate={merchantAssociate} />
            <div className={styles.actions}>
              <StatusBoxes status={saveStatus}>
                <ErrorBox relative>{t("We couldn't save identity information. Try again")}</ErrorBox>
                <SuccessBox>{t("Thank you!")}</SuccessBox>
              </StatusBoxes>
            </div>
          </div>
        </ContentBox>
        <div className={styles.buttons}>
          <Button block status={saveStatus} type="submit" className="m-top-20">
            {t("Save and sign off")}
          </Button>
        </div>
      </div>
    </Form>
  );
};
