import { FC } from "react";
import { Trans } from "react-i18next";
import FadeAnimation from "../../../../../components/animate/FadeAnimation";
import { WarningBox } from "../../../../../components/boxes/WarningBox";
import { ZoomableImage } from "../../../../../components/images/ZoomableImage";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { MerchantAssociateResponse } from "../../../../../data/dataConfirm";
import styles from "../../AssociateReview.module.scss";

interface CompareProps {
  merchantAssociate: MerchantAssociateResponse;
  signoffIdCard: () => void;
  reject: () => void;
}

const CompareIDandSelfie: FC<CompareProps> = ({ merchantAssociate, signoffIdCard, reject }) => {
  const image = merchantAssociate.identityDocument.passport
    ? merchantAssociate.identityDocument.passport
    : merchantAssociate.identityDocument.idFront;

  return (
    <>
      <FadeAnimation>
        <div className={styles.rightColumn}>
          <h4>
            <Trans>ID</Trans>
          </h4>
          <div className={styles.selfie}>
            <ZoomableImage image={image} alt="ID Card" />
          </div>
        </div>
      </FadeAnimation>
      <FadeAnimation className={styles.spanColumns}>
        <WarningBox>
          <Trans>Compare the selfie and the image in the ID</Trans>
        </WarningBox>
        <div className={styles["columns-no-padding"]}>
          <div>
            <Button onClick={reject} block className="danger-button">
              <Trans>Reject selfie</Trans>
            </Button>
          </div>
          <div>
            <Button onClick={signoffIdCard} block>
              <Trans>Selfie and ID match</Trans>
            </Button>
          </div>
        </div>
      </FadeAnimation>
    </>
  );
};

export default CompareIDandSelfie;
