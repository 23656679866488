import React, { createRef } from "react";
import cx from "classnames";
import { AnalyticsCompleted, FilteredName } from "../../../data/dataStatistics";
import { DynamicOfType, Status } from "../../../data/types";
import { Language } from "../../../i18n";
import { getIntlDate } from "../../../components/utils";
import "./CompletedContractsGraph.scss";

interface Props {
  from: Date;
  to: Date;
  contracts: AnalyticsCompleted[];
  filteredName: FilteredName;
  width: number;
  language: Language;
}

interface Area {
  w: number;
  h: number;
}

function getScalesAndOffsets(
  filteredName: FilteredName,
  contracts: AnalyticsCompleted[],
  area: Area,
  from: Date,
  to: Date
) {
  if (filteredName === FilteredName.DATE) {
    const width = 10;
    const height = 10;
    const rx = height / 2;
    const xScale = window.d3.scaleUtc().range([0, area.w]).domain([from, to]);
    const noOfTicks = Math.floor(area.w / 200);
    return {
      width,
      height,
      rx,
      y: () => area.h - 2 * height,
      x: (d: Date) => -rx + xScale(d),
      keys: [],
      xTicks: xScale.ticks(noOfTicks),
      yTicks: [],
    };
  }

  const xScale = window.d3.scaleBand().range([0, area.w]).paddingInner(0.4);
  const ids: DynamicOfType<number> = {};
  let key: keyof Omit<AnalyticsCompleted, "date">;

  if (filteredName === FilteredName.BUNDLE_ID) {
    key = "bundleId";
  } else if (filteredName === FilteredName.PRICING_STEP) {
    key = "pricingStep";
  } else if (filteredName === FilteredName.TEAM_ID) {
    key = "team";
  }

  contracts.forEach((contract: AnalyticsCompleted) => {
    if (!ids[contract[key]]) {
      ids[contract[key]] = 1;
    } else {
      ids[contract[key]] = ids[contract[key]] + 1;
    }
  });

  const yMax = Math.max(...Object.values(ids));
  const yScale = window.d3.scaleLinear().range([area.h, 0]).domain([0, yMax]);

  const keys = Object.keys(ids);
  xScale.domain(keys);

  return {
    x: xScale,
    y: yScale,
    width: xScale.bandwidth(),
    height: area.h / yMax,
    rx: 0,
    keys,
    xTicks: [],
    yTicks: yScale.ticks(yMax > 4 ? 3 : 2),
  };
}

interface State {
  status: Status;
}

export class CompletedContractsGraph extends React.Component<Props, State> {
  plot = createRef<HTMLDivElement>();
  wrapper = createRef<HTMLDivElement>();
  d3Plot: any = null;
  area: Area = { w: 0, h: 0 };

  constructor(props: Props) {
    super(props);
    this.state = {
      status: Status.PENDING,
    };
  }

  componentDidMount() {
    if (this.plot?.current) {
      this.d3Plot = window.d3.select(this.plot.current.firstChild);
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    if (!this.plot.current) {
      return false;
    }

    if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
      this.draw({ ...nextProps });
    }

    return false;
  }

  draw = (props: Props) => {
    if (!this.plot.current) {
      return;
    }

    this.wrapper.current?.classList.add(Status.PENDING);
    const fromTag = document.getElementById("historical-contracts-from-tag");
    const toTag = document.getElementById("historical-contracts-to-tag");
    const amountTag = document.getElementById("historical-contracts-amount-tag");
    if (fromTag) {
      fromTag.textContent = getIntlDate(this.props.language, this.props.from);
    }
    if (toTag) {
      toTag.textContent = getIntlDate(this.props.language, this.props.to);
    }
    if (amountTag) {
      amountTag.textContent = `${props.contracts.length}`;
    }

    const { contracts, filteredName, from, to } = props;

    const box = this.plot.current.getBoundingClientRect();
    this.area = {
      w: box.width,
      h: box.height,
    };

    contracts.sort((a: AnalyticsCompleted, b: AnalyticsCompleted) => a.bundleId.localeCompare(b.bundleId));

    const items = this.d3Plot.selectAll("rect").data(contracts, (d: AnalyticsCompleted) => d.contractId);

    const scales = getScalesAndOffsets(filteredName, contracts, this.area, from, to);

    const yTicks = this.d3Plot.selectAll(".y-ticks").data([]);
    yTicks.exit().transition().duration(1000).style("opacity", 0).remove();

    const xTicks = this.d3Plot.selectAll(".x-ticks").data([]);
    xTicks.exit().transition().duration(1000).style("opacity", 0).remove();

    const exitSet = items.exit();
    exitSet.transition().duration(1000).style("opacity", 0).remove();

    const enterSet = items.enter().append("rect");
    enterSet
      .attr("width", 0)
      .attr("height", 0)
      .attr("x", this.area.w / 2)
      .attr("y", this.area.h / 2)
      .attr("class", (d: AnalyticsCompleted) => d.bundleId);

    const mergedSet = enterSet.merge(items);

    let key: keyof Omit<AnalyticsCompleted, "date">;

    if (filteredName === FilteredName.DATE) {
      this.wrapper.current?.classList.add("filtered-name-date");
      mergedSet
        .transition()
        .duration(1000)
        .attr("x", (d: AnalyticsCompleted) => scales.x(new Date(d.date)))
        .attr("y", scales.y())
        .attr("rx", scales.rx)
        .attr("width", scales.width)
        .attr("height", scales.height)
        .on("end", () => {
          this.wrapper.current?.classList.remove(Status.PENDING);
          const xTicks = this.d3Plot.selectAll(".x-ticks").data(scales.xTicks);
          xTicks
            .enter()
            .append("text")
            .style("opacity", 0)
            .attr("class", "x-ticks")
            .attr("x", (d: AnalyticsCompleted) => scales.x(d))
            .attr("y", this.area.h + 30)
            .text((d: Date) => getIntlDate(props.language, d))
            .transition()
            .duration(500)
            .style("opacity", 1);
        });

      return;
    }

    this.wrapper.current?.classList.remove("filtered-name-date");

    if (filteredName === FilteredName.TEAM_ID) {
      key = "team";
    } else if (filteredName === FilteredName.PRICING_STEP) {
      key = "pricingStep";
    } else {
      key = "bundleId";
    }

    const barOffsets: any = {};
    let noOfTransitions = 0;
    mergedSet
      .transition()
      .duration(1000)
      .attr("x", (d: AnalyticsCompleted) => scales.x(d[key]))
      .attr("y", (d: AnalyticsCompleted) => {
        if (!barOffsets[d[key]] && barOffsets[d[key]] !== 0) {
          barOffsets[d[key]] = 0;
        } else {
          barOffsets[d[key]] = barOffsets[d[key]] + 1;
        }

        const barIndex = barOffsets[d[key]];

        return this.area.h - scales.height - scales.height * barIndex;
      })
      .attr("rx", scales.rx)
      .attr("width", scales.width)
      .attr("height", scales.height)
      .on("start", () => noOfTransitions++)
      .on("end", () => {
        noOfTransitions--;
        if (noOfTransitions !== 0) {
          return;
        }
        this.wrapper.current?.classList.remove(Status.PENDING);
        const xTicks = this.d3Plot.selectAll(".x-ticks").data(scales.keys);
        const yTicks = this.d3Plot.selectAll(".y-ticks").data(scales.yTicks);
        const barCenter = scales.width / 2;

        const enteredXTicks = xTicks.enter().append("text").style("opacity", 0).attr("class", "x-ticks");

        const mergedXTicks = enteredXTicks.merge(xTicks);

        mergedXTicks
          .attr("x", (d: AnalyticsCompleted) => barCenter + scales.x(d))
          .attr("y", this.area.h + 30)
          .text((d: string) => d);

        enteredXTicks.transition().duration(500).style("opacity", 1);

        const enteredYTicks = yTicks.enter().append("g").style("opacity", 0).attr("class", "y-ticks");

        enteredYTicks.append("rect").attr("rx", 16).attr("width", 32).attr("height", 32);

        enteredYTicks.append("text");

        const mergedYTicks = enteredYTicks.merge(yTicks);

        mergedYTicks
          .select("rect")
          .attr("x", this.area.w - 12)
          .attr("y", (d: AnalyticsCompleted) => -16 + scales.y(d));

        mergedYTicks
          .select("text")
          .attr("x", this.area.w + 4)
          .attr("y", (d: AnalyticsCompleted) => 4 + scales.y(d))
          .text((d: string) => d);

        enteredYTicks.transition().duration(500).style("opacity", 1);
      });
  };

  render() {
    return (
      <div className="historical-contracts-wrapper" ref={this.wrapper}>
        <b id="historical-contracts-amount-tag"></b> contracts was sent to onboarding between{" "}
        <i id="historical-contracts-from-tag">{getIntlDate(this.props.language, this.props.from)}</i> and{" "}
        <i id="historical-contracts-to-tag">{getIntlDate(this.props.language, this.props.to)}</i>
        <div className="historical-contracts-legends">
          <div className="historical-contracts-legend">
            <div className="DESK_BUNDLE" /> Desk bundle
          </div>
          <div className="historical-contracts-legend">
            <div className="MOVE_BUNDLE" /> Move bundle
          </div>
          <div className="historical-contracts-legend">
            <div className="SOFTPOS_BUNDLE" /> SoftPos bundle
          </div>
        </div>
        <div className="fs-small no-of-contracts">
          <i>Number of contracts</i>
        </div>
        <div className={cx("historical-contracts-graph", this.state.status)} ref={this.plot}>
          <svg />
        </div>
      </div>
    );
  }
}
