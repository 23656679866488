import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { contractAssociateState } from "../../../../state/contractAssociateState";
import { contractBankState } from "../../../../state/contractBankState";
import { contractMainContractDataState } from "../../../../state/contractMainContractDataState";
import { getHolderName } from "./ConfirmBank";
import { BankAccount, BankAccountSource, MainContractData } from "../../../../model/contract/contractType";
import { IdImage } from "../../../Contract/uploads/IdImage";
import { FileType } from "../../../../components/fileUpload/FileUpload";
import { Status } from "../../../../data/types";
import { ZoomableImage } from "../../../../components/images/ZoomableImage";
import { API } from "../../../../network/API";
import { contractStatusState } from "../../../../state/contractStatusState";
import { InfoBox } from "../../../../components/boxes/InfoBox";

function getText(bankAccount: BankAccount) {
  if (bankAccount.source === BankAccountSource.OFFICIAL_REGISTRY) {
    return "This bank account was provided from the registry";
  }

  if (bankAccount.source === BankAccountSource.PSD_LOOKUP) {
    return "This bank account was provided by the merchant using Klarna";
  }

  return "This bank account was verified by a provided bank statement";
}

function getHolderElem(holder: string, mainData: MainContractData, t: any) {
  if (!holder) {
    return (
      <p className="color-warning-heavy fw-500">
        <b>{t("Heads up")} ✋</b>,<br />
        {t("we couldn't find a matching account holder to this bank account. ")}
      </p>
    );
  }

  if (holder === mainData.companyName) {
    return (
      <p className="fw-500 color-success-text">
        {t("Account holder is matching company name")} - <i>{holder}</i>
      </p>
    );
  }

  return (
    <p className="fw-500 color-success-text">
      {t("Account holder is matching owner")} <i>{holder}</i>
    </p>
  );
}

export const BankConfirmed: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const contractState = useRecoilValue(contractStatusState);
  const associates = useRecoilValue(contractAssociateState);
  const mainData = useRecoilValue(contractMainContractDataState);
  const bankAccount = useRecoilValue(contractBankState);

  const holder = getHolderName(mainData, associates, bankAccount.accountHolder);

  const url = API.getUrl(`/api/sales/merchant/${contractState.contractId}/bank`);

  return (
    <div className="confirm-bank">
      <h3>{t("Bank account")}</h3>

      <InfoBox className="m-bottom-20">{t(getText(bankAccount))}</InfoBox>

      <dl className="m-bottom-20">
        <dt>{t("IBAN")}:</dt>
        <dd>{bankAccount.iban}</dd>
        <dt>{t("Bank")}:</dt>
        <dd>{bankAccount.bank}</dd>
        <dt>{t("Account holder")}:</dt>
        <dd>{holder || "-"}</dd>
      </dl>

      {getHolderElem(holder || "", mainData, t)}

      {bankAccount.statementUploaded !== undefined &&
        (bankAccount.mimeType === "application/pdf" ? (
          <IdImage
            status={Status.DEFAULT}
            image={{
              url: url,
              type: FileType.PDF,
            }}
            isGeneric={true}
          />
        ) : (
          <ZoomableImage alt="Bank Statement" image={url} />
        ))}
    </div>
  );
};
