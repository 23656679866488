import { CSSProperties } from "react";
import { FiGlobe } from "react-icons/fi";

interface Props {
  className?: string;
  style?: CSSProperties;
}

export const EcomIcon = ({ className, style }: Props) => {
  return <FiGlobe className={className} style={{ fontSize: "1.1em", ...style }} />;
};
