import {
  EditablePricingTemplate,
  PricingModel,
  PricingStructure,
  PricingTemplate,
} from "../model/pricing/pricingTypes";
import { PricingSettings, Store, STORE_KEY } from "../Store";
import { atom, selector } from "recoil";
import { ContractId } from "../model/common/commonType";

import { ContractType } from "../model/contract/contractType";
import {
  INITIAL_TARIFF_CLASS_MAPPING,
  INITIAL_DCC_CLASS,
  DEFAULT_OPTIONAL_FEES,
  DEFAULT_TRANSACTION_FEES,
} from "../model/pricing/pricingUtils";

// To know if a tempalte ID is mocked.
export const TEMPLATE_ID_MOCK = -1;

// in theory should never be used since real data gets loaded from BE
const INITIAL_PRICING = {
  templateId: TEMPLATE_ID_MOCK,
  pricingModel: PricingModel.BLENDED,
  promotionMonths: 0,
  installationFeeValue: 0,
  acceptance: true,

  cashlessPromotion: false,
  installationFee: undefined,
  view: Store.getValue<PricingSettings>(STORE_KEY.STORE_PRICING_SETTINGS).view,

  chargebackFee: 0,
  surchargesCommercialCards: 0,
  surchargesNonEea: 0,
  preAuthorizationFees: true,

  conditionCategoryCode: 0,
  contractCategory: 0,
  contractTemplateName: "",
  tariffClassMappings: INITIAL_TARIFF_CLASS_MAPPING,

  steppedBasedPricing: undefined,
  simplePricing: false,
  cashlessPromotionIsPreSelected: false,

  dcc: [INITIAL_DCC_CLASS],
  contractType: ContractType.INSTORE,
};

export const INITIAL_EDITABLE_PRICING_TEMPLATE: EditablePricingTemplate = {
  ...INITIAL_PRICING,
  transactionFees: [...DEFAULT_OPTIONAL_FEES],
};

// Initial state when no pricing has been selected for a contract yet
const INITIAL_CONTRACT_PRICING_INSTORE: PricingStructure = {
  ...INITIAL_PRICING,
  pricingStructureId: 0,
  name: "dummy instore pricing structure",
  contractId: "" as ContractId,
  customizedBy: "",
  customized: false,
  created: new Date(),
  cas: 0,
  transactionFees: [...DEFAULT_TRANSACTION_FEES],
  cashlessPromotionIsPreSelected: false,
  contractType: ContractType.INSTORE,
  dcc: INITIAL_DCC_CLASS,
  installedByTechnician: false,
};

const INITIAL_CONTRACT_PRICING_ECOM: PricingStructure = {
  ...INITIAL_PRICING,
  pricingStructureId: 0,
  name: "dummy ecommerce pricing structure",
  contractId: "" as ContractId,
  customizedBy: "",
  customized: false,
  created: new Date(),
  cas: 0,
  transactionFees: [...DEFAULT_TRANSACTION_FEES],
  cashlessPromotionIsPreSelected: false,
  contractType: ContractType.ECOMMERCE,
  dcc: INITIAL_DCC_CLASS,
  installedByTechnician: false,
};

export type ContractPricingState = Record<ContractType, PricingStructure>;

export const INITIAL_CONTRACT_PRICING_STATE: ContractPricingState = {
  [ContractType.INSTORE]: INITIAL_CONTRACT_PRICING_INSTORE,
  [ContractType.ECOMMERCE]: INITIAL_CONTRACT_PRICING_ECOM,
};

// used to be of type PricingRepresentation
// state representing what pricing a contract has
export const contractPricingState = atom<ContractPricingState>({
  key: "contract-cost-state",
  default: INITIAL_CONTRACT_PRICING_STATE,
});

// all available pricing templates
export const availablePricingTemplatesState = atom<PricingTemplate[]>({
  key: "contract-pricing-template-state",
  default: [],
});

export const pricingBlendedSelector = selector({
  key: "pricing-blended-selector",
  get: ({ get }) => {
    return get(availablePricingTemplatesState).filter((item) => item.pricingModel === PricingModel.BLENDED);
  },
});

export const pricingIcppSelector = selector({
  key: "pricing-icpp-selector",
  get: ({ get }) => {
    return get(availablePricingTemplatesState).filter((item) => item.pricingModel === PricingModel.ICPP);
  },
});

export const pricingPackSelector = selector({
  key: "pricing-pack-selector",
  get: ({ get }) => {
    return get(availablePricingTemplatesState).filter((item) => item.pricingModel === PricingModel.PACK);
  },
});

export const selectedPricingTemplates = selector({
  key: "pricing-selected-template-is-active",
  get: ({ get }) => {
    const templates = get(availablePricingTemplatesState);
    const cost = get(contractPricingState);

    const instoreTemplate = templates.find(
      (template) => template.templateId === cost[ContractType.INSTORE].templateId
    );
    const ecomTemplate = templates.find(
      (template) => template.templateId === cost[ContractType.ECOMMERCE].templateId
    );

    return {
      [ContractType.ECOMMERCE]: ecomTemplate,
      [ContractType.INSTORE]: instoreTemplate,
    };
  },
});
