import { useState, useCallback, useRef, FC } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { Minus } from "../../../../../../components/icons/Minus";
import { Plus } from "../../../../../../components/icons/Plus";
import { Button } from "../../../../../../components/interactions/Buttons/Button";
import { dataContracts } from "../../../../../../data/dataContracts";
import { ONGOING_RESPONSE } from "../../../../../../data/queues/QueueTypes";
import { Store2 as StoreInterface } from "../../../../../../model/contract/contractType";
import { Terminal, TerminalType } from "../../../../../../model/contract/contractType";
import { storeQueue } from "../../../../../../data/queues/StoreQueue";
import { Status } from "../../../../../../data/types";
import { ServerError } from "../../../../../../network/API";
import { contractSaveState, contractErrorState } from "../../../../../../state/contractSaveState";
import { contractStatusState } from "../../../../../../state/contractStatusState";
import { contractStoresState } from "../../../../../../state/contractStoresState";
import { ContractEditError } from "../../../../ContractEditError";
import { ContractSaveError, handleError } from "../../../../ContractPage";
import { BiStore } from "react-icons/bi";

import styles from "./StoreAddTerminals.module.scss";
import { TerminalConfigurationOptions } from "../../../../../../data/dataTerminalPricing";
import cx from "classnames";
import {
  TerminalsByType,
  generateNewTerminal,
  getTerminalEntityType,
  getTerminalTypeString,
} from "../../../../../../model/terminal/terminalUtils";
import { getStoreAddress } from "../../../../../../model/contract/contractUtils";

interface Props {
  store: StoreInterface;
  terminalConfigOptions: TerminalConfigurationOptions[];
  maxUniqueTerminals: boolean;
  maxTerminalLimitation: boolean;
  groupedTerminals: TerminalsByType[];
}

export const StoreAddTerminals: FC<Props> = ({
  store,
  terminalConfigOptions,
  maxUniqueTerminals,
  maxTerminalLimitation,
  groupedTerminals,
}) => {
  const setStores = useSetRecoilState(contractStoresState);
  const { contractId, edit } = useRecoilValue(contractStatusState);
  const [, setStatus] = useState<Status>(Status.DEFAULT);
  const [error, setError] = useState<ContractSaveError | null>(null);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);
  const lastTriedStoreSave = useRef<StoreInterface>();

  const deviceToggleTerminals = getDeviceToggleTerminals(terminalConfigOptions, groupedTerminals);

  const onClose = useCallback(() => {
    setError(null);
  }, []);

  const saveCallback = useCallback(
    (err: ServerError<StoreInterface> | null | typeof ONGOING_RESPONSE, response?: StoreInterface[]) => {
      if (err === ONGOING_RESPONSE) {
        return;
      }

      setStatus(Status.DEFAULT);

      if (err) {
        handleError(err, setError);
        setDataError((dataErrors) =>
          dataErrors.concat({
            date: new Date(),
          })
        );
        return;
      }

      if (response) {
        setStores(response);
        setDataSaved((dataSaved) =>
          dataSaved.concat({
            date: new Date(),
          })
        );
      }
    },
    [setDataSaved, setDataError, setStores]
  );

  const onSave = useCallback(
    (store: StoreInterface) => {
      lastTriedStoreSave.current = store;
      storeQueue.saveStore(contractId, store, saveCallback);
    },
    [contractId, saveCallback]
  );

  const onAdd = useCallback(
    (terminalType: TerminalType) => {
      const articles = terminalConfigOptions.find((config) => config.terminalType === terminalType)?.articles;
      const updatedStore = {
        ...store,
        terminals: store.terminals.concat(generateNewTerminal(store.storeId, terminalType, articles)),
      };
      onSave(updatedStore);
    },
    [store, onSave, terminalConfigOptions]
  );

  const onRemove = useCallback(
    (terminalType: TerminalType) => {
      const index = store.terminals.findIndex((terminal) => terminal.terminalType === terminalType);

      const terminals = [...store.terminals];

      if (index > -1) {
        terminals.splice(index, 1);
        onSave({
          ...store,
          terminals,
        });
      }
    },
    [store, onSave]
  );

  const trySave = useCallback(() => {
    if (lastTriedStoreSave.current) {
      onSave(lastTriedStoreSave.current);
    }
  }, [onSave]);

  const retry = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(trySave, 500);
  }, [trySave]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataContracts.claimContract(contractId).then(trySave).catch(trySave); // We're lazy, execute save again, which will fail,
      // and propagate error to Overlay
    }, 500);
  }, [contractId, trySave]);

  const status = edit ? Status.DEFAULT : Status.DISABLED;

  return (
    <div className={styles.root}>
      <ContractEditError
        error={error}
        setError={setError}
        retry={retry}
        onClose={onClose}
        reclaimAndSave={reclaimAndSave}
      />

      <div className={styles["store-info"]}>
        <BiStore size={24} className="text-passive" />
        <div className={styles.store_data}>
          <div className="fw-600">{store?.commercialName ? store.commercialName : "No name set"}</div>
          <div className="fw-500 fs-small text-passive">{getStoreAddress(store)}</div>
        </div>
      </div>

      <ul className={styles["device-toggle-list"]}>
        {deviceToggleTerminals.map(({ terminalType, noLongerAvailable }) => (
          <DeviceToggle
            key={`${terminalType}_toggle`}
            onAdd={onAdd}
            onRemove={onRemove}
            terminalType={terminalType}
            noLongerAvailable={noLongerAvailable}
            deviceAmount={getDeviceAmount(terminalType, store.terminals)}
            status={status}
            isDisabled={
              maxTerminalLimitation ||
              (maxUniqueTerminals &&
                !groupedTerminals.some((terminal) => terminal.terminalType === terminalType))
            }
          />
        ))}
      </ul>
    </div>
  );
};

type DeviceToggleTerminal = {
  terminalType: TerminalType;
  // If the terminal is present on the contract but not on the country's available terminals.
  // Will only happen if we remove a terminal type from tue country but contract has said terminal
  noLongerAvailable: boolean;
};

const getDeviceToggleTerminals = (
  terminalConfigOptions: TerminalConfigurationOptions[],
  groupedTerminals: TerminalsByType[]
): DeviceToggleTerminal[] => {
  //
  const countryTerminals: Set<TerminalType> = new Set(
    terminalConfigOptions.map((terminal) => terminal.terminalType)
  );

  const baseTerminals: DeviceToggleTerminal[] = terminalConfigOptions.map((terminal) => ({
    terminalType: terminal.terminalType,
    noLongerAvailable: false,
  }));

  const noLongerAvialable: DeviceToggleTerminal[] = groupedTerminals
    .filter((group) => !countryTerminals.has(group.terminalType))
    .map((filteredGroups) => ({ terminalType: filteredGroups.terminalType, noLongerAvailable: true }));

  return [...baseTerminals, ...noLongerAvialable];
};

const getDeviceAmount = (terminalType: TerminalType, storeTerminals: Terminal[]) => {
  return storeTerminals.filter((terminal) => terminal.terminalType === terminalType).length;
};

interface DeviceToggleProps extends DeviceToggleTerminal {
  deviceAmount: number;
  onAdd: (terminalType: TerminalType) => void;
  onRemove: (terminalType: TerminalType) => void;
  status: Status;
  isDisabled: boolean;
}

const DeviceToggle: FC<DeviceToggleProps> = ({
  terminalType,
  noLongerAvailable,
  onAdd,
  onRemove,
  status,
  deviceAmount,
  isDisabled,
}) => {
  return (
    <li className={styles["device-toggle"]}>
      <div
        className={cx([styles["terminal-amount"]], {
          [styles.selected]: deviceAmount > 0,
        })}
      >
        <div className={styles["terminal-type-text"]}>{getTerminalTypeString(terminalType)}:</div>
        <div>{deviceAmount}</div>
      </div>

      <div
        className={cx([styles["terminal-add"]], {
          [styles.selected]: deviceAmount > 0,
        })}
      >
        <Button
          type="button"
          status={deviceAmount === 0 ? Status.DISABLED : status}
          action
          onClick={() => {
            onRemove(terminalType);
          }}
          className="mini danger-button"
        >
          <Minus />
        </Button>

        <div className={styles["desktop-add-terminal"]}>
          <div>{getTerminalEntityType(terminalType)}</div>
        </div>

        <div className={styles["mobile-add-terminal"]}>
          <div className={styles["terminal-type-text"]}>{getTerminalTypeString(terminalType)}:</div>
          <div>{deviceAmount}</div>
        </div>

        <Button
          type="button"
          status={isDisabled || noLongerAvailable ? Status.DISABLED : status}
          action
          onClick={() => {
            onAdd(terminalType);
          }}
          className="mini "
        >
          <Plus />
        </Button>
      </div>
    </li>
  );
};
