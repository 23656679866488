import { FC } from "react";
import styles from "./NewPagination.module.scss";
import { Button } from "../interactions/Buttons/Button";
import { ArrowLeftIcon } from "../icons/ArrowLeftIcon";
import { ArrowRightIcon } from "../icons/ArrowRightIcon";
import { useTranslation } from "react-i18next";
import { Status } from "../../data/types";
import PaginationButton from "./PaginationButton";
import DynamicPaginationButtons from "./DynamicPaginationButtons";

interface Props {
  activePage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  className?: string;
  alwaysShow?: boolean;
  paginationsPerSide?: 3 | 4 | 5;
}

export const NewPagination: FC<Props> = ({
  activePage,
  totalPages,
  onPageChange,
  paginationsPerSide = 4,
}) => {
  const { t } = useTranslation();
  const activePageNumber = activePage + 1;

  const renderPaginationButtons = () => {
    if (totalPages === 0) {
      return <PaginationButton onPageChange={onPageChange} pageNumber={1} isSelected={true} />;
    }

    if (totalPages < 10) {
      return Array.from(Array(totalPages).keys()).map((page) => (
        <PaginationButton
          key={page}
          onPageChange={onPageChange}
          pageNumber={page + 1}
          isSelected={page === activePage}
        />
      ));
    }

    return (
      <DynamicPaginationButtons
        onPageChange={onPageChange}
        activePageNumber={activePageNumber}
        lastPageNumber={totalPages}
        paginationsPerSide={paginationsPerSide}
      />
    );
  };

  return (
    <nav className={styles.root}>
      <Button
        onClick={() => onPageChange(activePage - 1)}
        variant="text"
        className={styles.navigation_button}
        status={activePageNumber === 1 ? Status.DISABLED : Status.DEFAULT}
      >
        <ArrowLeftIcon /> {t("Prev page")}
      </Button>
      <ul className={styles.pages}>{renderPaginationButtons()}</ul>
      <Button
        onClick={() => onPageChange(activePage + 1)}
        variant="text"
        className={styles.navigation_button}
        status={activePageNumber === totalPages ? Status.DISABLED : Status.DEFAULT}
      >
        {t("Next page")} <ArrowRightIcon />
      </Button>
    </nav>
  );
};
