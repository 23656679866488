import { useCallback, useState } from "react";
import { dataLocationLookup } from "../../data/dataLocationLookup";
import { ValidationProps } from "../form/hooks/useValidation";
import { Alternative } from "../interactions/InputTypes";
import { Searchable } from "../Searchable/Searchable";
import { useDebounceFn } from "../../hooks/useDebounce";
import { Country } from "../../model/common/commonType";
import { Address } from "../../model/contract/contractType";

interface Props extends ValidationProps {
  onChange: (address: Partial<Address>) => void;
  disabled?: boolean;
  label?: string;
  country: Country;
}

// const getStreetRow = ({ street, streetNumber }: { street?: string; streetNumber?: string }) => {
//   if (street && streetNumber) {
//     return `${street} ${streetNumber}`;
//   } else if (street) {
//     return street;
//   } else {
//     return undefined;
//   }
// };

export const AddressSearch: React.FunctionComponent<Props> = ({
  onChange,
  label = "Search address",
  country,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Alternative<string>[]>([]);

  const search = useCallback(
    (value: string) => {
      if (value.length < 2) return;

      dataLocationLookup.getSuggestions(value, [country]).then((suggestions) => {
        setSuggestions(suggestions.map(({ text }) => ({ text, value: text })));
      });
    },
    [country]
  );

  const debounceSearch = useDebounceFn(search, 500);

  const select = useCallback(
    (value: string) => {
      dataLocationLookup.getLocation(value, [country]).then((location) => {
        onChange({
          street: location.street,
          streetNumber: location.streetNumber,
          city: location.municipality,
          postalCode: location.postalCode,
          countryCode: location.country,
        });
        setSearchValue("");
        setSuggestions([]);
      });
    },
    [onChange, country]
  );

  return (
    <div className="address-search">
      <Searchable
        {...props}
        onChange={(value) => {
          setSearchValue(value);
          debounceSearch(searchValue);
        }}
        onSelect={select}
        alternatives={suggestions}
        value={searchValue}
        autocomplete="off"
        placeholder="Search address..."
        {...{ label }}
      />
    </div>
  );
};
