import { FC } from "react";
import FadeAnimation from "../../../../../../../components/animate/FadeAnimation";
import { TextInput } from "../../../../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../../../../components/form/validators/RequiredValidator";
import { WebsiteValidator } from "../../../../../../../components/form/validators/WebsiteValidator";
import { Status } from "../../../../../../../data/types";
import {
  EcomTypesContentProps,
  HTTPS_PREFIX,
  addHttpsPrefix,
  removeHttpsPrefix,
} from "./EcomTypesContentUtils";

const WebsiteContent: FC<EcomTypesContentProps> = ({ onChange, store, t, edit, status }) => {
  return (
    <FadeAnimation animationKey="website-content">
      <TextInput
        onChange={(value) => onChange("ecommerceURL", addHttpsPrefix(value))}
        name="website-url"
        placeholder="website.com/"
        value={removeHttpsPrefix(store.ecommerceURL)}
        label={t("Website URL")}
        validators={[
          new RequiredValidator(t("Website URL is required")),
          new WebsiteValidator(t("URL is not valid"), true),
        ]}
        disabled={!edit || status === Status.DISABLED}
        preIcon={{
          icon: HTTPS_PREFIX,
        }}
      />
    </FadeAnimation>
  );
};

export default WebsiteContent;
