import { data } from "./data";
import { PricingStep } from "../model/contract/contractType";
import { ContractId, Country, TeamId, UserId } from "../model/common/commonType";
import { ContractStatus, PackageId } from "../model/contract/contractType";

export const TODAY = "today";

export interface OverviewStatistics {
  date: Date;
  started: number;
  sentForSigning: number;
  completedSigning: number;
  completedSignoff: number;
  completedOnboarding: number;
}

export interface Current {
  creating: number;
  waitingSigning: number;
  waitingSignoff: number;
  completedToday: number;
  onboardedToday: number;
}

export interface AnalyticsCompleted {
  date: Date; //Date of when the contract was completed, but not onboarded
  contractId: ContractId;
  bundleId: PackageId; //I know.. names are hard. First they called them packages, now they are bundles
  pricingStep: PricingStep;
  team: string; //Name of the team that completed the contract
  teamId: TeamId;
  user: string; //Name of the user that completed the contract
  userId: UserId;
  hoursToSign: number; //How many hours it took to completly sign the contract
}

export enum FilteredName {
  DATE = "date",
  BUNDLE_ID = "bundle",
  PRICING_STEP = "pricing",
  TEAM_ID = "team",
  USER_ID = "user",
  HOURS_TO_SIGN = "hours-to-sign",
}

export interface AnalyticsFilter {
  from: string;
  to: string | typeof TODAY;
  status?: ContractStatus;
  country?: Country;
  teamId?: TeamId;
  filteredName: FilteredName;
}

export const dataStatistics = {
  // getStatistics: () => data.get("/test/statistics.json"),

  //Optional query params:  Country & teamId
  //Om admin är sales admin ignoreras country
  getAdminCurrent: (query: string) => data.get<Current>(`/api/admin/statistics/current${query}`),

  //Required query params:  from & to, dates
  getAdminHistorical: (query: string) =>
    data.get<OverviewStatistics[]>(`/api/admin/statistics/historic/overview${query}`),

  getAdminCompleted: (query: string) =>
    data.get<AnalyticsCompleted[]>(`/api/admin/statistics/historic/completed${query}`),
  /*********/

  getSalesCurrent: () => data.get<Current>(`/api/sales/statistics/current`),

  //Required query params:  from & to, dates
  getSalesHistorical: (query: string) =>
    data.get<OverviewStatistics[]>(`/api/sales/statistics/historic/overview${query}`),

  //Required query params:  from & to, dates
  getSalesCompleted: (query: string) =>
    data.get<AnalyticsCompleted[]>(`/api/sales/statistics/historic/completed${query}`),
};
