import { ContractId } from "../model/common/commonType";
import { data } from "./data";

const BASE_URL = "/api/dev";

export const fillContract = (): Promise<ContractId> => {
  return data.post<ContractId>(`${BASE_URL}/fill-contract`).then(response =>{
    return response as ContractId;
  });
};

export const fillMerchant = (): Promise<ContractId> => {
  return data.post<ContractId>(`${BASE_URL}/fill-merchant`).then(response =>{
    return response as ContractId;
  });
};

export const fillAfterMerchant = (): Promise<ContractId> => {
  return data.post<ContractId>(`${BASE_URL}/fill-after-merchant`).then(response =>{
    return response as ContractId;
  });
};

export const fillUntilCompleted = (): Promise<ContractId> => {
  return data.post<ContractId>(`${BASE_URL}/fill-until-completed`).then(response =>{
    return response as ContractId;
  });
};

export const deleteAllDevCompany = (): Promise<void> => {
  return data.post<void>(`${BASE_URL}/delete-all-by-country`);
};

export const continueToCompletedFromContract = (contractId: ContractId): Promise<void> => {
  return data.post(`${BASE_URL}/continue-to-completed-from-contract/${contractId}`);
};
