import { useState, useCallback, useMemo, useRef } from "react";
import { TFunction, Trans, useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { Form } from "../../../components/form/Form";
import { HiddenInput } from "../../../components/form/HiddenInput";
import { Select } from "../../../components/form/Select";
import { InvalidValuesValidator } from "../../../components/form/validators/InvalidValuesValidator";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Button } from "../../../components/interactions/Buttons/Button";
import { ScrollPositionAnchor } from "../../../components/scrollPosition/ScrollPositionAnchor";
import { getLatestValueByKey } from "../../../components/utils";
import { dataContracts } from "../../../data/dataContracts";
import { Associate, AssociateRole } from "../../../model/associate/associateTypes";
import { ONGOING_RESPONSE } from "../../../data/queues/QueueTypes";
import { AssociateId, Cas } from "../../../model/common/commonType";
import { BeneficialOwnerType } from "../../../model/contract/contractType";
import { associateQueue, SaveType } from "../../../data/queues/AssociateQueue";
import { Status } from "../../../data/types";
import { contractAssociateState, sortedAssociatesSelector } from "../../../state/contractAssociateState";
import { contractMainContractDataState } from "../../../state/contractMainContractDataState";
import { contractErrorState, contractSaveState } from "../../../state/contractSaveState";
import { ContractStatusState, contractStatusState } from "../../../state/contractStatusState";
import { LinkAnchors } from "../ContractEdit";
import { ContractEditError } from "../ContractEditError";
import { ContractSaveError, getEditStatus, handleError } from "../ContractPage";
import { FormName } from "../menus/ContractEditMenu";
import "./Owners.scss";
import { OwnersOverlay } from "./OwnersOverlay";
import { SectionFieldSet } from "../../../components/sectionFieldSet/SectionFieldSet";
import { TipBox } from "../../../components/boxes/TipBox";
import { beneficialOwnerTypeDisplay } from "../../../model/contract/contractUtils";

const DUMMY_ASSOCIATE_ID = "aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee" as AssociateId;

function getLegalFormOpts(t: TFunction<"translation">) {
  const legalList = [];
  for (let item in BeneficialOwnerType) {
    legalList.push({
      value: item,
      text: t(beneficialOwnerTypeDisplay(item as BeneficialOwnerType)),
      disabled: false,
    });
  }

  legalList.unshift({
    value: "",
    text: t("Select owner type"),
    disabled: true,
  });

  return legalList;
}

function getNewOwner(associates: Associate[]) {
  return getLatestValueByKey("created", associates);
}

function getSelectStatus(
  contractStatus: ContractStatusState,
  owners: Associate[],
  ownerType?: BeneficialOwnerType
) {
  if (!contractStatus.edit) {
    return Status.DISABLED;
  }

  if (owners.length) {
    return Status.DISABLED;
  }

  if (
    ownerType === BeneficialOwnerType.FIDUCIAL_OR_TRUST ||
    ownerType === BeneficialOwnerType.FOUNDATION_OR_OTHER
  ) {
    return Status.ERROR;
  }

  return Status.DEFAULT;
}

export function Owners() {
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const contractStatus = useRecoilValue(contractStatusState);
  const setAssociates = useSetRecoilState(contractAssociateState);
  const associates = useRecoilValue(sortedAssociatesSelector);
  const [error, setError] = useState<ContractSaveError | null>(null);
  const [mainData, setMainData] = useRecoilState(contractMainContractDataState);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);
  const [activeAssociate, setActiveAssociate] = useState<Associate | null>(null);
  const ref = useRef<HTMLFormElement>(null);

  const owners = useMemo(
    () => associates.filter((person) => person.roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1),
    [associates]
  );

  const legalFormOpts = useMemo(() => getLegalFormOpts(t), [t]);

  const onClose = useCallback(() => {
    setError(null);
    setActiveAssociate(null);
  }, []);

  const saveCallback = useCallback(
    (err, response, wasAdded?) => {
      if (err === ONGOING_RESPONSE) {
        return;
      }

      setStatus(Status.DEFAULT);

      if (err) {
        handleError(err, setError);
        setDataError((dataErrors) =>
          dataErrors.concat({
            date: new Date(),
          })
        );
        return;
      }

      setAssociates(response);

      if (wasAdded) {
        const newOwner = getNewOwner(response);
        setActiveAssociate(newOwner);
      }

      setDataSaved((dataSaved) =>
        dataSaved.concat({
          date: new Date(),
        })
      );
    },
    [setDataSaved, setDataError, setAssociates]
  );

  const onChange = useCallback(
    (value) => {
      const copy = {
        ...mainData,
        beneficialOwnerType: value,
      };

      setMainData(copy);
      dataContracts
        .saveMainContractData(contractStatus.contractId, copy)
        .then((data) => {
          setStatus(Status.DEFAULT);
          setMainData({ ...copy, cas: data.cas });
          setDataSaved((dataSaved) =>
            dataSaved.concat({
              date: new Date(),
            })
          );
        })
        .catch((err) => {
          saveCallback(err, null);
        });
    },
    [setMainData, mainData, contractStatus.contractId, setDataSaved, saveCallback]
  );

  const onAdd = useCallback(() => {
    associateQueue.saveAssociate(
      contractStatus.contractId,
      {
        associateId: DUMMY_ASSOCIATE_ID,
        cas: 0 as Cas,
        roles: [AssociateRole.BENEFICIAL_OWNER],
      },
      SaveType.ASSOCIATE,
      (err, response) => {
        saveCallback(err, response, true);
      }
    );
  }, [contractStatus.contractId, saveCallback]);

  const retry = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(onAdd, 500);
  }, [onAdd]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataContracts.claimContract(contractStatus.contractId).then(onAdd).catch(onAdd); // We're lazy, execute save again, which will fail,
      // and propagate error to Overlay
    }, 500);
  }, [contractStatus.contractId, onAdd]);

  const selectStatus = getSelectStatus(contractStatus, owners, mainData.beneficialOwnerType);

  const isTrustOrFund = selectStatus === Status.ERROR;

  const inputStatus = useMemo(() => {
    return getEditStatus(contractStatus.edit, isTrustOrFund ? Status.DISABLED : Status.DEFAULT);
  }, [contractStatus.edit, isTrustOrFund]);

  const warningStatus = useMemo(() => {
    return getEditStatus(contractStatus.edit, mainData.beneficialOwnerType ? Status.DEFAULT : Status.ERROR);
  }, [contractStatus.edit, mainData.beneficialOwnerType]);

  const buttonStatus = useMemo(() => {
    return getEditStatus(
      contractStatus.edit,
      mainData.beneficialOwnerType && !isTrustOrFund ? status : Status.DISABLED
    );
  }, [contractStatus.edit, status, mainData.beneficialOwnerType, isTrustOrFund]);

  const onToggle = useCallback(
    (toggled, toggledAssociate: Associate) => {
      const copy = {
        ...toggledAssociate,
        roles: toggled
          ? [...toggledAssociate.roles, AssociateRole.BENEFICIAL_OWNER]
          : toggledAssociate.roles.filter((role) => role !== AssociateRole.BENEFICIAL_OWNER),
      };

      associateQueue.saveAssociate(contractStatus.contractId, copy, SaveType.ASSOCIATE, saveCallback);
    },
    [contractStatus.contractId, saveCallback]
  );

  // // Close overlay if associate was deleted
  // useEffect(() => {
  //   if (
  //     !associates.find(
  //       (person) => person.associateId === activeAssociate?.associateId
  //     )
  //   ) {
  //     onClose();
  //   }
  // }, [associates, activeAssociate, onClose]);

  const areOwnersSelected = !!associates.find(
    (associate) => associate.roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1
  );

  return (
    <div className="owners">
      <ScrollPositionAnchor id={LinkAnchors.OWNERS.anchor} />
      <SectionFieldSet headerTitle={t(LinkAnchors.OWNERS.name)} formName={FormName.OWNER}>
        <ContractEditError
          error={error}
          setError={setError}
          retry={retry}
          onClose={onClose}
          reclaimAndSave={reclaimAndSave}
        />

        <div className="beneficial-dropdown">
          <Select
            onChange={onChange}
            value={mainData.beneficialOwnerType || ""}
            alternatives={legalFormOpts}
            name="beneficialOwnerType"
            label={t("Type of owner structure")}
            validators={[
              new RequiredValidator(t("Owner structure is required")),
              new InvalidValuesValidator(
                new Set([BeneficialOwnerType.FIDUCIAL_OR_TRUST, BeneficialOwnerType.FOUNDATION_OR_OTHER]),
                t(`The selected owner structure is not yet supported`)
              ),
            ]}
            disabled={!contractStatus.edit || areOwnersSelected}
          />
          <TipBox className="m-top-10">
            <Trans>
              Current owners <b>must</b> be deselected in order to change owner structure
            </Trans>
          </TipBox>
        </div>
        {!!associates.length && <h5 className="m-bottom-20">{t("Select owners")}</h5>}
        {associates.map((associate) => (
          <OwnersOverlay
            associate={associate}
            onToggle={onToggle}
            status={inputStatus}
            key={associate.associateId}
            initialOpen={associate.associateId === activeAssociate?.associateId}
            scrollToRef={ref}
          />
        ))}

        <Form name={FormName.OWNER} ref={ref}>
          <HiddenInput
            label={t("Owner type")}
            value={!!mainData.beneficialOwnerType ? true : undefined}
            validators={[new RequiredValidator(t("Please select owner type"))]}
            scrollToRef={ref}
          />
          <HiddenInput
            label={t("Owners")}
            value={owners.length > 0 || !mainData.beneficialOwnerType ? true : undefined}
            validators={[new RequiredValidator(t("Please select at least one owner"))]}
            scrollToRef={ref}
          />
        </Form>

        {warningStatus === Status.ERROR && (
          <ErrorBox relative className="m-top-30">
            Please select owner structure before adding owners
          </ErrorBox>
        )}

        <Button onClick={onAdd} block status={buttonStatus} variant="text" className="m-top-30">
          {t("Add beneficial owner")}
        </Button>
      </SectionFieldSet>
    </div>
  );
}
