import React from "react";
import { Link } from "../../components/links/Link";
import { DASHBOARD_ROUTE } from "../Dashboard/DashboardPage";
import { PageStripped } from "../Page/PageStripped";
import "./AssessmentsPage.scss";

export const ASSESSMENTS_ROUTE = "/assessments";

export const AssessmentsPage: React.FunctionComponent = () => {
  return (
    <PageStripped title="Assessments" className="assessments">
      <section>
        <article>
          Assessments implementation
          <p>
            <Link link={DASHBOARD_ROUTE}>Go back</Link>
          </p>
        </article>
      </section>
    </PageStripped>
  );
};
