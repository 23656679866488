import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { AnimateHeight, Speed } from "../../../components/animate/AnimateHeight";
import { Form, FormContainer } from "../../../components/form/Form";
import { hasRealErrors } from "../../../components/form/FormHelpers";
import { Retry } from "../../../components/retry/Retry";
import { dataContracts } from "../../../data/dataContracts";
import { dataPricing } from "../../../data/dataPricing";
import { PricingTemplate, PricingStructure } from "../../../model/pricing/pricingTypes";
import { Status } from "../../../data/types";
import { availablePricingTemplatesState, TEMPLATE_ID_MOCK } from "../../../state/contractCostState";
import { contractStatusState } from "../../../state/contractStatusState";
import { ContractEditError } from "../ContractEditError";
import { FormName } from "../menus/ContractEditMenu";
import { LoadTemplate } from "./templates/LoadTemplate";
import { CashlessPromotion } from "./CashlessPromotion/CashlessPromotion";
import { AdditionalSettings } from "./AdditionalSettings/AdditionalSettings";
import { PricingIngress } from "./PricingIngress/PricingIngress";
import "./Pricing.scss";
import { FeeValidation } from "./FeeValidation";
import { CASHLESS_PROMOTION_COUNTRIES, convertToOptionalFee } from "../../../model/pricing/pricingUtils";
import TransactionFeeInputs from "../../Admin/Pricing/AdminPricingCountryPage/AdminPresetPage/PricingInputs/TransactionFeeInputs/TransactionFeeInputs";
import { PromotionMonths } from "./PromotionMonths/PromotionMonths";
import SteppedBasedPricingAdmin from "../../Admin/Pricing/AdminPricingCountryPage/AdminPresetPage/PricingInputs/SteppedBasedPricing/SteppedBasedPricingAdmin";
import { AnimatePresence } from "framer-motion";
import useSavePricingStructure from "./useSavePricingStructure";

import { ContractType } from "../../../model/contract/contractType";
import ContentBox from "../../../components/ContentBox/ContentBox";
import { getContractTypeDisplayString } from "../../../model/contract/contractUtils";
import styles from "./AdvancedPricing.module.scss";
import EcomTerminalSummary from "./stores/EcomSummary/EcomTerminalSummary/EcomTerminalSummary";

export const PricingEcom: React.FunctionComponent = () => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const { contractId, edit, country } = useRecoilValue(contractStatusState);
  const [templates, setTemplates] = useRecoilState(availablePricingTemplatesState);

  const { savePricingStructure, contractPricing, error, setError, setPricingView } =
    useSavePricingStructure();

  const {
    pricingModel,
    transactionFees,
    view,
    templateId: contractTemplateId,
    steppedBasedPricing,
  } = contractPricing.ECOMMERCE;

  const optionalTransactionFees = useMemo(() => transactionFees.map(convertToOptionalFee), [transactionFees]);

  const disabled = !edit || true;
  const IsNotMockId = contractTemplateId !== TEMPLATE_ID_MOCK;

  const formRef = useRef<FormContainer>();
  const transactionFeesRef = useRef<HTMLDivElement>(null);
  const terminalPricesRef = useRef<HTMLDivElement>(null);

  const fetchPricingTemplates = useCallback(
    async (clearCache: boolean = false) => {
      try {
        const templates = await dataPricing.loadTemplates(contractId, clearCache);
        const sortedTemplates = [...templates].sort(sortByPriority);
        setTemplates(sortedTemplates);
        setStatus(Status.SUCCESS);
      } catch (err) {
        console.log("Could not fetch templates: ", err);
        setStatus(Status.ERROR);
      }
    },
    [contractId, setTemplates]
  );

  useEffect(() => {
    if (typeof formRef.current === "undefined") return;
    if (contractPricing.ECOMMERCE.templateId === -1) return;
    if (!contractPricing.ECOMMERCE.pricingModel) return;

    savePricingStructure(contractPricing.ECOMMERCE);
  }, [contractPricing, savePricingStructure]);

  useEffect(() => {
    if (status !== Status.DEFAULT) return;
    setStatus(Status.PENDING);
    fetchPricingTemplates();
  }, [fetchPricingTemplates, status]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataContracts
        .claimContract(contractId)
        .then(() => savePricingStructure(contractPricing.ECOMMERCE))
        .catch(savePricingStructure); // We're lazy, execute save again, which will fail, and propagate error to Overlay
    }, 500);
  }, [contractId, savePricingStructure, contractPricing, setError]);

  const retry = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(() => fetchPricingTemplates(true), 3000);
  }, [fetchPricingTemplates, setError]);

  if (
    status === Status.SUCCESS &&
    isMissingTemplatesAndMissingPricingInContract(templates, contractPricing.ECOMMERCE)
  )
    return <div>Success but is missing stuff</div>;

  return (
    <div className="pricing">
      <ContractEditError
        error={error}
        setError={setError}
        retry={retry}
        onClose={() => setError(null)}
        reclaimAndSave={reclaimAndSave}
      />
      <h5>{getContractTypeDisplayString(ContractType.ECOMMERCE)}</h5>

      <ContentBox className={styles.instore} collapsible={true}>
        <PricingIngress />

        <Retry retry={retry} status={status}>
          <AnimateHeight name={IsNotMockId ? "ok" : "not-ok"} speed={Speed.SLOW}>
            <Form
              onSaveTrigger={(event, form) => {
                if (form.isInvalid || hasRealErrors(form)) return;
                savePricingStructure(contractPricing.ECOMMERCE);
              }}
              name={FormName.PRICING_CALCULATOR}
              formContainer={formRef}
            >
              <LoadTemplate contractTypeTemplatesToLoad={ContractType.ECOMMERCE} />

              {IsNotMockId && (
                <>
                  <AnimatePresence>
                    {steppedBasedPricing ? (
                      <SteppedBasedPricingAdmin
                        key={"stepped-based-pricing"}
                        steppedBasedPricing={steppedBasedPricing}
                        country={country}
                        pricingModel={pricingModel}
                        setEditablePricing={() => console.log("")}
                        isSalesComp
                      />
                    ) : (
                      <TransactionFeeInputs
                        key={"transaction-fees"}
                        pricingView={view}
                        pricingModel={pricingModel}
                        optionalTransactionFees={optionalTransactionFees}
                        disabled={disabled}
                        roundedBorders={false}
                        country={country}
                        setPricingView={setPricingView}
                        className="m-bottom-30"
                        isSalesComponent
                      />
                    )}
                  </AnimatePresence>

                  <FeeValidation
                    transactionFees={optionalTransactionFees}
                    scrollRef={transactionFeesRef}
                    country={country}
                  />

                  <AdditionalSettings
                    disabled={disabled}
                    isSalesComponent={true}
                    country={country}
                    {...contractPricing.ECOMMERCE}
                  />

                  {CASHLESS_PROMOTION_COUNTRIES.includes(country) ? (
                    <CashlessPromotion contractType={ContractType.ECOMMERCE} />
                  ) : (
                    <PromotionMonths contractType={ContractType.ECOMMERCE} />
                  )}

                  {IsNotMockId && (
                    <div ref={terminalPricesRef}>
                      <EcomTerminalSummary />
                    </div>
                  )}
                </>
              )}
            </Form>
          </AnimateHeight>
        </Retry>
      </ContentBox>
    </div>
  );
};

const sortByPriority = (a: PricingTemplate, b: PricingTemplate) => {
  if (a.priority > b.priority) {
    return 1;
  }

  if (a.priority < b.priority) {
    return -1;
  }

  return a.name.localeCompare(b.name);
};

function isMissingTemplatesAndMissingPricingInContract(templates: PricingTemplate[], cost: PricingStructure) {
  if (cost.templateId !== -1) {
    return false;
  }

  if (templates.length > 0) {
    return false;
  }

  return true;
}
