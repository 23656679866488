import { Trans, useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { Checkboxes } from "../../../../components/interactions/Checkboxes/Checkboxes";
import { Status } from "../../../../data/types";
import { contractPricingState } from "../../../../state/contractCostState";
import { contractStatusState } from "../../../../state/contractStatusState";

import { Minus } from "../../../../components/icons/Minus";
import { Plus } from "../../../../components/icons/Plus";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { FC, useCallback, useMemo } from "react";

import { getCountryDisplayString } from "../../../../components/utils";
import useGeneralData from "../../../../hooks/useGeneralData";
import { ContractType } from "../../../../model/contract/contractType";
import styles from "./CashlessPromotion.module.scss";
import { InfoBox } from "../../../../components/boxes/InfoBox";
import { CASHLESS_PROMOTION_MONTHS } from "../../../../model/pricing/pricingUtils";

const CASHLESS_PROMOTION = "CASHLESS_PROMOTION";

interface Props {
  contractType: ContractType;
}

export const calculateOfferingStart = (promotionMonths: number) => {
  const offeringStart = new Date();
  offeringStart.setMonth(offeringStart.getMonth() + 1);
  offeringStart.setMonth(offeringStart.getMonth() + promotionMonths);
  return offeringStart;
};

export const CashlessPromotion: FC<Props> = ({ contractType }) => {
  const [contractPricing, setContractPricing] = useRecoilState(contractPricingState);
  const { country, edit } = useRecoilValue(contractStatusState);
  const { cashlessPromotion, promotionMonths, cashlessPromotionIsPreSelected } =
    contractPricing[contractType];

  const { t } = useTranslation();
  const { language } = useGeneralData();

  const numberOfPromotionMonths = useMemo(() => CASHLESS_PROMOTION_MONTHS[country], [country]);

  const onToggleCashless = useCallback(() => {
    setContractPricing((prev) => {
      const newCashlessPromotion = !prev[contractType].cashlessPromotion;
      return {
        ...prev,
        [contractType]: {
          ...prev[contractType],
          cashlessPromotion: newCashlessPromotion,
          promotionMonths: newCashlessPromotion ? numberOfPromotionMonths : 0,
        },
      };
    });
  }, [numberOfPromotionMonths, setContractPricing, contractType]);

  const decrementButtonStatus = useMemo(() => {
    return cashlessPromotion || !edit || promotionMonths === 0 ? Status.DISABLED : Status.DEFAULT;
  }, [cashlessPromotion, edit, promotionMonths]);

  const incrementButtonStatus = useMemo(() => {
    return cashlessPromotion || !edit ? Status.DISABLED : Status.DEFAULT;
  }, [cashlessPromotion, edit]);

  const onRemoveMonth = useCallback(
    () =>
      setContractPricing((prev) => ({
        ...prev,
        [contractType]: {
          ...prev[contractType],
          promotionMonths: prev[contractType].promotionMonths - 1,
        },
      })),
    [setContractPricing, contractType]
  );

  const onAddMonth = useCallback(
    () =>
      setContractPricing((prev) => ({
        ...prev,
        [contractType]: {
          ...prev[contractType],
          promotionMonths: prev[contractType].promotionMonths + 1,
        },
      })),
    [setContractPricing, contractType]
  );

  return (
    <>
      <div className={styles.root}>
        <h5>{t("Cashless promotion")}</h5>
        {cashlessPromotionIsPreSelected && (
          <InfoBox className="m-y-20">
            {t(
              "This price plan has a pre-selected cashless promotion value. Therefore this cannot be edited by Sales."
            )}
          </InfoBox>
        )}

        <div className="double-auto-columns m-top-20">
          <div className={styles.checkbox}>
            <Checkboxes
              disabled={cashlessPromotionIsPreSelected ? true : !edit}
              onChange={onToggleCashless}
              values={[contractPricing[contractType].cashlessPromotion ? CASHLESS_PROMOTION : ""]}
              alternatives={[
                {
                  text: t("Cashless promotion"),
                  value: CASHLESS_PROMOTION,
                },
              ]}
            />
          </div>
          <div>
            {cashlessPromotionIsPreSelected ? (
              <i className="fw-500 text-passive">
                {cashlessPromotion
                  ? t(
                      "The cashless promotion (government program) for {{ country }} is 12 promotion months",
                      {
                        country: getCountryDisplayString(country),
                      }
                    )
                  : t("This price plan has no cashless promotion.")}
              </i>
            ) : (
              <Trans>
                <i>Cashless promotion</i> has a fixed number of promotion months (
                {{ numberOfPromotionMonths }}) set. If set, the <b>First invoice</b> date may not be edited.
              </Trans>
            )}
          </div>
        </div>
        <div className={`double-auto-columns ${styles.promotion_months_toggle}`}>
          <div className={styles.first_invoice}>{t("First invoice")}</div>
          <div className={styles.months_toggle}>
            {!cashlessPromotionIsPreSelected && (
              <Button
                type="button"
                action
                onClick={onRemoveMonth}
                className="small"
                status={decrementButtonStatus}
              >
                <Minus />
              </Button>
            )}

            <span className="offer-start">
              {calculateOfferingStart(promotionMonths).toLocaleDateString(language, {
                year: "numeric",
                month: "long",
              })}
            </span>
            {!cashlessPromotionIsPreSelected && (
              <Button
                type="button"
                action
                onClick={onAddMonth}
                className="small"
                status={incrementButtonStatus}
              >
                <Plus />
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
