import { FC } from "react";
import { FormattedDate } from "../../../../../components/time/FormattedDate";
import { MerchantAssociateResponse } from "../../../../../data/dataConfirm";
import { AssociateRole } from "../../../../../model/associate/associateTypes";
import styles from "./SignatureDisplay.module.scss";
import partentStyles from "../../AssociateReview.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  merchantAssociate: MerchantAssociateResponse;
}

export const SignatureDisplay: FC<Props> = ({ merchantAssociate }) => {
  const { roles, signature } = merchantAssociate;
  const { t } = useTranslation();

  if (roles.indexOf(AssociateRole.SIGNATORY) === -1 || !signature) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className="m-bottom-10 text-passive">{t("Signature")}</div>
      <div>
        <img className={styles.image} src={signature?.image} alt="Signature" />
        <div>
          <dl className={partentStyles.data_list}>
            <dd>{t("Signed")}</dd>
            <dt>
              <FormattedDate date={signature.uploaded} />
            </dt>
            <dd>{t("Signed from")}</dd>
            <dt>
              {signature.city}, {signature.country}
            </dt>
            <dd>{t("IP address")}</dd>
            <dt>{signature.ipAddress}</dt>
          </dl>
        </div>
      </div>
    </div>
  );
};
