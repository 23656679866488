import React from "react";
import { PageStripped } from "./PageStripped";
import styles from "./HiddenPage.module.scss";

export const HIDDEN_ROUTE = "/hidden-page";

export const HiddenPage: React.FunctionComponent = () => {
  return (
    <PageStripped title="Hidden" className="hidden-page">
      <div className={styles.content}>
        <h1>Hidden Page</h1>
      </div>
    </PageStripped>
  );
};
