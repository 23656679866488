import { FC } from "react";
import FadeAnimation from "../../../../../../../components/animate/FadeAnimation";
import { TextInput } from "../../../../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../../../../components/form/validators/RequiredValidator";
import { WebsiteValidator } from "../../../../../../../components/form/validators/WebsiteValidator";
import { Status } from "../../../../../../../data/types";
import {
  EcomTypesContentProps,
  HTTPS_PREFIX,
  addHttpsPrefix,
  removeHttpsPrefix,
} from "./EcomTypesContentUtils";

const PaymentLinksContent: FC<EcomTypesContentProps> = ({ onChange, store, t, edit, status }) => {
  return (
    <FadeAnimation animationKey="payment-links">
      <div>
        <TextInput
          onChange={(value) => onChange("ecommerceTermsAndConditionsURL", addHttpsPrefix(value))}
          name="terms-and-condition"
          placeholder="website/terms-and-conditions.com"
          value={removeHttpsPrefix(store.ecommerceTermsAndConditionsURL)}
          label={t("URL to the terms and conditions")}
          validators={[
            new RequiredValidator(t("URL to terms and condition is required")),
            new WebsiteValidator(t("URL is not valid"), true),
          ]}
          disabled={!edit || status === Status.DISABLED}
          preIcon={{
            icon: HTTPS_PREFIX,
          }}
        />
      </div>
      <div>
        <TextInput
          onChange={(value) => onChange("ecommerceURL", addHttpsPrefix(value))}
          name="product offering"
          placeholder="website/offering.com"
          value={removeHttpsPrefix(store.ecommerceURL)}
          label={t("URL to the product offering")}
          validators={[
            new RequiredValidator(t("URL to terms and condition is required")),
            new WebsiteValidator(t("URL is not valid"), true),
          ]}
          disabled={!edit || status === Status.DISABLED}
          preIcon={{
            icon: HTTPS_PREFIX,
          }}
        />
      </div>
    </FadeAnimation>
  );
};

export default PaymentLinksContent;
