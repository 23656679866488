import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Language, getCountryByLanguage, getLanguageName } from "../../i18n";
import { Select } from "../form/Select";
import { Alternative } from "../interactions/InputTypes";
import cx from "classnames";
import "./LanguageSelect.scss";
import { Flag } from "../flags/Flag";
import { useLocation } from "react-router";

interface Props {
  className?: string;
}

export const LanguageSelect = ({ className }: Props) => {
  const { i18n } = useTranslation();
  const languageCode = i18n.language.split('-')[0];
  const { search } = useLocation();
  const allowFrance = search.indexOf("lang=fr") > -1;

  const languageAlternatives: Alternative<Language>[] = useMemo(
    () =>
      Object.values(Language)
        .filter((lang) => (lang === Language.FRANCE && !allowFrance ? false : true))
        .sort()
        .map((lang) => ({
          value: lang as Language,
          text: getLanguageName(lang as Language, languageCode),
        })),
    [allowFrance, languageCode]
  );

  return (
    <div className={cx("language-select", className)}>
      <Flag lang={getCountryByLanguage(languageCode as Language)} />
      <Select
        className="fw-500"
        value={languageCode}
        alternatives={languageAlternatives}
        onChange={(newLanguage) => {
          i18n.changeLanguage(newLanguage);
        }}
      />
    </div>
  );
};
