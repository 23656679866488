import { FC } from "react";
import styles from "./PackData.module.scss";
import { PricingPackTerminalSelect } from "./PricingPackTerminalSelect/PricingPackTerminalSelect";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { PricingPackThreshold } from "./PricingPackThreshold/PricingPackThreshold";
import { Country } from "../../../../../../../model/common/commonType";
import { PricingUpdateType } from "../PricingInputs";
import { EditablePricingTemplate } from "../../../../../../../model/pricing/pricingTypes";
import ContentBox from "../../../../../../../components/ContentBox/ContentBox";
import { WarningBox } from "../../../../../../../components/boxes/WarningBox";

type TemplateProps = Pick<
  EditablePricingTemplate,
  "monthlyAcquiringFee" | "monthlyTurnoverThreshold" | "terminalType"
>;

interface Props extends TemplateProps {
  update?: (updates: PricingUpdateType) => void;
  disabled?: boolean;
  country: Country;
  isSalesComponent?: boolean;
}

const PackData: FC<Props> = ({
  terminalType,
  update,
  disabled,
  country,
  monthlyAcquiringFee,
  monthlyTurnoverThreshold,
  isSalesComponent = false,
}) => {
  const { t } = useTranslation();

  const values: [number | undefined, number | undefined] = [monthlyTurnoverThreshold, monthlyAcquiringFee];

  return (
    <ContentBox
      colorStyle={isSalesComponent ? "grey" : "default"}
      padding="no"
      className={isSalesComponent ? "m-bottom-30" : ""}
    >
      <div className={cx(styles.root)}>
        <div className={styles.header}>
          <h5>{t("Pack information")}</h5>
          <WarningBox>{t("Pack price plans are limited to 1 store and 1 pre-selected terminal")}</WarningBox>
        </div>
        <div className={styles.content}>
          <PricingPackTerminalSelect
            terminalType={terminalType}
            setTerminalType={update ? (terminalType) => update({ terminalType }) : undefined}
            disabled={disabled}
            country={country}
            onlyDisplaySelected={isSalesComponent ? true : false}
          />
          <hr className={styles.hr} />
          <PricingPackThreshold
            values={values}
            setValues={
              update
                ? (volume, fee) => update({ monthlyTurnoverThreshold: volume, monthlyAcquiringFee: fee })
                : () => null
            }
            disabled={disabled}
            country={country}
          />
        </div>
      </div>
    </ContentBox>
  );
};

export default PackData;
