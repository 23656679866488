import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import { Form } from "../../../components/form/Form";
import { TextInput } from "../../../components/form/TextInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Button } from "../../../components/interactions/Buttons/Button";
import { dataConfirm } from "../../../data/dataConfirm";
import { ContractId } from "../../../model/common/commonType";
import { Status } from "../../../data/types";

export const VIES_PAGE = "/admin/vies";

function getElem(saved: boolean, error: boolean, t: any) {
  if (saved) {
    return {
      name: "saved",
      elem: <SuccessBox relative>{t("Contract updated")}</SuccessBox>,
    };
  }

  if (error) {
    return {
      name: "error",
      elem: <ErrorBox relative>{t("Could not update contract. Check inspector for status.")}</ErrorBox>,
    };
  }

  return {
    name: "none",
    elem: <div />,
  };
}

export function FixViesBySettingItInDbPage() {
  const [saved, setSaved] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [id, setId] = useState<string>();
  const [vat, setVat] = useState<string>();
  const { t } = useTranslation();
  const elem = getElem(saved, error, t);

  return (
    <section>
      <article>
        <div
          className="fix-contract-page"
          style={{
            maxWidth: "480px",
            margin: "60px auto",
          }}
        >
          <h4>{t("Fix empty vies")}</h4>

          <Form
            onSubmit={(event, form) => {
              setError(false);
              setSaved(false);

              if (form.isInvalid) {
                return;
              }

              if (!vat) {
                return;
              }

              dataConfirm
                .fixViesByDbUpdate(id as ContractId, vat)
                .then(() => {
                  setSaved(true);
                })
                .catch((err) => {
                  console.log("err", err);
                  setError(true);
                });
            }}
          >
            <div className="m-top-40">
              <TextInput
                onChange={(val) => {
                  setError(false);
                  setSaved(false);
                  setId(val);
                }}
                label={t("Add contract id")}
                value={id}
                validators={[new RequiredValidator(t("Id is required"))]}
              />

              <TextInput
                onChange={(val) => {
                  setError(false);
                  setSaved(false);
                  setVat(val);
                }}
                label={t("Add VAT number")}
                value={vat}
                validators={[new RequiredValidator(t("VAT is required"))]}
              />
            </div>

            <AnimateHeight name={elem.name}>{elem.elem}</AnimateHeight>

            <Button
              className="m-top-20"
              block
              type="submit"
              status={saved ? Status.DISABLED : Status.DEFAULT}
            >
              {t("Fix vies")}
            </Button>
          </Form>
        </div>
      </article>
    </section>
  );
}
