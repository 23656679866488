import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { AnalyticsFilterUtils } from "../../../model/AnalyticsFilter";
import { analyticsFilterState } from "../../../state/analyticsState";
import { AnalyticsCompleted, dataStatistics } from "../../../data/dataStatistics";
import { Status } from "../../../data/types";

import { dataMaps } from "../../../data/dataMaps";
import { userState } from "../../../state/userState";
import { CompletedContractsGraph } from "./CompletedContractsGraph";
import { CompletedContractsFilter } from "./CompletedContractsFilter";
import { useWidth } from "../../../hooks/useWidth";
import { useTranslation } from "react-i18next";
import "./CompletedContracts.scss";
import { Language } from "../../../i18n";
import { Retry } from "../../../components/retry/Retry";

export const CompletedContracts: React.FunctionComponent = () => {
  const [analyticsFilter] = useRecoilState(analyticsFilterState);
  const [contracts, setContracts] = useState<AnalyticsCompleted[]>([]);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const { i18n } = useTranslation();
  const user = useRecoilValue(userState);
  const width = useWidth();

  const load = useCallback(() => {
    const filterQuery = AnalyticsFilterUtils.filterToQuery(analyticsFilter);
    const statsPromise = user?.salesUser
      ? dataStatistics.getSalesCompleted(filterQuery)
      : dataStatistics.getAdminCompleted(filterQuery);

    Promise.all([statsPromise, dataMaps.initD3()])
      .then((response) => {
        setStatus(Status.SUCCESS);
        setContracts(response[0]);
      })
      .catch((err) => {
        setStatus(Status.ERROR);
      });
  }, [analyticsFilter, user]);

  useEffect(load, [load]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(load, 500);
  }, [load]);

  return (
    <div className="historical-contracts">
      <Retry status={status} retry={retry}>
        <CompletedContractsFilter />
        <CompletedContractsGraph
          filteredName={analyticsFilter.filteredName}
          contracts={contracts}
          from={new Date(analyticsFilter.from)}
          to={new Date(analyticsFilter.to)}
          width={width}
          language={i18n.language as Language}
        />
      </Retry>
    </div>
  );
};
