import { FC } from "react";
import { AnimateHeight } from "../../../../../../components/animate/AnimateHeight";
import { ErrorBox } from "../../../../../../components/boxes/ErrorBox";
import { SuccessBox } from "../../../../../../components/boxes/SuccessBox";
import { Country } from "../../../../../../model/common/commonType";
import { Status } from "../../../../../../data/types";

interface Props {
  status: Status;
  country: Country;
}

export const AdminPreSetPageStatusMessages: FC<Props> = ({ status, country }) => {
  return (
    <AnimateHeight name={status.toString()}>
      <div>
        {status === Status.SUCCESS && (
          <SuccessBox relative>We have successfully updated this price plan!</SuccessBox>
        )}

        {status === Status.ERROR && (
          <ErrorBox relative>
            <b>Ooh noo!</b> We couldn't save the changes
          </ErrorBox>
        )}
      </div>
    </AnimateHeight>
  );
};
