import { DateTime } from "luxon";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Table } from "../../../components/table/Table";
import { Button } from "../../../components/interactions/Buttons/Button";
import { FormattedDate } from "../../../components/time/FormattedDate";
import { FormattedDuration } from "../../../components/time/FormattedDuration";
import { Page, SortDirection } from "../../../model/Page";
import { Pagination } from "../../../components/pagination/Pagination";
import { AdminLoginHistory, SalesUser, userAdministration } from "../../../data/UserAdministration";
import { AdminPage } from "../AdminPage";
import { AddUserModal } from "./AddSalesUserModal";
import { SALES_USER_EDIT_PAGE } from "./SalesUserPage";
import { Flag } from "../../../components/flags/Flag";
import { Edit } from "../../../components/icons/Edit";
import { getDynamicLink } from "../../../components/utils";
import { Pending } from "../../../components/icons/Pending";
import { TeamId } from "../../../model/common/commonType";
import "./UserAdministration.scss";

export const SALES_ADMIN_USERS_PAGE = "/admin/users/sales";
const PAGE_LIMIT = 20;

export const SalesUsersPage: React.FunctionComponent = () => {
  const [salesUsers, setSalesUsers] = useState<Page<SalesUser>>();
  const history = useHistory();
  const location = useLocation();

  const onPageChange = (page: number) => {
    const search = new URLSearchParams(location.search);
    search.set("page", page.toString());
    history.push({
      pathname: location.pathname,
      search: search.toString(),
    });
  };

  const onSortChange = (ev: React.MouseEvent<HTMLButtonElement>) => {
    const property = (ev.target as HTMLButtonElement).dataset.id || "name";
    const search = new URLSearchParams(location.search);
    search.set("sort", property);

    const direction = salesUsers?.sort.sorted
      ? salesUsers?.sort.direction === SortDirection.ASC
        ? SortDirection.DESC
        : SortDirection.ASC
      : SortDirection.ASC;
    search.set("sortOrder", direction);

    history.push({
      pathname: location.pathname,
      search: search.toString(),
    });
  };

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const page = search.has("page") ? Number(search.get("page")) : 0;
    const sort = search.get("sort");
    const sortOrder = search.get("sortOrder") as SortDirection;
    const country = search.get("country");
    const teamId = search.get("teamId") as TeamId;

    userAdministration
      .loadSalesUsers(page, PAGE_LIMIT, country, teamId, sort, sortOrder)
      .then(setSalesUsers)
      .catch((err) => {
        // TODO
        console.error(err);
      });
  }, [setSalesUsers, location]);

  const onEdit = useCallback(
    (data) => {
      history.push(getDynamicLink(SALES_USER_EDIT_PAGE, { id: data.id }));
    },
    [history]
  );

  if (salesUsers === undefined) {
    return (
      <AdminPage title="Sales Users" className="sales-users">
        <section>
          <div className="center">
            <Pending />
          </div>
        </section>
      </AdminPage>
    );
  }

  const showLastLogin = (lastLogin?: AdminLoginHistory) => {
    if (!lastLogin) {
      return "-";
    }

    const hoursSinceLogin = DateTime.fromISO(lastLogin.created).diffNow("hours");

    if (hoursSinceLogin.hours < 48) {
      return (
        <span>
          <FormattedDuration duration={hoursSinceLogin} />
        </span>
      );
    }
    return <FormattedDate date={lastLogin.created} pattern="yyyy-MM-dd" />;
  };

  const isSorted = (property: string) => {
    if (!salesUsers.sort.sorted) {
      return undefined;
    }

    if (salesUsers.sort.property === property) {
      return salesUsers.sort.direction === "ASC" ? "ascending" : "descending";
    }

    return undefined;
  };

  return (
    <AdminPage title="Sales Users" className="light-theme sales-users">
      <section>
        <article>
          <AddUserModal
            onSave={() => {
              onPageChange(0);
            }}
          ></AddUserModal>
        </article>

        <article>
          <Table className="m-top-20">
            <thead>
              <tr>
                <th />
                <th />
                <th>
                  <button className={isSorted("name")} onClick={onSortChange} data-id="name">
                    Name
                  </button>
                </th>
                <th>
                  <button className={isSorted("email")} onClick={onSortChange} data-id="email">
                    Email
                  </button>
                </th>
                <th>
                  <button className={isSorted("phone")} onClick={onSortChange} data-id="phone">
                    Phone number
                  </button>
                </th>
                <th>
                  <button className={isSorted("teamId")} onClick={onSortChange} data-id="teamId">
                    Team
                  </button>
                </th>
                <th>
                  Last login
                  {/* Last login is from another table so need to fix sorting in the backend to make this work */}
                  {/* <button
                    className={isSorted("lastLogin")}
                    onClick={onSortChange}
                    data-id="lastLogin"
                  >
                    Last Login
                  </button> */}
                </th>
              </tr>
            </thead>
            <tbody>
              {salesUsers.content.map((row) => (
                <tr key={row.id}>
                  <td className="text-center">
                    <Button
                      action
                      className="mini"
                      data={{
                        id: row.id,
                      }}
                      onClick={onEdit}
                    >
                      <Edit />
                    </Button>
                  </td>
                  <td className="text-center">
                    <Flag lang={row.country} size={24} />
                  </td>
                  <td className="truncate">{row.name}</td>
                  <td className="truncate">{row.email}</td>
                  <td className="truncate">{row.phone}</td>
                  <td className="truncate">{row.teamName}</td>
                  <td className="truncate">{showLastLogin(row.lastLogin)}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Pagination
            className="m-top-10"
            onPageChange={onPageChange}
            activePage={salesUsers.number}
            totalPages={salesUsers.totalPages}
          />
        </article>
      </section>
    </AdminPage>
  );
};
