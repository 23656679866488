import { ContractId, UTCDate } from "../common/commonType";
import { ContractType, TerminalType } from "../contract/contractType";

export enum CardBrand {
  VISA_DEBIT = "VISA_DEBIT",
  VISA_CREDIT = "VISA_CREDIT",
  VPAY = "VPAY",
  MASTERCARD_DEBIT = "MASTERCARD_DEBIT",
  MASTERCARD_CREDIT = "MASTERCARD_CREDIT",
  MAESTRO = "MAESTRO",
  DINERS = "DINERS",
  UNION = "UNION",
  JCB = "JCB",
  //ALIPAY = "ALIPAY",
  BLIK = "BLIK",
}

// RegularBrands and PremiumBrands are for all countries
enum RegularBrands {
  VISA_DEBIT = CardBrand.VISA_DEBIT,
  VISA_CREDIT = CardBrand.VISA_CREDIT,
  VPAY = CardBrand.VPAY,
  MASTERCARD_DEBIT = CardBrand.MASTERCARD_DEBIT,
  MASTERCARD_CREDIT = CardBrand.MASTERCARD_CREDIT,
  MAESTRO = CardBrand.MAESTRO,
}

enum PremiumBrands {
  DINERS = CardBrand.DINERS,
  UNION = CardBrand.UNION,
  JCB = CardBrand.JCB,
}

// brands that are specific for countries
enum PolandSpecificBrands {
  BLIK = CardBrand.BLIK,
}

export const polandSpecificBrandsArray = Object.values(PolandSpecificBrands) as unknown as CardBrand[];

// group all country specific brands here, used for feeBounds calculation...
export const countrySpecificBrands = [...polandSpecificBrandsArray];

export const regularBrandsArray = Object.values(RegularBrands) as unknown as CardBrand[];
export const premiumBrandsArray = Object.values(PremiumBrands) as unknown as CardBrand[];
export const brandsArray: CardBrand[] = Object.values(CardBrand);

export enum FeeType {
  FIXED = "fixedFee",
  TRANSACTION = "transactionFee",
  MIN = "minFee",
  MAX = "maxFee",
}

export enum PricingModel {
  BLENDED = "BLENDED",
  ICPP = "ICPP",
  PACK = "PACK",
}

export enum TransactionDiscount {
  NONE = "NONE",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  EXTRA_LARGE = "EXTRA_LARGE",
}

export enum PricingView {
  INDIVIDUAL = "individual",
  GROUP = "group",
}

export interface TransactionFee {
  brand: CardBrand;
  [FeeType.MIN]: number;
  [FeeType.FIXED]: number;
  [FeeType.TRANSACTION]: number;
  [FeeType.MAX]?: number;
  tariffClass: number;
}

export interface OptionalTransactionFee {
  brand: CardBrand;
  [FeeType.MIN]?: string;
  [FeeType.FIXED]?: string;
  [FeeType.TRANSACTION]?: string;
  [FeeType.MAX]?: string;
  tariffClass?: number;
}

export enum TariffClassProduct {
  ECAMC = "ECAMC",
  MAES = "MAES",
  DMC = "DMC",
  VISA = "VISA",
  VPAY = "VPAY",
  VSDB = "VSDB",
  CUP = "CUP",
  DINER = "DINER",
  JCB = "JCB",
  BLIK = "BLIK",
  UNION = "UNION",
}

enum TariffClassProductsTapOnMobileBase {
  ECAMC = TariffClassProduct.ECAMC,
  MAES = TariffClassProduct.MAES,
  DMC = TariffClassProduct.DMC,
  VISA = TariffClassProduct.VISA,
  VPAY = TariffClassProduct.VPAY,
  VSDB = TariffClassProduct.VSDB,
}

enum TariffClassProductBase {
  ECAMC = TariffClassProduct.ECAMC,
  MAES = TariffClassProduct.MAES,
  DMC = TariffClassProduct.DMC,
  VISA = TariffClassProduct.VISA,
  VPAY = TariffClassProduct.VPAY,
  VSDB = TariffClassProduct.VSDB,
  CUP = TariffClassProduct.CUP,
  DINER = TariffClassProduct.DINER,
  JCB = TariffClassProduct.JCB,
}

enum PolandSPecificTariffClassProduct {
  BLIK = TariffClassProduct.BLIK,
}

export const TARIFF_CLASS_PRODUCTS_ALL: TariffClassProduct[] = Object.values(TariffClassProduct);

export const TARIFF_CLASS_PRODUCTS_TAP_ON_MOBILE_BASE: TariffClassProduct[] = Object.values(
  TariffClassProductsTapOnMobileBase
) as unknown as TariffClassProduct[];

export const TARIFF_CLASS_PRODUCTS_BASE = Object.values(
  TariffClassProductBase
) as unknown as TariffClassProduct[];

export const POLAND_SPECIFIC_TARIFF_CLASS_PRODUCTS = Object.values(
  PolandSPecificTariffClassProduct
) as unknown as TariffClassProduct[];

export interface TariffClassMapping {
  product: TariffClassProduct;
  tariffClass: number;
}

export interface DCC {
  dcc: number;
  tariffClass: number;
}

export interface PricingStep {
  fromThreshold: number;
  toThreshold: number;
  transactionFee: number;
}

export interface SteppedBasedPricing {
  cashlessPromotionPricing: PricingStep[] | undefined;
  regularPricing: PricingStep[];
}

export interface Pomca {
  terminalAmount: number;
  fee: number;
}

// base pricing, used for both PricingStructure, PricingTemplate
export interface BasePricing {
  pricingModel: PricingModel;
  promotionMonths: number;
  cashlessPromotion: boolean;
  transactionFees: TransactionFee[];
  chargebackFee: number;
  surchargesCommercialCards: number;
  surchargesNonEea: number;
  preAuthorizationFees: boolean;

  // iPass mapping fields
  contractTemplateName: string;
  conditionCategoryCode: number;
  contractCategory: number;

  // monthlyAcquiringFee, monthlyTurnoverThreshold only relevant for PACK pricing model.
  // terminalType is only relevant for PACK
  monthlyAcquiringFee?: number;
  monthlyTurnoverThreshold?: number;
  terminalType?: TerminalType;

  name: string;
  steppedBasedPricing?: SteppedBasedPricing;
  cashlessPromotionIsPreSelected: boolean;
  contractType: ContractType;

  storeLimitation?: number;
  terminalLimitation?: number;
  disabledTerminals?: TerminalType[];
  pomca?: Pomca[];
}

// previous name: Pricing
// PricingStructure represents each individual contracts price. Very similar to PricingTemplate. DB table
export interface PricingStructure extends BasePricing {
  pricingStructureId: number;
  contractId: ContractId;
  templateId: number;
  customizedBy: string;
  customized: boolean;
  created: Date;
  cas: number;

  installationFee?: number;
  cashBack?: number;
  acceptance: boolean;
  dcc: DCC;
  installedByTechnician: boolean;

  // view, defaultedCashless only exists in FE
  view: PricingView;
}

// ContractPricing is property on Contract
export type ContractPricing = Omit<PricingStructure, "view">;

// PricingTemplate is what represents all available templates that Sales can choose from. DB table
export interface PricingTemplate extends BasePricing {
  templateId: number; // is id in db
  description: string;
  priority: number;
  disabled?: UTCDate;
  updated?: UTCDate;
  updatedBy?: string;
  dcc: DCC[];
  tariffClassMappings: TariffClassMapping[];
}

// TODO
export interface EditablePricingTemplate
  extends Omit<
    PricingTemplate,
    | "transactionFees"
    | "pricingModel"
    | "name"
    | "description"
    | "priority"
    | "disabled"
    | "updated"
    | "updatedBy"
    | "contractTemplateName"
    | "contractCategory"
    | "conditionCategoryCode"
  > {
  transactionFees: OptionalTransactionFee[];
  pricingModel?: PricingModel;

  description?: string;
  name?: string;

  contractTemplateName?: string;
  conditionCategoryCode?: number;
  contractCategory?: number;

  view: PricingView;
}

// fields that are dependent on the pricing model
export type DynamicPricingFields = Pick<
  EditablePricingTemplate,
  | "monthlyAcquiringFee"
  | "monthlyTurnoverThreshold"
  | "terminalType"
  | "steppedBasedPricing"
  | "preAuthorizationFees"
  | "surchargesNonEea"
  | "surchargesCommercialCards"
  | "storeLimitation"
  | "terminalLimitation"
>;
