import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { PermissionError } from "../../components/permissionError/PermissionError";
import { Retry } from "../../components/retry/Retry";
import { dataContracts } from "../../data/dataContracts";
import { dataConfirm, ConfirmStatus } from "../../data/dataConfirm";
import { ContractId } from "../../model/common/commonType";
import { Contract, ContractStatus } from "../../model/contract/contractType";
import { Status } from "../../data/types";
import { ServerError } from "../../network/API";
import { checkIdExpiry, contractAssociateState } from "../../state/contractAssociateState";
import { contractBankState } from "../../state/contractBankState";
import { contractDocumentsState } from "../../state/contractDocumentsState";
import { contractMainContractDataState } from "../../state/contractMainContractDataState";
import { contractPackageState } from "../../state/contractPackageState";
import { contractStatusState } from "../../state/contractStatusState";
import { contractStoresState } from "../../state/contractStoresState";
import { merchantStatusState } from "../../state/merchantStatusState";
import { userState } from "../../state/userState";
import { getUserIsAllowedToEdit } from "../Contract/ContractPage";
import { MerchantStatus } from "./MerchantStatus";
import "./MerchantStatusPage.scss";
import { Page, PageType } from "../Page/Page";

export const MERCHANT_ROUTE = "/merchant/:id";

export interface Props {
  merchantInfo?: ConfirmStatus;
  contract?: Contract;
}

const MerchantStatusInner: React.FunctionComponent<Props> = ({ merchantInfo, contract }) => {
  if (!merchantInfo || !contract) {
    return null;
  }

  return <MerchantStatus />;
};

export function MerchantStatusPage() {
  const [merchantInfo, setMerchantInfo] = useState<ConfirmStatus>();
  const [contract, setContract] = useState<Contract>();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const { id } = useParams<{ id: ContractId }>();
  const setBank = useSetRecoilState(contractBankState);
  const setMainData = useSetRecoilState(contractMainContractDataState);
  const setAssociates = useSetRecoilState(contractAssociateState);
  const setPackage = useSetRecoilState(contractPackageState);
  const [, setContractStatus] = useRecoilState(contractStatusState);
  const setStores = useSetRecoilState(contractStoresState);
  const setDocuments = useSetRecoilState(contractDocumentsState);
  const setMerchantStatus = useSetRecoilState(merchantStatusState);
  // const setTracking = useSetRecoilState(contractTrackingState);
  const user = useRecoilValue(userState);
  const [missingPermission, setMissingPermission] = useState(false);

  const setData = useCallback(
    (contractResponse, merchantResponse: ConfirmStatus) => {
      const userIsAllowedToEdit = getUserIsAllowedToEdit(contractResponse, user);
      setContract(contractResponse);
      setBank(contractResponse.bankAccount);
      setMainData(contractResponse.contractData);
      setPackage(contractResponse.contractPackage);
      setAssociates(checkIdExpiry(contractResponse.associates));
      setContractStatus({
        contractId: contractResponse.contractId,
        status: contractResponse.status,
        created: contractResponse.created,
        country: contractResponse.country,
        lastUpdated: contractResponse.lastUpdated,
        completed: contractResponse.completed,
        currentSalesUser: contractResponse.currentSalesUser,
        teamId: contractResponse.teamId,
        edit: userIsAllowedToEdit,
        enabledFeatures: contractResponse.enabledFeatures,
      });
      // setTracking(merchantResponse.tracking);
      setStores(contractResponse.stores);

      setDocuments(contractResponse.documents);
      setMerchantInfo(merchantResponse);
      setMerchantStatus({
        status: merchantResponse.status,
        confirmationSent: merchantResponse.confirmationSent,
        merchantConfirmed: merchantResponse.merchantConfirmed,
        merchantComplete: merchantResponse.merchantComplete,
        salesContact: merchantResponse.salesContact,
        allSignedOff: merchantResponse.allSignedOff,
        tracking: merchantResponse.tracking,
        remoteIdStatus: merchantResponse.remoteIdStatus,
        isConfirmed: merchantResponse.status === ContractStatus.COMPLETE,
      });
    },
    [
      setBank,
      setMainData,
      setPackage,
      setAssociates,
      setContractStatus,
      setStores,
      setDocuments,
      user,
      setMerchantStatus,
    ]
  );

  const load = useCallback(
    (contractId) => {
      setStatus(Status.PENDING);

      Promise.all([
        dataConfirm.getConfirmStatus(contractId, true),
        dataContracts.loadContract(contractId, true),
      ])
        .then((responses) => {
          const [merchantResponse, contractResponse] = responses;
          setData(contractResponse, merchantResponse);
          setStatus(Status.SUCCESS);
        })
        .catch((error: ServerError<unknown>) => {
          if (error.status === 403) {
            setMissingPermission(true);
          }
          setStatus(Status.ERROR);
        });
    },
    [setData]
  );

  useEffect(() => {
    load(id as ContractId);
  }, [load, id]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(() => {
      load(id);
    }, 300);
  }, [load, id]);

  // useEffect(() => {
  //   if (!contractStatus.contractId) {
  //     return;
  //   }
  //   const wsId = `contract-${contractStatus.contractId}`;
  //   WsClient.subscribe(
  //     wsId,
  //     `/contracts/${contractStatus.contractId}`,
  //     (value) => {
  //       if (value.event === WlxEvent.MERCHANT_UPDATE) {
  //         Promise.all([
  //           dataConfirm.getConfirmStatus(
  //             contractStatus.contractId,
  //             true
  //           ),
  //           dataContracts.loadContract(contractStatus.contractId, true),
  //         ])
  //           .then((responses) => {
  //             const [merchantResponse, contractResponse] = responses;
  //             setData(contractResponse, merchantResponse);
  //           })
  //           .catch((err) => {
  //             console.log("handle error?", err);
  //           });
  //       }
  //     }
  //   );
  //   return () => {
  //     WsClient.unsubscribe(wsId);
  //   };
  // }, [contractStatus.contractId, setData]);

  return (
    <Page title="Merchant status" pageType={PageType.SALES_CONTRACT}>
      {missingPermission ? (
        <PermissionError />
      ) : (
        <Retry retry={retry} status={status}>
          <MerchantStatusInner merchantInfo={merchantInfo} contract={contract} />
        </Retry>
      )}
    </Page>
  );
}
