import React from "react";

interface Props {
  children: React.ReactNode;
}

export const FieldSetTonedBox: React.FunctionComponent<Props> = ({
  children,
}) => {
  return <div className="fieldset-toned-box">{children}</div>;
};
