import { FC, useCallback, useState } from "react";
import { useHistory } from "react-router";
import { Edit } from "../../../components/icons/Edit";
import { Expand } from "../../../components/icons/Expand";
import { Button } from "../../../components/interactions/Buttons/Button";
import { NewOverlay } from "../../../components/overlay/NewOverlay";
import { Table } from "../../../components/table/Table";
import { getDynamicLink } from "../../../components/utils";
import { PricingModel } from "../../../model/pricing/pricingTypes";
import { SalesTeam } from "../../../data/UserAdministration";

interface Props {
  team: SalesTeam;
}

export const TeamPricingButtonOverlay: FC<Props> = ({ team }) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const onPricingView = useCallback(
    (data) => {
      history.push(
        getDynamicLink("/admin/pricing/:country/:id", {
          country: team.country,
          id: data.id,
        })
      );
    },
    [history, team.country]
  );

  const modelDisplay = (model: PricingModel) => {
    switch (model) {
      case PricingModel.BLENDED:
        return "Blended";
      case PricingModel.ICPP:
        return "Interchange++";
    }
    return model;
  };

  return (
    <>
      <Button action className="mini" onClick={() => setIsOpen(true)}>
        <Expand />
      </Button>
      <NewOverlay open={isOpen} onClose={() => setIsOpen(false)} className="team-members-button-overlay">
        <div>
          <h4>Pricing Presets</h4>
          <Table>
            <thead>
              <tr>
                <th>Pricing</th>
                <th>Model</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {team.pricingPresets.map((pricing) => (
                <tr key={pricing.id}>
                  <td>{pricing.name}</td>
                  <td>{modelDisplay(pricing.pricingModel)}</td>
                  <td className="text-right">
                    <Button
                      action
                      className="mini"
                      data={{
                        id: pricing.id,
                      }}
                      onClick={onPricingView}
                    >
                      <Edit />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </NewOverlay>
    </>
  );
};
