import { useCallback, FC } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../../../state/userState";
import { LOGOUT_ROUTE } from "../../Auth/Logout";
import { LogoutIcon } from "../../../components/icons/LogoutIcon";
import { Button } from "../../../components/interactions/Buttons/Button";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import styles from "./SidebarUser.module.scss";
import { Store, STORE_KEY } from "../../../Store";
import { dataAuth } from "../../../data/dataAuth";
import { ADMIN_DASHBOARD_ROUTE } from "../../Admin/AdminDashboardPage";
import { SwitchIcon } from "../../../components/icons/SwitchIcon";
import { DASHBOARD_ROUTE } from "../../Dashboard/DashboardPage";

interface Props {
  admin?: boolean;
}

const VerticalNavProfile: FC<Props> = ({ admin = false }) => {
  const user = useRecoilValue(userState);
  const history = useHistory();
  const { t } = useTranslation();
  const setUserState = useSetRecoilState(userState);

  const switchRole = () => {
    if (admin) {
      switchToSales();
    } else {
      switchToAdmin();
    }
  };

  const switchToSales = useCallback(() => {
    dataAuth.switchToSales().then((session) => {
      setUserState(session);
      Store.setValue(STORE_KEY.STORE_USER, session);
      history.push(DASHBOARD_ROUTE);
    });
  }, [history, setUserState]);

  const switchToAdmin = useCallback(() => {
    dataAuth.switchToAdmin().then((session) => {
      setUserState(session);
      Store.setValue(STORE_KEY.STORE_USER, session);
      history.push(ADMIN_DASHBOARD_ROUTE);
    });
  }, [history, setUserState]);

  return (
    <div className={styles.root}>
      <div className={styles.user}>
        <div className={styles.name}>{user?.name}</div>
        <div className={styles.email}>{user?.email}</div>
      </div>
      {user?.canSwitch ? (
        <Button block onClick={switchRole}>
          {admin ? "Switch to Sales" : "Switch to Admin"}
          <SwitchIcon style={{ marginLeft: 8 }} />
        </Button>
      ) : null}
      <Button
        color="danger"
        variant="text"
        block
        onClick={() => {
          history.push(LOGOUT_ROUTE);
        }}
      >
        {t("Logout")}
        <LogoutIcon style={{ marginLeft: 8 }} />
      </Button>
    </div>
  );
};

export default VerticalNavProfile;
