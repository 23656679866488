import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { injectVariables } from "../utils/Replacer";
import { useTranslation } from "react-i18next";
import { IMessage } from "./component/IMessage";
import { VariableList } from "../components/VariableList/VariableList";
import { count } from "../utils/SmsLength";
import { Select } from "../../../../components/form/Select";
import { TextArea } from "../../../../components/form/TextArea";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Alternative } from "../../../../components/interactions/InputTypes";
import {
  TemplateId,
  InternalTextProperties,
  TextTemplateAndTranslationsResponse,
  dataCommunication,
} from "../../../../data/dataCommunication";
import { Language } from "../../../../i18n";
import { AdminPage } from "../../AdminPage";
import "./TextPreview.scss";

export const TEXT_PREVIEW_URL = "/admin/communication/preview/text/:templateId";

export const TextPreview = () => {
  const { templateId } = useParams<{ templateId: TemplateId }>();
  const [templateName, setTemplateName] = useState<string>();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language as Language);
  const [text, setText] = useState("");
  const [variables, setVariables] = useState<InternalTextProperties[]>();
  const [originalText, setOriginalText] =
    useState<TextTemplateAndTranslationsResponse>();

  useEffect(() => {
    setTemplateName(undefined);
    setText("");

    if (!templateId) {
      return;
    }

    dataCommunication
      .getTextTemplateAndTranslations(templateId, language as Language)
      .then((response) => {
        setTemplateName(response.templateName);
        setText(response.text);
        setVariables(response.internalProperties);
      });
    dataCommunication
      .getTextTemplateAndTranslations(templateId, Language.UK)
      .then(setOriginalText);
  }, [templateId, language]);

  if (!templateId || !templateId || !variables || !originalText) {
    return null;
  }

  const textWithVariables = injectVariables(text);
  const originalTextWithVariables = injectVariables(originalText.text);

  const textLength = count(textWithVariables);

  return (
    <AdminPage>
      <div className="text-preview">
        <h3>Preview for {templateName}</h3>
        <div className="main-wrapper">
          <div className="preview-wrapper">
            <IMessage
              language={language}
              message={textWithVariables}
              originalText={
                language !== Language.UK ? originalTextWithVariables : undefined
              }
            />
            <div></div>
          </div>
          <div className="translation-wrapper">
            <h4 className="space-between">
              Translations
              <div className="space-between">
                <VariableList variables={variables} />
                <Select
                  alternatives={Object.entries(Language).map(
                    ([label, language]): Alternative<Language> => ({
                      text: capitalize(label),
                      value: language,
                    })
                  )}
                  onChange={(language) => setLanguage(language)}
                  value={language}
                />
              </div>
            </h4>
            <TextArea onChange={setText} label="Text content" value={text} />
            <div className="space-between counter">
              <span>
                {textLength.inCurrentMessage} / {textLength.characterPerMessage}
              </span>
              <span>{textLength.messages} Message(s)</span>
            </div>

            <Button
              onClick={() => {
                dataCommunication.postTextTranslations(
                  templateId,
                  language,
                  text
                );
              }}
              block
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </AdminPage>
  );
};
