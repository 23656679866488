import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { getIntlDate } from "../../../components/utils";
import { DocumentId } from "../../../model/common/commonType";
import { Status } from "../../../data/types";
import { legalAssociatesSelector } from "../../../state/contractAssociateState";
import { contractDocumentsState } from "../../../state/contractDocumentsState";
import { contractStatusState } from "../../../state/contractStatusState";
import { merchantStatusState } from "../../../state/merchantStatusState";
import { getEditStatus, getUserIsAllowedToEdit } from "../../Contract/ContractPage";
import { AssociateListItem } from "./AssociateListItem";
import { Complete } from "./Complete";
import { Created } from "./Created";
import { EventComplete } from "./TimelineEvents/EventComplete";
import { EventCreated } from "./TimelineEvents/EventCreated";
import { EventMerchantConfirmation } from "./TimelineEvents/EventMerchantConfirmation";
import { EventMerchantConfirmed } from "./TimelineEvents/EventMerchantConfirmed";
import { EventSentToOwners } from "./TimelineEvents/EventSentToOwners";
import { ToAssociates } from "./ToAssociates";
import { ToSignature } from "./ToSignature";
import { ToVerification } from "./ToVerification";
import { ToVerificationComplete } from "./ToVerificationComplete";
import { EventDocument } from "./TimelineEvents/EventDocument";
import { ConfirmDoc } from "./ConfirmDocs/ConfirmDoc";
import { contractBankState } from "../../../state/contractBankState";
import { RemoteIdStatus } from "../../../data/dataConfirm";
import { AssociateRole } from "../../../model/associate/associateTypes";
import { EventBank } from "./TimelineEvents/EventBank";
import { ToBank } from "./ToBank";
import "./Timeline.scss";
import { userState } from "../../../state/userState";
import { NewOverlay } from "../../../components/overlay/NewOverlay";

const MISSING_DATE = " - ";
export const ITEM_OFFSET = 20;
export const ITEM_HEIGHT = 85;
export const ITEM_HEIGHT_EVENT = 120;
export const ITEM_HEIGHT_ASSOCIATE = 180;

export function Timeline() {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const merchantStatus = useRecoilValue(merchantStatusState);
  const contractStatus = useRecoilValue(contractStatusState);
  const bankAccount = useRecoilValue(contractBankState);
  const associates = useRecoilValue(legalAssociatesSelector);
  const documents = useRecoilValue(contractDocumentsState);

  const user = useRecoilValue(userState);
  const [confirmGenericDoc, setConfirmGenericDoc] = useState<DocumentId | null>(null);
  const isContractOwner = getUserIsAllowedToEdit(contractStatus, user);

  const primaryContact = useMemo(
    () => associates.find((manager) => manager.roles.indexOf(AssociateRole.PRIMARY_CONTACT) > -1),
    [associates]
  );

  const onClose = useCallback(() => {
    setConfirmGenericDoc(null);
  }, []);

  const createdDate = getIntlDate(lang, contractStatus.created);
  const sentToMerchantDate = getIntlDate(lang, merchantStatus.confirmationSent);
  const sentToOwnersForSignature = getIntlDate(lang, merchantStatus.merchantConfirmed);

  const merchantHasConfirmedContract = merchantStatus.merchantConfirmed !== undefined;

  //TODO Merchant complete, signoffComplete

  const completeDate = getIntlDate(lang, merchantStatus.contractCompleted);

  const onConfirmDoc = useCallback((doc) => setConfirmGenericDoc(doc.id), []);

  //TODO Is confirmed är lite förvirrande, det betyder att kontraktet är färdigt
  const enableConfirmDetails =
    //I can edit
    isContractOwner &&
    //Contract is not complete
    !merchantStatus.isConfirmed &&
    // Merchant has confirmed the contract details
    merchantHasConfirmedContract;

  const buttonStatus = getEditStatus(enableConfirmDetails, Status.DEFAULT);

  const associateRemoteIdStatus = new Map(merchantStatus.remoteIdStatus.map((s) => [s.associateId, s]));

  const associateTracking = new Map(merchantStatus.tracking.map((t) => [t.associateId, t]));

  return (
    <div className="timeline">
      <NewOverlay open={!!confirmGenericDoc} onClose={onClose} className="overlay-confirm-doc">
        <ConfirmDoc onClose={onClose} document={documents.find((v) => v.id === confirmGenericDoc) || null} />
      </NewOverlay>

      <ul className="timeline-tags">
        <EventCreated createdDate={createdDate} />

        <EventMerchantConfirmation
          sentToMerchantDate={sentToMerchantDate}
          sentToOwnersForSignature={sentToOwnersForSignature}
          contractStatus={contractStatus}
          contractId={contractStatus.contractId}
          primaryContact={primaryContact}
        />

        <EventMerchantConfirmed
          sentToOwnersForSignature={sentToOwnersForSignature}
          hasMultipleAssociates={associates.length > 1}
        />

        {documents.map((doc) => (
          <EventDocument
            key={doc.id}
            document={doc}
            active={merchantHasConfirmedContract}
            onConfirmDoc={onConfirmDoc}
            buttonStatus={buttonStatus}
          />
        ))}

        <EventBank active={sentToOwnersForSignature !== MISSING_DATE} buttonStatus={buttonStatus} />

        <EventSentToOwners sentToOwnersForSignature={sentToOwnersForSignature} />

        {associates.map((person) => (
          <AssociateListItem
            contractId={contractStatus.contractId}
            key={person.associateId}
            associate={person}
            enableConfirmDetails={enableConfirmDetails}
            bankAccount={bankAccount}
            remoteIdStatus={
              associateRemoteIdStatus.get(person.associateId) ||
              //Not pretty, but we will always have an associate status for these
              ({ associateId: person.associateId } as RemoteIdStatus)
            }
            associateTracking={associateTracking.get(person.associateId)}
          />
        ))}

        <EventComplete completeDate={completeDate} merchantStatus={merchantStatus} />
      </ul>
      <svg className="timeline-sketch">
        <Created />
        <ToVerification />
        <ToVerificationComplete />
        <ToSignature documents={documents} active={sentToOwnersForSignature !== MISSING_DATE} />
        <ToBank documents={documents} active={sentToOwnersForSignature !== MISSING_DATE} />
        <ToAssociates
          documents={documents}
          associates={associates}
          active={sentToOwnersForSignature !== MISSING_DATE}
        />
        <Complete merchantStatus={merchantStatus} documents={documents} associates={associates} />
      </svg>
    </div>
  );
}
