import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  createdDate: string;
}

export const EventCreated: React.FunctionComponent<Props> = ({
  createdDate,
}) => {
  const { t } = useTranslation();
  return (
    <li className="active">
      <div className="event">
        <div className="timeline-date">{createdDate}</div>
        <b>{t("Contract created")}</b>
      </div>
    </li>
  );
};
