import React from "react";
import cx from "classnames";
import { Image } from "../../../../components/images/Image";
import "./ProviderLogo.scss";

interface Props {
  className?: string;
}

export const JCB: React.FunctionComponent<Props> = ({ className }) => (
  <div className={cx("provider-logo", "logo-jcb", className)}>
    <Image
      src="/images/cards/jcb_logo_300x231_51.svg"
      ratio={300 / 231.51}
      alt="JCB logo"
    />
  </div>
);
