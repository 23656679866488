import React, { useState, useEffect, useRef } from "react";
import { Trans } from "react-i18next";
import { useRecoilValue } from "recoil";
import { ownerSharesSelector } from "../../state/contractAssociateState";
import { AnimateHeight } from "../animate/AnimateHeight";
import { WarningBox } from "../boxes/WarningBox";
import { ErrorBox } from "../boxes/ErrorBox";

interface Props {
  capitalStake: number;
  votingRightsStake: number;
}

enum ShareWarning {
  VOTING_EXCEEDS = "VOTING_EXCEEDS",
  CAPITAL_EXCEEDS = "CAPITAL_EXCEEDS",
  VOTING_BELOW = "VOTING_BELOW",
  CAPITAL_BELOW = "CAPITAL_BELOW",
  NONE = "",
}

function getWarning(votingRights: number, capitalStake: number) {
  if (votingRights > 100) {
    return ShareWarning.VOTING_EXCEEDS;
  }

  if (capitalStake > 100) {
    return ShareWarning.CAPITAL_EXCEEDS;
  }

  if (votingRights < 100) {
    return ShareWarning.VOTING_BELOW;
  }

  if (capitalStake < 100) {
    return ShareWarning.CAPITAL_BELOW;
  }

  return ShareWarning.NONE;
}

export const OwnershipShareWarning: React.FunctionComponent<Props> = ({
  capitalStake,
  votingRightsStake,
}) => {
  const shareValues = useRecoilValue(ownerSharesSelector);

  const { totalVotingRights, totalCapitalStake } = shareValues;
  const totalVotingRightsByOthers = useRef(
    totalVotingRights - votingRightsStake
  );
  const totalCapitalStakeByOthers = useRef(totalCapitalStake - capitalStake);
  const [warning, setWarning] = useState<ShareWarning>(
    getWarning(totalVotingRights, totalCapitalStake)
  );

  useEffect(() => {
    const { totalVotingRights, totalCapitalStake } = shareValues;
    totalVotingRightsByOthers.current = totalVotingRights - votingRightsStake;
    totalCapitalStakeByOthers.current = totalCapitalStake - capitalStake;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareValues]);

  useEffect(() => {
    setWarning(
      getWarning(
        totalVotingRightsByOthers.current + votingRightsStake,
        totalCapitalStakeByOthers.current + capitalStake
      )
    );
  }, [
    votingRightsStake,
    capitalStake,
    totalCapitalStakeByOthers,
    totalVotingRightsByOthers,
  ]);

  return (
    <div className="m-top-10">
      <AnimateHeight name={warning}>
        <div>
          {warning === ShareWarning.VOTING_EXCEEDS && (
            <ErrorBox relative>
              <Trans>
                At the moment, the <b>voting rights</b> between all owners{" "}
                <b>exceeds 100%</b>. You should update your owners so that that
                the total voting rights either equals 100% or, if there are
                minority owners with a voting stake below 25%, falls below 100%.
              </Trans>
            </ErrorBox>
          )}
          {warning === ShareWarning.CAPITAL_EXCEEDS && (
            <ErrorBox relative>
              {" "}
              <Trans>
                At the moment, the <b>capital shares</b> between all owners
                <b> exceeds 100%</b>. You should update your owners so that that
                the total capital shares either equals 100% or, if there are
                minority owners with a capital share below 25%, falls below
                100%.
              </Trans>
            </ErrorBox>
          )}
          {warning === ShareWarning.VOTING_BELOW && (
            <WarningBox relative>
              <Trans>
                At the moment, the <b>voting rights</b> between all owners{" "}
                <b>falls below 100%</b>. This is okey, there might be minority
                owners (with a voting stake below 25%) that are not registered
                here. If there are no minority owners however, the total voting
                rights should equal 100%.
              </Trans>
            </WarningBox>
          )}

          {warning === ShareWarning.CAPITAL_BELOW && (
            <WarningBox relative>
              <Trans>
                At the moment, the <b>capital shares</b> between all owners{" "}
                <b>falls below 100%</b>. This is okey, there might be minority
                owners (with a capital share below 25%) that are not registered
                here. If there are no minority owners however, the total capital
                shares should equal 100%.
              </Trans>
            </WarningBox>
          )}
        </div>
      </AnimateHeight>
    </div>
  );
};
