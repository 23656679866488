import { AssociateRole } from "./associateTypes";

const ASSOCIATE_ROLE_DISPLAY_STRING_MAP: Record<AssociateRole, string> = {
  [AssociateRole.PRIMARY_CONTACT]: "Primary contact",
  [AssociateRole.SIGNATORY]: "Signatory",
  [AssociateRole.ACCOUNT_HOLDER]: "Account holder",
  [AssociateRole.BENEFICIAL_OWNER]: "Beneficial owner",
  [AssociateRole.PORTAL_LOGIN]: "Portal login",
  [AssociateRole.STORE_CONTACT]: "Store contact",
};

export const getAssociateRoleDisplayName = (role: AssociateRole) => {
  const roleString = ASSOCIATE_ROLE_DISPLAY_STRING_MAP[role];
  return roleString ?? role;
};

const associateRoleOrder = [
  AssociateRole.PRIMARY_CONTACT,
  AssociateRole.BENEFICIAL_OWNER,
  AssociateRole.SIGNATORY,
  AssociateRole.ACCOUNT_HOLDER,
  AssociateRole.PORTAL_LOGIN,
];

export const sortAssociateRoles = (roleA: AssociateRole, roleB: AssociateRole) => {
  const indexA = associateRoleOrder.indexOf(roleA);
  const indexB = associateRoleOrder.indexOf(roleB);
  return indexA - indexB;
};
