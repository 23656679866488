import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button } from "../../../components/interactions/Buttons/Button";
import { ScrollPositionAnchor } from "../../../components/scrollPosition/ScrollPositionAnchor";
import { dataConfirm } from "../../../data/dataConfirm";
import { DocumentId } from "../../../model/common/commonType";
import { BankAccountSource, ContractDocument, DocumentType } from "../../../model/contract/contractType";
import { documentTypeDescription, documentTypeDisplay } from "../../../model/contract/contractUtils";
import { Status } from "../../../data/types";
import { legalAssociatesSelector } from "../../../state/contractAssociateState";
import { contractDocumentsState } from "../../../state/contractDocumentsState";
import { contractStatusState } from "../../../state/contractStatusState";
import { LinkAnchors } from "../ContractEdit";
import { AddCustomDoc } from "./AddCustomDoc";
import styles from "./DocumentsSection.module.scss";
import { SectionFieldSet } from "../../../components/sectionFieldSet/SectionFieldSet";
import { HiOutlineIdentification } from "react-icons/hi2";
import { Associate, AssociateRole } from "../../../model/associate/associateTypes";

import { TrashIcon } from "../../../components/icons/TrashIcon";
import { FormName } from "../menus/ContractEditMenu";
import { contractBankState } from "../../../state/contractBankState";
import { RiBankLine } from "react-icons/ri";
import { getFullname } from "../../../components/utils";
import { IoDocumentTextOutline } from "react-icons/io5";

function getName(firstName?: string, lastName?: string) {
  if (firstName && lastName) {
    return `${lastName}, ${firstName}`;
  }

  return firstName || lastName || "No name set";
}

export function DocumentsSection() {
  const { t } = useTranslation();
  const associates = useRecoilValue(legalAssociatesSelector);
  const [documents, setDocuments] = useRecoilState(contractDocumentsState);
  const filteredDocuments = documents.filter(
    (doc) =>
      doc.documentType !== DocumentType.E_COMMERCE_SUPPLEMENT &&
      doc.documentType !== DocumentType.BANK_STATEMENT
  );
  const contractStatus = useRecoilValue(contractStatusState);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const buttonStatus = contractStatus.edit ? status : Status.DISABLED;
  const bank = useRecoilValue(contractBankState);
  const primaryContact = associates.find(({ roles }) => roles.includes(AssociateRole.PRIMARY_CONTACT));

  function removeCustomDocument(docId: DocumentId) {
    dataConfirm
      .removeSalesCustomDocument(contractStatus.contractId, docId)
      .then((docs) => setDocuments(docs))
      .catch((err) => {
        setStatus(Status.ERROR);
        setTimeout(() => setStatus(Status.DEFAULT), 4000);
      });
  }

  return (
    <div className="uploads">
      <ScrollPositionAnchor id={LinkAnchors.UPLOADS.anchor} />

      <SectionFieldSet headerTitle={t("Documents")} formName={FormName.DOCUMENTS}>
        <div className="fw-500 m-bottom-30">
          {t("These are the documents that will be required by the merchant")}
        </div>

        {associates.length ? (
          <>
            <div className="fw-600 m-bottom-10">{t("Identification documents")}</div>
            {associates.map((associate) => (
              <IdentificationDocument key={associate.associateId} associate={associate} />
            ))}
          </>
        ) : null}
        <div className="m-top-40">
          <div className="fw-600 m-bottom-10">{t("Other documents")}</div>

          {bank.source === BankAccountSource.BANK_STATEMENT && (
            <BankStatement primaryContact={primaryContact} />
          )}

          {filteredDocuments.map((doc) => (
            <OtherDocument
              key={doc.id}
              document={doc}
              onRemove={() => removeCustomDocument(doc.id)}
              buttonStatus={buttonStatus}
            />
          ))}

          {documents.length || bank.source === BankAccountSource.BANK_STATEMENT ? (
            <br />
          ) : (
            <p className="fw-500 text-passive">
              {t("There are no other documents associated with this contract")}
            </p>
          )}
        </div>

        <AddCustomDoc />
      </SectionFieldSet>
    </div>
  );
}

interface IdentificationDocumentProps {
  associate: Associate;
}

const IdentificationDocument: FC<IdentificationDocumentProps> = ({ associate }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.identitficationDocument}>
      <div className={styles.iconWrapper}>
        <HiOutlineIdentification />
      </div>

      <ul className={styles.data}>
        <li className="fw-500">{getName(associate.firstName, associate.lastName)}</li>
        <li className="fs-small text-passive fw-500">
          {t("Identification documents (passport or driver's license), selfie and signature")}
        </li>
      </ul>
    </div>
  );
};

interface OtherDocumentProps {
  document: ContractDocument;
  onRemove: (docId: string) => void;
  buttonStatus: Status;
}

const OtherDocument: FC<OtherDocumentProps> = ({ document, onRemove, buttonStatus }) => {
  const { t } = useTranslation();
  const isCustomDoc = document.documentType === DocumentType.SALES_CUSTOM_REQUEST;
  const description = isCustomDoc
    ? document.documentDescription
    : t(documentTypeDescription(document.documentType));

  return (
    <div className={styles.identitficationDocument}>
      <div className={styles.iconWrapper}>
        <IoDocumentTextOutline />
      </div>

      <ul className={styles.data}>
        <li className="fw-500">{t(documentTypeDisplay(document.documentType))}</li>
        <li className="fs-small text-passive fw-500">{description}</li>
      </ul>
      {isCustomDoc && (
        <Button
          status={buttonStatus}
          onClick={onRemove}
          className={styles.remove}
          variant="text"
          color="danger"
          action
        >
          <TrashIcon />
        </Button>
      )}
    </div>
  );
};

interface BankStatementProps {
  primaryContact?: Associate;
}

const BankStatement: FC<BankStatementProps> = ({ primaryContact }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.identitficationDocument}>
      <div className={styles.iconWrapper}>
        <RiBankLine />
      </div>

      <ul className={styles.data}>
        <li className="fw-500">
          {primaryContact
            ? t("Bank statement provided by {{name}}", {
                name: getFullname(primaryContact),
              })
            : t("Bank statement")}
        </li>
        <li className="fs-small text-passive fw-500">
          {t(documentTypeDescription(DocumentType.BANK_STATEMENT))}
        </li>
      </ul>
    </div>
  );
};
