import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { ErrorBox } from "../../../../../components/boxes/ErrorBox";
import { WarningBox } from "../../../../../components/boxes/WarningBox";

interface Props {
  contractTemplateId: number;
  contractPricingExists: boolean;
}

export const NoTemplatesFound: FC<Props> = ({ contractTemplateId, contractPricingExists }) => {
  const { t } = useTranslation();

  if (contractPricingExists) {
    return (
      <div className="m-bottom-20">
        <WarningBox relative>
          {t(
            "There are no price plans assigned to your team. However, this contract has already got a price plan ({{pricePlan}}) set so you might still continue to use it.",
            {
              pricePlan: contractTemplateId,
            }
          )}
        </WarningBox>
      </div>
    );
  } else {
    return (
      <div className="m-bottom-20">
        <ErrorBox relative>
          <Trans>
            <b>Ooh noo!</b> There are no pricing plans assigned to your team. There should be a
            sales manager somewhere that can help?
          </Trans>
        </ErrorBox>
      </div>
    );
  }
};
