import React from "react";
import cx from "classnames";
import { Image } from "../../../../components/images/Image";
import "./ProviderLogo.scss";

interface Props {
  className?: string;
}

export const Union: React.FunctionComponent<Props> = ({ className }) => (
  <div className={cx("provider-logo", "logo-union", className)}>
    <Image
      src="/images/cards/union_pay_logo_256x160.svg"
      ratio={256 / 160}
      alt="Union pay logo"
    />
  </div>
);
