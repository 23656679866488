import { data } from "./data";

import {
  BankAccount,
  ContractDocument,
  ContractStatus,
  IdType,
  VatStatus,
} from "../model/contract/contractType";
import { LinkId, UTCDate, AssociateId, ContractId, DocumentId } from "../model/common/commonType";
import { AssociateRole, AssociateIdentity, Associate } from "../model/associate/associateTypes";

export enum InviteType {
  INVITE_TO_CONFIRM = "INVITE_TO_CONFIRM",
  INVITE_TO_SUBMIT = "INVITE_TO_SUBMIT",
  REMINDER_TO_SUBMIT = "REMINDER_TO_SUBMIT",
  RE_SUBMIT_DOCUMENT = "RE_SUBMIT_DOCUMENT",
}

export enum LiveVerifier {
  RIGHT_TOP = "RIGHT_TOP",
  RIGHT_BOTTOM = "RIGHT_BOTTOM",
  LEFT_TOP = "LEFT_TOP",
  LEFT_BOTTOM = "LEFT_BOTTOM",
}

interface ViewLog {
  linkId: LinkId;
  created: UTCDate;
  ip: string;
}

export interface SalesContact {
  name: string;
  phoneNumber?: string;
  email: string;
}

interface MerchantAssociateSignature {
  uploaded: UTCDate;
  image: string; //Base64 image, borde vi ha en bättre value class?
  ipAddress: string;
  country?: string;
  city?: string;
}

interface RemoteIdentificationSelfie {
  uploaded?: UTCDate;
  signedOff?: UTCDate;
  signedOffBy?: string;
  liveVerifier: LiveVerifier;
  image?: string; //base64
  ipAddress?: string;
  userAgent?: string;
  country?: string;
  city?: string;
}

interface RemoteIdentificationDocument {
  uploaded?: UTCDate;
  signedOff?: UTCDate;
  signedOffBy?: string;
  passport?: string;
  idFront?: string;
  idBack?: string;
  ipAddress?: string;
  userAgent?: string;
  country?: string;
  city?: string;
}

export interface MerchantAssociateResponse {
  name: string;
  roles: AssociateRole[];
  signedOff?: UTCDate;
  signedOffBy?: UTCDate;
  signature?: MerchantAssociateSignature;
  identificationData: AssociateIdentity;
  selfie: RemoteIdentificationSelfie;
  identityDocument: RemoteIdentificationDocument;
}

export interface ConstrainedAssociate extends Omit<Associate, "documents"> {
  documents?: {
    idCard: ContractDocument;
  };
}

export type SaveAssociate = Partial<Associate>;

export interface EmailLink {
  id: LinkId;
  email: string;
  created: UTCDate;
  associateId: AssociateId;
  //Link doesn't work anymore
  revoked?: UTCDate;
  revokedBy?: string;
  //When the email was sent, if its missing it means its stalled internally
  sent?: UTCDate;
  sendBy: UTCDate;
  //When the email was considered delivered to the remote email server
  delivered?: UTCDate;
  //When the tracking pixel was triggered
  opened?: UTCDate;
  //When the recipent clicked the link in the email
  visited?: UTCDate;
  //If the email couldn't be deliverd
  deliveryFailure?: UTCDate;
  failureReason?: string;
  viewLog: ViewLog[];
  linkType: InviteType;
}

export interface SmsLink {
  id: LinkId;
  number: string;
  created: UTCDate;
  associateId: AssociateId;
  //Link doesn't work anymore
  revoked?: UTCDate;
  revokedBy?: string;
  //When the sms was sent, if its missing it means its stalled internally
  sent?: UTCDate;
  sendBy: UTCDate;
  //When the sms was considered delivered to the remote provider
  delivered?: UTCDate;
  visited?: UTCDate;
  //If the sms couldn't be deliverd
  failed?: UTCDate;
  viewLog: ViewLog[];
  linkType: InviteType;
}

export enum CommunicationEvent {
  SENT = "SENT",
  DELIVERED = "DELIVERED",
  DELIVERY_FAILED = "DELIVERY_FAILED",
  MESSAGE_VIEWED = "MESSAGE_VIEWED",
  CONTRACT_VIEWED = "CONTRACT_VIEWED",
  REMINDER = "REMINDER",
  REVOKED = "REVOKED",
  RE_ACTIVATED = "RE_ACTIVATED",
  CREATED = "CREATED",
}

export interface CommunicationTrackingLog {
  created: UTCDate;
  associateId: AssociateId;
  //Email / Number that received the message
  communicationRecipient: string;
  event: CommunicationEvent;
  merchantIp?: string;
  //Om ett event är triggat av en sales kommer namnet stå här, tex en revoke
  salesUserName?: string;
}

export interface AssociateTracking {
  associateId: AssociateId;
  smsLink?: SmsLink;
  emailLink: EmailLink;
  trackingLog: CommunicationTrackingLog[];
}

export interface RemoteIdStatus {
  associateId: AssociateId;
  uploadedSelfie?: UTCDate;
  signedOffSelfie?: UTCDate;
  uploadedIdDocument?: UTCDate;
  signedOffIdDocument?: UTCDate;
}

enum KlarnaAccountType {
  DEFAULT = "DEFAULT",
  SAVING = "SAVING",
  CREDITCARD = "CREDITCARD",
  DEPOT = "DEPOT",
  UNKNOWN = "UNKNOWN",
}

export enum KlarnaAccountStatus {
  MATCH = "MATCH",
  VALID = "VALID",
  NO_HOLDER = "NO_HOLDER",
  NO_IBAN = "NO_IBAN",
  INVALID_TYPE = "INVALID_TYPE",
  INVALID_CURRENCY = "INVALID_CURRENCY",
}

export interface ExternalBankAccount {
  alias?: string;
  accountNumber?: string;
  iban: string;
  bic: string;
  transferType: string;
  accountType: KlarnaAccountType;
  holderName?: string;
  accountStatus: KlarnaAccountStatus;
}

export interface ExternalBankAccountSessions {
  associateId: string;
  sessionId: string;
  shortSessionId: string;
  flowId: string;
  created: UTCDate;
  terminated?: UTCDate;
  bankName: string;
  bankCode: string;
  bankCountry: string;
  accounts: ExternalBankAccount[];
}

export interface BankAccountRequest
  extends Pick<BankAccount, "cas" | "bic" | "bank" | "iban" | "accountHolder"> {}

export interface ConfirmStatus {
  status: ContractStatus;
  confirmationSent?: UTCDate;
  //timestmap of when the merchant confirmed the contract-data
  merchantConfirmed?: UTCDate;
  //timestamp of when the contract was considered complete with all input required from merchant
  merchantComplete?: UTCDate;
  //Timestamp of when all associate and document where signed off
  allSignedOff?: UTCDate;
  //Timestamp of when contract was marked as completed
  contractCompleted?: UTCDate;
  salesContact: SalesContact;
  tracking: AssociateTracking[];
  remoteIdStatus: RemoteIdStatus[];
}

export interface CompletedContract {
  //Target email for the backoffice email
  //Should we add customer recipient?
  backofficeRecipient: string;

  //When the job was started to create the contract files
  started: UTCDate;
  //The sales admin that clicked "complete"
  startedBy: string;

  //Timestamp of when we successfully sent the email to backoffice
  backofficeEmailSent?: UTCDate;

  backofficeEmailViewed?: UTCDate;
  backofficeAttachmentDownloaded?: UTCDate;
  //Timestamp of when we successfully sent the email to the customer
  merchantEmailSent?: UTCDate;

  //Timestamp of when backoffice is done working on the contract,
  // we might not always get this, it depends on when they click the link in the email
  backofficeProvisioningCompleted?: UTCDate;
  //If the contract had softpos package, we will try to dispatch
  //the contract to softpos after backoffice is done
  softposProvisioningCompleted?: UTCDate;

  //If we have a merchantId, backoffice is done with creating
  // the customer and we might have started provisioning softpos
  merchantId?: string;

  passDirection: string;
  passMerchandiser: string;
}

export interface TextBlock {
  text: string;
  width: number; //Percentage of total image, ex: 0.223 = 22.3% of the image total width
  height: number;
  left: number; // Percentage offset from the left side of the image
  top: number;
}

export interface ResolvedData {
  idType?: IdType;
  documentNumber?: string;
  nationality?: string; //2 alpha
  issuingCountry?: string; //2 alpha
  issuingAuthority?: string;
  dateOfBirth?: string; //iso date
  issueDate?: string; //iso date
  expiryDate?: string; //iso date
}

export interface IdScan {
  passport?: TextBlock[];
  idFront?: TextBlock[];
  idBack?: TextBlock[];
  resolvedData: ResolvedData;
}

export interface TracingSmsLink {
  id: LinkId;
  number: string;
  linkType: string;
  sent: UTCDate;
  failed?: UTCDate;
}

export interface TracingEmailLink {
  id: LinkId;
  email: string;
  linkType: string;
  sent: UTCDate;
  failed?: UTCDate;
}

export interface GeoIpDetails {
  ip: string;
  country: string;
  countryCode: string;
  city?: string;
}

export interface TracingLog {
  id: number;
  event: CommunicationEvent;
  created: UTCDate;
  recipient: string;
  salesUserName?: string;
  merchantIpLookup?: GeoIpDetails;
}

export interface TracingInformation {
  associateId: AssociateId;
  phoneNumber?: string;
  email?: string;
  smsLink?: TracingSmsLink;
  emailLink?: TracingEmailLink;
  lastVisit?: TracingLog;
  communicationLog: TracingLog[];
}

export interface VatResponse {
  result: VatStatus;
  valid: boolean;
}

export const dataConfirm = {
  getConfirmStatus: (contractId: ContractId, force = false) =>
    data.get<ConfirmStatus>(`/api/sales/merchant/${contractId}`, force),

  getAssociates: (contractId: ContractId, force = false) =>
    data.get<Associate[]>(`/api/sales/contracts/${contractId}/associates`, force),

  getAssociate: (contractId: ContractId, associateId: AssociateId, force = false) =>
    data.get<Associate>(`/api/sales/contracts/${contractId}/associates/${associateId}`, force),

  rejectAssociateDocument: (contractId: ContractId, associateId: AssociateId, documentId: DocumentId) =>
    data.delete<Associate[]>(
      `/api/sales/merchant/${contractId}/associates/${associateId}/document/${documentId}`
    ),

  rejectAssociateSignature: (contractId: ContractId, associateId: AssociateId) =>
    data.delete<Associate[]>(`/api/sales/merchant/${contractId}/associates/${associateId}/signature`),

  signoffOnAssociate: (contractId: ContractId, associateId: AssociateId) =>
    data.post<Associate[]>(`/api/sales/merchant/${contractId}/associate/${associateId}/signoff`),

  loadCompletedContract: (contractId: ContractId, force = true) =>
    data.get<CompletedContract>(`/api/sales/contracts/completed/${contractId}`, force),

  addSalesCustomDocument: (contractId: ContractId, description: string) => {
    return data.post<ContractDocument[]>(`/api/sales/contracts/${contractId}/document/custom`, {
      documentDescription: description,
    });
  },

  resendMerchantEmail: (contractId: ContractId) => {
    return data.post(`/api/sales/contracts/completed/${contractId}/email/merchant`);
  },

  resendBackofficeEmail: (contractId: ContractId) => {
    return data.post(`/api/sales/contracts/completed/${contractId}/email/backoffice`);
  },

  removeSalesCustomDocument: (contractId: ContractId, documentId: DocumentId) =>
    data.delete<ContractDocument[]>(`/api/sales/contracts/${contractId}/document/custom/${documentId}`),

  saveAssociateIdentity: (contractId: ContractId, associateId: AssociateId, identity: AssociateIdentity) => {
    return data.post<Associate[]>(
      `/api/sales/merchant/${contractId}/associates/${associateId}/identity`,
      identity
    );
  },

  addBankAccount: (contractId: ContractId, bank: BankAccountRequest) =>
    data.post<BankAccount>(`/api/sales/merchant/${contractId}/bank`, bank),

  rejectBankAccount: (contractId: ContractId) => data.delete(`/api/sales/merchant/${contractId}/bank`),

  getIdScan: (contractId: ContractId, associateId: AssociateId) =>
    data.get<IdScan>(`/api/sales/merchant/${contractId}/associate/${associateId}/scan-id`),

  fixVies: (contractId: ContractId) => data.post<VatResponse>(`/api/admin/fix/vies?contractId=${contractId}`),

  fixViesByDbUpdate: (contractId: ContractId, vat: string) =>
    data.post<VatResponse>(`/api/admin/fix/vies?contractId=${contractId}&vatNumber=${vat}`),

  fixContract: (contractId: ContractId) =>
    data.post(`/api/admin/fix/merchant-completeness?contractId=${contractId}`),

  getExternalBankAccounts: (contractId: ContractId, associateId: AssociateId) =>
    data.get<ExternalBankAccountSessions[]>(`/api/sales/merchant/${contractId}/klarna/${associateId}`),

  getTrackingInformation: (contractId: ContractId, associateId: AssociateId, refreshCache = false) =>
    data.get<TracingInformation>(
      `/api/sales/merchant/${contractId}/associates/${associateId}/tracing`,
      refreshCache
    ),

  confirmContractComplete: (contractId: ContractId, merchandiser: string, direction: string) =>
    data.post<void>(`/api/sales/merchant/${contractId}/complete`, {
      merchandiser,
      direction,
    }),

  retractContract: (contractId: ContractId) => data.post<void>(`/api/sales/merchant/${contractId}/retract`),

  sendReminders: (contractId: ContractId) => data.post(`/api/sales/merchant/${contractId}/links/reminders`),

  resendLink: (contractId: ContractId, associateId: AssociateId, email: string, phoneNumber: string) =>
    data.post<ConfirmStatus>(`/api/sales/merchant/${contractId}/associates/${associateId}/links`, {
      email: email,
      phoneNumber: phoneNumber,
    }),

  deleteLink: (contractId: ContractId, linkId: LinkId) =>
    data.delete<ConfirmStatus>(`/api/sales/merchant/${contractId}/links/${linkId}`),

  createNewSmsLink: (contractId: ContractId, associateId: AssociateId) =>
    data.post(`/api/sales/merchant/${contractId}/associate/${associateId}/new-sms-link`),

  createNewEmailLink: (contractId: ContractId, associateId: AssociateId) =>
    data.post(`/api/sales/merchant/${contractId}/associate/${associateId}/new-email-link`),

  confirmUploadedDocument: (contractId: ContractId, documentId: DocumentId) =>
    data.post<ContractDocument[]>(`/api/sales/merchant/${contractId}/document/${documentId}`),

  rejectUploadedDocument: (contractId: ContractId, documentId: DocumentId) =>
    data.delete<ContractDocument[]>(`/api/sales/merchant/${contractId}/document/${documentId}`),

  loadAssociateRemoteId: (contractId: ContractId, associateId: AssociateId) =>
    data.get<MerchantAssociateResponse>(`/api/sales/merchant/${contractId}/associates/${associateId}`),

  signOffIddocument: (contractId: ContractId, associateId: AssociateId, timeSpentReviewing: number) =>
    data.post(`/api/sales/merchant/${contractId}/associates/${associateId}/iddocument`, {
      timeSpentReviewing,
    }),

  signOffSelfie: (contractId: ContractId, associateId: AssociateId, timeSpentReviewing: number) =>
    data.post(`/api/sales/merchant/${contractId}/associates/${associateId}/selfie`, {
      timeSpentReviewing,
    }),

  rejectSelfie: (contractId: ContractId, associateId: AssociateId) =>
    data.delete(`/api/sales/merchant/${contractId}/associates/${associateId}/selfie`),

  rejectIdDocument: (contractId: ContractId, associateId: AssociateId) =>
    data.delete(`/api/sales/merchant/${contractId}/associates/${associateId}/iddocument`),
};
