import React, { useCallback } from "react";
import { Status } from "../../../../../../data/types";
import { Trash } from "../../../../../../components/icons/Trash";
import { Button } from "../../../../../../components/interactions/Buttons/Button";
import { useTranslation } from "react-i18next";
import { Terminal as TerminalInterface, TerminalType } from "../../../../../../model/contract/contractType";
import { Store2 } from "../../../../../../model/contract/contractType";
import { isFirstTerminalIncluded } from "./Terminals";
import { Country } from "../../../../../../model/common/commonType";

interface Props {
  terminal: TerminalInterface;
  inputStatus: Status;
  saveStore: (store: Store2) => void;
  store: Store2;
  index: number;
  country: Country;
}

const TerminalName: React.VoidFunctionComponent<{
  terminalType: TerminalType;
  country: Country;
}> = ({ terminalType, country }) => {
  const { t } = useTranslation();

  if (country === Country.CZECHIA) {
    if (terminalType === TerminalType.SOFT_POS) {
      return t("PayPhone");
    }
  }
  if (terminalType === TerminalType.SOFT_POS) {
    return t("Worldline Tap on Mobile");
  }
  return t(terminalType);
};

export const Terminal: React.FunctionComponent<Props> = ({
  terminal,
  inputStatus,
  saveStore,
  store,
  index,
  country,
}) => {
  const { t } = useTranslation();
  const onRemove = useCallback(() => {
    const copy = store.terminals.filter((item) => item.id !== terminal.id);

    saveStore({
      ...store,
      terminals: copy,
    });
  }, [terminal, saveStore, store]);

  const tag =
    index === 0 && isFirstTerminalIncluded(country) ? (
      <>
        <TerminalName terminalType={terminal.terminalType} country={country} />{" "}
        {index === 0 && <i>({t("included")})</i>}
      </>
    ) : (
      <TerminalName terminalType={terminal.terminalType} country={country} />
    );

  return (
    <li className="terminal-row">
      <ul>
        <li className="terminal-name">{tag}</li>
        <li className="terminal-remove">
          <Button type="button" action onClick={onRemove} className="mini" status={inputStatus}>
            <Trash />
          </Button>
        </li>
      </ul>
    </li>
  );
};
