import {
  dataPassUsers,
  PassCategory,
  PassLookup,
} from "../../../data/dataPassUsers";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export type PassLookupSuccess = (lookup: PassLookup) => void;

export class PassIdValidator extends BaseValidator {
  name = "PassId validator";
  private category: PassCategory;
  private onSuccessLookup: PassLookupSuccess;
  constructor(
    category: PassCategory,
    error: string,
    onSuccessLookup: PassLookupSuccess
  ) {
    super(error);
    this.category = category;
    this.onSuccessLookup = onSuccessLookup;
  }

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (value.length === 0) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
        return;
      }

      dataPassUsers
        .lookupPassUser(value, this.category)
        .then((lookup) => {
          if (lookup.found) {
            resolve({
              status: VALIDATION_STATE.SUCCESS,
            });
            this.onSuccessLookup(lookup);
          } else {
            resolve({
              status: VALIDATION_STATE.FAILED,
              message: this.error,
            });
          }
        })
        .catch((err) => {
          resolve({
            status: VALIDATION_STATE.FAILED,
            message: this.error,
          });
        });
    });
  }
}
