import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { contractAssociateState } from "../../../../state/contractAssociateState";
import { contractBankState } from "../../../../state/contractBankState";
import { contractMainContractDataState } from "../../../../state/contractMainContractDataState";
import { getHolderName } from "./ConfirmBank";
import { InfoBox } from "../../../../components/boxes/InfoBox";

export const ApiConfirmed: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const associates = useRecoilValue(contractAssociateState);
  const mainData = useRecoilValue(contractMainContractDataState);
  const bankAccount = useRecoilValue(contractBankState);

  return (
    <div className="confirm-bank">
      <h3>{t("Bank account")}</h3>

      <InfoBox className="m-bottom-20">{t("This bank account was provided from the registry")}</InfoBox>

      <dl>
        <dt>{t("IBAN")}:</dt>
        <dd>{bankAccount.iban}</dd>
        <dt>{t("Bank")}:</dt>
        <dd>{bankAccount.bank}</dd>
        <dt>{t("Account holder")}:</dt>
        <dd>{getHolderName(mainData, associates, bankAccount.accountHolder)}</dd>
      </dl>
    </div>
  );
};
