import { atom, selector } from "recoil";
import { Cas } from "../model/common/commonType";
import { ContractPackage, Validity } from "../model/contract/contractType";

export const contractPackageState = atom<ContractPackage>({
  key: "contract-package-state",
  default: {
    cas: 0 as Cas,
  },
});

export const validatePackageSelector = selector<Validity>({
  key: "validate-package-selector",
  get: ({ get }) => {
    const offering = get(contractPackageState);

    if (!offering.packageId || !offering.pricingStep) {
      return Validity.DEFAULT;
    }

    return Validity.VALID;
  },
});
