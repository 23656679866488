import { FC } from "react";
import { FileIcon } from "../../icons/FileIcon";
import { Button } from "../../interactions/Buttons/Button";
import { TrashIcon } from "../../icons/TrashIcon";
import { Status } from "../../../data/types";
import styles from "./UploadedFile.module.scss";
import { Link } from "../../links/Link";
import { FiImage } from "react-icons/fi";
import { FaRegFilePdf } from "react-icons/fa";
import { DocumentType } from "../../../model/contract/contractType";
import { documentTypeDisplay } from "../../../model/contract/contractUtils";

interface Props {
  fileName?: string;
  description: string;
  documentType: DocumentType;
  mimeType?: string;
  status?: Status;
  deleteFile: () => void;
  viewFileUrl: string;
}

const UploadedFile: FC<Props> = ({
  fileName = "View file",
  description,
  status = Status.DEFAULT,
  viewFileUrl,
  deleteFile,
  mimeType,
  documentType,
}) => {
  return (
    <div className={styles.uploaded_file}>
      <div className={styles.icon}>{getMimeTypeIcon(mimeType)}</div>
      <div className={styles.body}>
        <div className={styles.titleRow} style={{ marginBottom: 2 }}>
          <Link link={viewFileUrl} external className={"fw-500"}>
            {fileName}
          </Link>
        </div>
        <ul className={styles.descriptions}>{description}</ul>
      </div>
      <div className={styles.actions}>
        <div className={styles.typeLabel}>{documentTypeDisplay(documentType)}</div>
        {status === Status.DEFAULT && (
          <Button action variant="text" color="danger" onClick={deleteFile} className={styles.delete}>
            <TrashIcon />
          </Button>
        )}
      </div>
    </div>
  );
};

export const getMimeTypeIcon = (mimeType?: string) => {
  if (mimeType?.includes("image")) return <FiImage />;
  if (mimeType?.includes("pdf")) return <FaRegFilePdf />;
  return <FileIcon />;
};

export default UploadedFile;
