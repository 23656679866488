import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { Country, Currency } from "./model/common/commonType";
import { API } from "./network/API";

const displayLanguageCache: Record<string, Intl.DisplayNames> = {};

// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
export enum Language {
  POLAND = "pl",
  CROATIA = "hr",
  UK = "en",
  FRANCE = "fr",
  CZECH = "cs",
}

export enum TRANSLATION_NAMESPACE {
  SALES = "sales",
  MERCHANT = "merchant",
  MCC = "mcc",
}

export const DEFAULT_TRANSLATION_NAMESPACE = TRANSLATION_NAMESPACE.SALES;

export const countryToLang = (country?: Country): Language => {
  if (country === Country.CZECHIA) {
    return Language.CZECH;
  }
  return country?.toLocaleLowerCase() as Language;
};

export function defaultLanguageFromCountry(country?: Country) {
  switch (country) {
    case Country.CROATIA: {
      return Language.CROATIA;
    }

    case Country.POLAND: {
      return Language.POLAND;
    }

    case Country.CZECHIA: {
      return Language.CZECH;
    }

    default: {
      return Language.UK;
    }
  }
}

export function defaultCurrencyFromCountry(country?: Country) {
  switch (country) {
    case Country.CROATIA: {
      return Currency.CROATIA;
    }

    case Country.POLAND: {
      return Currency.POLAND;
    }

    case Country.CZECHIA: {
      return Currency.CZECHIA;
    }

    default: {
      return Currency.CROATIA;
    }
  }
}

const DEBUG = false;

const SENT_CACHE = new Set<string>();

const HttpApi = new Backend(null, {
  loadPath: API.getUrl("/api/translation/{{lng}}/{{ns}}.json"),
  allowMultiLoading: false,
  crossDomain: true,
  withCredentials: true,
  requestOptions: {
    mode: "cors",
    credentials: "include",
  },
});

export const getLanguageName = (language: Language, currentLanguage?: Language | string) => {
  const lang = currentLanguage || i18n.language ? i18n.language : Language.UK.toString();
  if (!displayLanguageCache[lang]) {
    displayLanguageCache[lang] = new Intl.DisplayNames(lang, {
      type: "language",
    });
  }

  return displayLanguageCache[lang].of(language);
};

export function getCountryByLanguage(lang: Language) {
  switch (lang) {
    case Language.CROATIA:
      return Country.CROATIA;
    case Language.POLAND:
      return Country.POLAND;
    case Language.CZECH:
      return Country.CZECHIA;
    case Language.FRANCE:
      return "fr";
    default:
      return "gb";
  }
}

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ns: [DEFAULT_TRANSLATION_NAMESPACE, TRANSLATION_NAMESPACE.MCC],
    defaultNS: DEFAULT_TRANSLATION_NAMESPACE,
    // resources,
    debug: DEBUG,
    fallbackLng: Language.UK,
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    saveMissingTo: "current",
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      const language = lng[0];

      if (language === Language.UK) {
        return;
      }

      const cacheKey = language + ns + key;
      if (SENT_CACHE.has(cacheKey)) {
        return;
      }
      SENT_CACHE.add(cacheKey);
      fetch(API.getUrl(`/api/translation/${lng}/${ns}.json`), {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          site: DEFAULT_TRANSLATION_NAMESPACE,
        }),
      }).catch(() => {
        console.warn("Couldnt send missing translation [" + key + "]");
      });
    },
  });

export default i18n;
