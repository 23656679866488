import React from "react";
import { Trans } from "react-i18next";
import { HelpModal } from "../../../../../components/helpModal/HelpModal";
import foilImage1 from "./images/security-marks-foil-1.png";
import foilImage2 from "./images/security-marks-foil-2.png";
import marksImage1 from "./images/security-marks-photo-1.png";
import marksImage2 from "./images/security-marks-photo-2.png";
import marksImage3 from "./images/security-marks-photo-3.png";
import "./SecurityMarkHelpModal.scss";

export const SecurityMarkHelpModal = () => {
  return (
    <HelpModal>
      <div className="security-mark-help-modal">
        <h2>
          <Trans>Security mark</Trans>
        </h2>
        <div className="image-container">
          <div>
            <h4>
              <Trans>Security foil</Trans>
            </h4>
            <p>
              <Trans>
                An Identity card usally contains one or more security features that are called foil. They are
                usally some sort of shiny icon of a national symbol
              </Trans>
            </p>
            <div className="image-wrapper">
              <img src={foilImage1} alt="Security foil" />
              <img src={foilImage2} alt="Security foil" />
            </div>
          </div>
          <div className="m-top-20">
            <h4>
              <Trans>Holographic face photo</Trans>
            </h4>
            <p>
              <Trans>
                On the ID card there should be one or more copies of the primary picture. These smaller
                picutures are usally embeded in a transparent plastic part or can have some text being
                overlayed{" "}
              </Trans>
            </p>
            <div className="image-wrapper">
              <img src={marksImage1} alt="Security mark" />
              <img src={marksImage2} alt="Security mark" />
              <img src={marksImage3} alt="Security mark" />
            </div>
          </div>
        </div>
      </div>
    </HelpModal>
  );
};
