import React from "react";
import { ContractTableView } from "./FilterView/ContractTableView";
import { Page } from "../Page/Page";
import "./DashboardPage.scss";
import { DESKTOP_PLUS_WIDTH } from "../../hooks/useWidth";

export const DASHBOARD_ROUTE = "/dashboard";

export const DashboardPage: React.FunctionComponent = () => {
  return (
    <Page title="Dashboard" className="dashboard" contentMaxWidth={DESKTOP_PLUS_WIDTH}>
      <ContractTableView />
    </Page>
  );
};
