import { DateTime } from "luxon";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteProps, useHistory, useLocation } from "react-router";
import { useRecoilState } from "recoil";
import { Logo } from "../../components/images/Logo";
import { SupportBox } from "../../components/supportBox/SupportBox";
import { TabView } from "../../components/tabView/TabView";
import { Role, SessionResponse } from "../../data/dataAuth";
import { FilterUtils } from "../../model/Filter";
import { userState } from "../../state/userState";
import { Filter as FilterInterface, Store, STORE_KEY } from "../../Store";
import { ADMIN_DASHBOARD_ROUTE } from "../Admin/AdminDashboardPage";
import { DASHBOARD_ROUTE } from "../Dashboard/DashboardPage";
import { PageStripped } from "../Page/PageStripped";
import { LoginEmail } from "./LoginEmail";
import { LoginOtp } from "./LoginOtp";
import "./LoginPage.scss";
import { BACKOFFICE_DASHBOARD_ROUTE } from "../BackOffice/BackofficeDashboardPage";
import { LangSelector } from "../../components/langSelector/LangSelector";

export const LOGIN_ROUTE = "/";

export const LoginPage: React.FunctionComponent<RouteProps> = () => {
  const { t } = useTranslation();
  const requestId = useRef<string>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [user, setUserState] = useRecoilState(userState);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!user) return;

    const isValid = DateTime.now() < DateTime.fromISO(user.expiry);
    if (isValid) {
      const target = getUriByRole(user.role);
      history.push(target);
    }
  }, [history, user]);

  const onNext = useCallback(
    (code) => {
      requestId.current = code;
      setTabIndex(tabIndex + 1);
    },
    [tabIndex]
  );

  const getUriByRole = function (role: Role): string {
    switch (role) {
      case Role.ADMIN:
        return ADMIN_DASHBOARD_ROUTE;
      case Role.BACKOFFICE:
        return BACKOFFICE_DASHBOARD_ROUTE;
      case Role.SALES:
        return `${DASHBOARD_ROUTE}${FilterUtils.getQueryFromFilter(
          Store.getValue<FilterInterface>(STORE_KEY.STORE_DASHBOARD_FILTER)
        )}`;
    }
  };

  const onLogin = useCallback(
    (data: SessionResponse) => {
      setUserState(data);
      Store.setValue(STORE_KEY.STORE_USER, data);
      const url = new URLSearchParams(location.search).get("expired");

      if (url) {
        history.push(url.toString());
      } else {
        const uri = getUriByRole(data.role);
        history.push(uri);
      }
      // WsClient.activate();
    },
    [history, setUserState, location]
  );

  const onReset = useCallback(() => {
    requestId.current = undefined;
    setTabIndex(0);
  }, []);

  return (
    <PageStripped title={t("Wlx Login")} className="login-page">
      <section>
        <article>
          <div>
            <header>
              <Logo />
              <LangSelector />
            </header>
            <div className="login-top">
              <h1>{t("Sign in")}</h1>
            </div>
            <TabView index={tabIndex}>
              <LoginEmail onNext={onNext} />
              <LoginOtp requestId={requestId.current} onLogin={onLogin} onReset={onReset} />
            </TabView>
          </div>
        </article>

        <div className="bottom-box">
          <SupportBox />
        </div>
      </section>
    </PageStripped>
  );
};
