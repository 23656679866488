import { FC } from "react";
import cx from "classnames";
import { StatusBox, StatusBoxImplProps } from "./StatusBox";

export const ErrorBox: FC<StatusBoxImplProps> = ({
  children,
  relative,
  className,
  ...otherProps
}) => {
  return (
    <StatusBox
      {...otherProps}
      className={cx("status-box", "error-box", className, {
        relative,
      })}
    >
      {children}
    </StatusBox>
  );
};
