import { FC } from "react";
import FadeAnimation from "../../../../../../../components/animate/FadeAnimation";
import { TextInput } from "../../../../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../../../../components/form/validators/RequiredValidator";
import { WebsiteValidator } from "../../../../../../../components/form/validators/WebsiteValidator";
import { Status } from "../../../../../../../data/types";
import {
  EcomTypesContentProps,
  HTTPS_PREFIX,
  addHttpsPrefix,
  removeHttpsPrefix,
} from "./EcomTypesContentUtils";

const TestWebsiteContent: FC<EcomTypesContentProps> = ({ onChange, store, t, edit, status }) => {
  return (
    <FadeAnimation animationKey="website-content" className="tablet-columns">
      <div>
        <TextInput
          onChange={(value) => onChange("ecommerceURL", addHttpsPrefix(value))}
          name="URL to product page"
          value={removeHttpsPrefix(store.ecommerceURL)}
          placeholder="website.com/"
          label={t("Final live URL")}
          validators={[
            new RequiredValidator(t("URL to product page is required")),
            new WebsiteValidator(t("URL is not valid"), true),
          ]}
          disabled={!edit || status === Status.DISABLED}
          hint={t("Final live website URL")}
          preIcon={{
            icon: HTTPS_PREFIX,
          }}
        />
      </div>
      <div>
        <TextInput
          onChange={(value) => onChange("ecommerceTestURL", addHttpsPrefix(value))}
          name="test-website-url"
          value={removeHttpsPrefix(store.ecommerceTestURL)}
          placeholder="test-website.com/"
          label={t("Test website URL")}
          validators={[
            new RequiredValidator(t("Test website URL is required")),
            new WebsiteValidator(t("URL is not valid"), true),
          ]}
          disabled={!edit || status === Status.DISABLED}
          hint={t("Test website URL")}
          preIcon={{
            icon: HTTPS_PREFIX,
          }}
        />
      </div>
      <div>
        <TextInput
          onChange={(value) => onChange("ecommerceUsername", value)}
          name="test-username"
          value={store.ecommerceUsername}
          label={t("Test username")}
          disabled={!edit || status === Status.DISABLED}
          hint={t("If needed for access")}
        />
      </div>
      <div>
        <TextInput
          onChange={(value) => onChange("ecommercePassword", value)}
          name="test-password"
          value={store.ecommercePassword}
          label={t("Test password")}
          disabled={!edit || status === Status.DISABLED}
          hint={t("If needed for access")}
        />
      </div>
    </FadeAnimation>
  );
};

export default TestWebsiteContent;
