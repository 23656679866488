import { FC } from "react";
import AssociateRolesList from "../../../components/associate/AssociateRolesList";
import { Associate } from "../../../model/associate/associateTypes";
import styles from "./AssociateData.module.scss";
import { useTranslation } from "react-i18next";

interface AssociateDataProps {
  associate: Associate;
}

const AssociateData: FC<AssociateDataProps> = ({ associate }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.associate_data}>
      <h2>
        {associate?.firstName} {associate?.lastName}
      </h2>
      <AssociateRolesList associate={associate} />
      <dl>
        <dd>{t("Email")}: </dd>
        <dt>{associate.email}</dt>
        <dd>{t("Phone")}: </dd>
        <dt>{associate.phoneNumber}</dt>
      </dl>
    </div>
  );
};

export default AssociateData;
