import React from "react";

import "./AdminPage.scss";
import { Page, PageType } from "../Page/Page";

export interface PageProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
}

export const AdminPage: React.FunctionComponent<PageProps> = ({ children, className, title }) => {
  return (
    <Page title={title} className={"admin-page " + className} pageType={PageType.ADMIN}>
      {children}
    </Page>
  );
};
