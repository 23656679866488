import React from "react";
import cx from "classnames";
import { ITEM_HEIGHT, ITEM_HEIGHT_EVENT, ITEM_OFFSET } from "./Timeline";
import { getOwnerOffset, MANDATORY_EVENTS } from "./ToSignature";
import { ContractDocument } from "../../../model/contract/contractType";

interface Props {
  active: boolean;
  documents: ContractDocument[];
}

export const ToBank: React.FunctionComponent<Props> = ({ active, documents }) => {
  const start = ITEM_OFFSET + MANDATORY_EVENTS * ITEM_HEIGHT;
  const end = getOwnerOffset(documents) - ITEM_HEIGHT_EVENT;

  return (
    <>
      <line x1="10" x2="50" y1={start} y2={end} className={cx({ active })} />
      <circle r="4" cx="50" cy={end} className={cx({ active })} />
    </>
  );
};
