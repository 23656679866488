import { DateTime } from "luxon";
import { atom } from "recoil";
import { isValidDate } from "../components/utils";
import {
  AnalyticsFilter,
  OverviewStatistics,
  TODAY,
} from "../data/dataStatistics";
import { Store, STORE_KEY } from "../Store";

export const analyticsState = atom<OverviewStatistics[]>({
  key: "analytics-state",
  default: [],
});

const filter: AnalyticsFilter = Store.getValue(
  STORE_KEY.STORE_ANALYTICS_FILTER
);

if (filter.to === TODAY) {
  filter.to = DateTime.now().toISODate();
} else if (!isValidDate(new Date(filter.to))) {
  filter.to = DateTime.now().toISODate();
}

export const analyticsFilterState = atom<AnalyticsFilter>({
  key: "analytics-filter-state",
  default: filter,
});
