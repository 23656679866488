import React from "react";
import { useLocation, matchPath } from "react-router";
import { NavLink } from "react-router-dom";
import { MenuItem } from "./SalesDashboardMenuItems";
import styles from "./SidebarMenuItems.module.scss";
import cx from "classnames";

type MenuItemProps = JSX.IntrinsicElements["li"] & {
  menuItem: MenuItem;
  hoveredItem?: string;
};

export const SidebarItem = React.forwardRef<HTMLLIElement, MenuItemProps>(
  ({ menuItem, hoveredItem, ...props }, ref) => {
    const location = useLocation();
    let match;

    if (menuItem.active === undefined) {
      match = matchPath(location.pathname, {
        path: menuItem.link,
        // exact: true,
        strict: false,
      });
    } else if (menuItem.active) {
      match = true;
    } else {
      match = false;
    }

    return (
      <li ref={ref} {...props}>
        <NavLink
          className={cx([styles.menu_item], {
            [styles.menu_item_active]: match,
            [styles.menu_item_disabled]: menuItem.disabled,
          })}
          to={menuItem.link}
          title={typeof menuItem.name === "string" ? menuItem.name : ""}
          aria-disabled
        >
          {menuItem.name}
        </NavLink>
      </li>
    );
  }
);
