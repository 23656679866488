import { FC, ReactNode } from "react";
import styles from "./IconButton.module.scss";
import cx from "classnames";

interface Props {
  children: ReactNode;
  variant?: "primary" | "contained";
  size?: "small" | "medium" | "large";
  rounded?: boolean;
  className?: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
}

export const IconButton: FC<Props> = ({
  children,
  variant = "primary",
  size = "medium",
  type = "button",
  rounded,
  className,
  onClick,
}) => {
  const rootClass = cx(styles.root, className, {
    [styles.contained]: variant === "contained",
    [styles.rounded]: rounded,
    [styles.small]: size === "small",
    [styles.large]: size === "large",
  });

  return (
    <button type={type} className={rootClass} onClick={onClick}>
      {children}
    </button>
  );
};
