import { BaseValidator } from "./BaseValidator";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { isPotentialOIB, validateMBO, validateOIB } from "../../CompanyIdUtils";

export class OibOrMboValidator extends BaseValidator {
  name = "OIB/MBO validator";

  private oibHint?: string;
  private mboHint?: string;

  constructor(error: string, oibHint?: string, mboHint?: string) {
    super(error);
    this.oibHint = oibHint;
    this.mboHint = mboHint;
  }

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (isPotentialOIB(value)) {
        if (validateOIB(value)) {
          resolve({
            status: VALIDATION_STATE.SUCCESS,
            hint: this.oibHint,
          });
        } else {
          resolve({
            status: VALIDATION_STATE.FAILED,
            message: this.error,
            hideError: value.replaceAll("-", "").length < 11,
          });
        }
      } else {
        if (validateMBO(value)) {
          resolve({
            status: VALIDATION_STATE.SUCCESS,
            hint: this.mboHint,
          });
        } else {
          resolve({
            status: VALIDATION_STATE.FAILED,
            message: this.error,
            hideError: value.replaceAll("-", "").length < 8,
          });
        }
      }
    });
  }
}
