import React, { useCallback, useEffect, useState } from "react";
import { Beacon } from "../../../components/beacon/Beacon";
import { Flag } from "../../../components/flags/Flag";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Table } from "../../../components/table/Table";
import { Country } from "../../../model/common/commonType";
import { Validity } from "../../../model/contract/contractType";
import { AdminUser, userAdministration } from "../../../data/UserAdministration";
import { AdminPage } from "../AdminPage";
import { AddNewAdminUserOverlay } from "./AddAdminUserOverlay";
import { AdminUserEdit } from "./AdminUserEdit";
import { NewOverlay } from "../../../components/overlay/NewOverlay";
import styles from "./AdminUser.module.scss";

export const SUPER_ADMIN_USERS_PAGE = "/admin/users/super";

export const AdminUsersPage: React.FunctionComponent = () => {
  const [admins, setAdmins] = useState<AdminUser[]>([]);
  const [adminEditId, setAdminEditId] = useState<number>(-1);

  const [countryFilter, setCountryFilter] = useState<Country>();
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState<"ascending" | "descending">("ascending");

  useEffect(() => {
    userAdministration.loadAllAdministrators().then(setAdmins);
  }, [setAdmins]);

  const onClose = useCallback(() => {
    setAdminEditId(-1);
  }, []);

  const isSorted = (property: string) => {
    if (sortField === property) {
      return sortDirection;
    }
    return undefined;
  };

  const sort = (property: string) => {
    setSortField(property);
    const direction = sortDirection === "ascending" ? "descending" : "ascending";
    setSortDirection(direction);
  };

  return (
    <AdminPage title="Admin Users" className="admin-users">
      <section className={styles.page}>
        <h3 className="m-bottom-40">Admin users</h3>
        <article>
          <AddNewAdminUserOverlay
            refresh={() => userAdministration.loadAllAdministrators().then(setAdmins)}
          />

          <NewOverlay open={adminEditId !== -1} onClose={onClose} className="overlay-edit">
            <p>Edit admin with id {adminEditId}</p>
          </NewOverlay>
          {countryFilter ? (
            <Button
              type="button"
              block
              ghost
              onClick={(_) => {
                setCountryFilter(undefined);
              }}
            >
              Show all
            </Button>
          ) : null}
          <Table className="m-top-20">
            <thead>
              <tr>
                <th />
                <th>
                  <button className={isSorted("name")} onClick={(_) => sort("name")}>
                    Name
                  </button>
                </th>
                <th>
                  <button className={isSorted("email")} onClick={(_) => sort("email")}>
                    Email
                  </button>
                </th>
                <th className="">
                  <button className={isSorted("superAdmin")} onClick={(_) => sort("superAdmin")}>
                    Super
                  </button>
                </th>
                <th className="">
                  <button className={isSorted("country")} onClick={(_) => sort("country")}>
                    Sales
                  </button>
                </th>
                <th className="">
                  <button className={isSorted("pricingAdmin")} onClick={(_) => sort("pricingAdmin")}>
                    Pricing
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {admins
                .filter((user) => {
                  if (countryFilter) {
                    return user.country === countryFilter;
                  }
                  return true;
                })
                .sort((r1, r2) => {
                  if (sortField.length === 0) {
                    return 0;
                  }
                  const val1 = r1[sortField as keyof AdminUser] as string;
                  const val2 = r2[sortField as keyof AdminUser] as string;
                  if (val1 === undefined) {
                    return 1;
                  }
                  if (val2 === undefined) {
                    return -1;
                  }
                  if (val1 > val2) {
                    return 1 * (sortDirection === "descending" ? -1 : 1);
                  }
                  if (val1 < val2) {
                    return -1 * (sortDirection === "descending" ? -1 : 1);
                  }
                  return 0;
                })
                .map((row) => (
                  <tr key={row.id}>
                    <td className="vertical-center">
                      <AdminUserEdit
                        adminUser={row}
                        refresh={() => userAdministration.loadAllAdministrators().then(setAdmins)}
                      />
                    </td>
                    <td className="truncate vertical-center">{row.name}</td>
                    <td className="truncate vertical-center">{row.email}</td>
                    <td className="vertical-center">
                      {row.superAdmin ? <Beacon mini validity={Validity.VALID} /> : null}
                    </td>
                    <td className=" vertical-center">
                      {row.salesAdmin && row.country ? (
                        <button
                          onClick={(_) => {
                            setCountryFilter(row.country);
                          }}
                        >
                          <Flag lang={row.country} size={24} />
                        </button>
                      ) : null}
                    </td>
                    <td className="vertical-center">
                      {row.pricingAdmin ? <Beacon mini validity={Validity.VALID} /> : null}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </article>
      </section>
    </AdminPage>
  );
};
