import { FunctionComponent, ReactNode } from "react";
import styles from "./SectionFieldSet.module.scss";

// components
import { SectionFieldSetHeader } from "./sectionFieldSetHeader";
import ContentBox from "../ContentBox/ContentBox";
import { FormName } from "../../pages/Contract/menus/ContractEditMenu";

interface Props {
  headerTitle: string;
  formName: FormName;
  children: ReactNode;
  sectionNumber?: number;
  className?: string;
  collapsible?: boolean;
  noPadding?: boolean;
}

export const SectionFieldSet: FunctionComponent<Props> = ({
  children,
  collapsible,
  noPadding = false,
  ...props
}) => {
  return (
    <div className={styles.root}>
      <SectionFieldSetHeader {...props} />
      <ContentBox padding={noPadding ? "no" : "md"} collapsible={collapsible}>
        {children}
      </ContentBox>
    </div>
  );
};
