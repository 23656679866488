import { API } from "../network/API";
import { data } from "./data";
import { ContractId, UTCDate } from "../model/common/commonType";

export enum PassCategory {
  direction = "direction",
  merchandiser = "merchandiser",
  direction_org_unit = "direction org unit",
  officer = "officer",
  sales = "sales",
  support_unit = "support unit",
}

export interface PassLookup {
  found: boolean;
  userId: string;
  name: string;
  email?: string;
}

export interface PassUser {
  userId: string;
  name?: string;
  number?: string;
  email?: string;
  officerId: string;
  officerCategory: string;
}

export interface Location {
  id: number;
  name: string;
  street: string;
  city: string;
  merchantId: string;
  value?: string;
}

interface MerchantStore {
  id: number;
  merchantId?: string;
}

export interface BackofficeResponse {
  companyName: string;
  locations: Location[];
  completed?: UTCDate;
  provisioningCompleted: boolean;
  merchantId: string;
}

export const dataPassUsers = {
  getMerchant: (contractId: ContractId, force = false) =>
    data.get<BackofficeResponse>(`/api/backoffice/contract/${contractId}`, force),

  postMerchant: (contractId: ContractId, merchantId: string, stores: MerchantStore[]) =>
    API.post(`/api/backoffice/contract/${contractId}`, {
      merchantId,
      stores,
    }),

  postForArchive: (merchantId: string, files: File[]) => {
    const req = new XMLHttpRequest();
    const formData = new FormData();
    formData.append("merchantId", merchantId);

    files.forEach((file, i) => {
      formData.append(`files`, file); // keep the name "files" for each file to match the backend
    });
    req.withCredentials = true;
    req.open("POST", API.getUrl(`/api/backoffice/archive`));
    req.setRequestHeader("Accept", "application/json"); // Set the Accept header if needed
    req.send(formData);
  },

  lookupPassUser: (userId: string, category: PassCategory) =>
    data.get<PassLookup>(`/api/sales/pass?userId=${userId}&category=${category}`, true),

  getPassUser: (salesId: string) => data.get<PassUser>(`/api/admin/pass?salesId=${salesId}`, true),

  uploadExcel: (file: FormData) => API.postFormData(`/api/admin/pass/upload`, file),

  getPassUsers: (category: PassCategory) =>
    data.get<PassLookup[]>(`/api/sales/pass/all?category=${category}`, true),

  getAllPassUsers: () => data.get<PassUser[]>("/api/admin/pass/all"),
};
