import { ReactNode, useCallback, useState, VoidFunctionComponent } from "react";
import { AddCircleOutline } from "../../../components/icons/AddCircleOutline";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Dropdown } from "../../../components/interactions/Dropdown/Dropdown";
import { Input } from "../../../components/interactions/Input/Input";
import { Toggle } from "../../../components/interactions/Toggle/Toggle";
import { LoginMethod } from "../../../data/dataAuth";
import { Country } from "../../../model/common/commonType";
import { Status } from "../../../data/types";
import { NewAdministratorRequest, userAdministration } from "../../../data/UserAdministration";

import styles from "./AdminUser.module.scss";
import { NewOverlay } from "../../../components/overlay/NewOverlay";

const DEFAULT_ADD_USER = () => {
  return {
    email: "",
    name: "",
    loginMethod: LoginMethod.OTP,
    superAdmin: false,
    salesAdmin: false,
    pricingAdmin: false,
  } as NewAdministratorRequest;
};

interface Props {
  refresh: () => void;
}

export const AddNewAdminUserOverlay: VoidFunctionComponent<Props> = ({ refresh }) => {
  const [open, setOpen] = useState<boolean>(false);
  const onOpen = useCallback(() => {
    setNewAdminUserRequest(DEFAULT_ADD_USER());
    setNameStatus(Status.DEFAULT);
    setEmailStatus(Status.DEFAULT);
    setEmailError("");
    setOpen(true);
  }, []);
  const onClose = useCallback(() => setOpen(false), []);

  const [nameStatus, setNameStatus] = useState(Status.DEFAULT);
  const [emailStatus, setEmailStatus] = useState(Status.DEFAULT);
  const [emailError, setEmailError] = useState("");
  const [countryStatus, setCountryStatus] = useState(Status.DEFAULT);

  const [newAdminUserRequest, setNewAdminUserRequest] = useState<NewAdministratorRequest>(DEFAULT_ADD_USER());

  const [saveButtonStatus, setSaveButtonStatus] = useState(Status.DEFAULT);

  const updateWithReset = useCallback(
    (status: Status, message?: ReactNode) => {
      setSaveButtonStatus(status);
      setTimeout(() => {
        setSaveButtonStatus(Status.DEFAULT);
      }, 4000);
    },
    [setSaveButtonStatus]
  );

  const onSave = () => {
    if (newAdminUserRequest.name.length === 0) {
      updateWithReset(Status.ERROR);
      setNameStatus(Status.ERROR);
      return;
    }
    if (newAdminUserRequest.email.length === 0) {
      setEmailError("Email is required");
      setEmailStatus(Status.ERROR);
      updateWithReset(Status.ERROR);
      return;
    }
    if (!userAdministration.isValidEmail(newAdminUserRequest.email)) {
      setEmailError("Invalid email format");
      setEmailStatus(Status.ERROR);
      updateWithReset(Status.ERROR);
      return;
    }

    if (!newAdminUserRequest.loginMethod) {
      updateWithReset(Status.ERROR);
      return;
    }

    if (newAdminUserRequest.salesAdmin && !newAdminUserRequest.country) {
      setCountryStatus(Status.ERROR);
      updateWithReset(Status.ERROR);
      return;
    }

    setSaveButtonStatus(Status.PENDING);
    userAdministration
      .addNewAdministrator(newAdminUserRequest)
      .then(() => {
        updateWithReset(Status.SUCCESS);
        refresh();
        setOpen(false);
      })
      .catch((err) => {
        updateWithReset(Status.ERROR);
      });
  };

  return (
    <div className="add-admin-user-overlay light-theme">
      <NewOverlay open={open} onClose={onClose} noPadding widthSize="small">
        <div className={styles.header}>
          <h5 style={{ margin: 0 }}>New admin user</h5>
        </div>
        <form onSubmit={(event) => event.preventDefault()}>
          <div className={styles.form_body}>
            <div className={styles.tablet_columns}>
              <div>
                <Input
                  onChange={(value) => {
                    setNewAdminUserRequest({
                      ...newAdminUserRequest,
                      email: value,
                    });
                  }}
                  value={newAdminUserRequest.email}
                  name="email"
                  label="Email"
                  status={emailStatus}
                  message={emailStatus === Status.ERROR ? emailError : null}
                  placeholder="Used as login"
                  onBlur={() => {
                    if (newAdminUserRequest.email.length === 0) {
                      setEmailError("Email is required");
                      setEmailStatus(Status.ERROR);
                      return;
                    }
                    if (userAdministration.isValidEmail(newAdminUserRequest.email)) {
                      // if (
                      //     newAdminUserRequest.email.endsWith("@bambora.com") ||
                      //     newAdminUserRequest.email.endsWith("@six-group.com") ||
                      //     newAdminUserRequest.email.endsWith("@ingenico.com")
                      // ) {
                      //   setLoginMethodStatus(Status.DISABLED);
                      //   setNewSalesUserRequest({
                      //     ...newSalesUser,
                      //     loginMethod: LoginMethod.AZURE,
                      //   });
                      // }

                      setEmailStatus(Status.SUCCESS);
                    } else {
                      setEmailError("Wrong format on email");
                      setEmailStatus(Status.ERROR);
                    }
                  }}
                />
              </div>
              <div>
                <Input
                  onChange={(value) => {
                    setNewAdminUserRequest({
                      ...newAdminUserRequest,
                      name: value,
                    });
                  }}
                  value={newAdminUserRequest.name}
                  name="name"
                  label="Name"
                  status={nameStatus}
                  message={nameStatus === Status.ERROR ? "Name is required" : null}
                  placeholder="Display name"
                  onBlur={() => {
                    if (newAdminUserRequest.name.length === 0) {
                      setNameStatus(Status.ERROR);
                    } else if (newAdminUserRequest.name.length > 0) {
                      setNameStatus(Status.SUCCESS);
                    } else {
                      setNameStatus(Status.DEFAULT);
                    }
                  }}
                />
              </div>

              <div>
                <Dropdown
                  alternatives={[
                    { value: "", text: "Select" },
                    { value: "HR", text: "Croatia" },
                    { value: "PL", text: "Poland" },
                    { value: "CZ", text: "Czechia" },
                  ]}
                  label="Country"
                  status={newAdminUserRequest.salesAdmin ? countryStatus : Status.DISABLED}
                  value={newAdminUserRequest.country}
                  onChange={(value) => {
                    if (value === "") {
                      setCountryStatus(Status.ERROR);
                    } else {
                      setCountryStatus(Status.SUCCESS);
                    }

                    setNewAdminUserRequest({
                      ...newAdminUserRequest,
                      country: value as Country,
                    });
                  }}
                />
              </div>
            </div>

            <div className="role-toggle m-y-20">
              <Toggle
                className="mini"
                onChange={(value) => {
                  setNewAdminUserRequest({
                    ...newAdminUserRequest,
                    pricingAdmin: false,
                    superAdmin: false,
                    salesAdmin: value,
                  });
                }}
                selected={newAdminUserRequest.salesAdmin}
                label="Sales Admin"
              />
            </div>

            <div className="role-toggle m-bottom-20">
              <Toggle
                className="mini"
                onChange={(value) => {
                  setNewAdminUserRequest({
                    ...newAdminUserRequest,
                    salesAdmin: false,
                    pricingAdmin: false,
                    superAdmin: value,
                    country: undefined,
                  });
                }}
                selected={newAdminUserRequest.superAdmin}
                label="Super Admin"
              />
            </div>

            <div className="role-toggle">
              <Toggle
                className="mini"
                onChange={(value) => {
                  setNewAdminUserRequest({
                    ...newAdminUserRequest,
                    salesAdmin: false,
                    pricingAdmin: value,
                    superAdmin: false,
                    country: undefined,
                  });
                }}
                selected={newAdminUserRequest.pricingAdmin}
                label="Pricing Admin"
              />
            </div>

            {/* <div className={styles.tablet_columns}>
              <div>
              <Dropdown
                alternatives={[
                  { value: "", text: "Select" },
                  { value: "AZURE", text: "Azure OAUTH" },
                  { value: "GOOGLE", text: "Google OAUTH" },
                  { value: "OTP", text: "Login Code" },
                ]}
                label="Login Method"
                status={loginMethodStatus}
                value={newAdminUserRequest.loginMethod as string}
                onChange={(value) => {
                  if (value === "") {
                    setLoginMethodStatus(Status.ERROR);
                  } else {
                    setLoginMethodStatus(Status.SUCCESS);
                  }

                  setNewAdminUserRequest({
                    ...newAdminUserRequest,
                    loginMethod: value as LoginMethod,
                  });
                }}
              />
            </div>
            </div> */}
          </div>
          <div className={styles.actions}>
            <Button block onClick={onSave} status={saveButtonStatus}>
              Create
            </Button>
          </div>
        </form>
      </NewOverlay>
      <Button onClick={onOpen}>
        New Admin User <AddCircleOutline />
      </Button>
    </div>
  );
};
