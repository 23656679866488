import { ReactNode, useRef, VoidFunctionComponent } from "react";
import { id } from "../utils";
import "./ButtonToggle.scss";

interface Props {
  label: string | ReactNode;
  helpText?: string;
  value?: boolean;
  positiveText: string;
  negativeText: string;
  onChange: (value: boolean) => void;
}

export const ButtonToggle: VoidFunctionComponent<Props> = ({
  label,
  helpText,
  negativeText,
  positiveText,
  value,
  onChange,
}) => {
  const inputId = useRef(id());
  const positiveId = useRef(inputId.current + "_positive");
  const negativeId = useRef(inputId.current + "_negative");

  return (
    <div className="radio_button_toggle">
      <fieldset>
        <div style={{ marginBottom: 10 }}>
          <legend>{label}</legend>
          <span className="help-text">{helpText}</span>
        </div>
        <input
          id={positiveId.current}
          name={positiveId.current}
          type="radio"
          onChange={() => onChange(true)}
          value={"true"}
          checked={value === true}
        />
        <label htmlFor={positiveId.current}>
          <span className="radio_button_toggle-text">{positiveText}</span>
        </label>
        <input
          id={negativeId.current}
          name={negativeId.current}
          type="radio"
          onChange={() => onChange(false)}
          value={"false"}
          checked={value === false}
        />
        <label htmlFor={negativeId.current}>
          <span className="radio_button_toggle-text">{negativeText}</span>
        </label>
      </fieldset>
    </div>
  );
};
