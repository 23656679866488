import React, { useCallback, useRef, useState } from "react";
import cx from "classnames";
import { useRecoilValue } from "recoil";
import { BankAccount, BankAccountSource, Validity } from "../../../../model/contract/contractType";
import { Status } from "../../../../data/types";
import { Beacon } from "../../../../components/beacon/Beacon";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { useTranslation } from "react-i18next";
import { ConfirmBank } from "../ConfirmBank/ConfirmBank";
import { contractBankState } from "../../../../state/contractBankState";
import { NewOverlay } from "../../../../components/overlay/NewOverlay";
import { EditIcon } from "../../../../components/icons/EditIcon";
import { EyeIcon } from "../../../../components/icons/EyeIcon";

interface Props {
  active: boolean;
  buttonStatus: Status;
}

function getLabelAndStatus(bankAccount: BankAccount) {
  if (bankAccount.source === BankAccountSource.OFFICIAL_REGISTRY) {
    return {
      label: "Verified by registry",
      status: Validity.VALID,
    };
  }

  if (bankAccount.source === BankAccountSource.PSD_LOOKUP) {
    return {
      label: "Added by merchant",
      status: bankAccount.iban ? Validity.VALID : Validity.MISSING,
    };
  }

  return {
    label: "Merchant uploaded bank statement",
    status: !!bankAccount.statementUploaded ? Validity.VALID : Validity.MISSING,
  };
}

function sourceToText(bankAccount: BankAccount) {
  const source = bankAccount.source;
  if (!source) {
    return "Bank statement";
  }
  if (source === BankAccountSource.OFFICIAL_REGISTRY) {
    return "Official Registry";
  }
  if (source === BankAccountSource.PSD_LOOKUP) {
    return "Klarna Lookup";
  }
  return "Bank statement";
}

export const EventBank: React.FunctionComponent<Props> = ({ active, buttonStatus }) => {
  const { t } = useTranslation();
  const bankAccount = useRecoilValue(contractBankState);
  const [open, setOpen] = useState<boolean>(false);
  const wide = useRef<boolean>(!!bankAccount.statementUploaded);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const labelAndStatus = getLabelAndStatus(bankAccount);

  let status = Status.DEFAULT;
  if (!bankAccount.signedOff) {
    status = active ? buttonStatus : Status.DISABLED;
  }

  return (
    <>
      <NewOverlay
        open={open}
        onClose={onClose}
        className={cx("overlay-confirm-bank", {
          wide: wide.current,
        })}
        widthSize="large"
      >
        <ConfirmBank onClose={onClose} open={open} />
      </NewOverlay>
      <li
        className={cx("timeline-event-document", {
          active,
        })}
      >
        <Button
          className="small event-button event-button--absolute"
          onClick={() => setOpen(true)}
          status={status}
        >
          {bankAccount.signedOff ? (
            <>
              <EyeIcon />
              {t("View")}
            </>
          ) : (
            <>
              <EditIcon />
              {t("Confirm")}
            </>
          )}
        </Button>
        <div>
          <div className="truncated" style={{ marginBottom: 5 }}>
            <b>{t("Bank account")}, </b>
            <span className="timeline-item-type">{t(sourceToText(bankAccount))}</span>
          </div>
          <div className={cx("additional-event-info", "fs-small")}>
            <Beacon mini className="aligned" validity={labelAndStatus.status} />
            <span className="additional-event-passive-text">{t(labelAndStatus.label)}</span>
          </div>

          {bankAccount.source === BankAccountSource.OFFICIAL_REGISTRY ? null : (
            <div className={cx("additional-event-info", "fs-small")}>
              <Beacon
                mini
                className="aligned"
                validity={bankAccount.signedOff ? Validity.VALID : Validity.MISSING}
              />
              <span className="additional-event-passive-text">{t("Sales confirmation")}</span>
            </div>
          )}
        </div>
      </li>
    </>
  );
};
