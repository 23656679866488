import React from "react";
import { useTranslation } from "react-i18next";
import { PricingStep } from "../../model/contract/contractType";
import { Country, Currency } from "../../model/common/commonType";
import { ContractPackage, PackageId, Validity } from "../../model/contract/contractType";
import { Language } from "../../i18n";
import { Beacon } from "../beacon/Beacon";
import { Fee } from "../fee/Fee";
import "./Acquiring.scss";

interface Props {
  pack: ContractPackage;
  country: Country;
}

export const pricingMapping = {
  [PricingStep.LIGHT]: 750,
  [PricingStep.STANDARD]: 1500,
  [PricingStep.ADVANCED]: 2250,
};

// export const pricingMapping = {
//   [PricingStep.LIGHT]: 5240,
//   [PricingStep.STANDARD]: 10500,
//   [PricingStep.ADVANCED]: 15720,
// };

// export const costSoftPosMapping = {
//   [PricingStep.LIGHT]: 90,
//   [PricingStep.STANDARD]: 135,
//   [PricingStep.ADVANCED]: 165,
// };

// export const costStandardMapping = {
//   [PricingStep.LIGHT]: 130,
//   [PricingStep.STANDARD]: 190,
//   [PricingStep.ADVANCED]: 225,
// };

export const costMappings = {
  [PackageId.SOFTPOS_BUNDLE]: {
    [PricingStep.LIGHT]: 12.9,
    [PricingStep.STANDARD]: 19.9,
    [PricingStep.ADVANCED]: 24.9,
  },
  [PackageId.DESK_BUNDLE]: {
    [PricingStep.LIGHT]: 19.9,
    [PricingStep.STANDARD]: 27.9,
    [PricingStep.ADVANCED]: 30.9,
  },
  // 2 EUR more than desk
  [PackageId.MOVE_BUNDLE]: {
    [PricingStep.LIGHT]: 21.9,
    [PricingStep.STANDARD]: 29.9,
    [PricingStep.ADVANCED]: 32.9,
  },
};

export const AcquiringHR: React.FunctionComponent<Props> = ({ pack, country }) => {
  const { t } = useTranslation();

  if (country !== Country.CROATIA) {
    return null;
  }

  // Default to SoftPos
  const costMapping =
    costMappings[pack.packageId || PackageId.SOFTPOS_BUNDLE] || costMappings[PackageId.SOFTPOS_BUNDLE];

  return (
    <div className="acquiring">
      <strong>Mjesečni volumen transakcije uključen:</strong>
      <br />
      <dl>
        <dt>
          <span>
            <i>Light</i> - {new Intl.NumberFormat(Language.CROATIA).format(pricingMapping[PricingStep.LIGHT])}
          </span>
        </dt>
        <dd>
          {" "}
          {pack.pricingStep === PricingStep.LIGHT && (
            <span className="selected-acquiring">
              <b>{t("selected")}</b>
              <Beacon validity={Validity.VALID} mini />
            </span>
          )}
          <Fee
            cost={costMapping[PricingStep.LIGHT]}
            lang={Language.CROATIA}
            currency={Currency.CROATIA}
            extension="month"
            decimals={2}
          />
        </dd>
        <dt>
          <span>
            <i>Standard</i> -{" "}
            {new Intl.NumberFormat(Language.CROATIA).format(pricingMapping[PricingStep.STANDARD])}
          </span>
        </dt>
        <dd>
          {" "}
          {pack.pricingStep === PricingStep.STANDARD && (
            <span className="selected-acquiring">
              <Beacon validity={Validity.VALID} mini />
              <b>{t("selected")}</b>
            </span>
          )}
          <Fee
            cost={costMapping[PricingStep.STANDARD]}
            lang={Language.CROATIA}
            currency={Currency.CROATIA}
            extension="month"
            decimals={2}
          />
        </dd>
        <dt>
          <span>
            <i>Advanced</i> -{" "}
            {new Intl.NumberFormat(Language.CROATIA).format(pricingMapping[PricingStep.ADVANCED])}
          </span>
        </dt>
        <dd>
          {" "}
          {pack.pricingStep === PricingStep.ADVANCED && (
            <span className="selected-acquiring">
              <b>{t("selected")}</b>
              <Beacon validity={Validity.VALID} mini />
            </span>
          )}
          <Fee
            cost={costMapping[PricingStep.ADVANCED]}
            lang={Language.CROATIA}
            currency={Currency.CROATIA}
            extension="month"
            decimals={2}
          />
        </dd>
      </dl>
      <p>
        {t(
          "Note that the figure above represents the basic monthly cost. The final cost will also depend on the number of selected terminals."
        )}
      </p>
    </div>
  );
};
