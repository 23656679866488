import { ReactNode, useState, FC } from "react";
import { matchPath, useLocation } from "react-router";
import { BarChartIcon } from "../../../../components/icons/BarChartIcon";
import { TextFileIcon } from "../../../../components/icons/TextFileIcon";
import { ANALYTICS_ROUTE } from "../../../Analytics/AnalyticsPage";
import { defaultFilter } from "../../../../Store";
import { AnalyticsFilter } from "../../../../data/dataStatistics";
import { AnalyticsFilterUtils } from "../../../../model/AnalyticsFilter";
import { FilterUtils } from "../../../../model/Filter";
import { DASHBOARD_ROUTE } from "../../../Dashboard/DashboardPage";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { analyticsFilterState } from "../../../../state/analyticsState";
import { SidebarItem } from "./SidebarMenuItem";
import { NewContractOverlay } from "../../../../components/NewOrganisationOverlay/NewContractOverlay";
import MockNewContract from "../../../Contract/MockContractData/MockNewContract";
import { LanguageSelect } from "../../../../components/LanguageSelect/LanguageSelect";
import { SupportBox } from "../../../../components/supportBox/SupportBox";
import SidebarUser from "../SidebarUser";
import styles from "./SidebarMenuItems.module.scss";
import parentStyles from "../Sidebar.module.scss";

export interface MenuItem {
  name: string | ReactNode;
  link: string;
  disabled: boolean;
  active?: boolean;
}

function getDefaultRoute() {
  const filter = { ...defaultFilter };
  return `${DASHBOARD_ROUTE}${FilterUtils.getQueryFromFilter(filter)}`;
}

function getAnalyticsLink(filter: AnalyticsFilter) {
  return `${ANALYTICS_ROUTE}${AnalyticsFilterUtils.filterToQuery(filter)}`;
}

export const SalesDashboardMenuItems: FC = () => {
  const { pathname } = useLocation();

  const [hoveredItem, setHoveredItem] = useState<string>();
  const { t } = useTranslation();

  const isDashboard = matchPath(pathname, { path: DASHBOARD_ROUTE });
  const analyticsFilter = useRecoilValue(analyticsFilterState);

  const menuItems = [
    {
      name: (
        <>
          <TextFileIcon />
          {t("All contracts")}
        </>
      ),
      link: getDefaultRoute(),
      disabled: false,
      active: isDashboard !== null,
    },

    {
      name: (
        <>
          <BarChartIcon />
          {t("Statistics")}
        </>
      ),
      link: getAnalyticsLink(analyticsFilter),
      disabled: false,
      active: !!matchPath(pathname, {
        path: ANALYTICS_ROUTE,
        exact: true,
        strict: false,
      }),
    },
  ];

  return (
    <>
      <div>
        <NewContractOverlay />
        <MockNewContract />
      </div>
      <nav>
        <ul className={styles.sidebar_menu_items}>
          {menuItems.map((item) => (
            <SidebarItem
              key={item.link}
              menuItem={item}
              hoveredItem={hoveredItem}
              onMouseEnter={() => {
                !item.disabled && setHoveredItem(item.link);
              }}
              onMouseLeave={() => setHoveredItem(undefined)}
            />
          ))}
        </ul>
      </nav>
      <nav className={parentStyles.bottomContent}>
        <LanguageSelect />
        <SupportBox />
        <SidebarUser />
      </nav>
    </>
  );
};
