import { ReactNode, useState, FC } from "react";
import { matchPath, useHistory, useLocation } from "react-router";
import styles from "./SidebarMenuItems.module.scss";
import { TextFileIcon } from "../../../../components/icons/TextFileIcon";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { SidebarItem } from "./SidebarMenuItem";
import { CheckSquareIcon } from "../../../../components/icons/CheckSquareIcon";
import { EditIcon } from "../../../../components/icons/EditIcon";
import { getDynamicLink } from "../../../../components/utils";
import { COMPLETED_CONTRACT_PAGE } from "../../../CompletedContract/CompletedContractPage";
import { CONTRACT_ROUTE } from "../../../Contract/ContractPage";
import { MERCHANT_ROUTE } from "../../../MerchantStatus/MerchantStatusPage";
import { ContractStatus } from "../../../../model/contract/contractType";
import { contractStatusState } from "../../../../state/contractStatusState";
import MockContractData from "../../../Contract/MockContractData/MockContractData";
import { ContractEditMenu } from "../../../Contract/menus/ContractEditMenu";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Button } from "../../../../components/interactions/Buttons/Button";

export interface MenuItem {
  name: string | ReactNode;
  link: string;
  disabled: boolean;
  active?: boolean;
}

export const ContractMenuItems: FC = () => {
  const [hoveredItem, setHoveredItem] = useState<string>();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const contractStatus = useRecoilValue(contractStatusState);

  const isContractDetailsPage = matchPath(pathname, { path: CONTRACT_ROUTE, exact: true });
  const enableMerchantReview = contractStatus && contractStatus.status !== ContractStatus.PENDING_SALES_INPUT;
  const enableCompleted = enableMerchantReview && contractStatus && contractStatus.completed;

  const history = useHistory();

  const menuItems = [
    {
      name: (
        <>
          <TextFileIcon /> {t("Contract details")}
        </>
      ),
      link: getDynamicLink(CONTRACT_ROUTE, { id: contractStatus.contractId }),
      disabled: false,
    },
    {
      name: (
        <>
          <EditIcon /> {t("Merchant Review")}
        </>
      ),
      link: getDynamicLink(MERCHANT_ROUTE, { id: contractStatus.contractId }),
      disabled: !enableMerchantReview,
    },
    {
      name: (
        <>
          <CheckSquareIcon /> {t("Onboarding")}
        </>
      ),
      link: getDynamicLink(COMPLETED_CONTRACT_PAGE, {
        id: contractStatus.contractId,
      }),
      disabled: !enableCompleted,
    },
  ];

  return (
    <>
      <Button onClick={() => history.go(-1)} className={styles.back}>
        <MdOutlineKeyboardBackspace fontSize={"1.5em"} /> {t("Back")}
      </Button>

      <nav style={{ minHeight: 40 }}>{isContractDetailsPage && <MockContractData />}</nav>

      <nav>
        <ul className={styles.sidebar_menu_items}>
          {menuItems.map((item) => (
            <SidebarItem
              key={item.link}
              menuItem={item}
              hoveredItem={hoveredItem}
              onMouseEnter={() => {
                !item.disabled && setHoveredItem(item.link);
              }}
              onMouseLeave={() => setHoveredItem(undefined)}
            />
          ))}
        </ul>
      </nav>

      {isContractDetailsPage && (
        <nav style={{ marginTop: "auto" }}>
          <ContractEditMenu />
        </nav>
      )}
    </>
  );
};
