import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import { Button } from "../../../components/interactions/Buttons/Button";
import { ConfirmButton, ConfirmType } from "../../../components/interactions/Buttons/ConfirmButton";
import { getDynamicLink } from "../../../components/utils";
import { COMPLETED_CONTRACT_PAGE } from "../../CompletedContract/CompletedContractPage";
import { PassUserInputs } from "../components/PassUserInputs";
import { Status } from "../../../data/types";
import { ContractDocument, ContractStatus } from "../../../model/contract/contractType";
import { ContractStatusState } from "../../../state/contractStatusState";
import { useHistory } from "react-router";
import { PassUserValues } from "../components/PassUserInputs";
import { Associate } from "../../../model/associate/associateTypes";
import styles from "./ConfirmBox.module.scss";
import ContentBox from "../../../components/ContentBox/ContentBox";

interface Props {
  status: Status;
  contractStatus: ContractStatusState;
  passUserValues: PassUserValues;
  onPassUserChange: (values: PassUserValues) => void;
  associates: Associate[];
  documents: ContractDocument[];
  onConfirm: () => void;
}

export const ConfirmBox: FC<Props> = ({
  status,
  contractStatus,
  passUserValues,
  onPassUserChange,
  associates,
  documents,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { merchandiser, direction } = passUserValues;

  return (
    <div className={styles.root}>
      {status === Status.ERROR && (
        <ErrorBox>
          <div className="line-mini">
            <Trans>
              <b>Ooh!</b> Something went wrong. We couldn't confirm the contract. Try again?
            </Trans>
          </div>
        </ErrorBox>
      )}

      {status === Status.SUCCESS && (
        <SuccessBox noPadding={false}>
          <Trans>
            <b>Well done!</b> We will create the contract.
          </Trans>
        </SuccessBox>
      )}

      {contractStatus.status === ContractStatus.COMPLETE ? (
        <Button
          className="m-top-20"
          onClick={() => {
            history.push(
              getDynamicLink(COMPLETED_CONTRACT_PAGE, {
                id: contractStatus.contractId,
              })
            );
          }}
        >
          {t("Show completed contract")}
        </Button>
      ) : (
        <ContentBox padding="s">
          <div className={styles.content}>
            <div className="fw-500">
              <Trans>
                When all data is collected we need to add <i>Merchandiser</i> and <i>Direction</i>. Then we're
                ready to create our contract.
              </Trans>
            </div>

            <div>
              <PassUserInputs
                values={{ merchandiser, direction }}
                onChange={onPassUserChange}
                disabled={isCompletedInputDisabled(contractStatus, associates, documents)}
              />
            </div>
            <div className="m-top-10">
              <ConfirmButton
                confirmType={ConfirmType.SUCCESS}
                onClick={onConfirm}
                status={getConfirmStatus(contractStatus, associates, documents, merchandiser, direction)}
                removeButtonText={t("Yes, confirm")}
              >
                {t("Confirm contract")}
              </ConfirmButton>
            </div>
          </div>
        </ContentBox>
      )}
    </div>
  );
};

function isContractComplete(associates: Associate[], documents: ContractDocument[]) {
  const allAssociatesConfirmed = associates.every((associate) => associate.signedOff);

  if (!allAssociatesConfirmed) {
    return false;
  }

  const allDocsConfirmed = documents.every((doc) => doc.confirmed);

  if (!allDocsConfirmed) {
    return false;
  }
  return true;
}

function isCompletedInputDisabled(
  contractStatus: ContractStatusState,
  associates: Associate[],
  documents: ContractDocument[]
) {
  if (!contractStatus.edit) {
    return true;
  }
  return !isContractComplete(associates, documents);
}

function getConfirmStatus(
  contractStatus: ContractStatusState,
  associates: Associate[],
  documents: ContractDocument[],
  merchandiser: string | undefined,
  direction: string | undefined
) {
  if (!contractStatus.edit) {
    return Status.DISABLED;
  }

  if (!merchandiser || !direction) {
    return Status.DISABLED;
  }

  if (!isContractComplete(associates, documents)) {
    return Status.DISABLED;
  }

  return Status.DEFAULT;
}
