import { AssociateRole, Associate } from "../../../model/associate/associateTypes";
import { AssociateId, Country, Cas, StoreId } from "../../../model/common/commonType";
import {
  Saluation,
  Address,
  MainContractData,
  Contact,
  ContractType,
  Store2,
} from "../../../model/contract/contractType";
import { Language, countryToLang } from "../../../i18n";
import {
  FEMALE_FIRST_NAMES,
  MALE_FIRST_NAMES,
  LAST_NAMES,
  STREETS,
  CITIES,
  POSTAL_CODES,
  COMPANY_NAMES,
  LOCATIONS,
  STREET_NUMBERS,
} from "./mockData";
const DUMMY_ASSOCIATE_ID = "aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee" as AssociateId;

const getRandomIndex = (list: any[]) => Math.floor(Math.random() * list.length);

const getRandomFirstname = (salutation: Saluation) => {
  const isShe = salutation === Saluation.SHE;

  const names = isShe ? FEMALE_FIRST_NAMES : MALE_FIRST_NAMES;
  const randomIndex = getRandomIndex(names);
  return names[randomIndex];
};

const getRandomLastname = () => {
  const randomIndex = getRandomIndex(LAST_NAMES);
  return LAST_NAMES[randomIndex];
};

const getEmail = (firstname: string, lastname: string) => {
  return `${firstname}.${lastname}@mail.com`.toLowerCase();
};

const getRandomPhonenumber = () => {
  return "+123456789";
};

const getRandomSalutation = () => {
  const isShe = Math.random() < 0.5;
  return isShe ? Saluation.SHE : Saluation.HE;
};

const generateRandomAddress = (countryCode: Country): Address => {
  const randomIndex = getRandomIndex(STREETS);
  const street = STREETS[randomIndex];
  const city = CITIES[randomIndex];
  const postalCode = POSTAL_CODES[randomIndex];
  const streetNumber = STREET_NUMBERS[randomIndex] ?? STREET_NUMBERS[0];
  return { street, postalCode, city, countryCode, streetNumber };
};

const getRandomContact = (): Contact => {
  const saluation = getRandomSalutation();
  const firstName = getRandomFirstname(saluation);
  const lastName = getRandomLastname();
  const phoneNumber = getRandomPhonenumber();
  const email = getEmail(firstName, lastName);
  return {
    saluation,
    firstName,
    lastName,
    phoneNumber,
    email,
  };
};

const getRandomCompanyName = () => {
  const randomIndex = getRandomIndex(COMPANY_NAMES);
  return COMPANY_NAMES[randomIndex];
};

const getRandomStoreName = () => {
  const randomIndex = getRandomIndex(LOCATIONS);
  return LOCATIONS[randomIndex] + " Store";
};

const rolesThatRequireAddress: AssociateRole[] = [AssociateRole.BENEFICIAL_OWNER, AssociateRole.SIGNATORY];

export const getRandomAssociate = (country: Country, roles: AssociateRole[]): Associate => {
  const shouldGenerateAddress = roles.some((role) => rolesThatRequireAddress.includes(role));

  const address: Address | undefined = shouldGenerateAddress ? generateRandomAddress(country) : undefined;

  const randomAssociate: Associate = {
    ...getRandomContact(),
    associateId: DUMMY_ASSOCIATE_ID,
    cas: 0 as Cas,
    language: Language.UK,
    roles,
    address,
  } as Associate;

  return randomAssociate;
};

type MockCompanyData = Pick<
  MainContractData,
  "primaryAddress" | "website" | "countryOfOrigin" | "acceptance" | "language" | "companyName"
>;

export const getRandomCompanyData = (country: Country) => {
  const companyName = getRandomCompanyName();

  const randomCompanyData: MockCompanyData = {
    companyName,
    primaryAddress: generateRandomAddress(country),
    website: `${companyName}.com`.replaceAll(" ", "-").toLowerCase(),
    countryOfOrigin: country,
    acceptance: true,
    language: countryToLang(country),
  };

  return randomCompanyData;
};

export const getRandomStoreData = (country: Country) => {
  const storeName = getRandomStoreName();
  const cardStatementName = storeName.replaceAll(" ", "_").toUpperCase();

  const saveStore: Store2<ContractType.INSTORE> = {
    cas: 0 as Cas,
    cardStatementName: cardStatementName,
    commercialName: storeName,
    contact: getRandomContact(),
    address: generateRandomAddress(country),
    terminals: [],
    contractType: ContractType.INSTORE,
    storeId: 0 as StoreId,
    storePhone: getRandomPhonenumber(),
  };

  return saveStore;
};
