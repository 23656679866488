import { FunctionComponent, useRef } from "react";
import { PricingTemplate } from "../../../model/pricing/pricingTypes";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { HiddenInput } from "../../../components/form/HiddenInput";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { contractStoresState, contractTerminalsSelector } from "../../../state/contractStoresState";
import { ContractType, TerminalType } from "../../../model/contract/contractType";

type TemplateProps = Pick<PricingTemplate, "storeLimitation" | "terminalLimitation">;

interface Props extends TemplateProps {
  scrollRef?: React.RefObject<HTMLElement>;
  contractType: ContractType;
}

export const LimitationValidation: FunctionComponent<Props> = ({
  storeLimitation,
  terminalLimitation,
  scrollRef,
  contractType,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const storeCount = useRecoilValue(contractStoresState).filter(
    (store) => store.contractType === contractType
  ).length;

  const terminalCount = useRecoilValue(contractTerminalsSelector).filter((terminal) => {
    if (contractType === ContractType.INSTORE) {
      return terminal.terminalType !== TerminalType.ECOMMERCE;
    } else {
      return terminal.terminalType === TerminalType.ECOMMERCE;
    }
  }).length;

  return (
    <div ref={ref} style={{ margin: 10 }}>
      {storeLimitation && (
        <HiddenInput
          label="Store limitation"
          scrollToRef={scrollRef || ref}
          value={storeCount > storeLimitation ? undefined : true}
          validators={[
            new RequiredValidator(
              t(
                `The selected price plan has a store limitation of {{ storeLimitation }}, but found {{ storeCount }}`,
                {
                  storeLimitation,
                  storeCount,
                }
              )
            ),
          ]}
        />
      )}
      {terminalLimitation && (
        <HiddenInput
          label="Terminal limitation"
          scrollToRef={scrollRef || ref}
          value={terminalCount > terminalLimitation ? undefined : true}
          validators={[
            new RequiredValidator(
              t(
                `The selected price plan has a terminal limitation of {{ terminalLimitation }}, but found {{ terminalCount }}`,
                {
                  terminalLimitation,
                  terminalCount,
                }
              )
            ),
          ]}
        />
      )}
    </div>
  );
};
