import React from "react";
import { Trans } from "react-i18next";
import { Button } from "../interactions/Buttons/Button";

export const PermissionError = () => {
  return (
    <div className="retry-error">
      <p>
        <Trans>You don't have permission to load this contract</Trans>
      </p>
      <Button
        onClick={() => {
          window.location.pathname = "/";
        }}
      >
        <Trans>Back to dashboard</Trans>
      </Button>
    </div>
  );
};
