export const POSTAL_CODES = [
  "12345",
  "56789",
  "98765",
  "43210",
  "87654",
  "21098",
  "34567",
  "89012",
  "67890",
  "54321",
  "10987",
  "76543",
  "32109",
  "45678",
  "87654",
  "23456",
  "54321",
  "12345",
  "67890",
  "98765",
];

export const CITIES = [
  "Havenbrook",
  "Summitville",
  "Harbor Springs",
  "Willowdale",
  "Crestview",
  "Silverport",
  "Meadowridge",
  "Glendale",
  "Cascade City",
  "Rivertown",
  "Skylight Heights",
  "Evergreen Springs",
  "Coral Grove",
  "Moonlight Bay",
  "Sunflower Ridge",
  "Serenity Falls",
  "Ember Hollow",
  "Crystal Vista",
  "Oakridge",
  "Starlight Meadows",
];

export const STREETS = [
  "Maple Avenue",
  "Oak Street",
  "Elm Lane",
  "Cedar Boulevard",
  "Pinecrest Road",
  "Sycamore Drive",
  "Willow Way",
  "Birch Lane",
  "Magnolia Terrace",
  "Juniper Court",
  "Cypress Street",
  "Chestnut Avenue",
  "Aspen Lane",
  "Poplar Place",
  "Redwood Drive",
  "Acacia Lane",
  "Hawthorn Avenue",
  "Spruce Street",
  "Laurel Lane",
  "Alder Road",
];

export const MALE_FIRST_NAMES = [
  "James",
  "Ethan",
  "Benjamin",
  "William",
  "Alexander",
  "Michael",
  "Daniel",
  "Andrew",
  "Christopher",
  "Nicholas",
  "Joshua",
  "Samuel",
  "Matthew",
  "Joseph",
  "David",
  "Thomas",
  "Anthony",
  "Jonathan",
  "Robert",
  "Ryan",
];

export const FEMALE_FIRST_NAMES = [
  "Emma",
  "Olivia",
  "Ava",
  "Isabella",
  "Sophia",
  "Mia",
  "Charlotte",
  "Amelia",
  "Harper",
  "Evelyn",
  "Abigail",
  "Emily",
  "Elizabeth",
  "Sofia",
  "Avery",
  "Ella",
  "Scarlett",
  "Grace",
  "Chloe",
  "Lily",
];

export const LAST_NAMES = [
  "Nguyen",
  "Patel",
  "Kim",
  "Almeida",
  "Singh",
  "Gonzalez",
  "Li",
  "Santos",
  "Dubois",
  "Chang",
  "Abdi",
  "Nkosi",
  "Fernandez",
  "Ibrahim",
  "Kowalski",
  "Ocampo",
  "Chen",
  "Oliveira",
  "Nakamura",
  "Adeyemi",
];

export const COMPANY_NAMES = [
  "Swift Solutions",
  "ClearWave Industries",
  "Skyline Services",
  "Precision Tech",
  "EcoGrove Enterprises",
  "Summit Innovations",
  "Harmony Solutions",
  "BlueSky Ventures",
  "SilverStone Technologies",
  "Cedar Systems",
  "Crystal Clear Industries",
  "Meadow Crest Solutions",
  "SunRise Enterprises",
  "Horizon Tech",
  "Maple Lane Ventures",
  "OceanView Industries",
  "GreenLeaf Solutions",
  "StoneBridge Services",
  "ClearView Ventures",
  "RedOak Technologies",
];

export const LOCATIONS = [
  "Willow Emp.",
  "Crimson Corner",
  "Azure Outfit.",
  "Whispering B.",
  "Radiant Meadows",
  "Velvet Harbor",
  "Ember Glade",
  "Celestial Plaza",
  "Sapphire Emp.",
  "Tranquil Mall",
  "Mystic Grove",
  "Golden Bazaar",
  "Enchanted Out.",
  "Crimson Square",
  "Moonlit Emp.",
  "Serenity Stores",
  "Twilight Mkt.",
  "Lush Center",
  "Starlight Mall",
  "Velvet Plaza",
];

export const STREET_NUMBERS = [
  "123A",
  "478B",
  "982",
  "56C",
  "714D",
  "39E",
  "821",
  "305F",
  "67G",
  "450",
  "202H",
  "813",
  "374I",
  "90",
  "638J",
  "51K",
  "986",
  "277L",
  "403",
  "159M",
];
