import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { dataContracts } from "../../../data/dataContracts";
import { BankAccount } from "../../../model/contract/contractType";
import { Status } from "../../../data/types";
import { legalAssociatesSelector } from "../../../state/contractAssociateState";
import { contractBankState } from "../../../state/contractBankState";
import { contractMainContractDataState } from "../../../state/contractMainContractDataState";
import { contractErrorState, contractSaveState } from "../../../state/contractSaveState";
import { contractStatusState } from "../../../state/contractStatusState";
import { LinkAnchors } from "../ContractEdit";
import { ContractEditError } from "../ContractEditError";
import { ContractSaveError, getEditStatus, handleError } from "../ContractPage";
import { AccountLookup } from "./AccountLookup";
import { contractDocumentsState } from "../../../state/contractDocumentsState";
import { TextInput } from "../../../components/form/TextInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Select } from "../../../components/form/Select";
import { Form } from "../../../components/form/Form";
import { IbanValidator } from "../../../components/form/validators/IbanValidator";
import { hasRealErrors } from "../../../components/form/FormHelpers";
import { FormName } from "../menus/ContractEditMenu";
import { IBAN } from "ibankit";
import { clearCas } from "../../../components/utils";
import { ScrollPositionAnchor } from "../../../components/scrollPosition/ScrollPositionAnchor";
import "./Bank.scss";
import { SectionFieldSet } from "../../../components/sectionFieldSet/SectionFieldSet";

function getInitialLookupStatus(bank: BankAccount) {
  if (!bank.iban) {
    return Status.DEFAULT;
  }

  if (bank.bank && bank.bic) {
    return Status.SUCCESS;
  }

  return Status.ERROR;
}

export function InnerBank() {
  const { t } = useTranslation();
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);
  const setDocuments = useSetRecoilState(contractDocumentsState);
  const contractStatus = useRecoilValue(contractStatusState);
  const mainData = useRecoilValue(contractMainContractDataState);
  const associates = useRecoilValue(legalAssociatesSelector);
  const [bank, setBank] = useRecoilState(contractBankState);
  const [error, setError] = useState<ContractSaveError | null>(null);
  const prevSaved = useRef<string>(JSON.stringify(clearCas(bank)));
  const [lookupStatus, setLookupStatus] = useState(
    getEditStatus(contractStatus.edit, getInitialLookupStatus(bank))
  );

  const onClose = useCallback(() => setError(null), []);

  const onSave = useCallback(
    (updatedBank) => {
      const stringCopy = JSON.stringify(clearCas(updatedBank));
      if (prevSaved.current === stringCopy) {
        return;
      } else {
        prevSaved.current = stringCopy;
      }

      dataContracts
        .saveBankAccount(contractStatus.contractId, updatedBank)
        .then((response) => {
          setBank(response);
          setDataSaved((dataSaved) =>
            dataSaved.concat({
              date: new Date(),
            })
          );
          // Try fetch new docs
          dataContracts
            .loadDocuments(contractStatus.contractId)
            .then((docs) => setDocuments(docs))
            .catch((err) => {
              // Just let it be. We won't list additional docs in this case
              console.error(err);
            });
        })
        .catch((err) => {
          prevSaved.current = "";
          handleError(err, setError);
          setDataError((dataErrors) =>
            dataErrors.concat({
              date: new Date(),
            })
          );
        });
    },
    [contractStatus.contractId, setBank, setError, setDataSaved, setDataError, setDocuments]
  );

  const opts = useMemo(() => {
    if (!mainData.companyName) {
      return [];
    }
    const options = [
      {
        text: t("Select account holder"),
        value: "",
        disabled: true,
      },
      {
        text: `${t("Company")}: ${mainData.companyName}`,
        value: "COMPANY",
      },
    ];

    associates.forEach((item) => {
      if (!item.firstName && !item.lastName) {
        return;
      }

      let name;
      if (item.firstName && item.lastName) {
        name = `${item.firstName} ${item.lastName}`;
      } else if (item.firstName) {
        name = item.firstName;
      } else {
        name = item.lastName;
      }

      options.push({
        text: name || "No name",
        value: item.associateId,
      });
    });

    return options;
  }, [mainData.companyName, associates, t]);

  const inputStatus = useMemo(() => {
    return getEditStatus(contractStatus.edit, Status.DEFAULT);
  }, [contractStatus.edit]);

  const retry = useCallback(() => {
    setError(null);
    setTimeout(() => onSave(bank), 500);
  }, [onSave, bank]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setTimeout(() => {
      dataContracts
        .claimContract(contractStatus.contractId)
        .then(() => onSave(bank))
        .catch(() => onSave(bank)); // We're lazy, execute save again, which will fail,
      // and propagate error to Overlay
    }, 500);
  }, [contractStatus.contractId, onSave, bank]);

  const lookupBicAndBank = useCallback(
    (bankParam) => {
      setLookupStatus(Status.PENDING);

      dataContracts
        .lookupBicAndBank(bankParam.iban, contractStatus.contractId)
        .then((lookupResult) => {
          if (lookupResult.success) {
            const updatedBank = {
              ...bankParam,
              bic: lookupResult.bic,
              bank: lookupResult.bank,
            };
            setBank(updatedBank);
            onSave(updatedBank);
            setLookupStatus(Status.SUCCESS);
          } else {
            const updatedBank = {
              ...bankParam,
              bic: "",
              bank: "",
            };
            setBank(updatedBank);
            onSave(updatedBank);
            setLookupStatus(Status.ERROR);
          }
        })
        .catch(() => {
          setLookupStatus(Status.ERROR);
        });
    },
    [contractStatus.contractId, setBank, onSave]
  );

  const onSuggestionChange = useCallback(
    (iban) => {
      const updatedBank = {
        ...bank,
        iban,
      };
      setLookupStatus(Status.PENDING);
      setBank(updatedBank);
      lookupBicAndBank(updatedBank);
    },
    [bank, setBank, lookupBicAndBank]
  );

  const onChange = useCallback(
    (val, name) => {
      setBank((prevBank) => {
        return {
          ...prevBank,
          [name as keyof BankAccount]: val,
        };
      });

      if (name !== "iban") {
        return;
      }

      if (IBAN.isValid(val)) {
        lookupBicAndBank({
          ...bank,
          iban: val,
        });
      } else {
        setLookupStatus(Status.DEFAULT);
      }
    },
    [setBank, bank, lookupBicAndBank]
  );

  useEffect(() => {
    if (bank.iban && (!bank.bic || !bank.bank)) {
      lookupBicAndBank(bank);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { bank: bankName, bic } = bank;
  const gotBankFromLookup = bank.iban;

  return (
    <div className="bank">
      <Form
        onSaveTrigger={(event, form) => {
          const realErrors = hasRealErrors(form);
          if (!realErrors && (event.target as HTMLInputElement).name !== "iban") {
            onSave(bank);
          }
        }}
        name={FormName.BANK}
      >
        <ScrollPositionAnchor id={LinkAnchors.BANK_DETAILS.anchor} />

        <ContractEditError
          error={error}
          setError={setError}
          retry={retry}
          onClose={onClose}
          reclaimAndSave={reclaimAndSave}
        />

        <SectionFieldSet headerTitle={t(LinkAnchors.BANK_DETAILS.name)} formName={FormName.BANK}>
          {
            <div>
              <AccountLookup
                onSuggestionChange={onSuggestionChange}
                bank={bank}
                contractStatus={contractStatus}
                status={inputStatus}
              />
              <div className="tablet-columns">
                <div>
                  <Select
                    onChange={(value) => {
                      const updatedBank = {
                        ...bank,
                        accountHolder: value,
                      };
                      setBank(updatedBank);
                      onSave(updatedBank);
                    }}
                    label={t("Account Holder")}
                    alternatives={opts}
                    value={bank.accountHolder || ""}
                    hint={t("Owner of the account")}
                    validators={[new RequiredValidator(t("Account owner is required"))]}
                    disabled={!contractStatus.edit}
                  />
                </div>
                {gotBankFromLookup && (
                  <div>
                    <TextInput
                      onChange={onChange}
                      label="IBAN"
                      value={bank.iban}
                      name="iban"
                      validators={[
                        new RequiredValidator(t("IBAN is required")),
                        new IbanValidator(t("IBAN is invalid")),
                      ]}
                      disabled={true}
                    />
                  </div>
                )}
              </div>
            </div>
          }

          {gotBankFromLookup && (
            /**
             * We want to toggle this using JS instead of CSS so that the
             * validators are removed when not needed
             */
            <div className={cx("lookup-result", lookupStatus)}>
              <div className="manuel-input m-top-20">
                <p>
                  <Trans>
                    We couldn't find neither <b>Bank</b> or <b>BIC</b> for this <i>IBAN</i>. You have to
                    manually add them.
                  </Trans>
                </p>

                <div className="columns m-top-20">
                  <div>
                    <TextInput
                      onChange={onChange}
                      label={t("Bank")}
                      value={bank.bank}
                      name="bank"
                      validators={[new RequiredValidator(t("Bank is required"))]}
                      attributes={{
                        tabIndex: lookupStatus === Status.ERROR ? 0 : -1,
                      }}
                      disabled={!contractStatus.edit}
                    />
                  </div>
                  <div>
                    <TextInput
                      onChange={onChange}
                      label="BIC"
                      validators={[new RequiredValidator(t("Bic is required"))]}
                      value={bank.bic}
                      name="bic"
                      attributes={{
                        tabIndex: lookupStatus === Status.ERROR ? 0 : -1,
                      }}
                      disabled={!contractStatus.edit}
                    />
                  </div>
                </div>
              </div>

              <div className="successful-lookup">
                <p>
                  <Trans>
                    We found a corresponding <b>Bank</b> (<i>{{ bankName }}</i>) and a <b>BIC</b> (
                    <i>{{ bic }}</i>) to this IBAN.
                  </Trans>
                </p>
              </div>
            </div>
          )}
        </SectionFieldSet>
      </Form>
    </div>
  );
}
