import React from "react";
import { getIntlCost } from "../utils";
import "./Fee.scss";

interface Props {
  lang: string;
  cost: number;
  currency: string;
  extension?: string;
  decimals?: number;
}

export const Fee: React.FunctionComponent<Props> = ({
  lang,
  cost,
  currency,
  extension = null,
  decimals = 0,
}) => {
  if (extension) {
    return (
      <span className="fee">
        {getIntlCost(lang, cost, currency, decimals)} <span className="fee-ext">/ {extension}</span>
      </span>
    );
  }

  return <span className="fee">{getIntlCost(lang, cost, currency, decimals)}</span>;
};
