import React from "react";
import cx from "classnames";
import { CheckboxChecked } from "./CheckboxChecked";
import "./Checkbox.scss";
import { SquareIcon } from "./SquareIcon";

interface Props {
  className?: string;
  checked: boolean;
}

export const Checkbox: React.FunctionComponent<Props> = ({
  className,
  checked,
}) => {
  return (
    <div
      className={cx("checkbox", className, {
        checked,
      })}
      style={{ backgroundColor: "transparent" }}
    >
      <SquareIcon className="checkbox-unchecked" />
      <CheckboxChecked />
    </div>
  );
};
