import { useMemo, FC, useCallback } from "react";
import { PricingUpdateType } from "../PricingInputs";
import { useTranslation } from "react-i18next";
import { RadioGroup } from "../../../../../../../components/form/RadioGroup";
import { Select } from "../../../../../../../components/form/Select";
import { TextArea } from "../../../../../../../components/form/TextArea";
import { TextInput } from "../../../../../../../components/form/TextInput";
import { MaxLengthValidator } from "../../../../../../../components/form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../../../../../../../components/form/validators/MinLengthValidator";
import { RequiredValidator } from "../../../../../../../components/form/validators/RequiredValidator";
import { Country } from "../../../../../../../model/common/commonType";
import {
  EditablePricingTemplate,
  Pomca,
  PricingModel,
  SteppedBasedPricing,
} from "../../../../../../../model/pricing/pricingTypes";
import { Language } from "../../../../../../../i18n";
import { Checkboxes } from "../../../../../../../components/interactions/Checkboxes/Checkboxes";

import {
  CASHLESS_PROMOTION_COUNTRIES,
  getPricingModelDisplayName,
  INITIAL_STEPPED_BASED_PRICING,
} from "../../../../../../../model/pricing/pricingUtils";
import { ContractType } from "../../../../../../../model/contract/contractType";
import { getContractTypeDisplayString } from "../../../../../../../model/contract/contractUtils";
import styles from "./GeneralPricePlanInputs.module.scss";
import { Alternative } from "../../../../../../../components/interactions/InputTypes";
import { INITIAL_POMCA } from "../PomcaFee/PomcaFee";

type TemplateProps = Pick<
  EditablePricingTemplate,
  | "name"
  | "description"
  | "pricingModel"
  | "steppedBasedPricing"
  | "cashlessPromotion"
  | "cashlessPromotionIsPreSelected"
  | "contractType"
  | "pomca"
>;

interface Props extends TemplateProps {
  language: Language;
  country: Country;
  isUpdate: boolean;
  disabled: boolean;
  update: (updates: PricingUpdateType) => void;

  storeLimitationIsEnabled: boolean;
  setStoreLimitationIsEnabled: (value: boolean) => void;
  terminalLimitationIsEnabled: boolean;
  setTerminalLimitationIsEnabled: (value: boolean) => void;
  disableTerminalsEnabled: boolean;
  setDisableTerminalsEnabled: (value: boolean) => void;
}

export const GeneralPricePlanDataInputs: FC<Props> = ({
  language,
  country,
  isUpdate,
  disabled,
  update,
  steppedBasedPricing,
  name,
  description,
  pricingModel,
  cashlessPromotionIsPreSelected,
  contractType,
  storeLimitationIsEnabled,
  setStoreLimitationIsEnabled,
  terminalLimitationIsEnabled,
  setTerminalLimitationIsEnabled,
  disableTerminalsEnabled,
  setDisableTerminalsEnabled,
  pomca,
}) => {
  const { t } = useTranslation();

  const getContractTypeValue = () => {
    if (contractType.includes(ContractType.INSTORE)) {
      return ContractType.INSTORE;
    }

    if (contractType.includes(ContractType.ECOMMERCE)) {
      return ContractType.ECOMMERCE;
    }

    return undefined;
  };

  const alternatives = useMemo(() => {
    const regionNames = new (Intl as any).DisplayNames([language], {
      type: "region",
    });

    return Object.values(Country).map((key: string) => ({
      text: regionNames.of(key),
      value: key,
    }));
  }, [language]);

  const onChangeOptions = useCallback(
    (values: string[], name: string, value: string) => {
      const steppedBasedChecked = values.includes(STEPPED_BASED_PRICING);
      const cashlessPreChecked = values.includes(CASHLESS_PRE_SELECTED);
      const disabledTerminalsChecked = values.includes(DISABLE_TERMINALS);
      const storeLimitationChecked = values.includes(STORE_LIMITATION);
      const terminalLimitationChecked = values.includes(TERMINAL_LIMITATION);
      const pomcaChecked = values.includes(POMCA_FEE);

      const steppedBasedClicked = value === STEPPED_BASED_PRICING;
      const cashlessPreClicked = value === CASHLESS_PRE_SELECTED;
      const disabledTerminalsClicked = value === DISABLE_TERMINALS;
      const storeLimitationClicked = value === STORE_LIMITATION;
      const terminalLimitationClicked = value === TERMINAL_LIMITATION;
      const pomcaClicked = value === POMCA_FEE;

      const updateObject: PricingUpdateType = {};

      if (steppedBasedClicked) {
        updateObject.steppedBasedPricing = steppedBasedChecked ? INITIAL_STEPPED_BASED_PRICING : undefined;
      }

      if (cashlessPreClicked) {
        updateObject.cashlessPromotionIsPreSelected = cashlessPreChecked;
      }

      if (disabledTerminalsClicked) {
        updateObject.disabledTerminals = disabledTerminalsChecked ? [] : undefined;
      }

      if (storeLimitationClicked && !storeLimitationChecked) {
        updateObject.storeLimitation = undefined;
      }

      if (terminalLimitationClicked && !terminalLimitationChecked) {
        updateObject.terminalLimitation = undefined;
      }

      if (pomcaClicked) {
        updateObject.pomca = pomcaChecked ? INITIAL_POMCA : undefined;
        updateObject.terminalLimitation = pomcaChecked ? INITIAL_POMCA.length : undefined;
      }

      setStoreLimitationIsEnabled(storeLimitationChecked);
      setTerminalLimitationIsEnabled(pomcaClicked ? pomcaChecked : terminalLimitationChecked);
      setDisableTerminalsEnabled(disabledTerminalsChecked);

      return update(updateObject);
    },
    [update, setStoreLimitationIsEnabled, setTerminalLimitationIsEnabled, setDisableTerminalsEnabled]
  );

  return (
    <div className="tablet-columns">
      <div className={styles.column}>
        <Select
          label={t("Country")}
          validators={[new RequiredValidator(t("Country is required"))]}
          disabled={true}
          onChange={(value) => null}
          alternatives={alternatives}
          value={country}
        />
        <RadioGroup
          disabled={isUpdate || disabled}
          label={t("Contract type")}
          onChange={(value) => update({ contractType: value })}
          alternatives={contractTypeAlts}
          value={getContractTypeValue()}
          validators={[new RequiredValidator(t("Contract type is required"))]}
        />
        <RadioGroup
          disabled={isUpdate || disabled}
          label={t("Pricing model")}
          onChange={(value) => update({ pricingModel: value })}
          alternatives={modelAlternatives}
          value={pricingModel}
          validators={[new RequiredValidator(t("Pricing model is required"))]}
        />

        {pricingModel !== PricingModel.PACK && (
          <Checkboxes
            label="Price plan options"
            onChange={onChangeOptions}
            values={getPricingOptionValues({
              steppedBasedPricing,
              cashlessPromotionIsPreSelected,
              storeLimitationIsEnabled,
              terminalLimitationIsEnabled,
              disableTerminalsEnabled,
              pomca,
            })}
            alternatives={getPricePlanOptions(country, pomca)}
            disabled={disabled}
          />
        )}
      </div>
      <div className={styles.column}>
        <TextInput
          label="Price plan name"
          name="pre-set-name"
          value={name}
          onChange={(value) => update({ name: value })}
          placeholder={t("Magic")}
          disabled={disabled}
          message={
            isUpdate ? (
              <div className={styles.warning_box}>
                {t(
                  "Changing the name might be dangerous. The name change will be mirrored to all contracts including those being currently edited"
                )}
              </div>
            ) : (
              t("Name of price plan")
            )
          }
          validators={[
            new RequiredValidator(t("Name is required")),
            new MaxLengthValidator(NAME_MAX_LENGTH, t(`Max length is ${NAME_MAX_LENGTH} characters`)),
            new MinLengthValidator(NAME_MIN_LENGTH, t(`Min length is ${NAME_MIN_LENGTH} characters`)),
          ]}
        />
        <TextArea
          label="Price plan description"
          name="pre-set-description"
          className="pricing-input-description"
          value={description}
          onChange={(value) => update({ description: value })}
          disabled={disabled}
          validators={[
            new MaxLengthValidator(
              DESCRIPTION_MAX_LENGTH,
              t(`Max length is {{DESCRIPTION_MAX_LENGTH}} characters`, {
                DESCRIPTION_MAX_LENGTH,
              })
            ),
            new MinLengthValidator(
              DESCRIPTION_MIN_LENGTH,
              t(`Min length is {{DESCRIPTION_MIN_LENGTH}} characters`, {
                DESCRIPTION_MIN_LENGTH,
              })
            ),
          ]}
        />
      </div>

      <div></div>
    </div>
  );
};

const NAME_MAX_LENGTH = 30;
const NAME_MIN_LENGTH = 3;
const DESCRIPTION_MAX_LENGTH = 300;
const DESCRIPTION_MIN_LENGTH = 0;

const STEPPED_BASED_PRICING = "stepped-based-pricing";
const CASHLESS_PRE_SELECTED = "cashless-pre-selected";
const STORE_LIMITATION = "store-limitation";
const TERMINAL_LIMITATION = "terminal-limitation";
const DISABLE_TERMINALS = "disable-terminals";
const POMCA_FEE = "pomca-fee";

const contractTypeAlts = [
  {
    text: getContractTypeDisplayString(ContractType.INSTORE),
    value: ContractType.INSTORE,
  },
  {
    text: getContractTypeDisplayString(ContractType.ECOMMERCE),
    value: ContractType.ECOMMERCE,
  },
];

const modelAlternatives = [
  {
    text: getPricingModelDisplayName(PricingModel.BLENDED),
    value: PricingModel.BLENDED,
  },
  {
    text: getPricingModelDisplayName(PricingModel.ICPP),
    value: PricingModel.ICPP,
  },
  {
    text: getPricingModelDisplayName(PricingModel.PACK),
    value: PricingModel.PACK,
  },
];

const getPricePlanOptions = (country: Country, pomca?: Pomca[]): Alternative<string>[] => {
  const baseOptions: Alternative<string>[] = [
    { text: "Store limitation", value: STORE_LIMITATION },
    { text: "Terminal limitation", value: TERMINAL_LIMITATION, disabled: !!pomca },
    { text: "Disable terminals", value: DISABLE_TERMINALS },
    { text: "Stepped based pricing", value: STEPPED_BASED_PRICING },
  ];

  if (CASHLESS_PROMOTION_COUNTRIES.includes(country)) {
    const cashlessOptions = {
      text: "Pre-select cashless promotion",
      value: CASHLESS_PRE_SELECTED,
    };
    baseOptions.push(cashlessOptions);
  }

  if (country === Country.CZECHIA) {
    const pomcaOption = {
      text: "POMCA",
      value: POMCA_FEE,
    };
    baseOptions.push(pomcaOption);
  }

  return baseOptions;
};

type PricingOptions = {
  steppedBasedPricing: SteppedBasedPricing | undefined;
  cashlessPromotionIsPreSelected: boolean;
  storeLimitationIsEnabled: boolean;
  terminalLimitationIsEnabled: boolean;
  disableTerminalsEnabled: boolean;
  pomca: Pomca[] | undefined;
};

const getPricingOptionValues = (options: PricingOptions) => {
  const {
    steppedBasedPricing,
    cashlessPromotionIsPreSelected,
    storeLimitationIsEnabled,
    terminalLimitationIsEnabled,
    disableTerminalsEnabled,
    pomca,
  } = options;

  const values: string[] = [];
  if (steppedBasedPricing) values.push(STEPPED_BASED_PRICING);
  if (cashlessPromotionIsPreSelected) values.push(CASHLESS_PRE_SELECTED);
  if (storeLimitationIsEnabled) values.push(STORE_LIMITATION);
  if (terminalLimitationIsEnabled) values.push(TERMINAL_LIMITATION);
  if (disableTerminalsEnabled) values.push(DISABLE_TERMINALS);
  if (pomca) values.push(POMCA_FEE);
  return values;
};
