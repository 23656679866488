import React, { RefObject, useCallback } from "react";
import cx from "classnames";
import { Saluation } from "../../model/contract/contractType";
import { Associate } from "../../model/associate/associateTypes";
import { RadioGroup } from "../form/RadioGroup";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { TextInput } from "../form/TextInput";
import { MinLengthValidator } from "../form/validators/MinLengthValidator";
import { MaxLengthValidator } from "../form/validators/MaxLengthValidator";
import { EmailValidator } from "../form/validators/EmailValidator";
import { PhoneCountryCodeValidator } from "../form/validators/PhoneCountryCodeValidator";
import { useTranslation } from "react-i18next";
import { PhoneNumberCountry } from "./PhoneNumberCountry";

export interface ContactRequiredFields {
  salutation: boolean;
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  phone: boolean;
}

interface Props {
  associate: Associate;
  onChange: (associate: Associate, field: string, ev?: InputEvent) => void;
  columnClass?: string;
  disabled?: boolean;
  contactRequiredFields: ContactRequiredFields;
  scrollToRef?: RefObject<HTMLElement>;
}

export const Contact: React.FunctionComponent<Props> = ({
  associate,
  onChange,
  columnClass,
  disabled = false,
  contactRequiredFields,
  scrollToRef,
}) => {
  const { t } = useTranslation();
  const saluationOpts = [
    {
      text: t("Mister"),
      value: Saluation.HE,
    },
    {
      text: t("Misses"),
      value: Saluation.SHE,
    },
  ];

  const onUpdate = useCallback(
    (value, name, ev) => {
      const update: any = { ...associate };
      update[name as keyof Associate] = value;
      onChange(update, name, ev);
    },
    [associate, onChange]
  );

  return (
    <>
      <div className={cx("contact-input contact-input-saluation", columnClass)}>
        <RadioGroup
          className="horizontal"
          onChange={onUpdate}
          name="saluation"
          label={t("Saluation")}
          alternatives={saluationOpts}
          value={associate.saluation || ""}
          validators={
            contactRequiredFields.salutation ? [new RequiredValidator(t("Saluation is required"))] : []
          }
          disabled={disabled}
          scrollToRef={scrollToRef}
        />
      </div>
      <div className={cx("contact-input", columnClass)}>
        <TextInput
          onChange={onUpdate}
          name="firstName"
          label={t("First Name")}
          value={associate.firstName}
          data-testid="contact-first-name"
          validators={[
            ...(contactRequiredFields.firstName ? [new RequiredValidator(t("First name is required"))] : []),
            new MinLengthValidator(2, t("First name must be at least {{min}} characters", { min: 2 })),
            new MaxLengthValidator(
              25,
              t("First name must be less than {{max}} characters", {
                max: 25,
              })
            ),
          ]}
          disabled={disabled}
          scrollToRef={scrollToRef}
        />
      </div>
      <div className={cx("contact-input", columnClass)}>
        <TextInput
          onChange={onUpdate}
          name="lastName"
          label={t("Last Name")}
          value={associate.lastName}
          data-testid="contact-last-name"
          validators={[
            ...(contactRequiredFields.lastName ? [new RequiredValidator(t("Last name is required"))] : []),
            new MinLengthValidator(2, t("Last name must be at least {{min}} characters", { min: 2 })),
            new MaxLengthValidator(
              25,
              t("Last name must be less than {{max}} characters", {
                max: 25,
              })
            ),
          ]}
          disabled={disabled}
          scrollToRef={scrollToRef}
        />
      </div>
      <div className={cx("contact-input", columnClass)}>
        <TextInput
          onChange={onUpdate}
          name="email"
          label={t("Email")}
          value={associate.email}
          data-testid="contact-email"
          validators={[
            ...(contactRequiredFields.email ? [new RequiredValidator(t("Email is required"))] : []),
            new EmailValidator(t("Email is not valid")),
            new MaxLengthValidator(
              50,
              t("Email must be less than {{max}} characters", {
                max: 50,
              })
            ),
          ]}
          disabled={disabled}
          scrollToRef={scrollToRef}
        />
      </div>
      <div className={cx("contact-input", columnClass)}>
        <TextInput
          onChange={onUpdate}
          name="phoneNumber"
          label={t("Mobile phone")}
          value={associate.phoneNumber}
          data-testid="contact-phone"
          validators={[
            ...(contactRequiredFields.phone ? [new RequiredValidator(t("Phone number is required"))] : []),
            new PhoneCountryCodeValidator(t("Phone number must start with a country code e.g +46...")),
            new MinLengthValidator(
              8,
              t("Mobile phone must be at least {{min}} characters", {
                min: 8,
              })
            ),
            new MaxLengthValidator(
              14,
              t("Mobile phone must be less than {{max}} characters", {
                max: 14,
              })
            ),
          ]}
          disabled={disabled}
          scrollToRef={scrollToRef}
          message={<PhoneNumberCountry phoneNumber={associate.phoneNumber} />}
        />
      </div>
    </>
  );
};
