import React, { useState, useCallback, useRef, useEffect } from "react";
import cx from "classnames";
import { useTranslation, Trans } from "react-i18next";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { ErrorBox } from "../../../../components/boxes/ErrorBox";
import { StatusBoxes } from "../../../../components/boxes/StatusBoxes";
import { SuccessBox } from "../../../../components/boxes/SuccessBox";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { ConfirmButton, ConfirmType } from "../../../../components/interactions/Buttons/ConfirmButton";
import {
  dataConfirm,
  ExternalBankAccount,
  ExternalBankAccountSessions,
  KlarnaAccountStatus,
} from "../../../../data/dataConfirm";
import { AssociateRole } from "../../../../model/associate/associateTypes";
import { Status } from "../../../../data/types";
import { contractBankState, defaultBankAccount } from "../../../../state/contractBankState";
import { getHolderName } from "./ConfirmBank";
import { useRecoilValue, useRecoilState } from "recoil";
import { BankAccount, Validity } from "../../../../model/contract/contractType";
import { contractAssociateState } from "../../../../state/contractAssociateState";
import { contractMainContractDataState } from "../../../../state/contractMainContractDataState";
import { contractStatusState } from "../../../../state/contractStatusState";
import { Cas } from "../../../../model/common/commonType";
import { Retry } from "../../../../components/retry/Retry";
import { Beacon } from "../../../../components/beacon/Beacon";
import "./PsdConfirmed.scss";
import { InfoBox } from "../../../../components/boxes/InfoBox";

interface Props {
  onClose: () => void;
  open: boolean;
}

function getAccountStatus(bankAccount: BankAccount, account: ExternalBankAccount, t: any) {
  const status = account.accountStatus;

  if (bankAccount.iban === account.iban) {
    return {
      validity: Validity.VALID,
      status: Status.DISABLED,
      info: t("Currently selected"),
    };
  }

  if (status === KlarnaAccountStatus.VALID) {
    return {
      validity: Validity.VALID,
      status: Status.DEFAULT,
      info: t("Available"),
    };
  }

  if (status === KlarnaAccountStatus.MATCH) {
    return {
      validity: Validity.VALID,
      status: Status.DEFAULT,
      info: t("Account holder matches associate or company"),
    };
  }

  let info;
  if (status === KlarnaAccountStatus.INVALID_TYPE) {
    info = t("Account is of invalid type");
  } else if (status === KlarnaAccountStatus.INVALID_CURRENCY) {
    info = t("Account uses an unsupported currency");
  } else if (status === KlarnaAccountStatus.NO_HOLDER) {
    info = t("Account holder is missing for this account");
  } else {
    info = t("Iban is missing for this account");
  }

  return {
    validity: Validity.MISSING,
    status: Status.ERROR,
    info,
  };
}

function getAllAccounts(sessions: ExternalBankAccountSessions[]) {
  let accounts: ExternalBankAccount[] = [];
  sessions.forEach((session) => {
    accounts = accounts.concat(session.accounts);
  });

  return accounts;
}

export const PsdConfirmed: React.FunctionComponent<Props> = ({ onClose, open }) => {
  const { t } = useTranslation();
  const associates = useRecoilValue(contractAssociateState);

  const mainData = useRecoilValue(contractMainContractDataState);
  const [bankAccount, setBankAccount] = useRecoilState(contractBankState);
  const account = useRef<BankAccount>(bankAccount);
  const contractState = useRecoilValue(contractStatusState);
  const [confirmStatus, setConfirmStatus] = useState<Status>(Status.DEFAULT);
  const [rejectStatus, setRejectStatus] = useState<Status>(Status.DEFAULT);
  const [externalAccountsStatus, setExternalAccountsStatus] = useState<Status>(Status.PENDING);
  const [message, setMessage] = useState<string>("");
  const accountHolder = associates.find(
    (associate) => associate.roles.indexOf(AssociateRole.ACCOUNT_HOLDER) > -1
  );
  const [externalAccounts, setExternalAccounts] = useState<ExternalBankAccountSessions[]>();
  const [isMatch, setIsMatch] = useState<boolean>(false);
  const confirmed = useRef<boolean>(!!bankAccount.signedOff);

  const loadExternal = useCallback(() => {
    if (!open) {
      return;
    }

    if (!accountHolder) {
      return;
    }

    if (account.current.signedOff) {
      setExternalAccountsStatus(Status.DEFAULT);
      return;
    }

    dataConfirm
      .getExternalBankAccounts(contractState.contractId, accountHolder.associateId)
      .then((data) => {
        setExternalAccounts(data);
        const allAccounts = getAllAccounts(data);
        setIsMatch(allAccounts.findIndex((item) => item.iban === account.current.iban) > -1);
        setExternalAccountsStatus(Status.SUCCESS);
      })
      .catch((err) => setExternalAccountsStatus(Status.ERROR));
  }, [open, accountHolder, contractState.contractId]);

  const onRetry = useCallback(() => {
    setExternalAccountsStatus(Status.PENDING);
    setTimeout(() => {
      loadExternal();
    }, 600);
  }, [loadExternal]);

  useEffect(loadExternal, [loadExternal]);

  const onReject = useCallback(() => {
    dataConfirm
      .rejectBankAccount(contractState.contractId)
      .then(() => {
        setMessage("Thank you. We have rejected the bank account");
        setBankAccount(() => ({
          ...defaultBankAccount,
        }));
      })
      .catch(() => {
        setRejectStatus(Status.ERROR);
        setTimeout(() => {
          setMessage("");
          setRejectStatus(Status.DEFAULT);
        }, 3000);
      });
  }, [contractState.contractId, setBankAccount]);

  const onConfirm = useCallback(() => {
    dataConfirm
      .addBankAccount(contractState.contractId, {
        cas: bankAccount.cas as Cas,
        bic: bankAccount.bic,
        bank: bankAccount.bank,
        iban: bankAccount.iban,
        accountHolder: bankAccount.accountHolder,
      })
      .then((bankObj) => {
        setMessage("Thank you. We have confirmed the bank account");
        setConfirmStatus(Status.SUCCESS);
        account.current.cas = bankObj.cas;
        setBankAccount(() => ({
          ...bankObj,
          // signedOff: new Date().toISOString() as UTCDate,
        }));
      })
      .catch(() => {
        setConfirmStatus(Status.ERROR);
        setTimeout(() => {
          setConfirmStatus(Status.DEFAULT);
        }, 3000);
      });
  }, [contractState.contractId, setBankAccount, account, bankAccount]);

  let externalElem = <div />;

  if (externalAccountsStatus === Status.SUCCESS) {
    if (externalAccounts?.length) {
      externalElem = (
        <div className="external-accounts">
          <p>{t("We found a few other accounts. Perhaps there is one that is a better match?")}</p>
          {externalAccounts.map((session) => (
            <div key={session.sessionId}>
              <b>{session.bankName}</b>
              <ul>
                {session.accounts.map((externalAccount) => {
                  const accountProps = getAccountStatus(bankAccount, externalAccount, t);

                  return (
                    <li className="fs-small session-bank-account" key={externalAccount.iban}>
                      <div className="session-bank-account-iban-header">{t("IBAN")}:</div>
                      <div className="session-bank-account-iban-value">{externalAccount.iban}</div>
                      <div className="session-bank-account-holder-header">{t("Account holder")}:</div>
                      <div className="session-bank-account-holder-value">{externalAccount.holderName}</div>
                      <div className="session-bank-account-info">
                        <div>
                          <Beacon validity={accountProps.validity} className="mini" />
                          {accountProps.info}
                        </div>

                        {accountProps.status !== Status.ERROR && (
                          <Button
                            className="small"
                            status={accountProps.status}
                            onClick={() => {
                              setBankAccount((prevAccount) => ({
                                ...prevAccount,
                                iban: externalAccount.iban,
                                bank: session.bankName,
                                bic: externalAccount.bic,
                                accountHolder: externalAccount.holderName,
                              }));
                            }}
                          >
                            {t("Select this account")}
                          </Button>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        </div>
      );
    } else {
      externalElem = <p>{t("We did not receive any other bank accounts for this merchant.")}</p>;
    }
  }

  return (
    <div
      className={cx("confirm-bank", {
        "hide-external-accounts": isMatch,
      })}
    >
      <h3>{t("Bank account")}</h3>
      <InfoBox className="m-bottom-20">
        <Trans>
          This bank account was added by <u>the merchant</u>. You will have to confirm the bank details or
          reject them. If rejected the chosen manager of the account will be asked to confirm the bank account
          again.
        </Trans>
      </InfoBox>
      <dl className="m-bottom-10">
        <dt>{t("IBAN")}:</dt>
        <dd>{bankAccount.iban}</dd>
        <dt>{t("Bank")}:</dt>
        <dd>{bankAccount.bank}</dd>
        <dt>{t("Account holder")}:</dt>
        <dd>{getHolderName(mainData, associates, account.current.accountHolder)}</dd>
      </dl>

      <Retry status={externalAccountsStatus} retry={onRetry}>
        {externalElem}
      </Retry>

      <AnimateHeight name={message}>
        {message ? (
          <>
            <SuccessBox>{t(message)}</SuccessBox>
            <Button block ghost onClick={onClose} className="m-top-30">
              {t("Close")}
            </Button>
          </>
        ) : (
          <>
            <div className="m-bottom-10">
              <StatusBoxes
                status={
                  confirmStatus === Status.ERROR || rejectStatus === Status.ERROR
                    ? Status.ERROR
                    : Status.DEFAULT
                }
              >
                <ErrorBox relative>{t("Something went wrong. Try again?")}</ErrorBox>
              </StatusBoxes>
            </div>

            {confirmed.current ? null : (
              // <Button block onClick={onClose} variant="text">
              //   {t("Close")}
              // </Button>
              <>
                <div className="m-bottom-10">
                  <ConfirmButton
                    ghost
                    onClick={onReject}
                    removeButtonText={t("Yes, reject")}
                    status={rejectStatus}
                  >
                    {t("Reject bank account")}
                  </ConfirmButton>
                </div>

                <ConfirmButton
                  onClick={onConfirm}
                  removeButtonText={t("Yes, confirm")}
                  confirmType={ConfirmType.SUCCESS}
                  status={confirmStatus}
                >
                  {t("Confirm bank account")}
                </ConfirmButton>
              </>
            )}
          </>
        )}
      </AnimateHeight>
    </div>
  );
};
