import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { dataAuth } from "../../data/dataAuth";
import { LOGOUT_ROUTE } from "../../pages/Auth/Logout";
import { userState } from "../../state/userState";
import { Store, STORE_KEY } from "../../Store";
import { Button } from "../interactions/Buttons/Button";
import styles from "./LogoutWarning.module.scss";
import { NewOverlay } from "../overlay/NewOverlay";

const WARNING_MINUTES = 15;
const INTERVAL_SECONDS = 3 * 1000;

export const LogoutWarning = () => {
  const [showWarning, setShowWarning] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const user = useRecoilValue(userState);
  const setUserState = useSetRecoilState(userState);
  const timer = useRef<number>();

  useEffect(() => {
    if (!user) {
      clearInterval(timer.current);
      return;
    }

    timer.current = window.setInterval(() => {
      const expiry = DateTime.fromISO(user.expiry);
      if (expiry.diffNow().milliseconds < 0) {
        setShowWarning(false);
        history.push(LOGOUT_ROUTE);
      } else if (expiry.diffNow("minutes").minutes < WARNING_MINUTES) {
        setShowWarning(true);
      }
    }, INTERVAL_SECONDS);

    return () => {
      clearInterval(timer.current);
    };
  }, [history, user]);

  return (
    <div className="light-theme">
      <NewOverlay open={showWarning} onClose={() => {}} disableClose={true} widthSize="small">
        <div className={styles.root}>
          <h4 className={styles.h4}>{t("You are about the be logged out")}</h4>
          <p>{t("You will soon be logged out of the application.")}</p>
          <div className={styles.buttons}>
            <Button
              block
              onClick={() => {
                setShowWarning(false);
                history.push(LOGOUT_ROUTE);
              }}
              className="danger-button"
            >
              {t("Logout")}
            </Button>
            <Button
              block
              onClick={() => {
                dataAuth
                  .getCurrentSession()
                  .then((resp) => {
                    setUserState(resp);
                    Store.setValue(STORE_KEY.STORE_USER, resp);
                  })
                  .catch(() => {
                    history.push(LOGOUT_ROUTE);
                  })
                  .finally(() => {
                    setShowWarning(false);
                  });
              }}
            >
              {t("Stay logged in")}
            </Button>
          </div>
        </div>
      </NewOverlay>
    </div>
  );
};
