import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { Checkboxes } from "../../../../components/interactions/Checkboxes/Checkboxes";
import { contractPricingState } from "../../../../state/contractCostState";
import { contractStatusState } from "../../../../state/contractStatusState";
import styles from "./Acceptance.module.scss";
import { ContractType } from "../../../../model/contract/contractType";
import { useCallback } from "react";

const ACCEPTANCE = "ACCEPTANCE";

export const Acceptance: React.FC = () => {
  const [cost, setCost] = useRecoilState(contractPricingState);
  const { edit } = useRecoilValue(contractStatusState);
  const { acceptance } = cost.INSTORE;
  const { t } = useTranslation();

  const onEnableAquiringOnly = useCallback(
    (value: string[]) => {
      setCost((prev) => ({
        ...prev,
        [ContractType.INSTORE]: {
          ...prev[ContractType.INSTORE],
          acceptance: value.includes(ACCEPTANCE),
        },
      }));
    },
    [setCost]
  );

  return (
    <>
      <div className={styles.root}>
        <h5>{t("Acceptance")}</h5>

        <div className="tablet-columns ">
          <div className="m-top-30">
            <Checkboxes
              disabled={!edit}
              onChange={onEnableAquiringOnly}
              values={acceptance ? [ACCEPTANCE] : []}
              alternatives={[
                {
                  text: t("Acceptance"),
                  value: ACCEPTANCE,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
