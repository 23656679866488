import cx from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useRecoilValue } from "recoil";
import { Retry } from "../../../components/retry/Retry";
import { ContractListResponse, dataContracts } from "../../../data/dataContracts";
import { FilterUtils } from "../../../model/Filter";
import { Page } from "../../../model/Page";
import { Status } from "../../../data/types";
import { userState } from "../../../state/userState";
import {
  DashboardDatesFormat,
  DashboardSettings,
  Filter as FilterInterface,
  STORE_KEY,
  Store,
} from "../../../Store";
import { ContractTableRow } from "./ContractTableRow";
import "./ContractTableView.scss";
import { FaRegSadCry } from "react-icons/fa";
import ContractTableHeader from "./ContractTableHeader";
import ContractTableFilters from "./ContractTableFilters";
import { DASHBOARD_ROUTE } from "../DashboardPage";
import { NewPagination } from "../../../components/NewPagination/NewPagination";
import { ContractStatus } from "../../../model/contract/contractType";

interface Props {
  className?: string;
}

// const ESC = 27;

const INITIAL_PAGE_SIZE = Number(Store.getValue<FilterInterface>(STORE_KEY.STORE_DASHBOARD_FILTER).size);
const INITIAL_DATE_FORMAT = Store.getValue<DashboardSettings>(STORE_KEY.STORE_DASHBOARD_SETTINGS).dateFormat;

export type StatusCount = Record<ContractStatus, number>;

export const ContractTableView: React.FunctionComponent<Props> = ({ className }) => {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [contracts, setContracts] = useState<Page<ContractListResponse>>();
  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE ?? 15);
  const [dateFormat, setDateFormat] = useState<DashboardDatesFormat>(INITIAL_DATE_FORMAT);
  const user = useRecoilValue(userState);
  const { t } = useTranslation();

  const { search, pathname } = useLocation(); // search is the whole query string...
  const history = useHistory();

  const filter = useMemo(() => FilterUtils.searchToFilter(search), [search]);

  const onChangePageSize = useCallback(
    (newValue: number) => {
      const newFilter: FilterInterface = { ...filter, size: newValue, page: 0 };
      Store.setValue(STORE_KEY.STORE_DASHBOARD_FILTER, newFilter);
      setPageSize(Number(newValue));
      history.replace(`${DASHBOARD_ROUTE}${FilterUtils.getQueryFromFilter(newFilter)}`);
    },
    [filter, history]
  );

  const onChangeDateFormat = useCallback((newFormat: DashboardDatesFormat) => {
    Store.setValue(STORE_KEY.STORE_DASHBOARD_SETTINGS, { dateFormat: newFormat });
    setDateFormat(newFormat);
  }, []);

  useEffect(() => {
    if (!search) {
      const filterStorage = Store.getValue<FilterInterface>(STORE_KEY.STORE_DASHBOARD_FILTER);
      return history.replace(`${DASHBOARD_ROUTE}${FilterUtils.getQueryFromFilter(filterStorage)}`);
    }
  }, [history, search]);

  const fetchPageData = useCallback(() => {
    setStatus(Status.PENDING);
    const finalFilter: FilterInterface = { ...filter, size: pageSize };
    dataContracts
      .loadContractsListing(finalFilter)
      .then((response) => {
        setStatus(Status.SUCCESS);
        setContracts(response);
      })
      .catch((err) => {
        setStatus(Status.ERROR);
      });
  }, [filter, pageSize]);

  useEffect(() => {
    Store.setValue(STORE_KEY.STORE_DASHBOARD_FILTER, filter);
    fetchPageData();
  }, [fetchPageData, filter]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(fetchPageData, 600);
  }, [fetchPageData]);

  const onPageChange = (page: number) => {
    const searchParams = new URLSearchParams(search);
    searchParams.set("page", page.toString());

    history.push({ pathname: pathname, search: searchParams.toString() });
  };

  if (!search) {
    return null;
  }

  return (
    <div className={cx("filter-view", className)}>
      <ContractTableFilters
        onChangePageSize={onChangePageSize}
        pageSize={pageSize}
        dateFormat={dateFormat}
        onChangeDateFormat={onChangeDateFormat}
      />

      <Retry status={status} retry={retry} errorStyle={{ width: "100%" }}>
        <div className={"filter-table-wrapper"}>
          <table className="filter-table">
            <ContractTableHeader />
            {contracts?.content && (
              <tbody>
                {contracts?.content.map((contract, index) => {
                  return (
                    <ContractTableRow
                      dateFormat={dateFormat}
                      key={contract.contractId}
                      user={user}
                      contract={contract}
                      index={index}
                    />
                  );
                })}
              </tbody>
            )}
          </table>
          {!contracts?.content.length && (
            <div className="filter-row-empty">
              <FaRegSadCry size={20} />
              <div>{t("No contracts found")}</div>
            </div>
          )}
        </div>
      </Retry>

      <div className="filter-view-pagination">
        <NewPagination
          onPageChange={onPageChange}
          activePage={contracts ? contracts.number : 0}
          totalPages={contracts ? contracts.totalPages : 0}
          alwaysShow={true}
          paginationsPerSide={4}
        />
      </div>
    </div>
  );
};

// const handler = useCallback((ev) => {
//   if (ev.keyCode === ESC) {
//     setOpen(false);
//   }
// }, []);

// useEffect(() => {
//   window.addEventListener("keydown", handler, false);
//   return () => {
//     window.removeEventListener("keydown", handler, false);
//   };
// }, [handler]);

// const onClose = useCallback(() => {
//   setOpen(false);
// }, []);
