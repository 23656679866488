import { FC, useState } from "react";
import cx from "classnames";
import styles from "./ContentBox.module.scss";
import { Collapsible } from "../animate/Collapsible";
import { Button } from "../interactions/Buttons/Button";
import { T } from "../translation/T";
import { ChevronDown } from "../icons/ChevronDown";
import { ChevronUp } from "../icons/ChevronUp";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  colorStyle?: "default" | "grey" | "completed";
  padding?: "no" | "xs" | "s" | "md";
  collapsible?: boolean;
  header?: string;
}

const ContentBox: FC<Props> = ({
  children,
  colorStyle = "default",
  padding = "md",
  className,
  collapsible = false,
  header,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { t } = useTranslation();

  const headerClass = cx(styles.header, {
    [styles.no_padding]: padding === "no",
    [styles.padding_xs]: padding === "xs",
    [styles.padding_s]: padding === "s",
    [styles.padding_md]: padding === "md",
  });

  const contentClass = cx(styles.content, {
    [styles.no_padding]: padding === "no",
    [styles.padding_xs]: padding === "xs",
    [styles.padding_s]: padding === "s",
    [styles.padding_md]: padding === "md",
  });

  return (
    <div
      className={cx(styles.root, className, {
        [styles.color_style_default]: colorStyle === "default",
        [styles.color_style_grey]: colorStyle === "grey",
        [styles.color_style_completed]: colorStyle === "completed",
      })}
    >
      {header && (
        <div className={headerClass}>
          <h5 style={{ margin: 0 }}>{t(header)}</h5>
        </div>
      )}
      <div className={contentClass}>
        {collapsible ? (
          <Collapsible collapsed={isCollapsed} variant="light">
            {children}
          </Collapsible>
        ) : (
          children
        )}
      </div>

      {collapsible && (
        <div className={styles.expand}>
          <div>
            <Button className="small" onClick={() => setIsCollapsed(!isCollapsed)}>
              <div className={styles.buttonText}>
                {isCollapsed ? (
                  <>
                    <T>Show more</T> <ChevronDown />
                  </>
                ) : (
                  <>
                    <T>Show less</T> <ChevronUp />
                  </>
                )}
              </div>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentBox;
