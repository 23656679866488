import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "../../../../components/links/Link";
import { getDynamicLink, MISSING_DATE } from "../../../../components/utils";
import { Associate } from "../../../../model/associate/associateTypes";
import { ContractId } from "../../../../model/common/commonType";
import { ContractStatus } from "../../../../model/contract/contractType";
import { ContractStatusState } from "../../../../state/contractStatusState";
import { ASSOCIATE_REVEW_PAGE } from "../../AssociateReview/AssociateReviewPage";
import "./EventMerchantConfirmation.scss";
import { EyeIcon } from "../../../../components/icons/EyeIcon";

interface Props {
  sentToMerchantDate: string;
  sentToOwnersForSignature: string;
  contractStatus: ContractStatusState;
  contractId: ContractId;
  primaryContact?: Associate;
}

export const EventMerchantConfirmation: React.FunctionComponent<Props> = ({
  sentToMerchantDate,
  sentToOwnersForSignature,
  contractStatus,
  contractId,
  primaryContact,
}) => {
  const { t } = useTranslation();
  const { status } = contractStatus;

  const eventClass = cx("timeline-event-merchant-confirmation", {
    active: sentToMerchantDate !== MISSING_DATE,
  });

  const linkClass = cx("event-button", "event-button--right", "as-button", {
    "is-disabled": status !== ContractStatus.MERCHANT_VERIFICATION,
  });

  return (
    <li className={eventClass}>
      <div className="event">
        <div className="timeline-date">{sentToMerchantDate}</div>
        <div>
          <b>{t("Contract sent for confirmation.")}</b>
          <div className="additional-event-info fs-small"></div>
        </div>
      </div>
      {primaryContact && (
        <Link
          className={linkClass}
          link={getDynamicLink(ASSOCIATE_REVEW_PAGE, {
            contractId,
            associateId: primaryContact.associateId,
          })}
        >
          <EyeIcon />
          {status === ContractStatus.MERCHANT_VERIFICATION ? t("Waiting") : t("Confirmed")}
        </Link>
      )}
    </li>
  );
};
