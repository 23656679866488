import { useCallback, useEffect, useState, VoidFunctionComponent } from "react";
import { useRecoilValue } from "recoil";
import { BackofficeUser, userAdministration } from "../../data/UserAdministration";
import { Status } from "../../data/types";
import { userState } from "../../state/userState";
import { NewOverlay } from "../../components/overlay/NewOverlay";
import { Form } from "../../components/form/Form";
import { TextInput } from "../../components/form/TextInput";
import { RequiredValidator } from "../../components/form/validators/RequiredValidator";
import { EmailValidator } from "../../components/form/validators/EmailValidator";
import { EmailBackendValidator } from "../../components/form/validators/EmailBackendValidator";
import { Button } from "../../components/interactions/Buttons/Button";
import { Edit } from "../../components/icons/Edit";

interface Props {
  backOfficeUser: BackofficeUser;
  refresh: () => void;
}

export const BackOfficeUserEdit: VoidFunctionComponent<Props> = ({ backOfficeUser, refresh }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [newBackofficeUserRequest, setNewbackofficeUserRequest] = useState<BackofficeUser>(backOfficeUser);
  const [saveButtonStatus, setSaveButtonStatus] = useState(Status.DEFAULT);
  const user = useRecoilValue(userState);

  const isSelf = user?.userId === backOfficeUser.id;

  useEffect(() => {
    setNewbackofficeUserRequest(backOfficeUser);
  }, [backOfficeUser]);

  const updateWithReset = useCallback(
    (status: Status) => {
      setSaveButtonStatus(status);
      setTimeout(() => {
        setSaveButtonStatus(Status.DEFAULT);
      }, 4000);
    },
    [setSaveButtonStatus]
  );

  const handleDelete = useCallback(() => {
    userAdministration
      .deleteBackofficeUsers(backOfficeUser.id)
      .then(() => {
        refresh();
        setOpen(false);
      })
      .catch();
  }, [backOfficeUser, refresh]);

  return (
    <div className="add-admin-user-overlay light-theme">
      <NewOverlay open={open} onClose={() => setOpen(false)}>
        <strong>Backoffice User</strong>
        <Form
          onSubmit={(event, form) => {
            if (form.isInvalid) {
              return;
            }
            setSaveButtonStatus(Status.PENDING);

            userAdministration
              .editBackofficeUsers(newBackofficeUserRequest.id, {
                email: newBackofficeUserRequest.email,
                name: newBackofficeUserRequest.name,
                loginMethod: newBackofficeUserRequest.loginMethod,
                country: newBackofficeUserRequest.country,
              })
              .then(() => {
                refresh();
                setOpen(false);
                setSaveButtonStatus(Status.DEFAULT);
              })
              .catch(() => {
                updateWithReset(Status.ERROR);
              });
          }}
        >
          <div className="tablet-columns m-y-30">
            <div>
              <TextInput
                onChange={(value) => {
                  setNewbackofficeUserRequest({
                    ...newBackofficeUserRequest,
                    email: value,
                  });
                }}
                value={newBackofficeUserRequest.email}
                name="email"
                label="Email"
                placeholder="Used as login"
                validators={[
                  new RequiredValidator("Email is required"),
                  new EmailValidator("Invalid email format"),
                  new EmailBackendValidator("Email is not valid"),
                ]}
              />
            </div>
            <div>
              <TextInput
                onChange={(value) => {
                  setNewbackofficeUserRequest({
                    ...newBackofficeUserRequest,
                    name: value,
                  });
                }}
                value={newBackofficeUserRequest.name}
                name="name"
                label="Name"
                placeholder="Display name"
                validators={[new RequiredValidator("Name is required")]}
              />
            </div>
          </div>
          <div className="tablet-columns">
          <div>
              <Button
                type="button"
                className="danger-button"
                onClick={handleDelete}
                block
                status={isSelf ? Status.DISABLED : Status.DEFAULT}
              >
                Delete user
              </Button>
            </div>
            <div>
              <Button type="submit" block status={saveButtonStatus}>
                Save
              </Button>
            </div>

            
          </div>
        </Form>
      </NewOverlay>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        className="open-edit-button"
      >
        <Edit />
      </Button>
    </div>
  );
};
