import { FC } from "react";
import PaginationButton from "./PaginationButton";
import styles from "./NewPagination.module.scss";

interface Props {
  onPageChange: (pageNumber: number) => void;
  activePageNumber: number;
  lastPageNumber: number;
  paginationsPerSide: number; // number of pagination buttons per side of middle button. Total will be paginationsPerSide * 2 + 1 (middle). so 4 = 4*2 + 1 = 9
}

type PaginationData = {
  type: "number" | "dots";
  pageNumber: number;
};

const DynamicPaginationButtons: FC<Props> = ({
  onPageChange,
  activePageNumber,
  lastPageNumber,
  paginationsPerSide,
}) => {
  const paginationButtons: PaginationData[] = getPaginationDataArray(
    activePageNumber,
    lastPageNumber,
    paginationsPerSide
  );
  return (
    <>
      {paginationButtons.map(({ type, pageNumber }, index) => {
        return type === "dots" ? (
          <PageButtonDots key={index} />
        ) : (
          <PaginationButton
            key={index}
            onPageChange={onPageChange}
            pageNumber={pageNumber}
            isSelected={pageNumber === activePageNumber}
          />
        );
      })}
    </>
  );
};

export default DynamicPaginationButtons;

const PageButtonDots: FC = () => {
  return <div className={styles.page_button}>...</div>;
};

// The number of pagination buttons to display is by default is 9. Calculated from: 4 (left side) + 1 (middle) + 4 (right side) = 9;

const calculateElementPosition = (
  activePageNumber: number,
  lastPageNumber: number,
  numberOfSpotsAwayFromMiddle: number, // negative for left, positive for right side of middle
  paginationsPerSide: number
) => {
  const middlePaginationSpot = paginationsPerSide + 1;

  const isReachingLeftEnd = activePageNumber <= middlePaginationSpot;
  const isReachingRightEnd = activePageNumber >= lastPageNumber - paginationsPerSide;

  if (isReachingLeftEnd) {
    return middlePaginationSpot + numberOfSpotsAwayFromMiddle;
  }

  if (isReachingRightEnd) {
    return lastPageNumber - paginationsPerSide + numberOfSpotsAwayFromMiddle;
  }

  // when we display "dots"
  return activePageNumber + numberOfSpotsAwayFromMiddle;
};

const getPaginationDataArray = (
  activePageNumber: number,
  lastPageNumber: number,
  paginationsPerSide: number
) => {
  const middlePaginationSpot = paginationsPerSide + 1;

  const paginationData: PaginationData[] = [];

  // Add first page number
  paginationData.push({ type: "number", pageNumber: 1 });

  // Add left dots if necessary
  if (activePageNumber >= middlePaginationSpot) {
    paginationData.push({ type: "dots", pageNumber: 12345 });
  } else {
    paginationData.push({ type: "number", pageNumber: 2 });
  }

  // Generate middle elements dynamically. -2 comes from the first element and the "dots" elements
  const LOOP_ENDS = paginationsPerSide - 2;
  for (let i = -LOOP_ENDS; i <= LOOP_ENDS; i++) {
    const pageNumber = calculateElementPosition(activePageNumber, lastPageNumber, i, paginationsPerSide);
    paginationData.push({ type: "number", pageNumber: pageNumber });
  }

  // Add right dots if necessary
  if (activePageNumber <= lastPageNumber - paginationsPerSide) {
    paginationData.push({ type: "dots", pageNumber: 54321 });
  } else {
    paginationData.push({ type: "number", pageNumber: lastPageNumber - 1 });
  }

  // Add last page number
  paginationData.push({ type: "number", pageNumber: lastPageNumber });

  return paginationData;
};
