import React, { useState } from "react";
import { Button } from "../../../components/interactions/Buttons/Button";
import { useTranslation } from "react-i18next";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { Status } from "../../../data/types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { contractStatusState } from "../../../state/contractStatusState";
import { contractDocumentsState } from "../../../state/contractDocumentsState";
import { TextArea } from "../../../components/form/TextArea";
import { MaxLengthValidator } from "../../../components/form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../../../components/form/validators/MinLengthValidator";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { dataConfirm } from "../../../data/dataConfirm";
import "./AddCustomDoc.scss";

const DESCRIPTION_MAX_LENGTH = 200;
const DESCRIPTION_MIN_LENGTH = 10;

export const AddCustomDoc: React.FunctionComponent = () => {
  const contractStatus = useRecoilValue(contractStatusState);
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [value, setValue] = useState<string>("");
  const { t } = useTranslation();
  const setDocs = useSetRecoilState(contractDocumentsState);

  const addCustomDoc = () => setOpen(true);

  function onChange(inputValue: string) {
    setValue(inputValue);
  }

  const save = () => {
    if (!value) {
      setMessage(
        t(
          "You need to provide the merchant with a description of the document."
        )
      );
      setStatus(Status.ERROR);

      setTimeout(() => {
        setMessage("");
        setStatus(Status.DEFAULT);
      }, 4000);
      return;
    }

    setMessage("");
    setStatus(Status.PENDING);

    dataConfirm
      .addSalesCustomDocument(contractStatus.contractId, value)
      .then((docs) => {
        setDocs(docs);
        setOpen(false);
        setValue("");
        setStatus(Status.DEFAULT);
      })
      .catch((err) => {
        setMessage(t("Something went wrong. We couldn't save the document."));
        setStatus(Status.ERROR);

        setTimeout(() => {
          setStatus(Status.DEFAULT);
        }, 4000);
      });
  };

  const buttonStatus = contractStatus.edit ? status : Status.DISABLED;

  return (
    <div className="add-custom-docs m-top-40">
      <AnimateHeight name={open.toString()}>
        {open ? (
          <div>
            <p>
              <strong>{t("Add custom document")}</strong>
            </p>
            <div className="custom-doc-input">
              <TextArea
                message={message}
                name="upload-file-desc"
                value={value}
                onChange={onChange}
                label={t("Document description")}
                validators={[
                  new RequiredValidator(t("Description is required")),
                  new MaxLengthValidator(
                    DESCRIPTION_MAX_LENGTH,
                    t(`Max length is {{DESCRIPTION_MAX_LENGTH}} characters`, {
                      DESCRIPTION_MAX_LENGTH,
                    })
                  ),
                  new MinLengthValidator(
                    DESCRIPTION_MIN_LENGTH,
                    t(`Min length is {{DESCRIPTION_MIN_LENGTH}} characters`, {
                      DESCRIPTION_MIN_LENGTH,
                    })
                  ),
                ]}
              />
              <Button onClick={save} status={status}>
                {t("Save document")}
              </Button>
            </div>
          </div>
        ) : (
          <Button
            block
            onClick={addCustomDoc}
            status={buttonStatus}
            variant="text"
          >
            {t("Add custom document")}
          </Button>
        )}
      </AnimateHeight>
    </div>
  );
};
