import React, { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { contractPackageState } from "../../../state/contractPackageState";
import { PackageId, TerminalType } from "../../../model/contract/contractType";
import { Fee } from "../../../components/fee/Fee";
import { getIntlCost } from "../../../components/utils";
import { contractTerminalsSelector } from "../../../state/contractStoresState";
import { costMappings, pricingMapping } from "../../../components/acquiring/AcquiringHR";
import { Language } from "../../../i18n";
import { Currency } from "../../../model/common/commonType";

export const SOFT_POS_MONTHLY = 3;
export const DESK_MONTHLY = 8.9;
export const MOVE_MONTHLY = 10.9;

export function PricingHR() {
  const pack = useRecoilValue(contractPackageState);
  const terminals = useRecoilValue(contractTerminalsSelector);
  let volume = "-";
  let cost = "-";
  const mappedVolume = pricingMapping[pack.pricingStep as keyof typeof pricingMapping];

  const getCost = useCallback((noOfDesk, noOfMove) => {
    return Math.max(0, noOfDesk * DESK_MONTHLY + noOfMove * MOVE_MONTHLY);
  }, []);

  if (pack.packageId === PackageId.SOFTPOS_BUNDLE) {
    const noOfTerminals = terminals.filter(
      (terminal) => terminal.terminalType === TerminalType.SOFT_POS
    ).length;

    const quantity = Math.max(0, noOfTerminals - 1);
    const additionalCost = quantity * SOFT_POS_MONTHLY;
    const softPosCost = pack.pricingStep ? costMappings[PackageId.SOFTPOS_BUNDLE][pack.pricingStep] : 0;

    if (mappedVolume) {
      volume = new Intl.NumberFormat(Language.CROATIA).format(mappedVolume);
      cost = getIntlCost(Language.CROATIA, additionalCost + Number(softPosCost), Currency.CROATIA, 2);
    }

    return (
      <div className="pricing-hr">
        SoftPos application is available on Android
        <div className="tablet-columns m-top-20">
          <div className="m-bottom-40">
            <strong>Included:</strong>
            <br />- Monthly acquiring service fee 1 Account
            <br />
            <br />
            <b>{quantity}</b> additional accounts
            <br />-{" "}
            <Fee
              cost={SOFT_POS_MONTHLY}
              lang={Language.CROATIA}
              currency={Currency.CROATIA}
              extension="month"
              decimals={2}
            />{" "}
            per account.
            <br />
            <br />
            Additional functions:
            <br />- Dynamic currency conversion (DCC)
          </div>

          <div className="m-bottom-40">
            <strong>Services:</strong>
            <br />- Hotline
            <br />- App-download
            <br />- Software update
            <br />- System operation
          </div>
        </div>
        <div className="m-bottom-40">
          <strong>Acceptance:</strong>
          <dl>
            <dt>Brands:</dt>
            <dd>Visa, Mastercard, V PAY, Maestro</dd>

            <dt>Reimbursement currency:</dt>
            <dd>{Currency.CROATIA}</dd>

            <dt>Reimbursement frequency:</dt>
            <dd>Daily</dd>

            <dt>Reimbursement notice:</dt>
            <dd>Transaction level (detailed)</dd>

            <dt>Dynamic currency conversion (DCC):</dt>
            <dd>-1 % rebate on DCC transaction amount</dd>

            <dt>Uključene prednosti:</dt>
            <dd>Online Izvještaji / korištenje myPortal sučelja za trgovce </dd>
          </dl>
        </div>
        <div className="m-bottom-40">
          <strong>Mjesečna paušalna naknada:</strong>
          <dl>
            <dt>Mjesečni transkcijski paket:</dt>
            <dd>{volume}</dd>

            <dt>
              Mjesečna naknada za Acquiring usluge (paket) i dodatnih <b>{quantity} računa</b>:
            </dt>
            <dd>{cost}</dd>
          </dl>

          <div className="disclaimer">
            Trgovac ovlašćuje društvo SIX Payment Services za prebijanje dospjelih potraživanja (osobito
            mjesečne paušalne naknade) Isplatama proizašlim iz ispunjavanja ovog Modula Ugovora. Transakcije
            koje prelaze mjesečni iznos paketa isključuju se iz svih promocija (npr. Besplatni mjesec
            korištenja).
          </div>

          <div className="disclaimer">
            Doplata za prekoračenje iznosa transkacijskog paketa: odnosi se na sve transakcije koje prekorače
            mjesečni iznos paketa uključenog u pašalnu naknadu. Vrijedi za sva sredstva plaćanja: 1,29% (od
            iznosa transakcije) Doplata za poslovne kartice: odnosi se na sve transakcije učinjene s poslovnim
            karticama (npr. Visa Business) za sva sredstva plaćanja: 1,20% (od iznosa transakcije).
          </div>
        </div>
      </div>
    );
  }

  const numberOfDeskTerminals = terminals.filter(
    (terminal) => terminal.terminalType === TerminalType.DESK_3500
  ).length;

  const numberOfMoveTerminals = terminals.filter(
    (terminal) => terminal.terminalType === TerminalType.MOVE_3500
  ).length;

  const additionalDeskTerminals = Math.max(0, numberOfDeskTerminals - 1);
  const additionalMoveTerminals = Math.max(0, numberOfMoveTerminals - 1);
  const isDesk = pack.packageId === PackageId.DESK_BUNDLE;

  if (mappedVolume) {
    // volume = getIntlCost(lang, Number(mappedVolume), CURRENCY);
    volume = new Intl.NumberFormat(Language.CROATIA).format(mappedVolume);
    let terminalCost = 0;

    try {
      // @ts-ignore
      terminalCost = costMappings[pack.packageId][pack.pricingStep];
    } catch (err) {}

    cost = getIntlCost(
      Language.CROATIA,
      getCost(additionalDeskTerminals, additionalMoveTerminals) + terminalCost,
      Currency.CROATIA,
      2
    );
  }

  return (
    <div className="pricing-hr">
      <div className="m-bottom-30">
        <strong>Included:</strong>
        <br />- Acquiring service fee for <b>one</b> {isDesk ? "Desk 3500" : "Move 3500"} terminal <br />-
        Onsite installation
        <br />- A terminal charger with each terminal
      </div>
      <div className="m-bottom-30">
        <b>Additional terminals</b>
        <br />

        {isDesk ? (
          <>
            Desk 3500:{" "}
            <Fee
              cost={DESK_MONTHLY}
              lang={Language.CROATIA}
              currency={Currency.CROATIA}
              extension="month"
              decimals={2}
            />{" "}
            per terminal: Quantity <b>{additionalDeskTerminals}</b>
          </>
        ) : (
          <>
            <Fee
              cost={MOVE_MONTHLY}
              lang={Language.CROATIA}
              currency={Currency.CROATIA}
              extension="month"
              decimals={2}
            />{" "}
            per terminal: Quantity <b>{additionalMoveTerminals}</b>
          </>
        )}
      </div>
      <b>Service package Advanced</b>
      <div className="tablet-columns">
        <div>
          - Troubleshooting: on-site repair
          <br />- Hotline
          <br />- Software updates
          <br />- System operation
        </div>
        <div>
          - Dynamic currency conversion (DCC)
          <br />- Data communication (sim card/wifi)
        </div>
      </div>
      <div className="m-top-30">
        <strong>Acceptance:</strong>
        <dl>
          <dt>Brands:</dt>
          <dd>Visa, Mastercard, V PAY, Maestro, Diners Club/Discover, UnionPay, JCB</dd>

          <dt>Reimbursement currency:</dt>
          <dd>{Currency.CROATIA}</dd>

          <dt>Reimbursement frequency:</dt>
          <dd>Daily</dd>

          <dt>Reimbursement notice:</dt>
          <dd>Transaction level (detailed)</dd>

          <dt>Dynamic currency conversion (DCC):</dt>
          <dd>-1 % rebate on DCC transaction amount</dd>

          <dt>Features included:</dt>
          <dd>Online reports</dd>
        </dl>
      </div>

      <div className="m-top-30">
        <strong>Monthly flat fee:</strong>
        <dl>
          <dt>Monthly transaction volume included:</dt>
          <dd>{volume}</dd>

          <dt>
            {isDesk ? (
              <>
                Transaction fee based on volume and <b>{additionalDeskTerminals}</b> additional Desk terminals
              </>
            ) : (
              <>
                Transaction fee based on volume and <b>{additionalMoveTerminals}</b> additional Move
                terminals:
              </>
            )}
          </dt>
          <dd>{cost}</dd>
        </dl>

        <div className="disclaimer">
          Trgovac ovlašćuje društvo SIX Payment Services za prebijanje dospjelih potraživanja (osobito
          mjesečne paušalne naknade) Isplatama proizašlim iz ispunjavanja ovog Modula Ugovora. Transakcije
          koje prelaze mjesečni iznos paketa isključuju se iz svih promocija (npr. Besplatni mjesec
          korištenja).
        </div>

        <div className="disclaimer">
          Doplata za prekoračenje iznosa transkacijskog paketa: odnosi se na sve transakcije koje prekorače
          mjesečni iznos paketa uključenog u pašalnu naknadu. Vrijedi za sva sredstva plaćanja: 1,29% (od
          iznosa transakcije) Doplata za poslovne kartice: odnosi se na sve transakcije učinjene s poslovnim
          karticama (npr. Visa Business) za sva sredstva plaćanja: 1,20% (od iznosa transakcije).
        </div>
      </div>
    </div>
  );
}
