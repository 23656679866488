import { FC } from "react";
import { Link } from "../../../components/links/Link";
import ContentBox from "../../../components/ContentBox/ContentBox";
import { Contract, PackageId } from "../../../model/contract/contractType";
import { API } from "../../../network/API";
import { useTranslation } from "react-i18next";
import { Country } from "../../../model/common/commonType";

interface Props {
  contract: Contract;
}

const ContractDownloads: FC<Props> = ({ contract }) => {
  const { t } = useTranslation();

  const contractDownloadLinks = generateContractDownloadLinks(contract);

  const showTerminalsFile = contract.contractPackage?.packageId !== PackageId.SOFTPOS_BUNDLE;
  const showExcelFiles = contract.country === Country.POLAND || contract.country === Country.CZECHIA;
  const onboardingDownloadLinks = generateOnboardingDownloadLinks(
    contract,
    showTerminalsFile,
    showExcelFiles
  );

  return (
    <ContentBox>
      <h5>{t("Contract downloads")}</h5>
      <ul>
        {contractDownloadLinks?.map((linkData) => (
          <li style={{ marginBottom: 5 }} key={linkData.link}>
            <Link
              className={linkData.button ? "as-button ghost m-bottom-10 block" : ""}
              external
              download={linkData.download}
              link={API.getUrl(linkData.link)}
              iconType={linkData.downloadIcon ? "download" : "default"}
            >
              {t(linkData.text)}
            </Link>
          </li>
        ))}
      </ul>
      <hr style={{ margin: "20px 0" }} />
      <h5>{t("Onboarding Downloads")}</h5>
      <ul>
        {onboardingDownloadLinks?.map((linkData) => (
          <li style={{ marginBottom: 5 }} key={linkData.link}>
            <Link
              className={linkData.button ? "as-button ghost m-bottom-20" : ""}
              external
              download={linkData.download}
              link={API.getUrl(linkData.link)}
              iconType={linkData.downloadIcon ? "download" : "default"}
            >
              {t(linkData.text)}
            </Link>
          </li>
        ))}
      </ul>
    </ContentBox>
  );
};

export default ContractDownloads;

type DonwloadLinkData = {
  download: string;
  link: string;
  button: boolean;
  text: string;
  downloadIcon: boolean;
};

const generateContractDownloadLinks = (contract: Contract | undefined): DonwloadLinkData[] | null => {
  if (!contract) return null;
  const { contractData, contractId } = contract;
  const { companyName, vatNumber } = contractData;

  const allContractFiles: DonwloadLinkData = {
    download: `Contract Bundle ${companyName} ${vatNumber}.zip`,
    link: `/api/sales/contracts/completed/${contractId}/merchant/contract/zip`,
    button: true,
    text: "All Contract Files",
    downloadIcon: true,
  };

  const merchantContract: DonwloadLinkData = {
    download: `Merchant Contract ${companyName} ${vatNumber}.pdf`,
    link: `/api/sales/contracts/completed/${contractId}/merchant/contract`,
    button: false,
    text: "Merchant contract",
    downloadIcon: false,
  };

  const contractAttachments: DonwloadLinkData = {
    download: `Contract attachments ${companyName} ${vatNumber}.pdf`,
    link: `/api/sales/contracts/completed/${contractId}/backoffice/attachments`,
    button: false,
    text: "Contract attachments",
    downloadIcon: false,
  };

  const links = [allContractFiles, merchantContract, contractAttachments];

  return links;
};

const generateOnboardingDownloadLinks = (
  contract: Contract | undefined,
  showTerminalsFile: boolean,
  showExcelFiles: boolean
): DonwloadLinkData[] | null => {
  if (!contract) return null;
  const { contractData, contractId } = contract;
  const { companyName, vatNumber } = contractData;

  const unlockedMerchantContract: DonwloadLinkData = {
    download: `iPASS Import ${companyName} ${vatNumber}.pdf`,
    link: `/api/sales/contracts/completed/${contractId}/backoffice/contract`,
    button: false,
    text: "Unlocked Merchant Contract",
    downloadIcon: false,
  };

  const links: DonwloadLinkData[] = [unlockedMerchantContract];

  if (showTerminalsFile) {
    const terminalsFile: DonwloadLinkData = {
      download: `Order ${companyName} + ${vatNumber}.xlsx`,
      link: `/api/sales/contracts/completed/${contractId}/backoffice/terminal`,
      button: false,
      text: "Terminals",
      downloadIcon: true,
    };

    links.push(terminalsFile);
  }

  if (showExcelFiles) {
    const ipassIndependent: DonwloadLinkData = {
      download: `iPass import ${companyName} ${vatNumber}.xlsx`,
      link: `/api/sales/contracts/completed/${contractId}/backoffice/ipass`,
      button: false,
      text: "Ipass import",
      downloadIcon: true,
    };

    const ipassDependent: DonwloadLinkData = {
      download: `Dependent partner ${companyName} ${vatNumber}.xlsx`,
      link: `/api/sales/contracts/completed/${contractId}/backoffice/dependant_partner`,
      button: false,
      text: "Dependent partner",
      downloadIcon: true,
    };

    links.push(ipassIndependent);
    links.push(ipassDependent);
  }

  return links;
};
