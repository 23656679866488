import React, { useCallback, useEffect, useState } from "react";
import { BackofficeUser, userAdministration } from "../../data/UserAdministration";
import { Country } from "../../model/common/commonType";
import { AdminPage } from "../Admin/AdminPage";
import { AddNewBackofficeUserOverlay } from "./AddBackofficeUserOverlay";
import { NewOverlay } from "../../components/overlay/NewOverlay";
import { Button } from "../../components/interactions/Buttons/Button";
import { Table } from "../../components/table/Table";
import { BackOfficeUserEdit } from "./BackOfficeUserEdit";

export const BACKOFFICE_ADMIN_USERS_PAGE = "/admin/users/backoffice";

export const BackOfficeUsersPage: React.FunctionComponent = () => {
  const [backofficeUsers, setBackOfficeUsers] = useState<BackofficeUser[]>([]);
  const [backofficeEditId, setbackofficeEditId] = useState<number>(-1);

  const [countryFilter, setCountryFilter] = useState<Country>();
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState<"ascending" | "descending">("ascending");

  useEffect(() => {
    userAdministration.loadAllBackofficeUsers().then(setBackOfficeUsers);
  }, [setBackOfficeUsers]);

  const onClose = useCallback(() => {
    setbackofficeEditId(-1);
  }, []);

  const isSorted = (property: string) => {
    if (sortField === property) {
      return sortDirection;
    }
    return undefined;
  };

  const sort = (property: string) => {
    setSortField(property);
    const direction = sortDirection === "ascending" ? "descending" : "ascending";
    setSortDirection(direction);
  };

  return (
    <AdminPage title="Admin Users" className="admin-users">
      <section>
        <article>
          <AddNewBackofficeUserOverlay
            refresh={() => userAdministration.loadAllBackofficeUsers().then(setBackOfficeUsers)}
          />

          <NewOverlay open={backofficeEditId !== -1} onClose={onClose} className="overlay-edit">
            <p>Edit backoffice user with id {backofficeEditId}</p>
          </NewOverlay>
          {countryFilter ? (
            <Button
              type="button"
              block
              ghost
              onClick={(_) => {
                setCountryFilter(undefined);
              }}
            >
              Show all
            </Button>
          ) : null}
          <Table className="m-top-20">
            <thead>
              <tr>
                <th />
                <th>
                  <button className={isSorted("name")} onClick={(_) => sort("name")}>
                    Name
                  </button>
                </th>
                <th>
                  <button className={isSorted("email")} onClick={(_) => sort("email")}>
                    Email
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {backofficeUsers
                .filter((user) => {
                  if (countryFilter) {
                    return user.country === countryFilter;
                  }
                  return true;
                })
                .sort((r1, r2) => {
                  if (sortField.length === 0) {
                    return 0;
                  }
                  const val1 = r1[sortField as keyof BackofficeUser] as string;
                  const val2 = r2[sortField as keyof BackofficeUser] as string;
                  if (val1 === undefined) {
                    return 1;
                  }
                  if (val2 === undefined) {
                    return -1;
                  }
                  if (val1 > val2) {
                    return 1 * (sortDirection === "descending" ? -1 : 1);
                  }
                  if (val1 < val2) {
                    return -1 * (sortDirection === "descending" ? -1 : 1);
                  }
                  return 0;
                })
                .map((row) => (
                  <tr key={row.id}>
                    <td className="vertical-center">
                      <BackOfficeUserEdit
                        backOfficeUser={row}
                        refresh={() => userAdministration.loadAllBackofficeUsers().then(setBackOfficeUsers)}
                      />
                    </td>
                    <td className="truncate vertical-center">{row.name}</td>
                    <td className="truncate vertical-center">{row.email}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </article>
      </section>
    </AdminPage>
  );
};
