import { DateTimeFormatOptions } from "luxon";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useSetRecoilState } from "recoil";
import { ErrorBox } from "../../components/boxes/ErrorBox";
import { StatusBoxes } from "../../components/boxes/StatusBoxes";
import { Link } from "../../components/links/Link";
import { PermissionError } from "../../components/permissionError/PermissionError";
import { Retry } from "../../components/retry/Retry";
import { getDynamicLink, getIntlDate } from "../../components/utils";
import { dataContracts } from "../../data/dataContracts";
import { CompletedContract, dataConfirm } from "../../data/dataConfirm";
import { ContractId } from "../../model/common/commonType";
import { Contract, ContractStatus, PackageId, Validity } from "../../model/contract/contractType";
import { Status } from "../../data/types";
import { ServerError } from "../../network/API";
import { contractStatusState } from "../../state/contractStatusState";
import { MERCHANT_ROUTE } from "../MerchantStatus/MerchantStatusPage";
import "./CompletedContractPage.scss";
import ContentBox from "../../components/ContentBox/ContentBox";
import ContractDownloads from "./ContractDownloads/ContractDownloads";
import CompletedContractActions from "./CompletedContractActions/CompletedContractActions";
import { Page, PageType } from "../Page/Page";
import { Beacon } from "../../components/beacon/Beacon";

export const COMPLETED_CONTRACT_PAGE = "/contract/completed/:id";

const isCompletedContractDoneWorking = (completedContract: CompletedContract, contract?: Contract) => {
  if (
    completedContract.backofficeEmailSent &&
    completedContract.merchantEmailSent &&
    completedContract.backofficeProvisioningCompleted
  ) {
    if (contract?.contractPackage?.packageId === PackageId.SOFTPOS_BUNDLE) {
      if (completedContract.softposProvisioningCompleted) {
        return true;
      }
      //We're waiting for softpos provisiong
      return false;
    }
    //This was a normal terminal package, that means we're OK if emails has been sent
    return true;
  }
  return false;
};

const options: DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const CompletedContractInner: React.FunctionComponent<{
  contract?: Contract;
  id?: ContractId;
}> = ({ contract, id }) => {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [completedContract, setCompletedContract] = useState<CompletedContract>();
  const [merchantEmailStatus, setMerchantEmailStatus] = useState<Status>(Status.DISABLED);
  const [backofficeEmailStatus, setBackofficeEmailStatus] = useState<Status>(Status.DISABLED);
  const setContractStatus = useSetRecoilState(contractStatusState);

  const { i18n, t } = useTranslation();
  const lang = i18n.language;
  const timer = useRef<number>();

  const loadContractData = useCallback(
    async (id: ContractId) => {
      try {
        setStatus(Status.PENDING);
        const contract = await dataContracts.loadContract(id, true);
        setContractStatus({ ...contract, edit: false });
        const completedContract = await dataConfirm.loadCompletedContract(id, true);
        setCompletedContract(completedContract);
        setStatus(Status.SUCCESS);

        completedContract.merchantEmailSent
          ? setMerchantEmailStatus(Status.DEFAULT)
          : setMerchantEmailStatus(Status.DISABLED);

        completedContract.backofficeEmailSent
          ? setBackofficeEmailStatus(Status.DEFAULT)
          : setBackofficeEmailStatus(Status.DISABLED);

        if (isCompletedContractDoneWorking(completedContract, contract)) return;
        timer.current = window.setTimeout(() => loadContractData(id), 20000);
      } catch (e) {
        setStatus(Status.ERROR);
        timer.current = window.setTimeout(() => loadContractData(id), 30000);
      }
    },
    [setContractStatus]
  );

  const resendMerchantEmail = useCallback(() => {
    if (!id) return;

    setMerchantEmailStatus(Status.PENDING);
    setBackofficeEmailStatus(Status.PENDING);

    dataConfirm
      .resendMerchantEmail(id)
      .then(() => loadContractData(id))
      .finally(() => {
        setMerchantEmailStatus(Status.DEFAULT);
        setBackofficeEmailStatus(Status.DEFAULT);
      });
  }, [id, loadContractData]);

  const resendOnboardingEmail = useCallback(() => {
    if (!id) return;

    setMerchantEmailStatus(Status.PENDING);
    setBackofficeEmailStatus(Status.PENDING);

    dataConfirm
      .resendBackofficeEmail(id)
      .then(() => loadContractData(id))
      .finally(() => {
        setMerchantEmailStatus(Status.DEFAULT);
        setBackofficeEmailStatus(Status.DEFAULT);
      });
  }, [id, loadContractData]);

  useEffect(() => {
    loadContractData(id as ContractId);
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [loadContractData, id]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timer.current);
    };
  }, []);

  if (!contract || !id) return null;

  if (contract.status !== ContractStatus.COMPLETE) {
    return (
      <Page title="Completed contract" pageType={PageType.SALES_CONTRACT}>
        <section>
          <article>
            <h1>{contract.contractData.companyName}</h1>
            <p>Contract is not marked as completed</p>
            <Link link={getDynamicLink(MERCHANT_ROUTE, { id: contract.contractId })}>Back to timeline</Link>
          </article>
        </section>
      </Page>
    );
  }

  return (
    <div className="completed-contracts-page">
      <h1>{contract.contractData.companyName}</h1>

      <StatusBoxes status={status}>
        <ErrorBox relative>
          {t("Something went wrong. We couldn't fetch the most recent data. Reload page?")}
        </ErrorBox>
      </StatusBoxes>
      <h4 className="m-bottom-20">{t("Status")}</h4>
      <ContentBox className="m-bottom-30">
        <dl className="status-list">
          <dt className="fs-small">
            {completedContract?.started && <Beacon validity={Validity.VALID} mini />}
            {getIntlDate(lang, completedContract?.started, options)}
          </dt>
          <dd>
            <span>
              {t("Contract was finalized by")} <b>{completedContract?.startedBy ?? "..."}</b>.
            </span>
          </dd>
          <dt className="fs-small">
            {completedContract?.backofficeEmailSent && <Beacon validity={Validity.VALID} mini />}
            {getIntlDate(lang, completedContract?.backofficeEmailSent, options)}
          </dt>
          <dd>
            <span>
              {t("Contract was sent to onboarding team at")}{" "}
              <b>{completedContract?.backofficeRecipient ?? "..."}</b>
            </span>
          </dd>
          <dt className="fs-small">
            {completedContract?.merchantEmailSent && <Beacon validity={Validity.VALID} mini />}
            {getIntlDate(lang, completedContract?.merchantEmailSent, options)}
          </dt>
          <dd>
            <span>
              {t("Contract was sent to the merchant")}
              {completedContract?.merchantId ? (
                <>
                  {" "}
                  Merchant id: <b>{completedContract.merchantId}</b>
                </>
              ) : null}
              .
            </span>
          </dd>

          {/* <dt className="fs-small">
            {completedContract?.merchantEmailSent && <Beacon validity={Validity.VALID}/>}
            {getIntlDate(lang, completedContract?.backofficeEmailViewed, options)}
          </dt>
          <dd>{t("Onboarding team viewed contract")} </dd> */}
          <dt className="fs-small">
            {completedContract?.backofficeProvisioningCompleted && <Beacon validity={Validity.VALID} mini />}
            {getIntlDate(lang, completedContract?.backofficeProvisioningCompleted, options)}
          </dt>
          <dd>{t("Archiving and Onboarding completed for contract")}</dd>

          {contract?.contractPackage?.packageId === PackageId.SOFTPOS_BUNDLE ? (
            <>
              <dt className="fs-small">
                {completedContract?.backofficeProvisioningCompleted && (
                  <Beacon validity={Validity.VALID} mini />
                )}
                {getIntlDate(lang, completedContract?.softposProvisioningCompleted, options)}
              </dt>
              <dd>{t("The contract was dispatched for SoftPos setup")}</dd>
            </>
          ) : null}
        </dl>
      </ContentBox>

      <div className="completed-contract-second-row">
        <ContractDownloads contract={contract} />
        <CompletedContractActions
          resendMerchantEmail={resendMerchantEmail}
          resendOnboardingEmail={resendOnboardingEmail}
          merchantEmailStatus={merchantEmailStatus}
          onboardingEmailStatus={backofficeEmailStatus}
        />
        <ContentBox>
          <h5 className="m-bottom-20">Pass Information</h5>
          <dl className="m-bottom-40 pass-info-list">
            <dt className="text-passive">Merchandiser:</dt>
            <dd className="fw-500">{completedContract?.passMerchandiser}</dd>
            <dt className="text-passive">Direction:</dt>
            <dd className="fw-500">{completedContract?.passDirection}</dd>
          </dl>
        </ContentBox>
      </div>
    </div>
  );
};

export const CompletedContractPage = () => {
  const { id } = useParams<{ id: ContractId }>();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [contract, setContract] = useState<Contract>();

  const [missingPermission, setMissingPermission] = useState(false);

  const load = useCallback((id: ContractId) => {
    dataContracts
      .loadContract(id, true)
      .then((response) => {
        setContract(response);
        setStatus(Status.SUCCESS);
      })
      .catch((error: ServerError<unknown>) => {
        if (error.status === 403) {
          setMissingPermission(true);
        }
        setStatus(Status.ERROR);
      });
  }, []);

  useEffect(() => {
    load(id);
  }, [load, id]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(() => {
      load(id);
    }, 300);
  }, [load, id]);

  return (
    <Page title="Completed contract" pageType={PageType.SALES_CONTRACT}>
      {missingPermission ? (
        <PermissionError />
      ) : (
        <Retry retry={retry} status={status}>
          <CompletedContractInner contract={contract} id={id} />
        </Retry>
      )}
    </Page>
  );
};
