import { Country } from "../model/common/commonType";
import { data } from "./data";

const BASE_PATH = "/api/sales/location";

export interface LocationSuggestionResult {
  text: string;
}

export interface LocationLookupResult {
  streetNumber?: string;
  country?: string;
  municipality?: string;
  neighborhood?: string;
  postalCode?: string;
  region?: string;
  street?: string;
  subRegion?: string;
}

export const dataLocationLookup = {
  getSuggestions: (text: string, countries: Country[]) =>
    data.post<LocationSuggestionResult[]>(`${BASE_PATH}/suggestions`, {
      text,
      countries,
    }),
  getLocation: (text: string, countries: Country[]) =>
    data.post<LocationLookupResult>(`${BASE_PATH}/location`, {
      text,
      countries,
    }),
};
