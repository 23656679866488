import cx from "classnames";
import { useEffect, useState, VoidFunctionComponent } from "react";
import { Button } from "../../../components/interactions/Buttons/Button";
import { ContractId, UserId } from "../../../model/common/commonType";
import { Status } from "../../../data/types";
import { SalesUser, userAdministration } from "../../../data/UserAdministration";

import "./DelegateContractOverlay.scss";
import { NewOverlay } from "../../../components/overlay/NewOverlay";

interface Props {
  contractId?: ContractId;
  currentUser: UserId;
  onDelegate: () => void;
  onClose: () => void;
}

export const DelegateContractOverlay: VoidFunctionComponent<Props> = ({
  currentUser,
  contractId,
  onDelegate,
  onClose,
}) => {
  const [targetUserId, setTargetUserId] = useState<UserId>();
  const [users, setUsers] = useState<SalesUser[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!contractId) {
      return;
    }
    setLoading(true);
    setTargetUserId(undefined);
    userAdministration
      .loadAllSalesUsers()
      .then((salesUsers) => {
        const sortedUsers = salesUsers
          .filter((u) => u.id !== currentUser)
          .sort((a, b) => a.name.localeCompare(b.name));
        setUsers(sortedUsers);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [contractId, currentUser, setUsers]);

  return (
    <div className="delegate-contract-overlay light-theme">
      <NewOverlay open={contractId !== undefined} onClose={onClose}>
        <div className="sales-admin-list">
          {users.map((user) => {
            return (
              <div
                key={user.id}
                className={cx("sales-admin-row m-top-10", {
                  active: user.id === targetUserId,
                })}
              >
                <div
                  className="sales-name"
                  onClick={() => {
                    setTargetUserId(user.id);
                  }}
                >
                  <span>
                    <b>{user.name}</b>
                  </span>
                  <span className="sales-team">
                    <i>{user.teamName}</i>
                  </span>
                </div>
              </div>
            );
          })}
        </div>

        <Button
          className="m-top-20 delegate-confirm"
          onClick={() => {
            if (!targetUserId || !contractId) {
              return;
            }
            userAdministration.delegateContract(contractId, targetUserId).then(() => {
              onDelegate();
            });
          }}
          status={loading ? Status.DISABLED : targetUserId ? Status.DEFAULT : Status.DISABLED}
        >
          Delegate
        </Button>
      </NewOverlay>
    </div>
  );
};
