const CONTROL_VALUES = [6, 5, 7, 2, 3, 4, 5, 6, 7];
const ICO_LENGTH = 8;

export function validateNIP(nip: string) {
  if (!nip) {
    return false;
  }

  const nipWithoutDashes = nip.replace(/-/gu, ``);
  const reg = /^[0-9]{10}$/u;
  if (!reg.test(nipWithoutDashes)) {
    return false;
  }

  const dig = String(nipWithoutDashes).split(``);

  const sum = CONTROL_VALUES.map((controlValue, index) => {
    return controlValue * parseInt(dig[index] as string, 10);
  }).reduce((prev, current) => prev + current, 0);

  const control = sum % 11;
  const checkdigit = parseInt(dig[9] as string, 10);
  return control === checkdigit;
}

export function isPotentialMBO(mbo: string) {
  if (!mbo) {
    return false;
  }
  const mboWithoutDashes = mbo.replace(/-/gu, ``);
  const reg = /^[0-9]{8}$/u;
  return reg.test(mboWithoutDashes);
}

export function isPotentialOIB(oib: string) {
  if (!oib) {
    return false;
  }
  const oibWithoutDashes = oib.replace(/-/gu, ``);
  const reg = /^[0-9]{11}$/u;
  return reg.test(oibWithoutDashes);
}

export function validateMBO(mbo: string) {
  if (!mbo) {
    return false;
  }

  const mboWithoutDashes = mbo.replace(/-/gu, ``);
  const reg = /^[0-9]{8}$/u;
  if (!reg.test(mboWithoutDashes)) {
    return false;
  }

  let multiplier = 8;

  const dig = String(mboWithoutDashes).split(``);

  const sum = dig
    //Map to numeric value
    .map((value) => parseInt(value as string, 10))
    //Multiply each value and sum, reducing multipler after each operation
    .reduce((prev, value, index) => {
      //Skip checkdigit
      if (index === 7) {
        return prev;
      }
      const v = value * multiplier;
      multiplier--;
      return prev + v;
    }, 0);

  const control = (11 - (sum % 11)) % 10;
  const checkdigit = parseInt(dig[7] as string, 10);
  return control === checkdigit;
}

export function validateOIB(oib: string) {
  if (!oib) {
    return false;
  }

  const oibWithoutDashes = oib.replace(/-/gu, ``);
  const reg = /^[0-9]{11}$/u;
  if (!reg.test(oibWithoutDashes)) {
    return false;
  }

  let sum = 10;
  const dig = String(oibWithoutDashes).split(``);

  dig
    .map((value) => parseInt(value as string, 10))
    //This one is weird
    .forEach((value, index) => {
      //Skip the checkdigit
      if (index === 10) {
        return;
      }
      sum = sum + value;
      sum = sum % 10;
      if (sum === 0) {
        sum = 10;
      }
      sum *= 2;
      sum = sum % 11;
    });

  const checksum = (11 - sum) % 10;
  const checkdigit = parseInt(dig[10] as string, 10);
  return checksum === checkdigit;
}

export function validateICO(ico: string) {
  const length = ico.length;
  if (length !== ICO_LENGTH) {
    return false;
  }

  let sum = 0;
  let multiplier = 8;

  for (let i = 0; i < 7; i++) {
    let value = Number(ico[i]);
    sum += value * multiplier;
    multiplier--;
  }

  const checksum = (11 - (sum % 11)) % 10;
  const checkdigit = Number(ico[7]);
  return checksum === checkdigit;
}
