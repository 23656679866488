import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Language, getCountryByLanguage } from "../../i18n";
import { Flag } from "../flags/Flag";
import "./LangSelector.scss";

interface Props {
  className?: string;
}

export const LangSelector: React.FunctionComponent<Props> = ({ className }) => {
  const { i18n } = useTranslation();
  const { search } = useLocation();
  const allowFrance = search.indexOf("lang=fr") > -1;

  return (
    <ul className={cx("lang-selector", className)}>
      {Object.values(Language).map((lang) => {
        if (lang === Language.FRANCE && !allowFrance) {
          return null;
        }

        return (
          <li key={lang} className={cx({ selected: lang === i18n.language })}>
            <button
              onClick={() => {
                i18n.changeLanguage(lang);
              }}
            >
              <Flag lang={getCountryByLanguage(lang)} />
            </button>
          </li>
        );
      })}
    </ul>
  );
};
