import { useState, useCallback, useMemo, useRef } from "react";
import { LinkAnchors } from "../ContractEdit";
import { ContractEditError } from "../ContractEditError";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Status } from "../../../data/types";
import { contractStatusState } from "../../../state/contractStatusState";
import { ContractSaveError, getEditStatus, handleError } from "../ContractPage";
import { Button } from "../../../components/interactions/Buttons/Button";
import { dataContracts } from "../../../data/dataContracts";
import { AssociateId, Cas } from "../../../model/common/commonType";
import { contractAssociateState, sortedAssociatesSelector } from "../../../state/contractAssociateState";
import { Associate, AssociateRole } from "../../../model/associate/associateTypes";
import { ONGOING_RESPONSE } from "../../../data/queues/QueueTypes";
import { contractErrorState, contractSaveState } from "../../../state/contractSaveState";
import { associateQueue, SaveType } from "../../../data/queues/AssociateQueue";
import { useTranslation } from "react-i18next";
import { HiddenInput } from "../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { FormName } from "../menus/ContractEditMenu";
import { Form } from "../../../components/form/Form";
import { SigneesOverlay } from "./SigneesOverlay";
import { getLatestValueByKey } from "../../../components/utils";
import { ScrollPositionAnchor } from "../../../components/scrollPosition/ScrollPositionAnchor";
import { SectionFieldSet } from "../../../components/sectionFieldSet/SectionFieldSet";

function getNewSignee(associates: Associate[]) {
  return getLatestValueByKey("created", associates);
}

const DUMMY_ASSOCIATE_ID = "aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee" as AssociateId;

export function Signees() {
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const contractStatus = useRecoilValue(contractStatusState);
  const setAssociates = useSetRecoilState(contractAssociateState);
  const associates = useRecoilValue(sortedAssociatesSelector);
  const [error, setError] = useState<ContractSaveError | null>(null);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);
  const [activeAssociate, setActiveAssociate] = useState<Associate | null>(null);
  const ref = useRef<HTMLFormElement>(null);

  const onClose = useCallback(() => {
    setError(null);
    setActiveAssociate(null);
  }, []);

  // Close overlay if associate was deleted
  // useEffect(() => {
  //   if (
  //     !associates.find(
  //       (person) => person.associateId === activeAssociate?.associateId
  //     )
  //   ) {
  //     onClose();
  //   }
  // }, [associates, activeAssociate, onClose]);

  const buttonStatus = useMemo(() => {
    return getEditStatus(contractStatus.edit, status);
  }, [contractStatus.edit, status]);

  const inputStatus = useMemo(() => {
    return getEditStatus(contractStatus.edit, Status.DEFAULT);
  }, [contractStatus.edit]);

  const saveCallback = useCallback(
    (err, response, wasAdded?) => {
      if (err === ONGOING_RESPONSE) {
        return;
      }

      setStatus(Status.DEFAULT);

      if (err) {
        handleError(err, setError);
        setDataError((dataErrors) =>
          dataErrors.concat({
            date: new Date(),
          })
        );
        return;
      }

      setAssociates(response);

      if (wasAdded) {
        const newSignee = getNewSignee(response);
        setActiveAssociate(newSignee);
      }

      setDataSaved((dataSaved) =>
        dataSaved.concat({
          date: new Date(),
        })
      );
    },
    [setDataSaved, setDataError, setAssociates]
  );

  const onAdd = useCallback(() => {
    associateQueue.saveAssociate(
      contractStatus.contractId,
      {
        associateId: DUMMY_ASSOCIATE_ID,
        cas: 0 as Cas,
        roles: [AssociateRole.SIGNATORY],
      },
      SaveType.ASSOCIATE,
      (err, response) => {
        saveCallback(err, response, true);
      }
    );
  }, [contractStatus.contractId, saveCallback]);

  const retry = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(onAdd, 500);
  }, [onAdd]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataContracts.claimContract(contractStatus.contractId).then(onAdd).catch(onAdd); // We're lazy, execute save again, which will fail,
      // and propagate error to Overlay
    }, 500);
  }, [contractStatus.contractId, onAdd]);

  const onToggle = useCallback(
    (toggled, toggledAssociate: Associate) => {
      const copy = {
        ...toggledAssociate,
        roles: toggled
          ? [...toggledAssociate.roles, AssociateRole.SIGNATORY]
          : toggledAssociate.roles.filter((role) => role !== AssociateRole.SIGNATORY),
      };

      associateQueue.saveAssociate(contractStatus.contractId, copy, SaveType.ASSOCIATE, saveCallback);
    },
    [contractStatus.contractId, saveCallback]
  );

  return (
    <div className="signees">
      <ScrollPositionAnchor id={LinkAnchors.SIGNATORIES.anchor} />

      <SectionFieldSet headerTitle={t(LinkAnchors.SIGNATORIES.name)} formName={FormName.SIGNEE}>
        <ContractEditError
          error={error}
          setError={setError}
          retry={retry}
          onClose={onClose}
          reclaimAndSave={reclaimAndSave}
        />

        {!!associates.length && <h5 className="m-bottom-20">{t("Select representatives")}</h5>}

        {associates.map((associate) => (
          <SigneesOverlay
            associate={associate}
            onToggle={onToggle}
            status={inputStatus}
            key={associate.associateId}
            initialOpen={associate.associateId === activeAssociate?.associateId}
            scrollToRef={ref}
          />
        ))}
        <Form name={FormName.SIGNEE} ref={ref}>
          <HiddenInput
            label={t("Signees")}
            value={associates.find((associate) => associate.roles.indexOf(AssociateRole.SIGNATORY) > -1)}
            validators={[new RequiredValidator(t("Please select at least one representative"))]}
            scrollToRef={ref}
          />
        </Form>

        <Button onClick={onAdd} block status={buttonStatus} className="m-top-30" variant="text">
          {t("Add legal representative")}
        </Button>
      </SectionFieldSet>
    </div>
  );
}
