import React from "react";
import cx from "classnames";
import { MISSING_DATE } from "../../../../components/utils";
import { useTranslation } from "react-i18next";

interface Props {
  sentToOwnersForSignature: string;
  hasMultipleAssociates: boolean;
}

export const EventMerchantConfirmed: React.FunctionComponent<Props> = ({
  sentToOwnersForSignature,
  hasMultipleAssociates,
}) => {
  const { t } = useTranslation();

  const active = sentToOwnersForSignature !== MISSING_DATE;

  return (
    <li
      className={cx({
        active: active,
      })}
    >
      <div className="event">
        <div className="timeline-date">{sentToOwnersForSignature}</div>

        <b>{active ? t("Confirmed by merchant") : t("Waiting for merchant to confirm")}</b>
        <div className="additional-event-info fs-small">
          {(active && hasMultipleAssociates) ?? t("Invites sent to all assocaites")}
        </div>
      </div>
    </li>
  );
};
