import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  contractSaveState,
  contractErrorState,
} from "../../state/contractSaveState";
import "./Notifier.scss";

export function Notifier() {
  const [dataSaved, setDataSaved] = useRecoilState(contractSaveState);
  const dataError = useRecoilValue(contractErrorState);
  const [internalSaveTag, setInternalSaveTag] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    if (dataError.length) {
      setInternalSaveTag("");
    }
  }, [dataError]);

  useEffect(() => {
    if (dataSaved.length) {
      const item = dataSaved[dataSaved.length - 1];
      const time = DateTime.fromJSDate(item.date).toFormat("HH:mm");
      setInternalSaveTag(`${t("Saved at")} ${time}`);
      setDataSaved([]);
    }
  }, [dataSaved, setDataSaved, t]);

  // const onClick = useCallback(
  //   (data: any) => {
  //     const copy = dataError.filter((item, idx) => data.index !== idx);
  //     setDataError(copy);
  //     setInternalSaveTag("");
  //   },
  //   [setDataError, dataError]
  // );

  // const errors = dataError.map((error, idx) => (
  //   <div key={error.date.getTime()} className="notifier-error">
  //     <i>{DateTime.fromJSDate(error.date).toFormat("HH:mm")}</i> - <b>Noo!</b>{" "}
  //     Something went wrong. Perhaps a shaky network? Try action again or reload
  //     page?
  //     <Button block onClick={onClick} data={{ index: idx }}>
  //       Got it
  //     </Button>
  //   </div>
  // ));

  const saveTag = internalSaveTag ? (
    <div className="notifier-saved">
      <i>{internalSaveTag}</i>
    </div>
  ) : null;

  return (
    <div className="notifier">
      {saveTag}
      {/* {errors} */}
    </div>
  );
}
