import React from "react";
import cx from "classnames";
import { ReactNode } from "react";
import { motion } from "framer-motion";
import "./Collapsible.scss";

interface Props {
  children: ReactNode;
  className?: string;
  collapsed: boolean;
  collapsedHeight?: number;
  variant?: "dark" | "light";
}

export const Collapsible: React.FunctionComponent<Props> = ({
  children,
  className,
  collapsed,
  collapsedHeight = 150,
  variant = "dark",
}) => {
  return (
    <motion.div
      className={cx("collapsible", className, {
        "is-collapsed": collapsed,
        "is-dark": variant === "dark",
        "is-light": variant === "light",
      })}
      animate={{ height: collapsed ? collapsedHeight : "auto" }}
      transition={{ type: "spring", duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};
