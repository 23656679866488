import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import cx from "classnames";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Form } from "../../../../../components/form/Form";
import { hasRealErrors } from "../../../../../components/form/FormHelpers";
import { Link } from "../../../../../components/links/Link";
import { Country } from "../../../../../model/common/commonType";
import { PricingTemplate } from "../../../../../model/pricing/pricingTypes";
import {
  convertEditableTemplateToSaveObject,
  convertPricingTemplateToEditable,
} from "../../../../../model/pricing/pricingUtils";
import { Status } from "../../../../../data/types";
import { Language } from "../../../../../i18n";
import {
  INITIAL_EDITABLE_PRICING_TEMPLATE,
  availablePricingTemplatesState,
} from "../../../../../state/contractCostState";
import { AdminPage } from "../../../AdminPage";
import { ADMIN_PRICING_PAGE } from "../../AdminPricingPage/AdminPricingPage";
import { PresetsLoader } from "../components/PresSetsLoader/PreSetsLoader";
import { PricingInputs } from "./PricingInputs/PricingInputs";
import { cache } from "../../../../../network/Cache";
import { dataPricing } from "../../../../../data/dataPricing";
import { AdminPageActions } from "./PageActions/AdminPageActions";
import { AdminPreSetPageStatusMessages } from "./StatusMessages/AdminPreSetPageStatusMessages";
import "./AdminPreSetPage.scss";

export const ADMIN_PRICING_PRESET_PAGE = "/admin/pricing/:country/:preSetId";

export interface UrlParams {
  preSetId: string;
  country: Country;
}

export function EditAdminPresetPage() {
  const { preSetId, country } = useParams<UrlParams>();
  const allTemplates = useRecoilValue(availablePricingTemplatesState);
  const setPricingTemplateState = useSetRecoilState(availablePricingTemplatesState);
  const [editablePricing, setEditablePricing] = useState(INITIAL_EDITABLE_PRICING_TEMPLATE);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);

  const statusRef = useRef<HTMLDivElement>(null);
  const currentTemplate = useMemo(
    () => allTemplates.find((item) => item.templateId.toString() === preSetId),
    [preSetId, allTemplates]
  );

  useEffect(() => {
    if (!currentTemplate) return;

    const editableTemplate = convertPricingTemplateToEditable(currentTemplate, country);

    setEditablePricing(editableTemplate);
  }, [currentTemplate, setEditablePricing, country]);

  useEffect(() => {
    return () => cache.clear();
  }, []);

  const onSave = useCallback(async () => {
    try {
      // function can throw error
      const saveTemplate: PricingTemplate = convertEditableTemplateToSaveObject(editablePricing);

      const pricingTemplates = await dataPricing.savePricingTemplate(country, saveTemplate);
      setPricingTemplateState(pricingTemplates);
      setStatus(Status.SUCCESS);

      // TODO: fix this
      if (statusRef.current) {
        statusRef.current.scrollIntoView();
      }
    } catch (error) {
      console.log("error saving price template:", error);
      setStatus(Status.ERROR);
      setTimeout(() => setStatus(Status.DEFAULT), 4000);
    }
  }, [editablePricing, country, setPricingTemplateState]);

  // const buttonIsDisabled = status === Status.SUCCESS || groupedFees.length > 6;

  if (!currentTemplate) {
    return <PresetNotFound country={country} />;
  }

  return (
    <AdminPage title="Pricing preset page" className={cx("m-bottom-40", editablePricing.pricingModel)}>
      <Form
        onSubmit={(event, form) => {
          if (form.isInvalid || hasRealErrors(form)) return;
          onSave();
        }}
      >
        <h5 className="text-passive">Update price plan: </h5>
        <h1>
          <strong>{currentTemplate.name}</strong>
        </h1>

        <div className="m-y-40">
          <PresetsLoader country={country}>
            <PricingInputs
              editablePricing={editablePricing}
              setEditablePricing={setEditablePricing}
              isUpdate={true}
              lang={Language.UK}
              country={country}
              disabled={status === Status.SUCCESS}
            />
          </PresetsLoader>
          <div className="m-y-30">
            <AdminPreSetPageStatusMessages status={status} country={country} />
          </div>
          <div ref={statusRef}>
            <AdminPageActions status={status} buttonIsDisabled={false} country={country} />
          </div>
        </div>
      </Form>
    </AdminPage>
  );
}

const PresetNotFound = ({ country }: { country: Country }) => {
  return (
    <AdminPage title="Pricing preset page" className="admin-pricing-preset-page">
      <section>
        <article>
          <h1>Update price plan</h1>
          <PresetsLoader country={country}>
            <p>We didn't find this specific pricing price plan. Perhaps the link is incorrect?</p>
            <Link link={ADMIN_PRICING_PAGE}>Back to pricing overview</Link>
          </PresetsLoader>
        </article>
      </section>
    </AdminPage>
  );
};
