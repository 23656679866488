import cx from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextBlock } from "../../data/dataConfirm";
import "./ZoomableImage.scss";

export interface Props {
  image?: string;
  alt: string;
  showTextBlocks?: boolean;
  textBlocks?: TextBlock[];
}

function updateBackgroundPosition(
  e: React.MouseEvent<HTMLElement, MouseEvent>
) {
  var zoomer = e.currentTarget;
  const offsetX = e.nativeEvent.offsetX
    ? e.nativeEvent.offsetX
    : e.nativeEvent.pageX;
  const offsetY = e.nativeEvent.offsetY
    ? e.nativeEvent.offsetY
    : e.nativeEvent.pageY;
  const x = (offsetX / zoomer.offsetWidth) * 100;
  const y = (offsetY / zoomer.offsetHeight) * 100;
  zoomer.style.backgroundPosition = x + "% " + y + "%";
}

export const ZoomableImage: React.FunctionComponent<Props> = ({
  image,
  alt,
  showTextBlocks = false,
  textBlocks,
}) => {
  const { t } = useTranslation();
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    setSticky(false);
  }, [setSticky]);

  if (!image) {
    return <span>{t("No image found")}</span>;
  }
  return (
    <div className="zoomage__image__container">
      <div className="zoomable">
        <figure
          className={cx({
            sticky: sticky,
          })}
          style={{
            backgroundImage: `url(${image})`,
          }}
          onMouseMove={(e) => {
            if (sticky) {
              return;
            }
            updateBackgroundPosition(e);
          }}
          onClick={() => {
            setSticky(!sticky);
          }}
        >
          <img src={image} alt={alt} />
          <span className="small help-text">{t("Hover image to zoom")}</span>
          <span className="small click-to-freeze">
            {sticky ? t("Click to unfreeze image") : t("Click to freeze image")}
          </span>
          <div className="text-blocks">
            {showTextBlocks &&
              textBlocks?.map((block) => {
                return (
                  <div
                    key={block.top + block.left}
                    className="detected-text"
                    style={{
                      top: `${block.top * 100}%`,
                      left: `${block.left * 100}%`,
                      height: `${block.height * 100}%`,
                      width: `${block.width * 100}%`,
                    }}
                  ></div>
                );
              })}
          </div>
        </figure>
      </div>
    </div>
  );
};
