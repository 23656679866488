import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { FieldSet } from "../../../components/fieldSet/FieldSet";
import { Select } from "../../../components/form/Select";
import { Dropdown } from "../../../components/interactions/Dropdown/Dropdown";
import { Input } from "../../../components/interactions/Input/Input";
import { Table } from "../../../components/table/Table";
import { dataTranslation, TranslationId, TranslationText } from "../../../data/dataTranslation";
import { Status } from "../../../data/types";
import { defaultLanguageFromCountry, Language } from "../../../i18n";
import { userState } from "../../../state/userState";
import { AdminPage } from "../AdminPage";
import { MemoMissingTranslationsRow } from "./MissingTranslationRow";
import { SuggestTranslationOverlay } from "./SuggestTranslationOverlay";
import "./SuggestTranslationOverlay.scss";

export const TRANSLATIONS_PAGE = "/admin/translations";

enum ROW_FILTERS {
  ALL = "ALL",
  MISSING = "MISSING",
  TRANSLATED = "TRANSLATED",
}

enum NAMESPACE_FILTERS {
  ALL = "",
  MERCHANT = "merchant",
  SALES = "sales",
  MCC = "mcc",
}

export const NAMESPACE_ALTERNATIVES = [
  {
    value: NAMESPACE_FILTERS.ALL,
    text: "All",
  },
  {
    value: NAMESPACE_FILTERS.MERCHANT,
    text: "WLX Merchant",
  },
  {
    value: NAMESPACE_FILTERS.SALES,
    text: "WLX Sales",
  },
  {
    value: NAMESPACE_FILTERS.MCC,
    text: "Shared (MCC)",
  },
];

export const PRESENT_ALTERNATIVES = [
  {
    value: ROW_FILTERS.ALL,
    text: "All",
  },
  {
    value: ROW_FILTERS.MISSING,
    text: "Missing Translations",
  },
  {
    value: ROW_FILTERS.TRANSLATED,
    text: "Has Translation",
  },
];

export const ALTERNATIVES = [
  {
    value: Language.CROATIA,
    text: "Croatian",
  },
  {
    value: Language.POLAND,
    text: "Polish",
  },
  {
    value: Language.FRANCE,
    text: "French",
  },
  {
    value: Language.CZECH,
    text: "Czech",
  },
];

export const MissingTranslationsPage = () => {
  const { i18n } = useTranslation();
  const [translationTexts, setTranslationTexts] = useState<TranslationText[]>([]);
  const [activeTranslation, setActiveTranslation] = useState<TranslationText>();

  let lang;
  try {
    const urlSearchParams = new URLSearchParams(window.location.search);
    ({ lang } = Object.fromEntries(urlSearchParams.entries()));
  } catch (err) {}

  const user = useRecoilValue(userState);
  const isSuperAdmin = user?.adminUser?.superAdmin;

  const [filterCountry, setFilterCountry] = useState<Language>(
    Object.values(Language).includes(lang as Language)
      ? (lang as Language)
      : defaultLanguageFromCountry(user?.country)
  );

  const [filterText, setFilterText] = useState<string>();
  const [filterNamespace, setFilterNamespace] = useState<string>(NAMESPACE_FILTERS.ALL);
  const [translationFilter, setTranslationFilter] = useState(ROW_FILTERS.MISSING);

  const loadTranslations = useCallback(async () => {
    dataTranslation.loadTranslations(filterCountry).then(setTranslationTexts);
  }, [filterCountry]);

  const deleteTranslation = useCallback(
    (id: TranslationId) => {
      dataTranslation.deleteText(id).then(loadTranslations);
    },
    [loadTranslations]
  );

  useEffect(() => {
    dataTranslation.loadTranslations(filterCountry).then(setTranslationTexts);
  }, [setTranslationTexts, filterCountry]);

  const filteredTexts = useMemo(
    () =>
      translationTexts
        .filter((row) => {
          if (!filterNamespace) {
            return true;
          }
          return row.namespace === filterNamespace;
        })
        .filter((row) => {
          if (translationFilter === ROW_FILTERS.MISSING) {
            if (!row.value) {
              return true;
            }
            return false;
          }

          if (translationFilter === ROW_FILTERS.TRANSLATED) {
            return row.value;
          }
          return true;
        })
        .filter((row) => {
          if (!filterText) {
            return true;
          }
          const lowerCaseSearch = filterText.toLowerCase();
          const keyLowerCase = row.key.toLowerCase();
          if (keyLowerCase.includes(lowerCaseSearch)) {
            return true;
          }
          if (row.value) {
            const translationLowerCase = row.value.toLowerCase();
            if (translationLowerCase.includes(lowerCaseSearch)) {
              return true;
            }
          }
          return false;
        }),
    [filterNamespace, filterText, translationFilter, translationTexts]
  );

  const handleOnEdit = useCallback(
    (translation: TranslationText) => {
      setActiveTranslation(translation);
    },
    [setActiveTranslation]
  );

  const handleOnNextEdit = useCallback(
    async (translation: TranslationText) => {
      await loadTranslations();
      await new Promise((resolve) => setTimeout(resolve, 250));

      const index = filteredTexts.findIndex((text) => text.id === translation.id);
      if (index === -1) {
        setActiveTranslation(undefined);
        return;
      }
      const nextIndex = index + 1;
      if (nextIndex >= filteredTexts.length) {
        setActiveTranslation(undefined);
        return;
      }
      setActiveTranslation(filteredTexts[nextIndex]);
    },
    [loadTranslations, filteredTexts]
  );

  const handleOnEditClose = useCallback(
    async (updated: boolean) => {
      if (updated) {
        await loadTranslations();
      }
      await new Promise((resolve) => setTimeout(resolve, 250));
      setActiveTranslation(undefined);
    },
    [loadTranslations]
  );

  return (
    <AdminPage title="Translations" className="translations-page">
      <section>
        <SuggestTranslationOverlay
          translationText={activeTranslation}
          onClose={handleOnEditClose}
          onNext={handleOnNextEdit}
        />
        <FieldSet header="Translations">
          <div className="columns">
            <div className="column m-bottom-10">
              <Dropdown
                onChange={(value) => {
                  setFilterCountry(value as Language);
                  loadTranslations();
                }}
                label="Language"
                name="language"
                alternatives={ALTERNATIVES}
                value={filterCountry}
                status={isSuperAdmin ? Status.DEFAULT : Status.DISABLED}
              />
            </div>
            <div className="column m-bottom-10">
              <Select
                onChange={(value) => {
                  setFilterNamespace(value);
                }}
                label="Sites"
                alternatives={NAMESPACE_ALTERNATIVES}
                value={filterNamespace}
              />
            </div>
            <div className="column m-bottom-10">
              <Dropdown
                className="filter-dropdown"
                onChange={(value) => {
                  setTranslationFilter(value);
                }}
                label="Filter"
                name="translationFilter"
                alternatives={PRESENT_ALTERNATIVES}
                value={translationFilter}
              />
            </div>
            <div className="column m-bottom-10">
              <Input
                label="Search"
                value={filterText}
                onChange={(value: string) => {
                  setFilterText(value);
                }}
                name={"Search"}
              />
            </div>
          </div>
          <div className="translations-table-wrapper">
            <Table className="translations-table fs-small">
              <thead>
                <tr>
                  <th className="translations-header-site">Site</th>
                  <th className="translations-header-added">Added</th>
                  <th className="translations-header-original">Original</th>
                  <th className="translations-header-translation">Translation</th>
                  <th className="translations-header-translated">Translated</th>
                  <th className="translations-header-translated-by">Translated by</th>
                  <th className="translations-header-button"></th>
                  <th className="translations-header-button"></th>
                </tr>
              </thead>

              <tbody>
                {filteredTexts.map((row) => (
                  <MemoMissingTranslationsRow
                    key={row.id}
                    translationText={row}
                    deleteTranslation={deleteTranslation}
                    language={i18n.language}
                    onEdit={handleOnEdit}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        </FieldSet>
      </section>
    </AdminPage>
  );
};
