import React, { ReactNode } from "react";
import cx from "classnames";
import { Link as RouterLink } from "react-router-dom";
import { External } from "../icons/External";
import "./Link.scss";
import { DownloadIcon } from "../icons/DownloadIcon";

interface Props {
  link: string;
  download?: string;
  external?: boolean;
  children: ReactNode;
  className?: string;
  iconType?: "default" | "download";
}

export const Link: React.FunctionComponent<Props> = ({
  link,
  external,
  children,
  className,
  download,
  iconType = "default",
}) => {
  if (external) {
    return (
      <a
        className={cx("link", "external-link", "postfix", className)}
        href={link}
        target="_blank"
        rel="noreferrer"
        download={download}
      >
        {children}
        {iconType === "default" ? <External /> : <DownloadIcon />}
      </a>
    );
  }
  return (
    <RouterLink className={cx("link", className)} to={link}>
      {children}
    </RouterLink>
  );
};
