import { useState, useCallback, useMemo, useRef } from "react";
import { LinkAnchors } from "../ContractEdit";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { dataContracts } from "../../../data/dataContracts";
import { Status } from "../../../data/types";
import { contractMainContractDataState } from "../../../state/contractMainContractDataState";
import { contractStatusState } from "../../../state/contractStatusState";
import { ContractSaveError, getEditStatus, handleError } from "../ContractPage";
import { ContractEditError } from "../ContractEditError";
import { useTranslation } from "react-i18next";
import { Input } from "../../../components/interactions/Input/Input";
import { MCC } from "../../../components/mcc/MCC";
import { VAT } from "../../../components/vat/VAT";
import { contractErrorState, contractSaveState } from "../../../state/contractSaveState";
import { Address, ContractFeature, RegonStatus, VatStatus } from "../../../model/contract/contractType";
import { contractDocumentsState } from "../../../state/contractDocumentsState";
import { clearCas, getCountryOpts, getLanguageOpts } from "../../../components/utils";
import { Form, FormContainer } from "../../../components/form/Form";
import { TextInput } from "../../../components/form/TextInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { MinLengthValidator } from "../../../components/form/validators/MinLengthValidator";
import { MaxLengthValidator } from "../../../components/form/validators/MaxLengthValidator";
import { Select } from "../../../components/form/Select";
import { Language } from "../../../i18n";
import { hasRealErrors } from "../../../components/form/FormHelpers";
import { FormName } from "../menus/ContractEditMenu";
import { RadioGroup } from "../../../components/form/RadioGroup";
import { ScrollPositionAnchor } from "../../../components/scrollPosition/ScrollPositionAnchor";
import { Country } from "../../../model/common/commonType";
import { SectionFieldSet } from "../../../components/sectionFieldSet/SectionFieldSet";

import { COUNTRY_ID_TITLE } from "../../../components/NewOrganisationOverlay/NewContractOverlay";
import { AddressWithSearch } from "../../../components/address/AddressWithSearch.module";

const MCC_CODE_LENGTH = 4;

export function CompanyData() {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState<ContractSaveError | null>(null);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [mainData, setMainData] = useRecoilState(contractMainContractDataState);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);
  const contractStatus = useRecoilValue(contractStatusState);
  const setDocuments = useSetRecoilState(contractDocumentsState);
  const prevMcc = useRef<string>(mainData.mcc || "");
  const prevSaved = useRef<string>(JSON.stringify(clearCas(mainData)));
  const country = useMemo(() => contractStatus.country, [contractStatus]);

  const formContainer = useRef<FormContainer>();

  const onClose = useCallback(() => {
    setError(null);
  }, []);

  const loadDocumentsIfMCCUpdated = useCallback(() => {
    if (!mainData.mcc) {
      return;
    }

    if (mainData.mcc === prevMcc.current) {
      return;
    }

    if (mainData.mcc.length !== MCC_CODE_LENGTH) {
      return;
    }

    prevMcc.current = mainData.mcc;
    dataContracts
      .loadDocuments(contractStatus.contractId)
      .then((docs) => setDocuments(docs))
      .catch((err) => {
        // Just let it be. We won't list additional docs in this case
        console.error(err);
      });
  }, [mainData.mcc, prevMcc, contractStatus.contractId, setDocuments]);

  const onSave = useCallback(
    (updated) => {
      const stringCopy = JSON.stringify(clearCas(updated));
      if (prevSaved.current === stringCopy) {
        return;
      } else {
        prevSaved.current = stringCopy;
      }

      dataContracts
        .saveMainContractData(contractStatus.contractId, {
          ...updated,
        })
        .then((data) => {
          setStatus(Status.DEFAULT);
          setMainData({ ...updated, cas: data.cas });
          setDataSaved((dataSaved) =>
            dataSaved.concat({
              date: new Date(),
            })
          );
          loadDocumentsIfMCCUpdated();
        })
        .catch((err) => {
          prevSaved.current = "";
          setStatus(Status.DEFAULT);
          handleError(err, setError);
          setDataError((dataErrors) =>
            dataErrors.concat({
              date: new Date(),
            })
          );
        });
    },
    [contractStatus.contractId, setMainData, setDataError, setDataSaved, loadDocumentsIfMCCUpdated]
  );

  const retry = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(onSave, 500);
  }, [onSave]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataContracts.claimContract(contractStatus.contractId).then(onSave).catch(onSave); // We're lazy, execute save again, which will fail,
      // and propagate error to Overlay
    }, 500);
  }, [contractStatus.contractId, onSave]);

  const inputStatus = useMemo(() => {
    return getEditStatus(contractStatus.edit, status);
  }, [contractStatus.edit, status]);

  const originOpts = useMemo(() => {
    const opts = getCountryOpts(i18n.language as Language, t, true);
    return opts.filter((opt) => opt.value !== contractStatus.country);
  }, [t, i18n.language, contractStatus.country]);

  const languageOpts = useMemo(() => {
    return getLanguageOpts(i18n.language as Language, contractStatus.country, t);
  }, [t, i18n.language, contractStatus.country]);

  const active = useMemo(() => inputStatus !== Status.DISABLED, [inputStatus]);

  const onAddressChange = useCallback(
    (address: Address) => {
      setMainData((data) => ({
        ...data,
        primaryAddress: address,
      }));

      if (formContainer.current) {
        const realErrors = hasRealErrors(formContainer.current);
        if (!realErrors) {
          onSave({ ...mainData, primaryAddress: address });
        }
      }
    },
    [setMainData, onSave, mainData]
  );

  return (
    <Form
      formContainer={formContainer}
      onSaveTrigger={(event, form) => {
        const realErrors = hasRealErrors(form);
        if (!realErrors) {
          onSave(mainData);
        }
      }}
      name={FormName.MAIN_DATA}
    >
      <ScrollPositionAnchor id={LinkAnchors.COMPANY_DATA.anchor} />
      <ContractEditError
        error={error}
        setError={setError}
        retry={retry}
        onClose={onClose}
        reclaimAndSave={reclaimAndSave}
      />
      <SectionFieldSet headerTitle={t(LinkAnchors.COMPANY_DATA.name)} formName={FormName.MAIN_DATA}>
        <div className={"double-auto-columns"}>
          <div>
            <div className="fw-600">{t("Acceptance agreement")}</div>
            <p className="m-y-10">
              {t("In the past a card acceptance agreement of the Merchant was terminated prematurely")}
            </p>
          </div>

          <RadioGroup
            label={t("Acceptance")}
            name="acceptance"
            onChange={(value) => {
              const copy = { ...mainData, acceptance: value === "true" };
              setMainData(copy);
              onSave(copy);
            }}
            value={mainData.acceptance ? "true" : "false"}
            disabled={!active}
            alternatives={[
              {
                text: t("No"),
                value: "false",
              },
              {
                text: t("Yes"),
                value: "true",
              },
            ]}
          />

          <div>
            <TextInput
              onChange={(value) => setMainData({ ...mainData, companyName: value })}
              label={t("Legal name")}
              value={mainData.companyName}
              name="companyName"
              validators={[
                new RequiredValidator(t("Legal name is required")),
                new MinLengthValidator(
                  2,
                  t("Legal name must be at least {{min}} characters", {
                    min: 2,
                  })
                ),
                new MaxLengthValidator(
                  60,
                  t("Legal name cannot be longer than {{min}} characters", {
                    min: 60,
                  })
                ),
              ]}
              disabled={!active}
            />
          </div>

          {/* We don't care about Company registration id (KRS / Regon) in Poland, so to not cause confusion we hide it.
            VAT-ID (NIP) is used instead and is the information we pass downwards in the end */}

          {/* TODO: Perhaps Regon will also be relevant for other countries?*/}
          {country === Country.POLAND ? (
            <TextInput
              onChange={(value) => setMainData({ ...mainData, regonNumber: value })}
              disabled={mainData.regonResult === RegonStatus.FOUND ? true : !active}
              value={mainData.regonNumber}
              label={t("REGON")}
              message={
                mainData.regonResult === RegonStatus.FOUND
                  ? t("Regon found from registry")
                  : t("Regon not found, added manually")
              }
              name="regonNumber"
            />
          ) : (
            <Input
              onChange={() => {}}
              status={Status.DISABLED}
              value={mainData.companyRegistrationId}
              label={`${t("Registry no.")} (${COUNTRY_ID_TITLE[country]})`}
              message={t("Can not be changed")}
              name="companyRegistrationId"
            />
          )}

          <VAT
            onChange={(value) => setMainData({ ...mainData, vatNumber: value })}
            status={VatStatus.VALID === mainData.vatResult ? Status.DISABLED : inputStatus}
            vatResult={mainData.vatResult}
            value={mainData.vatNumber}
            contractId={contractStatus.contractId}
          />

          <div>
            <MCC
              onChange={(value) => setMainData({ ...mainData, mcc: value })}
              status={inputStatus}
              selectedMccCode={mainData.mcc || ""}
              name="mcc"
              active={active}
              country={contractStatus.country}
              contractType={mainData.contractType}
            />
          </div>
        </div>

        <div className="m-y-30">
          <AddressWithSearch
            label="Business address"
            address={mainData.primaryAddress}
            onChange={onAddressChange}
            addressRequiredFields={{
              street: true,
              city: true,
              postalCode: true,
              countryCode: true,
              streetNumber: true,
            }}
            disabled={!active}
            country={country}
          />
        </div>

        <div className={"double-auto-columns"}>
          {!contractStatus.enabledFeatures.includes(ContractFeature.ADVANCED_PRICING) && (
            <TextInput
              onChange={(value) => setMainData({ ...mainData, cardholderStatementText: value })}
              label={t("Short address")}
              hint={t("For cardholder statement")}
              placeholder={t("Max 21 characters")}
              value={mainData.cardholderStatementText}
              name="cardholderStatementText"
              validators={[
                new RequiredValidator(t("Short adress is required")),
                new MaxLengthValidator(
                  21,
                  t("Short adress cannot be longer than {{min}} characters", {
                    min: 21,
                  })
                ),
              ]}
              disabled={!active}
            />
          )}

          <div>
            <TextInput
              onChange={(value) => setMainData({ ...mainData, website: value })}
              label={t("Company website")}
              placeholder={t("www.company.com")}
              value={mainData.website}
              validators={[
                new MaxLengthValidator(
                  50,
                  t("Company website cannot be longer than {{min}} characters", {
                    min: 50,
                  })
                ),
              ]}
              disabled={!active}
            />
          </div>

          <div>
            <Select
              onChange={(value) => {
                const copy = { ...mainData, language: value as Language };
                setMainData(copy);
                onSave(copy);
              }}
              disabled={!active}
              label={t("Communication language")}
              alternatives={languageOpts}
              value={mainData.language}
            />
          </div>

          <div>
            <Select
              onChange={(value) => {
                const copy = { ...mainData, countryOfOrigin: value };
                setMainData(copy);
                onSave(copy);
              }}
              disabled={!active}
              alternatives={originOpts}
              value={mainData.countryOfOrigin}
              label={t("Country of origin")}
              hint={t(
                "Mandatory field for state-controlled Merchants (embassies, consulates, chambers of commerce, etc.) whose business location differs from the country of origin."
              )}
            />
          </div>
        </div>
      </SectionFieldSet>
    </Form>
  );
}
