import React, { useEffect, useState } from "react";
import { generatePath } from "react-router";
import { NavLink } from "react-router-dom";
import {
  EmailsResponse,
  TextTemplateAndTranslationsResponse,
  dataCommunication,
} from "../../../../data/dataCommunication";
import { AdminPage } from "../../AdminPage";
import { EMAIL_PREVIEW_URL } from "../emailPreview/EmailPreview";
import { TEXT_PREVIEW_URL } from "../textPreview/TextPreview";

export const COMMUNICATION_LIST_URL = "/admin/communication";

export const CommunicationList = () => {
  const [email, setEmail] = useState<EmailsResponse[]>();
  const [texts, setTexts] = useState<TextTemplateAndTranslationsResponse[]>();

  useEffect(() => {
    dataCommunication.getEmails().then(setEmail);
    dataCommunication.getTexts().then(setTexts);
  }, []);

  return (
    <AdminPage>
      <section>
        <article>
          <div>
            <h2>Customer communication</h2>
          </div>
          <h3>Email translations</h3>
          <div>
            <ul>
              {email?.map((email) => (
                <li key={email.templateId}>
                  <NavLink
                    to={generatePath(EMAIL_PREVIEW_URL, {
                      templateId: email.templateId,
                    })}
                  >
                    {email.templateName}
                  </NavLink>
                </li>
              ))}
            </ul>
            <h3>SMS translations</h3>
            <ul>
              {texts?.map((text) => (
                <li key={text.templateId}>
                  <NavLink
                    to={generatePath(TEXT_PREVIEW_URL, {
                      templateId: text.templateId,
                    })}
                  >
                    {text.templateName}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </article>
      </section>
    </AdminPage>
  );
};
