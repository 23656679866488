import { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { ContractStatus } from "../../../components/contractStatus/ContractStatus";
import { FieldSet } from "../../../components/fieldSet/FieldSet";
import { Form } from "../../../components/form/Form";
import { TextInput } from "../../../components/form/TextInput";
import { InvalidValuesValidator } from "../../../components/form/validators/InvalidValuesValidator";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Pending } from "../../../components/icons/Pending";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Toggle } from "../../../components/interactions/Toggle/Toggle";
import { Pagination } from "../../../components/pagination/Pagination";
import { Table } from "../../../components/table/Table";
import { FormattedDate } from "../../../components/time/FormattedDate";
import { dataPricing } from "../../../data/dataPricing";
import { Country, TeamId } from "../../../model/common/commonType";
import { ContractStatus as Type, PackageId } from "../../../model/contract/contractType";
import { Page } from "../../../model/Page";
import { PricingModel, PricingTemplate } from "../../../model/pricing/pricingTypes";
import { Status } from "../../../data/types";
import { CurrentContract, TeamRequest, userAdministration } from "../../../data/UserAdministration";
import { userState } from "../../../state/userState";
import { COMPLETED_CONTRACT_PAGE } from "../../CompletedContract/CompletedContractPage";
import { CONTRACT_ROUTE } from "../../Contract/ContractPage";
import { MERCHANT_ROUTE } from "../../MerchantStatus/MerchantStatusPage";
import { AdminPage } from "../AdminPage";
import { CONTRACT_AUDIT_PAGE } from "../Audit/AdminContractAuditLog";
import "./SalesTeam.scss";
import cx from "classnames";
import { T } from "../../../components/translation/T";

import { getPricingModelDisplayName } from "../../../model/pricing/pricingUtils";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import { AnimateHeightMotion } from "../../../components/animate/AnimateHeightMotion";

export const SALES_TEAM_EDIT_PAGE = "/admin/teams/:id";

type PageParams = {
  id: TeamId;
};

function resolveLinkByStatus(status: Type): "/contract/:id" | "/merchant/:id" | "/contract/completed/:id" {
  if (status === Type.PENDING_SALES_INPUT) {
    return CONTRACT_ROUTE;
  }
  if (status === Type.COMPLETE) {
    return COMPLETED_CONTRACT_PAGE;
  }
  return MERCHANT_ROUTE;
}

export const SalesTeamPage = () => {
  let { id } = useParams<PageParams>();
  const history = useHistory();
  const user = useRecoilValue(userState);
  const [saveButtonStatus, setSaveButtonStatus] = useState(Status.DEFAULT);
  const [loading, setLoading] = useState(false);
  const [teamRequest, setTeamRequest] = useState<TeamRequest>({
    name: "",
    external: false,
    enabledBundles: [PackageId.SOFTPOS_BUNDLE, PackageId.DESK_BUNDLE, PackageId.MOVE_BUNDLE],
    pricingPresets: [],
  });
  const [teamNames, setTeamNames] = useState<Set<string>>(new Set());
  const [contracts, setContracts] = useState<Page<CurrentContract>>();
  const [pricingPresets, setPricingPresets] = useState<PricingTemplate[]>([]);

  const [page, setPage] = useState(0);

  const blendedPresets = useMemo(
    () =>
      pricingPresets
        .filter((p) => p.pricingModel === PricingModel.BLENDED)
        .sort((p1, p2) => p2.priority - p1.priority),
    [pricingPresets]
  );

  const interchangePresets = useMemo(
    () =>
      pricingPresets
        .filter((p) => p.pricingModel === PricingModel.ICPP)
        .sort((p1, p2) => p2.priority - p1.priority),
    [pricingPresets]
  );

  const packPresets = useMemo(
    () =>
      pricingPresets
        .filter((p) => p.pricingModel === PricingModel.PACK)
        .sort((p1, p2) => p2.priority - p1.priority),
    [pricingPresets]
  );

  useEffect(() => {
    setLoading(true);
    const teamsPromise = userAdministration.loadSalesTeams().then((teams) => {
      const names = new Set(teams.filter((team) => team.id !== id).map(({ name }) => name));
      setTeamNames(names);
    });

    const teamPromise = userAdministration
      .loadTeam(id)
      .then((team) => {
        setTeamRequest({
          name: team.name,
          external: team.external,
          enabledBundles: team.enabledBundles,
          pricingPresets: team.pricingPresets.map((p) => p.id),
          merchandiser: team.merchandiser,
        });
        setSaveButtonStatus(Status.DEFAULT);
      })
      .catch((err) => {
        setSaveButtonStatus(Status.ERROR);
      });

    const country = user?.country || Country.POLAND;
    const pricingPromise = dataPricing.loadPricingTemplates(country).then(setPricingPresets);

    Promise.all([teamsPromise, teamPromise, pricingPromise]).finally(() => {
      setLoading(false);
    });
  }, [id, user, setTeamRequest, setLoading, setSaveButtonStatus, setTeamNames, setPricingPresets]);

  useEffect(() => {
    userAdministration
      .loadTeamContractsPage(id, page, 20)
      .then(setContracts)
      .catch(() => {});
  }, [id, page, setContracts]);

  const updateWithReset = useCallback(
    (status: Status) => {
      setSaveButtonStatus(status);
      setTimeout(() => {
        setSaveButtonStatus(Status.DEFAULT);
      }, 5000);
    },
    [setSaveButtonStatus]
  );

  const updateSelectedBundles = (selected: boolean, bundle: PackageId) => {
    const idx = teamRequest.enabledBundles.findIndex((v) => v === bundle);
    if (selected) {
      if (idx > -1) {
        //Already existed?
        return;
      }
      setTeamRequest({
        ...teamRequest,
        enabledBundles: [...teamRequest.enabledBundles, bundle],
      });
    } else {
      var bundles = [...teamRequest.enabledBundles];
      bundles.splice(idx, 1);
      if (bundles.length === 0) {
        bundles = [PackageId.SOFTPOS_BUNDLE, PackageId.DESK_BUNDLE, PackageId.MOVE_BUNDLE];
      }
      setTeamRequest({
        ...teamRequest,
        enabledBundles: bundles,
      });
    }
  };

  const onSave = () => {
    if (!id) {
      return;
    }

    setSaveButtonStatus(Status.PENDING);

    userAdministration
      .updateTeam(id, teamRequest)
      .then(() => updateWithReset(Status.SUCCESS))
      .catch(() => updateWithReset(Status.ERROR));
  };

  const pricingToggle = (price: PricingTemplate) => {
    const presets = [...teamRequest.pricingPresets];
    return (
      <div className="preset-toggle-container">
        <div className="preset-toggle">
          <Toggle
            key={price.name}
            className={cx("mini m-top-10", price.disabled && "text-passive")}
            label={price.name}
            selected={presets.includes(price.templateId)}
            onChange={(selected) => {
              if (selected) {
                presets.push(price.templateId);
              } else {
                presets.splice(presets.indexOf(price.templateId), 1);
              }
              setTeamRequest({
                ...teamRequest,
                pricingPresets: presets,
              });
            }}
          />
        </div>
        {price.disabled && (
          <div className="preset-toggle-archived fs-mini">
            <T>Archived</T>
          </div>
        )}
      </div>
    );
  };

  if (loading) {
    return (
      <AdminPage className={"light-theme edit-sales-user"}>
        <FieldSet header={<span>Sales Team</span>}>
          <div>
            Loading... <Pending />
          </div>
        </FieldSet>
      </AdminPage>
    );
  }

  return (
    <AdminPage className={"light-theme edit-sales-team"}>
      <section>
        <article>
          <Form
            onSubmit={(_, form) => {
              if (form.isInvalid) {
                return;
              }
              onSave();
            }}
          >
            <div className="sales-team-fieldset">
              <h3 className="m-bottom-20">Sales Team</h3>
              <div className="columns m-bottom-20">
                <div>
                  <TextInput
                    onChange={(value) => {
                      setTeamRequest({
                        ...teamRequest,
                        name: value,
                      });
                    }}
                    value={teamRequest.name}
                    name="name"
                    label="Name"
                    placeholder="Name of the team"
                    validators={[
                      new RequiredValidator("Name is required"),
                      new InvalidValuesValidator(teamNames, "Name is already used"),
                    ]}
                  />
                </div>
              </div>
              <div className="column m-bottom-40">
                <h5 className="m-bottom-20">Enabled bundles</h5>
                <div className="bundle-toggle">
                  <Toggle
                    className="mini"
                    selected={teamRequest.enabledBundles.includes(PackageId.SOFTPOS_BUNDLE)}
                    onChange={(selected) => updateSelectedBundles(selected, PackageId.SOFTPOS_BUNDLE)}
                  />
                  <span className="bundle-row">Worldline Tap on Mobile Bundle</span>
                </div>
                <div className="bundle-toggle">
                  <Toggle
                    className="mini"
                    selected={teamRequest.enabledBundles.includes(PackageId.DESK_BUNDLE)}
                    onChange={(selected) => updateSelectedBundles(selected, PackageId.DESK_BUNDLE)}
                  />
                  <span className="bundle-row">Desk Bundle</span>
                </div>
                <div className="bundle-toggle">
                  <Toggle
                    className="mini"
                    selected={teamRequest.enabledBundles.includes(PackageId.MOVE_BUNDLE)}
                    onChange={(selected) => updateSelectedBundles(selected, PackageId.MOVE_BUNDLE)}
                  />
                  <span className="bundle-row">Move Bundle</span>
                </div>
              </div>

              <div className="columns m-bottom-30">
                <div className="column">
                  <h5 className="m-bottom-20">External Partner</h5>
                  <div className="bundle-toggle">
                    <Toggle
                      className="mini"
                      selected={teamRequest.external}
                      onChange={(selected) => {
                        setTeamRequest({
                          ...teamRequest,
                          external: selected,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="column">
                  <TextInput
                    onChange={(value) => {
                      setTeamRequest({
                        ...teamRequest,
                        merchandiser: value,
                      });
                    }}
                    value={teamRequest.merchandiser}
                    name="merchandiser"
                    label="Merchandiser"
                  />
                </div>
              </div>
              <h5 className="m-bottom-20">Price plans</h5>
              <div className="preset-columns">
                <div className="preset-column">
                  <div className="preset-label m-bottom-10">
                    {getPricingModelDisplayName(PricingModel.BLENDED)}
                  </div>
                  {blendedPresets.length ? (
                    blendedPresets.map(pricingToggle)
                  ) : (
                    <div>No presets available</div>
                  )}
                </div>
                <div className="preset-column">
                  <div className="preset-label m-bottom-10">
                    {getPricingModelDisplayName(PricingModel.ICPP)}
                  </div>
                  {interchangePresets.length ? (
                    interchangePresets.map(pricingToggle)
                  ) : (
                    <div>No presets available</div>
                  )}
                </div>
                <div className="preset-column">
                  <div className="preset-label m-bottom-10">
                    {getPricingModelDisplayName(PricingModel.PACK)}
                  </div>
                  {interchangePresets.length ? (
                    packPresets.map(pricingToggle)
                  ) : (
                    <div>No presets available</div>
                  )}
                </div>
              </div>
            </div>

            <AnimateHeightMotion presence>
              {saveButtonStatus === Status.SUCCESS && (
                <SuccessBox relative className="m-top-30">
                  We have successfully updated the Sales Team
                </SuccessBox>
              )}
            </AnimateHeightMotion>

            <div className="tablet-columns m-top-30">
              <div>
                <Button
                  ghost
                  block
                  onClick={() => {
                    history.go(-1);
                  }}
                >
                  Go back
                </Button>
              </div>
              <div>
                <Button block type="submit" status={saveButtonStatus}>
                  Update
                </Button>
              </div>
            </div>
          </Form>
        </article>

        <article>
          <div className="m-top-30">
            <h5>Current Contracts</h5>
            <Table>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Status</th>
                  <th>Started</th>
                  <th>Last Updated</th>
                  <th></th>
                  <th>Log</th>
                </tr>
              </thead>
              <tbody>
                {contracts?.content.map((contract) => (
                  <tr key={contract.companyRegistrationId}>
                    <td className="truncate">
                      {contract.companyName ? contract.companyName : contract.companyRegistrationId}
                    </td>
                    <td className="truncate">
                      <ContractStatus status={contract.status} />
                    </td>
                    <td className="truncate">
                      <FormattedDate date={contract.created} pattern="yyyy-MM-dd" />
                    </td>
                    <td className="truncate">
                      {contract.lastUpdated ? (
                        <FormattedDate date={contract.lastUpdated} pattern="yyyy-MM-dd" />
                      ) : (
                        " - "
                      )}
                    </td>
                    <td>
                      <Button
                        ghost
                        className="small"
                        onClick={() => {
                          history.push(
                            generatePath(resolveLinkByStatus(contract.status), {
                              id: contract.contractId,
                            })
                          );
                        }}
                        status={Status.DEFAULT}
                      >
                        Open
                      </Button>
                    </td>
                    <td>
                      <Button
                        ghost
                        className="small"
                        onClick={() => {
                          history.push(
                            generatePath(CONTRACT_AUDIT_PAGE, {
                              contractId: contract.contractId,
                            })
                          );
                        }}
                      >
                        View Log
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="m-top-10">
              <Pagination
                activePage={contracts ? contracts.number : 0}
                totalPages={contracts ? contracts.totalPages : 0}
                onPageChange={setPage}
              />
            </div>
          </div>
        </article>
      </section>
    </AdminPage>
  );
};
