import cx from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Form } from "../../../../../components/form/Form";
import { hasRealErrors } from "../../../../../components/form/FormHelpers";
import { dataPricing } from "../../../../../data/dataPricing";
import { Country } from "../../../../../model/common/commonType";
import { EditablePricingTemplate, PricingTemplate } from "../../../../../model/pricing/pricingTypes";
import { Status } from "../../../../../data/types";
import { Language } from "../../../../../i18n";
import { availablePricingTemplatesState } from "../../../../../state/contractCostState";
import { AdminPage } from "../../../AdminPage";
import { PricingInputs } from "./PricingInputs/PricingInputs";
import { cache } from "../../../../../network/Cache";
import {
  convertEditableTemplateToSaveObject,
  convertPricingTemplateToEditable,
  getPlaceholderPricingTemplate,
} from "../../../../../model/pricing/pricingUtils";
import "./AdminPreSetPage.scss";

import { AdminPageActions } from "./PageActions/AdminPageActions";
import { AdminPreSetPageStatusMessages } from "./StatusMessages/AdminPreSetPageStatusMessages";

export const ADMIN_NEW_PRICING_PAGE = "/admin/pricing/:country/new";

export function NewAdminPresetPage() {
  const { country } = useParams<{ country: Country }>();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [editablePricing, setEditablePricing] = useState(getInitialPricing(country));
  const setPricingTemplateState = useSetRecoilState(availablePricingTemplatesState);

  const statusRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => cache.clear();
  }, []);

  const onSave = useCallback(async () => {
    const saveTemplate: PricingTemplate = convertEditableTemplateToSaveObject(editablePricing);
    saveTemplate.templateId = 0; // tempalteId = 0 creates a new template in API

    const pricingTemplates = await dataPricing.savePricingTemplate(country, saveTemplate);
    setPricingTemplateState(pricingTemplates);
    setStatus(Status.SUCCESS);

    if (statusRef.current) {
      // TODO: fix this, not smooth transition
      statusRef.current.scrollIntoView();
    }
  }, [editablePricing, country, setPricingTemplateState]);

  return (
    <AdminPage
      title="Pricing preset page"
      className={cx("admin-pricing-preset-page", editablePricing.pricingModel)}
    >
      <Form
        onSubmit={(event, form) => {
          if (form.isInvalid || hasRealErrors(form)) return;
          onSave();
        }}
      >
        <section>
          <article>
            <h5 className="text-passive">New price plan: </h5>
            <h1>
              <strong>{editablePricing.name}</strong>
            </h1>
            <div className="m-y-40">
              <PricingInputs
                editablePricing={editablePricing}
                setEditablePricing={setEditablePricing}
                isUpdate={false}
                lang={Language.UK}
                country={country}
                disabled={status === Status.SUCCESS}
              />

              <div className="m-top-40">
                <AdminPreSetPageStatusMessages country={country} status={status} />
              </div>
              <div ref={statusRef}>
                <AdminPageActions status={status} buttonIsDisabled={false} country={country} />
              </div>
            </div>
          </article>
        </section>
      </Form>
    </AdminPage>
  );
}

const getInitialPricing = (country: Country): EditablePricingTemplate => {
  const placeholderTemplate = getPlaceholderPricingTemplate(country);
  const editablePricing = convertPricingTemplateToEditable(placeholderTemplate, country);
  return editablePricing;
};
