import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { Checkboxes } from "../../../../components/interactions/Checkboxes/Checkboxes";
import { contractPricingState } from "../../../../state/contractCostState";
import { contractStatusState } from "../../../../state/contractStatusState";
import { getCurrencySymbol } from "../../../../components/utils";
import useGeneralData from "../../../../hooks/useGeneralData";
import { useCallback, useMemo, useState } from "react";
import { Dropdown } from "../../../../components/interactions/Dropdown/Dropdown";
import { Status } from "../../../../data/types";
import { ContractType } from "../../../../model/contract/contractType";
import styles from "./Cashback.module.scss";

const ENABLE_CASHBACK = "ENABLE_CASHBACK";

export const Cashback: React.FC = () => {
  const [contractPricing, setContractPricing] = useRecoilState(contractPricingState);
  const { edit } = useRecoilValue(contractStatusState);
  const { cashBack } = contractPricing[ContractType.INSTORE];
  const [enableCashback, setCashback] = useState<string[]>(cashBack ? [ENABLE_CASHBACK] : []);
  const { currency, language } = useGeneralData();
  const { t } = useTranslation();

  const CASHBACK_ALTERNATIVES = useMemo(
    () => [
      {
        value: -1.0,
        text: t("-1.00 {{currency}}", {
          currency: getCurrencySymbol(language, currency),
        }),
      },
      {
        value: -0.5,
        text: t("-0.5 {{currency}}", {
          currency: getCurrencySymbol(language, currency),
        }),
      },
      {
        value: -0.2,
        text: t("-0.2 {{currency}}", {
          currency: getCurrencySymbol(language, currency),
        }),
      },
    ],
    [language, currency, t]
  );

  const toggleCashback = useCallback(
    (value: string[]) => {
      const enableCashBack = value.includes(ENABLE_CASHBACK);
      setContractPricing((prev) => ({
        ...prev,
        [ContractType.INSTORE]: {
          ...prev[ContractType.INSTORE],
          cashBack: enableCashBack ? CASHBACK_ALTERNATIVES[0].value : undefined,
        },
      }));

      setCashback(value);
    },
    [setContractPricing, CASHBACK_ALTERNATIVES]
  );

  return (
    <div className={styles.root}>
      <h5>{t("Cashback")}</h5>
      <div className="tablet-columns">
        <div className="m-top-30">
          <Checkboxes
            disabled={!edit}
            onChange={toggleCashback}
            values={enableCashback}
            alternatives={[
              {
                text: t("Cashback"),
                value: ENABLE_CASHBACK,
              },
            ]}
          />
        </div>
        <div>
          {enableCashback.includes(ENABLE_CASHBACK) && (
            <Dropdown
              status={edit ? Status.DEFAULT : Status.DISABLED}
              alternatives={CASHBACK_ALTERNATIVES}
              label={t("Cashback Value")}
              value={cashBack}
              onChange={(value) => {
                setContractPricing((prev) => ({
                  ...prev,
                  [ContractType.INSTORE]: {
                    ...prev[ContractType.INSTORE],
                    cashBack: value,
                  },
                }));
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
