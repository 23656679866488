import React from "react";
import { Link } from "../../components/links/Link";
import { DASHBOARD_ROUTE } from "../Dashboard/DashboardPage";
import { PageStripped } from "../Page/PageStripped";
import "./ApplicationPage.scss";

export const APPLICATION_ROUTE = "/application";

export const ApplicationPage: React.FunctionComponent = () => {
  return (
    <PageStripped title="Application" className="application">
      <section>
        <article>
          Application implementation
          <p>
            <Link link={DASHBOARD_ROUTE}>Go back</Link>
          </p>
        </article>
      </section>
    </PageStripped>
  );
};
