import React from "react";
import { useTranslation } from "react-i18next";
import { ContractStatus as Type } from "../../model/contract/contractType";
import "./ContractStatus.scss";

interface Props {
  status: Type;
}

export function getContractStatusDisplayString(status: Type, abbreviated = false) {
  if (abbreviated) {
    switch (status) {
      case Type.COMPLETE:
        return "Completed";
      case Type.MERCHANT_SIGNATURES:
        return "Signing";
      case Type.MERCHANT_VERIFICATION:
        return "Confirmation";
      case Type.PENDING_SALES_INPUT:
        return "Creating";
      case Type.SALES_VERIFICATION:
        return "Sign off";
      case Type.ANONYMISED:
        return "Anonymised";
    }
  } else {
    switch (status) {
      case Type.COMPLETE:
        return "Completed";
      case Type.MERCHANT_SIGNATURES:
        return "Merchant signing";
      case Type.MERCHANT_VERIFICATION:
        return "Merchant confirmation";
      case Type.PENDING_SALES_INPUT:
        return "Creating contract";
      case Type.SALES_VERIFICATION:
        return "Document sign off";
      case Type.ANONYMISED:
        return "Anonymised";
    }
  }
}

export const ContractStatus: React.FunctionComponent<Props> = ({ status }) => {
  const { t } = useTranslation();
  return <>{t(getContractStatusDisplayString(status))}</>;
};

export function contractStatusToString(t: any, status: Type) {
  return t(getContractStatusDisplayString(status));
}
