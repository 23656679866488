import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";

import { ContractType, Store2, Validity } from "../../../../model/contract/contractType";
import { Beacon } from "../../../../components/beacon/Beacon";
import { Expand } from "../../../../components/icons/Expand";
import { motion } from "framer-motion";
import { Location } from "../../../../components/icons/Location";
import { useRecoilState, useRecoilValue } from "recoil";
import { contractStoresState } from "../../../../state/contractStoresState";
import { dataContracts } from "../../../../data/dataContracts";
import { ContractSaveError, handleError } from "../../ContractPage";
import { contractStatusState } from "../../../../state/contractStatusState";
import { ContractEditError } from "../../ContractEditError";
import { FormContainer } from "../../../../components/form/Form";
import { StoreForm } from "./StoreForm/StoreForm";
import { useTranslation } from "react-i18next";
import { Status } from "../../../../data/types";
import { StoreId } from "../../../../model/common/commonType";
import { TrashIcon } from "../../../../components/icons/TrashIcon";

import EcomStore from "./EcomStoreForm/EcomStore";
import styles from "./StoreRow.module.scss";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { getStoreAddress } from "../../../../model/contract/contractUtils";

interface Props {
  store: Store2;
  onClick: (storeId: StoreId) => void;
  isActive: boolean;
  status: Status;
}

export const StoreRow: React.FunctionComponent<Props> = ({ store, onClick, isActive = false, status }) => {
  const { t } = useTranslation();
  const [stores, setStores] = useRecoilState(contractStoresState);
  const contractStatus = useRecoilValue(contractStatusState);
  const [isFormValid, setIsFormValid] = useState(false);
  const [removeError, setRemoveError] = useState<ContractSaveError | null>(null);
  const formContainer = useRef<FormContainer>();

  const onRemove = useCallback(() => {
    dataContracts
      .deleteStore(contractStatus.contractId, store.storeId)
      .then((response) => {
        setStores(response);
      })
      .catch((err) => {
        handleError(err, setRemoveError);
      });
  }, [contractStatus.contractId, setStores, store.storeId]);

  const retryRemove = useCallback(() => {
    setRemoveError(null);
    setTimeout(onRemove, 500);
  }, [onRemove]);

  const reclaimAndRemove = useCallback(() => {
    setRemoveError(null);
    setTimeout(() => {
      dataContracts.claimContract(contractStatus.contractId).then(onRemove).catch(onRemove);
      // We're lazy, execute save again, which will fail, and propagate error to Overlay
    }, 500);
  }, [contractStatus.contractId, onRemove]);

  useEffect(() => {
    const onUpdate = () => {
      if (formContainer.current && isFormValid !== formContainer.current.isValid) {
        setIsFormValid(formContainer.current.isValid);
      }
    };

    const container = formContainer.current;
    container?.addListener(onUpdate);
    return () => {
      container?.removeListener(onUpdate);
    };
  }, [isFormValid]);

  return (
    <>
      <ContractEditError
        error={removeError}
        setError={setRemoveError}
        retry={retryRemove}
        onClose={() => setRemoveError(null)}
        reclaimAndSave={reclaimAndRemove}
      />
      <ContentBox padding="no" className={styles.wrapper}>
        <motion.div
          className={styles.root}
          initial={{ opacity: 0, height: 0, y: -25 }}
          animate={{ opacity: 1, height: "auto", y: 0 }}
          exit={{ opacity: 0, height: 0 }}
        >
          <div className={styles.content}>
            <div className={styles.header} onClick={() => onClick(store.storeId)}>
              <Beacon
                validity={formContainer.current?.isValid ? Validity.VALID : Validity.MISSING}
                icon={<Location />}
              />

              <ul className="fw-500">
                <li>{store.commercialName || t("No name set")}</li>
                <li className="fs-small text-passive">{getStoreAddress(store)}</li>
              </ul>

              <div className={styles.actions}>
                {stores.length > 1 && store.contractType !== ContractType.ECOMMERCE && (
                  <motion.button
                    className={cx("button", "action", "small", "danger-button", status)}
                    disabled={status === Status.DISABLED}
                    whileHover={{ rotate: [null, 20, -20, 0] }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemove();
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    <TrashIcon />
                  </motion.button>
                )}

                <motion.div className={styles.icon} animate={{ rotate: isActive ? 180 : 0 }}>
                  <Expand />
                </motion.div>
              </div>
            </div>
            <motion.div
              animate={{ height: isActive ? "auto" : 0, opacity: isActive ? 1 : 0 }}
              transition={{ type: "tween", ease: "easeInOut" }}
              initial={false}
              className={styles.body}
            >
              <div className={styles.form}>
                {store.contractType === ContractType.ECOMMERCE ? (
                  <EcomStore key={store.storeId} store={store} formContainer={formContainer} />
                ) : (
                  <StoreForm key={store.storeId} store={store} formContainer={formContainer} />
                )}
              </div>
            </motion.div>
          </div>
        </motion.div>
      </ContentBox>
    </>
  );
};
