import { FC, useEffect, useState } from "react";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Dropdown } from "../../../components/interactions/Dropdown/Dropdown";
import {
  AdminMerchantCategory,
  dataMccAdministration,
  NewMccRequest,
  RISK_LEVEL_OPTIONS,
} from "../../../data/dataMccAdministration";
import { MccRiskLevel } from "../../../model/contract/contractType";
import { Status } from "../../../data/types";

import { NewOverlay } from "../../../components/overlay/NewOverlay";
import styles from "./AddMccModal.module.scss";
import { TrashIcon } from "../../../components/icons/TrashIcon";
import { TextInput } from "../../../components/form/TextInput";

export interface ModalProps {
  onSave: () => void;
  onClose: () => void;
  open: boolean;
  mccData: AdminMerchantCategory | null;
}

const DEFAULT_REQUEST: NewMccRequest = {
  code: "",
  name: "",
  note: "",
  category: "",
  faceToFace: MccRiskLevel.NONE,
  cardNotPresent: MccRiskLevel.NONE,
};

export const AddMccModal: FC<ModalProps> = ({ onSave, onClose, open, mccData }) => {
  const [mccRequest, setMccRequest] = useState<NewMccRequest>(DEFAULT_REQUEST);
  const [codeStatus, setCodeStatus] = useState(Status.DEFAULT);
  const [mccId, setMccId] = useState<number | null>(null);

  useEffect(() => {
    setMccId(null);
    setMccRequest(DEFAULT_REQUEST);

    if (!mccData) return;
    setMccRequest({ ...mccData });
    setMccId(mccData.id);
  }, [mccData]);

  const isFormValid = () => {
    return mccRequest.code.length === 4 && !isNaN(Number(mccRequest.code)) && mccRequest.name.length > 0;
  };

  const mccCodeValidation = () => {
    if (mccRequest.code.length === 0) return setCodeStatus(Status.DEFAULT);
    if (mccRequest.code.length < 3 || mccRequest.code.length > 4) return setCodeStatus(Status.ERROR);
    if (isNaN(Number(mccRequest.code))) return setCodeStatus(Status.ERROR);
    setCodeStatus(Status.SUCCESS);
  };

  const deleteMcc = (id: number) => {
    dataMccAdministration
      .deleteMcc(id)
      .then(() => {
        onSave();
        onClose();
      })
      .catch((e) => {
        console.log("error deleting: ", e);
      });
  };

  const updateMcc = () => {
    if (!mccId) return;
    dataMccAdministration.updateMcc(mccId, mccRequest).then((resp) => {
      onSave();
      onClose();
    });
  };

  const addNewMcc = () => {
    dataMccAdministration.addNewMcc(mccRequest).then(() => {
      onSave();
      onClose();
    });
  };

  return (
    <NewOverlay open={open} onClose={onClose} noPadding widthSize="small">
      <div className={styles.root}>
        <div className={styles.header}>
          <h4 style={{ margin: 0 }}>{mccData ? "Update MCC" : "Add new MCC"}</h4>
        </div>
        <div className={styles.body}>
          <div className={styles.columns}>
            <TextInput
              onChange={(value) => {
                setMccRequest({ ...mccRequest, code: value });
              }}
              value={mccRequest.code}
              name={"code"}
              label="Code"
              disabled={mccData ? true : false}
              message={codeStatus === Status.ERROR ? "Code is required and is 3 or 4 digits long" : null}
              placeholder="MCC, 3 or 4 digits"
              onBlur={mccCodeValidation}
            />
          </div>
          <div className={styles.columns}>
            <Dropdown
              label="Face to face"
              alternatives={RISK_LEVEL_OPTIONS}
              value={mccRequest.faceToFace}
              onChange={(value) => {
                setMccRequest({ ...mccRequest, faceToFace: value as MccRiskLevel });
              }}
            />

            <Dropdown
              label="Card not present"
              alternatives={RISK_LEVEL_OPTIONS}
              value={mccRequest.cardNotPresent}
              onChange={(value) => {
                setMccRequest({
                  ...mccRequest,
                  cardNotPresent: value as MccRiskLevel,
                });
              }}
            />
          </div>
          <div>
            <label htmlFor="description" className="input input-text">
              <div className="input-label">Description</div>
              <div className="input-frame">
                <textarea
                  placeholder="e.g. Trucking services, motor freight carriers, moving services"
                  id="description"
                  rows={3}
                  value={mccRequest.name}
                  onChange={(e) => {
                    const value = !e.target.value ? "" : "" + e.target.value;
                    setMccRequest({ ...mccRequest, name: value });
                  }}
                ></textarea>
              </div>
            </label>
          </div>

          <div>
            <label htmlFor="note" className="input input-text">
              <div className="input-label">Note</div>
              <div className="input-frame">
                <textarea
                  placeholder=""
                  id="note"
                  rows={3}
                  value={mccRequest.note}
                  onChange={(e) => {
                    const value = !e.target.value ? "" : "" + e.target.value;
                    setMccRequest({ ...mccRequest, note: value });
                  }}
                ></textarea>
              </div>
            </label>
          </div>
        </div>

        <div className={styles.actions}>
          {mccData && mccId && (
            <div className={styles.delete_button}>
              <Button block onClick={() => deleteMcc(mccId)} color="danger">
                <TrashIcon style={{ marginRight: 8 }} /> Delete
              </Button>
            </div>
          )}

          <div className={styles.close_button}>
            <Button block onClick={onClose} color="secondary" variant="text">
              Cancel
            </Button>
          </div>

          <div className={styles.save_button}>
            <Button
              onClick={mccData ? () => updateMcc() : () => addNewMcc()}
              status={isFormValid() ? Status.DEFAULT : Status.DISABLED}
              block
            >
              {mccData ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      </div>
    </NewOverlay>
  );
};
