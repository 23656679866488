import React, { RefObject, useRef } from "react";
import { Status } from "../../data/types";
import {
  Dropdown,
  Props as DropdownProps,
} from "../interactions/Dropdown/Dropdown";
import { useForm } from "./hooks/useForm";
import { useValidation, ValidationProps } from "./hooks/useValidation";
import { RequiredValidatorName } from "./validators/RequiredValidator";

interface Props<T>
  extends Omit<DropdownProps<T>, "status" | "required">,
    ValidationProps {
  disabled?: boolean;
  scrollToRef?: RefObject<HTMLElement>;
}

export function Select<T>({
  className,
  name,
  label = null,
  onChange,
  onBlur,
  hint = null,
  message = null,
  placeholder = "",
  alternatives = [],
  value,
  validators = [],
  forceValidation = false,
  disabled = false,
  scrollToRef,
}: Props<T>) {
  const ref = useRef<HTMLSelectElement>(null);
  const innerValidators = disabled ? [] : validators;
  const inputId = useRef(
    "select_" + Math.random().toString(36).substring(2, 9)
  );
  const [validity, errorMessages, resetValidation, status] = useValidation<T>(
    value,
    innerValidators,
    forceValidation
  );
  useForm(
    inputId.current,
    validity,
    value,
    resetValidation,
    scrollToRef ?? ref
  );

  const isRequired = innerValidators.some(
    (validator) => validator.name === RequiredValidatorName
  );

  return (
    <Dropdown
      className={className}
      name={name}
      label={label}
      onChange={onChange}
      onBlur={onBlur}
      hint={hint}
      placeholder={placeholder}
      alternatives={alternatives}
      value={value}
      status={disabled ? Status.DISABLED : status}
      required={isRequired}
      message={errorMessages.length > 0 ? errorMessages[0] : message}
      ref={ref}
    />
  );
}
