import { FC, useCallback, useEffect, useRef, useState } from "react";
import ContentBox from "../../../components/ContentBox/ContentBox";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Status } from "../../../data/types";
import { useTranslation } from "react-i18next";
import { SendIcon } from "../../../components/icons/SendIcon";
import { CopyIcon } from "../../../components/icons/CopyIcon";

interface Props {
  resendMerchantEmail: () => void;
  resendOnboardingEmail: () => void;
  merchantEmailStatus: Status;
  onboardingEmailStatus: Status;
}

const CompletedContractActions: FC<Props> = ({
  resendMerchantEmail,
  resendOnboardingEmail,
  merchantEmailStatus,
  onboardingEmailStatus,
}) => {
  const { t } = useTranslation();
  const copyTimer = useRef<number>();
  const [copyStatus, setCopyStatus] = useState<Status>(Status.DEFAULT);

  useEffect(() => {
    return () => {
      if (copyTimer) {
        window.clearTimeout(copyTimer.current);
      }
    };
  }, []);

  const onCopy = useCallback(() => {
    window.clearTimeout(copyTimer.current);
    navigator.clipboard
      .writeText(window.location.toString())
      .then(() => {
        setCopyStatus(Status.SUCCESS);

        copyTimer.current = window.setTimeout(() => {
          setCopyStatus(Status.DEFAULT);
        }, 2000);
      })
      .catch((err) => {
        setCopyStatus(Status.ERROR);

        copyTimer.current = window.setTimeout(() => {
          setCopyStatus(Status.DEFAULT);
        }, 2000);
      });
  }, []);

  return (
    <ContentBox>
      <h5>{t("Resend emails")}</h5>
      <Button block ghost onClick={resendMerchantEmail} status={merchantEmailStatus}>
        <SendIcon style={{ marginRight: 6 }} /> {t("Resend Merchant Email")}
      </Button>
      <Button block ghost onClick={resendOnboardingEmail} status={onboardingEmailStatus}>
        <SendIcon style={{ marginRight: 6 }} /> {t("Resend Onboarding Email")}
      </Button>
      <hr style={{ margin: "20px 0" }} />
      <h5>{t("Contract link")}</h5>
      <Button block ghost onClick={onCopy} status={copyStatus}>
        <CopyIcon style={{ marginRight: 6, width: "1.2em", height: "1.2em" }} /> {t("Copy contract link")}
      </Button>
    </ContentBox>
  );
};

export default CompletedContractActions;
