import React, { useState } from "react";
import cx from "classnames";
import "./FieldSet.scss";
import { ReactNode } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { Button } from "../interactions/Buttons/Button";
import { Collapsible } from "../animate/Collapsible";

interface Props {
  header: string | ReactNode;
  children: ReactNode;
  className?: string;
  collapsible?: boolean;
}

export const FieldSet: React.FunctionComponent<Props> = ({
  header,
  children,
  className,
  collapsible = false,
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={cx("fieldset", className)}>
      <h5 className="fieldset-header">{header}</h5>
      <div className="fieldset-inner">
        {collapsible && (
          <Collapsible collapsed={isCollapsed}>{children}</Collapsible>
        )}
        {!collapsible && children}
      </div>

      {collapsible && (
        <div className="fieldset-expand-button">
          {isCollapsed && (
            <motion.div layout layoutId="expand-button">
              <Button className="small" onClick={toggleCollapse}>
                {t("Show more")}
              </Button>
            </motion.div>
          )}
          {!isCollapsed && (
            <motion.div layout layoutId="expand-button">
              <Button className="small" onClick={toggleCollapse}>
                {t("Show less")}
              </Button>
            </motion.div>
          )}
        </div>
      )}
    </div>
  );
};
