import { Trans } from "react-i18next";
import { IcppDescription } from "./IcppDescription";
import { useState } from "react";
import { Checkboxes } from "../../../../components/interactions/Checkboxes/Checkboxes";
import { T } from "../../../../components/translation/T";
import { AnimateHeightMotion } from "../../../../components/animate/AnimateHeightMotion";
import { PricingSettings, STORE_KEY, Store } from "../../../../Store";

const localStorageValue = Store.getValue<PricingSettings>(STORE_KEY.STORE_PRICING_SETTINGS);

export function PricingIngress() {
  const [hide, setHide] = useState(localStorageValue.hidePricingIngress);

  const onChange = () => {
    const newValue = !hide;
    Store.setValue(STORE_KEY.STORE_PRICING_SETTINGS, {
      ...localStorageValue,
      hidePricingIngress: newValue,
    });
    setHide(newValue);
  };

  return (
    <div className="m-bottom-30">
      <Checkboxes
        className="compact"
        onChange={onChange}
        values={hide ? ["true"] : []}
        name="priceIngress"
        alternatives={[
          {
            text: <T>Hide price model description</T>,
            value: "true",
          },
        ]}
      />
      <AnimateHeightMotion presence>
        {!hide && (
          <>
            <ul className="pricing-overview desc m-top-20">
              <li>
                <Trans>
                  The <i>Pack pricing model</i> presents merchants with a convenient and predictable fixed
                  monthly fee that covers the cost of the terminal(s) and a specified monthly transaction
                  volume, without any additional fees. If the merchant surpasses the specified monthly
                  transaction volume, they will be charged a transaction fee for each subsequent transaction,
                  as outlined in the transaction fees table below.
                </Trans>
              </li>
              <li>
                <Trans>
                  The <i>Blended fee</i> option will <strong>include</strong> the total cost for the merchant.
                  There will be no additional fees. Worldline will compensate the card providers.
                </Trans>
              </li>

              <li>
                {/* The <i>Interchange &#x2B;&#x2B;</i> does{" "}
      <strong>not include</strong> underlaying costs. Instead Worldline
      will forward additional fees directly to the card provider. */}
                <Trans>
                  The pricing model <i>"Interchange &#x2B;&#x2B;"</i> consists of 3 components which add up to
                  form the individual commission rate for each purchase or refund transaction.
                </Trans>
              </li>
            </ul>
            <IcppDescription />
          </>
        )}
      </AnimateHeightMotion>
    </div>
  );
}
