import React, { useState, useCallback, useMemo, useEffect } from "react";
import cx from "classnames";
import { useRecoilValue } from "recoil";
import { Status } from "../../data/types";
import { contractMainContractDataState } from "../../state/contractMainContractDataState";
import { contractStatusState } from "../../state/contractStatusState";
import { Address } from "../../model/contract/contractType";
import { getEditStatus } from "../../pages/Contract/ContractPage";
import { Alternative } from "../interactions/InputTypes";
import { Dropdown } from "../interactions/Dropdown/Dropdown";
import { sortedAssociatesSelector } from "../../state/contractAssociateState";
import { Associate } from "../../model/associate/associateTypes";
import { useTranslation } from "react-i18next";
import { Button } from "../interactions/Buttons/Button";
import "./CopyFrom.scss";

export const defaultAddress: Address = {
  street: "",
  city: "",
  postalCode: "",
  countryCode: "",
};

export enum COPY_TYPE {
  ADDRESS,
  ASSOCIATE,
  PHONENUMBER,
}

interface Props {
  type: COPY_TYPE;
  onChange: (type: COPY_TYPE, item: Associate | Address) => void;
  associate?: Associate;
  className?: string;
  status?: Status;
  text?: string;
}

const labels = {
  primaryAddress: "Company address",
  primaryContact: "Primary contact",
  owner: "Owner-",
  signee: "Signee-",
};

function hasData(type: COPY_TYPE, associate: Associate) {
  if (type === COPY_TYPE.ADDRESS) {
    return associate.address?.city || associate.address?.street || associate.address?.postalCode;
  } else if (type === COPY_TYPE.PHONENUMBER) {
    return associate.phoneNumber;
  } else {
    return !!(associate.firstName || associate.lastName || associate.email);
  }
}

function getInitialValue(type: COPY_TYPE, associates: Associate[]) {
  if (type === COPY_TYPE.ADDRESS) {
    return labels.primaryAddress;
  } else {
    return "";
  }
}

function getTextLabel(associate: Associate) {
  if (!associate.firstName && !associate.lastName) {
    return `associate with id ${associate.associateId}`;
  }

  if (associate.firstName && associate.lastName) {
    return `${associate.firstName} ${associate.lastName}`;
  }

  return `${associate.firstName || associate.lastName}`;
}

const CHIP_ANIMATION_DURATION = 1000;

export const CopyFrom: React.FunctionComponent<Props> = ({
  type,
  associate,
  onChange,
  className,
  status = Status.DEFAULT,
  text = "Copy from",
}) => {
  const { t } = useTranslation();
  const contractStatus = useRecoilValue(contractStatusState);
  const associates = useRecoilValue(sortedAssociatesSelector);
  const mainDataStatus = useRecoilValue(contractMainContractDataState);
  const [value, setValue] = useState<string>(getInitialValue(type, associates));
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  const onSelect = useCallback((updatedValue) => {
    setValue(updatedValue);
  }, []);

  useEffect(() => {
    // reset value if associates are/associate is updated
    setValue("");
  }, [associates, associate?.associateId]);

  useEffect(() => {
    if (isAnimating) {
      const animationTimeout = setTimeout(() => {
        setIsAnimating(false);
      }, CHIP_ANIMATION_DURATION);

      return () => clearTimeout(animationTimeout);
    }
  }, [isAnimating]);

  const inputStatus = useMemo(() => {
    return getEditStatus(contractStatus.edit, status);
  }, [contractStatus.edit, status]);

  const options: Alternative<string>[] = useMemo(() => {
    const alternatives: Alternative<string>[] = [];

    if (type === COPY_TYPE.ADDRESS) {
      alternatives.push({
        value: labels.primaryAddress,
        text: t(labels.primaryAddress),
      });
    }

    associates.forEach((person) => {
      if (person.associateId === associate?.associateId) {
        return;
      }

      if (hasData(type, person)) {
        alternatives.push({
          value: person.associateId,
          text: getTextLabel(person),
        });
      }
    });

    return alternatives;
  }, [type, associates, associate?.associateId, t]);

  const onClick = useCallback(() => {
    if (!value && !options.length) {
      return;
    }

    setIsAnimating(true);

    const defaultValue = value || options[0].value;

    if (defaultValue === labels.primaryAddress) {
      onChange(type, { ...mainDataStatus.primaryAddress });
      return;
    }

    const chosen = associates.find((person) => person.associateId === defaultValue);

    if (!chosen) {
      return;
    }

    if (type === COPY_TYPE.ADDRESS) {
      onChange(type, chosen.address || defaultAddress);
    } else {
      onChange(type, chosen);
    }
  }, [type, value, onChange, mainDataStatus, associates, options]);

  return (
    <div className={cx("copy-from", "compact-input", className)}>
      {!!options.length && (
        <>
          <div className="copy-button-wrapper">
            <div
              className={cx("copy-chip", {
                "copy-chip-animate": isAnimating,
              })}
            >
              Copied!
            </div>
            <Button type="button" className="copy-button" onClick={onClick} status={inputStatus}>
              {inputStatus === Status.PENDING ? null : t(text)}
            </Button>
          </div>
          <Dropdown
            label={t(text)}
            onChange={onSelect}
            status={inputStatus}
            alternatives={options}
            value={value}
          />
        </>
      )}
    </div>
  );
};
