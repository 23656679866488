import { FC, useRef } from "react";
import { HiddenInput } from "../../../../../components/form/HiddenInput";
import { useTranslation } from "react-i18next";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { Terminal, TerminalType } from "../../../../../model/contract/contractType";
import {
  getTerminalImageData,
  getTerminalTypeString,
  groupTerminalsByType,
} from "../../../../../model/terminal/terminalUtils";
import styles from "./StoreFormTerminals.module.scss";
import { NewImage } from "../../../../../components/images/NewImage";
import { WarningBox } from "../../../../../components/boxes/WarningBox";

interface Props {
  terminals: Terminal[];
}

const StoreFormTerminals: FC<Props> = ({ terminals }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const groupedTerminals = groupTerminalsByType(terminals);

  return (
    <div>
      <div className="fw-700 m-bottom-20">{t("Terminals at location")}</div>
      {groupedTerminals.length > 0 ? (
        <div className={styles.terminals}>
          {groupedTerminals.map(({ terminalType, terminals }) => (
            <StoreTerminal key={terminalType} terminalType={terminalType} amount={terminals.length} />
          ))}
        </div>
      ) : (
        <WarningBox>
          {t(
            "No terminals have been added for this location. Terminals can be added in the pricing section."
          )}
        </WarningBox>
      )}

      <HiddenInput
        label={t("Terminals")}
        validators={[new RequiredValidator(t("You need at least one terminal"))]}
        value={terminals.length > 0 ? true : undefined}
        scrollToRef={ref}
      />
    </div>
  );
};

interface StoreTerminalProps {
  terminalType: TerminalType;
  amount: number;
}

const StoreTerminal: FC<StoreTerminalProps> = ({ terminalType, amount }) => {
  return (
    <div className={styles.terminal}>
      <div className={styles.image}>
        <NewImage {...getTerminalImageData(terminalType)} size="small" />
      </div>
      <div className={styles.terminalData}>
        <div className="fw-600">{getTerminalTypeString(terminalType)}</div>
        <h3 className={styles.amount}>&#215; {amount}</h3>
      </div>
    </div>
  );
};

export default StoreFormTerminals;
