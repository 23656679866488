import { FC } from "react";
import { DCC, EditablePricingTemplate } from "../../../../model/pricing/pricingTypes";
import styles from "./AdditionalSettings.module.scss";
import { AuthorizationFees } from "./AuthorizationFees";
import { Country } from "../../../../model/common/commonType";
import { SetEditablePricing } from "../../../Admin/Pricing/AdminPricingCountryPage/AdminPresetPage/PricingInputs/PricingInputs";
import { NumberInput } from "../../../../components/form/NumberInput";
import { FeeInputValidator } from "../../../../components/form/validators/FeeInputValidator";
import { MaxValidator } from "../../../../components/form/validators/MaxValidator";
import { MinValidator } from "../../../../components/form/validators/MinValidator";
import { getCurrencySymbol, getIntlCost } from "../../../../components/utils";

import { getIntlNumberFormat } from "../views/FeeInput";
import { useTranslation } from "react-i18next";
import useGeneralData from "../../../../hooks/useGeneralData";
import {
  CHARGEBACK_FEE_PLACEHOLDER,
  COMMERCIAL_CARD_MIN_FEE,
  isBlended,
  isICPP,
  NON_EEA_MIN_FEE,
} from "../../../../model/pricing/pricingUtils";
import { DCCSelector } from "../DCCSelector/DCCSelector";
import { useRecoilValue } from "recoil";
import { contractStatusState } from "../../../../state/contractStatusState";
import { ContractType } from "../../../../model/contract/contractType";
import { DCCSelectorAdmin } from "../DCCSelector/DCCSelectorAdmin";

export interface HighlighBoxStyleProps {
  variant?: "primary" | "white" | "grey";
  noBorder?: boolean;
  roundedBorders?: boolean;
  noPadding?: boolean;
  noBoxShadows?: boolean;
}

type TemplateProps = Pick<
  EditablePricingTemplate,
  "surchargesNonEea" | "surchargesCommercialCards" | "chargebackFee" | "pricingModel" | "preAuthorizationFees"
>;

interface Props extends TemplateProps {
  disabled?: boolean;
  setEditablePricing?: SetEditablePricing;
  isSalesComponent?: boolean;
  country: Country;
  contractType: ContractType;
  dccAdminValues?: DCC[];
}
type UpdateKey = "surchargesNonEea" | "surchargesCommercialCards" | "chargebackFee" | "dcc";

export const AdditionalSettings: FC<Props> = ({
  surchargesCommercialCards,
  surchargesNonEea,
  chargebackFee,
  pricingModel,
  dccAdminValues,
  preAuthorizationFees,
  disabled = false,
  setEditablePricing,
  isSalesComponent = false,
  country,
  contractType,
}) => {
  const { t } = useTranslation();
  const { language, currency } = useGeneralData();
  const { edit } = useRecoilValue(contractStatusState);

  const onChangeInput = (key: UpdateKey, value: number | undefined) => {
    if (!setEditablePricing || disabled) return;
    setEditablePricing((prev) => ({
      ...prev,
      [key]: value ?? 0,
    }));
  };

  if (!pricingModel) return null;

  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        <h5 style={{ margin: 0 }}>{t("Additional fees and conditions")}</h5>
      </div>
      <div className={`tablet-columns ${styles.content}`}>
        {!isICPP(pricingModel) && (
          <>
            <div className="m-bottom-20">
              <NumberInput
                label={t("Surcharge non-EEA, excl. commercial cards (%)")}
                name="surcharges"
                className="surcharges"
                disabled={disabled}
                value={surchargesNonEea}
                onChange={(value) => onChangeInput("surchargesNonEea", value)}
                placeholder={`${getIntlNumberFormat(language, NON_EEA_MIN_FEE[country], 2)}%`}
                message={t(SURCHARGES_NON_EEA_MESSAGE)}
                validators={[new FeeInputValidator(t("Fee is incorrectly formatted"))]}
                suffix="%"
              />
            </div>
            <div>
              <NumberInput
                label={t("Surcharge commercial cards (%)")}
                name="commercial"
                className="commercial"
                disabled={disabled}
                value={surchargesCommercialCards}
                onChange={(value) => onChangeInput("surchargesCommercialCards", value)}
                placeholder={`${getIntlNumberFormat(language, COMMERCIAL_CARD_MIN_FEE[country], 2)}%`}
                message={t(SURCHARGES_COMMERCIAL_CARDS_MESSAGE)}
                validators={[
                  new FeeInputValidator(t("Fee is incorrectly formatted")),
                  new MaxValidator(
                    4,
                    t("Maximum value is {{max}}%", {
                      max: getIntlNumberFormat(language, 4, 1),
                    })
                  ),
                ]}
                suffix="%"
              />
            </div>
          </>
        )}

        <div className="m-bottom-20">
          <NumberInput
            label={t(`Chargeback Fee ({{currency}})`, {
              currency: getCurrencySymbol(language, currency),
            })}
            name="chargeback-fee"
            disabled={disabled}
            value={chargebackFee}
            onChange={(value) => onChangeInput("chargebackFee", value)}
            placeholder={getIntlCost(language, CHARGEBACK_FEE_PLACEHOLDER[country], currency, 1)}
            message={t(
              "Applies to Mastercard, Maestro, Visa, VPAY, Diners, JCB, UnionPay, DebitMastercard, VisaDebit (in {{currency}})",
              {
                currency: getCurrencySymbol(language, currency),
              }
            )}
            validators={[
              new FeeInputValidator(t("Fee is incorrectly formatted")),
              new MinValidator(
                0,
                t("Minimum value is {{min}}", {
                  min: getIntlNumberFormat(language, 0, 1),
                })
              ),
            ]}
            prefix={`${getCurrencySymbol(language, currency)} `}
          />
        </div>
        <div>
          <NumberInput
            label={t("Acquirer fee")}
            name="acquirer-fee"
            disabled={true}
            value={0}
            onChange={() => {}}
            placeholder={t("From {{from}}% to {{to}}%", {
              from: getIntlNumberFormat(language, 0, 1),
              to: getIntlNumberFormat(language, 2, 1),
            })}
            message={t(ACQUIRER_FEE_MESSAGE)}
            validators={[
              new FeeInputValidator(t("Fee is incorrectly formatted")),
              new MinValidator(
                0,
                t("Minimum value is {{min}}%", {
                  min: getIntlNumberFormat(language, 0, 1),
                })
              ),
              new MaxValidator(
                2,
                t("Maximum value is {{max}}%", {
                  max: getIntlNumberFormat(language, 2, 1),
                })
              ),
            ]}
            suffix="%"
          />
        </div>
        <div>
          {!isSalesComponent && dccAdminValues ? (
            <DCCSelectorAdmin
              disabled={disabled}
              setEditablePricing={setEditablePricing}
              dccAdminValues={dccAdminValues}
            />
          ) : (
            <DCCSelector isEditable={edit} contractTypeCurr={contractType} country={country} />
          )}
        </div>
      </div>

      {isBlended(pricingModel) && (
        <div className={styles.auth_fees}>
          <AuthorizationFees
            pricingModel={pricingModel}
            preAuthorizationFees={preAuthorizationFees}
            setEditablePricing={setEditablePricing}
            disabled={disabled}
            isSalesComponent={isSalesComponent}
          />
        </div>
      )}
    </div>
  );
};

const SURCHARGES_NON_EEA_MESSAGE =
  "Applies to transactions with cards issued outside and accepted inside the European Economic Area and vice versa. Applies to Mastercard, Maestro, Visa, VPAY, DebitMastercard, VisaDebit (in %)";

const SURCHARGES_COMMERCIAL_CARDS_MESSAGE =
  "Applies to all transactions with non-domestic commercial cards (e.g. Business Cards). Applies to Mastercard, Maestro, Visa, VPAY, DebitMastercard, VisaDebit (in %)";

const ACQUIRER_FEE_MESSAGE =
  "'Acquirer fee' will be calculated depending on number of terminals/accounts and country. Applies to Mastercard as of framework agreement";
