import { Language } from "../../i18n";
import { Cas, UTCDate, AssociateId } from "../common/commonType";
import { IdType, Saluation, Address, ContractDocument } from "../contract/contractType";

export enum AssociateRole {
  PRIMARY_CONTACT = "PRIMARY_CONTACT",
  SIGNATORY = "SIGNATORY",
  ACCOUNT_HOLDER = "ACCOUNT_HOLDER",
  BENEFICIAL_OWNER = "BENEFICIAL_OWNER",
  PORTAL_LOGIN = "PORTAL_LOGIN",
  STORE_CONTACT = "STORE_CONTACT",
}

export interface AssociateIdentity {
  cas: Cas;
  idType: IdType;
  idDocumentNo?: string;
  dateOfBirth?: string; // yyyy-MM-dd
  placeOfBirth?: string;
  expiry?: string; // yyyy-MM-dd
  issued?: string; // yyyy-MM-dd
  nationality?: string;
  issuedByAuthority?: string;
  issuedByCountry?: string;
  //What city issued it
  issuedIn?: string;
  noExpiry?: boolean;
}

export interface AssociateOwner {
  cas: Cas;
  designatedOwner: boolean;
  designatedFunction?: string;
  capitalStake?: number; //0.00 - 100.00
  votingRightsStake?: number; //0.00 - 100.00
  hasInderectOwership?: boolean;
  companyName?: string;
  companyOwnershipStake?: number;
  companyStreet?: string;
  companyPostalCode?: string;
  companyCity?: string;
  companyCountry?: string;
}

export interface AssociateSignatory {
  signed?: UTCDate;
  signature?: string;
}

export interface Associate {
  associateId: AssociateId;
  cas: Cas;
  created?: UTCDate;
  roles: AssociateRole[];
  signedOff?: UTCDate;
  signedOffBy?: string;
  saluation?: Saluation;
  nationalId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  address?: Address;
  language?: Language;
  identity?: AssociateIdentity;
  owner?: AssociateOwner;
  signatory?: AssociateSignatory;
  documents: {
    idCard: ContractDocument;
  };
}
