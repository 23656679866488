import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

const pattern = new RegExp(
  "^(https?:\\/\\/)?" + // protocol (optional)
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "(\\d{1,3}\\.){3}\\d{1,3})" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$", // fragment locator
  "i"
);

export class WebsiteValidator extends BaseValidator {
  name = "Website validator";

  private isStrippedOfProtocol: boolean;

  constructor(error: string, isStrippedOfProtocol: boolean = false) {
    super(error);
    this.isStrippedOfProtocol = isStrippedOfProtocol;
  }

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      const modifiedValue = this.isStrippedOfProtocol ? `https://${value}` : value;

      const valid = !!pattern.test(modifiedValue);

      if (value.length === 0 || valid) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
