import React, {
  useState,
  useCallback,
  ReactNode,
  useRef,
  useEffect,
} from "react";
import { Status } from "../../data/types";
import { dataAuth } from "../../data/dataAuth";
import { Button } from "../../components/interactions/Buttons/Button";
import { Input } from "../../components/interactions/Input/Input";
import { Store, STORE_KEY } from "../../Store";
import { useTranslation } from "react-i18next";
import { useIsMountedRef } from "../../hooks/useIsMounted";
import { Language } from "../../i18n";

interface Props {
  onNext: (code: string) => void;
}

function isValidEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function getPotentialError(email: string, t: any) {
  if (isValidEmail(email)) {
    return "";
  }

  if (!email) {
    return t("Email has to be supplied");
  }

  return t("Seems like this is an invalid email address?");
}

export const LoginEmail: React.FunctionComponent<Props> = ({ onNext }) => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState<string>(
    Store.getValue<string>(STORE_KEY.STORE_LOGIN_EMAIL)
  );
  const [emailError, setEmailError] = useState<ReactNode>();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const requestId = useRef<string>();
  const statusRef = useRef<Status>(Status.DEFAULT);
  const mounted = useIsMountedRef();
  const formRef = useRef<HTMLFormElement>(null);

  const language = i18n.language as Language;

  const updateStatus = useCallback((statusValue: Status) => {
    statusRef.current = statusValue;
    setStatus(statusValue);
  }, []);

  const onEmailChange = useCallback(
    (val) => {
      updateStatus(Status.DEFAULT);
      setEmailError("");
      const trimmedEamil = val.trim();
      setEmail(trimmedEamil);
    },
    [updateStatus]
  );

  const onBlur = useCallback(() => {
    const error = getPotentialError(email, t);
    if (error) {
      updateStatus(Status.ERROR);
      setEmailError(error);
    } else {
      updateStatus(Status.DEFAULT);
      setEmailError("");
    }
  }, [email, t, updateStatus]);

  const onEmailSubmit = useCallback(
    (ev) => {
      ev.preventDefault();
      updateStatus(Status.PENDING);

      const error = getPotentialError(email, t);
      if (error) {
        updateStatus(Status.ERROR);
        setEmailError(error);
        return;
      }

      setEmailError("");

      dataAuth
        .startLogin(email, language)
        .then((data) => {
          Store.setValue(STORE_KEY.STORE_LOGIN_EMAIL, email);
          requestId.current = data.requestId;
          onNext(requestId.current);
          updateStatus(Status.DEFAULT);
        })
        .catch((err) => {
          setEmailError(t("Ooh no! Something bad happened. Try again?"));
          updateStatus(Status.ERROR);
          requestId.current = undefined;

          setTimeout(() => {
            if (!mounted.current) {
              return;
            }

            if (statusRef.current !== Status.ERROR) {
              return;
            }

            updateStatus(Status.DEFAULT);
          }, 4000);
        });
    },
    [email, language, onNext, t, updateStatus, mounted]
  );

  useEffect(() => {
    if (!formRef.current) {
      return;
    }

    const elem = formRef.current.querySelector("[name='input-login-email']");
    if (elem) {
      setTimeout(() => {
        (elem as HTMLInputElement).focus();
      }, 100);
    }
  }, []);

  return (
    <form onSubmit={onEmailSubmit} ref={formRef}>
      <Input
        label={t("Enter email")}
        name="input-login-email"
        value={email}
        onChange={onEmailChange}
        placeholder={t("Email used to login")}
        status={status}
        message={emailError}
        onBlur={onBlur}
      />
      <Button block status={status} type="submit">
        {t("Sign in")}
      </Button>
    </form>
  );
};
