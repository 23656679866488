import React from "react";
import cx from "classnames";
import { MISSING_DATE } from "../../../../components/utils";
import { useTranslation } from "react-i18next";

interface Props {
  sentToOwnersForSignature: string;
}

export const EventSentToOwners: React.FunctionComponent<Props> = ({
  sentToOwnersForSignature,
}) => {
  const { t } = useTranslation();
  return (
    <li
      className={cx({
        active: sentToOwnersForSignature !== MISSING_DATE,
      })}
    >
      <div className="event">
        <div className="timeline-date">{sentToOwnersForSignature}</div>{" "}
        <b>{t("Sent to owners / legal representatives")}</b>
      </div>
    </li>
  );
};
