import { FeatureSwitch } from "../../components/featureSwitch/FeatureSwitch";
import { Notifier } from "../../components/notifier/Notifier";
import { Address, ContractFeature } from "../../model/contract/contractType";
import { InnerBank } from "./bank/Bank";
import { Claim } from "./claim/Claim";
import { CompanyData } from "./companyData/CompanyData";
import { DeleteContract } from "./DeleteContract";
import { Logins } from "./logins/Logins";
import { Offering } from "./offering/Offering";
import { Owners } from "./owners/Owners";
import { PreviewContract } from "./PreviewContract";
import { Pricing } from "./pricing/Pricing";
import { PrimaryContact } from "./primaryContact/PrimaryContact";
import { SendForConfirmation } from "./SendForConfirmation";
import { Signees } from "./signees/Signees";
import { Locations } from "./stores/Locations";
import { DocumentsSection } from "./uploads/DocumentsSection";
import AdvancedPricing from "./pricingV2/AdvancedPricing";

export const LinkAnchors = {
  PRIMAR_CONTACT: { name: "Primary contact", anchor: "primary-contact" },
  COMPANY_DATA: { name: "Company data", anchor: "company-data" },
  PRICING_CALCULATOR: {
    name: "Pricing",
    anchor: "pricing-calculator",
  },
  OFFERING: { name: "Offering", anchor: "offering" },
  PRICING: { name: "Pricing", anchor: "pricing" },
  TERMINALS: { name: "Locations", anchor: "Locations" },
  SIGNATORIES: {
    name: "Legal representatives",
    anchor: "legal-representatives",
  },
  OWNERS: { name: "Beneficial Owners", anchor: "owners" },
  BANK_DETAILS: { name: "Payout Account", anchor: "bank-details" },
  PORTAL_LOGIN: { name: "Portal login", anchor: "portal-link" },
  UPLOADS: { name: "Documents", anchor: "documents" },
};

export interface StoreAddress extends Address {
  commercialName?: string;
}

export function ContractEdit() {
  return (
    <div className="contract-edit">
      <Notifier />
      <Claim />

      <PrimaryContact />

      <CompanyData />

      <FeatureSwitch feature={ContractFeature.ADVANCED_PRICING}>
        <FeatureSwitch.Default>
          <Offering />

          <Pricing />
        </FeatureSwitch.Default>
      </FeatureSwitch>

      <Locations />

      <FeatureSwitch feature={ContractFeature.ADVANCED_PRICING}>
        <FeatureSwitch.Feature>
          <AdvancedPricing />
        </FeatureSwitch.Feature>
      </FeatureSwitch>

      <Signees />

      <Owners />

      <InnerBank />

      <Logins />

      <DocumentsSection />

      <div>
        <PreviewContract />
        <SendForConfirmation />
        <DeleteContract />
      </div>
    </div>
  );
}
