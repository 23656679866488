import React, { RefObject } from "react";
import { Status } from "../../data/types";
import { BeneficialOwnerType } from "../../model/contract/contractType";
import { AssociateOwner } from "../../model/associate/associateTypes";
import { Trans, useTranslation } from "react-i18next";
import { NumberInput } from "../form/NumberInput";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { MinValidator } from "../form/validators/MinValidator";
import { MaxValidator } from "../form/validators/MaxValidator";
import { OwnershipShareWarning } from "./OwnershipShareWarning";

interface Props {
  ownerForm: AssociateOwner;
  status?: Status;
  columnClass?: string;
  onChange: (value: number | undefined, name: string) => void;
  beneficialOwnerType: BeneficialOwnerType;
  scrollToRef?: RefObject<HTMLElement>;
}

export const MultipleOwner: React.FunctionComponent<Props> = ({
  ownerForm,
  onChange,
  status,
  beneficialOwnerType,
  scrollToRef,
}) => {
  const { t } = useTranslation();

  if (beneficialOwnerType !== BeneficialOwnerType.MULTPLE_OWNERS) {
    return null;
  }

  return (
    <>
      <div className="tablet-columns">
        <div>
          <NumberInput
            onChange={onChange}
            label={t("Stake of voting rights")}
            name="votingRightsStake"
            hint={t("Only registry owners with more than 25% of voting rights")}
            value={ownerForm.votingRightsStake}
            placeholder={t("e.g 25 %")}
            attributes={{ min: 25, max: 100 }}
            disabled={status === Status.DISABLED}
            validators={[
              new RequiredValidator(t("Stake of voting rights is required")),
              new MinValidator(25, t("Minimum stake is 25")),
              new MaxValidator(100, t("Maximum stake is 100")),
            ]}
            scrollToRef={scrollToRef}
            suffix=" %"
          />
        </div>
        <div>
          <NumberInput
            onChange={onChange}
            label={t("Stake of capital")}
            name="capitalStake"
            hint={t("In percentage")}
            value={ownerForm.capitalStake}
            placeholder={t("e.g 30 %")}
            attributes={{ min: 0, max: 100 }}
            validators={[
              new RequiredValidator(t("Stake of voting rights is required")),
              new MinValidator(0, t("Minimum stake is 0")),
              new MaxValidator(100, t("Maximum stake is 100")),
            ]}
            scrollToRef={scrollToRef}
            suffix=" %"
          />
        </div>
        <div className="small">
          <Trans>
            Only owners with <b>25 %</b> or more of the voting rights should be registred. There can be four
            such owners at most.
          </Trans>
        </div>
      </div>

      <OwnershipShareWarning
        votingRightsStake={ownerForm.votingRightsStake || 0}
        capitalStake={ownerForm.capitalStake || 0}
      />
    </>
  );
};
