import { useCallback, useState } from "react";
import UploadedFile from "../../../../../../components/fileUploads/UploadedFile/UploadedFile";
import SingleFileUpload from "../../../../../../components/fileUploads/SingleFileUpload/SingleFileUpload";
import { useRecoilState } from "recoil";
import { contractDocumentsState } from "../../../../../../state/contractDocumentsState";
import { DocumentType } from "../../../../../../model/contract/contractType";
import { dataContracts } from "../../../../../../data/dataContracts";
import { ContractId, DocumentId } from "../../../../../../model/common/commonType";
import { API } from "../../../../../../network/API";
import useContractData from "../../../../../../hooks/useContractData";
import styles from "../EcomStore.module.scss";
import { Status } from "../../../../../../data/types";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Accordion from "../../../../../../components/accordion/Accordions";
import { UploadIcon } from "../../../../../../components/icons/UploadIcon";

const getViewURL = (contractId: ContractId, documentId: DocumentId) => {
  return API.getUrl(`/api/sales/contracts/${contractId}/document/${documentId}`);
};

const getUploadURL = (contractId: ContractId) => {
  return API.getUrl(`/api/sales/contracts/${contractId}/document/e-commerce`);
};

const EcomStoreUploadFiles = () => {
  const [documents, setDocuments] = useRecoilState(contractDocumentsState);
  const filteredDocuments = documents.filter(
    (doc) => doc.documentType === DocumentType.E_COMMERCE_SUPPLEMENT
  );
  const [status, setStatus] = useState(Status.DEFAULT);
  const [apiErrorMessage, setApiErrorMessage] = useState<string | undefined>(undefined);
  const { contractId } = useContractData();
  const [showUploadBox, setShowUploadBox] = useState(false);
  const { t } = useTranslation();

  const resetStatesToDefault = useCallback(() => {
    setApiErrorMessage(undefined);
    setStatus(Status.DEFAULT);
  }, []);

  const onDeleteFile = (contractId: ContractId, documentId: DocumentId) => {
    dataContracts
      .deleteEcommerceDocument(contractId, documentId)
      .then((documents) => setDocuments(documents));
  };

  const uploadFiles = useCallback(
    (file: File, fileDescription: string) => {
      setStatus(Status.PENDING);

      const request = new XMLHttpRequest();
      const formData = new FormData();
      formData.append("fileDescription", fileDescription);
      formData.append("file", file);
      request.withCredentials = true;
      const postUrl = getUploadURL(contractId);
      request.open("POST", postUrl);
      request.setRequestHeader("Accept", "application/json");
      request.send(formData);

      request.onload = () => {
        if (request.status === 200) {
          setStatus(Status.SUCCESS);
          setTimeout(() => {
            setShowUploadBox(false);
            resetStatesToDefault();
          }, 4000);

          dataContracts.loadDocuments(contractId).then((updatedDocuments) => {
            setDocuments(updatedDocuments);
          });
        } else {
          const errorMessage = request.responseText || "Could not upload file. Please try agian.";
          setApiErrorMessage(errorMessage);
          setStatus(Status.ERROR);
          setTimeout(() => {
            setApiErrorMessage(undefined);
            setStatus(Status.DEFAULT);
          }, 5000);
        }
      };
    },
    [contractId, resetStatesToDefault, setDocuments]
  );

  return (
    <>
      <div className={styles.uploaded_files}>
        {filteredDocuments.length > 0 && (
          <div className="fw-500" style={{ paddingLeft: 10 }}>
            {t("Uploaded attachements")}
          </div>
        )}

        <AnimatePresence>
          {filteredDocuments.map((file) => (
            <motion.div
              layout
              key={file.id}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{
                y: { duration: 0.25 },
                opacity: { duration: 0.2 },
              }}
            >
              <UploadedFile
                fileName={file.fileName ?? "View file"}
                description={file.documentDescription ?? "Supplemented e-commerce file"}
                documentType={file.documentType}
                viewFileUrl={getViewURL(contractId, file.id)}
                deleteFile={() => onDeleteFile(contractId, file.id)}
                mimeType={file.mimeType}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      <Accordion
        open={showUploadBox}
        title={"Add e-commerce attachements"}
        icon={<UploadIcon />}
        onClick={() => setShowUploadBox(!showUploadBox)}
      >
        <SingleFileUpload
          title="Upload e-commerce files"
          onUpload={uploadFiles}
          status={status}
          errorMessage={apiErrorMessage}
        />
      </Accordion>
    </>
  );
};

export default EcomStoreUploadFiles;
