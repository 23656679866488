import { useState, useEffect, useRef } from "react";

const getWidth = () => window.innerWidth || document.body.clientWidth;

export function useWidth() {
  let [width, setWidth] = useState(getWidth());
  const timer = useRef<number | null>(null);

  useEffect(() => {
    const resizeListener = () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = window.setTimeout(() => setWidth(getWidth()), 300);
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return width;
}

export const LANDSCAPE_WIDTH = 480;
export const TABLET_WIDTH = 768;
export const DESKTOP_WIDTH = 1024;
export const DESKTOP_MIDDLE_WIDTH = 1280;
export const DESKTOP_PLUS_WIDTH = 1440;
export const WIDE_WIDTH = 1600;
export const SUPER_WIDTH = 2048;
