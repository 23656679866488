import React, { ChangeEvent, useCallback, useRef } from "react";
import cx from "classnames";
import { Status } from "../../../data/types";
import { Alternative } from "../InputTypes";
import { id } from "../../utils";
import { Checkbox } from "../../icons/Checkbox";
import { Checkmark } from "../../icons/Checkmark";
import { Disabled } from "../../icons/Disabled";
import { Pending } from "../../icons/Pending";
import { Error } from "../../icons/Error";
import { ValidationProps, useValidation } from "../../form/hooks/useValidation";
import { useForm } from "../../form/hooks/useForm";
import { RequiredValidatorName } from "../../form/validators/RequiredValidator";
import "../Radio/RadioButtons.scss";

interface Props extends ValidationProps {
  className?: string;
  values?: string[];
  hint?: string | React.ReactNode;
  label?: string | React.ReactNode;
  name?: string;
  message?: string;
  onChange: (values: string[], name: string, value: string) => void;
  onBlur?: (values: string[] | undefined, name: string, value: string) => void;
  alternatives: Alternative<string>[];
  disabled?: boolean;
}

export function Checkboxes({
  className,
  name,
  label = null,
  onChange,
  hint,
  message,
  alternatives = [],
  values,
  disabled,
  validators = [],
  forceValidation = false,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const innerValidators = disabled ? [] : validators;
  const inputId = useRef("checkboxes_" + Math.random().toString(36).substring(2, 9));
  const [validity, errorMessages, resetValidation, status] = useValidation(
    values,
    innerValidators,
    forceValidation
  );
  useForm(inputId.current, validity, values, resetValidation, ref);

  const isRequired = innerValidators.some((validator) => validator.name === RequiredValidatorName);

  const identifier = useRef<string>(id());

  const internalChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const targetValue = ev.target.value;
      const index = values?.indexOf(targetValue) ?? -1;
      const newValues = values ? [...values] : [];
      if (index > -1) {
        newValues.splice(index, 1);
      } else {
        newValues.push(targetValue);
      }
      return onChange(newValues, name || identifier.current, targetValue);
    },
    [onChange, name, values]
  );

  const indexLength = alternatives.length - 1;

  const internalMessage = status === Status.ERROR && errorMessages.length > 0 ? errorMessages[0] : message;

  return (
    <div
      className={cx("input", "checkbox-buttons", `length-${alternatives.length}`, className, status, {
        "is-disabled": disabled,
      })}
      ref={ref}
    >
      <div className="input-label-wrapper">
        {label && (
          <div className="input-label">
            <div className="input-label-tag">{label}</div>
          </div>
        )}
        {values && values.length === 0 && isRequired && <div className="required-marker fs-small">*</div>}
      </div>
      {alternatives.map((option, idx) => {
        const current = option?.value as any;

        const checked = !!values?.find((value) => value === current);

        const labelId = `${name || identifier.current}-${idx}`;

        const altIsDisabled = option.disabled;

        return (
          <label
            htmlFor={labelId}
            key={current}
            className={cx("checkbox-button", `length-${alternatives.length}`, {
              "is-first": idx === 0,
              "is-last": indexLength === idx,
              "is-disabled": altIsDisabled,
              checked,
            })}
          >
            <Checkbox checked={checked} />
            <div className="checkbox-label">{option.text}</div>
            <input
              id={labelId}
              name={labelId}
              type="checkbox"
              className="input is-disabled"
              onChange={internalChange}
              value={current}
              checked={checked}
              disabled={
                (disabled || altIsDisabled) ?? (status === Status.DISABLED || status === Status.PENDING)
              }
            />

            <Disabled />
            {checked && (
              <div className="input-status is-disabled">
                <Checkmark />
                <Disabled />
                <Pending />
                <Error />
              </div>
            )}
          </label>
        );
      })}

      {(hint || internalMessage) && (
        <div className="input-messages">
          <div className="input-hint">{hint}</div>
          <div className="input-message">{internalMessage}</div>
        </div>
      )}
    </div>
  );
}
