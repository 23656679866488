import { PackageId } from "../model/contract/contractType";
import { data } from "./data";
import { Country, UserId } from "../model/common/commonType";
import { Language } from "../i18n";

export enum LoginMethod {
  OTP = "OTP",
  AZURE = "AZURE",
  GOOGLE = "GOOGLE",
}

export enum Role {
  ADMIN = "ADMIN",
  SALES = "SALES",
  BACKOFFICE = "BACKOFFICE",
}

interface LoginResponse {
  requestId: string;
  loginType: LoginMethod;
}

export interface SessionResponse {
  userId: UserId;
  expiry: string; // datetime
  role: Role;
  name: string;
  email: string;
  canSwitch: boolean; //If the user can switch roles
  country?: Country; //Sales users och sales admins har country
  salesUser?: SalesSessionResponse;
  adminUser?: AdminSessionResponse;
  backOfficeUser?: BackofficeSessionResponse;
}

export interface SalesSessionResponse {
  teamId: string;
  teamName: string;
  teamMerchandiser?: string; //Oklart om den kommer användas, men den skulle kunna användas i complete steget
  enabledBundles: PackageId[];
  external: boolean;
}

export interface AdminSessionResponse {
  superAdmin: boolean;
  salesAdmin: boolean;
  pricingAdmin: boolean;
}

export interface BackofficeSessionResponse {}

export const dataAuth = {
  startLogin: (email: string, language?: Language) =>
    data.post<LoginResponse>("/api/login", {
      email,
      language,
    }),

  postOtp: (requestId: string, otp: string) =>
    data.post<SessionResponse>("/api/login/otp", {
      code: otp,
      requestId,
    }),
  getCurrentSession: () => data.get<SessionResponse>("/api/whoami", true),

  switchToAdmin: () => data.post<SessionResponse>("/api/sales/switch"),
  switchToSales: () => data.post<SessionResponse>("/api/admin/switch"),
};
