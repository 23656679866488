import { atom } from "recoil";
import { ContractId, Country } from "../model/common/commonType";
import { Contract, ContractStatus } from "../model/contract/contractType";

export interface ContractStatusState
  extends Pick<
    Contract,
    | "contractId"
    | "status"
    | "created"
    | "country"
    | "lastUpdated"
    | "completed"
    | "currentSalesUser"
    | "teamId"
    | "enabledFeatures"
  > {
  edit: boolean;
}

export const contractStatusState = atom<ContractStatusState>({
  key: "contract-status-state",
  default: {
    contractId: "" as ContractId,
    status: ContractStatus.PENDING_SALES_INPUT,
    created: new Date().toISOString(),
    country: Country.POLAND,
    edit: false,
    enabledFeatures: [],
  },
});
