import { Article, TerminalConfigurationOptions } from "../../data/dataTerminalPricing";
import { StoreId, TerminalId, Cas } from "../common/commonType";
import { Terminal, TerminalType } from "../contract/contractType";

export const getTerminalImageData = (
  terminalType: TerminalType
): { src: string; ratio: number; alt: string } => {
  switch (terminalType) {
    case TerminalType.DESK_3500:
      return {
        src: "/images/terminals/desk5000_510x510.png",
        ratio: 510 / 510,
        alt: `Image of ${terminalType}`,
      };
    case TerminalType.DESK_5000:
      return {
        src: "/images/terminals/desk5000_510x510.png",
        ratio: 510 / 510,
        alt: `Image of ${terminalType}`,
      };
    case TerminalType.MOVE_3500:
      return {
        src: "/images/terminals/move5000_510x510.png",
        ratio: 510 / 510,
        alt: `Image of ${terminalType}`,
      };
    case TerminalType.MOVE_5000:
      return {
        src: "/images/terminals/move5000_510x510.png",
        ratio: 510 / 510,
        alt: `Image of ${terminalType}`,
      };
    case TerminalType.LINK_2500:
      return {
        src: "/images/terminals/link2500_510x510.png",
        ratio: 510 / 510,
        alt: `Image of ${terminalType}`,
      };
    case TerminalType.SATURN:
      return {
        src: "/images/terminals/saturn_377x929.png",
        ratio: 377 / 929,
        alt: `Image of ${terminalType}`,
      };
    case TerminalType.SOFT_POS:
      return {
        src: "/images/terminals/softpos_239x499.png",
        ratio: 239 / 499,
        alt: `Image of ${terminalType}`,
      };
    case TerminalType.DX_8000:
      return {
        src: "/images/terminals/dx8000_240x554.png",
        ratio: 239 / 499,
        alt: `Image of ${terminalType}`,
      };
    case TerminalType.ECOMMERCE:
      return { src: "/images/terminals/ecom_344x450.png", ratio: 239 / 499, alt: `Image of ${terminalType}` };
  }
};

export const getTerminalEntityType = (terminalType: TerminalType) => {
  if (terminalType !== TerminalType.SOFT_POS) return "terminal";
  else return "license";
};

const terminalTypeOrder = [
  TerminalType.SOFT_POS,
  TerminalType.DX_8000,
  TerminalType.SATURN,
  TerminalType.LINK_2500,
  TerminalType.DESK_3500,
  TerminalType.DESK_5000,
  TerminalType.MOVE_3500,
  TerminalType.MOVE_5000,
];

export const sortTerminalsByType = (priceDetails: TerminalConfigurationOptions[]) => {
  return priceDetails.sort((a, b) => {
    const indexA = terminalTypeOrder.indexOf(a.terminalType);
    const indexB = terminalTypeOrder.indexOf(b.terminalType);
    return indexB - indexA;
  });
};

export type TerminalsByType = { terminalType: TerminalType; terminals: Terminal[] };

export function groupTerminalsByType(terminals: Terminal[]): TerminalsByType[] {
  const output: TerminalsByType[] = [];

  terminals.forEach((terminal) => {
    const isFound = output.find(({ terminalType }) => terminalType === terminal.terminalType);
    if (!isFound) {
      output.push({ terminalType: terminal.terminalType, terminals: [terminal] });
    } else {
      isFound.terminals.push(terminal);
    }
  });

  return output.sort((a, b) => {
    const indexA = terminalTypeOrder.indexOf(a.terminalType);
    const indexB = terminalTypeOrder.indexOf(b.terminalType);
    return indexB - indexA;
  });
}

export const generateNewTerminal = (
  storeId: StoreId,
  terminalType: TerminalType,
  articles?: Article[]
): Terminal => {
  return {
    id: 0 as TerminalId,
    storeId: storeId,
    cas: 0 as Cas,
    terminalType: terminalType,
    terminalClosure: "23:30",
    tip: false,
    bookAfterReservation: false,
    articles,
  };
};

const TERMINAL_TYPE_STRING_MAP: Record<TerminalType, string> = {
  [TerminalType.SOFT_POS]: "Worldline Tap on Mobile",
  [TerminalType.DESK_3500]: "Desk 3500",
  [TerminalType.MOVE_3500]: "Move 3500",
  [TerminalType.LINK_2500]: "Link 2500",
  [TerminalType.SATURN]: "Saturn",
  [TerminalType.DESK_5000]: "Desk 5000",
  [TerminalType.MOVE_5000]: "Move 5000",
  [TerminalType.DX_8000]: "DX 8000",
  [TerminalType.ECOMMERCE]: "E-commerce Gateway",
};

export const getTerminalTypeString = (terminalType: TerminalType, abbreviated: boolean = false) => {
  if (abbreviated) {
    switch (terminalType) {
      case TerminalType.DESK_3500:
        return "Desk";
      case TerminalType.SOFT_POS:
        return "WL ToM";
      case TerminalType.MOVE_3500:
        return "Move";
      default:
        return terminalType;
    }
  } else {
    const stringType = TERMINAL_TYPE_STRING_MAP[terminalType];
    return stringType ?? terminalType;
  }
};
