import { PricingInstore } from "./PricingInstore";
import useContractData from "../../../hooks/useContractData";
import { ScrollPositionAnchor } from "../../../components/scrollPosition/ScrollPositionAnchor";
import { LinkAnchors } from "../ContractEdit";
import { SectionFieldSetHeader } from "../../../components/sectionFieldSet/sectionFieldSetHeader";
import styles from "./AdvancedPricing.module.scss";
import { PricingEcom } from "./PricingEcom";
import { FormName } from "../menus/ContractEditMenu";

const AdvancedPricing = () => {
  const { isEcomContract, isInstoreContract } = useContractData();

  return (
    <div>
      <ScrollPositionAnchor id={LinkAnchors.PRICING_CALCULATOR.anchor} />
      <SectionFieldSetHeader headerTitle={LinkAnchors.PRICING_CALCULATOR.name} formName={FormName.STORE} />

      <div className={styles.sections}>
        {isInstoreContract && <PricingInstore />}
        {isEcomContract && <PricingEcom />}
      </div>
    </div>
  );
};

export default AdvancedPricing;
