import { FC } from "react";
import styles from "./PomcaFeeSummary.module.scss";
import { useRecoilValue } from "recoil";
import { contractInstoresSelector, contractTerminalsSelector } from "../../../../state/contractStoresState";
import { Store2, Terminal, TerminalType } from "../../../../model/contract/contractType";
import { Pomca } from "../../../../model/pricing/pricingTypes";
import { Language } from "../../../../i18n";
import { Currency } from "../../../../model/common/commonType";
import { getCurrencySymbol } from "../../../../components/utils";
import useGeneralData from "../../../../hooks/useGeneralData";
import { useTranslation } from "react-i18next";
import { getStoreAddress } from "../../../../model/contract/contractUtils";

interface Props {
  pomca: Pomca[];
}

type storePomca = {
  store: Store2;
  terminalAmount: number;
  fee: number;
};

const calculateMonthlyFee = (terminalAmount: number, pomca: Pomca[]): number => {
  const found = pomca.find((fee) => fee.terminalAmount === terminalAmount);
  return found?.fee ?? 0;
};

const groupStoresAndPomca = (stores: Store2[], pomca: Pomca[], terminals: Terminal[]): storePomca[] => {
  return stores.map((store) => {
    const terminalAmount = terminals.filter((terminal) => terminal.storeId === store.storeId).length;
    const fee = calculateMonthlyFee(terminalAmount, pomca);
    return { store: store, terminalAmount, fee };
  });
};

const calculateTotalFee = (storePomcas: storePomca[]) => {
  return storePomcas.reduce((acc, curr) => acc + curr.fee, 0);
};

const PomcaFeeSummary: FC<Props> = ({ pomca }) => {
  const { currency, language } = useGeneralData();
  const { t } = useTranslation();

  const stores = useRecoilValue(contractInstoresSelector);
  const hardTerminals = useRecoilValue(contractTerminalsSelector).filter(
    ({ terminalType }) => terminalType !== TerminalType.ECOMMERCE
  );

  const storePomcas = groupStoresAndPomca(stores, pomca, hardTerminals);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.header}>
          <h5 style={{ margin: 0 }}>{t("POMCA summary")}</h5>
        </div>
        <div className={styles.subheader}>
          <div>{t("Location")}</div>
          <div>{t("Terminals")}</div>
          <div>{t("Minimum monthly fee")}</div>
        </div>
        <div className={styles.body}>
          {storePomcas.map((storePomca, index) => (
            <StoreRow
              key={`${index}-pomca-store-row`}
              storePomca={storePomca}
              language={language}
              currency={currency}
            />
          ))}
        </div>
        <div className={styles.footer}>
          <div>{t("Total minimum monthly fee")}</div>
          <span></span>
          <div>{`${getCurrencySymbol(language, currency)} ${calculateTotalFee(storePomcas)}`}</div>
        </div>
      </div>
    </>
  );
};

interface StoreRowProps {
  storePomca: storePomca;
  language: Language;
  currency: Currency;
}

const StoreRow: FC<StoreRowProps> = ({ storePomca, language, currency }) => {
  const { store } = storePomca;

  return (
    <div className={styles.row}>
      <div className={styles.store}>
        <div className={styles.storeName}>
          {store.commercialName}
          <div className="fs-small text-passive">{getStoreAddress(store)}</div>
        </div>
      </div>
      <div>{storePomca.terminalAmount}</div>
      <div>{`${getCurrencySymbol(language, currency)} ${storePomca.fee}`}</div>
    </div>
  );
};

export default PomcaFeeSummary;
