import { API, ServerError } from "../network/API";
import { LOGOUT_ROUTE } from "../pages/Auth/Logout";

function actAndDelagate<T>(err: ServerError<T>) {
  if (err.status === 401) {
    const params = new URLSearchParams();
    const currentUrl = new URL(window.location.href);
    const url = `${currentUrl.pathname}${currentUrl.search}`;
    params.set("expired", url);
    window.location.href = `${LOGOUT_ROUTE}?${params}`;
  }

  return Promise.reject(err);
}

export const data = {
  get: <T>(url: string, refreshCache?: boolean, options?: RequestInit) => {
    return API.get<T>(url, refreshCache, options).catch(actAndDelagate);
  },

  post: <T>(url: string, body?: any, options?: RequestInit) => {
    return API.post<T>(url, body, options).catch(actAndDelagate);
  },

  delete: <T>(url: string, body?: any, options?: RequestInit) => {
    return API.delete<T>(url, body, options).catch(actAndDelagate);
  },
};
