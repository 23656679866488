import React from "react";
import { Link } from "../../components/links/Link";
import { DASHBOARD_ROUTE } from "../Dashboard/DashboardPage";
import { PageStripped } from "../Page/PageStripped";
import "./OfferingsPage.scss";

export const OFFERINGS_ROUTE = "/offerings";

export const OfferingsPage: React.FunctionComponent = () => {
  return (
    <PageStripped title="Offerings" className="offerings">
      <section>
        <article>
          Offerings implementation
          <p>
            <Link link={DASHBOARD_ROUTE}>Go back</Link>
          </p>
        </article>
      </section>
    </PageStripped>
  );
};
