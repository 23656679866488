import { useEffect, useState } from "react";
import { FieldSet } from "../../components/fieldSet/FieldSet";
import { Form } from "../../components/form/Form";
import { TextInput } from "../../components/form/TextInput";
import { EmailValidator } from "../../components/form/validators/EmailValidator";
import { MaxLengthValidator } from "../../components/form/validators/MaxLengthValidator";
import { RequiredValidator } from "../../components/form/validators/RequiredValidator";
import { Button } from "../../components/interactions/Buttons/Button";
import { Link } from "../../components/links/Link";
import { Table } from "../../components/table/Table";
import { dataPeriodicReport } from "../../data/dataPeriodicReport";
import { PageStripped } from "../Page/PageStripped";
import "./FeatureTogglePage.scss";

export const PERIODIC_REPORT_PAGE = "/secret/report";

export const PeriodicReportPage: React.FunctionComponent = () => {
  const [recipient, setRecipient] = useState<string>("");
  const [automaticRecipients, setAutomaticRecipients] = useState<string[]>([]);

  useEffect(() => {
    dataPeriodicReport.getRecipients().then(setAutomaticRecipients);
  }, []);

  const onSendTest = () => {
    dataPeriodicReport.sendTestReport([recipient]);
  };

  return (
    <PageStripped className="periodic-report-page" title="Periodic report">
      <section>
        <article>
          <div className="landscape-wrapper">
            <FieldSet header="Send periodic report" className="m-top-40">
              <div className="fs-small text-passive m-bottom-10">
                Send a one time report to the email address specified below.
              </div>
              <Form
                onSubmit={(_, form) => {
                  if (form.isInvalid) {
                    return;
                  }

                  onSendTest();
                }}
              >
                <TextInput
                  onChange={(value) => {
                    setRecipient(value);
                  }}
                  name="email"
                  label="Email"
                  value={recipient}
                  validators={[
                    new RequiredValidator("Email is required"),
                    new EmailValidator("Email is not valid"),
                    new MaxLengthValidator(50, "Email must be less than {{max}} characters"),
                  ]}
                />
                <Button block type="submit">
                  Send periodic report
                </Button>
              </Form>
            </FieldSet>
            <FieldSet header="Monthly report recipients" className="m-top-40">
              <Table>
                <thead>
                  <tr>
                    <th>Recipient</th>
                  </tr>
                </thead>
                <tbody>
                  {automaticRecipients.map((recipient) => (
                    <tr key={recipient}>
                      <td>{recipient}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </FieldSet>
            <p className="center">
              <Link className="m-top-20" link="/">
                Back to front
              </Link>
            </p>
          </div>
        </article>
      </section>
    </PageStripped>
  );
};
