import styles from "./TerminalPriceSelector.module.scss";
import { Dropdown } from "../../../../../../../components/interactions/Dropdown/Dropdown";
import { FC, useState, useCallback, useEffect } from "react";
import { TerminalType } from "../../../../../../../model/contract/contractType";
import { useRecoilState } from "recoil";
import {
  TerminalPricingState,
  terminalPricingState as terminalPricingRecoilState,
} from "../../../../../../../state/terminalPricingState";
import {
  TerminalConfigurationOptions,
  UpdateTerminalPricingDto,
} from "../../../../../../../data/dataTerminalPricing";
import { ContractId } from "../../../../../../../model/common/commonType";
import { dataTerminalPricing } from "../../../../../../../data/dataTerminalPricing";
import { Status } from "../../../../../../../data/types";
import { T } from "../../../../../../../components/translation/T";
import { getTerminalTypeString } from "../../../../../../../model/terminal/terminalUtils";

interface Props {
  terminalPriceDetails: TerminalConfigurationOptions;
  contractId: ContractId;
  isEditable: boolean;
  label?: string;
  isPomca: boolean;
}

export const TerminalPriceSelector: FC<Props> = ({
  terminalPriceDetails,
  contractId,
  isEditable,
  label = generateLabel(terminalPriceDetails.terminalType),
  isPomca,
}) => {
  const [terminalPricingState, setTerminalPricingState] = useRecoilState(terminalPricingRecoilState);
  const [dropdownStatus, setDropdownStatus] = useState(Status.DEFAULT);
  const { terminalType } = terminalPriceDetails;
  const terminalMonthlyFee = getMonthlyCostFromState(terminalType, terminalPricingState);

  const updateTerminalPricing = useCallback(
    (requestBody: UpdateTerminalPricingDto) => {
      dataTerminalPricing
        .updateMonthlyTerminalCost(contractId, terminalType, requestBody)
        .then((newPrice) => setTerminalPricingState((prev) => ({ ...prev, [terminalType]: newPrice })))
        .catch((err) => {
          console.log("Could not update terminal price: ", err);
          setDropdownStatus(Status.ERROR);
          setTimeout(() => setDropdownStatus(Status.DEFAULT), 4000);
        });
    },
    [contractId, setTerminalPricingState, terminalType]
  );

  useEffect(() => {
    // if pomca is enabled on price plan, terminal price should be 0
    if (isPomca && terminalMonthlyFee !== 0) {
      updateTerminalPricing({ monthlyCost: 0, oneTimeCost: 0 });
    }
  }, [isPomca, updateTerminalPricing, terminalMonthlyFee]);

  return (
    <div className={styles.root}>
      <Dropdown
        label={label}
        alternatives={generatePriceAlternatives(terminalPriceDetails)}
        value={terminalMonthlyFee}
        onChange={(value) => updateTerminalPricing({ monthlyCost: value, oneTimeCost: 0 })}
        status={isPomca ? Status.DISABLED : isEditable ? dropdownStatus : Status.DISABLED}
        message={dropdownStatus === Status.ERROR && <T>Could not update monthly cost</T>}
      />
    </div>
  );
};

const generatePriceAlternatives = (terminalPriceDetails: TerminalConfigurationOptions) =>
  terminalPriceDetails.availableMonthlyCosts
    .sort((a, b) => a - b)
    .map((monthlyCost) => ({
      key: monthlyCost,
      value: monthlyCost,
      text: `${monthlyCost.toFixed(2)}`,
    }));

const generateLabel = (terminalType: TerminalType): string => {
  const terminalString = getTerminalTypeString(terminalType);
  return `${terminalString}`;
};

const getMonthlyCostFromState = (terminalType: TerminalType, terminalPricingState: TerminalPricingState) => {
  return terminalPricingState[terminalType]?.monthlyCost;
};
