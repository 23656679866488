import React, { CSSProperties, FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Status } from "../../data/types";
import { Button } from "../interactions/Buttons/Button";
import { Spinner } from "../spinner/Spinner";
import styles from "./RetryPage.module.scss";
import { ArrowLeftIcon } from "../icons/ArrowLeftIcon";
import { MdOutlineErrorOutline } from "react-icons/md";

interface Props {
  retry?: () => void;
  children: React.ReactNode | string;
  status: Status;
  errorMessage?: string;
  showBackToDashboard?: boolean;
  errorClassName?: string;
  errorStyle?: CSSProperties;
}

export const Retry: FC<Props> = ({
  retry,
  children,
  status,
  errorMessage,
  showBackToDashboard = false,
  errorClassName,
  errorStyle,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onRetry = useCallback(() => {
    if (retry) {
      retry();
    }
  }, [retry]);

  const backToDashboard = () => {
    history.push("/");
  };

  if (status === Status.PENDING) {
    return (
      <div className="retry-pending center">
        <Spinner />
      </div>
    );
  }

  if (status === Status.ERROR) {
    return (
      <div className={`${styles.error} ${errorClassName}`} style={errorStyle}>
        <div className={styles.error_box}>
          <h4 style={{ margin: 0 }}>
            <MdOutlineErrorOutline />
          </h4>
          {errorMessage ? errorMessage : t("Could not load content")}
        </div>

        <div className={styles.actions}>
          {showBackToDashboard && (
            <Button ghost onClick={backToDashboard}>
              <ArrowLeftIcon style={{ marginRight: 10, fontSize: "1.2em" }} />
              {t("Back to dashboard")}
            </Button>
          )}

          {retry && <Button onClick={onRetry}>{t("Retry")}</Button>}
        </div>
      </div>
    );
  }

  return <>{children}</>;
};
