import React, { useRef } from "react";
import { BankAccount, BankAccountSource, MainContractData } from "../../../../model/contract/contractType";
import { Associate } from "../../../../model/associate/associateTypes";
import { useRecoilValue } from "recoil";
import { contractBankState } from "../../../../state/contractBankState";
import { ApiConfirmed } from "./ApiConfirmed";
import { PsdConfirmed } from "./PsdConfirmed";
import { BankStatementConfirmed } from "./BankStatementConfirmed";
import { BankConfirmed } from "./BankConfirmed";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/interactions/Buttons/Button";
import "./ConfirmBank.scss";

interface Props {
  onClose: () => void;
  open: boolean;
}

export function getName(associate: Associate) {
  if (!associate.firstName && !associate.lastName) {
    return "";
  }

  if (associate.firstName && associate.lastName) {
    return `${associate.firstName} ${associate.lastName}`;
  }

  if (associate.firstName) {
    return associate.firstName;
  }

  return associate.lastName;
}

export function getHolderName(mainData: MainContractData, associates: Associate[], holder?: string) {
  if (holder === "COMPANY") {
    return mainData.companyName;
  }

  const associate = associates.find((item) => item.associateId === holder);

  if (associate) {
    return getName(associate);
  }

  return "";
}

export const ConfirmBank: React.FunctionComponent<Props> = ({ onClose, open }) => {
  const bankAccount = useRef<BankAccount>(useRecoilValue(contractBankState));
  const { t } = useTranslation();

  if (bankAccount.current.signedOff) {
    return <BankConfirmed />;
  }

  if (bankAccount.current.source === BankAccountSource.OFFICIAL_REGISTRY) {
    return <ApiConfirmed />;
  }

  if (bankAccount.current.source === BankAccountSource.PSD_LOOKUP) {
    return <PsdConfirmed onClose={onClose} open={open} />;
  }

  if (bankAccount.current.statementUploaded) {
    return <BankStatementConfirmed />;
  }

  return (
    <div className="confirm-bank">
      <h4>{t("Bank account")}</h4>
      <p className="confirm-bank-text">{t("The bank account has not yet been added.")}</p>

      <div className="confirm-bank-close-button">
        <Button className="button-variant-secondary" block onClick={onClose}>
          {t("Close")}
        </Button>
      </div>
    </div>
  );
};
