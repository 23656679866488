import { BaseValidator } from "./BaseValidator";
import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { validateNIP } from "../../CompanyIdUtils";

export class NipValidator extends BaseValidator {
  name = "NIP validator";

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (validateNIP(value)) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
          hideError: value.replaceAll("-", "").length < 10,
        });
      }
    });
  }
}
