import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { SetterOrUpdater } from "recoil";
import { WarningBox } from "../../../../../components/boxes/WarningBox";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { PricingModel, PricingTemplate } from "../../../../../model/pricing/pricingTypes";
import {
  convertTemplateToPricingStructure,
  getPricingModelDisplayName,
} from "../../../../../model/pricing/pricingUtils";
import { Status } from "../../../../../data/types";
import styles from "./SelectedTemplateNotFound.module.scss";
import { ContractPricingState } from "../../../../../state/contractCostState";
import { ContractType } from "../../../../../model/contract/contractType";

interface Props {
  pricingStructureId: number;
  contractTemplateId: number;
  edit: boolean;
  templateMap: Record<PricingModel, PricingTemplate[]>;
  setContractPricing: SetterOrUpdater<ContractPricingState>;
  templateName: string;
  contractTypeTemplatesToLoad: ContractType;
}

export const SelectedTemplateNotFound: FC<Props> = ({
  contractTemplateId,
  edit,
  templateMap,
  setContractPricing,
  templateName,
  contractTypeTemplatesToLoad,
  pricingStructureId,
}) => {
  const { t } = useTranslation();

  const handleOnClick = (model: PricingModel) => {
    const allModelTemplates = templateMap[model];
    const newTemplate = allModelTemplates[0];
    const newPricingStructure = convertTemplateToPricingStructure(pricingStructureId, newTemplate);

    setContractPricing((prev) => ({
      ...prev,
      [contractTypeTemplatesToLoad]: {
        ...newPricingStructure,
        cas: prev[contractTypeTemplatesToLoad].cas,
      },
    }));
  };

  return (
    <div className="m-bottom-20">
      <WarningBox relative>
        {t(
          "The Price Template ({{pricePlan}}) you used for this contract is no longer available. You can still continue to use it or you can switch to other available templates",
          {
            pricePlan: templateName ?? contractTemplateId,
          }
        )}

        <div className="m-top-20">
          <Trans>You can switch to the following price models:</Trans>
        </div>

        <div className={styles.buttonsGrid}>
          {Object.entries(templateMap).map(([model, templates]) => {
            if (templates.length < 1) return null;
            return (
              <div className={styles.button}>
                <Button
                  className="small"
                  block
                  status={edit ? Status.DEFAULT : Status.DISABLED}
                  onClick={() => handleOnClick(model as PricingModel)}
                >
                  <Trans>{getPricingModelDisplayName(model as PricingModel)}</Trans>
                </Button>
              </div>
            );
          })}
        </div>
      </WarningBox>
    </div>
  );
};
