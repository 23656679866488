import React, { FC } from "react";
import styles from "./AssociateReview.module.scss";
import { useTranslation } from "react-i18next";
import cx from "classnames";

interface Props {
  isComplete: boolean;
}

const ReviewStatus: FC<Props> = ({ isComplete }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx([styles.reviewStatus], {
        [styles.statusCompleted]: isComplete,
        [styles.statusWaiting]: !isComplete,
      })}
    >
      {isComplete ? t("Review completed") : "Awaiting review"}
    </div>
  );
};

export default ReviewStatus;
