import React from "react";
import { Trans, useTranslation } from "react-i18next";
import "./IcppDescription.scss";

export function IcppDescription() {
  const { t } = useTranslation();

  return (
    <div className="icpp-description">
      <div className="icpp-desc-table ">
        <div className="icpp-desc-item small">
          <div className="center">
            <b>{t("Interchange fees")}</b> - <span>{t("Dynamic")}</span>
          </div>
          <div>
            <Trans>
              The fees charged to the Merchant correspond to the actual fees
              levied by the card schemes on behalf of the card issuers.
            </Trans>
          </div>
        </div>
        <div className="center">+</div>
        <div className="icpp-desc-item small">
          <div className="center">
            <b>{t("Card scheme fees")}</b> - <span>{t("Dynamic")}</span>
          </div>
          <div>
            <Trans>
              The fees charged to the Merchant correspond to the actual fees
              levied by the card schemes.
            </Trans>
          </div>
        </div>
        <div className="center">+</div>
        <div className="icpp-desc-item small">
          <div className="center">
            <b>{t("Acquiring fees")}</b> -{" "}
            <span>{t("Fixed, in % and/or EUR")}</span>
          </div>
          <div>
            <Trans>
              The fees charged to the Merchant cover the costs for transaction
              processing and settlement, fraud monitoring and customer service.
            </Trans>
          </div>
        </div>
      </div>
    </div>
  );
}
