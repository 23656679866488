import React from "react";
import { CopyFrom, COPY_TYPE } from "./CopyFrom";
import { Address, Contact, IdCard } from "../../model/contract/contractType";
import "./HeaderWithCopy.scss";
import { Associate } from "../../model/associate/associateTypes";
import { Status } from "../../data/types";

type HeaderType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "strong";
interface Props {
  header: string;
  headerType?: HeaderType;
  type: COPY_TYPE;
  onChange: (type: COPY_TYPE, data: Address | Contact | IdCard) => void;
  associate?: Associate;
  status?: Status;
  className?: string;
}

export const HeaderWithCopy: React.FunctionComponent<Props> = ({
  type,
  onChange,
  header,
  status,
  className,
  associate,
  headerType = "strong",
}) => {
  return (
    <div className="header-with-copy tablet-columns">
      <div>{getHeader(headerType, header)}</div>
      <div>
        <CopyFrom
          onChange={onChange}
          type={type}
          status={status}
          className={className}
          associate={associate}
        />
      </div>
    </div>
  );
};

export const getHeader = (type: HeaderType, header: string) => {
  switch (type) {
    case "h1":
      return <h1 className="header-with-copy-title">{header}</h1>;
    case "h2":
      return <h2 className="header-with-copy-title">{header}</h2>;
    case "h3":
      return <h3 className="header-with-copy-title">{header}</h3>;
    case "h4":
      return <h4 className="header-with-copy-title">{header}</h4>;
    case "h5":
      return <h5 className="header-with-copy-title">{header}</h5>;
    case "h6":
      return <h6 className="header-with-copy-title">{header}</h6>;
    case "strong":
      return <strong>{header}</strong>;
    default:
      return <strong>{header}</strong>;
  }
};
