import { RefObject, useEffect, useRef, useState, VoidFunctionComponent } from "react";
import { FormContainer } from "../../../components/form/Form";
import { id } from "../../../components/utils";
import { Associate, AssociateRole } from "../../../model/associate/associateTypes";
import { Validity } from "../../../model/contract/contractType";
import { Status } from "../../../data/types";
import { FormName } from "../menus/ContractEditMenu";
import AssociateRowAndOverlay from "../../../components/associate/AssociateRowAndOverlay/AssociateRowAndOverlay";
import { AssociateType } from "../../../components/associate/AssociateRowAndOverlay/UpdateAssociate";

interface Props {
  associate: Associate;
  onToggle: (selected: boolean, toggledAssociate: Associate) => void;
  status: Status;
  initialOpen?: boolean;
  scrollToRef?: RefObject<HTMLElement>;
}

export const OwnersOverlay: VoidFunctionComponent<Props> = ({
  associate,
  onToggle,
  status,
  initialOpen = false,
  scrollToRef,
}) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const formContainer = useRef<FormContainer>();
  const isOwner = associate.roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1;
  const formId = useRef(id());
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const onUpdate = () => {
      if (formContainer.current && isFormValid !== formContainer.current.isValid) {
        setIsFormValid(formContainer.current.isValid);
      }
    };

    const container = formContainer.current;
    container?.addListener(onUpdate);
    return () => {
      container?.removeListener(onUpdate);
    };
  }, [isFormValid]);

  return (
    <AssociateRowAndOverlay
      associate={associate}
      status={status}
      toggled={isOwner}
      onToggle={onToggle}
      overlayOpen={isOpen}
      setOverlayOpen={setIsOpen}
      validity={isOwner ? (formContainer.current?.isValid ? Validity.VALID : Validity.MISSING) : undefined}
      formContainer={formContainer}
      formId={formId}
      scrollToRef={scrollToRef}
      formName={`${FormName.OWNER}-${formId.current}`}
      type={AssociateType.OWNER}
    />
  );
};
