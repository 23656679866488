import React, { useMemo } from "react";
import { userState } from "../../../state/userState";
import { useRecoilValue } from "recoil";
import { PricingHR } from "./PricingHR";
import { PricingPL } from "./PricingPL";
import { Country } from "../../../model/common/commonType";
import "./Pricing.scss";
import { PricingCZ } from "./PricingCZ";
import { ScrollPositionAnchor } from "../../../components/scrollPosition/ScrollPositionAnchor";
import { LinkAnchors } from "../ContractEdit";
import { SectionFieldSet } from "../../../components/sectionFieldSet/SectionFieldSet";
import { FormName } from "../menus/ContractEditMenu";

const pricingByCountry: Record<Country, React.ReactNode> = {
  [Country.POLAND]: <PricingPL />,
  [Country.CROATIA]: <PricingHR />,
  [Country.CZECHIA]: <PricingCZ />,
};

export function Pricing() {
  const user = useRecoilValue(userState);

  const elem = useMemo(() => {
    if (!user?.country) {
      return null;
    }
    return pricingByCountry[user.country];
  }, [user?.country]);

  if (!user?.country) {
    return null;
  }

  return (
    <div className="pricing">
      <ScrollPositionAnchor id={LinkAnchors.PRICING.anchor} />

      <SectionFieldSet headerTitle={LinkAnchors.PRICING.name} formName={FormName.STORE} collapsible>
        {elem}
      </SectionFieldSet>
    </div>
  );
}
