import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Checkboxes } from "../../../../components/interactions/Checkboxes/Checkboxes";
import { PricingModel } from "../../../../model/pricing/pricingTypes";
import { getIntlNumberFormat } from "../views/FeeInput";
import { HighlighBoxStyleProps } from "./AdditionalSettings";

import { SetEditablePricing } from "../../../Admin/Pricing/AdminPricingCountryPage/AdminPresetPage/PricingInputs/PricingInputs";
import useGeneralData from "../../../../hooks/useGeneralData";
import { PRE_AUTHORIZATION_FEES } from "../../../../model/pricing/pricingUtils";

interface Props extends HighlighBoxStyleProps {
  disabled: boolean;
  preAuthorizationFees: boolean;
  pricingModel: PricingModel;
  setEditablePricing?: SetEditablePricing;
  isSalesComponent?: boolean;
}

const APPLIED_BRANDS = "Mastercard, Maestro, DebitMastercard";

export const AuthorizationFees: FC<Props> = ({
  setEditablePricing,
  disabled,
  preAuthorizationFees,
  pricingModel,
  isSalesComponent = false,
}) => {
  const { t } = useTranslation();
  const { language } = useGeneralData();

  const onChangeInput = () => {
    if (!setEditablePricing || disabled) return;
    setEditablePricing((prev) => ({
      ...prev,
      preAuthorizationFees: !prev.preAuthorizationFees,
    }));
  };

  return (
    <>
      <Checkboxes
        className="m-bottom-30"
        disabled={disabled}
        onChange={onChangeInput}
        values={[pricingModel === PricingModel.BLENDED && preAuthorizationFees ? "preAuthFee" : ""]}
        alternatives={[
          {
            text: t("Apply pre-authorization fees"),
            value: "preAuthFee",
          },
        ]}
      />
      <dl className={isSalesComponent ? "text-passive" : ""} style={{ padding: "0 10px" }}>
        <dt>
          <div className="fw-500 ">{t("Integrity fee for final authorizations")}</div>
          <div className="fs-small">{APPLIED_BRANDS}</div>
        </dt>
        <dd className="fw-500">
          {getIntlNumberFormat(language, PRE_AUTHORIZATION_FEES.preAuthorizationFeeIntegrity, 1)}%
        </dd>

        <dt>
          <div className="fw-500 ">{t("Pre-authorization fee")}</div>
          <div className="fs-small">{APPLIED_BRANDS}</div>
        </dt>
        <dd className="fw-500">
          {getIntlNumberFormat(language, PRE_AUTHORIZATION_FEES.preAuthorizationFee, 1)}%
        </dd>
        <dt>
          <div className="fw-500 ">{t("Final authorization fee")} </div>
          <div className="fs-small">{APPLIED_BRANDS}</div>
        </dt>
        <dd className="fw-500">
          {getIntlNumberFormat(language, PRE_AUTHORIZATION_FEES.preAuthorizationFeeFinal, 1)}%
        </dd>
      </dl>
    </>
  );
};
