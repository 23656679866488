import { FC } from "react";
import { generatePath, useHistory } from "react-router";
import { Button } from "../../../../../../components/interactions/Buttons/Button";
import { Status } from "../../../../../../data/types";
import { ADMIN_PRICING_COUNTRY_PAGE } from "../../AdminPricingCountryPage";
import { Country } from "../../../../../../model/common/commonType";
import styles from "./AdminPageActions.module.scss";
import { ArrowLeftIcon } from "../../../../../../components/icons/ArrowLeftIcon";

interface Props {
  status: Status;
  buttonIsDisabled: boolean;
  country: Country;
}

export const AdminPageActions: FC<Props> = ({ status, buttonIsDisabled, country }) => {
  const history = useHistory();

  return (
    <div className={styles.root}>
      <div className={styles.buttons}>
        <div>
          <Button
            type="button"
            ghost
            block
            onClick={() =>
              history.push(
                generatePath(ADMIN_PRICING_COUNTRY_PAGE, {
                  country,
                })
              )
            }
          >
            <ArrowLeftIcon style={{ marginRight: 8 }} /> Back
          </Button>
        </div>
        <div>
          <Button block type="submit" status={buttonIsDisabled ? Status.DISABLED : status}>
            Save changes
          </Button>
        </div>
      </div>
    </div>
  );
};
