import React, { useCallback } from "react";
import cx from "classnames";
import { Status } from "../../../../data/types";
import { ContractDocument, Validity } from "../../../../model/contract/contractType";
import { documentTypeDisplay } from "../../../../model/contract/contractUtils";
import { Beacon } from "../../../../components/beacon/Beacon";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { EditIcon } from "../../../../components/icons/EditIcon";
import { EyeIcon } from "../../../../components/icons/EyeIcon";

interface Props {
  document: ContractDocument;
  active: boolean;
  buttonStatus: Status;
  onConfirmDoc: (doc: ContractDocument) => void;
}

export const EventDocument: React.FunctionComponent<Props> = ({
  active,
  document,
  onConfirmDoc,
  buttonStatus,
}) => {
  const { t } = useTranslation();

  const onConfirm = useCallback(() => onConfirmDoc(document), [onConfirmDoc, document]);

  let status = Status.DEFAULT;
  if (!document.confirmed) {
    status = active ? buttonStatus : Status.DISABLED;
  }

  return (
    <li className={cx("timeline-event-document", { active })}>
      <Button className="event-button event-button--absolute small" onClick={onConfirm} status={status}>
        {document.confirmed ? (
          <>
            <EyeIcon /> {t("View")}
          </>
        ) : (
          <>
            <EditIcon /> {t("Confirm")}
          </>
        )}
      </Button>
      <div>
        <div className="truncated" style={{ marginBottom: 5 }}>
          <b>{t(documentTypeDisplay(document.documentType))},</b>{" "}
          <span className="timeline-item-type">{t("Document")}</span>
        </div>
        <div className={cx("additional-event-info", "fs-small")}>
          <Beacon mini className="aligned" validity={document.uploaded ? Validity.VALID : Validity.MISSING} />
          <span className="additional-event-passive-text">{t("Merchant uploaded")}</span>
        </div>
        <div className={cx("additional-event-info", "fs-small")}>
          <Beacon
            mini
            className="aligned"
            validity={document.confirmed ? Validity.VALID : Validity.MISSING}
          />
          <span className="additional-event-passive-text">{t("Sales confirmation")}</span>
        </div>
      </div>
    </li>
  );
};
