import React, { FC } from "react";
import { Image } from "../../images/Image";
import styles from "./FileUploadImage.module.scss";

import { FiUpload } from "react-icons/fi";
import { Status } from "../../../data/types";
import cx from "classnames";
import { ErrorOutline } from "../../icons/ErrorOutline";
import { Pending } from "../../icons/Pending";
import { CheckCircleOutline } from "../../icons/CheckCircleOutline";

interface Props {
  status?: Status;
}

const FileUploadImage: FC<Props> = ({ status = Status.DEFAULT }) => {
  return (
    <div className={styles.root}>
      <div
        className={cx(styles.icon, {
          [styles.is_pending]: status === Status.PENDING,
          [styles.is_error]: status === Status.ERROR,
          [styles.is_success]: status === Status.SUCCESS,
        })}
      >
        {status === Status.PENDING && <Pending />}
        {status === Status.DEFAULT && <FiUpload />}
        {status === Status.ERROR && <ErrorOutline />}
        {status === Status.SUCCESS && <CheckCircleOutline />}
      </div>

      <Image
        src="/images/generic_file_512x512.png"
        ratio={512 / 512}
        alt="generic file"
        className={styles.image}
      />
    </div>
  );
};

export default FileUploadImage;
