import { useState } from "react";
import styles from "./EcomTerminalSummary.module.scss";
import { NewImage } from "../../../../../../components/images/NewImage";
import { getTerminalImageData, getTerminalTypeString } from "../../../../../../model/terminal/terminalUtils";
import { TerminalType } from "../../../../../../model/contract/contractType";
import { useTranslation } from "react-i18next";
import { Fee } from "../../../../../../components/fee/Fee";
import i18n, { defaultCurrencyFromCountry } from "../../../../../../i18n";
import { useRecoilValue } from "recoil";
import { contractStatusState } from "../../../../../../state/contractStatusState";
import { Button } from "../../../../../../components/interactions/Buttons/Button";
import { Expand } from "../../../../../../components/icons/Expand";
import { motion } from "framer-motion";
import { AnimateHeightMotion } from "../../../../../../components/animate/AnimateHeightMotion";

const terminalType = TerminalType.ECOMMERCE;
const EcomTerminalSummary = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { country } = useRecoilValue(contractStatusState);
  const currency = defaultCurrencyFromCountry(country);

  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        <h5 style={{ margin: 0 }}>{t("Acceptance summary")}</h5>
      </div>

      <div className={styles.terminal_summary}>
        <div className={styles.image}>
          <NewImage {...getTerminalImageData(terminalType)} />
        </div>
        <div className={styles.content}>
          <div className={styles.first_row}>
            <div className="fw-600">{getTerminalTypeString(terminalType)}</div>
            <h3 className={styles.amount}>&#215; 1</h3>
          </div>
          <div className={styles.second_row}>
            <div className={styles.fee}>
              <Fee lang={i18n.language} cost={30} currency={currency} extension={t("month")} decimals={2} />
            </div>
          </div>
          <div className={styles.third_row}>
            <Button variant="text" onClick={() => setIsOpen(!isOpen)} block className={styles.button}>
              {t("Configuration")}{" "}
              <motion.div animate={{ rotate: isOpen ? 180 : 0 }} className={styles.icon}>
                <Expand style={{ margin: 0 }} />
              </motion.div>
            </Button>
            <AnimateHeightMotion presence>
              {isOpen && <div className="fw-500 m-top-10">Configuration options to be added</div>}
            </AnimateHeightMotion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcomTerminalSummary;
