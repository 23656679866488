import { useState, FC, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ButtonToggle } from "../../../../../components/form/ButtonToggle";
import { ZoomableImage } from "../../../../../components/images/ZoomableImage";
import { Button } from "../../../../../components/interactions/Buttons/Button";
import { getDynamicLink } from "../../../../../components/utils";
import { dataConfirm, MerchantAssociateResponse } from "../../../../../data/dataConfirm";
import { AssociateId, ContractId } from "../../../../../model/common/commonType";
import { MERCHANT_ROUTE } from "../../../MerchantStatusPage";
import { ImageToggler } from "../../components/ImageToggler/ImageToggler";
import { SecurityMarkHelpModal } from "./SecurityMarkHelpModal";
import styles from "../../AssociateReview.module.scss";
import ContentBox from "../../../../../components/ContentBox/ContentBox";
import ReviewStatus from "../../ReviewStatus";

export interface Props {
  contractId: ContractId;
  associateId: AssociateId;
  merchantAssociate: MerchantAssociateResponse;
  completed: () => void;
  isCompleted: boolean;
}

export const AssociateIdCardReview: FC<Props> = ({
  contractId,
  associateId,
  merchantAssociate,
  completed,
  isCompleted,
}) => {
  const { t } = useTranslation();
  const [startedReview] = useState<Date>(new Date());
  const [isFullIdCard, setIsFullIdCard] = useState<boolean>();
  const [isClear, setIsClear] = useState<boolean>();
  const [isFaceClear, setIsFaceClear] = useState<boolean>();
  const [hasSecurityMark, setHasSecurityMark] = useState<boolean>();

  const history = useHistory();

  const signoffIdCard = useCallback(() => {
    const reviewInSeconds = Math.abs((startedReview.getTime() - new Date().getTime()) / 1000);

    dataConfirm.signOffIddocument(contractId, associateId, reviewInSeconds).then(() => {
      completed();
    });
  }, [contractId, associateId, completed, startedReview]);

  const reject = () => {
    dataConfirm.rejectIdDocument(contractId, associateId).then(() => {
      history.replace(getDynamicLink(MERCHANT_ROUTE, { id: contractId }));
    });
  };

  const isIdCard = merchantAssociate.identityDocument.idFront !== undefined;

  const common = isClear === true && isFaceClear === true && hasSecurityMark === true;

  const enabledContinue = isIdCard ? isFullIdCard === true && common : common;

  const showRejectButton =
    isFullIdCard === false || isClear === false || isFaceClear === false || hasSecurityMark === false;

  return (
    <div className={styles.view}>
      <ReviewStatus isComplete={isCompleted} />

      <ContentBox colorStyle={isCompleted ? "completed" : "default"}>
        <div className={styles.columns}>
          <div className={styles.rightColumn}>
            <h4 className={styles.title}>{t("ID")}</h4>
            <IdImages merchantAssociate={merchantAssociate} />
          </div>
          <div className={styles.questionsColumn}>
            <h4 className={styles.title}>{t("Review questions")}</h4>
            <div className={styles.questions}>
              {isIdCard && (
                <ButtonToggle
                  label={t("Does the pictures contain both sides of a National ID Card?")}
                  helpText={t("A drivings license is NOT allowed to be used and should be rejected")}
                  positiveText={t("Yes")}
                  negativeText={t("No")}
                  value={isCompleted ? true : isFullIdCard}
                  onChange={setIsFullIdCard}
                />
              )}
              <ButtonToggle
                label={t("Are the pictures clear and show the full id-card?")}
                helpText={t(
                  "The text should be clearly readable and no part of the ID card must be obstructed"
                )}
                positiveText={t("Yes")}
                negativeText={t("No")}
                value={isCompleted ? true : isClear}
                onChange={setIsClear}
              />
              <ButtonToggle
                label={t("Is the face clearly visable?")}
                helpText={t("The face should not be obscured by light reflections or blurry")}
                positiveText={t("Yes")}
                negativeText={t("No")}
                value={isCompleted ? true : isFaceClear}
                onChange={setIsFaceClear}
              />
              <ButtonToggle
                label={
                  <div className={styles.securityMarkModal}>
                    <Trans>Are the security marks clearly visible?</Trans>
                    <SecurityMarkHelpModal />
                  </div>
                }
                helpText={t(
                  "The security marks can be a foil icon, usally a national logo. There can also be a second picture of the face in a plastic window"
                )}
                positiveText={t("Yes")}
                negativeText={t("No")}
                value={isCompleted ? true : hasSecurityMark}
                onChange={setHasSecurityMark}
              />
            </div>
            <div className={styles.actions}>
              {enabledContinue && !isCompleted && (
                <div className="m-top-40">
                  <Button onClick={signoffIdCard} block>
                    <Trans>Continue</Trans>
                  </Button>
                </div>
              )}
              {showRejectButton && !isCompleted && (
                <div className="m-top-40">
                  <Button onClick={reject} block className="danger-button">
                    <Trans>Reject Uploaded ID</Trans>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </ContentBox>
    </div>
  );
};

interface IdImagesProps {
  merchantAssociate: MerchantAssociateResponse;
}

const IdImages: FC<IdImagesProps> = ({ merchantAssociate }) => {
  const { identityDocument } = merchantAssociate;

  if (identityDocument.passport) {
    return (
      <div className={styles.passport}>
        <ZoomableImage image={identityDocument.passport} alt="Passport" />
      </div>
    );
  }

  if (identityDocument.idFront && identityDocument.idBack) {
    return <ImageToggler images={[identityDocument.idFront, identityDocument.idBack]} />;
  }

  return null;
};
