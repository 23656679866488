import React, { RefObject, useCallback, useMemo } from "react";
import cx from "classnames";
import { Associate } from "../../model/associate/associateTypes";
import { useTranslation } from "react-i18next";
import { TextInput } from "../form/TextInput";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { MaxLengthValidator } from "../form/validators/MaxLengthValidator";
import { Select } from "../form/Select";
import { getCountryOpts } from "../utils";
import { Language } from "../../i18n";
import { NoLetterValidator } from "../form/validators/NoLetterValidator";
import { Address as AddressInterface } from "../../model/contract/contractType";

export type AddressRequiredFields = {
  [K in keyof AddressInterface]: boolean;
};

interface Props {
  associate: Associate;
  onChange: (associate: Associate, field: string, ev?: InputEvent) => void;
  columnClass?: string;
  disabled?: boolean;
  addressRequiredFields: AddressRequiredFields;
  scrollToRef?: RefObject<HTMLElement>;
}

export const AddressFields: React.FunctionComponent<Props> = ({
  associate,
  onChange,
  columnClass,
  disabled = false,
  addressRequiredFields,
  scrollToRef,
}) => {
  const { t, i18n } = useTranslation();
  const onUpdate = useCallback(
    (value, name, ev) => {
      const update: any = { ...associate.address };
      if (typeof value === "string") {
        update[name as keyof Associate] = value;
      } else {
        update[name as keyof Associate] = value.value;
      }
      onChange(
        {
          ...associate,
          address: update,
        },
        name,
        ev
      );
    },
    [associate, onChange]
  );

  const countryOpts = useMemo(() => {
    return getCountryOpts(i18n.language as Language, t);
  }, [i18n.language, t]);

  return (
    <>
      <div className={cx("address-input", columnClass)}>
        <TextInput
          onChange={onUpdate}
          name="street"
          label={t("Street")}
          value={associate.address?.street}
          validators={[
            ...(addressRequiredFields.street ? [new RequiredValidator(t("Street is required"))] : []),
            new MaxLengthValidator(
              42,
              t("Street/no cannot be longer than {{max}} characters", {
                max: 42,
              })
            ),
          ]}
          disabled={disabled}
          scrollToRef={scrollToRef}
        />
      </div>
      <div className={cx("address-input", columnClass)}>
        <TextInput
          onChange={onUpdate}
          name="streetNumber"
          label={t("Street number")}
          value={associate.address?.streetNumber}
          validators={[
            ...(addressRequiredFields.streetNumber
              ? [new RequiredValidator(t("Street number is required"))]
              : []),
            new MaxLengthValidator(
              12,
              t("Street number cannot be longer than {{max}} characters", {
                max: 12,
              })
            ),
          ]}
          disabled={disabled}
          scrollToRef={scrollToRef}
        />
      </div>

      <div className={cx("address-input", columnClass)}>
        <TextInput
          onChange={onUpdate}
          name="postalCode"
          label={t("Postal code")}
          value={associate.address?.postalCode}
          validators={[
            ...(addressRequiredFields.postalCode
              ? [new RequiredValidator(t("Postal code is required"))]
              : []),
            new MaxLengthValidator(
              10,
              t("Postal code cannot be longer than {{min}} characters", {
                min: 10,
              })
            ),
            new NoLetterValidator(t("Postal code must not contain letters")),
          ]}
          disabled={disabled}
          scrollToRef={scrollToRef}
        />
      </div>
      <div className={cx("address-input", columnClass)}>
        <TextInput
          onChange={onUpdate}
          name="city"
          label={t("City")}
          value={associate.address?.city}
          validators={[
            ...(addressRequiredFields.city ? [new RequiredValidator(t("City is required"))] : []),
            new MaxLengthValidator(
              40,
              t("City name cannot be longer than {{max}} characters", {
                max: 40,
              })
            ),
          ]}
          disabled={disabled}
          scrollToRef={scrollToRef}
        />
      </div>
      <div className={cx("address-input", columnClass)}>
        <Select
          onChange={onUpdate}
          name="countryCode"
          label={t("Country")}
          alternatives={countryOpts}
          value={associate.address?.countryCode}
          validators={
            addressRequiredFields.countryCode ? [new RequiredValidator(t("Country is required"))] : []
          }
          disabled={disabled}
          scrollToRef={scrollToRef}
        />
      </div>
    </>
  );
};
