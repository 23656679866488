import { CSSProperties } from "react";

interface Props {
  className?: string;
  style?: CSSProperties;
}

export const CheckIcon = ({ className, style }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.2em"
      height="1.2em"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
      style={style}
    >
      <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
  );
};
