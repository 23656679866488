import { ReactNode, useCallback, useState, VoidFunctionComponent } from "react";

import "./BackofficeUser.scss";
import { LoginMethod } from "../../data/dataAuth";
import { NewBackofficeUsersRequest, userAdministration } from "../../data/UserAdministration";
import { Status } from "../../data/types";
import { NewOverlay } from "../../components/overlay/NewOverlay";
import { Input } from "../../components/interactions/Input/Input";
import { Dropdown } from "../../components/interactions/Dropdown/Dropdown";
import { Country } from "../../model/common/commonType";
import { Button } from "../../components/interactions/Buttons/Button";
import { AddCircleOutline } from "../../components/icons/AddCircleOutline";
import styles from "./AddBackofficeUserOverlay.module.scss";

const DEFAULT_ADD_USER = () => {
  return {
    email: "",
    name: "",
    loginMethod: LoginMethod.OTP,
    number: "",
    country: Country.CROATIA,
  } as NewBackofficeUsersRequest;
};

interface Props {
  refresh: () => void;
}

export const AddNewBackofficeUserOverlay: VoidFunctionComponent<Props> = ({ refresh }) => {
  const [open, setOpen] = useState<boolean>(false);
  const onOpen = useCallback(() => {
    setNewBackofficeUserRequest(DEFAULT_ADD_USER());
    setNameStatus(Status.DEFAULT);
    setEmailStatus(Status.DEFAULT);
    setEmailError("");
    setOpen(true);
  }, []);
  const onClose = useCallback(() => setOpen(false), []);

  const [nameStatus, setNameStatus] = useState(Status.DEFAULT);
  const [emailStatus, setEmailStatus] = useState(Status.DEFAULT);
  const [emailError, setEmailError] = useState("");

  const [newBackofficeUserRequest, setNewBackofficeUserRequest] = useState<NewBackofficeUsersRequest>(
    DEFAULT_ADD_USER()
  );

  const [saveButtonStatus, setSaveButtonStatus] = useState(Status.DEFAULT);

  const updateWithReset = useCallback(
    (status: Status, message?: ReactNode) => {
      setSaveButtonStatus(status);
      setTimeout(() => {
        setSaveButtonStatus(Status.DEFAULT);
      }, 4000);
    },
    [setSaveButtonStatus]
  );

  const onSave = () => {
    if (newBackofficeUserRequest.name.length === 0) {
      updateWithReset(Status.ERROR);
      setNameStatus(Status.ERROR);
      return;
    }
    if (newBackofficeUserRequest.email.length === 0) {
      setEmailError("Email is required");
      setEmailStatus(Status.ERROR);
      updateWithReset(Status.ERROR);
      return;
    }
    if (!userAdministration.isValidEmail(newBackofficeUserRequest.email)) {
      setEmailError("Invalid email format");
      setEmailStatus(Status.ERROR);
      updateWithReset(Status.ERROR);
      return;
    }

    if (!newBackofficeUserRequest.loginMethod) {
      updateWithReset(Status.ERROR);
      return;
    }

    setSaveButtonStatus(Status.PENDING);
    userAdministration
      .addNewBackofficeUsers(newBackofficeUserRequest)
      .then(() => {
        updateWithReset(Status.SUCCESS);
        refresh();
        setOpen(false);
      })
      .catch((err) => {
        updateWithReset(Status.ERROR);
      });
  };

  return (
    <div className="add-admin-user-overlay light-theme">
      <NewOverlay open={open} onClose={onClose}>
        <strong>Backoffice User</strong>
        <form onSubmit={(event) => event.preventDefault()}>
          <div className={`tablet-columns ${styles.backoffice_users_form}`}>
            <div>
              <Input
                onChange={(value) => {
                  setNewBackofficeUserRequest({
                    ...newBackofficeUserRequest,
                    email: value,
                  });
                }}
                value={newBackofficeUserRequest.email}
                name="email"
                label="Email"
                status={emailStatus}
                message={emailStatus === Status.ERROR ? emailError : null}
                placeholder="Used as login"
                onBlur={() => {
                  if (newBackofficeUserRequest.email.length === 0) {
                    setEmailError("Email is required");
                    setEmailStatus(Status.ERROR);
                    return;
                  }
                  if (userAdministration.isValidEmail(newBackofficeUserRequest.email)) {
                    // if (
                    //     newAdminUserRequest.email.endsWith("@bambora.com") ||
                    //     newAdminUserRequest.email.endsWith("@six-group.com") ||
                    //     newAdminUserRequest.email.endsWith("@ingenico.com")
                    // ) {
                    //   setLoginMethodStatus(Status.DISABLED);
                    //   setNewSalesUserRequest({
                    //     ...newSalesUser,
                    //     loginMethod: LoginMethod.AZURE,
                    //   });
                    // }

                    setEmailStatus(Status.SUCCESS);
                  } else {
                    setEmailError("Wrong format on email");
                    setEmailStatus(Status.ERROR);
                  }
                }}
              />
            </div>
            <div>
              <Input
                onChange={(value) => {
                  setNewBackofficeUserRequest({
                    ...newBackofficeUserRequest,
                    name: value,
                  });
                }}
                value={newBackofficeUserRequest.name}
                name="name"
                label="Name"
                status={nameStatus}
                message={nameStatus === Status.ERROR ? "Name is required" : null}
                placeholder="Display name"
                onBlur={() => {
                  if (newBackofficeUserRequest.name.length === 0) {
                    setNameStatus(Status.ERROR);
                  } else if (newBackofficeUserRequest.name.length > 0) {
                    setNameStatus(Status.SUCCESS);
                  } else {
                    setNameStatus(Status.DEFAULT);
                  }
                }}
              />
            </div>
            <div>
              <Dropdown
                alternatives={[
                  { value: "", text: "Select" },
                  { value: "HR", text: "Croatia" },
                  { value: "PL", text: "Poland" },
                  { value: "CZ", text: "Czechia" },
                ]}
                label="Country"
                value={newBackofficeUserRequest.country}
                onChange={(value) => {
                  setNewBackofficeUserRequest({
                    ...newBackofficeUserRequest,
                    country: value as Country,
                  });
                }}
              />
            </div>
          </div>
          <div className="tablet-columns">
            {/* <div>
              <Dropdown
                alternatives={[
                  { value: "", text: "Select" },
                  { value: "AZURE", text: "Azure OAUTH" },
                  { value: "GOOGLE", text: "Google OAUTH" },
                  { value: "OTP", text: "Login Code" },
                ]}
                label="Login Method"
                status={loginMethodStatus}
                value={newAdminUserRequest.loginMethod as string}
                onChange={(value) => {
                  if (value === "") {
                    setLoginMethodStatus(Status.ERROR);
                  } else {
                    setLoginMethodStatus(Status.SUCCESS);
                  }

                  setNewAdminUserRequest({
                    ...newAdminUserRequest,
                    loginMethod: value as LoginMethod,
                  });
                }}
              />
            </div> */}
          </div>
          <Button block onClick={onSave} status={saveButtonStatus}>
            Create
          </Button>
        </form>
      </NewOverlay>
      <Button onClick={onOpen} className="m-bottom-40">
        New Backoffice User <AddCircleOutline />
      </Button>
    </div>
  );
};
