import React, { RefObject, useCallback, useMemo } from "react";
import { addressFieldRequiredText, addressFieldValidators } from "./addressUtils";
import { Address } from "../../model/contract/contractType";
import { Select } from "../form/Select";
import { TextInput } from "../form/TextInput";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { getCountryOpts } from "../utils";
import { useTranslation } from "react-i18next";
import useGeneralData from "../../hooks/useGeneralData";

interface Props {
  address: Partial<Address>;
  onChange: (address: Partial<Address>) => void;
  disabled?: boolean;
  scrollToRef?: RefObject<HTMLElement>;
  addressRequiredFields: Record<keyof Address, boolean> | Record<keyof Omit<Address, "countryCode">, boolean>;
  showCountryCode?: boolean;
}

export const AddressForm: React.FunctionComponent<Props> = ({
  address,
  onChange,
  disabled = false,
  scrollToRef,
  showCountryCode,
  addressRequiredFields,
}) => {
  const { t } = useTranslation();
  const { language } = useGeneralData();

  const onUpdate = useCallback(
    (value: any, name: string) => {
      const update = { ...address };
      if (typeof value === "string") {
        update[name as keyof Address] = value;
      } else {
        update[name as keyof Address] = value.value;
      }
      onChange(update);
    },
    [address, onChange]
  );

  const countryOpts = useMemo(() => {
    return showCountryCode ? getCountryOpts(language, t) : [];
  }, [showCountryCode, language, t]);

  return (
    <>
      <TextInput
        onChange={onUpdate}
        name="street"
        label={t("Street")}
        value={address?.street}
        validators={[
          ...(addressRequiredFields.street
            ? [new RequiredValidator(addressFieldRequiredText["street"])]
            : []),
          ...addressFieldValidators["street"],
        ]}
        disabled={disabled}
        scrollToRef={scrollToRef}
      />

      <TextInput
        onChange={onUpdate}
        name="streetNumber"
        label={t("Street number")}
        value={address?.streetNumber}
        validators={[
          ...(addressRequiredFields.streetNumber
            ? [new RequiredValidator(addressFieldRequiredText["streetNumber"])]
            : []),
          ...addressFieldValidators["streetNumber"],
        ]}
        disabled={disabled}
        scrollToRef={scrollToRef}
      />

      <TextInput
        onChange={onUpdate}
        name="postalCode"
        label={t("Postal code")}
        value={address?.postalCode}
        validators={[
          ...(addressRequiredFields.postalCode
            ? [new RequiredValidator(addressFieldRequiredText["postalCode"])]
            : []),
          ...addressFieldValidators["postalCode"],
        ]}
        disabled={disabled}
        scrollToRef={scrollToRef}
      />

      <TextInput
        onChange={onUpdate}
        name="city"
        label={t("City")}
        value={address?.city}
        validators={[
          ...(addressRequiredFields.city ? [new RequiredValidator(addressFieldRequiredText["city"])] : []),
          ...addressFieldValidators["city"],
        ]}
        disabled={disabled}
        scrollToRef={scrollToRef}
      />

      {showCountryCode && (
        <div>
          <Select
            onChange={onUpdate}
            name="countryCode"
            label={t("Country")}
            alternatives={countryOpts}
            value={address?.countryCode}
            validators={[
              ...("countryCode" in addressRequiredFields && addressRequiredFields.countryCode
                ? [new RequiredValidator(addressFieldRequiredText["countryCode"])]
                : []),
              ...addressFieldValidators["countryCode"],
            ]}
            disabled={disabled}
            scrollToRef={scrollToRef}
          />
        </div>
      )}
    </>
  );
};
