import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Checkmark } from "../../../components/icons/Checkmark";
import { Edit } from "../../../components/icons/Edit";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Table } from "../../../components/table/Table";
import { getDynamicLink } from "../../../components/utils";
import {
  SalesTeam,
  userAdministration,
} from "../../../data/UserAdministration";
import { AdminPage } from "../AdminPage";
import { AddNewTeamOverlay } from "./AddNewTeamOverlay";
import { SALES_TEAM_EDIT_PAGE } from "./SalesTeamPage";
import { TeamMembersButtonOverlay } from "./TeamMembersButtonOverlay";
import { TeamPricingButtonOverlay } from "./TeamPricingButtonOverlay";
import "./SalesTeam.scss";
import { PricingHeatMap } from "./PricingHeatMap";

export const SALES_TEAM_OVERVIEW_PAGE = "/admin/teams";

export const SalesTeamsOverview: React.FunctionComponent = () => {
  const history = useHistory();
  const [teams, setTeams] = useState<SalesTeam[]>([]);

  useEffect(() => {
    userAdministration.loadSalesTeams().then(setTeams);
  }, [setTeams]);

  const onEdit = useCallback(
    (data) => {
      history.push(getDynamicLink(SALES_TEAM_EDIT_PAGE, { id: data.id }));
    },
    [history]
  );

  const onAddedTeam = useCallback(() => {
    userAdministration.loadSalesTeams().then(setTeams);
  }, [setTeams]);

  return (
    <AdminPage title="Sales teams" className="sales-teams">
      <section>
        <article>
          <AddNewTeamOverlay onAddedTeam={onAddedTeam} currentTeams={teams} />

          <Table className="m-top-20">
            <thead>
              <tr>
                <th />
                <th>Name</th>
                <th className="text-center">External</th>
                <th className="text-center">Merchandiser</th>
                <th className="text-center">Price presets</th>
                <th className="text-center">Members</th>
              </tr>
            </thead>
            <tbody>
              {teams.map((row) => (
                <tr key={row.id}>
                  <td className="text-center">
                    <Button
                      action
                      className="mini"
                      data={{
                        id: row.id,
                      }}
                      onClick={onEdit}
                    >
                      <Edit />
                    </Button>
                  </td>
                  <td className="truncate">{row.name}</td>
                  <td className="truncate text-center">
                    {row.external ? (
                      <Checkmark className="external-team-check" />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-center">{row.merchandiser ?? "-"}</td>
                  <td className="text-center">
                    <span className="member-count">
                      {row.pricingPresets.length}
                    </span>
                    <TeamPricingButtonOverlay team={row} />
                  </td>
                  <td className="text-center">
                    <span className="member-count">{row.memberCount}</span>
                    <TeamMembersButtonOverlay id={row.id} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <PricingHeatMap teams={teams} />
        </article>
      </section>
    </AdminPage>
  );
};
