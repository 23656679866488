import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { dataContracts } from "../../../data/dataContracts";
import { dataSales, TeamMember } from "../../../data/dataSales";
import { ContractStatus } from "../../../model/contract/contractType";
import { Status } from "../../../data/types";
import { contractStatusState } from "../../../state/contractStatusState";
import { userState } from "../../../state/userState";
import { getContractIsEditable, getUserIsAllowedToEdit } from "../ContractPage";
import { NotAllowed } from "./NotAllowed";
import { NotAllowedAdmin } from "./NotAllowedAdmin";
import { NotEditable } from "./NotEditable";

export function Claim() {
  const [currentMember, setCurrentMember] = useState<TeamMember>();
  const user = useRecoilValue(userState);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [contractStatus, setContractStatus] = useRecoilState(contractStatusState);
  const [edit] = useState<boolean>(contractStatus.edit);
  const [allowedToEdit] = useState<boolean>(getUserIsAllowedToEdit(contractStatus, user));
  const [contractEditable] = useState<boolean>(getContractIsEditable(contractStatus));

  const onClaimClick = useCallback(() => {
    setStatus(Status.SUCCESS);
    dataContracts
      .claimContract(contractStatus.contractId)
      .then(() =>
        setContractStatus({
          ...contractStatus,
          currentSalesUser: user?.userId,
          edit: getContractIsEditable(contractStatus),
        })
      )
      .catch(() => {
        setContractStatus({
          ...contractStatus,
          edit: getContractIsEditable(contractStatus),
        });
        // handle errors in save contract
      });
  }, [user, contractStatus, setContractStatus]);

  useEffect(() => {
    if (contractStatus.edit) return;

    dataSales
      .loadMyTeamMembers()
      .then((members) => {
        const currentMember = members.filter(
          (individual) => individual.userId === contractStatus.currentSalesUser
        )[0];

        if (currentMember) {
          setCurrentMember(currentMember);
        }
      })
      .catch(() => {
        // no matter, keep showing id instead of name
      });
  }, [contractStatus]);

  if (edit) return null;

  if (contractStatus.status === ContractStatus.COMPLETE) return null;

  if (user?.adminUser) return <NotAllowedAdmin />;

  if (!contractEditable) {
    return (
      <NotEditable
        onClick={onClaimClick}
        status={status}
        contractStatus={contractStatus}
        currentMember={currentMember}
        user={user}
      />
    );
  }

  if (!allowedToEdit) {
    return (
      <NotAllowed
        onClick={onClaimClick}
        status={status}
        contractStatus={contractStatus}
        currentMember={currentMember}
      />
    );
  }

  return null;
}

// useEffect(() => {
//   const contractId = contractStatus.contractId;
//   const wsId = `contract-${contractId}`;
//   WsClient.subscribe(wsId, `/contracts/${contractId}`, (value) => {
//     if (value.event === WlxEvent.CONTRACT_CLAIMED) {
//       const userId = value.payload["userId"] as UserId;
//       //Claimed by me,
//       // here we could check if this was the tab that
//       // actually did the save and update state based on that
//       if (userId === user?.userId) {
//         return;
//       }
//       // const userName = value.payload["userName"];
//       //TODO pretty notice! :)
//       //Maybe even web notification?
//       // window.alert("Contract claimed by " + userName);
//       setContractStatus({ ...contractStatus, edit: false });
//     }
//   });
//   return () => {
//     WsClient.unsubscribe(wsId);
//   };
// }, [contractStatus, user, setContractStatus]);
