import { data } from "./data";
import { ContractId, Country, TeamId } from "../model/common/commonType";
import { PricingStructure, PricingTemplate } from "../model/pricing/pricingTypes";

export interface TeamWithPrice {
  teamId: TeamId;
  teamName: string;
  otherPricingPresets: {
    id: number;
    name: string;
  };
}

export interface PricingSelected {
  date: string;
  count: number;
}

export const dataPricing = {
  loadTemplates: (contractId: ContractId, clearCache: boolean = false) =>
    data.get<PricingTemplate[]>(`/api/sales/contract/${contractId}/pricing/templates`, clearCache),

  savePricing: (contractId: ContractId, pricing: PricingStructure) =>
    data.post<PricingStructure>(`/api/sales/contract/${contractId}/pricing`, pricing),

  loadAdminPresets: (country?: Country) => {
    if (country) {
      return data.get<PricingTemplate[]>(`/api/admin/pricing?country=${country}`);
    }

    return data.get<PricingTemplate[]>(`/api/admin/pricing`);
  },

  disablePricingTemplate: (id: number) => data.delete(`/api/admin/pricing/${id}`),

  enablePricingTemplate: (id: number) => data.post(`/api/admin/pricing/enable/${id}`),

  //Sätt templateId = 0 så blir det en ny
  savePricingTemplate: (country: Country, template: PricingTemplate) =>
    data.post<PricingTemplate[]>(`/api/admin/pricing?country=${country}`, template),

  loadTeamsWithPricingActive: (templateId: number) =>
    data.get<TeamWithPrice[]>(`/api/admin/pricing/${templateId}/teams`),

  calculateStatisticssForPreset: (templateId: number) =>
    data.get<PricingSelected[]>(`/api/admin/pricing/${templateId}/contracts`),

  //Om man är sales admin ignoreras country och man får bara dom tillgängliga föär landet
  loadPricingTemplates: (country: Country) =>
    data.get<PricingTemplate[]>(`/api/admin/pricing?country=${country}`),
};
