import React from "react";
import { Language } from "../../i18n";
import ReactCountryFlag from "react-country-flag";
import { Country } from "../../model/common/commonType";
import cx from "classnames";
import "./Flag.scss";

interface Props {
  lang: Language | Country | string;
  rounded?: boolean;
  size?: number | string;
}

export const Flag: React.FunctionComponent<Props> = ({ lang, rounded = true, size = 30 }) => {
  return (
    <ReactCountryFlag
      className={cx("flag", { rounded })}
      style={{
        width: "auto",
        height: size,
      }}
      svg
      countryCode={lang.toUpperCase()}
    />
  );
};
