import { useMemo } from "react";
import { PHONE_COUNTRY_CODES } from "../form/validators/PhoneCountryCodes";

interface Props {
  phoneNumber?: string;
}

export const PhoneNumberCountry = ({ phoneNumber }: Props) => {
  const country = useMemo(
    () =>
      phoneNumber && phoneNumber.length > 4
        ? getCountryFromPhoneNumber(phoneNumber)
        : undefined,
    [phoneNumber]
  );

  return <>{country}</>;
};

export const getCountryFromPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return;
  }

  for (let i = 5; i >= 1; i--) {
    const code = phoneNumber.substring(0, i);
    const country: string | undefined = PHONE_COUNTRY_CODES[code];
    if (country) {
      return country;
    }
  }
};
