import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, matchPath } from "react-router";
import { useRecoilValue, useRecoilState } from "recoil";
import { BarChartIcon } from "../../../../components/icons/BarChartIcon";
import { DollarSignIcon } from "../../../../components/icons/DollarSignIcon";
import { FileIcon } from "../../../../components/icons/FileIcon";
import { GlobeIcon } from "../../../../components/icons/GlobeIcon";
import { MailIcon } from "../../../../components/icons/MailIcon";
import { TextFileIcon } from "../../../../components/icons/TextFileIcon";
import { UserIcon } from "../../../../components/icons/UserIcon";
import { UsersIcon } from "../../../../components/icons/UsersIcon";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { MissingTranslationsBadge } from "../../../../components/missingTranslationsBadge/MissingTranslationsBadge";
import { dataTranslationSession } from "../../../../data/dataTranslationSession";
import { featureState } from "../../../../state/featureState";
import { translationStateActiveSelector } from "../../../../state/translationState";
import { userState } from "../../../../state/userState";
import { ADMIN_DASHBOARD_ROUTE } from "../../../Admin/AdminDashboardPage";
import { COMMUNICATION_TESTER } from "../../../Admin/Communication/CommunicationTester";
import { ADMIN_CONTRACTS_LIST_PAGE } from "../../../Admin/Contracts/AdminContractsListPage";
import { MCC_ADMIN_PAGE } from "../../../Admin/Mcc/MccAdministrationPage";
import { PASS_FILE_UPLOAD_PAGE } from "../../../Admin/Pass/PassFIleUpload";
import { ADMIN_PRICING_PAGE } from "../../../Admin/Pricing/AdminPricingPage/AdminPricingPage";
import { SALES_TEAM_OVERVIEW_PAGE } from "../../../Admin/Teams/SalesTeamsOverview";
import { TRANSLATIONS_PAGE } from "../../../Admin/Translation/MissingTranslations";
import { SUPER_ADMIN_USERS_PAGE } from "../../../Admin/Users/AdminUsers";
import { SALES_USER_EDIT_PAGE } from "../../../Admin/Users/SalesUserPage";
import { SALES_ADMIN_USERS_PAGE } from "../../../Admin/Users/SalesUsers";
import { BACKOFFICE_ADMIN_USERS_PAGE } from "../../../BackOffice/BackOfficeUsers";
import { MenuItem } from "./SalesDashboardMenuItems";
import { SidebarItem } from "./SidebarMenuItem";
import { SupportBox } from "../../../../components/supportBox/SupportBox";
import SidebarUser from "../SidebarUser";
import styles from "./SidebarMenuItems.module.scss";
import parentStyles from "../Sidebar.module.scss";

export const AdminMenuItems = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const user = useRecoilValue(userState);
  const [isTranslationModeActive, setTranslationModeActive] = useRecoilState(translationStateActiveSelector);
  const { TRANSLATION_MODE: isTranslationModeAvailable } = useRecoilValue(featureState);
  const [hoveredItem, setHoveredItem] = useState<string>();

  const toggleTranslationManagement = useCallback(() => {
    if (!isTranslationModeActive) {
      dataTranslationSession.start().then(() => {
        setTranslationModeActive(true);
      });
    }
  }, [isTranslationModeActive, setTranslationModeActive]);

  // Det här ska tas hand om av App-routern
  if (!user) return null;

  const isSalesAdmin = user.adminUser && user.adminUser.salesAdmin;

  const superAdminMenuItems: MenuItem[] = [
    {
      name: (
        <>
          <UsersIcon /> Admin users
        </>
      ),
      link: SUPER_ADMIN_USERS_PAGE,
      disabled: false,
      active: matchPath(location.pathname, { path: SUPER_ADMIN_USERS_PAGE }) !== null,
    },
    {
      name: (
        <>
          <UsersIcon /> Backoffice users
        </>
      ),
      link: BACKOFFICE_ADMIN_USERS_PAGE,
      disabled: false,
      active: matchPath(location.pathname, { path: BACKOFFICE_ADMIN_USERS_PAGE }) !== null,
    },
    {
      name: (
        <>
          <FileIcon /> MCC administration
        </>
      ),
      link: MCC_ADMIN_PAGE,
      disabled: false,
      active: matchPath(location.pathname, { path: MCC_ADMIN_PAGE }) !== null,
    },
    {
      name: (
        <>
          <UsersIcon /> PASS users
        </>
      ),
      link: PASS_FILE_UPLOAD_PAGE,
      disabled: false,
      active: matchPath(location.pathname, { path: PASS_FILE_UPLOAD_PAGE }) !== null,
    },
  ];

  const salesAdminMenuItems: MenuItem[] = [
    {
      name: (
        <>
          <TextFileIcon />
          {t("Contracts")}
        </>
      ),
      link: ADMIN_CONTRACTS_LIST_PAGE,
      disabled: false,
      active: matchPath(location.pathname, { path: ADMIN_CONTRACTS_LIST_PAGE }) !== null,
    },

    {
      name: (
        <>
          <UserIcon /> Sales representatives
        </>
      ),
      link: SALES_ADMIN_USERS_PAGE,
      disabled: false,
      active:
        matchPath(location.pathname, { path: SALES_ADMIN_USERS_PAGE }) !== null ||
        matchPath(location.pathname, { path: SALES_USER_EDIT_PAGE }) !== null,
    },

    {
      name: (
        <>
          <UsersIcon /> {t("Teams")}
        </>
      ),
      link: SALES_TEAM_OVERVIEW_PAGE,
      disabled: false,
      active: matchPath(location.pathname, { path: SALES_TEAM_OVERVIEW_PAGE }) !== null,
    },
    {
      name: (
        <>
          <GlobeIcon /> {t("Translations")}
          {isSalesAdmin && user.country && <MissingTranslationsBadge country={user.country} />}
        </>
      ),
      link: TRANSLATIONS_PAGE,
      disabled: false,
      active: matchPath(location.pathname, { path: TRANSLATIONS_PAGE }) !== null,
    },
  ];

  const generateMenuItems = (): MenuItem[] => {
    let menuItems: MenuItem[] = [
      {
        name: (
          <>
            <BarChartIcon /> {t("Admin overview")}
          </>
        ),
        link: ADMIN_DASHBOARD_ROUTE,
        disabled: false,
        active: matchPath(location.pathname, { path: ADMIN_DASHBOARD_ROUTE, exact: true }) !== null,
      },
    ];

    if (user.adminUser?.superAdmin) {
      menuItems = [...menuItems, ...superAdminMenuItems];
    }

    if (user.adminUser?.salesAdmin) {
      menuItems = [...menuItems, ...salesAdminMenuItems];
    }

    if (user.adminUser?.pricingAdmin) {
      menuItems.push({
        name: (
          <>
            <DollarSignIcon /> {t("Pricing page")}
          </>
        ),
        link: ADMIN_PRICING_PAGE,
        disabled: false,
        active: matchPath(location.pathname, { path: ADMIN_PRICING_PAGE }) !== null,
      });
    }

    menuItems.push({
      name: (
        <>
          <MailIcon /> {t("Email / sms tester")}
        </>
      ),
      link: COMMUNICATION_TESTER,
      disabled: false,
      active: matchPath(location.pathname, { path: COMMUNICATION_TESTER }) !== null,
    });

    return menuItems;
  };

  return (
    <>
      <nav>
        <ul className={styles.sidebar_menu_items}>
          {generateMenuItems().map((item) => (
            <SidebarItem
              key={item.link}
              menuItem={item}
              hoveredItem={hoveredItem}
              onMouseEnter={() => {
                !item.disabled && setHoveredItem(item.link);
              }}
              onMouseLeave={() => setHoveredItem(undefined)}
            />
          ))}
        </ul>

        {isTranslationModeAvailable && !isTranslationModeActive && (
          <ul>
            <li className="logout-list-item">
              <Button block ghost onClick={toggleTranslationManagement}>
                Translation mode
              </Button>
            </li>
          </ul>
        )}
      </nav>
      <div className={parentStyles.bottomContent}>
        <SupportBox />
        <SidebarUser admin={true} />
      </div>
    </>
  );
};
