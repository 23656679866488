import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { FieldSet } from "../../../../components/fieldSet/FieldSet";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Dropdown } from "../../../../components/interactions/Dropdown/Dropdown";
import { Input } from "../../../../components/interactions/Input/Input";
import {
  AdminMerchantCategory,
  dataMccAdministration,
  RISK_LEVEL_OPTIONS,
  UpdateMccRequest,
} from "../../../../data/dataMccAdministration";
import { MccRiskLevel } from "../../../../model/contract/contractType";
import { Status } from "../../../../data/types";
import { AdminPage } from "../../AdminPage";

import { MCC_ADMIN_PAGE } from "../MccAdministrationPage";

export const MCC_EDIT_PAGE = "/admin/mcc/:id";

type PageParams = {
  id: string;
};

export const EditMccPage: FC = () => {
  let { id } = useParams<PageParams>();
  const history = useHistory();

  const [mcc, setMcc] = useState<AdminMerchantCategory>();
  const [updateMcc, setUpdateMcc] = useState<UpdateMccRequest>({
    name: "",
    note: "",
    category: "",
    faceToFace: MccRiskLevel.NONE,
    cardNotPresent: MccRiskLevel.NONE,
  });

  const [industryStatus, setIndustryStatus] = useState(Status.DEFAULT);
  const [categoryStatus, setCategoryStatus] = useState(Status.DEFAULT);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const mccId = parseInt(id);
    setLoading(true);

    dataMccAdministration
      .load(mccId)
      .then((mcc) => {
        setMcc(mcc);
        setUpdateMcc({
          name: mcc.name,
          note: mcc.note,
          category: mcc.category,
          faceToFace: mcc.faceToFace,
          cardNotPresent: mcc.cardNotPresent,
        });
      })
      .finally(() => setLoading(false));
  }, [setMcc, id]);

  const saveItem = () => {
    if (updateMcc.name.length === 0) {
      return;
    }

    const mccId = parseInt(id);
    dataMccAdministration.updateMcc(mccId, updateMcc).then(() => {
      history.push(MCC_ADMIN_PAGE);
    });
  };

  const isFormValid = () => {
    return updateMcc.name.length > 0 && updateMcc.category.length > 0;
  };

  const deleteItem = () => {
    const confirmed = window.confirm("Are you sure you want to delete MCC " + mcc?.code + "?");
    if (!confirmed) {
      return;
    }
    setLoading(true);
    const mccId = parseInt(id);
    dataMccAdministration.deleteMcc(mccId).then(() => {
      history.push(MCC_ADMIN_PAGE);
    });
  };

  if (loading) {
    return (
      <AdminPage className="admin-edit-mcc">
        <section>Loading..</section>
      </AdminPage>
    );
  }

  if (!mcc) {
    return <span>Could not find MCC with id {id}</span>;
  }

  return (
    <AdminPage className="admin-edit-mcc light-theme">
      <section>
        <article>
          <FieldSet header={`Edit MCC ${mcc.code} - ${updateMcc.name}`}>
            <div className="tablet-columns">
              <div className="column">
                <Input
                  value={mcc.code}
                  name={"code"}
                  label="Code"
                  status={Status.DISABLED}
                  onChange={() => {}}
                />
              </div>
              <div className="column">
                <Input
                  onChange={(value) => {
                    setUpdateMcc({ ...updateMcc, name: value });
                  }}
                  value={updateMcc.name}
                  name={"name"}
                  label="Industry"
                  status={industryStatus}
                  message={industryStatus === Status.ERROR ? "Industry is required" : null}
                  placeholder="MCC Industry"
                  onBlur={() => {
                    if (updateMcc.name.length === 0) {
                      setIndustryStatus(Status.ERROR);
                      return;
                    }
                    if (updateMcc.name.length > 5) {
                      setIndustryStatus(Status.SUCCESS);
                      return;
                    }
                    setIndustryStatus(Status.DEFAULT);
                  }}
                />
              </div>
            </div>
            <label htmlFor="note" className="input input-text">
              <div className="input-label">Note</div>
              <div className="input-frame">
                <textarea
                  id="note"
                  rows={3}
                  value={updateMcc.note}
                  onChange={(e) => {
                    const value = !e.target.value ? "" : "" + e.target.value;
                    setUpdateMcc({ ...updateMcc, note: value });
                  }}
                ></textarea>
              </div>
            </label>

            <Input
              onChange={(value) => {
                setUpdateMcc({ ...updateMcc, category: value });
              }}
              value={updateMcc.category}
              name="category"
              label="Category"
              status={categoryStatus}
              message={categoryStatus === Status.ERROR ? "Category is required" : null}
              placeholder="MCC Category"
              onBlur={() => {
                if (updateMcc.category.length === 0) {
                  setCategoryStatus(Status.ERROR);
                  return;
                }
                if (updateMcc.category.length > 5) {
                  setCategoryStatus(Status.SUCCESS);
                  return;
                }
                setCategoryStatus(Status.DEFAULT);
              }}
            />

            <div className="tablet-columns">
              <div className="column">
                <Dropdown
                  label="Face to face"
                  alternatives={RISK_LEVEL_OPTIONS}
                  value={updateMcc.faceToFace}
                  onChange={(value) => {
                    setUpdateMcc({
                      ...updateMcc,
                      faceToFace: value as MccRiskLevel,
                    });
                  }}
                />
              </div>
              <div className="column">
                <Dropdown
                  label="Card not present"
                  alternatives={RISK_LEVEL_OPTIONS}
                  value={updateMcc.cardNotPresent}
                  onChange={(value) => {
                    setUpdateMcc({
                      ...updateMcc,
                      cardNotPresent: value as MccRiskLevel,
                    });
                  }}
                />
              </div>
            </div>

            <div className="tablet-columns">
              <div className="column">
                <Button onClick={saveItem} status={isFormValid() ? Status.DEFAULT : Status.DISABLED} block>
                  Save
                </Button>
              </div>

              <div className="tablet-columns">
                <div className="column">
                  <Button ghost block onClick={() => history.push(MCC_ADMIN_PAGE)}>
                    Cancel
                  </Button>
                </div>
                <div className="column">
                  <Button className="danger-button" block onClick={deleteItem}>
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </FieldSet>
        </article>
      </section>
    </AdminPage>
  );
};
