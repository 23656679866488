import cx from "classnames";
import { useEffect, useRef, useState } from "react";
import { ProgressInterface } from "../../../components/fileUpload/FileUpload";
import { Progress } from "../../../components/fileUpload/Progress";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Status } from "../../../data/types";
import { API } from "../../../network/API";
import { AdminPage } from "../AdminPage";

import { SiMicrosoftexcel } from "react-icons/si";
import { Table } from "../../../components/table/Table";
import { dataPassUsers, PassUser } from "../../../data/dataPassUsers";
import { TextInput } from "../../../components/form/TextInput";

import styles from "./PassFileUpload.module.scss";

export const PASS_FILE_UPLOAD_PAGE = "/admin/pass";

function searchPassUsers(users: PassUser[], queryString: string): PassUser[] {
  // Create a regex pattern from the queryString. We use the 'i' flag to make the search case-insensitive.
  const queryRegex = new RegExp(queryString, "i");

  // Filter users based on whether any of the specified fields match the regex pattern
  return users.filter(
    (user) =>
      queryRegex.test(user.name || "") ||
      queryRegex.test(user.userId) ||
      queryRegex.test(user.officerId) ||
      queryRegex.test(user.officerCategory)
  );
}

export const PassFileUploadPage = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [progress, setProgress] = useState<ProgressInterface>({ progress: 0, name: "" });
  const [passUsers, setPassUsers] = useState<PassUser[]>([]);
  const [filteredPassUsers, setFilteredPassUsers] = useState<PassUser[]>([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    dataPassUsers.getAllPassUsers().then((data) => {
      setPassUsers(data);
      setFilteredPassUsers(data);
    });
  }, []);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const onSearch = (query: string) => {
    setSearchString(query);

    if (query.length === 0) {
      setFilteredPassUsers(passUsers);
    } else {
      const result = searchPassUsers(passUsers, query);
      setFilteredPassUsers(result);
    }
  };

  return (
    <AdminPage title="Pass File upload" className="pass-file-upload">
      <div className={styles.body}>
        <h3 className="m-bottom-40">Pass users</h3>
        <div className={styles.header}>
          <TextInput
            label="Search PASS user"
            onChange={onSearch}
            className={styles.search}
            value={searchString}
          />
          <div
            className={cx("upload-box m-top-30", {
              "is-pending": loading,
            })}
          >
            <label htmlFor="document">
              <Button block onClick={handleUploadClick} status={status}>
                <SiMicrosoftexcel style={{ marginRight: 8, fontSize: "1.2em" }} />
                Upload PASS user file
              </Button>
            </label>
            <Progress {...progress} />
            <input
              type="file"
              onChange={(event) => {
                if (!event.target.files) {
                  return;
                }
                setStatus(Status.PENDING);
                setLoading(true);
                const selectedFile = event.target.files[0];
                const req = new XMLHttpRequest();
                req.upload.addEventListener("progress", (event) => {
                  if (event.lengthComputable) {
                    setProgress({
                      progress: (event.loaded / event.total) * 100,
                      name: selectedFile.name,
                    });
                  }
                });

                req.onload = function () {
                  if (req.status === 200) {
                    setProgress({
                      progress: 100,
                      name: selectedFile.name,
                    });
                    setStatus(Status.SUCCESS);
                  } else {
                    setStatus(Status.ERROR);
                    setTimeout(() => setStatus(Status.DEFAULT), 4000);
                  }
                };

                const formData = new FormData();
                formData.append("document", selectedFile, selectedFile.name);
                req.open("POST", API.getUrl(`/api/admin/pass/upload`));
                req.withCredentials = true;
                req.send(formData);
              }}
              ref={hiddenFileInput}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              style={{ display: "none" }}
              id="document"
              disabled={status !== Status.DEFAULT}
            />
          </div>
        </div>

        <Table className="m-top-10">
          <thead>
            <tr>
              <th></th>
              <th>Officer ID</th>
              <th>User ID</th>
              <th>Officer Category</th>
              <th>Officer / Group name</th>
            </tr>
          </thead>
          <tbody>
            {filteredPassUsers.map((user) => {
              return (
                <tr>
                  <td></td>
                  <td> {user.officerId}</td>
                  <td> {user.userId}</td>
                  <td> {user.officerCategory}</td>
                  <td> {user.name}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </AdminPage>
  );
};
