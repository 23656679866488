import { FunctionComponent } from "react";
import styles from "./SectionFieldSet.module.scss";
import { T } from "../translation/T";
import { useFormValidity } from "../form/MultiFormContext";
import classNames from "classnames/bind";
import { TSwitch } from "../translation/TSwitch";
import { Validity } from "../../model/contract/contractType";
import { FormName } from "../../pages/Contract/menus/ContractEditMenu";

const cx = classNames.bind(styles);

interface Props {
  sectionNumber?: number;
  headerTitle: string;
  formName: FormName;
}

export const SectionFieldSetHeader: FunctionComponent<Props> = ({ headerTitle, sectionNumber, formName }) => {
  const validity = useFormValidity(formName);

  return (
    <div className={styles.header}>
      <div className={styles.titleWrapper}>
        <h5 className={styles.index}>{sectionNumber && `0${sectionNumber}`}</h5>
        <h4 className={styles.title}>
          <T>{headerTitle}</T>
        </h4>
      </div>
      <hr className={styles.divider} />
      <div className={cx(styles.status, validity)}>
        <TSwitch>
          <T.Condition condition={validity !== Validity.VALID}>incomplete</T.Condition>
          <T.Default>completed</T.Default>
        </TSwitch>
      </div>
    </div>
  );
};
