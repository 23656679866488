import { CSSProperties } from "react";
import { MdOutlineStorefront } from "react-icons/md";

interface Props {
  className?: string;
  style?: CSSProperties;
}

export const InstoreIcon = ({ className, style }: Props) => {
  return <MdOutlineStorefront className={className} style={{ fontSize: "1.1em", ...style }} />;
};
