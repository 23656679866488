import React, { useRef } from "react";
import { getDynamicLink } from "../../../components/utils";
import { ContractStatusState } from "../../../state/contractStatusState";
import { MERCHANT_ROUTE } from "../../MerchantStatus/MerchantStatusPage";
import { Link } from "../../../components/links/Link";
import { matchPath, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { SessionResponse } from "../../../data/dataAuth";
import { CONTRACT_ROUTE } from "../ContractPage";
import { TeamMember } from "../../../data/dataSales";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Status } from "../../../data/types";
import styles from "./Claim.module.scss";

interface Props {
  contractStatus: ContractStatusState;
  currentMember?: TeamMember;
  user: SessionResponse | null;
  onClick: () => void;
  status: Status;
}

export const NotEditable: React.FunctionComponent<Props> = ({
  contractStatus,
  currentMember,
  user,
  onClick,
  status,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isContractPage = !!matchPath(pathname, {
    path: CONTRACT_ROUTE,
  });

  const isUserOwnerOfContract = useRef<boolean>(user?.userId === contractStatus.currentSalesUser);

  if (isContractPage) {
    return (
      <div className={styles.root}>
        <div className={styles.eyebrow} />
        <h4 className={styles.title}>{t("Heads up")}! ✋</h4>
        <p className={styles.text}>
          {t(
            "This contract has already been sent to owners / legal representatives for signatures / identification. Hence, you can't edit this contract."
          )}
        </p>

        {!isUserOwnerOfContract.current ? (
          <p>
            If you must edit / delete the contract you can either <b>revoke</b> the contract from{" "}
            <Link
              className={styles.link}
              link={getDynamicLink(MERCHANT_ROUTE, {
                id: contractStatus.contractId,
              })}
            >
              the confirmation page
            </Link>{" "}
            or you can <b>delete</b> the contract at the bottom of this page.
          </p>
        ) : (
          <>
            <p>
              If you want to confirm, revoke or delete this contract you must first <u>claim it</u>, then you
              can either <b>confirm</b> or <b>revoke</b> the contract from{" "}
              <Link
                className={styles.link}
                link={getDynamicLink(MERCHANT_ROUTE, {
                  id: contractStatus.contractId,
                })}
              >
                the confirmation page
              </Link>{" "}
              or you can <b>delete</b> the contract at the bottom of this page.
            </p>
            <Button block className="m-top-20" onClick={onClick} status={status}>
              {t("Claim contract")}
            </Button>
          </>
        )}
      </div>
    );
  }

  if (isUserOwnerOfContract.current) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.eyebrow} />
      <h4 className={styles.title}>{t("Heads up")} ✋</h4>
      <p className={styles.text}>
        This contract was last edited by <b>{currentMember?.name ?? contractStatus.currentSalesUser}</b>
      </p>

      <p className={styles.text}>
        If you want to confirm, revoke or delete this contract you must first <u>claim it</u>.
      </p>

      <Button block className="m-top-20" onClick={onClick} status={status}>
        {t("Claim contract")}
      </Button>
    </div>
  );
};
