import React from "react";
import { Page } from "../Page/Page";
import { Filter } from "./Filter";
import { CurrentStats } from "./CurrentStats";
import { HistoricalStats } from "./HistoricalStats";
import { PersistFilter } from "../../components/analyticsFilter/PersistFilter";
import { useTranslation } from "react-i18next";
import { CompletedContracts } from "./CompletedContracts/CompletedContracts";

export const ANALYTICS_ROUTE = "/analytics";

export const AnalyticsPage: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Page title="Analytics" className="analytics-page">
      <PersistFilter />

      <CurrentStats />
      <h2>{t("Historical statistics")}</h2>
      <Filter />
      <HistoricalStats />

      <h2>{t("Sent to onboarding")}</h2>
      <CompletedContracts />
    </Page>
  );
};
