import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./AccountLookup.scss";
import { useTranslation } from "react-i18next";
import { BankAccountSuggestion, dataContracts } from "../../../data/dataContracts";
import { BankAccount } from "../../../model/contract/contractType";
import { ContractStatusState } from "../../../state/contractStatusState";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Status } from "../../../data/types";
import { Dropdown } from "../../../components/interactions/Dropdown/Dropdown";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Select } from "../../../components/form/Select";

interface Props {
  onSuggestionChange: (iban: string) => void;
  bank: BankAccount;
  contractStatus: ContractStatusState;
  status: Status;
}

export const AccountLookup: React.FunctionComponent<Props> = ({
  bank,
  onSuggestionChange,
  contractStatus,
  status,
}) => {
  const { t } = useTranslation();
  const [suggestions, setSuggestions] = useState<BankAccountSuggestion[]>([]);
  const [hasFetched, setHasFetched] = useState<boolean>(false);
  const [fetchStatus, setFetchStatus] = useState<Status>(Status.DEFAULT);

  useEffect(() => {
    // Delay request
    if (bank.iban) {
      return;
    }

    setHasFetched(true);

    dataContracts
      .registryBankLookup(contractStatus.contractId)
      .then((response) => {
        setSuggestions(response);
        if (response.length === 1) {
          onSuggestionChange(response[0].iban);
        }
      })
      .catch(() => setHasFetched(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSuggestionChange, contractStatus.contractId]);

  const onFetch = useCallback(() => {
    setFetchStatus(Status.PENDING);

    setTimeout(() => {
      dataContracts
        .registryBankLookup(contractStatus.contractId)
        .then((response) => {
          setHasFetched(true);
          setSuggestions(response);
        })
        .catch(() => {
          setFetchStatus(Status.ERROR);
          setTimeout(() => {
            setFetchStatus(Status.DEFAULT);
          }, 3000);
        });
    }, 400);
  }, [contractStatus.contractId]);

  const opts = useMemo(() => {
    const options = [
      {
        value: "",
        text: t("Select bank account"),
        disabled: true,
      },
    ];

    suggestions.forEach((item) =>
      options.push({
        text: `${item.bank} - ${item.iban}`,
        value: item.iban,
        disabled: false,
      })
    );

    return options;
  }, [suggestions, t]);

  if (hasFetched && !suggestions.length) {
    return (
      <div className="m-bottom-30 fw-500 text-passive" style={{ paddingLeft: 10 }}>
        {t(
          "Unfortunately, we didn't find any bank accounts associated with this merchant. A bank statement from the primary contact will have to be provided"
        )}
      </div>
    );
  }

  if (hasFetched) {
    return (
      <div className="m-bottom-30 tablet-columns">
        <div>
          <Select
            onChange={onSuggestionChange}
            alternatives={opts}
            validators={[new RequiredValidator("Please select a suggested account")]}
            value={bank.iban || ""}
            name="suggestionAccount"
            label={t("Suggested accounts")}
            disabled={!contractStatus.edit}
          />
        </div>

        <div />
      </div>
    );
  }

  return (
    <div className="m-bottom-40 tablet-columns">
      <div>
        <Dropdown
          onChange={onSuggestionChange}
          status={Status.DISABLED}
          name="suggestionAccount"
          label={t("Suggested bank accounts")}
          alternatives={opts}
          value=""
        />
      </div>
      <div>
        <Button
          ghost
          onClick={onFetch}
          block
          className="align-input-label"
          status={status === Status.DISABLED ? status : fetchStatus}
        >
          {t("Fetch suggested accounts")}
        </Button>
      </div>
    </div>
  );
};
