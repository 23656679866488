import { useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { IdCardInputs } from "../../../../../components/idCard/IdCardInputs";
import { FormattedDate } from "../../../../../components/time/FormattedDate";
import { MerchantAssociateResponse } from "../../../../../data/dataConfirm";
import { Associate } from "../../../../../model/associate/associateTypes";
import { ContractId } from "../../../../../model/common/commonType";
import { Status } from "../../../../../data/types";
import styles from "../../AssociateReview.module.scss";
import { OpenImageTypes, SignedOffImages } from "./SignedOffImages";
import { SignatureDisplay } from "../../components/SignatureDisplay/SignatureDisplay";
import ContentBox from "../../../../../components/ContentBox/ContentBox";

export interface Props {
  contractId: ContractId;
  merchantAssociate: MerchantAssociateResponse;
  associate: Associate;
}

export const AssociateSignedOffView: FC<Props> = ({ contractId, merchantAssociate, associate }) => {
  const { t } = useTranslation();
  const [openImage, setOpenImage] = useState<OpenImageTypes>("");

  return (
    <ContentBox>
      <div className={styles.columns}>
        <div className={styles.leftColumn}>
          <SignedOffImages
            merchantAssociate={merchantAssociate}
            openImage={openImage}
            toggleImage={setOpenImage}
          />
          <div className="m-top-20">
            <SignatureDisplay merchantAssociate={merchantAssociate} />
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className="m-bottom-30">
            <h4>{t("Sign off details")}</h4>
            <dl className={styles.data_list}>
              <dd>{t("Signed off at")}</dd>
              <dt>
                <FormattedDate date={associate.signedOff} />
              </dt>
              <dd>{t("Signed off by")}</dd>
              <dt>{associate.signedOffBy}</dt>
            </dl>
          </div>
          <div className={styles.actions}>
            <h4>{t("Identity details")}</h4>
            <div className={styles.idCardInputsColumn}>
              <IdCardInputs
                cardForm={merchantAssociate.identificationData}
                setCardForm={() => console.log("this should not be possible")}
                status={Status.DISABLED}
              />
            </div>
          </div>
        </div>
      </div>
    </ContentBox>
  );
};
