import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { Form, FormContainer } from "../../../../../components/form/Form";
import { hasRealErrors } from "../../../../../components/form/FormHelpers";
import { TextInput } from "../../../../../components/form/TextInput";
import { MaxLengthValidator } from "../../../../../components/form/validators/MaxLengthValidator";
import { RequiredValidator } from "../../../../../components/form/validators/RequiredValidator";
import { Status } from "../../../../../data/types";
import { ContractEditError } from "../../../ContractEditError";
import { FormName } from "../../../menus/ContractEditMenu";
import { Terminals } from "../StoreForm/Terminals/Terminals";
import { clearCas } from "../../../../../components/utils";
import { ONGOING_RESPONSE, QueueCallback } from "../../../../../data/queues/QueueTypes";
import { ContractType, ECommerceType, Store2 } from "../../../../../model/contract/contractType";
import { getEcomTypeDisplayString } from "../../../../../model/contract/contractUtils";
import { storeQueue } from "../../../../../data/queues/StoreQueue";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { dataContracts } from "../../../../../data/dataContracts";
import { contractPackageState } from "../../../../../state/contractPackageState";
import { contractSaveState, contractErrorState } from "../../../../../state/contractSaveState";
import { contractStatusState } from "../../../../../state/contractStatusState";
import { contractStoresState } from "../../../../../state/contractStoresState";
import { ContractSaveError, handleError, getEditStatus } from "../../../ContractPage";
import { RadioGroup } from "../../../../../components/form/RadioGroup";
import { AnimatePresence } from "framer-motion";
import EcomStoreUploadFiles from "./EcomStoreUpload/EcomStoreUploadFiles";
import { InfoBox } from "../../../../../components/boxes/InfoBox";
import styles from "./EcomStore.module.scss";
import { Alternative } from "../../../../../components/interactions/InputTypes";
import { WarningBox } from "../../../../../components/boxes/WarningBox";
import { HiddenInput } from "../../../../../components/form/HiddenInput";
import { ErrorBox } from "../../../../../components/boxes/ErrorBox";
import PaymentLinksContent from "./EcomStoreUpload/EcomTypesContent/PaymentLinksContent";
import TestWebsiteContent from "./EcomStoreUpload/EcomTypesContent/TestWebsiteContent";
import WebsiteContent from "./EcomStoreUpload/EcomTypesContent/WebsiteContent";

interface Props {
  store: Store2<ContractType.ECOMMERCE>;
  formContainer: React.MutableRefObject<FormContainer | undefined>;
}

const YES = "YES";
const NO = "NO";

const EcomStore: FC<Props> = ({ store, formContainer }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [error, setError] = useState<ContractSaveError | null>(null);
  const [urlsIsAvailable, setUrlsIsAvailable] = useState(YES);
  const { contractId, edit } = useRecoilValue(contractStatusState);
  const packageState = useRecoilValue(contractPackageState);
  const [, setStores] = useRecoilState(contractStoresState);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);

  const { storeId, ecommerceType } = store;

  const prevSaved = useRef<string>(JSON.stringify(clearStoreCas(store)));
  const inputStatus = useMemo(() => getEditStatus(edit, Status.DEFAULT), [edit]);

  const onChangeStoreData = useCallback(
    <K extends keyof Store2<ContractType.ECOMMERCE>>(key: K, value: Store2<ContractType.ECOMMERCE>[K]) => {
      setStores((prevStores) =>
        prevStores.map((prevStore) => {
          if (prevStore.storeId === storeId) {
            return { ...prevStore, [key]: value };
          }
          return prevStore;
        })
      );
    },
    [setStores, storeId]
  );

  const saveStoreCallback = useCallback<QueueCallback<Store2>>(
    (err, response) => {
      if (err === ONGOING_RESPONSE) return;
      setStatus(Status.DEFAULT);

      if (err) {
        prevSaved.current = "";
        handleError(err, setError);
        setDataError((dataErrors) => dataErrors.concat({ date: new Date() }));
        return;
      }

      if (!response) return;

      setStores(response);
      setDataSaved((dataSaved) => dataSaved.concat({ date: new Date() }));
    },
    [setDataSaved, setDataError, setStores]
  );

  const onSaveStore = useCallback(
    (saveStore: Store2) => {
      const stringCopy = JSON.stringify(clearCas(saveStore));
      if (prevSaved.current === stringCopy) return;
      prevSaved.current = stringCopy;

      setStatus(Status.PENDING);
      storeQueue.saveStore(contractId, saveStore, saveStoreCallback);
    },
    [contractId, saveStoreCallback]
  );

  const trySave = useCallback(() => {
    onSaveStore(store);
  }, [onSaveStore, store]);

  const retry = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(trySave, 500);
  }, [trySave]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    // We're lazy, execute save again, which will fail, and propagate error to Overlay
    setTimeout(() => dataContracts.claimContract(contractId).then(trySave).catch(trySave), 500);
  }, [contractId, trySave]);

  const renderContent = () => {
    if (urlsIsAvailable === NO) {
      return null;
    }

    if (ecommerceType === ECommerceType.WEBSITE) {
      return (
        <WebsiteContent
          key={"website-content"}
          store={store}
          onChange={onChangeStoreData}
          status={status}
          edit={edit}
          t={t}
        />
      );
    }

    if (ecommerceType === ECommerceType.TEST_WEBSITE) {
      return (
        <TestWebsiteContent
          key={"test-website-content"}
          store={store}
          onChange={onChangeStoreData}
          status={status}
          edit={edit}
          t={t}
        />
      );
    }

    if (ecommerceType === ECommerceType.PAYMENT_LINKS) {
      return (
        <PaymentLinksContent
          key={"payment-link-content"}
          store={store}
          onChange={onChangeStoreData}
          status={status}
          edit={edit}
          t={t}
        />
      );
    }

    if (!ecommerceType) return null;

    return <ErrorBox>Could not find Ecommerce Type {store.ecommerceType}</ErrorBox>;
  };

  const ref = useRef(null);

  return (
    <Form
      onSaveTrigger={(event, form) => {
        if (hasRealErrors(form)) return;
        onSaveStore(store);
      }}
      name={FormName.STORE}
      formContainer={formContainer}
    >
      <ContractEditError
        error={error}
        setError={setError}
        retry={retry}
        onClose={() => setError(null)}
        reclaimAndSave={reclaimAndSave}
      />

      <div className="tablet-columns">
        <div>
          <TextInput
            onChange={(value) => onChangeStoreData("commercialName", value)}
            name="tradingName" // previous commercialName
            value={store.commercialName}
            label={t("Trading name")}
            validators={[
              new RequiredValidator(t("Trading name is required")),
              new MaxLengthValidator(
                30,
                t("Trading name cannot be longer than {max} characters", { max: 30 })
              ),
            ]}
            disabled={!edit || status === Status.DISABLED}
          />
        </div>

        <div>
          <TextInput
            onChange={(value) => onChangeStoreData("cardStatementName", value)}
            label={t("Short commercial name")} // previous short address
            hint={t("For cardholder statement")}
            placeholder={t("Max 21 characters")}
            value={store.cardStatementName}
            name="shortCommercialName"
            validators={[
              new RequiredValidator(t("Short commercial name is required")),
              new MaxLengthValidator(
                21,
                t("Short commercial name cannot be longer than {{min}} characters", {
                  min: 21,
                })
              ),
            ]}
            disabled={!edit || status === Status.DISABLED}
          />
        </div>
      </div>

      <RadioGroup
        className="horizontal m-bottom-30"
        onChange={(type) => onChangeStoreData("ecommerceType", type)}
        name="ecommerce-type"
        label={t("E-commerce type")}
        alternatives={ecomTypes}
        value={store.ecommerceType}
        validators={[new RequiredValidator(t("E-commerce type is required"))]}
      />

      <RadioGroup
        className="horizontal"
        onChange={(val) => setUrlsIsAvailable(val)}
        alternatives={attachmentsAlts}
        value={urlsIsAvailable}
        label="Are there available URLs?"
      />
      <div className={styles.infoBox}>
        {urlsIsAvailable === NO ? (
          <WarningBox>{t("You have to upload attachements")}</WarningBox>
        ) : (
          <InfoBox>
            {t(
              "You can also supplement with attachements if the given URLs does not provide sufficient information"
            )}
          </InfoBox>
        )}
      </div>

      <div className="m-bottom-30">
        <AnimatePresence exitBeforeEnter>{renderContent()}</AnimatePresence>
      </div>

      <EcomStoreUploadFiles />

      <div className="m-y-10">
        <HiddenInput
          value={undefined}
          scrollToRef={ref}
          label={"E-commerce attachements"}
          validators={[new RequiredValidator("You need to add attchement if no URLs are provided")]}
        />
      </div>

      <Terminals
        terminals={store.terminals}
        packageState={packageState}
        inputStatus={inputStatus}
        store={store}
        saveStore={onSaveStore}
      />
    </Form>
  );
};

const attachmentsAlts: Alternative<string>[] = [
  { text: "Yes", value: YES },
  { text: "No", value: NO },
];

export default EcomStore;

const ecomTypes = [
  {
    text: getEcomTypeDisplayString(ECommerceType.WEBSITE),
    value: ECommerceType.WEBSITE,
  },
  {
    text: getEcomTypeDisplayString(ECommerceType.TEST_WEBSITE),
    value: ECommerceType.TEST_WEBSITE,
  },
  {
    text: getEcomTypeDisplayString(ECommerceType.PAYMENT_LINKS),
    value: ECommerceType.PAYMENT_LINKS,
  },
];

function clearStoreCas(store: Store2) {
  const terminalsCopy = clearCas(store.terminals);
  const storeCopy = clearCas(store);
  return {
    ...storeCopy,
    terminals: terminalsCopy,
  };
}

// const WarningTextMap: Record<ECommerceType, string> = {
//   [ECommerceType.WEBSITE]: "You have to upload attachements that shows the website",
//   [ECommerceType.TEST_WEBSITE]: "You have to upload attachements that shows the test website",
//   [ECommerceType.PAYMENT_LINKS]: "You have to upload attachements that shows the product offering and terms and conditions"
// }

// const getWarningText = (type: ECommerceType) => {
//   const text = WarningTextMap[type];
//   return text ?? "You have to upload attachements"
// }
