import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  RegisteredTranslationsSelector,
  translationManagementState,
  translationStateActiveSelector,
  translationStateSelector,
} from "../../state/translationState";
import cx from "classnames";
import "./T.scss";
import { DEFAULT_TRANSLATION_NAMESPACE } from "../../i18n";
import { StringMap, TOptions } from "i18next";

interface PropsWithChildren {
  children: string;
  options?: TOptions<StringMap> | undefined;
  alternativeIds?: string[];
}

interface PropsWithId {
  id: string;
  options?: TOptions<StringMap> | undefined;
  alternativeIds?: string[];
}

type Props = PropsWithChildren | PropsWithId;

export type TDefaultProps = Props;

export type TConditionalProps = Props & {
  condition: boolean;
};

export const T: React.FunctionComponent<Props> & {
  Condition: React.FunctionComponent<TConditionalProps>;
  Default: React.FunctionComponent<TDefaultProps>;
} = ({ options, ...props }) => {
  const translationModeActive = useRecoilValue(translationStateActiveSelector);

  const translation = "id" in props ? props.id : props.children;

  if (typeof translation !== "string") {
    throw Error("T: child must be a string");
  }

  return translationModeActive ? (
    <TManaged {...props}>{translation}</TManaged>
  ) : (
    <TUnmanaged {...props}>{translation}</TUnmanaged>
  );
};

const TUnmanaged: React.FunctionComponent<PropsWithChildren> = ({
  children,
  options,
}) => {
  const { t } = useTranslation();

  return t(children, options);
};

const TManaged: React.FunctionComponent<PropsWithChildren> = ({
  children,
  options,
  ...props
}) => {
  const { t } = useTranslation();
  const registerTranslation = useSetRecoilState(RegisteredTranslationsSelector);
  const setTranslation = useSetRecoilState(translationStateSelector);
  const ref = useRef<HTMLSpanElement | null>(null);
  const { translations } = useRecoilValue(translationManagementState);

  const highlight = useCallback(() => {
    const internalRef = ref.current;
    if (internalRef) {
      internalRef.classList.add("highlight");

      setTimeout(() => {
        internalRef.classList.remove("highlight");
      }, 3000);
    }
  }, []);

  useEffect(() => {
    registerTranslation([{ id: children, ref, highlightFunction: highlight }]);
  }, [registerTranslation, children, highlight]);

  const isTranslated = useMemo(
    () =>
      !!translations?.find(
        (t) =>
          t.key === children &&
          t.namespace === (options?.namespace || DEFAULT_TRANSLATION_NAMESPACE)
      )?.value,
    [translations, children, options]
  );

  const handleOnClick = () => {
    setTranslation({ selected: children, alternatives: props.alternativeIds });
  };

  return (
    <span
      ref={ref}
      className={cx("managed-translation", { "is-missing": !isTranslated })}
      onClick={handleOnClick}
    >
      {t(children, options)}
      {props.alternativeIds && props.alternativeIds.length > 1 && (
        <span className="editable-translation-variants">
          + {props.alternativeIds.length - 1}
        </span>
      )}
    </span>
  );
};

export const TCondition: React.FunctionComponent<TConditionalProps> = ({
  ...props
}) => {
  return <T {...props} />;
};

export const TDefault: React.FunctionComponent<TDefaultProps> = ({
  ...props
}) => {
  return <T {...props} />;
};

T.Condition = TCondition;
T.Default = TDefault;
