import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Dropdown } from "../../../components/interactions/Dropdown/Dropdown";
import { Input } from "../../../components/interactions/Input/Input";
import {
  dataAdminCommunication,
  EmailTemplate,
  SmsTemplate,
  TestEmailRequest,
  TestSmsRequest,
} from "../../../data/dataAdminCommunication";
import { Status } from "../../../data/types";
import phoneprefixes from "../../../lang/phoneprefixes.json";
import { userState } from "../../../state/userState";
import { AdminPage } from "../AdminPage";
import "./CommunicationTester.scss";
import ContentBox from "../../../components/ContentBox/ContentBox";

export const COMMUNICATION_TESTER = "/admin/communication/tester";

const LANGUAGES = [
  {
    value: "en",
    text: "English",
  },
  {
    value: "hr",
    text: "Croatian",
  },
  {
    value: "pl",
    text: "Polish",
  },
  {
    value: "cs",
    text: "Czech",
  },
];

const EMAIL_TEMPLATES = [
  {
    value: EmailTemplate.INVITE_TO_CONFIRM,
    text: "Invite to confirm",
  },
  {
    value: EmailTemplate.INVITE,
    text: "Invite to sign",
  },
  {
    value: EmailTemplate.RESUBMIT_DOCUMENTS,
    text: "Resubmit documents",
  },
  {
    value: EmailTemplate.CONTRACT_COMPLETED,
    text: "Contract completed",
  },
];

const SMS_TEMPLATES = [
  {
    value: SmsTemplate.INVITE_TO_CONFIRM,
    text: "Invite to confirm",
  },
  {
    value: SmsTemplate.INVITE,
    text: "Invite to sign",
  },
  {
    value: SmsTemplate.RESUBMIT_DOCUMENTS,
    text: "Resubmit documents",
  },
];

export const CommunicationTester = () => {
  const country = useRecoilValue(userState)?.country?.toLocaleLowerCase() || "en";

  const [emailRequest, setEmailRequest] = useState<TestEmailRequest>({
    emailTemplate: EmailTemplate.INVITE_TO_CONFIRM,
    language: country,
  });

  const [smsRequest, setSmsRequest] = useState<TestSmsRequest>({
    template: SmsTemplate.INVITE_TO_CONFIRM,
    language: country,
    number: window.localStorage.getItem("wlx.admin.number") || "",
  });

  const [smsNumberStatus, setSmsNumberStatus] = useState(Status.DEFAULT);
  const [smsButtonStatus, setSmsButtonStatus] = useState(Status.DISABLED);
  const [numberHint, setNumberHint] = useState("");
  const [emailButtonStatus, setEmailButtonStatus] = useState(Status.DEFAULT);

  const findCountry = (number: string) => {
    return phoneprefixes.find((value) => {
      return number.startsWith(value.dialCode);
    });
  };

  useEffect(() => {
    if (smsRequest.number.length === 0 || !smsRequest.number.startsWith("+")) {
      setSmsNumberStatus(Status.ERROR);
      setSmsButtonStatus(Status.DISABLED);
      return;
    }

    const country = findCountry(smsRequest.number);

    if (country) {
      setNumberHint(country.dialCode + " " + country.name);
    }
    setSmsNumberStatus(Status.DEFAULT);
    setSmsButtonStatus(Status.DEFAULT);
  }, [smsRequest.number]);

  return (
    <AdminPage title="Communication Tester">
      <div className="communication-tester">
        <h3 className="m-bottom-40">Communication tester</h3>
        <section>
          <ContentBox header="Test Email">
            <div className="tablet-columns">
              <div className="column">
                <Dropdown
                  alternatives={EMAIL_TEMPLATES}
                  label="Template"
                  value={emailRequest.emailTemplate}
                  onChange={(value) => {
                    setEmailRequest({
                      ...emailRequest,
                      emailTemplate: value as EmailTemplate,
                    });
                  }}
                />
              </div>
              <div className="column">
                <Dropdown
                  alternatives={LANGUAGES}
                  label="Language"
                  value={emailRequest.language}
                  onChange={(value) => {
                    setEmailRequest({
                      ...emailRequest,
                      language: value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="column m-top-20">
              <Button
                onClick={() => {
                  dataAdminCommunication.sendTestEmail(emailRequest).then(() => {
                    setEmailButtonStatus(Status.SUCCESS);
                    setTimeout(() => {
                      setEmailButtonStatus(Status.DEFAULT);
                    }, 3000);
                  });
                }}
                status={emailButtonStatus}
                block
              >
                Send
              </Button>
            </div>
          </ContentBox>
        </section>
        <section>
          <ContentBox header="Test SMS" className="m-top-20">
            <div className="tablet-columns">
              <div className="column">
                <Dropdown
                  alternatives={SMS_TEMPLATES}
                  label="Template"
                  value={smsRequest.template}
                  onChange={(value) => {
                    setSmsRequest({
                      ...smsRequest,
                      template: value as SmsTemplate,
                    });
                  }}
                />
              </div>
              <div className="column">
                <Dropdown
                  alternatives={LANGUAGES}
                  label="Language"
                  value={smsRequest.language}
                  onChange={(value) => {
                    setSmsRequest({
                      ...smsRequest,
                      language: value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="column m-top-20">
              <Input
                onChange={(value) => {
                  setSmsRequest({ ...smsRequest, number: value });
                }}
                value={smsRequest.number}
                name={"number"}
                label="Phone number"
                status={smsNumberStatus}
                hint={numberHint}
                message={smsNumberStatus === Status.ERROR ? "Number must be international format" : null}
              />
            </div>
            <div className="m-top-20">
              <Button
                onClick={() => {
                  dataAdminCommunication.sendTestSms(smsRequest).then(() => {
                    window.localStorage.setItem("wlx.admin.number", smsRequest.number);
                    setSmsButtonStatus(Status.SUCCESS);
                    setTimeout(() => {
                      setSmsButtonStatus(Status.DEFAULT);
                    }, 3000);
                  });
                }}
                status={smsButtonStatus}
                block
              >
                Send
              </Button>
            </div>
          </ContentBox>
        </section>
      </div>
    </AdminPage>
  );
};
