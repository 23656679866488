import { FunctionComponent, useMemo, useRef } from "react";
import { OptionalTransactionFee } from "../../../model/pricing/pricingTypes";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { HiddenInput } from "../../../components/form/HiddenInput";
import { useTranslation } from "react-i18next";

import { Country } from "../../../model/common/commonType";
import { getBrandsData } from "../../../model/pricing/pricingUtils";

// const REQUIRED_BRANDS: Brands[] = Object.entries(BRAND_MAP)
//   .filter(([, { disabled }]) => !disabled)
//   .map(([brand]) => brand as Brands);

interface Props {
  transactionFees: OptionalTransactionFee[];
  scrollRef?: React.RefObject<HTMLElement>;
  country: Country;
}

export const FeeValidation: FunctionComponent<Props> = ({ transactionFees, scrollRef, country }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const missingCardBrands = useMemo(
    () =>
      getBrandsData(country)
        .filter((data) => !data.disabled)
        .filter(({ brand }) => !transactionFees.some((fee) => fee.brand === brand))
        .map((data) => data.label),
    [transactionFees, country]
  );

  const hasMissingCardBrands = missingCardBrands.length > 0;

  return (
    <div ref={ref} style={{ margin: 10 }}>
      <HiddenInput
        label="Required card brands"
        scrollToRef={scrollRef || ref}
        value={hasMissingCardBrands ? undefined : true}
        validators={[
          new RequiredValidator(
            t(
              `Missing required card brand {{brand}}, select another pricing preset or have the pricing preset updated with the latest card brands`,
              {
                brand: missingCardBrands.join(", "),
              }
            )
          ),
        ]}
      />
    </div>
  );
};
