import { useTranslation } from "react-i18next";
import styles from "./Claim.module.scss";

export const NotAllowedAdmin = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.eyebrow} />
      <h4 className={styles.title}>{t("Heads up")} ✋</h4>
      <p className={styles.text}>
        {t(
          "You are viewing this contract as an administrator and are therefore unable to make changes to it."
        )}
      </p>
    </div>
  );
};
