import React, { FC, RefObject } from "react";
import { Validity } from "../../../model/contract/contractType";
import { NewOverlay } from "../../overlay/NewOverlay";
import { AssociateRow } from "./AssociateRow";
import { UpdateAssociate, AssociateType } from "./UpdateAssociate";
import { Associate } from "../../../model/associate/associateTypes";
import { Status } from "../../../data/types";
import { FormContainer } from "../../form/Form";

interface Props {
  overlayOpen: boolean;
  setOverlayOpen: (open: boolean) => void;
  associate: Associate;
  status: Status;
  validity: Validity | undefined;

  toggled: boolean;
  onToggle: (selected: boolean, data: any) => void;
  formContainer: React.MutableRefObject<FormContainer | undefined>;
  formId: React.MutableRefObject<string>;
  scrollToRef?: RefObject<HTMLElement>;
  formName: string;
  type: AssociateType;
}

const AssociateRowAndOverlay: FC<Props> = ({
  overlayOpen,
  setOverlayOpen,
  associate,
  status,
  toggled,
  onToggle,
  formContainer,
  scrollToRef,
  validity,
  formName,
  type,
}) => {
  return (
    <>
      <AssociateRow
        associate={associate}
        setActiveAssociate={() => setOverlayOpen(true)}
        status={status}
        toggled={toggled}
        onToggle={onToggle}
        validity={validity}
      />
      <NewOverlay
        open={overlayOpen}
        onClose={() => setOverlayOpen(false)}
        className="overlay-edit"
        disableClose={status !== Status.DISABLED}
        noPadding
        noOverflowScroll
      >
        <UpdateAssociate
          status={status}
          associate={associate}
          onClose={() => setOverlayOpen(false)}
          formName={formName}
          formContainer={formContainer}
          type={type}
          scrollToRef={scrollToRef}
        />
      </NewOverlay>
    </>
  );
};

export default AssociateRowAndOverlay;
