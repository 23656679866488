import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import cx from "classnames";
import { BankAccount, MainContractData } from "../../../../model/contract/contractType";
import { contractBankState, defaultBankAccount } from "../../../../state/contractBankState";
import { TFunction, Trans, useTranslation } from "react-i18next";
import { useRecoilValue, useRecoilState } from "recoil";
import { contractAssociateState } from "../../../../state/contractAssociateState";
import { contractMainContractDataState } from "../../../../state/contractMainContractDataState";
import { getName } from "./ConfirmBank";
import { dataConfirm } from "../../../../data/dataConfirm";
import { Associate, AssociateRole } from "../../../../model/associate/associateTypes";
import { Status } from "../../../../data/types";
import { IdImage } from "../../../Contract/uploads/IdImage";
import { FileType } from "../../../../components/fileUpload/FileUpload";
import { ErrorBox } from "../../../../components/boxes/ErrorBox";
import { SuccessBox } from "../../../../components/boxes/SuccessBox";
import { getIntlDate } from "../../../../components/utils";
import { IBAN } from "ibankit";
import { dataContracts } from "../../../../data/dataContracts";
import { contractStatusState } from "../../../../state/contractStatusState";
import { TextInput } from "../../../../components/form/TextInput";
import { Form, FormContainer } from "../../../../components/form/Form";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { Select } from "../../../../components/form/Select";
import { IbanValidator } from "../../../../components/form/validators/IbanValidator";
import { AnimateHeight } from "../../../../components/animate/AnimateHeight";
import { ConfirmButton, ConfirmType } from "../../../../components/interactions/Buttons/ConfirmButton";
import i18n from "../../../../i18n";
import { UTCDate } from "../../../../model/common/commonType";
import { API } from "../../../../network/API";
import { ZoomableImage } from "../../../../components/images/ZoomableImage";
import "./BankStatementConfirmed.scss";
import { WarningBox } from "../../../../components/boxes/WarningBox";
import { AnimateHeightAuto } from "../../../../components/animate/AnimateHeightAuto";

function getOpts(mainData: MainContractData, associates: Associate[], t: TFunction) {
  const options = [
    {
      text: t("Select account holder"),
      value: "",
      disabled: true,
    },
    {
      text: `${t("Company")}: ${mainData.companyName}`,
      value: "COMPANY",
    },
  ];

  associates.forEach((item) => {
    const name = getName(item);

    if (!name) {
      return;
    }

    if (!item.roles.includes(AssociateRole.BENEFICIAL_OWNER)) {
      return false;
    }

    options.push({
      text: name || "No name",
      value: item.associateId,
    });
  });

  return options;
}

export const BankStatementConfirmed: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const associates = useRecoilValue(contractAssociateState);
  const mainData = useRecoilValue(contractMainContractDataState);
  const contractState = useRecoilValue(contractStatusState);
  const [bankAccount, setBankAccount] = useRecoilState(contractBankState);
  const [showBicAndBank, setShowBicAndBank] = useState<boolean>(false);
  const [rejectStatus, setRejectStatus] = useState<Status>(Status.DEFAULT);
  const [confirmStatus, setConfirmStatus] = useState<Status>(Status.DEFAULT);
  const [account, setAccount] = useState<BankAccount>({
    ...bankAccount,
  });
  const formContainer = useRef<FormContainer>();

  const opts = useMemo(() => getOpts(mainData, associates, t), [mainData, associates, t]);

  const onReject = useCallback(() => {
    setRejectStatus(Status.PENDING);

    dataConfirm
      .rejectBankAccount(contractState.contractId)
      .then(() => {
        setRejectStatus(Status.SUCCESS);
        setBankAccount(() => ({
          ...defaultBankAccount,
        }));
      })
      .catch(() => {
        setRejectStatus(Status.ERROR);
        setTimeout(() => {
          setRejectStatus(Status.DEFAULT);
        }, 3000);
      });
  }, [contractState.contractId, setBankAccount]);

  const onConfirm = useCallback(() => {
    if (formContainer.current?.isInvalid) {
      formContainer.current?.setForceErrors(true);
      return;
    }

    setConfirmStatus(Status.PENDING);

    dataConfirm
      .addBankAccount(contractState.contractId, {
        cas: account.cas,
        bic: account.bic,
        bank: account.bank,
        iban: account.iban,
        accountHolder: account.accountHolder,
      })
      .then(() => {
        setConfirmStatus(Status.SUCCESS);
        setBankAccount({
          ...account,
          signedOff: new Date().toISOString() as UTCDate,
        });
      })
      .catch(() => {
        setConfirmStatus(Status.ERROR);
        setTimeout(() => {
          setConfirmStatus(Status.DEFAULT);
        }, 3000);
      });
  }, [contractState.contractId, account, setBankAccount]);

  useEffect(() => {
    if (!account.iban) {
      return;
    }

    if (!IBAN.isValid(account.iban)) {
      return;
    }

    setRejectStatus(Status.PENDING);
    setConfirmStatus(Status.PENDING);

    dataContracts
      .lookupBicAndBank(account.iban, contractState.contractId)
      .then((lookupResult) => {
        setRejectStatus(Status.DEFAULT);
        setConfirmStatus(Status.DEFAULT);

        if (lookupResult.success) {
          setShowBicAndBank(false);
          setAccount((prevAccount) => ({
            ...prevAccount,
            bic: lookupResult.bic,
            bank: lookupResult.bank,
          }));
        } else {
          setShowBicAndBank(true);
          setAccount((prevAccount) => ({
            ...prevAccount,
            bic: undefined,
            bank: undefined,
          }));
        }
      })
      .catch((err) => {
        setRejectStatus(Status.ERROR);
        setConfirmStatus(Status.ERROR);

        setTimeout(() => {
          setRejectStatus(Status.ERROR);
          setConfirmStatus(Status.ERROR);
        }, 3000);
        console.error(err);
      });
  }, [account.iban, contractState.contractId, setAccount]);

  const wasSuccessfulOrConfirmed =
    confirmStatus === Status.SUCCESS || rejectStatus === Status.SUCCESS || bankAccount.signedOff;

  const url = API.getUrl(`/api/sales/merchant/${contractState.contractId}/bank`);

  return (
    <div
      className={cx("confirm-bank", "bank-statement-review", {
        "show-status": confirmStatus !== Status.DEFAULT || rejectStatus !== Status.DEFAULT,
      })}
    >
      <h4>{t("Payout account")}</h4>
      <WarningBox>{t("This bank statement has to be validated and confirmed.")}</WarningBox>
      <div className="m-top-20 relative review-columns">
        <div className="relative">
          <div className="confirm-bank-statuses">
            {confirmStatus === Status.ERROR && (
              <ErrorBox relative>{t("Something went wrong. We couldn't confirm this document.")}</ErrorBox>
            )}

            {confirmStatus === Status.SUCCESS && (
              <SuccessBox relative>
                <i>{getIntlDate(i18n.language, new Date())}</i> -{" "}
                {t("Thanks! We have confirmed this document.")}
              </SuccessBox>
            )}

            {rejectStatus === Status.ERROR && (
              <ErrorBox relative>{t("Something went wrong. We couldn't reject this document.")}</ErrorBox>
            )}

            {rejectStatus === Status.SUCCESS && (
              <SuccessBox relative>{t("Ok! We have rejected this document.")}</SuccessBox>
            )}
          </div>
          {account.mimeType === "application/pdf" ? (
            <IdImage
              status={Status.DEFAULT}
              image={{
                url: url,
                type: FileType.PDF,
              }}
              isGeneric={true}
            />
          ) : (
            <ZoomableImage alt="Bank Statement" image={url} />
          )}
        </div>

        <div>
          <AnimateHeightAuto className="">
            {wasSuccessfulOrConfirmed ? null : (
              <Form formContainer={formContainer}>
                <div>
                  <div className="m-bottom-20">
                    <TextInput
                      onChange={(val, name) => {
                        setAccount((prevBank) => ({
                          ...prevBank,
                          [name]: val,
                        }));
                      }}
                      label="IBAN"
                      value={account.iban}
                      name="iban"
                      validators={[
                        new RequiredValidator(t("IBAN is required")),
                        new IbanValidator(t("IBAN is invalid")),
                      ]}
                    />
                  </div>
                  <div className="m-bottom-20">
                    <Select
                      onChange={(val) =>
                        setAccount((prevBank) => ({
                          ...prevBank,
                          accountHolder: val,
                        }))
                      }
                      label={t("Account Holder")}
                      alternatives={opts}
                      value={account.accountHolder}
                      hint={t("Owner of the account")}
                      validators={[new RequiredValidator(t("Account owner is required"))]}
                    />
                  </div>

                  <div className="m-bottom-20">
                    <TextInput
                      onChange={(val, name) => {
                        setAccount((prevBank) => ({
                          ...prevBank,
                          [name]: val,
                        }));
                      }}
                      label={t("Bank")}
                      value={account.bank}
                      name="bank"
                      validators={[new RequiredValidator(t("Bank is required"))]}
                      attributes={{
                        tabIndex: showBicAndBank ? 0 : -1,
                      }}
                      disabled={!showBicAndBank}
                    />
                  </div>
                  <div className="m-bottom-20">
                    <TextInput
                      onChange={(val, name) => {
                        setAccount((prevBank) => ({
                          ...prevBank,
                          [name]: val,
                        }));
                      }}
                      label="BIC"
                      validators={[new RequiredValidator(t("Bic is required"))]}
                      value={account.bic}
                      name="bic"
                      attributes={{
                        tabIndex: showBicAndBank ? 0 : -1,
                      }}
                      disabled={!showBicAndBank}
                    />
                  </div>
                </div>

                <AnimateHeight name={showBicAndBank ? "show" : "hide"}>
                  {showBicAndBank ? (
                    <div className="m-bottom-30">
                      <Trans>
                        The account has to be a transaction account. <b>BIC</b> and <b>Bank</b> are mandatory
                        fields. If e.g. <b>BIC</b> is missing the contract has to be rejected and the merchant
                        will have to upload document for an alternative account.
                      </Trans>
                    </div>
                  ) : (
                    <div />
                  )}
                </AnimateHeight>

                <div className="m-top-40">
                  <div>
                    <ConfirmButton
                      ghost
                      onClick={onReject}
                      removeButtonText={t("Yes, reject")}
                      status={rejectStatus}
                      small={false}
                    >
                      {t("Reject document")}
                    </ConfirmButton>
                  </div>
                  <div className="m-top-10">
                    <ConfirmButton
                      small={false}
                      confirmType={ConfirmType.SUCCESS}
                      className="confirm-button"
                      removeButtonText={t("Yes, confirm")}
                      onClick={onConfirm}
                      status={confirmStatus}
                    >
                      {t("Confirm document")}
                    </ConfirmButton>
                  </div>
                </div>
              </Form>
            )}
          </AnimateHeightAuto>
        </div>
      </div>
    </div>
  );
};
