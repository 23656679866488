import React from "react";
import cx from "classnames";
import { Image } from "../../../../components/images/Image";
import "./ProviderLogo.scss";

interface Props {
  className?: string;
}

export const Visa: React.FunctionComponent<Props> = ({ className }) => (
  <div className={cx("provider-logo", "logo-visa", className)}>
    <Image
      src="/images/cards/visa_logo_1000x324.svg"
      ratio={1000 / 324}
      alt="Visa logo"
    />
  </div>
);
