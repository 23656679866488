import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { Retry } from "../../../../../../components/retry/Retry";
import { dataPricing } from "../../../../../../data/dataPricing";
import { Country } from "../../../../../../model/common/commonType";
import { Status } from "../../../../../../data/types";
import { useIsMountedRef } from "../../../../../../hooks/useIsMounted";
import { availablePricingTemplatesState } from "../../../../../../state/contractCostState";

interface Props {
  children: ReactNode;
  country: Country;
}

export const PresetsLoader: FunctionComponent<Props> = ({ children, country }) => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const setPreSets = useSetRecoilState(availablePricingTemplatesState);
  const mounted = useIsMountedRef();

  const load = useCallback(
    (country: Country) => {
      dataPricing
        .loadAdminPresets(country)
        .then((data) => {
          setPreSets(data);
          if (mounted.current) {
            setStatus(Status.SUCCESS);
          }
        })
        .catch((err) => {
          if (mounted.current) {
            setStatus(Status.ERROR);
          }
        });
    },
    [setPreSets, mounted]
  );

  useEffect(() => {
    if (!country) {
      return;
    }

    load(country);
  }, [load, country, mounted]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(() => load(country), 300);
  }, [country, load]);

  return (
    <Retry status={status} retry={retry}>
      {children}
    </Retry>
  );
};
