import { useCallback, useEffect, useRef, useState, VFC } from "react";
import { useRecoilValue } from "recoil";
import { Form, FormContainer } from "../../../components/form/Form";
import { TextInput } from "../../../components/form/TextInput";
import { InvalidValuesValidator } from "../../../components/form/validators/InvalidValuesValidator";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { AddCircleOutline } from "../../../components/icons/AddCircleOutline";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Toggle } from "../../../components/interactions/Toggle/Toggle";
import { NewOverlay } from "../../../components/overlay/NewOverlay";
import { dataPricing } from "../../../data/dataPricing";
import { Country } from "../../../model/common/commonType";
import { PackageId } from "../../../model/contract/contractType";
import { PricingModel, PricingTemplate } from "../../../model/pricing/pricingTypes";
import { Status } from "../../../data/types";
import { TeamRequest, SalesTeam, userAdministration } from "../../../data/UserAdministration";
import { userState } from "../../../state/userState";
import { getPricingModelDisplayName } from "../../../model/pricing/pricingUtils";

export interface ModalProps {
  onAddedTeam: () => void;
  currentTeams: SalesTeam[];
}

const DEFAULT_TEAM_ADD = () => {
  return {
    name: "",
    external: false,
    enabledBundles: [PackageId.SOFTPOS_BUNDLE, PackageId.DESK_BUNDLE, PackageId.MOVE_BUNDLE],
    pricingPresets: [],
  } as TeamRequest;
};

export const AddNewTeamOverlay: VFC<ModalProps> = ({ onAddedTeam, currentTeams }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)} className="m-bottom-20">
        New Team <AddCircleOutline />
      </Button>
      <NewOverlay open={isOpen} onClose={() => setIsOpen(false)}>
        <AddNewTeamOverlayInner
          currentTeams={currentTeams}
          onAddedTeam={() => {
            setIsOpen(false);
            onAddedTeam();
          }}
        />
      </NewOverlay>
    </>
  );
};

export const AddNewTeamOverlayInner: VFC<ModalProps> = ({ onAddedTeam, currentTeams }) => {
  const user = useRecoilValue(userState);
  //Vi defaultar bara till nåt, user ska finnas här
  const country = user?.country || Country.POLAND;

  const formRef = useRef<FormContainer>();
  const [teamRequest, setTeamRequest] = useState<TeamRequest>(DEFAULT_TEAM_ADD());
  const [saveButtonStatus, setSaveButtonStatus] = useState(Status.DEFAULT);
  const [pricingPresets, setPricingPresets] = useState<PricingTemplate[]>([]);
  const currentTeamNames = new Set(currentTeams.map((s) => s.name));

  useEffect(() => {
    setTeamRequest(DEFAULT_TEAM_ADD());
    dataPricing.loadPricingTemplates(country).then(setPricingPresets);
  }, [country]);

  const updateWithReset = useCallback(
    (status: Status) => {
      setSaveButtonStatus(status);
      setTimeout(() => {
        setSaveButtonStatus(Status.DEFAULT);
      }, 4000);
    },
    [setSaveButtonStatus]
  );

  const onSave = () => {
    setSaveButtonStatus(Status.PENDING);

    userAdministration
      .addNewSalesTeam(teamRequest)
      .then(() => {
        onAddedTeam();
      })
      .catch(() => {
        updateWithReset(Status.ERROR);
      });
  };

  const pricingToggle = (price: PricingTemplate) => {
    const presets = [...teamRequest.pricingPresets];
    return (
      <Toggle
        className="mini m-top-10"
        label={price.name}
        selected={presets.includes(price.templateId)}
        onChange={(selected) => {
          if (selected) {
            presets.push(price.templateId);
          } else {
            presets.splice(presets.indexOf(price.templateId), 1);
          }
          setTeamRequest({
            ...teamRequest,
            pricingPresets: presets,
          });
        }}
      />
    );
  };

  const updateSelectedBundles = (selected: boolean, bundle: PackageId) => {
    const idx = teamRequest.enabledBundles.findIndex((v) => v === bundle);
    if (selected) {
      if (idx > -1) {
        //Already existed?
        return;
      }
      setTeamRequest({
        ...teamRequest,
        enabledBundles: [...teamRequest.enabledBundles, bundle],
      });
    } else {
      var bundles = [...teamRequest.enabledBundles];
      bundles.splice(idx, 1);
      if (bundles.length === 0) {
        bundles = [PackageId.SOFTPOS_BUNDLE, PackageId.DESK_BUNDLE, PackageId.MOVE_BUNDLE];
      }
      setTeamRequest({
        ...teamRequest,
        enabledBundles: bundles,
      });
    }
  };

  return (
    <div className="add-team-overlay light-theme">
      <strong>Create Team</strong>
      <Form
        formContainer={formRef}
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }
          onSave();
        }}
      >
        <div className="m-top-20">
          <div>
            <TextInput
              onChange={(value) => {
                setTeamRequest({
                  ...teamRequest,
                  name: value,
                });
              }}
              value={teamRequest.name}
              name="name"
              label="Name"
              placeholder="Name of the team"
              validators={[
                new RequiredValidator("Name is required"),
                new InvalidValuesValidator(currentTeamNames, "Name is already used"),
              ]}
            />
          </div>
          <div>
            <label>Enabled bundles</label>
            <div className="bundle-toggle">
              <Toggle
                className="mini"
                selected={teamRequest.enabledBundles.includes(PackageId.SOFTPOS_BUNDLE)}
                onChange={(selected) => updateSelectedBundles(selected, PackageId.SOFTPOS_BUNDLE)}
              />
              <span className="bundle-row">Worldline Tap on Mobile Bundle</span>
            </div>
            <div className="bundle-toggle">
              <Toggle
                className="mini"
                selected={teamRequest.enabledBundles.includes(PackageId.DESK_BUNDLE)}
                onChange={(selected) => updateSelectedBundles(selected, PackageId.DESK_BUNDLE)}
              />
              <span className="bundle-row">Desk Bundle</span>
            </div>
            <div className="bundle-toggle">
              <Toggle
                className="mini"
                selected={teamRequest.enabledBundles.includes(PackageId.MOVE_BUNDLE)}
                onChange={(selected) => updateSelectedBundles(selected, PackageId.MOVE_BUNDLE)}
              />
              <span className="bundle-row">Move Bundle</span>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <label>External Partner</label>
            <div className="bundle-toggle">
              <Toggle
                className="mini"
                selected={teamRequest.external}
                onChange={(selected) =>
                  setTeamRequest({
                    ...teamRequest,
                    external: selected,
                  })
                }
              />
            </div>
          </div>
          <div className="column">
            <TextInput
              onChange={(value) => {
                setTeamRequest({
                  ...teamRequest,
                  merchandiser: value,
                });
              }}
              value={teamRequest.merchandiser}
              name="merchandiser"
              label="Merchandiser"
            />
          </div>
        </div>
        <h4>Pricing Presets</h4>
        <div className="columns">
          <div className="column">
            <strong>{getPricingModelDisplayName(PricingModel.BLENDED)}</strong>
            {pricingPresets
              .filter((p) => p.pricingModel === PricingModel.BLENDED)
              .sort((p1, p2) => {
                return p2.priority - p1.priority;
              })
              .map(pricingToggle)}
          </div>
          <div className="column">
            <strong>{getPricingModelDisplayName(PricingModel.ICPP)}</strong>
            {pricingPresets
              .filter((p) => p.pricingModel === PricingModel.ICPP)
              .sort((p1, p2) => {
                return p2.priority - p1.priority;
              })
              .map(pricingToggle)}
          </div>
          <div className="column">
            <strong>{getPricingModelDisplayName(PricingModel.PACK)}</strong>
            {pricingPresets
              .filter((p) => p.pricingModel === PricingModel.PACK)
              .sort((p1, p2) => {
                return p2.priority - p1.priority;
              })
              .map(pricingToggle)}
          </div>
        </div>
        <div className="m-top-30">
          <Button type="submit" block status={saveButtonStatus}>
            Create
          </Button>
        </div>
      </Form>
    </div>
  );
};
