import { Language } from "../i18n";
import { data } from "./data";
import { TranslationId, TranslationText } from "./dataTranslation";

export const dataTranslationSession = {
  start: () => {
    return data.post(`/api/translations/start`);
  },

  loadTranslations: (language?: Language, skipCache = false) => {
    const lang = language || "";
    return data.get<TranslationText[]>(
      `/api/admin/translations?language=${lang}`,
      skipCache
    );
  },

  updateTranslation: (id: TranslationId, text: string) => {
    return data.post(`/api/translations/${id}`, {
      text,
    });
  },

  deleteTranslation: (id: TranslationId) => {
    return data.delete(`/api/translations/${id}`);
  },

  getByPage: (page: string) => {
    return data.post<TranslationText>(`/api/translations`, {
      page,
    });
  },
};
