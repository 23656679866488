import { atom } from "recoil";
import { Cas } from "../model/common/commonType";
import { ContractType, MainContractData, RegonStatus } from "../model/contract/contractType";
import { Language } from "../i18n";

export const contractMainContractDataState = atom<MainContractData>({
  key: "contract-main-contracta-data-state",
  default: {
    cas: 0 as Cas,
    companyRegistrationId: "",
    companyName: "",
    website: "",
    vatNumber: "",
    companyType: "",
    mcc: "",
    cardholderStatementText: "",
    countryOfOrigin: "",
    primaryAddress: {},
    beneficialOwnerType: undefined,
    language: Language.UK,
    acceptance: false,
    regonNumber: "",
    regonResult: RegonStatus.NOT_FOUND,
    contractType: [ContractType.INSTORE],
  },
});
