import { TerminalPricing } from "../state/terminalPricingState";
import { data } from "./data";
import { ContractId, Country } from "../model/common/commonType";
import { TerminalType } from "../model/contract/contractType";

export const BASE_URL = "/api/sales/contracts/terminal-prices";

enum PriceType {
  NONE = "NONE",
  ONE_TIME = "ONE_TIME",
  PER_MONTH = "PER_MONTH",
  PER_TRANSACTION = "PER_TRANSACTION",
}

export enum ArticleName {
  INSTALLATION_PLUG_AND_PLAY = "INSTALLATION_PLUG_AND_PLAY",
  SIM_CARD = "SIM_CARD",
}

export interface Article {
  article: ArticleName;
  articleNo: string;
  articleDescription: string;
  priceType: PriceType;
  price: number | undefined;
}

export interface TerminalConfigurationOptions {
  terminalType: TerminalType;
  availableMonthlyCosts: number[];
  availableOneTimeCosts: number[];
  defaultMonthlyCost: number;
  defaultOneTimeCost: number;
  articles?: Article[];
}
export interface TerminalPricingByCountries {
  [Country.CROATIA]: TerminalConfigurationOptions[];
  [Country.POLAND]: TerminalConfigurationOptions[];
  [Country.CZECHIA]: TerminalConfigurationOptions[];
}

export interface UpdateTerminalPricingDto {
  monthlyCost: number;
  oneTimeCost: number;
}

export interface TerminalPricingResponse {
  terminalType: TerminalType;
  monthlyCost: number;
  oneTimeCost: number;
}

// perhaps create different ones depending on country differences
const DEFAULT_TEMRINAL_PRICE_DETAILS: TerminalConfigurationOptions[] = [
  {
    terminalType: TerminalType.SOFT_POS,
    availableMonthlyCosts: [5],
    availableOneTimeCosts: [0],
    defaultMonthlyCost: 5,
    defaultOneTimeCost: 0,
  },
  {
    terminalType: TerminalType.DESK_3500,
    availableMonthlyCosts: [25, 29, 35, 39],
    availableOneTimeCosts: [0],
    defaultMonthlyCost: 35,
    defaultOneTimeCost: 0,
  },
  {
    terminalType: TerminalType.MOVE_3500,
    availableMonthlyCosts: [25, 29, 35, 39],
    availableOneTimeCosts: [0],
    defaultMonthlyCost: 39,
    defaultOneTimeCost: 0,
  },
];

export const getDefaultTerminalPriceDetails = (country: Country): TerminalConfigurationOptions[] => {
  switch (country) {
    case Country.POLAND:
      return DEFAULT_TEMRINAL_PRICE_DETAILS;
    case Country.CROATIA:
      return DEFAULT_TEMRINAL_PRICE_DETAILS;
    case Country.CZECHIA:
      return DEFAULT_TEMRINAL_PRICE_DETAILS;
    default:
      return DEFAULT_TEMRINAL_PRICE_DETAILS;
  }
};

export const dataTerminalPricing = {
  updateMonthlyTerminalCost: (
    contractId: ContractId,
    terminalType: TerminalType,
    payload: UpdateTerminalPricingDto
  ) =>
    data.post<TerminalPricing>(
      `${BASE_URL}/contract-id/${contractId}/terminal-type/${terminalType}/update`,
      payload
    ),

  getContractTerminalPrices: (contractId: ContractId, options?: RequestInit) =>
    data.get<TerminalPricingResponse[]>(`${BASE_URL}/contract-id/${contractId}`, true, options),

  getTerminalConfigurationOptions: (country: Country, options?: RequestInit) =>
    data.get<TerminalConfigurationOptions[]>(`${BASE_URL}/country/${country}`),

  getAllCountriesTerminalPrices: () => data.get<TerminalPricingByCountries>(`${BASE_URL}/all`),
};
