import { Filter, defaultFilter } from "../Store";

export const FilterUtils = {
  searchToFilter: (search: string) => {
    const params = new URLSearchParams(search);
    const keys: { [key: string]: string } = {};
    params.forEach((value, key) => {
      keys[key] = value;
    });
    return FilterUtils.paramsToFilter(keys);
  },

  getQueryFromFilter: (filter: Filter = defaultFilter) => {
    return `?${FilterUtils.getParams(filter).toString()}`;
  },

  paramsToFilter: (params: { [key: string]: string }) => {
    const filter: { [key: string]: string | number } = { ...params };

    if (!params.page) {
      return defaultFilter;
    }

    filter.page = parseInt(params.page, 10);
    if (params.size) {
      filter.size = parseInt(params.size, 10);
    }

    return filter as unknown as Filter;
  },

  getParams: (filter: Filter) => {
    const params = new URLSearchParams();
    (Object.keys(filter) as Array<keyof typeof filter>).forEach((key) => {
      if (key === "page") {
        params.set(key, filter[key]?.toString());
      } else {
        if (filter[key] === "" || filter[key] === null || filter[key] === undefined) {
          return;
        }

        params.set(key, (filter[key] || "")?.toString());
      }
    });

    return params;
  },
};
