import React from "react";
import { FC } from "react";
import { AnimatePresence, MotionProps, motion } from "framer-motion";

interface Props {
  className?: string;
  animatePresence?: boolean;
  open?: boolean;
  animationKey?: string;
}

export const FADE_ANIMATION_PROPS: MotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const FadeAnimation: FC<Props> = ({
  children,
  className,
  animatePresence = false,
  open = true,
  animationKey,
}) => {
  if (animatePresence) {
    return (
      <AnimatePresence>
        {open && (
          <motion.div className={className} {...FADE_ANIMATION_PROPS}>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    );
  }

  return (
    <>
      {open && (
        <motion.div key={animationKey} className={className} {...FADE_ANIMATION_PROPS}>
          {children}
        </motion.div>
      )}
    </>
  );
};

export default FadeAnimation;
