import React from "react";
import { Associate } from "../../model/associate/associateTypes";

interface Props {
  associate: Associate;
}

export const Name: React.FunctionComponent<Props> = ({ associate }) => {
  if (associate.firstName && associate.lastName) {
    return (
      <>
        {associate.lastName}, {associate.firstName}
      </>
    );
  }

  return <>{associate.firstName}</> || <>{associate.lastName}</> || <>&nbsp;</>;
};
