import { RefObject, useCallback, useMemo, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

import styles from "./AddressWithSearch.module.scss";

import { addressFieldRequiredText, addressFieldValidators } from "./addressUtils";
import { AddressPopover } from "./AddressPopover";
import { AddressSearch } from "./AddressSearch";
import { Address } from "../../model/contract/contractType";
import { HiddenInput } from "../form/HiddenInput";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { T } from "../translation/T";
import { IoLocationOutline } from "react-icons/io5";
import { Button } from "../interactions/Buttons/Button";
import { CloseIcon } from "../icons/CloseIcon";
import { Flag } from "../flags/Flag";
import { Link } from "../links/Link";
import cx from "classnames";
import { Country } from "../../model/common/commonType";

interface SharedProps {
  address: Partial<Address>;
  onChange: (address: Partial<Address>) => void;
  disabled?: boolean;
  scrollToRef?: RefObject<HTMLElement>;
  label?: string;
  noLabel?: boolean;
  country: Country;
}

interface PropsWithoutCountryCode extends SharedProps {
  addressRequiredFields?: Record<keyof Omit<Address, "countryCode">, boolean>;
  showCountryCode?: false;
}

interface PropsWithCountryCode extends SharedProps {
  addressRequiredFields: Record<keyof Address, boolean>;
  showCountryCode: true;
}

type Props = PropsWithoutCountryCode | PropsWithCountryCode;

export const AddressWithSearch = ({
  address,
  onChange,
  addressRequiredFields = {
    street: false,
    city: false,
    postalCode: false,
    countryCode: false,
    streetNumber: false,
  },
  disabled,
  label,
  noLabel,
  country,
  ...props
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const secondAddressRow = useMemo(() => {
    return [address.city, address.postalCode].filter(Boolean).join(", ");
  }, [address]);

  const onAddressSelect = useCallback(
    (location: Partial<Address>) => {
      onChange({
        ...address,
        ...location,
      });
    },
    [onChange, address]
  );

  const googleMapsLink = useMemo(() => {
    if (Object.values(address).filter(Boolean).length <= 2) {
      return;
    }

    const addressString = [
      `${address.street}`,
      address.city,
      address.postalCode,
      address.countryCode || "Poland",
    ].join(", ");

    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addressString)}`;
  }, [address]);

  const hasFullAddress = address.street && address.streetNumber && address.city && address.postalCode;

  return (
    <div className={styles.addressWithSearch} ref={ref}>
      <div className={styles.top}>
        <div className={styles.searchWrapper}>
          <AddressSearch onChange={onAddressSelect} {...{ disabled, label }} country={country} />
        </div>
        <div className={styles.manual_button}>
          <AddressPopover
            onChange={onAddressSelect}
            {...{ ...props, address, addressRequiredFields, disabled }}
          />
        </div>
      </div>

      <AnimatePresence initial={false}>
        <motion.div
          className={cx(styles.addressContainer, { [styles.addressContainer_fullAddress]: hasFullAddress })}
          key={address.street + secondAddressRow}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
        >
          <IoLocationOutline className={styles.locationIcon} />
          <ul>
            <li className="fw-500">
              {hasFullAddress ? (
                <Link link={googleMapsLink ?? ""} external>
                  {`${address.street} ${address.streetNumber}`}
                </Link>
              ) : address.street ? (
                <span>{address.street}</span>
              ) : (
                <span className="text-passive">
                  <T>No address set</T>
                </span>
              )}
            </li>

            <li className={styles.secondAddressRow}>
              {secondAddressRow || ""}{" "}
              {address.countryCode && <Flag lang={address.countryCode ?? ""} size={"1em"} />}
            </li>
          </ul>

          {hasFullAddress && !disabled && (
            <Button
              onClick={() =>
                onAddressSelect({
                  street: undefined,
                  streetNumber: undefined,
                  postalCode: undefined,
                  countryCode: undefined,
                  city: undefined,
                })
              }
              action
              variant="text"
              color="secondary"
              className={styles.closeButton}
            >
              <CloseIcon />
            </Button>
          )}
        </motion.div>
      </AnimatePresence>

      <div className={styles.validationWrapper}>
        {Object.entries(addressRequiredFields).map(([key, required]) => {
          if (!required) {
            return null;
          }
          return (
            <HiddenInput
              noMargins
              key={key}
              name={key}
              value={address[key as keyof Address]}
              scrollToRef={ref}
              validators={[
                ...addressFieldValidators[key as keyof Address],
                ...(required ? [new RequiredValidator(addressFieldRequiredText[key as keyof Address])] : []),
              ]}
              label={""}
            />
          );
        })}
      </div>
    </div>
  );
};
