import React, { memo, useCallback, useMemo, useState } from "react";
import cx from "classnames";
import { LinkAnchors } from "../ContractEdit";
import { contractStoresState } from "../../../state/contractStoresState";
import { useRecoilState, useRecoilValue } from "recoil";
import { FieldSet } from "../../../components/fieldSet/FieldSet";
import { contractPackageState } from "../../../state/contractPackageState";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { useTranslation } from "react-i18next";
import { ScrollPositionAnchor } from "../../../components/scrollPosition/ScrollPositionAnchor";
import { contractStatusState } from "../../../state/contractStatusState";
import { Button } from "../../../components/interactions/Buttons/Button";
import { AnimatePresence } from "framer-motion";
import { StoreRow } from "./StoreRow/StoreRow";
import { Cas, StoreId } from "../../../model/common/commonType";
import { SaveNewStoreRequest, dataContracts } from "../../../data/dataContracts";
import { getEditStatus } from "../ContractPage";
import { Status } from "../../../data/types";
import { contractPricingState } from "../../../state/contractCostState";
import { isPack } from "../../../model/pricing/pricingUtils";
import { SectionFieldSetHeader } from "../../../components/sectionFieldSet/sectionFieldSetHeader";
import { ContractType } from "../../../model/contract/contractType";
import { getContractTypeDisplayString } from "../../../model/contract/contractUtils";
import useContractData from "../../../hooks/useContractData";
import { FormName } from "../menus/ContractEditMenu";

const StoreRowMemo = memo(StoreRow);

export const Locations: React.FunctionComponent = () => {
  const [stores, setStores] = useRecoilState(contractStoresState);
  const { t } = useTranslation();
  const { isAdvancedPricing } = useContractData();
  const { packageId } = useRecoilValue(contractPackageState);
  const { contractId, edit } = useRecoilValue(contractStatusState);
  const { pricingModel, storeLimitation } = useRecoilValue(contractPricingState).INSTORE;
  const [expandedStoreId, setExpandedStoreId] = useState<StoreId | undefined>(
    stores.length === 1 ? stores[0].storeId : undefined
  );

  const inputStatus = useMemo(() => getEditStatus(edit, Status.DEFAULT), [edit]);

  const cannotAddLocationsPack = isPack(pricingModel) && stores.length >= 1;
  const cannotAddLimitation = storeLimitation && stores.length >= storeLimitation;

  const ecomLocations = stores.filter((store) => store.contractType === ContractType.ECOMMERCE);
  const instoreLocations = stores.filter((store) => store.contractType === ContractType.INSTORE);

  const addNewStore = useCallback(() => {
    dataContracts
      .saveStore(contractId, getSaveNewStoreRequest(ContractType.INSTORE))
      .then((response) => {
        const newStore = response[response.length - 1];
        if (!expandedStoreId) setExpandedStoreId(newStore.storeId);
        setStores(response);
      })
      .catch((err) => console.log("could not add store: ", err));
  }, [contractId, expandedStoreId, setStores]);

  const onClickStoreRow = useCallback(
    (storeId: StoreId) => setExpandedStoreId((prevId) => (prevId !== storeId ? storeId : undefined)),
    []
  );

  if (isAdvancedPricing) {
    return (
      <div className="stores new-pricing">
        <ScrollPositionAnchor id={LinkAnchors.TERMINALS.anchor} />
        <SectionFieldSetHeader headerTitle={t(LinkAnchors.TERMINALS.name)} formName={FormName.STORE} />

        {instoreLocations.length > 0 && (
          <div className="m-bottom-30">
            <div className="fw-600 m-bottom-10">{`${t(
              getContractTypeDisplayString(ContractType.INSTORE)
            )} locations`}</div>
            <AnimatePresence initial={false}>
              {instoreLocations.map((store) => (
                <StoreRowMemo
                  key={store.storeId}
                  store={store}
                  status={inputStatus}
                  isActive={expandedStoreId === store.storeId}
                  onClick={onClickStoreRow}
                />
              ))}
            </AnimatePresence>

            <Button
              block
              onClick={addNewStore}
              className="m-top-20"
              variant="text"
              status={cannotAddLimitation || cannotAddLocationsPack ? Status.DISABLED : inputStatus}
            >
              {t("Add additional location")}
            </Button>
            {cannotAddLocationsPack && (
              <div className="center m-top-10">
                <i className="fw-500 text-passive fs-small ">
                  {t("Pack price plans are limited to 1 store location")}
                </i>
              </div>
            )}
            {cannotAddLimitation && (
              <div className="center m-top-10">
                <i className="fw-500 text-passive fs-small ">
                  {t("The current price plan is limited to {{storeLimitation}} stores", { storeLimitation })}
                </i>
              </div>
            )}
          </div>
        )}
        {ecomLocations.length > 0 && (
          <>
            <div className="fw-600 m-bottom-10">{`${t(
              getContractTypeDisplayString(ContractType.ECOMMERCE)
            )} locations`}</div>
            <AnimatePresence initial={false}>
              {ecomLocations.map((store) => (
                <StoreRowMemo
                  key={store.storeId}
                  store={store}
                  status={inputStatus}
                  isActive={expandedStoreId === store.storeId}
                  onClick={onClickStoreRow}
                />
              ))}
            </AnimatePresence>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="stores">
      <ScrollPositionAnchor id={LinkAnchors.TERMINALS.anchor} />

      <FieldSet header={t(LinkAnchors.TERMINALS.name)}>
        <div
          className={cx("no-package-warning", {
            "has-package": !!packageId,
          })}
        >
          <ErrorBox>{t("Please select a package before adding terminals")}</ErrorBox>
        </div>

        {packageId && (
          <div>
            {stores.map((store) => (
              <StoreRow
                status={inputStatus}
                key={store.storeId}
                store={store}
                isActive={true}
                onClick={() =>
                  setExpandedStoreId(expandedStoreId !== store.storeId ? store.storeId : undefined)
                }
              />
            ))}
          </div>
        )}
      </FieldSet>
    </div>
  );
};

const getSaveNewStoreRequest = (contractType: ContractType) => {
  const request: SaveNewStoreRequest = {
    cas: 0 as Cas,
    commercialName: "",
    address: {},
    contact: {},
    terminals: [],
    contractType,
  };

  return request;
};
