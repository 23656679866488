import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { MultiForm } from "../../components/form/MultiForm";
import { PermissionError } from "../../components/permissionError/PermissionError";
import { ScrollPosition } from "../../components/scrollPosition/ScrollPosition";
import { trimAllButAlphaNumeric } from "../../components/utils";
import { SessionResponse } from "../../data/dataAuth";
import { CAS_ERROR_CODE, CLAIM_ERROR_CODE, dataContracts, SERVER_ERROR_CODE } from "../../data/dataContracts";
import { ContractId } from "../../model/common/commonType";
import { Contract, ContractFeature, ContractStatus, ContractType } from "../../model/contract/contractType";
import { Status } from "../../data/types";
import { useIsMountedRef } from "../../hooks/useIsMounted";
import { ServerError } from "../../network/API";
import { contractAssociateState } from "../../state/contractAssociateState";
import { contractBankState } from "../../state/contractBankState";
import { INITIAL_CONTRACT_PRICING_STATE, contractPricingState } from "../../state/contractCostState";
import { contractDocumentsState } from "../../state/contractDocumentsState";
import { contractMainContractDataState } from "../../state/contractMainContractDataState";
import { contractPackageState } from "../../state/contractPackageState";
import { ContractStatusState, contractStatusState } from "../../state/contractStatusState";
import { contractStoresState } from "../../state/contractStoresState";
import { userState } from "../../state/userState";
import { ContractEdit } from "./ContractEdit";
import "./ContractPage.scss";

import { Retry } from "../../components/retry/Retry";
import { Page, PageType } from "../Page/Page";

export const CONTRACT_ROUTE = "/contract/:id";

export enum ContractSaveError {
  CAS_ERROR,
  CLAIM_ERROR,
  SERVER_ERROR,
  SAVE_ERROR,
}

export function handleError(err: ServerError<any>, setError: (contractError: ContractSaveError) => void) {
  if (err.status === CLAIM_ERROR_CODE) {
    setError(ContractSaveError.CLAIM_ERROR);
    return;
  }

  if (err.status === CAS_ERROR_CODE) {
    setError(ContractSaveError.CAS_ERROR);
    return;
  }

  if (err.status === SERVER_ERROR_CODE) {
    setError(ContractSaveError.SERVER_ERROR);
    return;
  }

  setError(ContractSaveError.SAVE_ERROR);
}

export function getEditStatus(edit: boolean, status: Status) {
  if (!edit) {
    return Status.DISABLED;
  }

  return status;
}

//TODO flytta till nåt mer gemensamt för contract
export function getContractIsEditable(contract: ContractStatusState | Contract) {
  return (
    contract.status === ContractStatus.PENDING_SALES_INPUT ||
    contract.status === ContractStatus.MERCHANT_VERIFICATION
  );
}

export function getUserIsAllowedToEdit(
  contract: ContractStatusState | Contract,
  user: SessionResponse | null
) {
  if (!user) {
    return false;
  }

  return user.userId === contract.currentSalesUser;
}

const ContractInner: React.FunctionComponent<{ contract?: Contract }> = ({ contract }) => {
  if (!contract) {
    return null;
  }

  return <ContractEdit />;
};

export const ContractPage: React.FunctionComponent = () => {
  const [contract, setContract] = useState<Contract>();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const { id } = useParams<{ id: ContractId }>();
  const setBank = useSetRecoilState(contractBankState);
  const setMainData = useSetRecoilState(contractMainContractDataState);
  const setAssociates = useSetRecoilState(contractAssociateState);
  const setPackage = useSetRecoilState(contractPackageState);
  const setContractStatus = useSetRecoilState(contractStatusState);
  const setStores = useSetRecoilState(contractStoresState);
  const setDocuments = useSetRecoilState(contractDocumentsState);
  const user = useRecoilValue(userState);
  const mounted = useIsMountedRef();
  const [missingPermission, setMissingPermission] = useState(false);
  const setContractPricing = useSetRecoilState(contractPricingState);

  const load = useCallback(
    async (contractId) => {
      setStatus(Status.PENDING);

      try {
        const contractResponse = await dataContracts.loadContract(contractId, true);
        const contractIsEditable = getContractIsEditable(contractResponse);
        const userIsAllowedToEdit = getUserIsAllowedToEdit(contractResponse, user);

        setContract(contractResponse);
        setBank(contractResponse.bankAccount);
        setAssociates(contractResponse.associates);
        setPackage({ cashlessPromotion: false, ...contractResponse.contractPackage });
        setMainData({
          acceptance: false,
          ...contractResponse.contractData,
          vatNumber: trimAllButAlphaNumeric(contractResponse.contractData.vatNumber),
          contractType: contractResponse.contractType,
        });

        const features = contractResponse.enabledFeatures;
        if (
          window.location.search.includes("?calc") &&
          !features.includes(ContractFeature.ADVANCED_PRICING)
        ) {
          features.push(ContractFeature.ADVANCED_PRICING);
        }

        setContractStatus({
          contractId: contractResponse.contractId,
          status: contractResponse.status,
          created: contractResponse.created,
          country: contractResponse.country,
          lastUpdated: contractResponse.lastUpdated,
          completed: contractResponse.completed,
          currentSalesUser: contractResponse.currentSalesUser,
          teamId: contractResponse.teamId,
          edit: contractIsEditable && userIsAllowedToEdit,
          enabledFeatures: features,
        });
        setStores(contractResponse.stores);
        setDocuments(contractResponse.documents);

        if (contractResponse.contractPricing && contractResponse.contractPricing.length > 0) {
          const instorePricing = contractResponse.contractPricing.find(
            (pricing) => pricing.contractType === ContractType.INSTORE
          );
          const ecomPricing = contractResponse.contractPricing.find(
            (pricing) => pricing.contractType === ContractType.ECOMMERCE
          );

          setContractPricing((prev) => ({
            [ContractType.ECOMMERCE]: { ...prev.ECOMMERCE, ...ecomPricing },
            [ContractType.INSTORE]: { ...prev.INSTORE, ...instorePricing },
          }));
        } else {
          setContractPricing(INITIAL_CONTRACT_PRICING_STATE);
        }

        setStatus(Status.SUCCESS);
      } catch (error: any) {
        if (error.status === 403) {
          setMissingPermission(true);
        }
        setStatus(Status.ERROR);
      }
    },
    [
      setBank,
      setMainData,
      setAssociates,
      setPackage,
      setContractStatus,
      setStores,
      setDocuments,
      setContractPricing,
      user,
    ]
  );

  useEffect(() => {
    load(id as ContractId);
  }, [load, id]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(() => {
      if (!mounted.current) {
        return;
      }
      load(id);
    }, 300);
  }, [load, id, mounted]);

  return (
    <MultiForm>
      <ScrollPosition>
        <Page title="Contract" pageType={PageType.SALES_CONTRACT}>
          {missingPermission ? (
            <PermissionError />
          ) : (
            <Retry retry={retry} status={status}>
              <ContractInner contract={contract} />
            </Retry>
          )}
        </Page>
      </ScrollPosition>
    </MultiForm>
  );
};
