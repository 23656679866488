import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useMemo } from "react";
import { convertArrayToObjectByKey } from "../../../../components/utils";
import {
  CardBrand,
  EditablePricingTemplate,
  FeeType,
  OptionalTransactionFee,
  PricingModel,
} from "../../../../model/pricing/pricingTypes";
import {
  FeeInput,
  GetUpdatedFeesArgs,
  UpdateFeeInputArgs,
  getUpdatedFees,
  updateTariffClassGroupedFees,
} from "./FeeInput";
import { FeeBrandLogo } from "./FeeBrandLogo";
import "./View.scss";
import { Country } from "../../../../model/common/commonType";
import { groupFees } from "../../../../model/pricing/transactionFeesUtils";
import { NumberInput } from "../../../../components/form/NumberInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { MinValidator } from "../../../../components/form/validators/MinValidator";

interface Props {
  disabled?: boolean;
  pricingModel: PricingModel;
  transactionFees: OptionalTransactionFee[];
  setEditablePricing?: Dispatch<SetStateAction<EditablePricingTemplate>>;
  country: Country;
  isSalesComponent?: boolean;
}

export const Group: FunctionComponent<Props> = ({
  disabled,
  pricingModel,
  transactionFees,
  setEditablePricing,
  country,
  isSalesComponent,
}) => {
  const isActive = !disabled;
  const map = useMemo(() => convertArrayToObjectByKey(transactionFees, "brand"), [transactionFees]);

  const groupedFees = useMemo(
    () => groupFees(transactionFees, pricingModel),
    [transactionFees, pricingModel]
  );

  const updateFeeInput = useCallback(
    (updateArgs: UpdateFeeInputArgs) => {
      if (!setEditablePricing) return;

      setEditablePricing((prev) => {
        const args: GetUpdatedFeesArgs = {
          ...updateArgs,
          value: updateArgs.value?.toString() ?? "",
          transactionFees: prev.transactionFees,
        };

        const updatedFees = getUpdatedFees(args);

        return {
          ...prev,
          transactionFees: updatedFees,
        };
      });
    },
    [setEditablePricing]
  );

  const updateTariffClass = (value: number | undefined, additionalBrands: CardBrand[]) => {
    if (!setEditablePricing || value === undefined) return;

    setEditablePricing((prev) => {
      const updatedFees = updateTariffClassGroupedFees({
        value: value,
        additionalBrands,
        transactionFees: prev.transactionFees,
      });

      return {
        ...prev,
        transactionFees: updatedFees,
      };
    });
  };

  return (
    <div className="pricing-view">
      {groupedFees.map((group) => {
        // Grab the first brand in the group to use as the key for fees
        // since all should be the same anyway
        const firstBrandInGroup = group[0].brand;
        const groupedBrands = group.map((fee) => fee.brand);

        const tariffClass = group[0].tariffClass;

        return (
          <React.Fragment key={groupedBrands.join()}>
            <div>
              <div className="logo-family">
                <ul>
                  {[...groupedBrands]
                    .sort((a, b) => a.localeCompare(b))
                    .map((brand) => (
                      <li key={brand}>
                        <FeeBrandLogo brand={brand} />
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="card-column-inputs">
              <div>
                <FeeInput
                  pricingModel={pricingModel}
                  updateFeeInput={updateFeeInput}
                  item={map[firstBrandInGroup]}
                  feeType={FeeType.TRANSACTION}
                  required={false}
                  active={isActive}
                  additionalBrands={groupedBrands}
                  country={country}
                />
              </div>

              <div>
                <FeeInput
                  pricingModel={pricingModel}
                  updateFeeInput={updateFeeInput}
                  item={map[firstBrandInGroup]}
                  feeType={FeeType.MIN}
                  required={false}
                  active={isActive}
                  additionalBrands={groupedBrands}
                  country={country}
                />
              </div>

              <div>
                <FeeInput
                  pricingModel={pricingModel}
                  updateFeeInput={updateFeeInput}
                  item={map[firstBrandInGroup]}
                  feeType={FeeType.MAX}
                  required={false}
                  active={isActive}
                  additionalBrands={groupedBrands}
                  country={country}
                />
              </div>

              <div>
                <FeeInput
                  pricingModel={pricingModel}
                  updateFeeInput={updateFeeInput}
                  item={map[firstBrandInGroup]}
                  feeType={FeeType.FIXED}
                  required={false}
                  active={isActive}
                  additionalBrands={groupedBrands}
                  country={country}
                />
              </div>

              {!isSalesComponent && (
                <div>
                  <NumberInput
                    onChange={(value) => updateTariffClass(value, groupedBrands)}
                    label={"Tariff class"}
                    validators={[
                      new RequiredValidator("Tariff class is required"),
                      new MinValidator(1, "Tariff class must be a positive number"),
                    ]}
                    value={tariffClass}
                    message={"Needed for provisioning to iPass"}
                  />
                </div>
              )}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
