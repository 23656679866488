import { FC } from "react";
import cx from "classnames";
import { StatusBox, StatusBoxImplProps } from "./StatusBox";

export const DefaultBox: FC<StatusBoxImplProps> = ({ children, relative, ...otherProps }) => {
  return (
    <StatusBox
      {...otherProps}
      className={cx("status-box", "default-box", {
        relative,
      })}
    >
      {children}
    </StatusBox>
  );
};
