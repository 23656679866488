import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { FieldSet } from "../../../components/fieldSet/FieldSet";
import { Pagination } from "../../../components/pagination/Pagination";
import { Table } from "../../../components/table/Table";
import { FormattedDate } from "../../../components/time/FormattedDate";
import { ContractAudit, ContractAuditType, dataAudit } from "../../../data/dataAudit";
import { ContractId } from "../../../model/common/commonType";
import { AdminPage } from "../AdminPage";

export const CONTRACT_AUDIT_PAGE = "/admin/audit/contract/:contractId";

type PageParams = {
  contractId: ContractId;
  page?: string;
};

const actionTexts: Record<ContractAuditType, string> = {
  VIEW_CONTRACT: "Viewed contract",
  NEW_CONTRACT: "Created contract",
  DELETED_CONTRACT: "Deleted contract",
  RETRACT_CONTRACT: "Retracted contract",
  CLAIM_CONTRACT: "Claimed contract",
  SIGNEE_SELECTION: "Selected signee(s)",
  SIGNEE_CONFIRMATION: "Confirmed signee(s)",
  SIGNEE_REJECTION: "Rejected signee(s)",
  OWNER_SELECTION: "Selected owner(s)",
  OWNER_CONFIRMATION: "Confirmed owner(s)",
  SEND_CONFIRMATION: "Sent confirmation",
  MARK_COMPLETE: "Marked complete",
  LOCAL_REGISTRY_UPDATE: "Data loaded from registry",
  LINK_REMINDER: "Sent link reminder",
  LINK_REVOKE: "Revoked link",
  DOCUMENT_UPLOAD: "Uploaded document",
  DOCUMENT_DELETE: "Deleted document",
  DOCUMENT_REJECT: "Rejected document",
  DOCUMENT_CONFIRM: "Confirmed document",
  ASSOCIATE_SIGNOFF: "Confirmed associate",
};

export const ContractAuditPage = () => {
  const { contractId, page } = useParams<PageParams>();
  const [auditData, setAuditData] = useState<ContractAudit>();
  const [auditPage, setAuditPage] = useState(page ? Number(page) : 0);

  useEffect(() => {
    dataAudit.loadContractAuditLog(contractId, auditPage).then(setAuditData);
  }, [contractId, auditPage]);

  return (
    <AdminPage className={"light-theme edit-sales-user"}>
      <section>
        <article>
          <FieldSet header="Contract audit log">
            <div>
              <Table>
                <thead>
                  <tr>
                    <th>Timestamp</th>
                    <th>User</th>
                    <th>Action</th>
                    <th>Ip</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {auditData?.auditLog.content.map((log) => (
                    <tr key={log.id}>
                      <td className="truncate">
                        <FormattedDate date={log.created} pattern="yyyy-MM-dd HH:mm:ss" />
                      </td>
                      <td className="truncate">{log.salesUserName}</td>
                      <td className="truncate">{actionTexts[log.type]}</td>
                      <td className="truncate">{log.ip}</td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="m-top-10">
                <Pagination
                  activePage={auditData ? auditData.auditLog.number : 0}
                  totalPages={auditData ? auditData.auditLog.totalPages : 0}
                  onPageChange={setAuditPage}
                />
              </div>
            </div>
          </FieldSet>
        </article>
      </section>
    </AdminPage>
  );
};
