import React, { useState, useCallback, useRef } from "react";

import { LinkAnchors } from "../ContractEdit";
import { ContractEditError } from "../ContractEditError";
import { Status } from "../../../data/types";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { dataContracts } from "../../../data/dataContracts";
import { contractStatusState } from "../../../state/contractStatusState";
import { ContractSaveError, handleError } from "../ContractPage";
import { ContractPackage, PackageId } from "../../../model/contract/contractType";
import { useTranslation } from "react-i18next";
import { contractPackageState } from "../../../state/contractPackageState";
import { contractSaveState, contractErrorState } from "../../../state/contractSaveState";
import { contractTerminalsSelector } from "../../../state/contractStoresState";
import { AcquiringPL } from "../../../components/acquiring/AcquiringPL";
import { AcquiringHR } from "../../../components/acquiring/AcquiringHR";
import { userState } from "../../../state/userState";
import { Form } from "../../../components/form/Form";
import { FormName } from "../menus/ContractEditMenu";
import { Country } from "../../../model/common/commonType";
import { CashlessPromotion } from "./CashlessPromotion";
import { PromotionMonths } from "../../../components/promotionMonths/PromotionMonths";
import { MonthlyTransactionVolume } from "./MonthlyTransactionVolume";
import { Terminals } from "./Terminals";
import { ScrollPositionAnchor } from "../../../components/scrollPosition/ScrollPositionAnchor";
import "./Offering.scss";
import { SectionFieldSet } from "../../../components/sectionFieldSet/SectionFieldSet";
import { CASHLESS_PROMOTION_MONTHS } from "../../../model/pricing/pricingUtils";

export function Offering() {
  const contractStatus = useRecoilValue(contractStatusState);
  const [offerPackage, setOfferPackage] = useRecoilState(contractPackageState);
  const [error, setError] = useState<ContractSaveError | null>(null);
  const terminals = useRecoilValue(contractTerminalsSelector);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);
  const user = useRecoilValue(userState);
  const prevPackage = useRef<any>();

  const country = user?.country as Country;

  const { t } = useTranslation();

  const onClose = useCallback(() => setError(null), []);

  const onSave = useCallback(
    (pack = prevPackage.current) => {
      if (!pack.packageId) {
        return;
      }

      dataContracts
        .updatePackage(contractStatus.contractId, {
          ...pack,
          promotionMonths: (pack as ContractPackage).cashlessPromotion
            ? CASHLESS_PROMOTION_MONTHS[country]
            : pack.promotionMonths,
        })
        .then((response) => {
          const copy = { ...response };
          delete copy.promotionMonths;
          setOfferPackage({ ...pack, ...copy });
          setDataSaved((dataSaved) =>
            dataSaved.concat({
              date: new Date(),
            })
          );
        })
        .catch((err) => {
          handleError(err, setError);
          setDataError((dataErrors) =>
            dataErrors.concat({
              date: new Date(),
            })
          );
        });
    },
    [contractStatus.contractId, country, setOfferPackage, setDataSaved, setDataError]
  );

  const retry = useCallback(() => {
    setError(null);
    setTimeout(onSave, 50);
  }, [onSave]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setTimeout(() => {
      dataContracts.claimContract(contractStatus.contractId).then(onSave).catch(onSave); // We're lazy, execute save again, which will fail,
      // and propagate error to Overlay
    }, 50);
  }, [contractStatus.contractId, onSave]);

  const onPackageChange = useCallback(
    (packageId: PackageId) => {
      if (!contractStatus.edit) {
        return;
      }

      const copy = {
        ...offerPackage,
        packageId,
      };
      setOfferPackage(copy);
      prevPackage.current = copy;
      onSave(copy);
    },
    [setOfferPackage, offerPackage, contractStatus.edit, onSave]
  );

  const onChange = useCallback(
    (val, name) => {
      const copy = {
        ...offerPackage,
        [name]: val,
      };
      setOfferPackage(copy);
      prevPackage.current = copy;
      onSave(copy);
    },
    [setOfferPackage, offerPackage, onSave]
  );

  const isDisabled = !contractStatus.edit || !!terminals.length;

  const offeringStart = new Date();
  if (offerPackage.cashlessPromotion) {
    offeringStart.setMonth(offeringStart.getMonth() + CASHLESS_PROMOTION_MONTHS[country]);
  } else if (offerPackage.promotionMonths && Number(offerPackage.promotionMonths)) {
    offeringStart.setMonth(offeringStart.getMonth() + offerPackage.promotionMonths);
  }

  return (
    <div className="offering">
      <ScrollPositionAnchor id={LinkAnchors.OFFERING.anchor} />

      <ContractEditError
        error={error}
        setError={setError}
        retry={retry}
        onClose={onClose}
        reclaimAndSave={reclaimAndSave}
      />

      <SectionFieldSet
        headerTitle={t(LinkAnchors.OFFERING.name)}
        formName={FormName.PACKAGE}
        sectionNumber={3}
      >
        <Form name={FormName.PACKAGE}>
          <div>
            <Terminals
              terminals={terminals}
              disabled={isDisabled}
              onChange={onPackageChange}
              selectedPackageId={offerPackage.packageId}
              country={country}
            />

            <CashlessPromotion
              onChange={onChange}
              cashlessPromotion={offerPackage.cashlessPromotion}
              country={country}
              inputStatus={contractStatus.edit ? Status.DEFAULT : Status.DISABLED}
            />

            <MonthlyTransactionVolume
              onChange={onChange}
              country={country}
              disabled={!contractStatus.edit}
              pricingStep={offerPackage.pricingStep}
            />

            <div className="m-bottom-30">
              <AcquiringHR pack={offerPackage} country={country} />
              <AcquiringPL pack={offerPackage} country={country} />
            </div>
          </div>

          <PromotionMonths />
        </Form>
      </SectionFieldSet>
    </div>
  );
}
