import { ValidationResponse, VALIDATION_STATE } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class NoLetterValidator extends BaseValidator {
  name = "NoLetterValidator";

  validate(value: number | string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      const letterRegex = /[a-zA-Z]/;

      if (typeof value === "string" && !letterRegex.test(value)) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
