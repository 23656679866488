import styles from "./Backdrop.module.scss";
import { FunctionComponent } from "react";
import cx from "classnames";
import { AnimatePresence, MotionProps, motion } from "framer-motion";
import { createPortal } from "react-dom";

export type BackdropVariants = "dark" | "blur";

interface Props {
  open: boolean;
  onClick: () => void;
  variant?: BackdropVariants;
}

const motionProps: MotionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.125 },
};

export const Backdrop: FunctionComponent<Props> = ({ open, onClick, variant = "blur" }) => {
  const backdropPortalEl = document.getElementById("backdrop-portal");

  const backdropClass = cx(styles.root, {
    [styles.dark]: variant === "dark",
    [styles.blur]: variant === "blur",
  });

  return (
    <>
      {backdropPortalEl &&
        createPortal(
          <AnimatePresence>
            {open && <motion.div className={backdropClass} onClick={onClick} {...motionProps} />}
          </AnimatePresence>,
          backdropPortalEl
        )}
    </>
  );
};
