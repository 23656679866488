import { atom } from "recoil";
import { Store, STORE_KEY, Theme } from "../Store";

export const TABLET_WIDTH = 768;

export enum ViewState {
  STRIPPED = "stripped",
  DEFAULT = "default",
}

export const themeState = atom<Theme>({
  key: "theme",
  default: Store.getValue(STORE_KEY.STORE_THEME),
});

export const viewState = atom<ViewState>({
  key: "viewState",
  default: ViewState.DEFAULT,
});

export const backdropState = atom<boolean>({
  key: "backdrop",
  default: false,
});
