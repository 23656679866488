import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { getContractStatusDisplayString } from "../../../components/contractStatus/ContractStatus";
import { ContractStatus } from "../../../model/contract/contractType";
import { Link } from "../../../components/links/Link";
import { DASHBOARD_ROUTE } from "../DashboardPage";
import { Filter as FilterInterface } from "../../../Store";
import { FilterUtils } from "../../../model/Filter";
import { useTranslation } from "react-i18next";
import styles from "./StatusFilter.module.scss";
import { FileIcon } from "../../../components/icons/FileIcon";
import { EditIcon } from "../../../components/icons/EditIcon";
import { EyeIcon } from "../../../components/icons/EyeIcon";
import { CheckIcon } from "../../../components/icons/CheckIcon";
import { CheckSquareIcon } from "../../../components/icons/CheckSquareIcon";
import { motion } from "framer-motion";
import { BUTTON_TRANSITION_PROPS } from "../../../components/interactions/Buttons/Button";
import { FaSignature } from "react-icons/fa";
import { StatusCount } from "../FilterView/ContractTableView";
import { Retry } from "../../../components/retry/Retry";
import { Status } from "../../../data/types";
import { dataContracts } from "../../../data/dataContracts";

interface Props {
  filter: FilterInterface;
}

const INITIAL_CONTRACT_STATUS: StatusCount = {
  [ContractStatus.MERCHANT_SIGNATURES]: 0,
  [ContractStatus.MERCHANT_VERIFICATION]: 0,
  [ContractStatus.PENDING_SALES_INPUT]: 0,
  [ContractStatus.SALES_VERIFICATION]: 0,
  [ContractStatus.COMPLETE]: 0,
  [ContractStatus.ANONYMISED]: 0,
};

const calcTotalContractCount = (statuses: StatusCount) => {
  return Object.values(statuses).reduce((total, count) => total + count, 0);
};

export const StatusFilter: React.FunctionComponent<Props> = ({ filter }) => {
  const [contractStatuses, setContractStatuses] = useState<StatusCount>(INITIAL_CONTRACT_STATUS);
  const [status, setStatus] = useState(Status.DEFAULT);

  const fetchStatuses = useCallback(() => {
    setStatus(Status.PENDING);
    dataContracts
      .loadContractsStatus()
      .then((response) => {
        setStatus(Status.DEFAULT);
        setContractStatuses(response.status);
      })
      .catch((err) => {
        console.log("error fetching contract statuses: ", err);
        setStatus(Status.ERROR);
      });
  }, []);

  useEffect(() => {
    fetchStatuses();
  }, [fetchStatuses]);

  const isAllActive = !filter.status;
  const totalContracts = calcTotalContractCount(contractStatuses);
  const allParam = FilterUtils.getParams({ ...filter, status: undefined });
  const allLinkRoute = `${DASHBOARD_ROUTE}?${allParam.toString()}`;

  return (
    <Retry status={status} retry={fetchStatuses}>
      <div className="dashboard-filter">
        <ul className={styles.breadcrumbs}>
          <BreadcrumbAll isActive={isAllActive} count={totalContracts} link={allLinkRoute} />
          {Object.keys(ContractStatus)
            .filter((s) => s !== ContractStatus.ANONYMISED) // Don't show anonymised
            .map((key) => {
              const status = key as ContractStatus;
              const newFilter: FilterInterface = { ...filter, status, page: 0 };
              const param = FilterUtils.getParams(newFilter);
              const linkRoute = `${DASHBOARD_ROUTE}?${param.toString()}`;
              const count = contractStatuses[status];
              const filterStatus = filter.status;
              const isActive = filterStatus === status;

              return (
                <Breadcrumb key={status} status={status} link={linkRoute} isActive={isActive} count={count} />
              );
            })}
        </ul>
      </div>
    </Retry>
  );
};

const getStatusIcon = (status: ContractStatus) => {
  switch (status) {
    case ContractStatus.PENDING_SALES_INPUT:
      return <EditIcon />;
    case ContractStatus.MERCHANT_VERIFICATION:
      return <CheckSquareIcon />;
    case ContractStatus.SALES_VERIFICATION:
      return <EyeIcon />;
    case ContractStatus.MERCHANT_SIGNATURES:
      return <FaSignature />;
    case ContractStatus.COMPLETE:
      return <CheckIcon />;
    default:
      return <FileIcon />;
  }
};

interface BreadcrumbsProps {
  link: string;
  isActive: boolean;
  status: ContractStatus;
  count: number;
}

interface BreadcrumbsAllProps {
  isActive: boolean;
  count: number;
  link: string;
}

const BreadcrumbAll: React.FunctionComponent<BreadcrumbsAllProps> = ({ isActive, count, link }) => {
  const { t } = useTranslation();

  return (
    <motion.li
      whileTap={{ scale: 0.975 }}
      transition={BUTTON_TRANSITION_PROPS}
      className={cx(styles.breadcrumb, {
        [styles.breadcumb_active]: isActive,
      })}
    >
      <Link link={link} className={styles.link}>
        <div className={cx(styles.icon_wrapper, styles.all)}>
          <FileIcon />
        </div>
        <div className={styles.data}>
          <span className={`${styles.status}`}>{t("All")}</span>
          <span className={styles.count}>{count}</span>
        </div>
      </Link>
    </motion.li>
  );
};

const Breadcrumb: React.FunctionComponent<BreadcrumbsProps> = ({ isActive, link, status, count }) => {
  const { t } = useTranslation();

  return (
    <motion.li
      whileTap={{ scale: 0.975 }}
      transition={BUTTON_TRANSITION_PROPS}
      className={cx(styles.breadcrumb, {
        [styles.breadcumb_active]: isActive,
      })}
    >
      <Link link={link} className={styles.link}>
        <div
          className={cx(styles.icon_wrapper, {
            [styles.creating]: status === ContractStatus.PENDING_SALES_INPUT,
            [styles.confirmation]: status === ContractStatus.MERCHANT_VERIFICATION,
            [styles.signing]: status === ContractStatus.MERCHANT_SIGNATURES,
            [styles.verification]: status === ContractStatus.SALES_VERIFICATION,
            [styles.completed]: status === ContractStatus.COMPLETE,
          })}
        >
          {getStatusIcon(status)}
        </div>
        <div className={styles.data}>
          <span className={`${styles.status}`}>{t(getContractStatusDisplayString(status, true))}</span>
          <span className={styles.count}>{count}</span>
        </div>
      </Link>
    </motion.li>
  );
};
