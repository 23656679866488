import { Trans, useTranslation } from "react-i18next";
import { DefaultBox } from "../../../components/boxes/DefaultBox";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { StatusBoxes } from "../../../components/boxes/StatusBoxes";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import { ConfirmButton, ConfirmType } from "../../../components/interactions/Buttons/ConfirmButton";
import { TCondition, TDefault } from "../../../components/translation/T";
import { TSwitch } from "../../../components/translation/TSwitch";
import { Status } from "../../../data/types";
import { dataConfirm } from "../../../data/dataConfirm";
import { Associate } from "../../../model/associate/associateTypes";
import { FC, useCallback, useState } from "react";
import { MerchantStatusState } from "../../../state/merchantStatusState";
import { ContractStatusState } from "../../../state/contractStatusState";
import { ContractStatus } from "../../../model/contract/contractType";
import { getMerchantStatusEdit } from "../MerchantStatus";
import styles from "./SendReminderBox.module.scss";
import { MailIcon } from "../../../components/icons/MailIcon";
import ContentBox from "../../../components/ContentBox/ContentBox";

interface Props {
  primaryContact: Associate | undefined;
  allConfirmed: boolean;
  merchantStatus: MerchantStatusState;
  contractStatus: ContractStatusState;
  status: Status;
}

export const SendReminderBox: FC<Props> = ({
  primaryContact,
  allConfirmed,
  merchantStatus,
  contractStatus,
  status,
}) => {
  const { t } = useTranslation();
  const { contractId, edit } = contractStatus;

  const [reminderStatus, setReminderStatus] = useState<Status>(Status.DEFAULT);

  const sendReminders = useCallback(() => {
    dataConfirm
      .sendReminders(contractId)
      .then(() => {
        setReminderStatus(Status.SUCCESS);
        setTimeout(() => setReminderStatus(Status.DEFAULT), 10000);
      })
      .catch(() => {
        setReminderStatus(Status.ERROR);
        setTimeout(() => setReminderStatus(Status.DEFAULT), 4000);
      });
  }, [contractId]);

  if (allConfirmed) return null;

  return (
    <>
      <ContentBox padding="s">
        <b className={styles.title}>
          <Trans>Send reminder</Trans>
          <MailIcon />
        </b>
        <div className={styles.statusBoxes}>
          <StatusBoxes status={reminderStatus}>
            <ErrorBox>
              <div>
                <Trans>
                  <b>Ooh!</b> Something went wrong. We couldn't send the reminders. Try again?
                </Trans>
              </div>
            </ErrorBox>

            <SuccessBox>
              <div>{t("Reminders have been sent!")}</div>
            </SuccessBox>

            <DefaultBox relative noPadding={true}>
              <TSwitch>
                <TCondition
                  id={`The primary contact has not yet confirmed the contract. You can send a reminder by clicking the button below.`}
                  condition={merchantStatus.status === ContractStatus.MERCHANT_VERIFICATION}
                />
                <TDefault>
                  Note that not all owners and/or legal representatives have been confirmed. You can send
                  reminders to those that haven't uploaded their signature / identification.
                </TDefault>
              </TSwitch>
            </DefaultBox>
          </StatusBoxes>
        </div>

        <ConfirmButton
          className="m-top-auto"
          confirmType={ConfirmType.SUCCESS}
          onClick={sendReminders}
          status={getMerchantStatusEdit(
            merchantStatus,
            edit,
            status === Status.SUCCESS ? Status.DISABLED : reminderStatus
          )}
          removeButtonText={t("Yes, send")}
        >
          <TSwitch>
            <TCondition
              id={`Send reminder to primary contact`}
              condition={merchantStatus.status === ContractStatus.MERCHANT_VERIFICATION}
            />
            <TDefault>Send reminders</TDefault>
          </TSwitch>
        </ConfirmButton>
      </ContentBox>
    </>
  );
};
