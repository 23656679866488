import { useEffect } from "react";
import { DateTime } from "luxon";
import { useLocation } from "react-router";
import { useSetRecoilState } from "recoil";
import { TODAY } from "../../data/dataStatistics";
import { AnalyticsFilterUtils } from "../../model/AnalyticsFilter";
import { analyticsFilterState } from "../../state/analyticsState";
import { defaultAnalyticsFilter, Store, STORE_KEY } from "../../Store";

export function PersistFilter() {
  const setAnalyticsFilter = useSetRecoilState(analyticsFilterState);
  const location = useLocation();

  useEffect(() => {
    let updatedFilter;

    if (AnalyticsFilterUtils.isValidQuery(location.search)) {
      updatedFilter = AnalyticsFilterUtils.queryToFilter(location.search);
    } else {
      updatedFilter = { ...defaultAnalyticsFilter };
    }

    setAnalyticsFilter(updatedFilter);
    let to = updatedFilter.to;

    if (DateTime.now().toISODate() === updatedFilter.to) {
      to = TODAY;
    }

    Store.setValue(STORE_KEY.STORE_ANALYTICS_FILTER, { ...updatedFilter, to });
  }, [location.search, setAnalyticsFilter]);

  return null;
}
