import { FC, ReactNode } from "react";
import { motion } from "framer-motion";
import styles from "./Accordions.module.scss";
import { AnimateHeightMotion } from "../animate/AnimateHeightMotion";
import cx from "classnames";
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";

interface Props {
  title: string;
  open: boolean;
  icon?: ReactNode;
  onClick: () => void;
}

const Accordion: FC<Props> = ({ title, open, children, icon, onClick }) => {
  return (
    <div className={styles.root}>
      <div
        className={cx(styles.header, {
          [styles.headerOpen]: open,
        })}
        onClick={onClick}
      >
        {icon && <div className={styles.icon}>{icon}</div>}
        <div className={styles.title}>{title}</div>
        <div className={styles.openIcon}>{open ? <FiMinus /> : <FiPlus />}</div>
      </div>
      <AnimateHeightMotion presence>
        {open && <motion.div className={styles.body}>{children}</motion.div>}
      </AnimateHeightMotion>
    </div>
  );
};

export default Accordion;
