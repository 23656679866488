import React, { useState, useEffect, useRef } from "react";
import { PricingModel, PricingTemplate } from "../../../../model/pricing/pricingTypes";
import { dataPricing, PricingSelected } from "../../../../data/dataPricing";
import { Status } from "../../../../data/types";
import { Spinner } from "../../../../components/spinner/Spinner";
import "./PricingPulse.scss";

interface Props {
  preSet: PricingTemplate;
  width: number;
  d3: Status;
}

const gradient = {
  [PricingModel.BLENDED]: ["#305338", "#71c2f9"],
  [PricingModel.ICPP]: ["#FC575E", "#71c2f9"],
  [PricingModel.PACK]: ["#FC575E", "#71c2f9"],
};

export const PricingPulse: React.FunctionComponent<Props> = ({ preSet, d3, width }) => {
  const [data, setData] = useState<PricingSelected[] | Status.ERROR>();
  const [path, setPath] = useState<string>("M0 0");
  const ref = useRef<HTMLDivElement>(null);
  const maxRef = useRef<number>(0);

  useEffect(() => {
    dataPricing
      .calculateStatisticssForPreset(preSet.templateId)
      .then((data) => setData(data))
      .catch((err) => setData(Status.ERROR));
  }, [preSet.templateId]);

  useEffect(() => {
    if (!data || data === Status.ERROR) {
      return;
    }

    if (d3 !== Status.SUCCESS) {
      return;
    }

    if (!ref.current) {
      return;
    }

    const box = ref.current.getBoundingClientRect();
    const max = window.d3.max(data, (d: PricingSelected) => d.count);
    maxRef.current = max;
    const y = window.d3
      .scaleLinear()
      .range([box.height, 0])
      .domain([0, max + 1]);

    const x = window.d3
      .scaleBand()
      .range([0, box.width])
      .domain(data.map((d) => d.date));

    const line = window.d3
      .line()
      .x(function (d: PricingSelected) {
        return x(d.date);
      })
      .y(function (d: PricingSelected) {
        return y(d.count);
      });

    setPath(line(data));
  }, [d3, data, width]);

  if (d3 === Status.ERROR || data === Status.ERROR) {
    return null;
    // return (
    //   <div className="pricing-pulse">
    //     <div className="pricing-pulse-overlay">
    //       Something went wrong. Can't show the graph.
    //     </div>
    //   </div>
    // );
  }

  if (d3 === Status.PENDING || !data) {
    return (
      <div className="pricing-pulse">
        <div className="pricing-pulse-overlay">
          <Spinner />
        </div>
      </div>
    );
  }

  let id = `gradient-${preSet.pricingModel}${preSet.templateId}`;

  return (
    <div className="pricing-pulse" ref={ref}>
      <span className="pricing-pulse-label fs-mini">Usage (year)</span>
      <svg>
        <defs>
          <linearGradient id={id} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="30%" stopColor={gradient[preSet.pricingModel][0]} />
            {/* <stop offset="75%" stopColor={gradient[preSet.pricingModel][1]} /> */}
            <stop offset="100%" stopColor={gradient[preSet.pricingModel][1]} />
          </linearGradient>
        </defs>
        <path d={path} stroke={maxRef.current === 0 ? gradient[preSet.pricingModel][1] : `url(#${id})`} />
      </svg>
    </div>
  );
};
