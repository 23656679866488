import { FC } from "react";

import styles from "./IpassMapping.module.scss";
import { NumberInput } from "../../../../../../../components/form/NumberInput";
import { TextInput } from "../../../../../../../components/form/TextInput";
import { RequiredValidator } from "../../../../../../../components/form/validators/RequiredValidator";
import { EditablePricingTemplate } from "../../../../../../../model/pricing/pricingTypes";
import { PricingUpdateType } from "../PricingInputs";
import ContentBox from "../../../../../../../components/ContentBox/ContentBox";

type TemplateProps = Pick<
  EditablePricingTemplate,
  "contractTemplateName" | "conditionCategoryCode" | "contractCategory"
>;

interface Props extends TemplateProps {
  update: (updates: Partial<PricingUpdateType>) => void;
  inputsDisabled: boolean;
}

export const IpassMapping: FC<Props> = ({
  update,
  inputsDisabled,
  conditionCategoryCode,
  contractCategory,
  contractTemplateName,
}) => {
  return (
    <ContentBox padding="no">
      <div className={styles.heading}>
        <h5>iPass mapping</h5>
        <p className="fs-small fw-500 text-passive" style={{ margin: 0 }}>
          These fields must correspond with the corresponding fields in iPass for the particular pricing,
          their purpose is to map the WLx price plan to iPass.
        </p>
      </div>
      <div className={styles.content}>
        <TextInput
          label="Contract template name"
          name="contractTemplateName"
          value={contractTemplateName}
          onChange={(newValue) => update({ contractTemplateName: newValue })}
          disabled={inputsDisabled}
          validators={[new RequiredValidator("Contract template name is required")]}
        />

        <NumberInput
          label="Contract category"
          name="contractCategory"
          value={contractCategory}
          onChange={(newValue) => update({ contractCategory: newValue })}
          disabled={inputsDisabled}
          validators={[new RequiredValidator("Contract category is required")]}
        />

        <NumberInput
          label="Condition category code"
          name="conditionCategoryCode"
          value={conditionCategoryCode}
          onChange={(newValue) => update({ conditionCategoryCode: newValue })}
          disabled={inputsDisabled}
          validators={[new RequiredValidator("Condition category code is required")]}
        />
      </div>
    </ContentBox>
  );
};
