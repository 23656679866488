import React, { RefObject } from "react";
import { designatedOwnerOpts } from "./OwnerAttributes";
import { Checkboxes } from "../interactions/Checkboxes/Checkboxes";
import { Status } from "../../data/types";
import { BeneficialOwnerType } from "../../model/contract/contractType";
import { AssociateOwner } from "../../model/associate/associateTypes";
import { useTranslation } from "react-i18next";
import { TextInput } from "../form/TextInput";
import { RequiredValidator } from "../form/validators/RequiredValidator";

interface Props {
  ownerForm: AssociateOwner;
  status?: Status;
  onChange: (value: string | string[], name: string) => void;
  beneficialOwnerType: BeneficialOwnerType;
  scrollToRef?: RefObject<HTMLElement>;
}

export const NoDirectNoMajority: React.FunctionComponent<Props> = ({
  ownerForm,
  onChange,
  status,
  beneficialOwnerType,
  scrollToRef,
}) => {
  const { t } = useTranslation();
  if (beneficialOwnerType !== BeneficialOwnerType.NO_DIRECT_NO_MAJORITY) {
    return null;
  }

  return (
    <div className="tablet-columns">
      <div>
        <Checkboxes
          onChange={onChange}
          alternatives={designatedOwnerOpts}
          label={<div>&nbsp;</div>}
          name="designatedOwner"
          values={ownerForm.designatedOwner ? ["designatedOwner"] : []}
          disabled={status === Status.DISABLED}
        />
      </div>

      <div>
        <TextInput
          onChange={onChange}
          label={t("Designated function")}
          name="designatedFunction"
          value={ownerForm.designatedFunction || ""}
          disabled={status === Status.DISABLED}
          validators={[new RequiredValidator(t("Designated function is required"))]}
          scrollToRef={scrollToRef}
        />
      </div>
    </div>
  );
};
