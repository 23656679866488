import { atom, selector } from "recoil";
import { ContractType, Store2 } from "../model/contract/contractType";
import { Terminal } from "../model/contract/contractType";
import { Article } from "../data/dataTerminalPricing";

export const contractStoresState = atom<Store2[]>({
  key: "contract-stores-state",
  default: [],
});

export const contractTerminalsSelector = selector({
  key: "contract-terminals-selector",
  get: ({ get }) => {
    const stores = get(contractStoresState);
    const initialValue: Terminal[] = [];
    return stores.reduce((a, b) => a.concat(b.terminals), initialValue);
  },
});

export const contractArticlesSelector = selector({
  key: "contract-articles-selector",
  get: ({ get }) => {
    const stores = get(contractStoresState);
    const allArticles: Article[] = [];

    stores.forEach(({ terminals }) => {
      terminals.forEach(({ articles }) => {
        if (articles) {
          allArticles.push(...articles);
        }
      });
    });

    return allArticles;
  },
});

export const contractInstoresSelector = selector({
  key: "contract-instore-selector",
  get: ({ get }) => {
    const stores = get(contractStoresState).filter((store) => store.contractType === ContractType.INSTORE);
    return stores as Store2<ContractType.INSTORE>[];
  },
});
