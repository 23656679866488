import React from "react";
import cx from "classnames";
import { MerchantStatusState } from "../../../../state/merchantStatusState";
import { ContractStatus } from "../../../../model/contract/contractType";
import { useTranslation } from "react-i18next";

interface Props {
  merchantStatus: MerchantStatusState;
  completeDate: string;
}

export const EventComplete: React.FunctionComponent<Props> = ({ merchantStatus, completeDate }) => {
  const { t } = useTranslation();
  return (
    <li
      className={cx({
        active: merchantStatus.status === ContractStatus.COMPLETE,
      })}
    >
      <div className="event">
        <div className="timeline-date">{completeDate}</div> <b>{t("Contract complete")}</b>
      </div>
    </li>
  );
};
