import React, { RefObject } from "react";
import { Status } from "../../data/types";
import { BeneficialOwnerType } from "../../model/contract/contractType";
import { AssociateOwner } from "../../model/associate/associateTypes";
import { useTranslation } from "react-i18next";
import { NumberInput } from "../form/NumberInput";
import { RequiredValidator } from "../form/validators/RequiredValidator";
import { MinValidator } from "../form/validators/MinValidator";
import { MaxValidator } from "../form/validators/MaxValidator";

interface Props {
  ownerForm: AssociateOwner;
  status?: Status;
  columnClass?: string;
  onChange: (value: number | undefined, name: string) => void;
  beneficialOwnerType: BeneficialOwnerType;
  scrollToRef?: RefObject<HTMLElement>;
}

export const SoleProprietor: React.FunctionComponent<Props> = ({
  ownerForm,
  onChange,
  status,
  beneficialOwnerType,
  scrollToRef,
}) => {
  const { t } = useTranslation();
  if (beneficialOwnerType !== BeneficialOwnerType.SOLE_PROPRIETOR) {
    return null;
  }

  return (
    <div className="tablet-columns">
      <div>
        <NumberInput
          onChange={onChange}
          label={t("Stake of voting rights")}
          name="votingRightsStake"
          hint={t("In percentage")}
          value={ownerForm.votingRightsStake}
          placeholder={t("e.g 30 %")}
          attributes={{ min: 25, max: 100 }}
          disabled={status === Status.DISABLED}
          validators={[
            new RequiredValidator(t("Stake of voting rights is required")),
            new MinValidator(25, t("Minimum stake is 25")),
            new MaxValidator(100, t("Minimum stake is 100")),
          ]}
          scrollToRef={scrollToRef}
          suffix=" %"
        />
      </div>
      <div>
        <NumberInput
          onChange={onChange}
          label={t("Stake of capital")}
          name="capitalStake"
          hint={t("In percentage")}
          value={ownerForm.capitalStake}
          placeholder={t("e.g 30 %")}
          attributes={{ min: 0, max: 100 }}
          disabled={status === Status.DISABLED}
          validators={[
            new RequiredValidator(t("Stake of capital is required")),
            new MinValidator(0, t("Minimum stake is 0")),
            new MaxValidator(100, t("Minimum stake is 100")),
          ]}
          scrollToRef={scrollToRef}
          suffix=" %"
        />
      </div>
      <div />
    </div>
  );
};
