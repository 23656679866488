import React from "react";
import { PersistFilter } from "../../components/analyticsFilter/PersistFilter";
import { T } from "../../components/translation/T";
import { CompletedContracts } from "../Analytics/CompletedContracts/CompletedContracts";
import { CurrentStats } from "../Analytics/CurrentStats";
import { Filter } from "../Analytics/Filter";
import { HistoricalStats } from "../Analytics/HistoricalStats";
import { AdminPage } from "./AdminPage";

export const ADMIN_DASHBOARD_ROUTE = "/admin";

export const AdminDashboardPage: React.FunctionComponent = () => {
  return (
    <AdminPage title="Admin Dashboard" className="admin-dashboard">
      <PersistFilter />
      <section>
        <article>
          <CurrentStats />
          <h2>
            <T>Historical statistics</T>
          </h2>
          <Filter />
          <HistoricalStats />

          <h2>
            <T>Sent to onboarding</T>
          </h2>
          <CompletedContracts />
        </article>
      </section>
    </AdminPage>
  );
};
