import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Retry } from "../../components/retry/Retry";
import { dataMaps } from "../../data/dataMaps";
import { dataStatistics, OverviewStatistics } from "../../data/dataStatistics";
import { AnalyticsFilterUtils } from "../../model/AnalyticsFilter";
import { Status } from "../../data/types";
import { analyticsFilterState } from "../../state/analyticsState";
import { HistoricalStatsGraph } from "./HistoricalStatsGraph";
import { userState } from "../../state/userState";
import { useTranslation } from "react-i18next";
import { useWidth } from "../../hooks/useWidth";
import "./HistoricalStats.scss";
import { Language } from "../../i18n";

export interface Point {
  name: keyof OverviewStatistics;
  date: Date;
  offset: number;
  count: number;
}

export function HistoricalStats() {
  const { t, i18n } = useTranslation();
  const user = useRecoilValue(userState);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const filter = useRecoilValue(analyticsFilterState);
  const [historicalData, setHistoricalData] = useState<Point[]>([]);
  const [max, setMax] = useState<number>(-1);
  const width = useWidth();

  const load = useCallback(() => {
    const filterQuery = AnalyticsFilterUtils.filterToQuery(filter);

    const statsPromise = user?.salesUser
      ? dataStatistics.getSalesHistorical(filterQuery)
      : dataStatistics.getAdminHistorical(filterQuery);

    Promise.all([statsPromise, dataMaps.initD3()])
      .then((response) => {
        setStatus(Status.SUCCESS);
        const data: Point[] = [];
        let max = -1;

        response[0].forEach((point) => {
          const offsetSentForSigning = point.started;
          const offsetCompletedSigning = offsetSentForSigning + point.sentForSigning;
          const offsetCompletedSignoff = offsetCompletedSigning + point.completedSigning;
          const offsetCompletedOnboarding = offsetCompletedSignoff + point.completedSignoff;

          max = Math.max(
            max,
            point.started +
              point.sentForSigning +
              point.completedSigning +
              point.completedSignoff +
              point.completedOnboarding
          );

          data.push({
            name: "started",
            date: new Date(point.date),
            offset: 0,
            count: point.started,
          });

          data.push({
            name: "sentForSigning",
            date: new Date(point.date),
            offset: offsetSentForSigning,
            count: point.sentForSigning,
          });

          data.push({
            name: "completedSigning",
            date: new Date(point.date),
            offset: offsetCompletedSigning,
            count: point.completedSigning,
          });

          data.push({
            name: "completedSignoff",
            date: new Date(point.date),
            offset: offsetCompletedSignoff,
            count: point.completedSignoff,
          });

          data.push({
            name: "completedOnboarding",
            date: new Date(point.date),
            offset: offsetCompletedOnboarding,
            count: point.completedOnboarding,
          });
        });
        setMax(max);
        setHistoricalData(data);
      })
      .catch((err) => {
        setStatus(Status.ERROR);
      });
  }, [filter, user?.salesUser]);

  useEffect(load, [load]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(load, 500);
  }, [load]);

  return (
    <>
      <ul className="historical-stats-legend fs-small">
        <li className="started">
          <span /> {t("Creating")}
        </li>
        <li className="sent-for-signing">
          <span /> {t("Awaiting merchant")}
        </li>
        <li className="completed-signing">
          <span /> {t("Awaiting sign off")}
        </li>
        <li className="completed-signoff">
          <span /> {t("Sent to onboarding")}
        </li>
        <li className="completed-onboarding">
          <span /> {t("Onboarded")}
        </li>
      </ul>
      <div className="historical-stats">
        <Retry status={status} retry={retry}>
          <HistoricalStatsGraph
            historicalData={historicalData}
            max={max}
            lang={i18n.language as Language}
            width={width}
          />
        </Retry>
      </div>
    </>
  );
}
