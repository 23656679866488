import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Input } from "../../../components/interactions/Input/Input";
import { AnalyticsFilter, FilteredName } from "../../../data/dataStatistics";
import { AnalyticsFilterUtils } from "../../../model/AnalyticsFilter";
import { analyticsFilterState } from "../../../state/analyticsState";
import { defaultAnalyticsFilter } from "../../../Store";
import "./CompletedContractsFilter.scss";

export function CompletedContractsFilter() {
  const { t } = useTranslation();
  const analyticsFilter = useRecoilValue(analyticsFilterState);
  const [from, setFrom] = useState<string>(analyticsFilter.from);
  const [to, setTo] = useState<string>(analyticsFilter.to);
  const history = useHistory();

  // Dates might be updated elsewhere
  useEffect(() => {
    setFrom(analyticsFilter.from);
    setTo(analyticsFilter.to);
  }, [analyticsFilter]);

  const onUpdate = useCallback(() => {
    const filter: AnalyticsFilter = {
      ...analyticsFilter,
      from,
      to,
    };

    if (AnalyticsFilterUtils.isValidFilter(filter)) {
      history.push(`${history.location.pathname}${AnalyticsFilterUtils.filterToQuery(filter)}`);
    }
  }, [from, to, analyticsFilter, history]);

  const onFilteredNameUpdate = useCallback(
    (filteredName) => {
      // setAnalyticsFilter((filter) => {
      //   return { ...filter, filteredName };
      // });

      const filter: AnalyticsFilter = {
        ...analyticsFilter,
        filteredName,
      };

      if (AnalyticsFilterUtils.isValidFilter(filter)) {
        history.replace(`${history.location.pathname}${AnalyticsFilterUtils.filterToQuery(filter)}`);
      }
    },
    [analyticsFilter, history]
  );

  return (
    <div className="historical-contracts-filter">
      <div className="filter-dates">
        <Input
          type="date"
          name="min-date"
          className="compact"
          attributes={{
            min: defaultAnalyticsFilter.from,
            max: to,
          }}
          onChange={(updatedFrom) => {
            setFrom(updatedFrom);
          }}
          value={from}
          label={t("Start date")}
        />

        <Input
          type="date"
          name="max-date"
          className="compact"
          attributes={{
            min: from,
            max: defaultAnalyticsFilter.to,
          }}
          onChange={(updatedTo) => {
            setTo(updatedTo);
          }}
          value={to}
          label={t("End date")}
        />

        <Button onClick={onUpdate}>{t("Update")}</Button>
      </div>

      <div className="filter-buttons">
        <Button
          className={cx("filter-button", {
            active: analyticsFilter.filteredName === FilteredName.DATE,
          })}
          onClick={() => {
            onFilteredNameUpdate(FilteredName.DATE);
          }}
        >
          Date
        </Button>
        <Button
          className={cx("filter-button", {
            active: analyticsFilter.filteredName === FilteredName.BUNDLE_ID,
          })}
          onClick={() => {
            onFilteredNameUpdate(FilteredName.BUNDLE_ID);
          }}
        >
          Bundle
        </Button>
        <Button
          className={cx("filter-button", {
            active: analyticsFilter.filteredName === FilteredName.TEAM_ID,
          })}
          onClick={() => {
            onFilteredNameUpdate(FilteredName.TEAM_ID);
          }}
        >
          Team id
        </Button>
        <Button
          className={cx("filter-button", {
            active: analyticsFilter.filteredName === FilteredName.PRICING_STEP,
          })}
          onClick={() => {
            onFilteredNameUpdate(FilteredName.PRICING_STEP);
          }}
        >
          Pricing
        </Button>
      </div>
    </div>
  );
}
