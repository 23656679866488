import React, { useCallback, useRef, useState } from "react";
import cx from "classnames";
import {
  ContractPackage,
  TerminalType,
  Terminal as TerminalInterface,
  PackageId,
} from "../../../../../../model/contract/contractType";
import { Status } from "../../../../../../data/types";
import { Button } from "../../../../../../components/interactions/Buttons/Button";
import { AddCircleOutline } from "../../../../../../components/icons/AddCircleOutline";
import { Cas, Country, Currency, TerminalId } from "../../../../../../model/common/commonType";
import { Store2 } from "../../../../../../model/contract/contractType";
import { Trans, useTranslation } from "react-i18next";
import { AnimateHeight } from "../../../../../../components/animate/AnimateHeight";
import { Expand } from "../../../../../../components/icons/Expand";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Terminal } from "./Terminal";
import { contractStoresState } from "../../../../../../state/contractStoresState";
import { Input } from "../../../../../../components/interactions/Input/Input";
import { getIntlCost } from "../../../../../../components/utils";
import { HiddenInput } from "../../../../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../../../../components/form/validators/RequiredValidator";
import { contractStatusState } from "../../../../../../state/contractStatusState";
import "./Terminals.scss";
import { OLD_TERMINAL_PRICES, TerminalPricingState } from "../../../../../../state/terminalPricingState";

interface Props {
  terminals: TerminalInterface[];
  saveStore: (store: Store2) => void;
  packageState: ContractPackage;
  inputStatus: Status;
  store: Store2;
}

export function isFirstTerminalIncluded(country: Country) {
  return country === Country.CROATIA;
}

function getNumberOfDecimals(country: Country, packageId: PackageId) {
  return packageId === PackageId.SOFTPOS_BUNDLE && country === Country.POLAND ? 2 : 0;
}

const countriesWithoutTerminalCosts = [Country.CZECHIA];

function getTerminalsCostElement(
  country: Country,
  terminals: TerminalInterface[],
  terminalPricing: TerminalPricingState,
  packageId?: PackageId
) {
  let terminalType: TerminalType;
  let currency;

  switch (packageId) {
    case PackageId.SOFTPOS_BUNDLE:
      terminalType = TerminalType.SOFT_POS;
      break;
    case PackageId.DESK_BUNDLE:
      terminalType = TerminalType.DESK_3500;
      break;
    default:
      terminalType = TerminalType.MOVE_3500;
      break;
  }

  switch (country) {
    case Country.CROATIA:
      currency = Currency.CROATIA;
      break;
    case Country.POLAND:
      currency = Currency.POLAND;
      break;
    default:
      currency = Currency.CZECHIA;
      break;
  }

  if (!packageId) {
    return <div className="terminals-additional-cost" />;
  }

  if (!terminals.length) {
    return <div className="terminals-additional-cost" />;
  }

  if (!terminalPricing[terminalType]) {
    return <div className="terminals-additional-cost" />;
  }

  if (countriesWithoutTerminalCosts.includes(country)) {
    return <div className="terminals-additional-cost" />;
  }

  let numberOfIncludedTerminals = isFirstTerminalIncluded(country) ? 1 : 0;

  const numberOfTerminals = Math.max(
    0,
    terminals.filter((terminal) => {
      if (packageId === PackageId.SOFTPOS_BUNDLE) {
        return terminal.terminalType === TerminalType.SOFT_POS;
      }

      if (packageId === PackageId.DESK_BUNDLE) {
        return terminal.terminalType === TerminalType.DESK_3500;
      }

      return terminal.terminalType === TerminalType.MOVE_3500;
    }).length - numberOfIncludedTerminals
  );

  const terminalCost = terminalPricing[terminalType].monthlyCost;

  const total = terminalCost
    ? getIntlCost(
        country,
        terminalCost * numberOfTerminals,
        currency ?? Currency.POLAND,
        getNumberOfDecimals(country, packageId)
      )
    : "Price not found for temrinal type";

  const formattedTerminalCost = terminalCost
    ? getIntlCost(country, terminalCost, currency ?? Currency.POLAND, getNumberOfDecimals(country, packageId))
    : "Price not found for temrinal type";

  if (isFirstTerminalIncluded(country)) {
    <div className="terminals-additional-cost">
      <Trans>
        Additional terminals are {{ formattedTerminalCost }} / month each. <b>{{ numberOfTerminals }}</b>{" "}
        additional terminals have been added to a cost of <b>{{ total }}</b>.
      </Trans>
    </div>;
  } else {
    return (
      <div className="terminals-additional-cost">
        <Trans>
          Terminals are {{ formattedTerminalCost }} / month each. <b>{{ numberOfTerminals }}</b> terminals
          have been added to a cost of <b>{{ total }}</b>.
        </Trans>
      </div>
    );
  }
}

export const Terminals: React.FunctionComponent<Props> = ({
  terminals,
  saveStore,
  packageState,
  inputStatus,
  store,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const setStores = useSetRecoilState(contractStoresState);
  const contract = useRecoilValue(contractStatusState);
  const { country } = contract;
  const ref = useRef<HTMLDivElement>(null);

  const defaultTerminalPrices = OLD_TERMINAL_PRICES;

  const onToggle = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  const addTerminal = useCallback(
    (type: TerminalType) => {
      const updatedTerminals = store.terminals.concat({
        id: 0 as TerminalId,
        cas: 0 as Cas,
        terminalType: type,
        bookAfterReservation: false,
        tip: false,
        terminalClosure: "23:30",
        storeId: store.storeId,
      });

      saveStore({ ...store, terminals: updatedTerminals });
    },
    [saveStore, store]
  );

  const onChange = useCallback(
    (val) => {
      const copy = store.terminals.map((item) => ({
        ...item,
        terminalClosure: val,
      }));

      setStores((prevStores) =>
        prevStores.map((prevStore) => {
          if (prevStore.storeId === store.storeId) {
            return {
              ...prevStore,
              terminals: copy,
            };
          }
          return prevStore;
        })
      );
    },
    [setStores, store.terminals, store.storeId]
  );

  const onBlur = useCallback(() => saveStore(store), [saveStore, store]);

  const addMove = useCallback(() => addTerminal(TerminalType.MOVE_3500), [addTerminal]);

  const addDesk = useCallback(() => addTerminal(TerminalType.DESK_3500), [addTerminal]);

  const addSoft = useCallback(() => addTerminal(TerminalType.SOFT_POS), [addTerminal]);

  return (
    <div className="store-terminals">
      <strong>{t("Add terminals")}</strong>

      <div className={cx("add-terminal-buttons", packageState.packageId)}>
        <div className="terminal-move">
          <Button
            type="button"
            onClick={addMove}
            action
            className="small"
            status={inputStatus}
            data-testid="add-move-terminal"
          >
            <AddCircleOutline />
          </Button>
          <span>{t("Move 3500")}</span>
        </div>
        <div className="terminal-desk">
          <Button
            type="button"
            onClick={addDesk}
            action
            className="small"
            status={inputStatus}
            data-testid="add-desk-terminal"
          >
            <AddCircleOutline />
          </Button>
          <span>{t("Desk 3500")}</span>
        </div>
        <div className="terminal-soft">
          <Button
            type="button"
            onClick={addSoft}
            action
            className="small"
            status={inputStatus}
            data-testid="add-soft-terminal"
          >
            <AddCircleOutline />
          </Button>
          {country !== Country.CZECHIA ? (
            <span>{t("Worldline Tap on Mobile")}</span>
          ) : (
            <span>{t("PayPhone")}</span>
          )}
        </div>
      </div>

      <div className="terminal-rows-wrapper" ref={ref}>
        <ul className="terminal-rows fs-small">
          <li className="terminal-row-header">
            <ul>
              <li className="terminal-name">{t("Terminal")}</li>
              {/* <li className="terminal-closure">{t("Closure")}</li>
              <li className="terminal-reservation">
                {t("Book after reservation")}
              </li>
              <li className="terminal-tips">{t("Tips")}</li> */}
              <li className="terminal-remove">&nbsp;</li>
            </ul>
          </li>
          {!terminals.length && (
            <li className="terminal-missing" key="missing">
              {t("Currently there are no terminals associated with this location")}
            </li>
          )}
          <HiddenInput
            label={t("Terminals")}
            validators={[new RequiredValidator(t("You need at least one terminal"))]}
            value={terminals.length > 0 ? true : undefined}
            scrollToRef={ref}
          />
          {terminals.map((terminal, idx) => (
            <Terminal
              terminal={terminal}
              inputStatus={inputStatus}
              key={terminal.id}
              saveStore={saveStore}
              store={store}
              index={idx}
              country={country}
            />
          ))}
        </ul>
      </div>

      {getTerminalsCostElement(country, terminals, defaultTerminalPrices, packageState.packageId)}

      <div
        className={cx({
          hide: packageState.packageId === PackageId.SOFTPOS_BUNDLE,
        })}
      >
        <div
          className={cx("flex", "closure-settings", {
            open,
          })}
        >
          <strong>{t("Set terminal parameters")}</strong>
          <Button action ghost type="button" className="small" onClick={onToggle}>
            <Expand />
          </Button>
        </div>

        <AnimateHeight name={open ? "open" : "closed"}>
          {open ? (
            <div className="terminal-closure-setting">
              <Input
                value={store.terminals[0]?.terminalClosure ?? "23:30"}
                onChange={onChange}
                label={t("Period closure time")}
                name="terminalClosure"
                placeholder="e.g. 23:30"
                status={inputStatus}
                onBlur={onBlur}
              />
            </div>
          ) : (
            <div />
          )}
        </AnimateHeight>
      </div>
    </div>
  );
};
