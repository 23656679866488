import React, { ReactNode } from "react";
import cx from "classnames";
import { ErrorOutline } from "../icons/ErrorOutline";
import { Checkmark } from "../icons/Checkmark";
import { Pending } from "../icons/Pending";
import "./StatusBox.scss";
import { WarningOutline } from "../icons/WarningOutline";
import { IconButton } from "../interactions/Buttons/IconButton";
import { CloseIcon } from "../icons/CloseIcon";

import { MdInfoOutline } from "react-icons/md";

export interface StatusBoxProps {
  className: string;
  children: string | ReactNode;
  margins?: boolean;
  size?: "small" | "medium" | "large" | "fullWidth";
  withoutBorder?: boolean;
  onClose?: () => void;
  noPadding?: boolean;
}

export const StatusBox: React.FunctionComponent<StatusBoxProps> = ({
  className,
  children,
  size = "fullWidth",
  withoutBorder = true,
  onClose,
  margins,
  noPadding = false,
}) => {
  return (
    <div
      className={cx(className, {
        "status-box-with-border": !withoutBorder,
        margins: margins,
        "status-box-without-padding": noPadding,
      })}
    >
      <div
        className={cx({
          "medium-box-size": size === "medium",
          "small-box-size": size === "small",
          "large-box-size": size === "large",
          "fullwidth-box-size": size === "fullWidth",
        })}
      >
        <div className="status-box-message">
          <div className={cx("status-box-icon")}>
            <ErrorOutline />
            <WarningOutline />
            <Checkmark />
            <Pending />

            <MdInfoOutline className="icon info-icon" />
          </div>
          {children}
          {onClose && (
            <div className="status-box-close">
              <IconButton rounded onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export interface StatusBoxImplProps
  extends Pick<StatusBoxProps, "size" | "withoutBorder" | "onClose" | "margins" | "noPadding"> {
  children: string | ReactNode;
  relative?: boolean;
  className?: string;
}
