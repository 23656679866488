import React, { useState, useCallback, useMemo, useRef } from "react";
import { Button } from "../../../components/interactions/Buttons/Button";
import { useTranslation } from "react-i18next";
import { Checkboxes } from "../../../components/interactions/Checkboxes/Checkboxes";
import { Language, TRANSLATION_NAMESPACE } from "../../../i18n";
import { TextInput } from "../../../components/form/TextInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { Form, FormContainer } from "../../../components/form/Form";
import { Status } from "../../../data/types";
import { AnimateHeight } from "../../../components/animate/AnimateHeight";
import { SuccessBox } from "../../../components/boxes/SuccessBox";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { dataTranslation } from "../../../data/dataTranslation";
import { Link } from "../../../components/links/Link";
import { TRANSLATIONS_PAGE } from "./MissingTranslations";
import "./AddTranslation.scss";

export const ADD_TRANSLATION_PAGE = "/admin/add-translation";

interface Props {}

function getElem(saved: string, error: boolean, t: any) {
  if (saved) {
    return {
      name: "saved",
      elem: (
        <SuccessBox relative>
          {t("Saved")}: <i>"{saved}"</i>
        </SuccessBox>
      ),
    };
  }

  if (error) {
    return {
      name: "error",
      elem: (
        <ErrorBox relative>{t("Could not save label. Try again?")}</ErrorBox>
      ),
    };
  }

  return {
    name: "none",
    elem: <div />,
  };
}

export const AddTranslation: React.FunctionComponent<Props> = () => {
  const [values, setValues] = useState<string[]>([]);
  const [namespaces, setNamespaces] = useState<string[]>([]);
  const [text, setText] = useState<string>();
  const formRef = useRef<FormContainer>();
  const { t, i18n } = useTranslation();
  const [savedValues, setSavedValues] = useState<string[]>([]);
  const change = (value: string[]) => {
    setError(false);
    setSaved("");
    setValues(value);
  };
  const changeNamespace = (value: string[]) => {
    setError(false);
    setSaved("");
    setNamespaces(value);
  };
  const [saved, setSaved] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const alternatives = useMemo(() => {
    const regionNames = new (Intl as any).DisplayNames([i18n.language], {
      type: "region",
    });

    return Object.keys(Language).map((lang) => {
      const key = lang as keyof typeof Language;
      return {
        text: regionNames.of(Language[key].toUpperCase()),
        value: Language[key],
      };
    });
  }, [i18n.language]);

  const namespaceAlts = useMemo(
    () =>
      Object.values(TRANSLATION_NAMESPACE).map((item) => ({
        text: t(item),
        value: item,
      })),
    [t]
  );

  const onSave = useCallback(() => {
    const promises: Promise<unknown>[] = [];

    if (!text) {
      return;
    }

    values.forEach((lang) => {
      namespaces.forEach((namespace) => {
        promises.push(
          dataTranslation.addTranslationLabel({
            language: lang as Language,
            namespace,
            key: text,
          })
        );
      });
    });

    Promise.all(promises)
      .then(() => {
        formRef.current?.resetValidation();
        setText("");
        setSaved(text);
        setSavedValues((prev) => [...prev, text]);
      })
      .catch(() => {
        setError(true);
      });
  }, [namespaces, text, values]);

  const elem = getElem(saved, error, t);

  return (
    <section>
      <article>
        <div className="add-translation">
          <Form formContainer={formRef}>
            <h4>{t("Add translation label")}</h4>
            <div className="m-top-40">
              <div className="m-top-40">
                <Checkboxes
                  label="Select namespace"
                  className="compact"
                  onChange={changeNamespace}
                  values={namespaces}
                  alternatives={namespaceAlts}
                />
              </div>

              <div className="m-top-40">
                <Checkboxes
                  label="Select languages"
                  className="compact"
                  onChange={change}
                  values={values}
                  alternatives={alternatives}
                />
              </div>

              <div className="m-top-40">
                <TextInput
                  onChange={(val) => {
                    setError(false);
                    setSaved("");
                    setText(val);
                  }}
                  label={t("Add translation label")}
                  value={text}
                  validators={[new RequiredValidator(t("Text is required"))]}
                />
              </div>

              <AnimateHeight name={elem.name}>{elem.elem}</AnimateHeight>

              <div className="m-top-20">
                <Button
                  block
                  onClick={() => {
                    setError(false);
                    setSaved("");
                    if (!formRef.current?.isValid) {
                      formRef.current?.setForceErrors(true);
                      return;
                    }
                    onSave();
                  }}
                  status={
                    values.length && namespaces.length
                      ? Status.DEFAULT
                      : Status.DISABLED
                  }
                >
                  {t("Save label")}
                </Button>
              </div>

              <div className="m-top-20 center">
                <Link link={TRANSLATIONS_PAGE}>{t("Translation page")}</Link>
              </div>
            </div>
          </Form>

          <div className="m-top-40">
            {savedValues.length ? <h5>{t("Saved labels")}</h5> : null}
            <ul>
              {savedValues.map((item) => (
                <li key={item}>{item}</li>
              ))}
              <li />
            </ul>
          </div>
        </div>
      </article>
    </section>
  );
};
