import { useTranslation } from "react-i18next";
import { Button } from "../interactions/Buttons/Button";
import { FC } from "react";
import styles from "./Error.module.scss";

interface Props {
  onClick: () => void;
}

export const ClaimError: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <h5>{t("Weird")} 🤔</h5>
      <p className={styles.message}>
        {t("Someone else has claimed this contract. We could try to reclaim it and then save it.")}
      </p>
      <Button onClick={onClick} block>
        {t("Reclaim and save?")}
      </Button>
    </div>
  );
};
