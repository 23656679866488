import React, { RefObject, useCallback } from "react";
import { Status } from "../../data/types";
import { Alternative } from "../interactions/InputTypes";
import { BeneficialOwnerType } from "../../model/contract/contractType";
import { SoleProprietor } from "./SoleProprietor";
import { MultipleOwner } from "./MultipleOwner";
import { NoDirectNoMajority } from "./NoDirectNoMajority";
import { NoDirectListedCompany } from "./NoDirectListedCompany";
import { FiducialOrTrust } from "./FiducialOrTrust";
import { FoundationOrOther } from "./FoundationOrOther";
import { AssociateOwner } from "../../model/associate/associateTypes";
import { defaultOwner } from "../../state/contractAssociateState";
import "./OwnerAttributes.scss";

interface Props {
  owner?: AssociateOwner;
  onChange: (owner: AssociateOwner, name?: string) => void;
  status?: Status;
  beneficialOwnerType: BeneficialOwnerType;
  scrollToRef?: RefObject<HTMLElement>;
}

export const designatedOwnerOpts: Alternative<string>[] = [
  {
    text: "Is sole owner",
    value: "designatedOwner",
  },
];

export const OwnerAttributes: React.FunctionComponent<Props> = ({
  owner = defaultOwner,
  onChange,
  status = Status.DEFAULT,
  beneficialOwnerType,
  scrollToRef,
}) => {
  const onUpdate = useCallback(
    (value, name) => {
      const update = { ...owner };
      if (Array.isArray(value)) {
        update.designatedOwner = value.length ? true : false;
      } else {
        if (name === "votingRightsStake" || name === "capitalStake") {
          (update as any)[name] = typeof value !== "undefined" ? value : undefined;
        } else {
          (update as any)[name] = value;
        }
      }
      onChange(update, name);
    },
    [onChange, owner]
  );

  return (
    <>
      <SoleProprietor
        onChange={onUpdate}
        ownerForm={owner}
        status={status}
        beneficialOwnerType={beneficialOwnerType}
        scrollToRef={scrollToRef}
      />

      <MultipleOwner
        onChange={onUpdate}
        ownerForm={owner}
        status={status}
        beneficialOwnerType={beneficialOwnerType}
        scrollToRef={scrollToRef}
      />

      <NoDirectNoMajority
        onChange={onUpdate}
        ownerForm={owner}
        status={status}
        beneficialOwnerType={beneficialOwnerType}
        scrollToRef={scrollToRef}
      />

      <NoDirectListedCompany
        onChange={onUpdate}
        ownerForm={owner}
        status={status}
        beneficialOwnerType={beneficialOwnerType}
        scrollToRef={scrollToRef}
      />

      <FiducialOrTrust beneficialOwnerType={beneficialOwnerType} />

      <FoundationOrOther beneficialOwnerType={beneficialOwnerType} />
    </>
  );
};
