import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { Minus } from "../../../../components/icons/Minus";
import { Plus } from "../../../../components/icons/Plus";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Status } from "../../../../data/types";
import { contractPricingState } from "../../../../state/contractCostState";
import { contractStatusState } from "../../../../state/contractStatusState";
import styles from "./PromotionMonths.module.scss";
import { ContractType } from "../../../../model/contract/contractType";
import useGeneralData from "../../../../hooks/useGeneralData";
import { calculateOfferingStart } from "../CashlessPromotion/CashlessPromotion";

interface Props {
  contractType: ContractType;
}

export const PromotionMonths: FC<Props> = ({ contractType }) => {
  const { language } = useGeneralData();
  const { t } = useTranslation();
  const { edit } = useRecoilValue(contractStatusState);

  const [contractPricing, setContractPricing] = useRecoilState(contractPricingState);
  const { cashlessPromotion, promotionMonths } = contractPricing[contractType];

  const decrementButtonStatus = useMemo(() => {
    return cashlessPromotion || !edit || promotionMonths === 0 ? Status.DISABLED : Status.DEFAULT;
  }, [cashlessPromotion, edit, promotionMonths]);

  const incrementButtonStatus = useMemo(() => {
    return cashlessPromotion || !edit ? Status.DISABLED : Status.DEFAULT;
  }, [cashlessPromotion, edit]);

  const onRemoveMonth = useCallback(
    () =>
      setContractPricing((prev) => ({
        ...prev,
        [contractType]: {
          ...prev[contractType],
          promotionMonths: prev[contractType].promotionMonths - 1,
        },
      })),
    [setContractPricing, contractType]
  );

  const onAddMonth = useCallback(
    () =>
      setContractPricing((prev) => ({
        ...prev,
        [contractType]: {
          ...prev[contractType],
          promotionMonths: prev[contractType].promotionMonths + 1,
        },
      })),
    [setContractPricing, contractType]
  );

  return (
    <div className={styles.root}>
      <h5>{t("Promotion months")}</h5>
      <div className={"tablet-columns"}>
        <div className={styles.invoice}>{t("First invoice")}</div>

        <div className={styles.toggle}>
          <Button
            type="button"
            action
            onClick={onRemoveMonth}
            className="small"
            status={decrementButtonStatus}
          >
            <Minus />
          </Button>
          <span className={styles.month}>
            {calculateOfferingStart(promotionMonths).toLocaleDateString(language, {
              year: "numeric",
              month: "long",
            })}
          </span>
          <Button type="button" action onClick={onAddMonth} className="small" status={incrementButtonStatus}>
            <Plus />
          </Button>
        </div>
      </div>
    </div>
  );
};
