import { deepCopy } from "../../components/utils";
import { getFeeBounds } from "./feeBoundUtils";
import { OptionalTransactionFee, PricingModel, CardBrand } from "./pricingTypes";

// compare fees and fee bounds to decide if they are the same and can be grouped
export const groupFees = (fees: OptionalTransactionFee[], pricingModel: PricingModel) => {
  const groupedFees: OptionalTransactionFee[][] = [];
  const pricingModelFeeBounds = getFeeBounds(pricingModel);

  fees.forEach((fee) => {
    const group = groupedFees.find((group) => {
      const firstFee = group[0];
      const firstFeeBounds = JSON.stringify(pricingModelFeeBounds[firstFee.brand]);

      const matchingBounds = firstFeeBounds === JSON.stringify(pricingModelFeeBounds[fee.brand]);

      if (!matchingBounds) {
        return false;
      }

      return areFeesEqual(firstFee, fee);
    });

    if (group) {
      group.push(fee);
    } else {
      groupedFees.push([fee]);
    }
  });
  return groupedFees;
};

// soley based on the pdf contract template that only has 6 rows
export const MAXIMUM_UNIQUE_FEE_ROWS = 6;
const MAXIMUM_UNIQUE_FEE_ROWS_PACK = 2;

export const hasValidUniqueFeeRows = (
  groupedFees: OptionalTransactionFee[][],
  contractUniqueRows: number,
  pricingModel: PricingModel
) => {
  const isTooManyRows = contractUniqueRows > MAXIMUM_UNIQUE_FEE_ROWS;

  if (isTooManyRows) return undefined;

  if (pricingModel === PricingModel.PACK) {
    const isTooManyPackRows = groupedFees.length >= MAXIMUM_UNIQUE_FEE_ROWS_PACK;
    if (isTooManyPackRows) return undefined;
  }

  return true;
};

const isFeeTypeEqual = (feeType1?: string, feeType2?: string) => {
  if (!feeType1 && !feeType2) return true;
  return feeType1 === feeType2;
};

export const areFeesEqual = (fee1: OptionalTransactionFee, fee2: OptionalTransactionFee) => {
  const isFixedFeeEqual = isFeeTypeEqual(fee1.fixedFee, fee2.fixedFee);
  const isMaxFeeEqual = isFeeTypeEqual(fee1.maxFee, fee2.maxFee);
  const isMinFeeEqual = isFeeTypeEqual(fee1.minFee, fee2.minFee);
  const isTransactionFeeEqual = isFeeTypeEqual(fee1.transactionFee, fee2.transactionFee);
  const isTariffClassEqual = fee1.tariffClass === fee2.tariffClass;

  return isFixedFeeEqual && isMaxFeeEqual && isMinFeeEqual && isTransactionFeeEqual && isTariffClassEqual;
};

/* 
  This is soley based on the pdf template layout. The default / minimum rows for a generated contract are 4 and maximum are 6. I.e. we can have 2 unique extra rows
  Check method getUniqueContractRows
  
  ROW 1:  Visa, Mastercard
  ROW 2:  VisaDebit, DebitMastercard
  ROW 3:  VPay, Maestro
  ROW 4:  Diners, Union Pay, JCB 
  ROW 5:  EXTRA
  ROW 6:  EXTRA

  */

enum BrandCategory {
  VISA_MASTERCARD = "VISA_MASTERCARD",
  VISA_DEBIT_DEBIT_MASTERCARD = "VISA_DEBIT_DEBIT_MASTERCARD",
  VPAY_MAESTRO = "VPAY_MAESTRO",
  DINERS_UNION_JCB = "DINERS_UNION_JCB",
}

type BrandCategoryMap = {
  [BrandCategory.VISA_MASTERCARD]: OptionalTransactionFee[];
  [BrandCategory.VISA_DEBIT_DEBIT_MASTERCARD]: OptionalTransactionFee[];
  [BrandCategory.VPAY_MAESTRO]: OptionalTransactionFee[];
  [BrandCategory.DINERS_UNION_JCB]: OptionalTransactionFee[];
};

const INITIAL_BRAND_CATEGORY_MAP: BrandCategoryMap = {
  [BrandCategory.VISA_MASTERCARD]: [],
  [BrandCategory.VISA_DEBIT_DEBIT_MASTERCARD]: [],
  [BrandCategory.VPAY_MAESTRO]: [],
  [BrandCategory.DINERS_UNION_JCB]: [],
};

const visaMastercardList = [CardBrand.VISA_CREDIT, CardBrand.MASTERCARD_CREDIT];
const isVisaMastercard = (brand: CardBrand) => visaMastercardList.includes(brand);

const visaDebitDebitMastercardList = [CardBrand.VISA_DEBIT, CardBrand.MASTERCARD_DEBIT];
const isVisaDeitDebitMastercard = (brand: CardBrand) => visaDebitDebitMastercardList.includes(brand);

const vpayMaestroList = [CardBrand.VPAY, CardBrand.MAESTRO];
const isVpayMaestro = (brand: CardBrand) => vpayMaestroList.includes(brand);

// const dinersUnionJcbList = [Brands.DINERS, Brands.UNION, Brands.JCB];
// const isDinersUnionJcb = (brand: Brands) => dinersUnionJcbList.includes(brand);

const generateCategoryMap = (transactionFee: OptionalTransactionFee[]): BrandCategoryMap => {
  const map = deepCopy(INITIAL_BRAND_CATEGORY_MAP);

  transactionFee.forEach((fee) => {
    if (isVisaMastercard(fee.brand)) {
      map[BrandCategory.VISA_MASTERCARD].push(fee);
    } else if (isVisaDeitDebitMastercard(fee.brand)) {
      map[BrandCategory.VISA_DEBIT_DEBIT_MASTERCARD].push(fee);
    } else if (isVpayMaestro(fee.brand)) {
      map[BrandCategory.VPAY_MAESTRO].push(fee);
    } else {
      map[BrandCategory.DINERS_UNION_JCB].push(fee);
    }
  });

  return map;
};

const getRowsFromCategory = (fees: OptionalTransactionFee[]) => {
  const firstFee = fees[0];
  const uniqueFees = [firstFee];

  for (let i = 1; i < fees.length; i++) {
    const currentFee = fees[i];
    const alreadyExists = uniqueFees.some((fee) => areFeesEqual(fee, currentFee));
    if (!alreadyExists) {
      uniqueFees.push(currentFee);
    }
  }

  return uniqueFees.length;
};

// this is the one that decided how many rows are allowed.
export const getUniqueContractRows = (transactionFees: OptionalTransactionFee[]) => {
  const categoryMap = generateCategoryMap(transactionFees);
  const first = getRowsFromCategory(categoryMap[BrandCategory.VISA_MASTERCARD]);
  const second = getRowsFromCategory(categoryMap[BrandCategory.VISA_DEBIT_DEBIT_MASTERCARD]);
  const third = getRowsFromCategory(categoryMap[BrandCategory.VPAY_MAESTRO]);
  const fourth = getRowsFromCategory(categoryMap[BrandCategory.DINERS_UNION_JCB]);

  const totalRows = first + second + third + fourth;
  return totalRows;
};
