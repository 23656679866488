import { atom, selector } from "recoil";
import { Associate, AssociateRole } from "../model/associate/associateTypes";
import { AssociateId, Cas, DocumentId } from "../model/common/commonType";
import { DocumentType, IdType, Saluation } from "../model/contract/contractType";
import { NO_EXPIRY_DATE } from "../components/idCard/IdCardInputs";

export const DUMMY_OWNER_ID = "aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee" as AssociateId;

export const defaultIdentity = {
  cas: 0 as Cas,
  idType: IdType.PASSPORT,
  noExpiry: false,
};

export const defaultOwner = {
  cas: 0 as Cas,
  designatedOwner: false,
  designatedFunction: "",
  capitalStake: undefined, //0.00 - 100.00
  votingRightsStake: undefined, //0.00 - 100.00
};

export const defaultAssociate = {
  associateId: DUMMY_OWNER_ID,
  cas: 0 as Cas,
  roles: [],
  address: {},
  saluation: Saluation.HE,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  documents: {
    idCard: {
      id: 0 as DocumentId,
      documentType: DocumentType.ID_DOCUMENT,
      fileAvailable: false,
    },
  },
};

export function checkIdExpiry(associates: Associate[]) {
  return associates.map((associate) => {
    if (!associate.identity) {
      return associate;
    }

    if (!associate.identity.expiry) {
      return associate;
    }

    if (associate.identity.expiry === NO_EXPIRY_DATE) {
      return {
        ...associate,
        identity: { ...associate.identity, noExpiry: true },
      };
    }

    return associate;
  });
}

export const contractAssociateState = atom<Associate[]>({
  key: "contract-associate-state",
  default: [],
});

export const bankAccountOwnerSelector = selector({
  key: "bank-account-owner-selector",
  get: ({ get }) => {
    return get(contractAssociateState).filter(
      (item) => item.roles.indexOf(AssociateRole.ACCOUNT_HOLDER) > -1
    )[0];
  },
});

export const sortedAssociatesSelector = selector({
  key: "sorted-associates-selector",
  get: ({ get }) => {
    const associates = get(contractAssociateState);
    return [...associates].sort((a, b) => {
      const aDate = new Date(a.created as string);
      const bDate = new Date(b.created as string);
      if (aDate < bDate) {
        return -1;
      }
      if (aDate > bDate) {
        return 1;
      }
      return 0;
    });
  },
});

export const legalAssociatesSelector = selector({
  key: "legal-associates-selector",
  get: ({ get }) => {
    return get(sortedAssociatesSelector).filter(
      (item) =>
        item.roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1 ||
        item.roles.indexOf(AssociateRole.SIGNATORY) > -1
    );
  },
});

export const businessAssociatesSelector = selector({
  key: "business-associates-selector",
  get: ({ get }) => {
    return get(sortedAssociatesSelector).filter(
      (item) =>
        item.roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1 ||
        item.roles.indexOf(AssociateRole.SIGNATORY) > -1 ||
        item.roles.indexOf(AssociateRole.ACCOUNT_HOLDER) > -1
    );
  },
});

export const primaryContactAssociatesSelector = selector({
  key: "primary-contact-associates-selector",
  get: ({ get }) => {
    return get(sortedAssociatesSelector).find(
      (item) => item.roles.indexOf(AssociateRole.PRIMARY_CONTACT) > -1
    );
  },
});

export function isOwner(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1;
}

export function isSignee(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.SIGNATORY) > -1;
}

export function hasLogin(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.PORTAL_LOGIN) > -1;
}

export function isPrimary(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.PRIMARY_CONTACT) > -1;
}

export function isAccountHolder(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.ACCOUNT_HOLDER) > -1;
}

export function isStoreContact(associate: Associate) {
  return associate.roles.indexOf(AssociateRole.STORE_CONTACT) > -1;
}

export const ownerSharesSelector = selector({
  key: "owner-shares-selector",
  get: ({ get }) => {
    const owners = get(contractAssociateState).filter(
      (item) => item.roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1
    );

    const totalVotingRights = owners.reduce((prevShare, owner) => {
      if (owner.owner) {
        return prevShare + (owner.owner.votingRightsStake || 0);
      }

      return prevShare;
    }, 0);

    const totalCapitalStake = owners.reduce((prevShare, owner) => {
      if (owner.owner) {
        return prevShare + (owner.owner.capitalStake || 0);
      }

      return prevShare;
    }, 0);

    return {
      totalVotingRights,
      totalCapitalStake,
    };
  },
});
