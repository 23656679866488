import cx from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Logo } from "../../components/images/Logo";
import { Form } from "../../components/form/Form";
import { AnimateHeight } from "../../components/animate/AnimateHeight";
import { FieldSet } from "../../components/fieldSet/FieldSet";
import { TextInput } from "../../components/form/TextInput";
import { MaxLengthValidator } from "../../components/form/validators/MaxLengthValidator";
import { MinLengthValidator } from "../../components/form/validators/MinLengthValidator";
import { NumberValidator } from "../../components/form/validators/NumberValidator";
import { RequiredValidator } from "../../components/form/validators/RequiredValidator";
import { Trash } from "../../components/icons/Trash";
import { Button } from "../../components/interactions/Buttons/Button";
import { Status } from "../../data/types";
import { API } from "../../network/API";
import { useIsMountedRef } from "../../hooks/useIsMounted";
import { Progress } from "../../components/fileUpload/Progress";
import { useTranslation } from "react-i18next";
import styles from "./BackofficeUpload.module.scss";
import { Link } from "../../components/links/Link";
import { LOGOUT_ROUTE } from "../Auth/Logout";

export const BACKOFFICE_DASHBOARD_ROUTE = "/backoffice";

const enabled = false;
export interface ProgressInterface {
  progress: number;
  name: string;
}
export const BackofficeDashboardPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [merchantIds, setMerchantIds] = useState<string[]>([""]);
  const [merchantName] = useState("");
  const [disabled] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [buttonStatus] = useState(Status.DEFAULT);
  const [cashless] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const allowMultipleMerchandisers = cashless && enabled;
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const mounted = useIsMountedRef();
  const fileElemRef = useRef<HTMLInputElement>(null);
  const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null);
  const [progress, setProgress] = useState<ProgressInterface>({
    progress: 0,
    name: "",
  });
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const maxDocuments = 10;

  const handleDeleteFile = (indexToDelete: number) => {
    setUploadedFiles((currentFiles) => currentFiles.filter((_, index) => index !== indexToDelete));
  };

  const showNoFilesAttachedNotification = useCallback(() => {
    setNotificationMessage("No files attached. Please attach some files before submitting.");
    setNotificationType("error");
    setShowNotification(true);

    if (notificationTimeoutRef.current) {
      clearTimeout(notificationTimeoutRef.current);
    }

    notificationTimeoutRef.current = window.setTimeout(() => {
      setShowNotification(false);
      notificationTimeoutRef.current = undefined;
    }, 3000);
  }, []);

  const upload = useCallback(
    (merchantId: string, files: File[]) => {
      setStatus(Status.PENDING);
      if (files.length === 0) {
        showNoFilesAttachedNotification();
        return; // Exit the function as there's nothing to upload
      }

      const req = new XMLHttpRequest();
      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setProgress({ progress, name: merchantId });
        }
      });
      req.onload = function () {
        if (req.status === 200) {
          setProgress({ progress: 100, name: merchantId });
          setNotificationMessage("Archived successfully!");
          setNotificationType("success");
          setShowNotification(true);

          setUploaded(true);
          setTimeout(() => {
            if (!mounted.current) return;
            setShowNotification(false);
            setUploadedFiles([]);
            setMerchantIds([""]);
            setProgress({ progress: 0, name: "" });
            setStatus(Status.DEFAULT);
          }, 4000);
        } else {
          const errorMessage = req.responseText || "Could not upload file. Please try agian.";
          setApiErrorMessage(errorMessage);
          setStatus(Status.ERROR);
          setUploaded(false);
          setNotificationMessage(errorMessage);
          setNotificationType("error");
          setShowNotification(true);
          setTimeout(() => {
            setStatus(Status.DEFAULT);
            setShowNotification(false);
            setProgress({ progress: 0, name: "" });
          }, 4000);
        }
      };

      const formData = new FormData();
      formData.append("merchantId", merchantId);

      files.forEach((file, i) => {
        formData.append(`files`, file); // keep the name "files" for each file to match the backend
      });
      req.withCredentials = true;
      req.open("POST", API.getUrl(`/api/backoffice/archive`));
      req.setRequestHeader("Accept", "application/json");
      req.send(formData);
    },
    [mounted, showNoFilesAttachedNotification]
  );

  const notificationTimeoutRef = useRef<number | undefined>();
  const showMaxDocumentsNotification = useCallback(() => {
    setNotificationMessage(`Cannot upload more than ${maxDocuments} documents.`);
    setNotificationType("error");
    setShowNotification(true);

    if (notificationTimeoutRef.current) {
      clearTimeout(notificationTimeoutRef.current);
    }

    notificationTimeoutRef.current = window.setTimeout(() => {
      setShowNotification(false);
      notificationTimeoutRef.current = undefined;
    }, 3000);
  }, [maxDocuments]);

  useEffect(() => {
    // Clear the timeout when the component unmounts
    return () => {
      if (notificationTimeoutRef.current) {
        clearTimeout(notificationTimeoutRef.current);
      }
    };
  }, []);

  const onAttachFile = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>): void => {
      setUploaded(false);
      if (ev.target.files) {
        const newFiles = Array.from(ev.target.files);
        if (uploadedFiles.length + newFiles.length > maxDocuments) {
          showMaxDocumentsNotification();

          setTimeout(() => setShowNotification(false), 3000);
          return;
        }
        setUploadedFiles((currentFiles) => [...currentFiles, ...newFiles]);
      }
      if (fileElemRef.current) {
        fileElemRef.current.value = "";
      }
      setApiErrorMessage(null);
    },
    [uploadedFiles.length, maxDocuments, showMaxDocumentsNotification]
  );

  const rootClass = cx([styles.root], { [styles.uploaded]: uploaded });

  return (
    <div className="backoffice-completed">
      <Logo />
      <div className="m-top-40">
        <Form
          onSubmit={(event, form) => {
            if (!form.isValid) {
              return;
            }
            upload(merchantIds[0], uploadedFiles);
          }}
        >
          <FieldSet header={merchantName}>
            <div className="">
              <AnimateHeight name={merchantIds.length + "height"}>
                <div
                  className={cx("merchant-wrapper", {
                    "has-cashless": allowMultipleMerchandisers,
                  })}
                >
                  <div className={cx("doc-upload", status)}>
                    <div>
                      {apiErrorMessage && (
                        <div className="error-box">
                          <span>{apiErrorMessage}</span>
                        </div>
                      )}
                      {/* Notification component */}
                      {showNotification && (
                        <div
                          className={cx(styles.notification, {
                            [styles.success]: notificationType === "success",
                            [styles.error]: notificationType === "error",
                          })}
                        >
                          {notificationMessage}
                        </div>
                      )}
                      <div className="doc-upload-button-container">
                        <input
                          id="file-upload"
                          type="file"
                          onChange={onAttachFile}
                          ref={fileElemRef}
                          style={{ display: "none" }}
                          multiple={true}
                        />

                        <label htmlFor="file-upload">
                          <span
                            className={cx("button", status, {
                              block: true,
                              "is-disabled": disabled,
                              // block: !doc.fileAvailable,
                            })}
                          >
                            {t("Upload document")}
                            {status === Status.ERROR && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                className={cx("icon", "icon-error")}
                              >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
                              </svg>
                            )}
                          </span>
                        </label>
                      </div>
                      <Progress {...progress} />
                    </div>
                  </div>

                  {uploadedFiles.map((file, index) => {
                    return (
                      <div key={file.name + index} className={rootClass}>
                        <div className={styles.content}>
                          <div className={styles.description}>
                            {file.name}
                            <button
                              type="button"
                              onClick={() => handleDeleteFile(index)}
                              className={styles.small}
                            >
                              <Trash />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {merchantIds.map((merchantId, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <TextInput
                          key={idx}
                          name="merchantId"
                          label="Merchant Id / VP Number"
                          onChange={(val) => {
                            setMerchantIds((values) => {
                              const copy = [...values];
                              copy[idx] = val ?? "";
                              return copy;
                            });
                          }}
                          disabled={disabled}
                          value={merchantIds[idx] ?? ""}
                          hint="ID for company"
                          validators={[
                            new RequiredValidator("This field is required"),
                            new NumberValidator("This field must be a number"),
                            new MinLengthValidator(9, "Minimum length is 9"),
                            new MaxLengthValidator(10, "Max length is 10"),
                          ]}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </AnimateHeight>
              <hr />
            </div>
          </FieldSet>
          <div className="m-top-20">
            <Button block status={buttonStatus} type="submit">
              Save and Archive
            </Button>
          </div>
        </Form>
        <div className={styles.link_container}>
          <Link link={LOGOUT_ROUTE}>{t("Logout")}</Link>
        </div>
      </div>
    </div>
  );
};
