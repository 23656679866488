import { FunctionComponent } from "react";

import styles from "./PricingPackThreshold.module.scss";
import { useTranslation } from "react-i18next";
import { NumberInput } from "../../../../../../../../components/form/NumberInput";
import { MinValidator } from "../../../../../../../../components/form/validators/MinValidator";
import { NumberValidator } from "../../../../../../../../components/form/validators/NumberValidator";
import { Country } from "../../../../../../../../model/common/commonType";
import { defaultCurrencyFromCountry } from "../../../../../../../../i18n";

interface Props {
  values: [number?, number?];
  setValues: (threshold?: number, fee?: number) => void;
  disabled?: boolean;
  country: Country;
}

export const PricingPackThreshold: FunctionComponent<Props> = ({
  values,
  setValues,
  disabled = false,
  country,
}) => {
  const [threshold, fee] = values;
  const currency = defaultCurrencyFromCountry(country);
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.header}>{t("Volume & pricing")}</div>
      <div className={styles.content}>
        <NumberInput
          label="Monthly transaction volume"
          name="monthlyTurnoverThreshold"
          value={threshold}
          onChange={(newValue) => {
            setValues(newValue, fee);
          }}
          validators={[
            new NumberValidator("Monthly transaction volume is required"),
            new MinValidator(1, "Monthly transaction volume must be greater than $1"),
          ]}
          disabled={disabled}
          placeholder="Monthly transaction volume"
          suffix={` ${currency}`}
        />
        <NumberInput
          label="Monthly fee"
          name="monthlyAcquiringFee"
          value={fee}
          onChange={(newValue) => {
            setValues(threshold, newValue);
          }}
          validators={[
            new NumberValidator("Monthly fee is required"),
            new MinValidator(1, "Monthly fee must be greater than $1"),
          ]}
          disabled={disabled}
          placeholder="Monthly fee"
          suffix={` ${currency}`}
        />
      </div>
    </div>
  );
};
