import { FC, useState } from "react";
import styles from "./DCCSelector.module.scss";
import { useTranslation } from "react-i18next";
import { SetEditablePricing } from "../../../Admin/Pricing/AdminPricingCountryPage/AdminPresetPage/PricingInputs/PricingInputs";
import { NumberInput } from "../../../../components/form/NumberInput";
import { getIntlNumberFormat } from "../../../../components/utils";
import { Language } from "../../../../i18n";
import { MinValidator } from "../../../../components/form/validators/MinValidator";
import { MaxValidator } from "../../../../components/form/validators/MaxValidator";
import DCCvalueDisplayer from "./DCCvalueDisplayer";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Status } from "../../../../data/types";
import { DCC } from "../../../../model/pricing/pricingTypes";

interface Props {
  disabled: boolean;
  setEditablePricing?: SetEditablePricing;
  dccAdminValues: DCC[];
}

export const DCCSelectorAdmin: FC<Props> = ({ disabled, setEditablePricing, dccAdminValues }) => {
  const { i18n, t } = useTranslation();
  const [currentDccInput, setCurrentDccInput] = useState<number>();
  const [currentTariffClassInput, setCurrentTariffClassInput] = useState<number>();

  const lang = i18n.language as Language;
  const minFee = 1;
  const maxFee = 5;
  const [hint, setHint] = useState<String>(
    t("From {{from}}% to {{to}}%", {
      from: getIntlNumberFormat(lang, minFee, 2),
      to: getIntlNumberFormat(lang, maxFee, 2),
    })
  );

  let errorMin = t("Minimum value is {{min}}%", {
    min: getIntlNumberFormat(lang, minFee, 2),
  });
  let errorMax = t("Maximum value is {{max}}%", {
    max: getIntlNumberFormat(lang, maxFee, 2),
  });
  const removeDccValue = (valueToRemove: DCC) => {
    if (setEditablePricing) {
      setEditablePricing((prev) => ({
        ...prev,
        dcc: prev.dcc.filter((value) => value !== valueToRemove),
      }));
    }
  };

  const handleDccInputChange = (value: number | undefined) => {
    if (value) {
      setCurrentDccInput(value);
    }
  };

  const handleTariffClassInputChange = (value: number | undefined) => {
    if (value) {
      setCurrentTariffClassInput(value);
    }
  };

  const handleAddDCCValue = () => {
    if (currentDccInput && (currentDccInput > maxFee || currentDccInput < minFee)) return;
    if (setEditablePricing && currentDccInput && currentTariffClassInput) {
      setEditablePricing((prev) => {
        const existingDcc = prev.dcc.find((dccObject) => dccObject.dcc === currentDccInput);

        if (!existingDcc) {
          const updatedDcc = [
            ...prev.dcc,
            { dcc: currentDccInput, tariffClass: currentTariffClassInput },
          ].sort((a, b) => a.dcc - b.dcc);
          setHint(
            t("From {{from}}% to {{to}}%", {
              from: getIntlNumberFormat(lang, minFee, 2),
              to: getIntlNumberFormat(lang, maxFee, 2),
            })
          );
          return {
            ...prev,
            dcc: updatedDcc,
          };
        } else {
          setHint("This DCC value already existed! :(");
          return prev;
        }
      });
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.dccvalues}>
        {dccAdminValues.map((value) => (
          <DCCvalueDisplayer key={value.dcc} onClose={() => removeDccValue(value)} disabled={disabled}>
            <span>{value.dcc.toString() + "%"}</span>
            <span>|</span>
            <span>{value.tariffClass.toString()}</span>
          </DCCvalueDisplayer>
        ))}
      </div>
      <div className={styles.values}>
        <div>
          <NumberInput
            disabled={disabled}
            label="DCC"
            value={currentDccInput}
            onChange={(val) => handleDccInputChange(val)}
            placeholder="DCC"
            message={hint}
            suffix="%"
            validators={[new MinValidator(minFee, errorMin), new MaxValidator(maxFee, errorMax)]}
          />
        </div>
        <div>
          <NumberInput
            disabled={disabled}
            label="Tariff Class"
            value={currentTariffClassInput}
            onChange={(val) => handleTariffClassInputChange(val)}
            placeholder="Tariff class"
            message={hint}
          />
        </div>
        <div className={styles.button}>
          <Button status={disabled ? Status.DISABLED : Status.DEFAULT} onClick={handleAddDCCValue}>
            Add DCC
          </Button>
        </div>
      </div>
    </div>
  );
};
