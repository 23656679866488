import { useTranslation } from "react-i18next";
import { FormattedDate } from "../../../../../components/time/FormattedDate";
import { FC, Fragment } from "react";
import { MerchantAssociateResponse } from "../../../../../data/dataConfirm";
import { ImageOverlaySignedOff } from "./ImageOverlaySignedOff";
import styles from "../../AssociateReview.module.scss";

export type OpenImageTypes = "passport" | "idFront" | "idBack" | "selfie" | "";
interface Props {
  merchantAssociate: MerchantAssociateResponse;
  openImage: OpenImageTypes;
  toggleImage: (string: OpenImageTypes) => void;
}

export const SignedOffImages: FC<Props> = ({ merchantAssociate, openImage, toggleImage }) => {
  const { t } = useTranslation();
  const { identityDocument, name: associateName } = merchantAssociate;

  return (
    <Fragment>
      <h4>{t(`${identityDocument.passport ? "Passport" : "National ID Card"}`)}</h4>
      <dl className={styles.data_list}>
        <dd>{t("Reviewed at")}</dd>
        <dt>
          <FormattedDate date={identityDocument.signedOff} />
        </dt>
        <dd>{t("Reviewed by")}</dd>
        <dt>{identityDocument.signedOffBy}</dt>
      </dl>
      <dl className={styles.data_list}>
        <dd>{t("Uploaded at")}</dd>
        <dt>
          <FormattedDate date={identityDocument.uploaded} />
        </dt>
        <dd>{t("Uploaded from")}</dd>
        <dt>{identityDocument.city ? identityDocument.city : "-"}</dt>
        <dd>{t("IP address")}</dd>
        <dt>{identityDocument.ipAddress}</dt>
      </dl>
      {identityDocument.passport ? (
        <ImageOverlaySignedOff
          showImage={openImage === "passport"}
          toggleImage={(open) => (open ? toggleImage("passport") : toggleImage(""))}
          image={identityDocument.passport}
          fileName={associateName}
          openText={"Open Image"}
          downloadText={"Download Image"}
          title={"Passport"}
        />
      ) : (
        <Fragment>
          <ImageOverlaySignedOff
            showImage={openImage === "idFront"}
            toggleImage={(open) => (open ? toggleImage("idFront") : toggleImage(""))}
            image={identityDocument.idFront}
            fileName={associateName}
            openText={"Open Front ID"}
            downloadText={"Download Front ID"}
            title={"ID Card Front"}
          />
          <ImageOverlaySignedOff
            showImage={openImage === "idBack"}
            toggleImage={(open) => (open ? toggleImage("idBack") : toggleImage(""))}
            image={identityDocument.idBack}
            fileName={associateName}
            openText={"Open Back ID"}
            downloadText={"Download Back ID"}
            title={"ID Card Back"}
          />
        </Fragment>
      )}
      <ImageOverlaySignedOff
        showImage={openImage === "selfie"}
        toggleImage={(open) => (open ? toggleImage("selfie") : toggleImage(""))}
        image={merchantAssociate.selfie.image}
        fileName={associateName}
        openText={"Open Selfie"}
        downloadText={"Download Selfie"}
        title={"Selfie"}
      />
    </Fragment>
  );
};
