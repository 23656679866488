import React, { FC, RefObject } from "react";
import { HiddenInput } from "../../components/form/HiddenInput";
import { RequiredValidator } from "../../components/form/validators/RequiredValidator";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { contractAssociateState } from "../../state/contractAssociateState";
import { Associate } from "../../model/associate/associateTypes";

interface Props {
  scrollToRef: RefObject<HTMLElement>;
}

const hasDuplicatesNames = (associates: Associate[]) => {
  const namesSet = new Set<string>();
  const duplicatedNames = new Set<string>();

  associates.forEach((associate) => {
    const name = associate.firstName + " " + associate.lastName;
    if (namesSet.has(name)) {
      duplicatedNames.add(name);
    } else {
      namesSet.add(name);
    }
  });

  return duplicatedNames;
};

const DuplicateAssociateError: FC<Props> = ({ scrollToRef }) => {
  const { t } = useTranslation();
  const associates = useRecoilValue(contractAssociateState);
  const duplicateNames = hasDuplicatesNames(associates);

  return (
    <HiddenInput
      label={t("Duplicate associates")}
      value={duplicateNames.size > 0 ? undefined : true}
      validators={[
        new RequiredValidator(
          t(
            "You have duplicate associate names '{{ duplicateNames }}'. Ensure each person is listed only once; please update one entry and remove any duplicates.",
            { duplicateNames: Array.from(duplicateNames).join(", ") }
          )
        ),
      ]}
      scrollToRef={scrollToRef}
    />
  );
};

export default DuplicateAssociateError;
